import { IconUsers } from '@tabler/icons-react';
import styled, { useTheme } from 'styled-components';
import { AppText, AppTextProps } from 'components/atoms/CommonAppComponents';

type GroupMailThreadMessageReadStatusCountProps = Pick<AppTextProps, 'textType'> & {
    readStatusCountText: string;
    color?: string;
    className?: string;
    onClick?: () => void;
};

const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const GroupMailThreadMessageReadStatusCount = ({
    readStatusCountText,
    textType,
    color,
    className,
    onClick,
}: GroupMailThreadMessageReadStatusCountProps) => {
    const theme = useTheme();

    return (
        <Container className={className} onClick={onClick}>
            <IconUsers size={20} color={color} />
            <AppText
                textType={textType}
                margin={0}
                marginLeft={theme.sizes.marginSmall}
                color={color}
            >
                {readStatusCountText}
            </AppText>
        </Container>
    );
};
