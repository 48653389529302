import { useEffect } from 'react';
import { ParentAccountEditForm } from 'components/templates';
import { PageBaseLayout } from 'components/layouts';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { ParentAccountFormFields } from 'types';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { formFields } from 'consts/form/formFields';
import { accountTexts } from 'consts/text';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { parseParentProfileDTOIntoParentAccountFormFields } from 'utils/parsers/user/parseParentProfileDTOIntoParentAccountFormFields';
import { parseParentAccountFormFieldsIntoUpdateParentProfileDTO } from 'utils/parsers/user/parseParentAccountFormFieldsIntoUpdateParentProfileDTO';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ParentAccountEditPage = () => {
    const { id: currentChildId } = useCurrentChild();
    const { form, formInitialData, setFormData } = useEditForm<ParentAccountFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const {
        data: parentProfileData,
        isFetching: isParentProfileDataFetching,
        isError: isParentProfileDataError,
    } = useAppQuery(
        'PARENT_PROFILE',
        [currentChildId],
        () => ParentHTTPService.children.parentProfile.getParentProfile(currentChildId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (parentProfileData && !form.getFieldValue(formFields.firstName)) {
            const parsedParentAccountFormFields =
                parseParentProfileDTOIntoParentAccountFormFields(parentProfileData);
            setFormData(parsedParentAccountFormFields);
        }
    }, [parentProfileData, form, setFormData]);

    const onParentAccountEditSuccess = () => {
        displaySuccess(accountTexts.successfullyUpdatedAccountData);
        statefulNavigate(appPaths.app.parent.account.details.base, { replace: true });
    };

    const {
        mutate: updateParentAccount,
        error: updateParentAccountError,
        isLoading: isUpdateParentAccountLoading,
    } = useAppMutation(ParentHTTPService.children.parentProfile.updateParentProfile, {
        key: ['UPDATE_ACCOUNT'],
        onSuccess: onParentAccountEditSuccess,
        invalidateQueryKeys: [['PARENT_PROFILE', currentChildId]],
    });

    const onEditParentAccountFinish = (parentAccountFormFields: ParentAccountFormFields) => {
        const parsedUpdateParentAccountDTO =
            parseParentAccountFormFieldsIntoUpdateParentProfileDTO(parentAccountFormFields);
        updateParentAccount({
            childId: currentChildId,
            updateParentProfileDTO: parsedUpdateParentAccountDTO,
        });
    };

    return (
        <PageBaseLayout isError={isParentProfileDataError}>
            <ParentAccountEditForm
                form={form}
                initialValues={formInitialData}
                axiosError={updateParentAccountError}
                isLoading={isParentProfileDataFetching || isUpdateParentAccountLoading}
                onFinish={onEditParentAccountFinish}
            />
        </PageBaseLayout>
    );
};
