import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';

type PsychologicalHelpAdditionalInformationInputElementProps = AppFormItemProps & {
    label: string;
};

export const PsychologicalHelpAdditionalInformationInputElement = ({
    label,
    ...restProps
}: PsychologicalHelpAdditionalInformationInputElementProps) => (
    <AppFormItem
        label={label}
        name={formFields.additionalInformation}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...restProps}
    >
        <AppInput autoComplete="off" />
    </AppFormItem>
);
