import { COMBINED_TREE_PICKER_KEY_SEPARATOR } from 'consts/combinedTreePickerKeySeparator';

const RECIPIENT_ID_KEY_PART_INDEX = 1;

export const parseRecipientKeyIntoRecipientId: (recipientKey: string) => string = (
    recipientKey,
) => {
    const hasNodeSeparator = recipientKey.includes(COMBINED_TREE_PICKER_KEY_SEPARATOR);
    if (hasNodeSeparator) {
        return recipientKey.split(COMBINED_TREE_PICKER_KEY_SEPARATOR)[RECIPIENT_ID_KEY_PART_INDEX];
    }
    return recipientKey;
};
