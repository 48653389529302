import { ReactNode } from 'react';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { Row } from './components';
import { DesktopContainer, MobileContainer, OuterContainer } from './AppCardListItem.styled';
import { Option, OptionsButton, OptionsButtonProps } from '../../AppTableAndListCommon';

export type AppCardListItemProps<ListItem> = Pick<
    OptionsButtonProps<ListItem>,
    'index' | 'isOptionsLoading'
> & {
    listItem: ListItem;
    options?: Option<ListItem>[];
    children?: ReactNode;
};

export const AppCardListItem = <ListItem,>({
    index,
    listItem,
    options,
    isOptionsLoading,
    children,
}: AppCardListItemProps<ListItem>) => {
    const isMobile = useIsMobile();

    return (
        <OuterContainer>
            {isMobile ? (
                <MobileContainer>{children}</MobileContainer>
            ) : (
                <DesktopContainer>{children}</DesktopContainer>
            )}
            {options && (
                <OptionsButton
                    index={index}
                    record={listItem}
                    options={options}
                    openOptionsButtonSize="BIG"
                    isOptionsLoading={isOptionsLoading}
                />
            )}
        </OuterContainer>
    );
};

AppCardListItem.CardRow = Row;
