import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useParams } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { DietAddEditForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { DietId, DietsFormFields } from 'types';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { ErrorDisplay } from 'components/molecules';
import { parseDietsFormFieldsIntoAddUpdateDietDTO } from 'utils/parsers/diets/parseDietsFormFieldsIntoAddUpdateDietDTO';
import { parseDietDTOIntoDietsFormFields } from 'utils/parsers/diets/parseDietDTOIntoDietsFormFields';
import { settingsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const DietsEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<DietsFormFields>();
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const { dietId: dietIdParam } = useParams<{ dietId: DietId }>();
    const dietId = dietIdParam || ('' as DietId);

    const onSuccess = () => {
        displaySuccess(settingsTexts.diets.successNotifications.dietEdited);
        statefulNavigate(appPaths.app.settings.diets.base, { replace: true });
    };

    const { isFetching: isDietDataFetching, isError: isDietDataError } = useAppQuery(
        'DIET',
        [dietId],
        () => StaffHTTPService.diets.getDiet(dietId),
        {
            onSuccess: (data) => setFormData(parseDietDTOIntoDietsFormFields(data)),
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const { mutate, error, isLoading } = useAppMutation(StaffHTTPService.diets.updateDiet, {
        key: ['UPDATE_DIET'],
        onSuccess,
        invalidateQueryKeys: [['DIETS'], ['DIET', dietId]],
    });

    const onFinish = (dietData: DietsFormFields) => {
        const parsedUpdateDietDTO = parseDietsFormFieldsIntoAddUpdateDietDTO(dietData);
        mutate({ dietId, updateDietDTO: parsedUpdateDietDTO });
    };

    if (isDietDataError) {
        return <ErrorDisplay />;
    }

    return (
        <DietAddEditForm
            type="edit"
            axiosError={error}
            isLoading={isDietDataFetching || isLoading}
            onFinish={onFinish}
            form={form}
            initialValues={formInitialData}
        />
    );
};
