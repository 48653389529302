import { DiscountTarget, DiscountType } from '../../types';
import { settlementTexts } from '../text';

export const discountTargetMapper: Record<DiscountTarget, string> = {
    [DiscountTarget.MEAL]: settlementTexts.discounts.general.meals,
    [DiscountTarget.STAY]: settlementTexts.discounts.general.stay,
    [DiscountTarget.EXTRA_LESSONS]: settlementTexts.discounts.general.extraLessons,
};

export const discountTypeMapper: Record<DiscountType, string> = {
    [DiscountType.PERCENTAGE_DISCOUNT]: settlementTexts.discounts.general.percentageDiscount,
    [DiscountType.CO_FINANCING]: settlementTexts.discounts.general.coFinancing,
    [DiscountType.ALLOWANCE]: settlementTexts.discounts.general.allowance,
};
