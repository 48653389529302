import { GroupDayAttendanceDTO } from 'types';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

type DayAttendanceDisplayProps = {
    dayAttendance?: GroupDayAttendanceDTO;
};

const PRESENT_DAY_ATTENDANCE_LABEL = '1';

export const DayAttendanceDisplay = ({ dayAttendance }: DayAttendanceDisplayProps) => {
    if (!dayAttendance) {
        return '';
    }
    return dayAttendance.type === 'PRESENT' ? PRESENT_DAY_ATTENDANCE_LABEL : EMPTY_VALUE_LABEL;
};
