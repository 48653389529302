import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { JournalDetailsLayout } from 'components/layouts';
import {
    JournalChildrenPage,
    JournalInspectionsPage,
    SpecialJournalDailyAttendancePage,
    SpecialJournalConsultationsPage,
    SpecialJournalLessonsPage,
    SpecialJournalQualificationPage,
    SpecialJournalTopicsPage,
    SpecialJournalMonthlyAttendancePage,
} from 'pages';
import { AppActions } from 'types';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import {
    JournalNavigate,
    NavigateToDefaultJournalPage,
} from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalDetails: RouteObject[] = [
    {
        path: appPaths.app.journals.specialJournalDetails.base,
        element: <Navigate to={appPaths.app.journals.base} />,
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW_MODULE,
                    subject: STAFF_APP_MODULES.SPECIAL_JOURNALS,
                }}
            >
                <JournalDetailsLayout journalType="SPECIAL" />
            </AbilityRoute>
        ),
        children: [
            { index: true, element: <NavigateToDefaultJournalPage journalType="SPECIAL" /> },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.base,
                element: (
                    <JournalNavigate
                        to={
                            appPaths.app.journals.specialJournalDetails.pickedJournal.attendance
                                .daily.base
                        }
                    />
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.daily
                    .base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_ATTENDANCE,
                        }}
                    >
                        <SpecialJournalDailyAttendancePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.monthly
                    .base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_ATTENDANCE,
                        }}
                    >
                        <SpecialJournalMonthlyAttendancePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.lessons.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_TIMETABLE_VIEW,
                        }}
                    >
                        <SpecialJournalLessonsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.children.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_CHILDREN_LIST_VIEW,
                        }}
                    >
                        <JournalChildrenPage journalType="SPECIAL" />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_INSPECTIONS,
                        }}
                    >
                        <JournalInspectionsPage journalType="SPECIAL" />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_CONSULTATIONS,
                        }}
                    >
                        <SpecialJournalConsultationsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_QUALIFICATION_BASIS,
                        }}
                    >
                        <SpecialJournalQualificationPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_TOPICS,
                        }}
                    >
                        <SpecialJournalTopicsPage />
                    </AbilityRoute>
                ),
            },
        ],
    },
];
