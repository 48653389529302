export const childFields = {
    id: 'id',
    firstName: 'firstName',
    lastName: 'lastName',
    sex: 'sex',
    birthDate: 'birthDate',
    birthPlace: 'birthPlace',
    institutionId: 'institutionId',
    groups: 'groups',
    diseases: 'diseases',
    allergies: 'allergies',
    address: 'address',
    guardians: 'guardians',
    parents: 'parents',
    contractValid: 'contractValid',
    status: 'status',
    pesel: 'pesel',
    agreements: 'agreements',
    photoUri: 'photoUri',
    dietDetails: 'dietDetails',
} as const;
