import { SettlementConfiguration } from 'types';
import { settlementTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const additionalLessonSettlementMapper = {
    [SettlementConfiguration.POSTPAID]:
        settlementTexts.extraLessons.settlementOptionsLabels.lastMonth,
    [SettlementConfiguration.PREPAID]:
        settlementTexts.extraLessons.settlementOptionsLabels.nextMonth,
    null: EMPTY_VALUE_LABEL,
};
