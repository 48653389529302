import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { ImportantEventDTO, ImportantEventId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { ImportantEventNameDisplay, DateDisplay } from 'components/molecules';
import { EventTypeSelect, Option } from 'components/atoms';
import { commonTexts, journalTexts } from 'consts/text';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { importantEventDTOFields } from 'consts/importantEvent/importantEventDTOFields';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useGroupJournalImportantEventsPageNetworkManage } from './hooks/useGroupJournalImportantEventsPageNetworkManage';

const columns: AppTableProps<ImportantEventDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.events.importantEvents.event,
        dataIndex: importantEventDTOFields.name,
        key: importantEventDTOFields.name,
        render: (_, importantEvent) => (
            <ImportantEventNameDisplay
                name={importantEvent.name}
                date={importantEvent.date}
                journalId={importantEvent.journalId}
                importantEventId={importantEvent.id}
            />
        ),
    },
    {
        title: commonTexts.dataLabels.date,
        dataIndex: importantEventDTOFields.date,
        key: importantEventDTOFields.date,
        render: (date: ImportantEventDTO['date']) => <DateDisplay date={date} />,
        align: 'center',
        isInvisibleInMobile: true,
    },
];

export const GroupJournalImportantEventsPage = () => {
    const [selectedToRemoveImportantEventId, setSelectedToRemoveImportantEventId] =
        useState<ImportantEventId | null>(null);
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const {
        groupJournalsAbilities: { checkIfCanManageEvents },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageEvents = checkIfCanManageEvents();

    const onImportantEventRemoveRequestFinishOrCancel = () =>
        setSelectedToRemoveImportantEventId(null);

    const {
        importantEventsData,
        isImportantEventsDataInitialLoading,
        isImportantEventsDataRefetching,
        isRemoveImportantEventLoading,
        isImportantEventsDataError,
        removeImportantEvent,
    } = useGroupJournalImportantEventsPageNetworkManage({
        journalId,
        onImportantEventRemoveRequestFinish: onImportantEventRemoveRequestFinishOrCancel,
    });

    const handleRemoveImportantEventClick = (importantEvent: ImportantEventDTO) =>
        setSelectedToRemoveImportantEventId(importantEvent.id);

    const handleRemoveImportantEventConfirm = () => {
        if (!selectedToRemoveImportantEventId) {
            return;
        }
        removeImportantEvent({ journalId, importantEventId: selectedToRemoveImportantEventId });
    };

    const navigateToImportantEventAddPage = () =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.add,
                {
                    journalId,
                },
            ),
        );

    const navigateToImportantEventEditPage = ({ id }: ImportantEventDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.edit
                    .pickedImportantEvent,
                { importantEventId: id, journalId },
            ),
        );

    const navigateToImportantEventDetailsPage = ({ id }: ImportantEventDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents
                    .details.pickedImportantEvent,
                { importantEventId: id, journalId },
            ),
        );

    const options: Option<ImportantEventDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToImportantEventDetailsPage,
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToImportantEventEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageEvents,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveImportantEventClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManageEvents,
        },
    ];

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveImportantEventId}
                title={
                    journalTexts.journalDetails.groupJournal.events.importantEvents
                        .confirmDeleteImportantEvent
                }
                confirmActionLoading={isRemoveImportantEventLoading}
                onOk={handleRemoveImportantEventConfirm}
                onCancel={onImportantEventRemoveRequestFinishOrCancel}
            />
            <AppTable
                title={
                    journalTexts.journalDetails.groupJournal.events.importantEvents
                        .importantEventList
                }
                addButtonLabel={
                    journalTexts.journalDetails.groupJournal.events.importantEvents.addEvent
                }
                emptyInfo={
                    journalTexts.journalDetails.groupJournal.events.importantEvents
                        .emptyImportantEventList
                }
                options={options}
                columns={columns}
                dataSource={importantEventsData}
                rowKey={({ id }) => id}
                isError={isImportantEventsDataError}
                isOptionsLoading={isRemoveImportantEventLoading}
                isDataInitialLoading={isImportantEventsDataInitialLoading}
                isDataRefetching={isImportantEventsDataRefetching}
                isHiddenAddButton={!canManageEvents}
                tableManageUtilsProps={{
                    isVisibleSearchFilter: false,
                    additionalFilters: (
                        <EventTypeSelect eventType="IMPORTANT_EVENT" journalId={journalId} />
                    ),
                }}
                onAddButtonClick={navigateToImportantEventAddPage}
            />
        </>
    );
};
