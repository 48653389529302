import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { GroupAddEditForm } from 'components/templates';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { GroupFormFields, GroupId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { parseGroupDTOIntoGroupFormFields } from 'utils/parsers/group/parseGroupDTOIntoGroupFormFields';
import { formFields } from 'consts/form/formFields';
import { groupsTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<GroupFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const pathData = useMatch(appPaths.app.groups.edit.pickedGroup);
    const { statefulNavigate } = useNavigateWithState();

    const groupId = (pathData?.params.groupId || '') as GroupId;

    const {
        data: groupData,
        isFetching: isGroupDataFetching,
        isError: isFetchGroupDataError,
    } = useAppQuery('GROUP', [groupId], () => StaffHTTPService.groups.getGroup(groupId), {
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useEffect(() => {
        if (groupData && !form.getFieldValue(formFields.name)) {
            setFormData(parseGroupDTOIntoGroupFormFields(groupData));
        }
    }, [groupData, form, setFormData]);

    const redirectToGroupsPage = (replace?: boolean) =>
        statefulNavigate(appPaths.app.groups.base, { replace });

    const onGroupEditSuccess = () => {
        displaySuccess(groupsTexts.successfullyUpdateGroupMessage);
        redirectToGroupsPage(true);
    };

    const {
        mutate: updateGroup,
        error: updateGroupError,
        isLoading: isUpdateGroupLoading,
    } = useAppMutation(StaffHTTPService.groups.updateGroup, {
        key: ['GROUP', groupId],
        onSuccess: onGroupEditSuccess,
        invalidateQueryKeys: [
            ['GROUPS'],
            ['GROUP', groupId],
            ['MENUS_GROUPS'],
            ['JOURNALS_GROUPS'],
            ['MAIL_THREAD_RECEIVERS'],
            ['JOURNALS_TEACHERS'],
            ['CHILDREN'],
            ['CHILD'],
        ],
    });

    const onEditGroupFinish = (groupFormFields: GroupFormFields) =>
        updateGroup({
            groupId,
            updateGroupDTO: groupFormFields,
        });

    if (!groupId) {
        redirectToGroupsPage();
    }

    if (isFetchGroupDataError) {
        return <ErrorDisplay />;
    }

    return (
        <GroupAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={updateGroupError}
            isLoading={isGroupDataFetching || isUpdateGroupLoading}
            onFinish={onEditGroupFinish}
        />
    );
};
