import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import {
    QueryFunction,
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { QueryMainKeysType } from './queryMainKeysType';
import { ApiErrorType } from './apiErrorType';

type InfiniteQueryOptions<TData> = UseInfiniteQueryOptions<TData, ApiErrorType>;

export const useAppInfiniteQuery = <ExpectedInfiniteQueryResponse extends AxiosResponse>(
    mainKey: QueryMainKeysType,
    additionalKeys: QueryKey,
    queryFn: QueryFunction<ExpectedInfiniteQueryResponse, QueryKey>,
    options?: InfiniteQueryOptions<ExpectedInfiniteQueryResponse>,
): UseInfiniteQueryResult<ExpectedInfiniteQueryResponse, ApiErrorType> => {
    const { id: currentInstitutionId } = useCurrentInstitution();
    const { id: childId, institutionAddress: childInstitutionAddress } = useCurrentChild();

    const { institutionId: childInstitutionId } = childInstitutionAddress;

    const queryKey = useMemo(() => {
        if (currentInstitutionId) {
            return [currentInstitutionId, mainKey, ...additionalKeys];
        }
        if (childId) {
            return [childInstitutionId, childId, mainKey, ...additionalKeys];
        }
        return [mainKey, ...additionalKeys];
    }, [additionalKeys, childId, childInstitutionId, currentInstitutionId, mainKey]);

    const wrapFunc: QueryFunction<ExpectedInfiniteQueryResponse, QueryKey> = (props) =>
        queryFn(props);

    return useInfiniteQuery<ExpectedInfiniteQueryResponse, ApiErrorType>(queryKey, wrapFunc, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) =>
            lastPage.data.last ? undefined : lastPage.data.pageable.pageNumber + 1,
        ...options,
    });
};
