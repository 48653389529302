import { MutableRefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type ScrollToTopProps<HTMLElement extends Element> = {
    elementRef: MutableRefObject<HTMLElement | null>;
};

export const useScrollToTop = <HTMLElement extends Element>({
    elementRef,
}: ScrollToTopProps<HTMLElement>) => {
    const { pathname } = useLocation();

    useEffect(() => elementRef.current?.scrollTo(0, 0), [pathname, elementRef]);
};
