import type { DayAttendanceAbsentType, GroupDayAttendanceDTO } from 'types';
import { dayAttendanceDTOFields } from 'consts/attendance/dayAttendanceDTOFields';
import { TableTagDisplay } from 'components/molecules/Table/TableTagDisplay/TableTagDisplay';
import { dayAttendanceAbsentTypesLabels } from 'consts/attendance/dayAttendanceAbsentTypesLabels';
import { tagColorsByDayAttendanceAbsentType } from 'consts/attendance/tagColorsByDayAttendanceAbsentType';

type DailyAttendanceTypeDisplayProps = {
    dayAttendance: GroupDayAttendanceDTO | null;
};

export const DailyAttendanceTypeDisplay = ({ dayAttendance }: DailyAttendanceTypeDisplayProps) => {
    if (dayAttendance === null || dayAttendance[dayAttendanceDTOFields.type] === 'PRESENT') {
        return null;
    }

    return (
        <TableTagDisplay
            value={dayAttendance.type as DayAttendanceAbsentType}
            labelMapper={dayAttendanceAbsentTypesLabels}
            colorMapper={tagColorsByDayAttendanceAbsentType}
        />
    );
};
