import styled from 'styled-components';

export const AppModalIconContainer = styled.div`
    display: flex;
    justify-content: center;

    & svg {
        width: 48px;
        height: 48px;
    }
`;
