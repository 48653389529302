import styled, { css } from 'styled-components';

export const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const DescriptionContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        margin-bottom: ${theme.sizes.marginLarge}px;
        display: flex;
        align-items: center;
        gap: ${theme.sizes.marginSmall}px;

        svg {
            flex-shrink: 0;
        }
    `}
`;
