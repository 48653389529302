import type { HistoryChangeSpecialAction, LabelMapper } from 'types';
import { settingsTexts } from 'consts/text';

export const historyChangeSpecialActionLabels: LabelMapper<HistoryChangeSpecialAction> = {
    NONE: settingsTexts.historyOfChanges.specialActionLabels.none,
    ARCHIVE: settingsTexts.historyOfChanges.specialActionLabels.archive,
    ACTIVATE_USER: settingsTexts.historyOfChanges.specialActionLabels.activateUser,
    CREATE_OR_UPDATE: settingsTexts.historyOfChanges.specialActionLabels.createOrUpdate,
    MODIFY_PROFILE_PHOTO: settingsTexts.historyOfChanges.specialActionLabels.modifyProfilePhoto,
    SEND_ACTIVATION_EMAIL: settingsTexts.historyOfChanges.specialActionLabels.sendActivationEmail,
    UNARCHIVE: settingsTexts.historyOfChanges.specialActionLabels.unarchive,
    UPDATE_CHILD_JOURNAL_DATES:
        settingsTexts.historyOfChanges.specialActionLabels.updateChildJournalDates,
    UPDATE_PRINCIPAL: settingsTexts.historyOfChanges.specialActionLabels.updatePrincipal,
};
