import { commonTexts, settlementTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { DiscountTarget, ExtraLessonsOptionsDTO } from 'types';
import { AppFormItem, AppInput, AppRadioButton, AppRadioGroup, AppSelect } from 'components/atoms';
import { DayPeriodPickerElement } from 'components/molecules';
import { CurrencyInputElement } from '../../molecules/FormElements/CurrencyInputElement/CurrencyInputElement';
import { PercentageInputElement } from '../../molecules/FormElements/PercentageInputElement/PercentageInputElement';

const extraLessonField = (
    discountTarget: DiscountTarget,
    options: ExtraLessonsOptionsDTO | undefined,
    isFetchingOptions: boolean,
) =>
    discountTarget === DiscountTarget.EXTRA_LESSONS && (
        <AppFormItem
            label={settlementTexts.discounts.addEditForm.chooseExtraLesson}
            name={formFields.courseId}
        >
            <AppSelect
                options={
                    options
                        ? options.map((option) => ({ value: option.id, label: option.name }))
                        : []
                }
                loading={isFetchingOptions}
            />
        </AppFormItem>
    );

export const percentageDiscountFields = (
    discountTarget: DiscountTarget,
    options: ExtraLessonsOptionsDTO | undefined,
    isFetchingOptions: boolean,
) => (
    <>
        {extraLessonField(discountTarget, options, isFetchingOptions)}
        <AppFormItem
            label={settlementTexts.discounts.addEditForm.discountName}
            name={formFields.name}
        >
            <AppInput />
        </AppFormItem>
        <AppFormItem justifyContent="space-between" doubleItem>
            <DayPeriodPickerElement
                mode="start"
                endField={formFields.endDate}
                startField={formFields.startDate}
                label={settlementTexts.discounts.addEditForm.discountStartDate}
            />
            <DayPeriodPickerElement
                mode="finish"
                endField={formFields.endDate}
                startField={formFields.startDate}
                label={settlementTexts.discounts.addEditForm.discountEndDate}
            />
        </AppFormItem>
        <PercentageInputElement
            label={settlementTexts.discounts.addEditForm.discountAmount}
            name={formFields.amount}
            isHalfParentWidth
        />
        <AppFormItem
            label={settlementTexts.discounts.addEditForm.combine}
            name={formFields.combinable}
        >
            <AppRadioGroup>
                <AppRadioButton value>{commonTexts.actionLabels.confirm}</AppRadioButton>
                <AppRadioButton value={false}>{commonTexts.actionLabels.reject}</AppRadioButton>
            </AppRadioGroup>
        </AppFormItem>
    </>
);

export const allowanceFields = (
    discountTarget: DiscountTarget,
    options: ExtraLessonsOptionsDTO | undefined,
    isFetchingOptions: boolean,
) => (
    <>
        {extraLessonField(discountTarget, options, isFetchingOptions)}
        <AppFormItem
            label={settlementTexts.discounts.addEditForm.discountName}
            name={formFields.name}
        >
            <AppInput />
        </AppFormItem>
        <AppFormItem justifyContent="space-between" doubleItem>
            <DayPeriodPickerElement
                mode="start"
                endField={formFields.endDate}
                startField={formFields.startDate}
                label={settlementTexts.discounts.addEditForm.discountStartDate}
            />
            <DayPeriodPickerElement
                mode="finish"
                endField={formFields.endDate}
                startField={formFields.startDate}
                label={settlementTexts.discounts.addEditForm.discountEndDate}
            />
        </AppFormItem>
        <CurrencyInputElement
            label={settlementTexts.discounts.addEditForm.allowanceAmount}
            isHalfParentWidth
        />
    </>
);

export const coFinancingFields = (
    discountTarget: DiscountTarget,
    options: ExtraLessonsOptionsDTO | undefined,
    isFetchingOptions: boolean,
) => (
    <>
        {extraLessonField(discountTarget, options, isFetchingOptions)}
        <AppFormItem
            label={settlementTexts.discounts.addEditForm.discountName}
            name={formFields.name}
        >
            <AppInput />
        </AppFormItem>
        <AppFormItem justifyContent="space-between" doubleItem>
            <DayPeriodPickerElement
                mode="start"
                endField={formFields.endDate}
                startField={formFields.startDate}
                label={settlementTexts.discounts.addEditForm.discountStartDate}
            />
            <DayPeriodPickerElement
                mode="finish"
                endField={formFields.endDate}
                startField={formFields.startDate}
                label={settlementTexts.discounts.addEditForm.discountEndDate}
            />
        </AppFormItem>
        <CurrencyInputElement
            label={settlementTexts.discounts.addEditForm.coFinancingAmount}
            isHalfParentWidth
        />
    </>
);
