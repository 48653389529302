import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ChildId } from 'types';
import { parentDeleteErrorParser } from 'utils/errorHandlers/parent/parentDeleteErrorParser';
import { childrenTexts, commonTexts } from 'consts/text/index';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { parentAddErrorParser } from 'utils/errorHandlers/parent/parentAddErrorParser';

type UseParentMutateProps = {
    childId: ChildId;
};

export const useParentMutate = ({ childId }: UseParentMutateProps) => {
    const { displayError, displaySuccess } = useSimpleNotification();
    const invalidateQueryKeys = [['CHILDREN'], ['CHILD', childId]];
    const queryKey = ['CHILD', childId];

    const { mutate: addParent, isLoading: isAddParentLoading } = useAppMutation(
        StaffHTTPService.children.parent.addParent,
        {
            onSuccess: () => displaySuccess(childrenTexts.successfullyAddParentMessage),
            onError: (error) =>
                displayError(parentAddErrorParser(error, childrenTexts.addParentErrorMessage)),
            key: queryKey,
            invalidateQueryKeys,
        },
    );

    const { mutate: deleteParent, isLoading: isDeleteParentLoading } = useAppMutation(
        StaffHTTPService.children.parent.deleteParent,
        {
            onSuccess: () => displaySuccess(childrenTexts.successfullyRemoveParentMessage),
            onError: (error) => displayError(parentDeleteErrorParser(error)),
            key: queryKey,
            invalidateQueryKeys,
        },
    );

    const { mutate: updateParent, isLoading: isUpdateParentLoading } = useAppMutation(
        StaffHTTPService.children.parent.updateParent,
        {
            onSuccess: () => displaySuccess(childrenTexts.successfullyUpdateParentMessage),
            onError: (error) => {
                const { code } = getErrorMessageAndCode(error);

                if (code === apiErrorCodes.USER_ALREADY_EXISTS) {
                    return displayError(commonTexts.errorMessages.userEmailTakenEnterAnother);
                }

                return displayError(childrenTexts.updateParentErrorMessage);
            },
            key: queryKey,
            invalidateQueryKeys,
        },
    );

    return {
        addParent,
        isAddParentLoading,
        deleteParent,
        isDeleteParentLoading,
        updateParent,
        isUpdateParentLoading,
        isParentMutating: isAddParentLoading || isDeleteParentLoading || isUpdateParentLoading,
    };
};
