import { DefaultTheme } from 'styled-components';
import { colors } from './colors';
import { sizes } from './sizes';
import { borders } from './borders';

export const theme: DefaultTheme = {
    sizes,
    colors,
    borders,
} as const;

export type AppTheme = { colors: typeof colors; sizes: typeof sizes; borders: typeof borders };
export type AppThemeColors = typeof colors;
