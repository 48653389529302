import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { InstitutionAddEditForm } from 'components/organisms';
import { IconPlus } from '@tabler/icons-react';
import { FormNavButtons } from 'components/molecules';
import { AppButton } from 'components/atoms';
import { InstitutionAddEditFormFields, InstitutionDto } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseInstitutionAddFormFieldsIntoInstitutionCreateData } from 'utils/parsers/institution/parseInstitutionAddFormFieldsIntoInstitutionCreateData';
import { useSetAtom } from 'jotai';
import { currentInstitutionAtom } from 'jotaiAtoms/currentInstitution';
import { AxiosResponse } from 'axios';
import { institutionAddErrorHandler } from 'utils/errorHandlers/institution/institutionAdd';

export const InstitutionAddPage = () => {
    const setInstitution = useSetAtom(currentInstitutionAtom);
    const navigate = useNavigate();
    const onSuccessNavigate = () => navigate(appPaths.app.base);

    const onSuccess = (response: AxiosResponse<InstitutionDto>) => {
        setInstitution({
            id: response.data.id,
            name: response.data.name,
        });
        onSuccessNavigate();
    };

    const { mutate, error, isLoading } = useAppMutation(
        StaffHTTPService.institutions.postInstitutions,
        {
            key: ['USER_DETAILS'],
            onSuccess,
            invalidateQueryKeys: [['USER_DETAILS']],
        },
    );
    const parsedError = error ? institutionAddErrorHandler(error) : '';

    const navButtons = (
        <FormNavButtons>
            <AppButton type="primary" htmlType="submit">
                <IconPlus size={20} style={{ marginRight: 8 }} />
                Utwórz placówkę
            </AppButton>
        </FormNavButtons>
    );

    const onFinish = (fields: InstitutionAddEditFormFields) =>
        mutate(parseInstitutionAddFormFieldsIntoInstitutionCreateData(fields));

    return (
        <InstitutionAddEditForm
            isLoading={isLoading}
            error={error ? institutionAddErrorHandler(error) : parsedError}
            onFinish={onFinish}
            maxWidth={440}
            withGoBack
            name="institutionAdd"
            formNavButtons={navButtons}
            mode="add"
        />
    );
};
