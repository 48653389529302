import { DateFormat } from 'types';
import { parseStringIntoDayjsDate } from './parseStringIntoDayjsDate';
import { parseDayjsDateIntoString } from './parseDayjsDateIntoString';

export const parseStringIntoFormattedStringDate: (
    date: string,
    fromFormat?: DateFormat,
    toFormat?: DateFormat,
) => string = (date, fromFormat = 'YYYY-MM-DD', toFormat = 'DD/MM/YYYY') => {
    const parsedDate = parseStringIntoDayjsDate(date, fromFormat);
    return parseDayjsDateIntoString(parsedDate, toFormat);
};
