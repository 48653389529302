import styled, { css } from 'styled-components';
import { AppModalIconContainer, AppModalTitle, AppText } from 'components/atoms';
import { getTextStyle } from '../../../utils/getTextStyle';

export const IconContainer = styled(AppModalIconContainer)`
    margin-bottom: 16px;
`;

export const ModalTitle = styled(AppModalTitle)`
    &.ant-typography {
        margin-bottom: 32px;
    }
`;

export const ModalSubtitle = styled(AppText)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.primary9};
        text-align: center;
        ${getTextStyle('BodyMediumSemiBold')}
    `}
`;
