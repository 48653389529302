import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { MailThreadsPreviewSideBar, ParentMailThreadsPreviewSideBar } from 'components/organisms';
import { NoSelectedMailThreadInfo } from 'components/molecules';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { appPaths } from 'consts/paths/paths';
import { BaseContainer, MailThreadOverviewContainer } from './MessagePanelLayout.styled';

type MessagePanelLayoutProps = {
    isParent?: boolean;
};

export const MessagePanelLayout = ({ isParent }: MessagePanelLayoutProps) => {
    const outlet = useOutlet();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const handleNewMailThreadButtonClick = () =>
        navigate(isParent ? appPaths.app.parent.messages.add : appPaths.app.messages.add);

    const renderMainContent = () => {
        if (outlet) {
            return (
                <MailThreadOverviewContainer>
                    <Outlet />
                </MailThreadOverviewContainer>
            );
        }
        if (!isMobile) {
            return (
                <NoSelectedMailThreadInfo
                    onNewMailThreadButtonClick={handleNewMailThreadButtonClick}
                />
            );
        }
        return null;
    };

    return (
        <BaseContainer>
            {isParent ? <ParentMailThreadsPreviewSideBar /> : <MailThreadsPreviewSideBar />}
            {renderMainContent()}
        </BaseContainer>
    );
};
