import { useCallback, useEffect, useState } from 'react';
import { getToken, onMessage } from 'firebase/messaging';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { isUnreadMessageAvailableAtom } from 'jotaiAtoms/isUnreadMessageAvailableAtom';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { useInvalidateMailThreads } from 'hooks/useInvalidateMailThreads/useInvalidateMailThreads';
import { isStaffUserType } from 'utils/isStaffUserType';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { handleNetworkError } from 'utils/handleNetworkError';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts } from 'consts/text';
import { messaging } from './firebase';

const vapidKey: Record<ImportMeta['env']['VITE_APP_ENV'], string> = {
    DEV: 'BKanuE8ZxoWCudwaTAKbRqMfeh5kW1c-NbJTfpoJbMD2cwYObqL415vs7c2M69eswAQdmxJFR92BZ5ocPGHdvTk',
    UAT: 'BFKFcrnLlfdPmZJs-NIn4SVoiQSyMxA4MRUxd2BDJ47pIq-FzKtZ5BXcSC-UEerkQX8qz9iAnPzVoY8ROgP76PE',
    PROD: 'BFVmBERY9nvzykFrPsplt1JMngaIUTzb4D-bZKCjOoRsstnCWCKaqR19pNhLg_v7Ioer5AMpuZ0_7uhMC4lTTUc',
};

const UNREAD_MESSAGE_NOTIFICATION_TITLE = 'New message notification';

const SUBSCRIBE_PUSH_MANAGER_ERROR_NAME = 'AbortError';

const GET_FCM_TOKEN_ATTEMPTS_NUMBER = 3;

export const useMessaging = () => {
    const [token, setToken] = useState<string>();
    const setIsUnreadMessageAvailableState = useSetAtom(isUnreadMessageAvailableAtom);
    const userDetails = useAtomValue(userDetailsAtom);
    const { displayWarning } = useSimpleNotification();

    const { invalidateMailThreadsData } = useInvalidateMailThreads();

    const userType = userDetails?.type;
    const isParentUserType = userType ? !isStaffUserType(userType) : undefined;
    const isMessagingDisabled = !userType || userType === 'SYS_ADMIN';

    const { mutateAsync: subscribeToNotifications } = useAppMutation(
        isParentUserType
            ? ParentHTTPService.mailThreads.notifications.subscribeToUnreadMessageNotifications
            : StaffHTTPService.mailThreads.notifications.subscribeToUnreadMessageNotifications,
        {
            key: ['SUBSCRIBE_TO_UNREAD_MESSAGE_NOTIFICATIONS'],
            onError: (error) => {
                handleNetworkError(error);
            },
        },
    );

    const requestForToken = useCallback(async () => {
        try {
            const permission = await Notification.requestPermission();

            switch (permission) {
                case 'granted': {
                    const fcmToken = await getToken(messaging, {
                        vapidKey: vapidKey[import.meta.env.VITE_APP_ENV],
                    });
                    return { fcmToken, isGrantedPermission: true };
                }
                case 'denied':
                case 'default':
                default: {
                    displayWarning(commonTexts.warningMessages.notActiveNotificationsWarning);
                    return { fcmToken: undefined, isGrantedPermission: false };
                }
            }
        } catch (error) {
            const typedError = error as Error;
            if (typedError.name === SUBSCRIBE_PUSH_MANAGER_ERROR_NAME) {
                console.warn(error);
            }
            console.error(error);
        }
        return { fcmToken: undefined, isGrantedPermission: false };
    }, [displayWarning]);

    const receiveToken = useCallback(
        async (attempt = 1) => {
            const { fcmToken, isGrantedPermission } = await requestForToken();
            if (!isGrantedPermission) {
                return;
            }
            if (fcmToken) {
                setToken(fcmToken);
            } else if (attempt < GET_FCM_TOKEN_ATTEMPTS_NUMBER) {
                await receiveToken(attempt + 1);
            } else {
                displayWarning(commonTexts.warningMessages.activateNotificationsReloadPageWarning);
            }
        },
        [requestForToken, displayWarning],
    );

    useEffect(() => {
        if (isMessagingDisabled) {
            return;
        }
        receiveToken();
    }, [isMessagingDisabled, receiveToken]);

    const activateMessaging = useCallback(async () => {
        if (!token) {
            return;
        }
        await subscribeToNotifications(token);
    }, [token, subscribeToNotifications]);

    useEffect(() => {
        activateMessaging();
    }, [activateMessaging]);

    useEffect(() => {
        if (isMessagingDisabled) {
            return undefined;
        }
        return onMessage(messaging, async (payload) => {
            if (payload.notification?.title === UNREAD_MESSAGE_NOTIFICATION_TITLE) {
                setIsUnreadMessageAvailableState(true);
                await invalidateMailThreadsData();
            }
        });
    }, [setIsUnreadMessageAvailableState, isMessagingDisabled, invalidateMailThreadsData]);
};
