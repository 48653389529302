import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { TopAppMenu } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { accountMenuItems, parentAccountMenuItems } from './menuItems';

export const AccountLayout = ({ isParent }: { isParent?: boolean }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const outlet = useOutlet();

    useEffect(() => {
        if (outlet) {
            return;
        }
        navigate(
            isParent ? appPaths.app.parent.account.details.base : appPaths.app.account.details.base,
        );
    }, [outlet, navigate, isParent]);

    return (
        <>
            <TopAppMenu
                items={isParent ? parentAccountMenuItems : accountMenuItems}
                selectedKeys={[pathname]}
            />
            <Outlet />
        </>
    );
};
