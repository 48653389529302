import { ErrorDisplay } from 'components/molecules';
import { QualificationAddEditForm } from 'components/templates';
import { appPaths } from 'consts/paths/paths';
import { journalTexts } from 'consts/text';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { generatePath, useLocation } from 'react-router-dom';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { ChildId } from 'types';
import { QualificationFormFields } from 'types/forms/qualification';
import { getFullName } from 'utils/getFullName';
import { parseQualificationFormFieldsIntoAddUpdateQualificationDTO } from 'utils/parsers/qualifications/parseQualificationFormFieldsIntoAddUpdateQualificationDTO';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const SpecialJournalQualificationAdd = () => {
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const { state: specialJournalLocationState } = useLocation();
    const { childId } = specialJournalLocationState as { childId: ChildId };
    const { displaySuccess } = useSimpleNotification();

    const {
        data: childDetails,
        isFetching: isChildFetching,
        isError,
    } = useAppQuery(
        'CHILD_QUALIFICATION',
        [journalId, childId],
        () => StaffHTTPService.qualifications.getChildForQualification(journalId, childId),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const childFullName = childDetails
        ? getFullName(childDetails.firstName, childDetails.lastName)
        : undefined;

    const onAddQualificationSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.specialJournal.qualifications
                .qualificationAddSuccessNotification,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base,
                { journalId },
            ),
            {
                replace: true,
            },
        );
    };

    const {
        mutate: addQualification,
        error: addQualificationError,
        isLoading: isAddQualificationLoading,
    } = useAppMutation(StaffHTTPService.qualifications.addUpdateQualification, {
        key: ['ADD_QUALIFICATIONS_BASES'],
        onSuccess: onAddQualificationSuccess,
        invalidateQueryKeys: [['QUALIFICATIONS_BASES', journalId]],
    });

    const onAddQualificationFinish = (qualificationData: QualificationFormFields) => {
        const parsedAddQualificationDTO = parseQualificationFormFieldsIntoAddUpdateQualificationDTO(
            qualificationData,
            childId,
        );

        addQualification({
            specialJournalId: journalId,
            addUpdateQualificationDTO: parsedAddQualificationDTO,
        });
    };

    if (isError) {
        return <ErrorDisplay />;
    }

    return (
        <QualificationAddEditForm
            mode="add"
            axiosError={addQualificationError}
            isLoading={isAddQualificationLoading || isChildFetching}
            onFinish={onAddQualificationFinish}
            childFullName={childFullName}
        />
    );
};
