import { ReactNode } from 'react';
import { NoDataStateIcon } from 'assets/icons';
import { StateInfoBase } from '../StateInfoBase/StateInfoBase';

export type EmptyDisplayProps = {
    emptyInfo?: string;
    emptyAdditionalInfo?: string;
    emptyIcon?: ReactNode;
};

export const EmptyDisplay = ({
    emptyInfo,
    emptyAdditionalInfo,
    emptyIcon = <NoDataStateIcon />,
}: EmptyDisplayProps) => (
    <StateInfoBase title={emptyInfo} subTitle={emptyAdditionalInfo} icon={emptyIcon} />
);
