import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppParagraph } from 'components/atoms';
import { getFullName } from 'utils/getFullName';
import { GroupConsultationChildDTO } from 'types';
import { SpecialConsultationDTO } from 'types/specialConstultation/specialConsultationDTO';

type ConsultationChildrenDisplayProps = {
    consultationChildren: GroupConsultationChildDTO[] | SpecialConsultationDTO['childrenArray'];
};

export const ConsultationChildrenDisplay = ({
    consultationChildren,
}: ConsultationChildrenDisplayProps) => {
    if (!consultationChildren.length) {
        return EMPTY_VALUE_LABEL;
    }

    return consultationChildren.map(({ id, firstName, lastName }) => (
        <AppParagraph key={id} margin={0}>
            {getFullName(firstName, lastName)}
        </AppParagraph>
    ));
};
