import { ChildId, GroupId } from 'types';
import { getCombinedTreePickerKey } from 'utils/getCombinedTreePickerKey';

type Group = { groupId: GroupId; children: { childId: ChildId }[] };

export const parseChildIdIntoChildTreePickerKey: (
    childId: ChildId,
    groups: Group[],
) => string | undefined = (childId, groups) => {
    const selectedChildGroup = groups.find((group) =>
        group.children.some((child) => child.childId === childId),
    );
    if (!selectedChildGroup) {
        return undefined;
    }
    return getCombinedTreePickerKey(childId, selectedChildGroup.groupId);
};
