import { SettlementOverallDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { weekDaysLabels } from 'consts/weekDaysLabels';

export const parseSettlementOverallDTOIntoJsonObject: (
    settlementOverallDTO: SettlementOverallDTO,
) => object = (settlementOverallDTO) => {
    const { workingDays, recipientName, bankAccountNumber } = settlementOverallDTO;

    const parsedBankAccountNumber = bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : EMPTY_VALUE_LABEL;
    const parsedWorkingDays = workingDays.workingDays.map(
        (workingDay) => weekDaysLabels[workingDay],
    );

    return {
        [jsonObjectsTexts.settlementOverallDTO.fields.workingDays]: parsedWorkingDays,
        [jsonObjectsTexts.settlementOverallDTO.fields.bankAccountNumber]: parsedBankAccountNumber,
        [jsonObjectsTexts.settlementOverallDTO.fields.recipientName]: recipientName,
    };
};
