import styled, { createGlobalStyle, css } from 'styled-components';

export const CloseIconContainer = styled.div`
    ${({ theme }) => css`
        color: ${theme.colors.primary.primary9};
        padding: 6px;
    `}
`;

export const GlobalStyle = createGlobalStyle`
      .ant-drawer .ant-drawer-header {
          border: none;
          padding: 16px;
          .ant-drawer-header-title {
              flex-direction: row-reverse;

              button {
                  margin-inline-end: 0;
                  margin-inline-start: 12px;
              }
          }
      }
      .ant-drawer .ant-drawer-body {
          padding-top: 0;
      }
`;
