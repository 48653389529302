import { Rule } from 'antd/es/form';
import {
    AppDatePicker,
    AppDatePickerProps,
    AppFormItem,
    AppFormItemProps,
    AppFormRangeItem,
} from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { AppFormRangeItemMode } from 'types';
import { datesTimelineValidator } from 'utils/validators';
import { commonTexts } from 'consts/text';
import { DATE_LABEL } from 'consts/labels/common';
import dayjs from 'dayjs';

export type DayPeriodPickerElementProps = Omit<AppFormItemProps, 'label'> &
    Pick<AppDatePickerProps, 'onChange'> & {
        mode: AppFormRangeItemMode;
        endField: keyof typeof formFields;
        startField: keyof typeof formFields;
        label?: string;
        minDate?: string;
        maxDate?: string;
    };

export const DayPeriodPickerElement = ({
    mode,
    startField,
    endField,
    onChange,
    label,
    minDate,
    maxDate,
    ...props
}: DayPeriodPickerElementProps) => {
    const rules: Rule[] = [
        props.optional ? { required: false } : { required: true, message: formElementRequiredText },
        ({ getFieldValue }) =>
            datesTimelineValidator(getFieldValue, {
                mode,
                startValueFieldName: startField,
                finishValueFieldName: endField,
                isFinishValueBeforeStartValueErrorText:
                    commonTexts.errorMessages.finishDateMustBeAfterStartDate,
                isStartValueAfterFinishValueErrorText:
                    commonTexts.errorMessages.startDateMustBeBeforeFinishDate,
                unitToCompare: 'day',
            }),
    ];

    const labelContent = label ? (
        <AppFormItem.Label mainText={label} additionalText={DATE_LABEL} />
    ) : undefined;

    return (
        <AppFormRangeItem
            mode={mode}
            startValues={{
                name: startField,
                rules,
            }}
            finishValues={{
                name: endField,
                rules,
            }}
            label={labelContent}
            {...props}
        >
            <AppDatePicker
                autoComplete="off"
                onChange={onChange}
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
            />
        </AppFormRangeItem>
    );
};
