import React, { Dispatch } from 'react';

type Props = { selectedDate: string; setSelectedDate: Dispatch<string> };

export const AppCalendarContext = React.createContext<Props>({
    selectedDate: '',
    setSelectedDate: (newState) => {
        console.error(`FormContext is not ready. Error ${newState} has not been set`);
    },
});
