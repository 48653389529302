import { Rule } from 'antd/es/form';
import { AppDatePicker, AppFormItem, AppFormItemProps, AppFormRangeItem } from 'components/atoms';
import { DATE_LABEL } from 'consts/labels/common';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { childrenTexts } from 'consts/text';
import { AppFormRangeItemMode } from 'types';
import { datesTimelineValidator } from 'utils/validators';

export type AccountingPeriodDatePickerElementProps = AppFormItemProps & {
    mode: AppFormRangeItemMode;
};

export const AccountingPeriodDatePickerElement = ({
    mode,
    ...props
}: AccountingPeriodDatePickerElementProps) => {
    const rules: Rule[] = [
        { required: true, message: formElementRequiredText },
        ({ getFieldValue }) =>
            datesTimelineValidator(getFieldValue, {
                mode,
                startValueFieldName: formFields.accountingPeriodStartDate,
                finishValueFieldName: formFields.accountingPeriodFinishDate,
                isFinishValueBeforeStartValueErrorText:
                    childrenTexts.childForm.settlementData.settlementPeriodEndBeforeStart,
                isStartValueAfterFinishValueErrorText:
                    childrenTexts.childForm.settlementData.settlementPeriodStartAfterEnd,
                unitToCompare: 'day',
            }),
    ];

    const getFormItemLabel = (itemMode: AppFormRangeItemMode) => (
        <AppFormItem.Label
            mainText={
                itemMode === 'start'
                    ? childrenTexts.childForm.settlementData.settlementPeriodStart
                    : childrenTexts.childForm.settlementData.settlementPeriodEnd
            }
            additionalText={DATE_LABEL}
        />
    );

    return (
        <AppFormRangeItem
            mode={mode}
            startValues={{
                label: getFormItemLabel('start'),
                name: formFields.accountingPeriodStartDate,
                rules,
            }}
            finishValues={{
                label: getFormItemLabel('finish'),
                name: formFields.accountingPeriodFinishDate,
                rules,
            }}
            {...props}
        >
            <AppDatePicker autoComplete="off" />
        </AppFormRangeItem>
    );
};
