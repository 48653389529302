import { ReactElement } from 'react';
import { MenuItem, InstitutionDropdown, ChildDropdown } from 'components/atoms';
import { UserType } from 'types';
import {
    adminAccountManageMenuItems,
    staffAccountManageMenuItems,
    parentAccountManageMenuItems,
} from './accountManageMenuItems';
import {
    STAFF_MODULES_BUTTONS_DATA,
    PARENT_MODULES_BUTTONS_DATA,
    ModulesButtonsData,
    ADMIN_MODULES_BUTTONS_DATA,
} from './modulesButtons';

type SideMenuNavigationConfig = {
    modulesButtonsData: ModulesButtonsData;
    Dropdown?: ReactElement;
    accountManageItems: MenuItem[];
};

const STAFF_SIDE_MENU_NAVIGATION_CONFIG: SideMenuNavigationConfig = {
    modulesButtonsData: STAFF_MODULES_BUTTONS_DATA,
    Dropdown: <InstitutionDropdown />,
    accountManageItems: staffAccountManageMenuItems,
};

const PARENT_SIDE_MENU_NAVIGATION_CONFIG: SideMenuNavigationConfig = {
    modulesButtonsData: PARENT_MODULES_BUTTONS_DATA,
    Dropdown: <ChildDropdown />,
    accountManageItems: parentAccountManageMenuItems,
};

const SYS_ADMIN_SIDE_MENU_NAVIGATION_CONFIG: SideMenuNavigationConfig = {
    modulesButtonsData: ADMIN_MODULES_BUTTONS_DATA,
    accountManageItems: adminAccountManageMenuItems,
};

export const getSideMenuNavigationConfig = (userType: UserType) => {
    switch (userType) {
        case 'PARENT':
            return PARENT_SIDE_MENU_NAVIGATION_CONFIG;
        case 'SYS_ADMIN':
            return SYS_ADMIN_SIDE_MENU_NAVIGATION_CONFIG;
        case 'EMPLOYEE':
        case 'OWNER':
        default:
            return STAFF_SIDE_MENU_NAVIGATION_CONFIG;
    }
};
