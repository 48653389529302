import { appPaths } from 'consts/paths/paths';
import { useMatch } from 'react-router-dom';
import { RoleId, RoleModifiedData } from 'types';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { formFields } from 'consts/form/formFields';
import { Form } from 'antd';
import { RolesAndPermissionsAddEditForm } from 'components/templates';
import {
    modulesEditAtom,
    permissionsEditAtom,
} from 'components/templates/settings/rolesAndPermissions/RolesAndPermissionsAddEditForm/roleAndPermissionState';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useRolesAndPermissionsEditPageNetworkManage } from './hooks';

export const RolesAndPermissionsEditPage = () => {
    const { statefulNavigate } = useNavigateWithState();
    const params = useMatch(appPaths.app.settings['roles-and-permissions'].edit.pickedRole);
    const roleId = params?.params.roleId as RoleId;
    const setPermissions = useSetAtom(permissionsEditAtom);
    const setModules = useSetAtom(modulesEditAtom);
    const [form] = Form.useForm();
    const {
        isRoleUpdating,
        updateRole,
        isError: isRoleUpdatingError,
        isRoleFetching,
        roleDetails,
    } = useRolesAndPermissionsEditPageNetworkManage(roleId);

    useEffect(() => {
        if (roleDetails) {
            setPermissions(roleDetails.permissions);
            setModules(roleDetails.modules);
            form.setFieldValue(formFields.name, roleDetails.name);
        }
    }, [form, roleDetails, setModules, setPermissions]);

    const handleCreateNewRole = useCallback(
        (roleModifiedData: RoleModifiedData) => {
            if (!roleDetails?.id) {
                return;
            }
            updateRole({ id: roleDetails.id, ...roleModifiedData });
        },
        [roleDetails?.id, updateRole],
    );

    if (!roleId) {
        statefulNavigate(appPaths.app.settings['roles-and-permissions'].base, { replace: true });
    }

    return (
        <RolesAndPermissionsAddEditForm
            form={form}
            isRoleUpdatingError={isRoleUpdatingError}
            isRoleFetching={isRoleFetching}
            onSave={handleCreateNewRole}
            isMinorLoading={isRoleUpdating}
            roleDetails={roleDetails}
            mode="edit"
        />
    );
};
