import { ReactNode } from 'react';
import { IconEdit } from '@tabler/icons-react';
import { AppButton, DetailsItem, AppGoBackButton } from 'components/atoms';
import { ErrorDisplay, LoadingDisplay } from 'components/molecules';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { commonTexts } from 'consts/text/index';

import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import {
    GoBackButtonContainer,
    InnerContainer,
    OuterContainer,
    StyledTitle,
} from './Details.styled';

type DetailsProps = {
    title: string;
    editPagePath?: string;
    isLoading?: boolean;
    isError?: boolean;
    isVisibleGoBackButton?: boolean;
    children: ReactNode;
    editPageNavigateState?: unknown;
};

export const Details = ({
    title,
    editPagePath,
    isLoading,
    isError,
    isVisibleGoBackButton = true,
    children,
    editPageNavigateState,
}: DetailsProps) => {
    const { statefulNavigate } = useNavigateWithState();
    const isMobile = useIsMobile();

    const goToEditPage = () => {
        if (!editPagePath) {
            return;
        }
        statefulNavigate(editPagePath, { state: editPageNavigateState });
    };

    const renderContent = () => {
        if (isError) {
            return <ErrorDisplay />;
        }
        if (isLoading) {
            return <LoadingDisplay />;
        }
        return children;
    };

    return (
        <>
            {isVisibleGoBackButton && (
                <GoBackButtonContainer>
                    <AppGoBackButton margin={0} />
                </GoBackButtonContainer>
            )}
            <OuterContainer>
                <StyledTitle level={4}>{title}</StyledTitle>
                <InnerContainer>{renderContent()}</InnerContainer>
                {editPagePath && (
                    <AppButton
                        type="primary"
                        icon={<IconEdit size={20} />}
                        margin={0}
                        fullWidth={isMobile}
                        onClick={goToEditPage}
                    >
                        {commonTexts.actionLabels.edit}
                    </AppButton>
                )}
            </OuterContainer>
        </>
    );
};

Details.Item = DetailsItem;
