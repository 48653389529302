import { ErrorStateIcon } from 'assets/icons';
import { commonTexts } from 'consts/text';
import { StateInfoBase, StateInfoBaseProps } from '../StateInfoBase/StateInfoBase';

type ErrorDisplayProps = Pick<StateInfoBaseProps, 'title' | 'className'> & {
    hideIcon?: boolean;
    hideSubTitle?: boolean;
};

export const ErrorDisplay = ({
    title = commonTexts.errorMessages.errorStateDisplay.title,
    className,
    hideIcon,
    hideSubTitle,
}: ErrorDisplayProps) => (
    <StateInfoBase
        className={className}
        title={title}
        subTitle={hideSubTitle ? undefined : commonTexts.errorMessages.errorStateDisplay.subTitle}
        icon={hideIcon ? undefined : <ErrorStateIcon />}
    />
);
