/* eslint-disable @typescript-eslint/naming-convention */
// @ts-ignore
type AppRouteStructure = Record<string, string | AppRouteStructure>;

const staffAppRoutes = {
    'add-institution': 'add-institution',
    'edit-invoice-details': 'edit-invoice-details',
    employees: {
        base: 'employees',
        edit: {
            base: 'edit',
            pickedEmployee: ':employeeId',
        },
        add: 'add',
    },
    children: {
        base: 'children',
        add: 'add',
        details: {
            base: 'details',
            pickedChild: {
                base: ':childId',
                'add-discount': { base: 'add-discount' },
                'edit-discount': { base: 'edit-discount', pickedDiscount: ':discountId' },
            },
        },
        edit: {
            base: 'edit',
            pickedChild: ':childId',
        },
    },
    groups: {
        base: 'groups',
        add: 'add',
        edit: {
            base: 'edit',
            pickedGroup: ':groupId',
        },
    },
    journals: {
        base: 'journals',
        add: 'add',
        edit: {
            base: 'edit',
            pickedJournal: ':journalId',
        },
        groupJournalDetails: {
            base: 'groupJournalDetails',
            pickedJournal: {
                base: ':journalId',
                attendance: {
                    base: 'attendance',
                    daily: {
                        base: 'daily',
                    },
                    monthly: {
                        base: 'monthly',
                    },
                },
                schedules: {
                    base: 'schedules',
                    daily: {
                        base: 'daily',
                        add: 'add',
                        edit: 'edit',
                    },
                    weekly: {
                        base: 'weekly',
                        add: 'add',
                        edit: 'edit',
                    },
                },
                children: {
                    base: 'children',
                    details: {
                        base: 'details',
                        pickedChild: {
                            base: ':childId',
                            'add-discount': { base: 'add-discount' },
                            'edit-discount': {
                                base: 'edit-discount',
                                pickedDiscount: ':discountId',
                            },
                        },
                    },
                    edit: {
                        base: 'edit',
                        pickedChild: ':childId',
                    },
                },
                topics: {
                    base: 'topics',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedTopic: ':topicId',
                    },
                },
                events: {
                    base: 'events',
                    importantEvents: {
                        base: 'importantEvents',
                        add: 'add',
                        edit: {
                            base: 'edit',
                            pickedImportantEvent: ':importantEventId',
                        },
                        details: {
                            base: 'details',
                            pickedImportantEvent: ':importantEventId',
                        },
                    },
                    trips: {
                        base: 'trips',
                        add: 'add',
                        edit: {
                            base: 'edit',
                            pickedTrip: ':tripId',
                        },
                        details: {
                            base: 'details',
                            pickedTrip: ':tripId',
                        },
                    },
                    parentTeacherConferences: {
                        base: 'parentTeacherConferences',
                        add: 'add',
                        edit: {
                            base: 'edit',
                            pickedParentTeacherConference: ':parentTeacherConferenceId',
                        },
                        details: {
                            base: 'details',
                            pickedParentTeacherConference: ':parentTeacherConferenceId',
                        },
                    },
                },
                consultations: {
                    base: 'consultations',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedConsultation: ':consultationId',
                    },
                    details: {
                        base: 'details',
                        pickedConsultation: ':consultationId',
                    },
                },
                inspections: {
                    base: 'inspections',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedInspection: ':inspectionId',
                    },
                    details: {
                        base: 'details',
                        pickedInspection: ':inspectionId',
                    },
                },
                psychologicalHelps: {
                    base: 'psychologicalHelps',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedPsychologicalHelp: ':psychologicalHelpId',
                    },
                    details: {
                        base: 'details',
                        pickedPsychologicalHelp: ':psychologicalHelpId',
                    },
                },
            },
        },
        specialJournalDetails: {
            base: 'specialJournalDetails',
            pickedJournal: {
                base: ':journalId',
                attendance: {
                    base: 'attendance',
                    daily: {
                        base: 'daily',
                    },
                    monthly: {
                        base: 'monthly',
                    },
                },
                lessons: {
                    base: 'lessons',
                },
                children: {
                    base: 'children',
                    details: {
                        base: 'details',
                        pickedChild: {
                            base: ':childId',
                            'add-discount': { base: 'add-discount' },
                            'edit-discount': {
                                base: 'edit-discount',
                                pickedDiscount: ':discountId',
                            },
                        },
                    },
                    edit: {
                        base: 'edit',
                        pickedChild: ':childId',
                    },
                },
                topics: {
                    base: 'topics',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedTopic: ':topicId',
                    },
                },
                consultations: {
                    base: 'consultations',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedConsultation: ':consultationId',
                    },
                    details: {
                        base: 'details',
                        pickedConsultation: ':consultationId',
                    },
                },
                inspections: {
                    base: 'inspections',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedInspection: ':inspectionId',
                    },
                    details: {
                        base: 'details',
                        pickedInspection: ':inspectionId',
                    },
                },
                qualifications: {
                    base: 'qualifications',
                    add: 'add',
                    edit: {
                        base: 'edit',
                        pickedQualification: ':qualificationId',
                    },
                    details: {
                        base: 'details',
                        pickedQualification: ':qualificationId',
                    },
                },
            },
        },
    },
    settlement: {
        base: 'settlement',
        children: {
            base: 'children',
            pickedChild: {
                base: ':childId',
                details: {
                    base: 'details',
                    payment: {
                        base: 'payment',
                        edit: { base: 'edit', pickedPayment: ':paymentId' },
                        add: 'add',
                    },
                },
                'cost-allocation': {
                    base: 'cost-allocation',
                    edit: { base: 'edit', pickedCostAllocation: ':costAllocationId' },
                    add: { base: 'add' },
                },
                payment: {
                    base: 'payment',
                    edit: { base: 'edit', pickedPayment: ':paymentId' },
                    add: 'add',
                },
            },
        },
        staff: {
            base: 'staff',
        },
        additionalLessons: {
            base: 'additionalLessons',
        },
    },
    menu: {
        base: 'menu',
        add: 'add',
        edit: {
            base: 'edit',
            pickedMenu: ':menuId',
        },
        details: {
            base: 'details',
            pickedMenu: ':menuId',
        },
    },
    timetable: {
        base: 'timetable',
        employeeSchedule: {
            base: 'employeeSchedule',
            add: 'add',
            edit: {
                base: 'edit',
                pickedLesson: ':lessonId',
            },
            absence: {
                base: 'absence',
                add: 'add',
                edit: {
                    base: 'edit',
                    pickedAbsence: ':absenceId',
                },
            },
        },
        workingTime: {
            base: 'workingTime',
            add: 'add',
            edit: {
                base: 'edit',
                pickedWorkingTime: ':workingTimeId',
            },
        },
        evidenceWorkingTime: {
            base: 'evidenceWorkingTime',
        },
    },
    messages: {
        base: 'messages',
        add: 'add',
        pickedMailThread: ':mailThreadId',
    },
    'remote-lessons': {
        base: 'remote-lessons',
        add: 'add',
        edit: {
            base: 'edit',
            pickedRemoteLesson: ':remoteLessonId',
        },
        details: {
            base: 'details',
            pickedRemoteLesson: ':remoteLessonId',
        },
    },
    calendar: {
        base: 'calendar',
        add: 'add',
        edit: {
            base: 'edit',
            pickedEvent: ':eventId',
        },
        details: {
            base: 'details',
            pickedEvent: ':eventId',
        },
    },
    settings: {
        base: 'settings',
        'institution-details': {
            base: 'institution-details',
            edit: 'edit',
        },
        'principal-details': 'principal-details',
        'invoice-details': {
            base: 'invoice-details',
            edit: 'edit',
        },
        settlements: {
            base: 'settlements',
            general: {
                base: 'general',
            },
            stays: {
                base: 'stays',
            },
            meals: {
                base: 'meals',
            },
        },
        diets: {
            base: 'diets',
            add: 'add',
            edit: {
                base: 'edit',
                pickedDiet: ':dietId',
            },
        },
        'roles-and-permissions': {
            base: 'roles-and-permissions',
            edit: { base: 'edit', pickedRole: ':roleId' },
            add: 'add',
        },
        'history-of-changes': 'history-of-changes',
        payments: 'payments',
        'your-account-type': 'your-account-type',
    },
    account: {
        base: 'account',
        details: {
            base: 'details',
            edit: 'edit',
        },
        'password-update': 'password-update',
    },
};

const parentAppRoutes = {
    base: 'parent',
    news: {
        base: 'news',
        attendance: {
            base: 'attendance',
            'add-absent': 'add-absent',
        },
        calendar: 'calendar',
        'daily-schedule': 'daily-schedule',
        'weekly-schedule': 'weekly-schedule',
        lessons: 'lessons',
    },
    'child-profile': {
        base: 'child-profile',
        edit: 'edit',
    },
    menu: 'menu',
    settlement: {
        base: 'settlement',
        payment: 'payment',
    },
    messages: {
        base: 'messages',
        add: 'add',
        pickedMailThread: ':mailThreadId',
    },
    account: {
        base: 'account',
        details: {
            base: 'details',
            edit: 'edit',
        },
        'password-update': 'password-update',
    },
};

const adminAppRoutes = {
    base: 'admin',
    institutions: {
        base: 'institutions',
        details: {
            base: 'details',
            pickedInstitution: {
                base: ':subscriptionId',
            },
        },
        'add-subscription': 'add-subscription',
        'edit-subscription': 'edit-subscription',
    },
};

export const appRoutes = {
    base: '/',
    app: {
        base: 'app',
        'user-confirmation': 'user-confirmation',
        ...staffAppRoutes,
        parent: { ...parentAppRoutes },
        admin: { ...adminAppRoutes },
    },
    auth: {
        base: 'authorization',
        'sign-in': 'sign-in',
        'sign-up': 'sign-up',
        'password-reset-request': 'password-reset-request',
        'activate-account-link-expired': 'activate-account-link-expired',
        'password-change': 'password-change',
        'auth-action-management': 'auth-action-management',
        legals: {
            base: 'legals',
            'terms-of-use': 'terms-of-use',
            'privacy-policy': 'privacy-policy',
        },
    },
    error: {
        base: 'error',
        'not-found': 'not-found',
        'no-permissions': 'no-permissions',
        'not-authorized': 'notAuthorized',
    },
};

export const getAppPaths = (routeNestedLevel: AppRouteStructure, upperPath: string = '') => {
    if (typeof routeNestedLevel.base !== 'string') {
        throw new Error('The new route level must contain a base key');
    }
    const shouldAddSlash = upperPath !== '/' && upperPath;
    const routeLevel = shouldAddSlash
        ? `${upperPath}/${routeNestedLevel.base}`
        : `${upperPath}${routeNestedLevel.base}`;
    return Object.entries(routeNestedLevel).reduce<AppRouteStructure>(
        (previousValue, [pathKey, pathDisplayValue]) => {
            let currentLevel = routeLevel;
            if (typeof pathDisplayValue === 'object') {
                currentLevel = getAppPaths(pathDisplayValue, routeLevel);
            } else if (pathKey !== 'base') {
                currentLevel = `${routeLevel}/${pathDisplayValue}`;
            }

            return { ...previousValue, [pathKey]: currentLevel };
        },
        {},
    );
};
export const appPaths: typeof appRoutes = getAppPaths(appRoutes);

export const LOGOUT_PATH = 'logout';
