import { useTheme } from 'styled-components';
import { AppParagraph, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { Container } from './WithAccountCreationOrNoPicker.styled';

export type WithAccountCreationOrNoPickerValue =
    | 'WITH_ACCOUNT_CREATION'
    | 'WITHOUT_ACCOUNT_CREATION';

type WithAccountCreationOrNoPickerProps = {
    selectedValue: WithAccountCreationOrNoPickerValue;
    onClickRadioButton: (value: WithAccountCreationOrNoPickerValue) => void;
};

const items: { label: string; value: WithAccountCreationOrNoPickerValue }[] = [
    {
        label: 'Otwórz konto',
        value: 'WITH_ACCOUNT_CREATION',
    },
    {
        label: 'Bez konta',
        value: 'WITHOUT_ACCOUNT_CREATION',
    },
];

export const WithAccountCreationOrNoPicker = ({
    selectedValue,
    onClickRadioButton,
}: WithAccountCreationOrNoPickerProps) => {
    const theme = useTheme();

    const radioElements = items.map(({ value, label }) => (
        <AppRadioButton key={value} value={value} onClick={() => onClickRadioButton(value)}>
            {label}
        </AppRadioButton>
    ));
    return (
        <Container>
            <AppRadioGroup value={selectedValue}>{radioElements}</AppRadioGroup>
            <AppParagraph
                textType="BodySmall"
                margin={0}
                marginTop={12}
                color={theme.colors.grayscale.gray9}
            >
                Jeżeli chcesz założyć konto rodzica.
            </AppParagraph>
        </Container>
    );
};
