import { AppColumnsType } from 'types';
import { ColumnsType } from 'antd/es/table/interface';

export const parseAppColumnsTypeIntoColumnsType: <TableType>(
    desktopColumns: AppColumnsType<TableType>,
    mobileColumns: AppColumnsType<TableType>,
    isMobile: boolean,
) => ColumnsType<TableType> = (desktopColumns, mobileColumns, isMobile) => {
    const columns = isMobile ? mobileColumns : desktopColumns;
    return columns.map(({ width, mobileWidth, render, renderMobile, ...columnsType }) => ({
        width: isMobile && mobileWidth ? mobileWidth : width,
        render: isMobile && renderMobile ? renderMobile : render,
        ...columnsType,
    }));
};
