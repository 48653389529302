import { AppSearchInput, AppText } from 'components/atoms';
import React from 'react';
import { commonTexts } from 'consts/text';
import { Container } from './SearchInput.styled';

type SearchInputProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput = ({ onChange }: SearchInputProps) => (
    <Container>
        <AppText margin={0} textType="BodyMSSemiBold">
            {commonTexts.actionLabels.search}
        </AppText>
        <AppSearchInput onChange={onChange} />
    </Container>
);
