import { Child } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { childSexLabels } from 'consts/child/childSexLabels';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseAddressIntoJsonObject } from 'utils/parsers/jsonObject/common/parseAddressIntoJsonObject';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parseChildParentIntoJsonObject } from 'utils/parsers/jsonObject/parent/parseChildParentIntoJsonObject';
import { parseChildGuardianIntoJsonObject } from 'utils/parsers/jsonObject/guardian/parseChildGuardianIntoJsonObject';
import { childStatusesLabels } from 'consts/child/childStatusesLabels';
import { parseChildGroupIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildGroupIntoJsonObject';
import { parseAgreementsIntoJsonObject } from 'utils/parsers/jsonObject/child/parseAgreementsIntoJsonObject';

export const parseChildIntoJsonObject: (child: Child) => object = (child) => {
    const {
        firstName,
        lastName,
        pesel,
        sex,
        birthDate,
        birthPlace,
        address,
        groups,
        photoUri,
        status,
        agreements,
        allergies,
        contractValid,
        diseases,
        parents,
        guardians,
    } = child;

    const groupJsonObjects = groups.map(parseChildGroupIntoJsonObject);
    const parsedBirthDate = parseStringIntoFormattedStringDate(birthDate);
    const addressJsonObject = address ? parseAddressIntoJsonObject(address) : EMPTY_VALUE_LABEL;
    const agreementsJsonObject = parseAgreementsIntoJsonObject(agreements);
    const parentJsonObjects = parents.map(parseChildParentIntoJsonObject);
    const guardianJsonObjects = guardians.map(parseChildGuardianIntoJsonObject);

    return {
        [jsonObjectsTexts.child.fields.firstName]: firstName,
        [jsonObjectsTexts.child.fields.lastName]: lastName,
        [jsonObjectsTexts.child.fields.sex]: childSexLabels[sex],
        [jsonObjectsTexts.child.fields.pesel]: pesel,
        [jsonObjectsTexts.child.fields.groups]: groupJsonObjects,
        [jsonObjectsTexts.child.fields.birthPlace]: birthPlace,
        [jsonObjectsTexts.child.fields.birthDate]: parsedBirthDate,
        [jsonObjectsTexts.child.fields.address]: addressJsonObject,
        [jsonObjectsTexts.child.fields.diseases]: diseases || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.child.fields.allergies]: allergies || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.child.fields.agreements]: agreementsJsonObject,
        [jsonObjectsTexts.child.fields.photoUri]: photoUri || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.child.fields.status]: childStatusesLabels[status],
        [jsonObjectsTexts.child.fields.contractValid]: contractValid
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.child.fields.parents]: parentJsonObjects,
        [jsonObjectsTexts.child.fields.guardians]: guardianJsonObjects,
    };
};
