import { useParams } from 'react-router-dom';
import { QualificationId } from 'types';

export const useQualificationId = () => {
    const { qualificationId: qualificationIdParam } = useParams();

    const qualificationId = (qualificationIdParam || '') as QualificationId;

    return { qualificationId };
};
