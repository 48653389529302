import { useEffect } from 'react';
import { Form } from 'antd';
import {
    AllergensInputElement,
    DiseasesInputElement,
    FormNavButtons,
    SocialNetworksImageSharingAgreementPicker,
} from 'components/molecules';
import { AppForm, DietPicker } from 'components/organisms';
import { FormPageModeDependedTexts, FormPageProps } from 'types';
import { formFields } from 'consts/form/formFields';
import { childrenTexts } from 'consts/text/index';
import staffHTTPService from 'services/HTTPService/staff/StaffHTTPService';

const addModeTexts: FormPageModeDependedTexts = {
    title: childrenTexts.addMoreInformation,
    buttonText: childrenTexts.addChildrenButtonLabel,
};

export const ChildAddEditStep3 = ({ enforcedInitialValues, ...props }: FormPageProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (enforcedInitialValues && !form.getFieldValue(formFields.diet)) {
            form.setFieldsValue(enforcedInitialValues);
        }
    }, [enforcedInitialValues, form]);

    const { title, buttonText, buttonIcon } = addModeTexts;

    return (
        <AppForm form={form} title={title} desktopTitleLevel={4} isFormHeaderAtBottom {...props}>
            <DietPicker httpMethod={{ getDiets: staffHTTPService.diets.getDiets }} />
            <DiseasesInputElement optional hasOptionalText />
            <AllergensInputElement optional hasOptionalText />
            <SocialNetworksImageSharingAgreementPicker />
            <FormNavButtons
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus
                withGoBackButton
            />
        </AppForm>
    );
};
