import { Drawer, DrawerProps } from 'antd';
import { IconX } from '@tabler/icons-react';
import { CloseIconContainer, GlobalStyle } from './AppDrawer.styled';

export const AppDrawer = ({ ...props }: DrawerProps) => (
    <>
        <GlobalStyle />
        <Drawer
            width="100%"
            placement="right"
            closeIcon={
                <CloseIconContainer>
                    <IconX size={20} />
                </CloseIconContainer>
            }
            {...props}
        />
    </>
);
