import AxiosBaseInstance from 'services/HTTPService/AxiosBaseInstance';
import {
    FullInstitutionSubscriptionDTO,
    SubscriptionId,
    SubscriptionInstitutionAndOwnerDTO,
    InstitutionId,
    CreateSubscriptionDTO,
    SubscriptionDTO,
    UpdateSubscriptionDTO,
} from 'types';
import {
    CLIENT_SUBSCRIPTIONS,
    CLIENT_SUBSCRIPTION,
    BLOCK_CLIENT_SUBSCRIPTION,
    ADD_CLIENT_SUBSCRIPTION,
} from 'services/HTTPService/admin/endpoints/clientSubscriptions';

class AdminHTTPService {
    private axios = new AxiosBaseInstance();

    public clientSubscriptions = {
        getInstitutionSubscriptionDataItems: (search?: string) =>
            this.axios.get<FullInstitutionSubscriptionDTO[]>(CLIENT_SUBSCRIPTIONS, {
                params: { search },
            }),
        getInstitutionSubscriptionDataItem: (subscriptionId: SubscriptionId) =>
            this.axios.get<SubscriptionInstitutionAndOwnerDTO>(CLIENT_SUBSCRIPTION(subscriptionId)),
        addSubscription: ({
            createSubscriptionDTO,
            institutionId,
        }: {
            createSubscriptionDTO: CreateSubscriptionDTO;
            institutionId: InstitutionId;
        }) =>
            this.axios.put<SubscriptionDTO>(ADD_CLIENT_SUBSCRIPTION, createSubscriptionDTO, {
                params: { institutionId },
            }),
        editSubscription: ({
            updateSubscriptionDTO,
            institutionId,
            subscriptionId,
        }: {
            updateSubscriptionDTO: UpdateSubscriptionDTO;
            institutionId: InstitutionId;
            subscriptionId: SubscriptionId;
        }) =>
            this.axios.put<SubscriptionDTO>(
                CLIENT_SUBSCRIPTION(subscriptionId),
                updateSubscriptionDTO,
                { params: { institutionId } },
            ),
        blockSubscription: (institutionId: InstitutionId) =>
            this.axios.patch<SubscriptionDTO>(BLOCK_CLIENT_SUBSCRIPTION, null, {
                params: { institutionId },
            }),
    };
}

export default new AdminHTTPService();
