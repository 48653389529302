import { PermissionName } from 'types';
import { appPaths } from 'consts/paths/paths';

export const GROUP_JOURNAL_DETAILS_PATHS_BY_PERMISSION: Partial<Record<PermissionName, string>> = {
    GROUP_JOURNALS_ATTENDANCE:
        appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.daily.base,
    GROUP_JOURNALS_SCHEDULE:
        appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.base,
    GROUP_JOURNALS_CHILDREN_LIST_VIEW:
        appPaths.app.journals.groupJournalDetails.pickedJournal.children.base,
    GROUP_JOURNALS_TOPICS: appPaths.app.journals.groupJournalDetails.pickedJournal.topics.base,
    GROUP_JOURNALS_EVENTS:
        appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.base,
    GROUP_JOURNALS_CONSULTATIONS:
        appPaths.app.journals.groupJournalDetails.pickedJournal.consultations.base,
    GROUP_JOURNALS_INSPECTIONS:
        appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.base,
    GROUP_JOURNALS_PSYCHOLOGICAL_HELP:
        appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base,
};

export const SPECIAL_JOURNAL_DETAILS_PATHS_BY_PERMISSION: Partial<Record<PermissionName, string>> =
    {
        SPECIAL_JOURNALS_ATTENDANCE:
            appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.daily.base,
        SPECIAL_JOURNALS_TIMETABLE_VIEW:
            appPaths.app.journals.specialJournalDetails.pickedJournal.lessons.base,
        SPECIAL_JOURNALS_CHILDREN_LIST_VIEW:
            appPaths.app.journals.specialJournalDetails.pickedJournal.children.base,
        SPECIAL_JOURNALS_TOPICS:
            appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base,
        SPECIAL_JOURNALS_CONSULTATIONS:
            appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.base,
        SPECIAL_JOURNALS_INSPECTIONS:
            appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base,
        SPECIAL_JOURNALS_QUALIFICATION_BASIS:
            appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base,
    };
