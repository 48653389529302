import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { MenusAddPage, MenusDetailsPage, MenusEditPage, MenusPage } from 'pages';

export const menuRoutes: RouteObject[] = [
    {
        path: appPaths.app.menu.base,
        element: (
            <AbilityRoute can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.DIET_CONFIG }}>
                <MenusPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.menu.add,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.DIET_CONFIG }}
            >
                <MenusAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.menu.edit.base,
        element: <Navigate to={appPaths.app.menu.base} />,
    },
    {
        path: appPaths.app.menu.edit.pickedMenu,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.DIET_CONFIG }}
            >
                <MenusEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.menu.details.pickedMenu,
        element: (
            <AbilityRoute can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.DIET_CONFIG }}>
                <MenusDetailsPage />
            </AbilityRoute>
        ),
    },
];
