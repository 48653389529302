import { RoleModifiedData } from 'types';
import { RolesAndPermissionsAddEditForm } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useCallback } from 'react';
import { appPaths } from 'consts/paths/paths';
import { settingsTexts } from 'consts/text';
import useForm from 'antd/es/form/hooks/useForm';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const RolesAndPermissionsAddPage = () => {
    const { displaySuccess, displayError } = useSimpleNotification();
    const [form] = useForm();
    const { statefulNavigate } = useNavigateWithState();

    const { isLoading: isNewRoleCreating, mutate: createNewRole } = useAppMutation(
        StaffHTTPService.roles.postRoles,
        {
            onSuccess: () => {
                statefulNavigate(appPaths.app.settings['roles-and-permissions'].base);
                displaySuccess(settingsTexts.rolesAndPermissions.role.added);
            },
            onError: () => displayError(settingsTexts.rolesAndPermissions.role.addingError),
            key: ['ROLE_CREATE'],
            invalidateQueryKeys: [['ROLES']],
        },
    );

    const handleCreateNewRole = useCallback(
        (roleModifiedData: RoleModifiedData) => createNewRole({ ...roleModifiedData }),
        [createNewRole],
    );

    return (
        <RolesAndPermissionsAddEditForm
            onSave={handleCreateNewRole}
            isMinorLoading={isNewRoleCreating}
            mode="add"
            form={form}
        />
    );
};
