import { ChildId, GuardianId, ParentId } from 'types';

export const CHILDREN = '/api/v1/children';
export const CHILD_LIMIT = '/api/v1/children/children';
export const CHILD_DETAILS = (childId: ChildId) => `${CHILDREN}/${childId}`;
export const ARCHIVE_CHILD = (childId: ChildId) => `${CHILD_DETAILS(childId)}/archive`;
export const RESTORE_CHILD = (childId: ChildId) => `${CHILD_DETAILS(childId)}/unarchive`;
export const CHILD_PROFILE_PHOTO = (childId: ChildId) => `${CHILD_DETAILS(childId)}/profile-photo`;
export const CHILD_GUARDIAN_BASE = (childId: ChildId) => `${CHILD_DETAILS(childId)}/guardians`;
export const CHILD_GUARDIAN = (childId: ChildId, guardianId: GuardianId) =>
    `${CHILD_GUARDIAN_BASE(childId)}/${guardianId}`;
export const CHILD_PARENT_BASE = (childId: ChildId) => `${CHILD_DETAILS(childId)}/parents`;
export const CHILD_PARENT = (childId: ChildId, parentId: ParentId) =>
    `${CHILD_PARENT_BASE(childId)}/${parentId}`;
