import { useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import type { UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { commonTexts } from 'consts/text/index';
import { IMAGE_FORMATS } from 'consts/file/fileFormats';
import { StyledUpload } from './AppAvatarUpload.styled';
import { UploadButton } from './components';
import { AppText } from '../AppText/AppText';
import { AppImage } from '../AppImage/AppImage';
import { AppLoader } from '../AppLoader/AppLoader';

export type AppAvatarUploadRequestOptions = UploadRequestOption;
export type AppAvatarUploadFile = UploadFile;

export type AppAvatarUploadProps = Pick<UploadProps, 'beforeUpload'> & {
    uploadedFile?: AppAvatarUploadFile | null;
    isLoading?: boolean;
    canManageAvatar?: boolean;
    onUpload?: UploadProps['customRequest'];
    onRemove?: (file: AppAvatarUploadFile) => void | boolean | Promise<void | boolean>;
};

const NoImageInfo = () => (
    <AppText textType="BodyMSSemiBold" margin={0}>
        {commonTexts.dataLabels.noImage}
    </AppText>
);

export const AppAvatarUpload = ({
    uploadedFile,
    isLoading,
    canManageAvatar,
    beforeUpload,
    onUpload,
    onRemove,
}: AppAvatarUploadProps) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreview = async (previewFile: UploadFile) => {
        if (!previewFile.url) {
            return;
        }
        setPreviewImage(previewFile.url);
        setPreviewOpen(true);
    };

    const renderStyledUploadContent = () => {
        if (isLoading) {
            return <AppLoader size={34} />;
        } else if (!uploadedFile) {
            return canManageAvatar ? <UploadButton /> : <NoImageInfo />;
        }
        return null;
    };

    return (
        <>
            <StyledUpload
                accept={IMAGE_FORMATS}
                listType="picture-circle"
                fileList={uploadedFile && !isLoading ? [uploadedFile] : []}
                customRequest={onUpload}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onRemove={onRemove}
                disabled={!canManageAvatar}
            >
                {renderStyledUploadContent()}
            </StyledUpload>
            <AppImage
                src={previewImage}
                alt="Child profile photo"
                style={{ display: 'none' }}
                preview={{
                    visible: previewOpen,
                    onVisibleChange: () => setPreviewOpen(false),
                }}
            />
        </>
    );
};
