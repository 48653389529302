import { ModalTreePickerElement } from 'components/molecules';
import React, { useContext, useMemo, useState } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { AppFormContext } from 'contexts/AppFormContext';
import { CoreCurriculumDTO, CoreCurriculumName } from 'types';
import { formFields } from 'consts/form/formFields';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { AppFormItem, AppSelect } from 'components/atoms';
import { useWatch } from 'antd/es/form/Form';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { journalTexts } from 'consts/text';
import { IconPlus } from '@tabler/icons-react';
import {
    mapCoreCurriculumListToCoreCurriculumNamesList,
    parseCoreCurriculumDataToTreeNodes,
} from './utils';

export const TopicCoreCurriculumPicker = () => {
    const { setFieldValue } = useFormInstance();
    const { formError, setFormError } = useContext(AppFormContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const curriculumsSelectValue =
        useWatch<CoreCurriculumName[] | undefined>(formFields.coreCurriculums) || [];

    const {
        data: coreCurriculumData,
        isFetching: isCoreCurriculumDataFetching,
        isError: isCoreCurriculumDataError,
    } = useAppQuery(
        'CORE_CURRICULUM',
        [],
        () => StaffHTTPService.coreCurriculums.getCoreCurriculums(),
        { staleTime: Infinity },
    );

    useDisplayFetchDataError({
        isFetching: isCoreCurriculumDataFetching,
        isError: isCoreCurriculumDataError,
        actualError: formError,
        errorMessage:
            journalTexts.journalDetails.groupJournal.topics.fetchingCoreCurriculumDataError,
        setError: setFormError,
    });

    const parsedCoreCurriculumData: CoreCurriculumDTO[] = useMemo(
        () => coreCurriculumData || [],
        [coreCurriculumData],
    );

    const onClearHandler = () => setFieldValue(formFields.coreCurriculums, null);
    const handleOpenModal = () => setIsModalOpen(true);

    const handleCloseModal = () => setIsModalOpen(false);

    const onSaveHandler = (values: React.Key[]) => {
        const coreCurriculumNames: React.Key[] =
            mapCoreCurriculumListToCoreCurriculumNamesList(parsedCoreCurriculumData);
        const filteredSelectedValues = values.filter((value) =>
            coreCurriculumNames.includes(value),
        );

        setFieldValue(formFields.coreCurriculums, filteredSelectedValues);
        handleCloseModal();
    };

    const mappedCoreCurriculumDataForTree = useMemo(
        () => parseCoreCurriculumDataToTreeNodes(parsedCoreCurriculumData),
        [parsedCoreCurriculumData],
    );

    return (
        <>
            <ModalTreePickerElement
                title={journalTexts.journalDetails.groupJournal.topics.coreCurriculum}
                open={isModalOpen}
                onCancel={handleCloseModal}
                onSave={onSaveHandler}
                treeData={mappedCoreCurriculumDataForTree}
                initialValue={curriculumsSelectValue}
                isLoading={isCoreCurriculumDataFetching}
                width={800}
                showCheckAll={false}
                showSearchBar={false}
            />
            <AppFormItem
                label={journalTexts.journalDetails.groupJournal.topics.coreCurriculum}
                name={formFields.coreCurriculums}
            >
                <AppSelect
                    mode="multiple"
                    value={curriculumsSelectValue}
                    suffixIcon={<IconPlus size={20} />}
                    open={false}
                    showSearch={false}
                    onClick={handleOpenModal}
                    onClear={onClearHandler}
                    loading={isCoreCurriculumDataFetching}
                />
            </AppFormItem>
        </>
    );
};
