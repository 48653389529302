import { AbsenceDTO } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { getAbsenceTypeLabel } from 'consts/absence/absenceType';

export const parseAbsenceDTOIntoJsonObject: (absenceDTO: AbsenceDTO) => object = (absenceDTO) => {
    const { description, startDate, endDate, type, paid, firstName, lastName } = absenceDTO;

    const parsedStartDate = parseStringIntoFormattedStringDate(startDate);
    const parsedEndDate = parseStringIntoFormattedStringDate(endDate);

    return {
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.firstname]: firstName,
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.lastname]: lastName,
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.startDate]: parsedStartDate,
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.endDate]: parsedEndDate,
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.type]:
            getAbsenceTypeLabel(type),
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.isPaid]: paid
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.absencesDTO.innerObjects.absenceDTO.fields.description]: description,
    };
};
