/* eslint-disable @typescript-eslint/naming-convention */
import { STAFF_MODULES_DATA } from 'consts/modules/staffModules';
import { accountTexts, commonTexts, employeeTexts } from 'consts/text/index';
import { appRoutes } from './paths';

const staffRoutesPl = {
    'add-institution': 'Dodaj placówkę',
    'edit-invoice-details': 'Edytuj dane do faktury',
    employees: {
        base: STAFF_MODULES_DATA.EMPLOYEES.name,
        edit: {
            base: 'Edytuj pracownika',
            pickedEmployee: '',
        },
        add: employeeTexts.addEmployeeButtonLabel,
    },
    children: {
        base: STAFF_MODULES_DATA.CHILDREN.name,
        add: 'Dodaj dziecko',
        details: {
            base: 'Szczegóły',
            pickedChild: {
                base: '',
                'add-discount': { base: 'Dodaj zniżkę' },
                'edit-discount': {
                    base: 'Edytuj zniżkę',
                    pickedDiscount: ':discountId',
                },
            },
        },
        edit: {
            base: 'Edytuj dziecko',
            pickedChild: '',
        },
    },
    groups: {
        base: STAFF_MODULES_DATA.GROUPS.name,
        add: 'Dodaj grupę',
        edit: {
            base: 'Edytuj grupę',
            pickedGroup: '',
        },
    },
    journals: {
        base: STAFF_MODULES_DATA.JOURNALS.name,
        add: 'Dodaj dziennik specjalistyczny',
        edit: {
            base: 'Edytuj dziennik specjalistyczny',
            pickedJournal: '',
        },
        groupJournalDetails: {
            base: STAFF_MODULES_DATA.GROUP_JOURNALS.name,
            pickedJournal: {
                base: ':journalId',
                attendance: {
                    base: 'Obecności',
                    daily: {
                        base: 'Lista obecności',
                    },
                    monthly: {
                        base: 'Dziennik obecności',
                    },
                },
                schedules: {
                    base: 'Plan zajęć',
                    daily: {
                        base: 'Plan dzienny',
                        add: 'Dodaj plan dzienny',
                        edit: 'Edytuj plan dzienny',
                    },
                    weekly: {
                        base: 'Plan tygodniowy',
                        add: 'Dodaj plan tygodniowy',
                        edit: 'Edytuj plan tygodniowy',
                    },
                },
                children: {
                    base: 'Lista dzieci',
                    details: {
                        base: 'Szczegóły',
                        pickedChild: {
                            base: '',
                            'add-discount': { base: 'Dodaj zniżkę' },
                            'edit-discount': {
                                base: 'Edytuj zniżkę',
                                pickedDiscount: ':discountId',
                            },
                        },
                    },
                    edit: {
                        base: 'Edytuj dziecko',
                        pickedChild: '',
                    },
                },
                topics: {
                    base: 'Dziennik zajęć',
                    add: 'Dodaj temat zajęć',
                    edit: {
                        base: 'Edytuj temat zajęć',
                        pickedTopic: '',
                    },
                },
                events: {
                    base: 'Wydarzenia',
                    importantEvents: {
                        base: 'Ważne',
                        add: 'Dodaj ważne wydarzenie',
                        edit: {
                            base: 'Edytuj ważne wydarzenie',
                            pickedImportantEvent: '',
                        },
                        details: {
                            base: 'Podgląd ważnego wydarzenia',
                            pickedImportantEvent: '',
                        },
                    },
                    trips: {
                        base: 'Wycieczki',
                        add: 'Dodaj wycieczkę',
                        edit: {
                            base: 'Edytuj wycieczkę',
                            pickedTrip: '',
                        },
                        details: {
                            base: 'Podgląd wycieczki',
                            pickedTrip: '',
                        },
                    },
                    parentTeacherConferences: {
                        base: 'Zebrania',
                        add: 'Dodaj zebranie',
                        edit: {
                            base: 'Edytuj zebranie',
                            pickedParentTeacherConference: '',
                        },
                        details: {
                            base: 'Podgląd zebrania',
                            pickedParentTeacherConference: '',
                        },
                    },
                },
                consultations: {
                    base: 'Konsultacje',
                    add: 'Dodaj konsultację',
                    edit: {
                        base: 'Edytuj konsultację',
                        pickedConsultation: '',
                    },
                    details: {
                        base: 'Podgląd konsultacji',
                        pickedConsultation: '',
                    },
                },
                inspections: {
                    base: 'Hospitacje',
                    add: 'Dodaj hospitację',
                    edit: {
                        base: 'Edytuj hospitację',
                        pickedInspection: '',
                    },
                    details: {
                        base: 'Podgląd hospitacji',
                        pickedInspection: '',
                    },
                },
                psychologicalHelps: {
                    base: 'Pomoc psychologiczna',
                    add: 'Dodaj pomoc psychologiczną',
                    edit: {
                        base: 'Edytuj pomoc psychologiczną',
                        pickedPsychologicalHelp: '',
                    },
                    details: {
                        base: 'Podgląd pomocy psychologicznej',
                        pickedPsychologicalHelp: '',
                    },
                },
            },
        },
        specialJournalDetails: {
            base: STAFF_MODULES_DATA.SPECIAL_JOURNALS.name,
            pickedJournal: {
                base: ':journalId',
                attendance: {
                    base: 'Obecności',
                    daily: {
                        base: 'Lista obecności',
                    },
                    monthly: {
                        base: 'Dziennik obecności',
                    },
                },
                lessons: {
                    base: 'Plan zajęć',
                },
                children: {
                    base: 'Lista dzieci',
                    details: {
                        base: 'Szczegóły',
                        pickedChild: {
                            base: '',
                            'add-discount': { base: 'Dodaj zniżkę' },
                            'edit-discount': {
                                base: 'Edytuj zniżkę',
                                pickedDiscount: ':discountId',
                            },
                        },
                    },
                    edit: {
                        base: 'Edytuj dziecko',
                        pickedChild: '',
                    },
                },
                consultations: {
                    base: 'Konsultacje',
                    add: 'Dodaj konsultację',
                    edit: {
                        base: 'Edytuj konsultację',
                        pickedConsultation: '',
                    },
                    details: {
                        base: 'Podgląd konsultacji',
                        pickedConsultation: '',
                    },
                },
                inspections: {
                    base: 'Hospitacje',
                    add: 'Dodaj hospitację',
                    edit: {
                        base: 'Edytuj hospitację',
                        pickedInspection: '',
                    },
                    details: {
                        base: 'Podgląd hospitacji',
                        pickedInspection: '',
                    },
                },
                qualifications: {
                    base: 'Kwalifikacje',
                    add: 'Dodaj kwalifikację',
                    edit: {
                        base: 'Edytuj kwalifikację',
                        pickedQualification: '',
                    },
                    details: {
                        base: 'Podgląd kwalifikacji',
                        pickedQualification: '',
                    },
                },
                topics: {
                    base: 'Dziennik zajęć',
                    add: 'Dodaj temat zajęć',
                    edit: {
                        base: 'Edytuj temat zajęć',
                        pickedTopic: '',
                    },
                },
            },
        },
    },
    settlement: {
        base: STAFF_MODULES_DATA.SETTLEMENT.name,
        children: {
            base: 'Dzieci',
            pickedChild: {
                base: '',
                details: {
                    base: 'Dane',
                    payment: {
                        base: 'Płatności',
                        edit: { base: 'Edytuj płatność', pickedPayment: '' },
                        add: 'Dodaj płatność',
                    },
                },
                'cost-allocation': {
                    base: 'Podział rachunku',
                    edit: { base: 'Edytuj podział rachunku', pickedCostAllocation: '' },
                    add: { base: 'Dodaj podział rachunku' },
                },
                payment: {
                    base: 'Płatności',
                    edit: { base: 'Edytuj płatność', pickedPayment: '' },
                    add: 'Dodaj płatność',
                },
            },
        },
        staff: {
            base: 'Personel',
        },
        additionalLessons: {
            base: 'Zajęcia dodatkowe',
        },
    },
    menu: {
        base: STAFF_MODULES_DATA.DIET.name,
        add: 'Dodaj jadłospis',
        edit: {
            base: 'Edytuj jadłospis',
            pickedMenu: '',
        },
        details: {
            base: 'Podgląd jadłospisu',
            pickedMenu: '',
        },
    },
    timetable: {
        base: STAFF_MODULES_DATA.TIMETABLE.name,
        employeeSchedule: {
            base: 'Grafik pracowników',
            add: 'Dodaj do grafiku',
            edit: {
                base: 'Edytuj  zajęcia',
                pickedLesson: '',
            },
            absence: {
                base: 'Nieobecności',
                add: 'Dodaj nieobecność',
                edit: {
                    base: 'Edytuj nieobecność',
                    pickedAbsence: '',
                },
            },
        },
        workingTime: {
            base: 'Rejestracja czasu pracy',
            add: 'Dodaj do grafiku',
            edit: {
                base: 'Edytuj grafik',
                pickedWorkingTime: '',
            },
        },
        evidenceWorkingTime: {
            base: 'Zestawienie czasu pracy',
        },
    },
    messages: {
        base: STAFF_MODULES_DATA.MESSAGES.name,
        add: 'Nowa wiadomość',
        pickedMailThread: '',
    },
    'remote-lessons': {
        base: STAFF_MODULES_DATA.REMOTE_LESSONS.name,
        add: 'Dodaj e-Zajęcia',
        edit: {
            base: 'Edytuj e-Zajęcia',
            pickedRemoteLesson: '',
        },
        details: {
            base: 'Podgląd e-Zajęć',
            pickedRemoteLesson: '',
        },
    },
    calendar: {
        base: STAFF_MODULES_DATA.CALENDAR.name,
        add: 'Dodaj wydarzenie',
        details: { base: 'Wydarzenie', pickedEvent: '' },
        edit: { base: 'Edytuj wydarzenie', pickedEvent: '' },
    },
    settings: {
        base: STAFF_MODULES_DATA.SETTINGS.name,
        'institution-details': { base: 'Dane placówki', edit: 'Edytuj placówkę' },
        'principal-details': commonTexts.menuItems.settingsMenu.principalData,
        'invoice-details': {
            base: 'Dane do faktury',
            edit: 'Edytuj dane do faktury',
        },
        settlements: {
            base: 'Rozliczenia',
            general: {
                base: 'Ogólne',
            },
            meals: {
                base: 'Posiłki',
            },
            stays: {
                base: 'Pobyty',
            },
        },
        diets: {
            base: 'Posiłki',
            add: 'Dodaj posiłek',
            edit: {
                base: 'Edytuj posiłek',
                pickedDiet: '',
            },
        },
        'roles-and-permissions': {
            base: 'Role i uprawnienia',
            edit: { base: 'Edytuj', pickedRole: '' },
            add: 'Dodaj',
        },
        'history-of-changes': 'Historia zmian',
        payments: 'Płatności',
        'your-account-type': 'Twój pakiet',
    },
    account: {
        base: 'Konto',
        details: {
            base: commonTexts.menuItems.accountMenu.yourProfile,
            edit: accountTexts.editAccount,
        },
        'password-update': commonTexts.menuItems.accountMenu.changePassword,
    },
};

const parentRoutesPl = {
    base: 'Panel rodzica',
    news: {
        base: 'Aktualne',
        attendance: {
            base: 'Obecność',
            'add-absent': 'Dodaj nieobecność',
        },
        calendar: 'Kalendarz',
        'daily-schedule': 'Plan ramowy dzienny',
        'weekly-schedule': 'Plan ramowy tygodniowy',
        lessons: 'Plan indywidualny',
    },
    'child-profile': {
        base: 'Profil dziecka',
        edit: 'Edytuj profil dziecka',
    },
    menu: 'Wyżywienie',
    settlement: {
        base: 'Rozliczenia',
        payment: 'Płatność',
    },
    messages: {
        base: 'Wiadomości',
        add: 'Nowa wiadomość',
        pickedMailThread: '',
    },
    account: {
        base: 'Konto',
        details: {
            base: 'Twój profil',
            edit: 'Edytuj profil',
        },
        'password-update': 'Zmień hasło',
    },
};

const adminRoutesPl = {
    base: 'Panel admina',
    institutions: {
        base: 'Placówki',
        details: {
            base: 'Szczegóły',
            pickedInstitution: {
                base: '',
            },
        },
        'add-subscription': 'Dodaj pakiet',
        'edit-subscription': 'Edytuj pakiet',
    },
};

export const routesPl: typeof appRoutes = {
    base: '/',
    app: {
        base: '',
        'user-confirmation': 'Potwierdzenie konta',
        ...staffRoutesPl,
        parent: { ...parentRoutesPl },
        admin: { ...adminRoutesPl },
    },
    auth: {
        base: 'Autoryzacja',
        'sign-in': 'Logowanie',
        'sign-up': 'Rejestracja',
        'password-reset-request': 'Reset hasła',
        'activate-account-link-expired': 'Link do resetu hasła wygasł',
        'password-change': 'Zmiana hasła',
        'auth-action-management': '',
        legals: {
            base: 'Dokumenty',
            'terms-of-use': 'Warunki użytkowania',
            'privacy-policy': 'Polityka prywatności',
        },
    },
    error: {
        base: 'Błąd',
        'not-found': '404',
        'no-permissions': '403',
        'not-authorized': '401',
    },
};
