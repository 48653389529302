import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import {
    AppModal,
    AppModalProps,
    FormNavButtons,
    SimpleInputNumberElement,
    CurrencyInputElement,
    ErrorDisplay,
    SimpleDatePicker,
} from 'components/molecules';
import { AppModalForm, AppModalFormProps } from 'components/organisms';
import { CommonFormProps, SubscriptionFormFields } from 'types';
import { subscriptionsTexts, commonTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { AppFormDetails } from 'components/atoms';
import {
    addModeTexts,
    CHILD_LIMIT_MAX_VALUE,
    CHILD_LIMIT_MIN_VALUE,
    editModeTexts,
} from './SubscriptionAddEditModal.consts';

type SubscriptionAddEditModalProps = Required<Pick<AppModalProps, 'open' | 'onCancel'>> &
    Omit<
        AppModalFormProps<SubscriptionFormFields>,
        'withGoBack' | 'width' | 'title' | 'name' | 'error' | 'onFinish'
    > &
    Pick<CommonFormProps, 'mode'> & {
        institutionName?: string;
        activeChildren?: number;
        isFetchDataError?: boolean;
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (packageFormFields: SubscriptionFormFields) => void;
    };

export const SubscriptionAddEditModal = ({
    open,
    mode,
    form,
    initialValues,
    institutionName,
    activeChildren,
    axiosError,
    isFetchDataError,
    isLoading,
    onCancel,
    onFinish,
    ...restProps
}: SubscriptionAddEditModalProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const subscriptionAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    const currentDay = dayjs();

    const renderContent = () => {
        if (isFetchDataError) {
            return <ErrorDisplay />;
        }
        return (
            <AppModalForm<SubscriptionFormFields>
                name={name}
                form={form}
                error={subscriptionAddEditError}
                onFinish={onFinish}
                {...restProps}
            >
                <AppFormDetails
                    label={commonTexts.dataLabels.institutionName}
                    value={institutionName}
                />
                <SimpleDatePicker
                    name={formFields.periodStart}
                    formItemLabel={subscriptionsTexts.subscriptionAddEditModal.periodStartLabel}
                    disabledDate={(date) => date.isBefore(currentDay, 'date')}
                    disabled={!isAddMode}
                />
                <CurrencyInputElement name={formFields.price} />
                <SimpleInputNumberElement
                    name={formFields.childLimit}
                    label={subscriptionsTexts.subscriptionAddEditModal.childrenLimitLabel}
                    min={CHILD_LIMIT_MIN_VALUE}
                    max={CHILD_LIMIT_MAX_VALUE}
                    rules={[
                        {
                            validator: (_, childLimitValue) =>
                                childLimitValue >= (activeChildren || CHILD_LIMIT_MIN_VALUE)
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(
                                              subscriptionsTexts.subscriptionAddEditModal.childLimitLessThanActiveChildrenValidationMessage,
                                          ),
                                      ),
                        },
                    ]}
                />
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={buttonText}
                    leftIcon={buttonIcon}
                    withPlus={isAddMode}
                    marginBottom={0}
                />
            </AppModalForm>
        );
    };

    return (
        <AppModal open={open} title={title} isLoading={isLoading} onCancel={onCancel}>
            {renderContent()}
        </AppModal>
    );
};
