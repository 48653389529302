import { AppFormItem, AppFormItemProps, AppTextArea } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { max5000LengthRule } from 'consts/rules/textRules';

export const LessonNoteInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Notatka"
        name={formFields.description}
        rules={[{ required: true, message: formElementRequiredText }, max5000LengthRule]}
        {...props}
    >
        <AppTextArea rows={6} autoComplete="off" />
    </AppFormItem>
);
