import { StepProps } from 'antd';
import { AppDotWithNumberStepsStyled, Centered } from './AppDotWithNumberSteps.styled';

export type AppDotWithNumberStepsProps = {
    current: number;
    stepsCount: number;
    centered?: boolean;
};
const getNumberSteps: (stepsCount: AppDotWithNumberStepsProps['stepsCount']) => StepProps[] = (
    stepsCount,
) => Array(stepsCount).fill({});

export const AppDotWithNumberSteps = ({
    current,
    stepsCount,
    centered = true,
}: AppDotWithNumberStepsProps) => {
    const numberSteps = getNumberSteps(stepsCount);
    const maxWidth = stepsCount * 26 + (stepsCount - 1 || 0) * 40;

    const content = (
        <AppDotWithNumberStepsStyled
            maxWidth={centered ? maxWidth : undefined}
            responsive={false}
            labelPlacement="vertical"
            direction="horizontal"
            current={current}
            items={numberSteps}
        />
    );

    return centered ? <Centered>{content}</Centered> : content;
};
