import { TripDTO, TripFormFields } from 'types';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseTripDTOIntoTripFormFields: (tripDTO: TripDTO) => TripFormFields = ({
    name,
    date,
    description,
    childrenArray,
    sendConsentToParents,
}) => ({
    name,
    date: parseStringIntoDayjsDate(date),
    description,
    childIds: childrenArray.map((child) => child.id),
    sendConsentToParents,
});
