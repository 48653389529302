import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useSetAtom } from 'jotai';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { authTexts } from 'consts/text/index';
import { ContainerRow, LeftIcon, RightContainer } from './AuthUpperNavBar.styled';
import { RightComponentMobile } from './components/mobile/RightComponentMobile';
import { RightComponentDesktop } from './components/desktop/RightComponentDesktop';
import { ButtonsProps, RedirectLink } from './AuthUpperNavBar.types';

const redirectLinks: RedirectLink[] = [
    {
        label: 'Strona główna',
        path: appPaths.base,
    },
    {
        label: 'Nasze rozwiązania',
        path: appPaths.base,
    },
    {
        label: 'Cennik',
        path: appPaths.base,
    },
    {
        label: 'Kontakt',
        path: appPaths.base,
    },
];

export const AuthUpperNavBar = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const setIsDrawerOpen = useSetAtom(isLoggedUserDrawerMenuOpenAtom);

    const navigateToLogin = useCallback(() => {
        navigate({ pathname: appPaths.auth['sign-in'] });
        setIsDrawerOpen(false);
    }, [navigate, setIsDrawerOpen]);
    const navigateToSignup = useCallback(() => {
        navigate({ pathname: appPaths.auth['sign-up'] });
        setIsDrawerOpen(false);
    }, [navigate, setIsDrawerOpen]);

    const buttons: ButtonsProps = useMemo(
        () => ({
            login: {
                onClick: navigateToLogin,
                children: authTexts.loginButtonLabel,
            },
            testTheApp: {
                onClick: navigateToSignup,
                children: 'Przetestuj teraz',
                type: 'primary',
            },
        }),
        [navigateToLogin, navigateToSignup],
    );

    const RightComponent = useMemo(
        () => (isMobile ? RightComponentMobile : RightComponentDesktop),
        [isMobile],
    );

    return (
        <ContainerRow>
            <LeftIcon />
            <RightContainer>
                <RightComponent buttons={buttons} redirectLinks={redirectLinks} />
            </RightContainer>
        </ContainerRow>
    );
};
