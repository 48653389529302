import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import isEqual from 'lodash/isEqual';
import { useWatch } from 'antd/es/form/Form';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { AppButton, AppFormItem, AppFormItemProps } from 'components/atoms';
import { IconArrowNarrowRight, IconDeviceFloppy } from '@tabler/icons-react';
import { StyledPlusIcon } from 'components/atoms/StyledIcons';
import type { AppFormProps } from 'components/organisms';
import { useNavigate } from 'react-router-dom';
import { ButtonsContainer } from './FormNavButtons.styled';

export type FormNavButtonsProps<AppFormFields> = Pick<
    AppFormItemProps,
    'justifyContent' | 'marginTop' | 'marginBottom'
> & {
    buttonText?: string;
    withGoBackButton?: boolean;
    buttonsFlexWidth?: number;
    goBackButtonText?: string;
    withArrow?: boolean;
    withPlus?: boolean;
    withDeviceFloppy?: boolean;
    children?: React.ReactNode;
    leftIcon?: React.ReactNode;
    formValuesBeforeChanges?: AppFormProps<AppFormFields>['initialValues'];
    checkEqualityFormFieldsParser?: (formFields: AppFormFields) => AppFormFields;
    checkEqualityHandler?: (
        actualFormValues: AppFormFields,
        formValuesBeforeChanges: AppFormProps<AppFormFields>['initialValues'],
    ) => boolean;
};
export const FormNavButtons = <AppFormFields,>({
    buttonText,
    withArrow = false,
    withPlus = false,
    withDeviceFloppy = false,
    withGoBackButton = false,
    goBackButtonText = 'Wróć',
    buttonsFlexWidth,
    children,
    justifyContent = 'center',
    marginTop,
    marginBottom,
    leftIcon,
    formValuesBeforeChanges,
    checkEqualityFormFieldsParser,
    checkEqualityHandler,
}: FormNavButtonsProps<AppFormFields>) => {
    const isMobile = useIsMobile();
    const {
        sizes: { marginLarge },
    } = useTheme();
    const navigate = useNavigate();
    const formValues = useWatch([]);

    const parsedLeftIcon = useMemo(() => {
        if (withPlus) {
            return <StyledPlusIcon />;
        }
        if (withDeviceFloppy) {
            return <IconDeviceFloppy size={20} />;
        }
        if (leftIcon) {
            return leftIcon;
        }

        return null;
    }, [leftIcon, withPlus, withDeviceFloppy]);

    const areNoDataChanges = useMemo(() => {
        if (!formValuesBeforeChanges) {
            return false;
        }
        const formFields = checkEqualityFormFieldsParser
            ? checkEqualityFormFieldsParser(formValues)
            : formValues;
        if (checkEqualityHandler) {
            return checkEqualityHandler(formFields, formValuesBeforeChanges);
        }
        return isEqual(formFields, formValuesBeforeChanges);
    }, [formValues, formValuesBeforeChanges, checkEqualityHandler, checkEqualityFormFieldsParser]);

    const content = children || (
        <ButtonsContainer>
            {withGoBackButton && (
                <AppButton
                    flex={buttonsFlexWidth}
                    fullWidth={isMobile}
                    margin={0}
                    onClick={() => navigate(-1)}
                >
                    {goBackButtonText}
                </AppButton>
            )}
            <AppButton
                fullWidth={isMobile}
                margin={0}
                type="primary"
                htmlType="submit"
                icon={parsedLeftIcon}
                flex={buttonsFlexWidth}
                disabled={areNoDataChanges}
            >
                {buttonText}
                {withArrow && <IconArrowNarrowRight style={{ marginLeft: 8 }} />}
            </AppButton>
        </ButtonsContainer>
    );

    return (
        <AppFormItem
            margin={0}
            marginTop={marginTop ?? marginLarge}
            marginBottom={marginBottom ?? marginLarge}
            justifyContent={justifyContent}
        >
            {content}
        </AppFormItem>
    );
};
