import { List } from 'antd';
import { ListItemProps } from 'antd/es/list/Item';
import styled from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export type AppListItemProps = ListItemProps & {
    orderNumber?: number;
};

const StyledListItem = styled(List.Item)`
    &.ant-list-item {
        padding: 0;
        border-block-end: none;
        ${getTextStyle('BodyMedium')}
    }
`;

const OrderNumberContainer = styled.span`
    display: inline-block;
    margin-right: 5px;
    text-align: right;
`;

export const AppListItem = ({ orderNumber, children, ...restProps }: AppListItemProps) => (
    <StyledListItem {...restProps}>
        {orderNumber && <OrderNumberContainer>{`${orderNumber}.`}</OrderNumberContainer>}
        {children}
    </StyledListItem>
);
