import { Navigate, RouteObject } from 'react-router-dom';
import { SideMenuLayout } from 'components/layouts';
import { appPaths } from 'consts/paths/paths';
import { institutionRoutes } from 'navigation/routes/loggedApp/routes/admin/institution';

export const adminRoutes: RouteObject[] = [
    {
        element: <SideMenuLayout />,
        children: [
            {
                path: appPaths.app.admin.base,
                element: <Navigate to={appPaths.app.admin.institutions.base} />,
            },
            ...institutionRoutes,
        ],
    },
];
