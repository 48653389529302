import { useParams } from 'react-router-dom';
import { JournalId } from 'types';

export const useJournalId = () => {
    const { journalId: journalIdParam } = useParams();

    const journalId = (journalIdParam || '') as JournalId;

    return { journalId };
};
