import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { AppParagraph } from 'components/atoms';
import { SelectFilter } from './components/SelectFilter.styled';
import { RangePickerFilter } from './components/RangePickerFilter.styled';

export const InputContainer = styled.span.withConfig({
    shouldForwardProp: getShouldForwardProp(['width']),
})<{ width?: string | number }>`
    ${({ theme, width }) => css`
        @media ${theme.sizes.mediaQueries.desktopMin} {
            width: ${width};
        }
    `}
`;

export type FilterProps = {
    input?: ReactNode;
    label?: string;
    width?: number | string;
};

export const Filter = ({ input, label, width }: FilterProps) => (
    <InputContainer width={width}>
        {label && (
            <AppParagraph margin={0} marginBottom="marginSmall" textType="BodyMSSemiBold">
                {label}
            </AppParagraph>
        )}
        {input}
    </InputContainer>
);

Filter.Select = SelectFilter;
Filter.RangePicker = RangePickerFilter;
