import { InputNumber, InputNumberProps } from 'antd';
import styled from 'styled-components';
import { inputStyle } from 'consts/styles/inputStyle';

export type AppInputNumberProps = Omit<InputNumberProps, 'decimalSeparator' | 'controls'>;

const StyledInputNumber = styled(InputNumber)<AppInputNumberProps>`
    width: 100%;
    border-radius: ${({ theme }) => theme.borders.borderRadiusSmall}px;
    ${inputStyle}

    input.ant-input-number-input {
        height: auto;
        padding: 0;
        border-radius: 0;
    }

    .ant-input-number-outlined {
        border: none;

        &:focus-within {
            box-shadow: none;
        }
    }

    .ant-input-number-group-addon {
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
    }
`;

const DECIMAL_SEPARATOR = ',';

export const AppInputNumber = ({ ...props }: AppInputNumberProps) => (
    <StyledInputNumber decimalSeparator={DECIMAL_SEPARATOR} controls={false} {...props} />
);
