import { ReactNode } from 'react';
import { AppFormRangeItemMode } from 'types';
import { AppFormItem, AppFormItemProps } from '../AppFormItem/AppFormItem';

export type AppFormRangeItemValues = Pick<AppFormItemProps, 'label' | 'name' | 'rules'>;

export type AppFormRangeItemProps = AppFormItemProps & {
    mode: AppFormRangeItemMode;
    startValues: AppFormRangeItemValues;
    finishValues: AppFormRangeItemValues;
    children: ReactNode;
};

export const AppFormRangeItem = ({
    mode,
    startValues,
    finishValues,
    children,
    ...props
}: AppFormRangeItemProps) => {
    const { label: startLabel, name: startName, rules: startRules } = startValues;
    const { label: finishLabel, name: finishName, rules: finishRules } = finishValues;
    const isStartMode = mode === 'start';

    return (
        <AppFormItem
            label={isStartMode ? startLabel : finishLabel}
            name={isStartMode ? startName : finishName}
            rules={isStartMode ? startRules : finishRules}
            {...props}
        >
            {children}
        </AppFormItem>
    );
};
