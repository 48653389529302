import { InternalAxiosRequestConfig } from 'axios';
import AxiosBaseInstance from 'services/HTTPService/AxiosBaseInstance';
import { readLocalStorageChildId } from 'services/localStorage/childId';
import { readLocalStorageInstitutionId } from 'services/localStorage/institutionId';

class ParentAxiosInstance extends AxiosBaseInstance {
    constructor() {
        super();
        // @ts-ignore
        this.axios.interceptors.request.use(this.addChildAndInstitutionIdsParams, Promise.reject);
    }

    addChildAndInstitutionIdsParams = async (config: InternalAxiosRequestConfig) => {
        const params = { ...config.params } || {};
        const childId = readLocalStorageChildId();
        const institutionId = readLocalStorageInstitutionId();
        if (childId && institutionId) {
            params.childId = childId;
            params.institutionId = institutionId;
        }
        return { ...config, params };
    };
}

export default ParentAxiosInstance;
