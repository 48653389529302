import { Input, InputProps } from 'antd';
import styled from 'styled-components';
import { inputStyle } from 'consts/styles/inputStyle';

export type AppInputProps = InputProps;

const StyledInput = styled(Input)<AppInputProps>`
    ${inputStyle}
`;

export const AppInput = ({ ...props }: AppInputProps) => <StyledInput {...props} />;
