import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps } from 'components/organisms/AppTable';
import { guardianTableItemFields } from 'consts/guardian/guardianTableItemFields';
import { IdDocumentNumberDisplay, PhoneNumberDisplay } from 'components/molecules/Table';
import { ChildGuardian } from 'types';
import { getFullName } from 'utils/getFullName';
import { WithId } from 'types/common/WithId';
import { Option } from 'components/atoms';
import { childrenTexts, commonTexts } from 'consts/text/index';

export type GuardianTableProps = {
    guardians: WithId<ChildGuardian>[];
    onEditGuardianClick: Option<WithId<ChildGuardian>>['onClick'];
    onRemoveGuardianClick: Option<WithId<ChildGuardian>>['onClick'];
    testId?: string;
    canManageTable?: boolean;
};

const columns: AppTableProps<WithId<ChildGuardian>>['columns'] = [
    {
        title: 'Nazwisko i imię',
        dataIndex: guardianTableItemFields.firstName,
        key: guardianTableItemFields.firstName,
        render: (_, record) => getFullName(record.firstName, record.lastName),
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: 'Numer dowodu',
        dataIndex: guardianTableItemFields.idNumber,
        key: guardianTableItemFields.idNumber,
        render: (idNumber) => <IdDocumentNumberDisplay idDocumentNumber={idNumber} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: 'Numer telefonu',
        dataIndex: guardianTableItemFields.phoneNumber,
        key: guardianTableItemFields.phoneNumber,
        render: (phoneNumber) => <PhoneNumberDisplay phoneNumber={phoneNumber} />,
        isVisibleAsExtendableInMobile: true,
    },
];

export const GuardianTable = ({
    guardians,
    onEditGuardianClick,
    onRemoveGuardianClick,
    testId,
    canManageTable,
}: GuardianTableProps) => {
    const options: Option<WithId<ChildGuardian>>[] = [
        { label: commonTexts.actionLabels.edit, onClick: onEditGuardianClick, Icon: <IconEdit /> },
        {
            label: commonTexts.actionLabels.delete,
            onClick: onRemoveGuardianClick,
            Icon: <IconTrash />,
        },
    ];

    return (
        <AppTable<WithId<ChildGuardian>>
            emptyInfo={childrenTexts.noGuardiansMessage}
            options={canManageTable ? options : undefined}
            columns={columns}
            rowKey={({ idNumber }) => idNumber}
            getRecordId={({ idNumber }) => idNumber}
            dataSource={guardians}
            isTableManageUtilsHidden
            isTableHeaderHidden
            id={testId}
        />
    );
};
