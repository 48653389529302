import { ChildParent } from 'types';
import { CountWithLimitDisplay } from 'components/molecules/Table/CountWithLimitDisplay/CountWithLimitDisplay';

const CHILD_PARENTS_LIMIT = 2;

export const ParentsAccountsCount = ({ parents }: { parents: ChildParent[] }) => {
    const parentsWithAccountCount = parents.reduce((totalCount, actualParent) => {
        if (actualParent.withAccount) {
            return totalCount + 1;
        }
        return totalCount;
    }, 0);
    return <CountWithLimitDisplay count={parentsWithAccountCount} limit={CHILD_PARENTS_LIMIT} />;
};
