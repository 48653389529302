import { Form } from 'antd';
import { ErrorDisplay } from 'components/molecules';
import { QualificationAddEditForm } from 'components/templates';
import { formFields } from 'consts/form/formFields';
import { appPaths } from 'consts/paths/paths';
import { journalTexts } from 'consts/text';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useEffect } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { ChildId } from 'types';
import { QualificationFormFields } from 'types/forms/qualification';
import { getFullName } from 'utils/getFullName';
import { parseQualificationFormFieldsIntoAddUpdateQualificationDTO } from 'utils/parsers/qualifications/parseQualificationFormFieldsIntoAddUpdateQualificationDTO';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useQualificationId } from '../hooks/useQualificationId/useQualificationId';

export const SpecialJournalQualificationEdit = () => {
    const [form] = Form.useForm();
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const { state: specialJournalLocationState } = useLocation();
    const { childId } = specialJournalLocationState as { childId: ChildId };
    const { displaySuccess } = useSimpleNotification();
    const { qualificationId } = useQualificationId();

    const {
        data: qualificationData,
        isFetching: isQualificationDataFetching,
        isError: isFetchQualificationDataError,
    } = useAppQuery(
        'QUALIFICATIONS_BASE',
        [journalId, qualificationId],
        () => StaffHTTPService.qualifications.getQualification(journalId, qualificationId),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            refetchOnWindowFocus: false,
        },
    );

    const childFullName = qualificationData
        ? getFullName(qualificationData.child.firstName, qualificationData.child.lastName)
        : undefined;

    const onEditQualificationSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.specialJournal.qualifications
                .qualificationEditSuccessNotification,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base,
                { journalId },
            ),
            {
                replace: true,
            },
        );
    };

    const {
        mutate: editQualification,
        error: editQualificationError,
        isLoading: isEditQualificationLoading,
    } = useAppMutation(StaffHTTPService.qualifications.addUpdateQualification, {
        key: ['UPDATE_QUALIFICATIONS_BASES'],
        onSuccess: onEditQualificationSuccess,
        invalidateQueryKeys: [
            ['QUALIFICATIONS_BASES', journalId],
            ['QUALIFICATIONS_BASE', journalId, qualificationId],
        ],
    });

    const onEditQualificationFinish = (qualificationDataToEdit: QualificationFormFields) => {
        const parsedAddQualificationDTO = parseQualificationFormFieldsIntoAddUpdateQualificationDTO(
            qualificationDataToEdit,
            childId,
        );

        editQualification({
            specialJournalId: journalId,
            addUpdateQualificationDTO: parsedAddQualificationDTO,
        });
    };

    useEffect(() => {
        if (qualificationData && !form.getFieldValue(formFields.basis)) {
            form.setFieldsValue(qualificationData);
        }
    }, [qualificationData, form]);

    if (isFetchQualificationDataError) {
        return <ErrorDisplay />;
    }

    return (
        <QualificationAddEditForm
            mode="edit"
            axiosError={editQualificationError}
            isLoading={isEditQualificationLoading || isQualificationDataFetching}
            onFinish={onEditQualificationFinish}
            childFullName={childFullName}
            form={form}
        />
    );
};
