import {
    EducationalGuideTextbookId,
    EducationalGuideTopicId,
} from 'types/educationalGuide/educationalGuide';

export const EDUCATIONAL_GUIDE_TEXTBOOKS = '/api/v1/education-guides/textbooks';

export const EDUCATIONAL_GUIDE_TEXTBOOK_UNITS = (textbookId: EducationalGuideTextbookId) =>
    `/api/v1/education-guides/textbooks/${textbookId}`;

export const EDUCATIONAL_GUIDE_TOPIC = (topicId: EducationalGuideTopicId) =>
    `/api/v1/education-guides/topics/${topicId}`;
