import styled, { css } from 'styled-components';
import { AppParagraph, AppTitle } from 'components/atoms';

export const Container = styled.span`
    display: flex;
    flex-direction: column;
    max-width: 250px;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
`;

export const StyledTitle = styled(AppTitle)`
    ${({ theme }) => css`
        &.ant-typography {
            color: ${theme.colors.grayscale.gray9};
        }
    `}
`;

export const Subtitle = styled(AppParagraph)`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray8};
    `}
`;

export const IconContainer = styled.div`
    ${({ theme }) => css`
        margin-top: ${theme.sizes.marginLarge}px;
    `}
`;
