import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'services/firebase/firebase';
import { useSetAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import { authStateAtom } from 'jotaiAtoms/authState';
import { appPaths } from 'consts/paths/paths';
import { userDetailsAtom } from '../../jotaiAtoms/userDetails';

export const useFirebaseSession = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const setLoggedState = useSetAtom(authStateAtom);
    const setUserDetails = useSetAtom(userDetailsAtom);

    useEffect(() => {
        onAuthStateChanged(auth, (userFirebase) => {
            if (userFirebase) {
                setLoggedState('authorized');
            } else {
                setLoggedState('unauthorized');
                setUserDetails(null);
                if (pathname.includes(appPaths.app.base)) {
                    navigate(appPaths.auth['sign-in'], { replace: true });
                }
            }
        });
    }, [navigate, pathname, setLoggedState, setUserDetails]);
};
