import { IconPlus } from '@tabler/icons-react';
import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { journalTexts } from 'consts/text';
import { ChildId, SelectOptionType } from 'types';

export type SpecialJournalChildrenPickerElementProps = AppFormItemProps & {
    onClick: () => void;
    onClear: () => void;
    isLoading: boolean;
    options: SelectOptionType<ChildId>[];
};

export const SpecialJournalChildrenPickerElement = ({
    onClick,
    onClear,
    isLoading,
    options,
    ...props
}: SpecialJournalChildrenPickerElementProps) => (
    <AppFormItem
        label={journalTexts.journals.addChildren}
        name={formFields.childIdsToAdd}
        {...props}
    >
        <AppSelect
            mode="multiple"
            suffixIcon={<IconPlus size={20} />}
            open={false}
            showSearch={false}
            onClick={onClick}
            onClear={onClear}
            loading={isLoading}
            options={options}
        />
    </AppFormItem>
);
