import type { HistoryChangeRefersTo, LabelMapper } from 'types';
import { settingsTexts } from 'consts/text';

export const historyChangeRefersToLabels: LabelMapper<HistoryChangeRefersTo> = {
    AUTHENTICATION: settingsTexts.historyOfChanges.refersToLabels.authentication,
    DIET: settingsTexts.historyOfChanges.refersToLabels.diet,
    CALENDAR: settingsTexts.historyOfChanges.refersToLabels.calendar,
    CHILD: settingsTexts.historyOfChanges.refersToLabels.child,
    DISCOUNT: settingsTexts.historyOfChanges.refersToLabels.discount,
    EMPLOYEE: settingsTexts.historyOfChanges.refersToLabels.employee,
    GROUP: settingsTexts.historyOfChanges.refersToLabels.group,
    GROUP_ATTENDANCE: settingsTexts.historyOfChanges.refersToLabels.groupAttendace,
    GUARDIAN: settingsTexts.historyOfChanges.refersToLabels.guardian,
    MENU: settingsTexts.historyOfChanges.refersToLabels.menu,
    INSTITUTION: settingsTexts.historyOfChanges.refersToLabels.institution,
    JOURNAL: settingsTexts.historyOfChanges.refersToLabels.journal,
    OWNER: settingsTexts.historyOfChanges.refersToLabels.owner,
    PARENT: settingsTexts.historyOfChanges.refersToLabels.parent,
    SETTLEMENT: settingsTexts.historyOfChanges.refersToLabels.settlement,
    TIMETABLE: settingsTexts.historyOfChanges.refersToLabels.timetable,
    ROLE: settingsTexts.historyOfChanges.refersToLabels.role,
    REMOTE_LESSON: settingsTexts.historyOfChanges.refersToLabels.remoteLesson,
};
