import { ReactNode } from 'react';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import {
    DesktopRowContainer,
    DesktopLabelCell,
    DesktopContentCell,
    MobileRowContainer,
    MobileLabel,
    MobileContentContainer,
} from './Row.styled';

type RowItemProps = {
    label: string;
    content?: ReactNode;
};

export const Row = ({ label, content }: RowItemProps) => {
    const isMobile = useIsMobile();

    const labelText = `${label}:`;

    return isMobile ? (
        <MobileRowContainer>
            <MobileLabel>{labelText}</MobileLabel>
            <MobileContentContainer>{content}</MobileContentContainer>
        </MobileRowContainer>
    ) : (
        <DesktopRowContainer>
            <DesktopLabelCell>{labelText}</DesktopLabelCell>
            <DesktopContentCell>{content}</DesktopContentCell>
        </DesktopRowContainer>
    );
};
