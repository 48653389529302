export const specialSymbols = [
    '^',
    '$',
    '*',
    '.',
    '[',
    ']',
    '{',
    '}',
    '(',
    ')',
    '?',
    '-',
    '"',
    '!',
    '@',
    '#',
    '%',
    '&',
    '/',
    '\\',
    '>',
    '<',
    "'",
    ':',
    ';',
    '|',
    '_',
    '~',
    '`',
    '+',
    '=',
];
