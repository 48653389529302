import { Tag } from 'antd';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { useTheme } from 'styled-components';

export const BalanceTag = ({ amount }: { amount: number }) => {
    const theme = useTheme();
    let bgColor: string = theme.colors.grayscale.gray3;
    let color: string = theme.colors.grayscale.gray9;
    if (amount > 0) {
        bgColor = theme.colors.green.green3;
        color = theme.colors.green.green8;
    } else if (amount < 0) {
        bgColor = theme.colors.red.red2;
        color = theme.colors.red.red8;
    }
    const formattedAmount = getFormattedPrice(amount);

    return (
        <Tag color={bgColor} style={{ color }}>
            {formattedAmount}
        </Tag>
    );
};
