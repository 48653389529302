import { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { TripDTO, TripId } from 'types';
import { tripDTOFields } from 'consts/trip/tripDTOFields';
import { PersonListDisplay, DateDisplay, NameWithDateDisplay } from 'components/molecules';
import { tripTableAdditionalColumnsKeys } from 'consts/trip/tripTableAdditionalColumnsKeys';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { EventTypeSelect, Option } from 'components/atoms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, journalTexts } from 'consts/text';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useGroupJournalTripsPageNetworkManage } from './hooks/useGroupJournalTripsPageNetworkManage';

const columns: AppTableProps<TripDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.events.trips.trip,
        dataIndex: tripDTOFields.name,
        key: tripDTOFields.name,
        render: (_, trip) => <NameWithDateDisplay name={trip.name} date={trip.date} />,
        width: '35%',
    },
    {
        title: commonTexts.dataLabels.childrenCount,
        dataIndex: tripTableAdditionalColumnsKeys.childCount,
        key: tripTableAdditionalColumnsKeys.childCount,
        render: (_, trip) => trip.childrenArray.length,
        align: 'center',
        width: '15%',
    },
    {
        title: commonTexts.dataLabels.date,
        dataIndex: tripDTOFields.date,
        key: tripDTOFields.date,
        render: (date: TripDTO['date']) => <DateDisplay date={date} />,
        align: 'center',
        isInvisibleInMobile: true,
        width: '50%',
    },
    {
        title: commonTexts.dataLabels.groupChildren,
        dataIndex: tripDTOFields.childrenArray,
        key: tripDTOFields.childrenArray,
        render: (_, trip) => <PersonListDisplay personsData={trip.childrenArray} />,
        isInvisibleInDesktop: true,
        isVisibleAsExtendableInMobile: true,
    },
];

export const GroupJournalTripsPage = () => {
    const { journalId } = useJournalId();
    const [selectedToRemoveTripId, setSelectedToRemoveTripId] = useState<TripId | null>(null);
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const {
        groupJournalsAbilities: { checkIfCanManageEvents },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageEvents = checkIfCanManageEvents();

    const onTripRemoveRequestFinishOrCancel = () => setSelectedToRemoveTripId(null);

    const {
        tripsData,
        isTripsDataInitialLoading,
        isTripsDataRefetching,
        isTripsDataError,
        isRemoveTripLoading,
        removeTrip,
    } = useGroupJournalTripsPageNetworkManage({
        journalId,
        onTripRemoveRequestFinish: onTripRemoveRequestFinishOrCancel,
    });

    const handleRemoveTripClick = (trip: TripDTO) => setSelectedToRemoveTripId(trip.id);

    const handleRemoveTripConfirm = () => {
        if (!selectedToRemoveTripId) {
            return;
        }
        removeTrip({
            journalId,
            tripId: selectedToRemoveTripId,
        });
    };

    const navigateToTripAddPage = () =>
        statefulNavigate(
            generatePath(appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.add, {
                journalId,
            }),
        );

    const navigateToTripEditPage = ({ id }: TripDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.edit
                    .pickedTrip,
                { tripId: id, journalId },
            ),
        );

    const navigateToTripDetailsPage = ({ id }: TripDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.details
                    .pickedTrip,
                { tripId: id, journalId },
            ),
        );

    const expandedRowRender = useCallback(
        (trip: TripDTO) => (
            <PersonListDisplay
                personsData={trip[tripDTOFields.childrenArray]}
                desktopLabel={commonTexts.dataLabels.groupChildren}
            />
        ),
        [],
    );

    const expandable = useMemo(
        () =>
            !isMobile
                ? {
                      expandedRowRender,
                      showExpandColumn: true,
                  }
                : undefined,
        [isMobile, expandedRowRender],
    );

    const options: Option<TripDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToTripDetailsPage,
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToTripEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageEvents,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveTripClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManageEvents,
        },
    ];

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveTripId}
                title={journalTexts.journalDetails.groupJournal.events.trips.confirmDeleteTrip}
                confirmActionLoading={isRemoveTripLoading}
                onOk={handleRemoveTripConfirm}
                onCancel={onTripRemoveRequestFinishOrCancel}
            />
            <AppTable
                title={journalTexts.journalDetails.groupJournal.events.trips.tripList}
                addButtonLabel={journalTexts.journalDetails.groupJournal.events.trips.addTrip}
                emptyInfo={journalTexts.journalDetails.groupJournal.events.trips.emptyTripList}
                options={options}
                columns={columns}
                dataSource={tripsData}
                expandable={expandable}
                rowKey={({ id }) => id}
                isError={isTripsDataError}
                isOptionsLoading={isRemoveTripLoading}
                isDataInitialLoading={isTripsDataInitialLoading}
                isDataRefetching={isTripsDataRefetching}
                isHiddenAddButton={!canManageEvents}
                tableManageUtilsProps={{
                    isVisibleSearchFilter: false,
                    additionalFilters: <EventTypeSelect eventType="TRIP" journalId={journalId} />,
                }}
                onAddButtonClick={navigateToTripAddPage}
            />
        </>
    );
};
