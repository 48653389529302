import styled, { css } from 'styled-components';
import { AppButton } from 'components/atoms/CommonAppComponents/AppButton/AppButton';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export type OpenAppOptionsButtonSize = 'NORMAL' | 'BIG';

export const OpenAppOptionsButton = styled(AppButton).withConfig({
    shouldForwardProp: getShouldForwardProp(['size']),
})<{ size?: OpenAppOptionsButtonSize }>`
    ${({ size }) => css`
        &.ant-btn-icon-only {
            padding: 0;
            width: 100%;
            ${size === 'BIG'
                ? css`
                      padding: 10px;
                  `
                : css`
                      height: 20px;
                  `}
        }
    `}
`;

export const AppOptionButton = styled(AppButton)`
    ${({ theme }) => css`
        &.ant-btn-background-ghost {
            justify-content: flex-start;
            align-items: center;
            line-height: 20px;

            &:not(:disabled):not(.ant-btn-disabled):hover {
                border: 1px solid transparent;
                background-color: ${theme.colors.primary.primary3};
                color: ${theme.colors.primary.primary9};
            }
        }
    `}
`;

export const AppOptionIconContainer = styled.span`
    width: 20px;
    height: 20px;
    svg {
        width: 20px;
        height: 20px;
    }
`;
