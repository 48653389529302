import styled, { css, useTheme } from 'styled-components';
import { Row } from 'antd';
import { IconInfoCircle } from '@tabler/icons-react';
import { AppText } from 'components/atoms';

type InfoTextProps = {
    text?: string;
    marginBottom?: number;
};

const RowWithMargin = styled(Row)<{ marginBottom: number | undefined }>`
    ${({ theme, marginBottom }) => css`
        margin-bottom: ${marginBottom ?? theme.sizes.marginNormal}px;
    `}
`;

const StyledIconInfoCircle = styled(IconInfoCircle)`
    height: 20px;
    width: 20px;
    flex-shrink: 0;
`;

export const InfoText = ({ text, marginBottom }: InfoTextProps) => {
    const theme = useTheme();

    return (
        <RowWithMargin marginBottom={marginBottom} align="middle" wrap={false}>
            <StyledIconInfoCircle />
            <AppText
                margin={0}
                marginLeft={theme.sizes.marginSmall}
                color={theme.colors.grayscale.gray9}
            >
                {text}
            </AppText>
        </RowWithMargin>
    );
};
