import { useAppQuery } from 'services/reactQuery/useAppQuery';
import DOMPurify from 'dompurify';
import { PageBaseLayout, ParentChildJournalDataLayout } from 'components/layouts';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parentTexts } from 'consts/text';
import { NoScheduleDataStateIcon } from 'assets';
import { DailyScheduleCard } from 'components/molecules';

export const DailySchedulePage = () => {
    const {
        data: dailyScheduleData,
        isInitialLoading: isDailyScheduleDataInitialLoading,
        isError: isDailyScheduleDataError,
        error: dailyScheduleDataError,
    } = useAppQuery('DAILY_SCHEDULE', [], ParentHTTPService.schedules.getDailySchedule, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const scheduleDescription = dailyScheduleData?.description
        ? DOMPurify.sanitize(dailyScheduleData?.description)
        : '';

    return (
        <ParentChildJournalDataLayout error={dailyScheduleDataError}>
            <PageBaseLayout
                isDataInitialLoading={isDailyScheduleDataInitialLoading}
                isError={isDailyScheduleDataError}
                isEmpty={!dailyScheduleData?.description}
                emptyStateData={{
                    emptyInfo: parentTexts.schedule.noDailySchedule,
                    emptyIcon: <NoScheduleDataStateIcon />,
                }}
            >
                <DailyScheduleCard description={scheduleDescription} />
            </PageBaseLayout>
        </ParentChildJournalDataLayout>
    );
};
