import styled, { css } from 'styled-components';
import { Steps, StepsProps } from 'antd';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export const AppDotWithNumberStepsStyled = styled(Steps).withConfig({
    shouldForwardProp: getShouldForwardProp(['maxWidth']),
})<StepsProps & { maxWidth?: number }>`
    ${({ theme, maxWidth }) => css`
        ${maxWidth ? `max-width: ${maxWidth}px;` : ''}
        // global
        &.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
            display: none;
        }
        &.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
            padding: 1px 10px;
            margin-inline-start: 16px;
            margin-inline-end: 0;
        }
        &.ant-steps .ant-steps-item-icon {
            font-size: 16px;
            font-weight: 400;
            height: 28px;
            width: 28px;
            line-height: 28px;
            margin-inline-start: 0;
            margin-inline-end: 0;
        }
        // finish
        &.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
            background-color: ${theme.colors.green.green6};
        }
        &.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
            color: ${theme.colors.grayscale.gray1};
        }
        &.ant-steps
            .ant-steps-item-finish
            > .ant-steps-item-container
            > .ant-steps-item-tail::after {
            background-color: ${theme.colors.green.green6};
        }
        // wait
        &.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
            color: ${theme.colors.grayscale.gray8};
        }
        &.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
            background-color: ${theme.colors.grayscale.gray1};
            border: 1.5px solid ${theme.colors.grayscale.gray8};
        }
        &.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: ${theme.colors.grayscale.gray8};
        }
        // process
        &.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
            color: ${theme.colors.primary.primary9};
        }
        &.ant-steps .ant-steps-item-process .ant-steps-item-icon {
            background-color: ${theme.colors.grayscale.gray1};
            border: 1.5px solid ${theme.colors.primary.primary9};
        }
        &.ant-steps
            .ant-steps-item-process
            > .ant-steps-item-container
            > .ant-steps-item-tail::after {
            background-color: ${theme.colors.primary.primary9};
        }
    `}
`;

export const Centered = styled.div`
    display: flex;
    justify-content: center;
`;
