import { AddSpecialJournalDTO, UpdateSpecialJournalDTO } from 'types';
import { SpecialJournalFormFields } from 'types/forms/journal';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseSpecialJournalFormFieldsIntoUpdateSpecialJournalDTO: (
    specialJournalFormFields: SpecialJournalFormFields,
) => UpdateSpecialJournalDTO = ({ startDate, finishDate, lessonsType, ...restFormFields }) => ({
    ...restFormFields,
    startDate: parseDayjsDateIntoString(startDate, 'YYYY-MM-DD'),
    endDate: parseDayjsDateIntoString(finishDate, 'YYYY-MM-DD'),
    lessonsType: lessonsType || null,
});

export const parseSpecialJournalFormFieldsIntoAddSpecialJournalDTO: (
    specialJournalFormFields: Required<SpecialJournalFormFields>,
) => AddSpecialJournalDTO = (specialJournalFormFields) => {
    const { childIdsToAdd, startDate, finishDate, ...restFormFields } = specialJournalFormFields;
    return {
        childIds: childIdsToAdd,
        startDate: parseDayjsDateIntoString(startDate, 'YYYY-MM-DD'),
        endDate: parseDayjsDateIntoString(finishDate, 'YYYY-MM-DD'),
        ...restFormFields,
    };
};
