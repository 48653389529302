import {
    AppButton,
    AppModalBase,
    AppModalTitle,
    AppText,
    BorderedContainerStyled,
    ComponentBlockingAppLoader,
} from 'components/atoms';
import { IconPlus } from '@tabler/icons-react';
import { ModalSubtitle } from 'components/molecules/AppModal/AppModal.styled';
import styled, { useTheme } from 'styled-components';
import { generatePath } from 'react-router-dom';
import { LeafIcon } from 'assets';
import { EventTag } from 'components/atoms/EventTag/EventTag';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { appPaths } from 'consts/paths/paths';
import { CalendarEventDetailedDto, CalendarEventDetailedDtos } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { FULL_DAY_FORMAT } from 'consts/dateFormats';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { useContext, useMemo } from 'react';
import { calendarTexts } from 'consts/text';
import { AppCalendarContext } from 'contexts/AppCalendarContext';
import { useCalendarAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { noop } from 'lodash';
import { AxiosResponse } from 'axios';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

type CalendarDayModalProps = {
    open: boolean;
    showDetails?: boolean;
    onCancel: () => void;
    monthlyFetchFunction: ({
        month,
        year,
    }: {
        month: number;
        year: number;
    }) => Promise<AxiosResponse<CalendarEventDetailedDtos, undefined>>;
    dailyFetchFunction: ({ date }: { date: string }) => Promise<AxiosResponse<string[], undefined>>;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
`;

const EventsContainer = styled(BorderedContainerStyled)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ModalSubtitleCapitalized = styled(ModalSubtitle)`
    text-transform: capitalize;
`;

const ModalTitleCapitalized = styled(AppModalTitle)`
    text-transform: capitalize;
`;

export const CalendarDayModal = ({
    open,
    onCancel,
    showDetails = true,
    monthlyFetchFunction,
    dailyFetchFunction,
}: CalendarDayModalProps) => {
    const theme = useTheme();
    const { statefulNavigate } = useNavigateWithState();

    const { calendarAbilities } = useCalendarAbilities();
    const canManageEvents = calendarAbilities.checkIfCanManageEvents() && showDetails;

    const { selectedDate } = useContext(AppCalendarContext);

    const date = parseStringIntoDayjsDate(selectedDate as string);
    const selectedYear = date.year();
    const selectedMonth = getMonthFromDayjsMonth(date.month());

    const { data: eventsMonthlyData, isFetching: isEventsMonthlyFetching } = useAppQuery(
        'MONTHLY_EVENTS',
        [selectedMonth, selectedYear],
        () =>
            monthlyFetchFunction({
                month: selectedMonth,
                year: selectedYear,
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { data: eventsData, isFetching: isEventsDataFetching } = useAppQuery(
        'DAILY_EVENTS',
        [selectedDate],
        () => dailyFetchFunction({ date: selectedDate }),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: !!eventsMonthlyData?.length,
        },
    );

    const mappedEvents = useMemo(
        () =>
            eventsData
                ?.map((eventId) => eventsMonthlyData?.find((event) => event.id === eventId))
                .filter((event) => event?.type) as CalendarEventDetailedDtos,
        [eventsData, eventsMonthlyData],
    );

    const onAddClick = () => {
        statefulNavigate(appPaths.app.calendar.add);
    };

    const renderContent = () => {
        if (isEventsDataFetching || isEventsMonthlyFetching) {
            return null;
        }

        if (!mappedEvents?.length) {
            return (
                <Container>
                    <LeafIcon />
                    <AppText textType="BodyMSSemiBold" color={theme.colors.grayscale.gray9}>
                        {calendarTexts.dayModal.noEvents}
                    </AppText>
                </Container>
            );
        }

        return (
            <EventsContainer>
                {mappedEvents?.map((event: CalendarEventDetailedDto) => (
                    <EventTag
                        showDetails={showDetails}
                        key={event?.id}
                        text={event?.name}
                        type={event?.type}
                        onTagClick={() =>
                            showDetails
                                ? statefulNavigate(
                                      generatePath(appPaths.app.calendar.details.pickedEvent, {
                                          eventId: event?.id,
                                      }),
                                  )
                                : noop
                        }
                    />
                ))}
            </EventsContainer>
        );
    };

    return (
        <AppModalBase
            open={open}
            onCancel={onCancel}
            footer={
                canManageEvents && (
                    <AppButton onClick={onAddClick} type="primary" margin={0} icon={<IconPlus />}>
                        {calendarTexts.dayModal.addEvent}
                    </AppButton>
                )
            }
            destroyOnClose
        >
            <ModalTitleCapitalized level={4}>{date.format(FULL_DAY_FORMAT)}</ModalTitleCapitalized>
            <Container>
                <ModalSubtitleCapitalized marginBottom={32} marginTop={5}>
                    {date.format('dddd')}
                </ModalSubtitleCapitalized>
            </Container>
            {renderContent()}
            {isEventsDataFetching && <ComponentBlockingAppLoader />}
        </AppModalBase>
    );
};
