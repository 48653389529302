import { MenuDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseMenuDTOIntoJsonObject: (menuDTO: MenuDTO) => object = (menuDTO) => {
    const { name, dateFrom, dateTo, attachments } = menuDTO;

    const formattedDateFrom = parseStringIntoFormattedStringDate(dateFrom);
    const formattedDateTo = parseStringIntoFormattedStringDate(dateTo);
    const attachmentsNames = attachments.map((attachment) => attachment.fileName);

    return {
        [jsonObjectsTexts.menuDTO.fields.name]: name,
        [jsonObjectsTexts.menuDTO.fields.dateFrom]: formattedDateFrom,
        [jsonObjectsTexts.menuDTO.fields.dateTo]: formattedDateTo,
        [jsonObjectsTexts.menuDTO.fields.attachments]: attachmentsNames,
    };
};
