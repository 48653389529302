import styled, { css } from 'styled-components';
import { AppParagraph } from 'components/atoms';
import { Error404Image } from 'assets';

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;

        @media ${theme.sizes.mediaQueries.tabletMax} {
            width: 100%;
            overflow: hidden;
        }
    `}
`;

export const MainTitle = styled(AppParagraph)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.primary8};
        font-size: 148px;
        font-weight: 700;
        line-height: 1;
    `}
`;

export const Subtitle = styled(AppParagraph)`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray9};
        font-size: 32px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.96px;
    `}
`;

export const StyledError404Image = styled(Error404Image)`
    ${({ theme }) => css`
        margin-bottom: ${theme.sizes.marginLarge}px;
    `}
`;

export const InfoSmall = styled(AppParagraph)`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray8};
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.48px;
        width: 340px;
    `}
`;
export const InfoNormal = styled(AppParagraph)`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray8};
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.48px;
    `}
`;
