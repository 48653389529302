import styled, { css } from 'styled-components';
import { AppButton, AppFlex, AppParagraph } from 'components/atoms';
import { UnlinkIcon } from 'assets';

export const Container = styled(AppFlex)`
    width: 100%;
`;

export const StyledUnlinkIcon = styled(UnlinkIcon)`
    ${({ theme }) => css`
        margin-bottom: ${theme.sizes.marginLarge}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 50px;
            height: 50px;
        }
    `}
`;

export const StyledAppParagraph = styled(AppParagraph)`
    text-align: center;
    max-width: 350px;
`;

export const GoToLoginButton = styled(AppButton)`
    ${({ theme }) => css`
        width: 100%;
        max-width: 200px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            max-width: none;
        }
    `}
`;
