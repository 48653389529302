import { useMemo } from 'react';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { EditChildForParentFormFields } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { childrenTexts } from 'consts/text';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { ChildEditForParentForm } from 'components/templates/child/ChildEditForParentForm/ChildEditForParentForm';
import { parseEditChildForParentFormFieldsIntoUpdateChildForParentDTO } from 'utils/parsers/child/parseEditChildForParentFormFieldsIntoUpdateChildForParentDTO';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { parseChildForParentIntoEditChildForParentFormFields } from 'utils/parsers/child/parseChildForParentIntoEditChildForParentFormFields';
import { useChildDetailsPageNetworkManage } from 'pages/app/parent/child/hooks/useChildDetailsPageNetworkManage';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

type ChildEditProps = {
    childrenPagePath: string;
};

export const ParentChildEdit = ({ childrenPagePath }: ChildEditProps) => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { id: childId } = useCurrentChild();

    const redirectToChildrenPage = (replace?: boolean) =>
        statefulNavigate(childrenPagePath, { replace });

    const onChildEditSuccess = () => {
        displaySuccess(childrenTexts.successfullyUpdateChildrenMessage);
        redirectToChildrenPage(true);
    };

    const {
        mutate: updateChild,
        error: updateChildError,
        isLoading: isUpdateChildLoading,
    } = useAppMutation(ParentHTTPService.children.updateChild, {
        key: ['UPDATE_CHILD', childId],
        onSuccess: onChildEditSuccess,
        invalidateQueryKeys: [['CHILD', childId], ['DIETS_CHILD_DETAILS']],
    });

    const {
        childDetailsData,
        isChildDetailsFetching,
        isChildDetailsError,
        isDietDetailsFetching,
        isDietDetailsError,
        dietDetails,
    } = useChildDetailsPageNetworkManage();

    const childEditStepsFormInitialStates = useMemo(() => {
        if (!childDetailsData || !dietDetails) {
            return null;
        }
        return parseChildForParentIntoEditChildForParentFormFields(childDetailsData, dietDetails);
    }, [childDetailsData, dietDetails]);

    const onFinish = (childUpdateData: EditChildForParentFormFields) => {
        const updateChildDTO =
            parseEditChildForParentFormFieldsIntoUpdateChildForParentDTO(childUpdateData);
        updateChild({ childId, updateChildDTO });
    };

    if (isChildDetailsError || isDietDetailsError) {
        return <ErrorDisplay />;
    }

    return (
        <ChildEditForParentForm
            initialValues={childEditStepsFormInitialStates}
            isLoading={isChildDetailsFetching || isUpdateChildLoading || isDietDetailsFetching}
            axiosError={updateChildError}
            onFinish={onFinish}
        />
    );
};
