import styled, { css } from 'styled-components';
import { AppParagraph } from 'components/atoms';

export const StyledLegalInfo = styled(AppParagraph)`
    ${({ theme }) => css`
        &.ant-typography {
            align-self: center;
            max-width: 300px;
            margin-bottom: ${theme.sizes.marginLarge}px;
        }
    `}
`;
