import { createGlobalStyle, css } from 'styled-components';
import { Image, ImageProps } from 'antd';

export type AppImageProps = ImageProps;

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
      .ant-image-preview-root .ant-image-preview-img {
          max-width: 90%;
          border-radius: ${theme.borders.borderRadiusNormal}px;
      }
  `}
`;

export const AppImage = (props: AppImageProps) => (
    <>
        <GlobalStyle />
        <Image {...props} />
    </>
);
