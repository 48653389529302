import { ChildParent } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { parseAddressIntoJsonObject } from 'utils/parsers/jsonObject/common/parseAddressIntoJsonObject';

export const parseChildParentIntoJsonObject: (childParent: ChildParent) => object = (
    childParent,
) => {
    const {
        firstName,
        lastName,
        email,
        bankAccountNumber,
        phoneNumber,
        withAccount,
        address,
        active,
    } = childParent;

    const parsedBankAccountNumber = bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : EMPTY_VALUE_LABEL;
    const parsedPhoneNumber = phoneNumber
        ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber)
        : EMPTY_VALUE_LABEL;
    const addressJsonObject = address ? parseAddressIntoJsonObject(address) : EMPTY_VALUE_LABEL;

    const childParentWithouAccountJsonObject = {
        [jsonObjectsTexts.childParent.fields.firstName]: firstName,
        [jsonObjectsTexts.childParent.fields.lastName]: lastName,
        [jsonObjectsTexts.childParent.fields.email]: email || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.childParent.fields.bankAccountNumber]: parsedBankAccountNumber,
        [jsonObjectsTexts.childParent.fields.phoneNumber]: parsedPhoneNumber,
        [jsonObjectsTexts.childParent.fields.withAccount]: withAccount
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.childParent.fields.address]: addressJsonObject,
    };

    if (!email) {
        return childParentWithouAccountJsonObject;
    }

    return {
        ...childParentWithouAccountJsonObject,
        [jsonObjectsTexts.childParent.fields.active]: active
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
