import { AxiosResponse } from 'axios';
import { InfiniteData } from '@tanstack/react-query';

type PaginationResponseBase<ResponseData> = { content: ResponseData[] };
export const parsePageableResponseDataIntoArray = <ResponseData>(
    pageableData: InfiniteData<AxiosResponse<PaginationResponseBase<ResponseData>, unknown>>,
) =>
    pageableData.pages.reduce<ResponseData[]>(
        (allData, pageData) => [...allData, ...pageData.data.content],
        [],
    );
