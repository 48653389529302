import { Tree } from 'antd';
import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const AppTreeStyled = styled(Tree)`
    ${({ theme }) => css`
        &.ant-tree .ant-tree-list {
            border-radius: 12px;
            border: 1px solid ${theme.colors.primary.primary5};
            overflow: auto;

            ${getTextStyle('BodyMedium')}
            .ant-tree-list-holder-inner {
                .ant-tree-treenode-motion {
                    width: 100%;
                }

                .ant-tree-treenode {
                    padding: 12px;
                    width: 100%;
                    height: fit-content;
                    align-items: center;
                    border-bottom: 1px solid ${theme.colors.primary.primary4};

                    &:last-child {
                        border-bottom-width: 0;
                    }

                    &:has(.ant-tree-node-content-wrapper .ant-tree-title .level1) {
                        background-color: ${theme.colors.grayscale.gray1};
                    }

                    &:has(.ant-tree-node-content-wrapper .ant-tree-title .level2) {
                        background-color: ${theme.colors.primary.primary2};
                    }

                    &:has(.ant-tree-node-content-wrapper .ant-tree-title .level3) {
                        background-color: ${theme.colors.primary.primary3};
                    }

                    .ant-tree-switcher {
                        align-self: center;
                    }

                    .ant-tree-checkbox {
                        order: 3;
                        margin: 10px;
                        align-self: center;

                        .ant-tree-checkbox-inner {
                            height: 20px;
                            width: 20px;
                        }
                    }

                    .ant-tree-node-content-wrapper {
                        width: 100%;

                        .site-tree-search-value {
                            color: ${theme.colors.primary.primary10};
                        }
                    }
                }
            }
        }
    `}
`;
