import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useSetAtom } from 'jotai';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { AppButton } from 'components/atoms/CommonAppComponents';
import { useOtherAbilities } from 'abilities';
import { institutionTexts } from 'consts/text';
import { StyledPlusIcon } from 'components/atoms/StyledIcons';

const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown:has(.institutionDropdown) {
      min-width: 220px !important;
  }
`;

export const DropDownRender = ({ menu }: { menu: React.ReactElement }) => {
    const setIsLoggedUserDrawerMenuOpen = useSetAtom(isLoggedUserDrawerMenuOpenAtom);
    const navigate = useNavigate();
    const {
        otherAbilities: { checkIfCanAddInstitution },
    } = useOtherAbilities();

    const canAddInstitution = checkIfCanAddInstitution();

    const navigateToInstitutionAdd = () => {
        navigate(appPaths.app['add-institution']);
        setIsLoggedUserDrawerMenuOpen(false);
    };

    return (
        <div className="institutionDropdown">
            <GlobalStyle />
            {menu}
            {canAddInstitution && (
                <AppButton
                    marginTop={8}
                    fullWidth
                    type="primary"
                    icon={<StyledPlusIcon />}
                    onClick={navigateToInstitutionAdd}
                >
                    {institutionTexts.addInstitution}
                </AppButton>
            )}
        </div>
    );
};
