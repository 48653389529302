import { useCallback } from 'react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useCurrentChild } from 'jotaiAtoms/currentChild';

export const useInvalidateMailThreads = () => {
    const queryClient = useQueryClient();

    const { id: currentChildId, institutionAddress: currentChildInstitutionAddress } =
        useCurrentChild();
    const { id: currentInstitutionId } = useCurrentInstitution();

    const currentChildInstitutionId = currentChildInstitutionAddress.institutionId || '';

    const invalidateMailThreadsData = useCallback(async () => {
        const mailThreadQueryKey: QueryKey = currentChildId
            ? [currentChildInstitutionId, currentChildId, 'MAIL_THREAD']
            : [currentInstitutionId, 'MAIL_THREAD'];
        const mailThreadsQueryKey: QueryKey = currentChildId
            ? [currentChildInstitutionId, currentChildId, 'MAIL_THREADS']
            : [currentInstitutionId, 'MAIL_THREADS'];
        const messagesQueryKey = currentChildId
            ? [currentChildInstitutionId, currentChildId, 'MESSAGES']
            : [currentInstitutionId, 'MESSAGES'];

        await Promise.all([
            queryClient.invalidateQueries(mailThreadQueryKey),
            queryClient.invalidateQueries(mailThreadsQueryKey),
            queryClient.invalidateQueries(messagesQueryKey),
        ]);
    }, [currentChildId, currentChildInstitutionId, currentInstitutionId, queryClient]);

    const invalidateUnreadMailThreadsStatusData = useCallback(async () => {
        const unreadMailThreadsStatusQueryKey: QueryKey = currentChildId
            ? [currentChildInstitutionId, currentChildId, 'UNREAD_MAIL_THREADS']
            : [currentInstitutionId, 'UNREAD_MAIL_THREADS'];
        await queryClient.invalidateQueries(unreadMailThreadsStatusQueryKey);
    }, [currentChildId, currentChildInstitutionId, currentInstitutionId, queryClient]);

    return { invalidateMailThreadsData, invalidateUnreadMailThreadsStatusData };
};
