import styled, { css } from 'styled-components';
import { GroupMailThreadBlockingSendMessageFormInfo } from 'components/molecules';
import { MessageAddForm, MessageAddFormProps } from 'components/organisms';
import { MailThreadId } from 'types';

export type SendMessageFormContentProps = Omit<MessageAddFormProps, 'className'> & {
    mailThreadId: MailThreadId;
    replyMailThreadId?: MailThreadId;
    isVisibleBlockingInfo: boolean;
};

const SendMessageFormContainer = styled.div`
    ${({ theme }) => css`
        padding: 24px;
        background-color: ${theme.colors.grayscale.gray1};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            padding: ${theme.sizes.marginNormal}px;
        }
    `}
`;

export const SendMessageFormContent = ({
    mailThreadId,
    replyMailThreadId,
    isVisibleBlockingInfo,
    ...restProps
}: SendMessageFormContentProps) => (
    <SendMessageFormContainer>
        {isVisibleBlockingInfo ? (
            <GroupMailThreadBlockingSendMessageFormInfo
                mailThreadId={mailThreadId}
                replyMailThreadId={replyMailThreadId}
            />
        ) : (
            <MessageAddForm {...restProps} />
        )}
    </SendMessageFormContainer>
);
