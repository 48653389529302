import { generatePath } from 'react-router-dom';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { journalTexts } from 'consts/text';
import { parseSpecialConsultationFormFieldsIntoAddUpdateSpecialConsultationDTO } from 'utils/parsers/specialConsultation/parseSpecialConsultationFormFieldsIntoAddUpdateGroupConsultationDTO';
import { SpecialConsultationFormFields } from 'types';
import { SpecialJournalConsultationAddEditForm } from 'components/templates';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const SpecialJournalConsultationAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const handleAddConsultationSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.common.consultations.successfullyAddedConsultation,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addConsultation,
        error: addConsultationError,
        isLoading: isAddConsultationLoading,
    } = useAppMutation(StaffHTTPService.specialConsultations.addSpecialConsultation, {
        key: ['ADD_SPECIAL_CONSULTATION'],
        onSuccess: handleAddConsultationSuccess,
        invalidateQueryKeys: [['SPECIAL_CONSULTATIONS', journalId]],
    });

    const handleAddConsultationFinish = (consultationFormData: SpecialConsultationFormFields) => {
        const parsedAddConsultationDTO =
            parseSpecialConsultationFormFieldsIntoAddUpdateSpecialConsultationDTO(
                consultationFormData,
            );

        addConsultation({ journalId, addSpecialConsultationDTO: parsedAddConsultationDTO });
    };

    return (
        <SpecialJournalConsultationAddEditForm
            mode="add"
            journalId={journalId}
            axiosError={addConsultationError}
            isLoading={isAddConsultationLoading}
            onFinish={handleAddConsultationFinish}
        />
    );
};
