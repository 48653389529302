import { AbsencesDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseAbsenceDTOIntoJsonObject } from './parseAbsenceDTOIntoJsonObject';

export const parseAbsencesDTOIntoJsonObject: (absencesDTO: AbsencesDTO) => object = (
    absencesDTO,
) => {
    const absenceJsonObjects = absencesDTO.absences.map(parseAbsenceDTOIntoJsonObject);

    return {
        [jsonObjectsTexts.absencesDTO.fields.absences]: absenceJsonObjects,
    };
};
