import { styled } from 'styled-components';

export const ItemGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
