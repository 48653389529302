import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { DualValueCellContent } from 'components/atoms';
import { PriceDisplay } from '../PriceDisplay/PriceDisplay';
import { DateDisplay } from '../DateDisplay/DateDisplay';

type AmountWithDateDisplayProps = {
    amount: number;
    date: string;
};

export const DateWithAmountDisplay = ({ amount, date }: AmountWithDateDisplayProps) => {
    const isMobile = useIsMobile();
    const parsedDate = parseStringIntoFormattedStringDate(date);

    return isMobile ? (
        <DualValueCellContent
            mainValue={<PriceDisplay value={amount} />}
            secondaryValue={parsedDate}
        />
    ) : (
        <DateDisplay date={date} />
    );
};
