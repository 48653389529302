import styled, { css } from 'styled-components';
import { AppList } from 'components/atoms/CommonAppComponents';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { MessageMemberName } from 'components/atoms/MessageMemberName/MessageMemberName';

export const StyledAppListItem = styled(AppList.Item)`
    ${({ theme }) => css`
        &.ant-list-item {
            padding: 6px 8px;
            border-block-end: 1px solid ${theme.colors.primary.primary3};
        }
    `}
`;

export const InnerContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['isActive']),
})<{ isActive: boolean }>`
    ${({ theme, isActive }) => css`
        width: 100%;
        padding: 6px 8px;
        border-radius: ${theme.borders.borderRadiusSmall}px;
        cursor: pointer;
        background-color: ${isActive ? theme.colors.primary.primary3 : 'transparent'};

        &:hover {
            background-color: ${theme.colors.primary.primary2};
        }
    `}
`;

export const MailThreadInfoContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${theme.sizes.marginSmall}px;
    `}
`;

export const MailThreadSenderName = styled(MessageMemberName)`
    width: 70%;
`;
