import { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    IconBellRinging2,
    IconCalendarMinus,
    IconCalendarOff,
    IconTrash,
} from '@tabler/icons-react';
import { AppTable } from 'components/organisms';
import { DailyAttendanceTypeDisplay, JournalAttendanceFilters } from 'components/molecules';
import { Option } from 'components/atoms';
import {
    GroupChildDailyAttendanceDTO,
    DayAttendanceAbsentType,
    Attendance,
    ChildId,
    AppColumnsType,
    DayAttendanceRemote,
} from 'types';
import { childDailyAttendanceDTOFields } from 'consts/attendance/childDailyAttendanceDTOFields';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { getFullName } from 'utils/getFullName';
import { dayAttendanceDTOFields } from 'consts/attendance/dayAttendanceDTOFields';
import { commonTexts, journalTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useGroupJournalDailyAttendancePageNetworkManage } from './hooks/useGroupJournalDailyAttendancePageNetworkManage';
import { DailyAttendanceEditableCell, DailyAttendanceEditableRow } from './components';

const baseColumnsData: AppColumnsType<GroupChildDailyAttendanceDTO> = [
    {
        title: commonTexts.dataLabels.lastnameAndFirstname,
        dataIndex: childDailyAttendanceDTOFields.child,
        key: childDailyAttendanceDTOFields.child,
        render: (child: GroupChildDailyAttendanceDTO['child']) =>
            getFullName(child.firstName, child.lastName),
        width: '20%',
        mobileWidth: '80%',
    },
    {
        title: journalTexts.journalDetails.groupJournal.attendance.daily.entryTime,
        dataIndex: childDailyAttendanceDTOFields.attendance,
        key: dayAttendanceDTOFields.entryTime,
        render: (dayAttendance: GroupChildDailyAttendanceDTO['attendance']) => (
            <DailyAttendanceTypeDisplay dayAttendance={dayAttendance} />
        ),
        width: '20%',
        editable: true,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.attendance.daily.exitTime,
        dataIndex: childDailyAttendanceDTOFields.attendance,
        key: dayAttendanceDTOFields.exitTime,
        render: (dayAttendance: GroupChildDailyAttendanceDTO['attendance']) => (
            <DailyAttendanceTypeDisplay dayAttendance={dayAttendance} />
        ),
        width: '60%',
        editable: true,
        isVisibleAsExtendableInMobile: true,
    },
];

export const GroupJournalDailyAttendancePage = () => {
    const { journalId } = useJournalId();
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const { statefulNavigate } = useNavigateWithState();
    const {
        genericAbilities: { checkIfCanEditAttendanceForSelectedDate },
        groupJournalsAbilities: { checkIfCanManageAttendance },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const parsedDateFilterValue = dateFilterValue
        ? parseStringIntoDayjsDate(dateFilterValue)
        : dayjs();
    const selectedDate = dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD');
    const selectedAttendanceType: Attendance = 'DAILY';
    const canManageAttendanceBase = checkIfCanManageAttendance();
    const canEditAttendanceForSelectedDate =
        checkIfCanEditAttendanceForSelectedDate(parsedDateFilterValue);
    const canManageAttendance = canManageAttendanceBase && canEditAttendanceForSelectedDate;

    const {
        dailyAttendanceData,
        firstAndLastJournalSchoolYears,
        isDailyAttendanceDataError,
        isJournalDataError,
        isDailyAttendanceDataInitialLoading,
        isJournalDataFetching,
        isUpdateDailyAttendanceLoading,
        isDeleteDailyAttendanceLoading,
        isDailyAttendanceDataRefetching,
        updateDailyAttendance,
        deleteDailyAttendance,
    } = useGroupJournalDailyAttendancePageNetworkManage({
        journalId,
        dateFilterValue: selectedDate,
    });

    const handleSetAbsentAttendance = (
        dayAttendanceType: DayAttendanceAbsentType | DayAttendanceRemote,
        childId: ChildId,
    ) =>
        updateDailyAttendance({
            journalId,
            childId,
            date: selectedDate,
            updateDayAttendanceDTO: { type: dayAttendanceType },
        });

    const handleSetPresentAttendance = useCallback(
        (childDailyAttendance: GroupChildDailyAttendanceDTO) => {
            const attendance = childDailyAttendance[childDailyAttendanceDTOFields.attendance];
            updateDailyAttendance({
                journalId,
                childId: childDailyAttendance[childDailyAttendanceDTOFields.child].id,
                date: selectedDate,
                updateDayAttendanceDTO: {
                    type: 'PRESENT',
                    entryTime: attendance?.[dayAttendanceDTOFields.entryTime],
                    exitTime: attendance?.[dayAttendanceDTOFields.exitTime],
                },
            });
        },
        [journalId, selectedDate, updateDailyAttendance],
    );

    const options: Option<GroupChildDailyAttendanceDTO>[] = [
        {
            label: journalTexts.journalDetails.groupJournal.attendance.daily.remoteAbsent,
            onClick: (childDailyAttendance: GroupChildDailyAttendanceDTO) =>
                handleSetAbsentAttendance(
                    'REMOTE',
                    childDailyAttendance[childDailyAttendanceDTOFields.child].id,
                ),
            Icon: <IconBellRinging2 />,
        },
        {
            label: journalTexts.journalDetails.groupJournal.attendance.daily.plannedAbsent,
            onClick: (childDailyAttendance: GroupChildDailyAttendanceDTO) =>
                handleSetAbsentAttendance(
                    'ABSENT_PLANNED',
                    childDailyAttendance[childDailyAttendanceDTOFields.child].id,
                ),
            Icon: <IconCalendarMinus />,
        },
        {
            label: journalTexts.journalDetails.groupJournal.attendance.daily.notReportedAbsent,
            onClick: (childDailyAttendance: GroupChildDailyAttendanceDTO) =>
                handleSetAbsentAttendance(
                    'ABSENT',
                    childDailyAttendance[childDailyAttendanceDTOFields.child].id,
                ),
            Icon: <IconCalendarOff />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: (childDailyAttendance: GroupChildDailyAttendanceDTO) =>
                deleteDailyAttendance({
                    journalId,
                    childId: childDailyAttendance[childDailyAttendanceDTOFields.child].id,
                    date: selectedDate,
                }),
            Icon: <IconTrash />,
        },
    ];

    const handleAttendanceTypeChange = (newAttendanceType: Attendance) => {
        if (newAttendanceType === selectedAttendanceType) {
            return;
        }
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.monthly.base,
                { journalId },
            ),
        );
    };

    const columns = useMemo(
        () =>
            baseColumnsData.map((baseColumnData) => {
                if (!baseColumnData.editable) {
                    return baseColumnData;
                }
                return {
                    ...baseColumnData,
                    onCell: (childDailyAttendance: GroupChildDailyAttendanceDTO) => ({
                        childDailyAttendance,
                        selectedDate,
                        editable: baseColumnData.editable,
                        title: baseColumnData.title,
                        cellKey: baseColumnData.key,
                        disabled: !canManageAttendance,
                        handleSave: handleSetPresentAttendance,
                    }),
                };
            }),
        [canManageAttendance, handleSetPresentAttendance, selectedDate],
    );

    const filters = firstAndLastJournalSchoolYears ? (
        <JournalAttendanceFilters
            attendanceType={selectedAttendanceType}
            date={parsedDateFilterValue}
            firstSchoolYear={firstAndLastJournalSchoolYears?.firstYear}
            lastSchoolYear={firstAndLastJournalSchoolYears?.lastYear}
            isDatePickerDisabled={isJournalDataFetching}
            onDateChange={handleChangeDateFilter}
            onClickNextOrPrevDate={handleClickNextOrPrevDate}
            onAttendanceTypeChange={handleAttendanceTypeChange}
        />
    ) : null;

    const isFreeFromSchoolDay = dailyAttendanceData?.freeFromSchool;

    return (
        <AppTable
            title={commonTexts.dataLabels.childList}
            emptyInfo={
                isFreeFromSchoolDay
                    ? commonTexts.emptyInfo.noLessons
                    : commonTexts.emptyInfo.noChildren
            }
            options={canManageAttendance ? options : undefined}
            columns={columns as AppColumnsType<GroupChildDailyAttendanceDTO>}
            dataSource={dailyAttendanceData?.childrenAttendance}
            rowKey={({ child }) => child.id}
            isError={isDailyAttendanceDataError || isJournalDataError}
            isDataInitialLoading={isDailyAttendanceDataInitialLoading}
            isDataRefetching={isDailyAttendanceDataRefetching}
            isOptionsLoading={isUpdateDailyAttendanceLoading || isDeleteDailyAttendanceLoading}
            tableManageUtilsProps={{
                isVisibleSearchFilter: false,
                additionalFilters: filters,
            }}
            editableCellsProps={{
                editableRow: DailyAttendanceEditableRow,
                editableCell: DailyAttendanceEditableCell,
            }}
        />
    );
};
