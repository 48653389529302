import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';

export const GroupInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Nazwa grupy"
        name={formFields.name}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...props}
    >
        <AppInput />
    </AppFormItem>
);
