export const parentTableItemFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    bankAccountNumber: 'bankAccountNumber',
    id: 'id',
    userId: 'userId',
    email: 'email',
    phoneNumber: 'phoneNumber',
    street: 'street',
    zipCode: 'zipCode',
    city: 'city',
} as const;
