import { ModalVariation } from 'types';
import { TrashIcon } from 'assets';
import { commonTexts } from 'consts/text/index';
import { AppConfirmModal } from '../../AppConfirmModal/AppConfirmModal';

export const RemoveModal = (props: ModalVariation) => (
    <AppConfirmModal
        icon={<TrashIcon />}
        okButtonProps={{ title: commonTexts.actionLabels.confirm, danger: true }}
        cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
        {...props}
    />
);
