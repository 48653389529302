import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import useForm from 'antd/es/form/hooks/useForm';
import { CommonFormProps, FormModeDependedTexts } from 'types';
import { GroupTopicFormFields } from 'types/forms';
import { commonTexts, journalTexts } from 'consts/text';
import {
    AppForm,
    AppFormProps,
    JournalTeachersPicker,
    TopicCoreCurriculumPicker,
    TopicEducationalGuideFormSection,
} from 'components/organisms';
import {
    DatePickerElement,
    FormNavButtons,
    TopicHoursTimePicker,
    LessonNoteInputElement,
    TopicNameInputElement,
    TeacherPickerVersion,
} from 'components/molecules';
import { AppFormItem } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { useDependentFormFields } from 'hooks/useDependentFormFields/useDependentFormFields';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

const addModeTexts: FormModeDependedTexts = {
    name: 'groupTopicAdd',
    title: journalTexts.journalDetails.groupJournal.topics.topicAddFormTitle,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'groupTopicEdit',
    title: journalTexts.journalDetails.groupJournal.topics.topicEditFormTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

type GroupTopicAddEditFormProps = Omit<
    AppFormProps<GroupTopicFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (groupTopicData: GroupTopicFormFields) => void;
    };

export const GroupTopicAddEditForm = ({
    mode,
    form,
    initialValues,
    axiosError,
    ...props
}: GroupTopicAddEditFormProps) => {
    const [formInstance] = useForm(form);
    const {
        watchedFieldsValues: watchedDateValue,
        handleWatchedFieldsValuesChange: handleWatchedDateValueChange,
    } = useDependentFormFields({
        watchedFields: [formFields.date],
        dependentFields: [formFields.teacherIds],
        formInstance,
    });

    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const { date: dateValue } = watchedDateValue;
    const parsedDate = dateValue ? parseDayjsDateIntoString(dateValue, 'YYYY-MM-DD') : undefined;

    const groupTopicAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<GroupTopicFormFields>
            form={formInstance}
            maxWidth={570}
            name={name}
            title={title}
            error={groupTopicAddEditError}
            withGoBack
            {...props}
        >
            <TopicEducationalGuideFormSection />
            <TopicNameInputElement />
            <DatePickerElement isHalfParentWidth onChange={handleWatchedDateValueChange} />
            <AppFormItem justifyContent="space-between" doubleItem>
                <TopicHoursTimePicker mode="start" />
                <TopicHoursTimePicker mode="finish" />
            </AppFormItem>
            <LessonNoteInputElement />
            <TopicCoreCurriculumPicker />
            <JournalTeachersPicker
                version={TeacherPickerVersion.MULTIPLE}
                date={parsedDate}
                disabled={!parsedDate}
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
            />
        </AppForm>
    );
};
