import styled, { css } from 'styled-components';
import { AppMenu } from 'components/atoms';

export const Container = styled.div`
    ${({ theme }) => css`
        width: 350px;
        padding-block: 24px;
        border-right: 1px solid ${theme.colors.primary.primary4};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
            padding-block: ${theme.sizes.marginNormal}px 0;
        }
    `}
`;

export const FiltersContainer = styled.div`
    ${({ theme }) => css`
        margin-block: ${theme.sizes.marginNormal}px 24px;
        padding-inline: ${theme.sizes.marginNormal}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            margin-top: 0;
        }
    `}
`;

export const StyledAppMenu = styled(AppMenu)`
    ${({ theme }) => css`
        margin-top: ${theme.sizes.marginNormal}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            margin-top: 12px;
        }
    `}
`;

export const MailThreadListContainer = styled.div`
    ${({ theme }) => css`
        height: calc(100% - 180px);
        padding-inline: ${theme.sizes.marginNormal}px ${theme.sizes.marginSmall}px;
        display: flex;
        flex-direction: column;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            height: calc(100% - 220px);
            padding-right: 0;
        }
    `}
`;
