import { FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import pick from 'lodash/pick';

type DependentFormFieldsProps<FormFields> = {
    watchedFields: string[];
    dependentFields: string[];
    formInstance: FormInstance<FormFields>;
};

export const useDependentFormFields = <FormFields>({
    watchedFields,
    dependentFields,
    formInstance,
}: DependentFormFieldsProps<FormFields>) => {
    const formFields = useWatch<FormFields>([], formInstance);

    const handleWatchedFieldsValuesChange = () => formInstance.resetFields([...dependentFields]);

    const watchedFieldsValues = pick(formFields, watchedFields);

    return { watchedFieldsValues, handleWatchedFieldsValuesChange };
};
