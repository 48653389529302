import {
    AppFormItem,
    AppFormItemProps,
    AppInputNumber,
    AppInputNumberProps,
} from 'components/atoms';

type SimpleInputNumberElementProps = AppFormItemProps &
    Pick<AppInputNumberProps, 'addonAfter' | 'precision' | 'min' | 'max'>;

const MAX_VALUE = 999999999;
const MIN_VALUE = 0;

export const SimpleInputNumberElement = ({
    disabled,
    addonAfter,
    precision,
    min,
    max,
    ...restProps
}: SimpleInputNumberElementProps) => (
    <AppFormItem disabled={disabled} {...restProps}>
        <AppInputNumber
            autoComplete="off"
            disabled={disabled}
            min={min ?? MIN_VALUE}
            max={max ?? MAX_VALUE}
            precision={precision}
            addonAfter={addonAfter}
        />
    </AppFormItem>
);
