import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const SubTableBody = styled.tbody`
    ${({ theme }) => css`
        width: 100%;
        background-color: ${theme.colors.primary.primary2};
    `}
`;

export const SubTableRow = styled.tr`
    width: 100%;
`;
export const SubTableCell = styled.td`
    ${({ theme }) => css`
        &:first-child {
            span {
                ${getTextStyle('BodyMSSemiBold')};
                padding-right: 16px;
            }
        }
        &:last-child {
            width: 100%;
        }
        padding: 12px;
        border-bottom: 1px solid ${theme.colors.primary.primary3};
    `}
`;
