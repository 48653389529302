import { ImportantEventDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseImportantEventDTOIntoJsonObject: (
    importantEventDTO: ImportantEventDTO,
) => object = (importantEventDTO) => {
    const { name, date, description } = importantEventDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);

    return {
        [jsonObjectsTexts.importantEventDTO.fields.name]: name,
        [jsonObjectsTexts.importantEventDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.importantEventDTO.fields.description]: description,
    };
};
