import { TreeProps } from 'antd/es/tree';
import * as React from 'react';
import { AppTreeStyled } from './AppTree.styles';

export type AppTreeProps = TreeProps;

export type ExtendedDataNode = {
    key: React.Key;
    title: string | React.ReactNode | ((data: ExtendedDataNode) => React.ReactNode);
    children?: ExtendedDataNode[];
    checkable?: boolean;
    disableCheckbox?: boolean;
    disabled?: boolean;
};

export const AppTree = (props: AppTreeProps) => <AppTreeStyled {...props} />;
