import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import dayjs from 'dayjs';
import { StyledAddButton } from 'pages/app/parent/groupAttendance/ParentGroupAttendancePage/ParentGroupAttendancePage.styled';
import { DatePickerWithArrows } from 'components/molecules';
import { parentTexts, settlementTexts } from 'consts/text';
import { AppText } from 'components/atoms';
import { BalanceTag } from 'components/molecules/BalanceTag/BalanceTag';
import { Col } from 'antd';
import { IconWallet } from '@tabler/icons-react';
import { ParentSettlementItem } from 'components/templates/settlement/ParentSettlementItem/ParentSettlementItem';
import { appPaths } from 'consts/paths/paths';
import { SummaryText } from 'components/atoms/SummaryText/SummaryText';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { PageBaseLayout } from 'components/layouts';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useParentSettlementPageNetworkManage } from './hooks/useParentSettlementPageNetworkManage';
import {
    FiltersContainer,
    ReversedRow,
    StyledColumn,
    StyledRow,
} from './ParentSettlementPage.styled';

export const ParentSettlementPage = () => {
    const { statefulNavigate } = useNavigateWithState();

    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });

    const parsedDateFilterValue = dateFilterValue
        ? parseStringIntoDayjsDate(dateFilterValue)
        : dayjs();
    const selectedYear = parsedDateFilterValue.year();
    const selectedMonth = getMonthFromDayjsMonth(parsedDateFilterValue.month());

    const {
        childMonthlySettlementData,
        childCostSplitsData,
        isChildMonthlySettlementInitialFetching,
        isChildCostSplitsInitialFetching,
        isChildMonthlySettlementRefetching,
        isChildCostSplitsRefetching,
        isChildMonthlySettlementError,
        isChildCostSplitsError,
    } = useParentSettlementPageNetworkManage({ selectedYear, selectedMonth });

    const handlePayButtonClick = () =>
        statefulNavigate(appPaths.app.parent.settlement.payment, {
            state: { year: selectedYear, month: selectedMonth },
        });

    const additionalFilters = (
        <FiltersContainer>
            <Col>
                <ReversedRow align="bottom">
                    <DatePickerWithArrows
                        picker="month"
                        format="MMMM"
                        label={parentTexts.groupAttendance.table.selectMonth}
                        value={parsedDateFilterValue}
                        onChange={handleChangeDateFilter}
                        onArrowButtonClick={(arrowButtonType) => {
                            if (arrowButtonType === 'NEXT') {
                                handleClickNextOrPrevDate?.('NEXT', 'month');
                            } else {
                                handleClickNextOrPrevDate?.('PREVIOUS', 'month');
                            }
                        }}
                    />
                    <StyledRow>
                        <AppText marginLeft={0}>{settlementTexts.children.details.balance}</AppText>
                        <BalanceTag amount={childMonthlySettlementData?.balance || 0} />
                    </StyledRow>
                </ReversedRow>
            </Col>
            <Col>
                <ReversedRow align="bottom">
                    <SummaryText
                        text={
                            childMonthlySettlementData
                                ? getFormattedPrice(childMonthlySettlementData.totalCost)
                                : getFormattedPrice(0)
                        }
                    />
                    <StyledAddButton
                        addButtonLabel={parentTexts.settlement.payButton}
                        onClick={handlePayButtonClick}
                        icon={<IconWallet />}
                    />
                </ReversedRow>
            </Col>
        </FiltersContainer>
    );

    return (
        <PageBaseLayout
            isDataInitialLoading={
                isChildMonthlySettlementInitialFetching || isChildCostSplitsInitialFetching
            }
            isDataRefetching={isChildMonthlySettlementRefetching || isChildCostSplitsRefetching}
            isError={isChildMonthlySettlementError || isChildCostSplitsError}
        >
            <StyledColumn>
                {additionalFilters}
                {childMonthlySettlementData && (
                    <ParentSettlementItem
                        costSplits={childCostSplitsData}
                        {...childMonthlySettlementData}
                    />
                )}
            </StyledColumn>
        </PageBaseLayout>
    );
};
