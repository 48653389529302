import { EditAdditionalLessonDTO, AdditionalLessonsFormFields } from 'types';

export const parseAdditionalLessonsFormFieldsIntoEditAdditionalLessonDTO = (
    additionalLessonsFormFields: AdditionalLessonsFormFields,
): EditAdditionalLessonDTO => {
    const { additionalLessonPaymentType, ...restFields } = additionalLessonsFormFields;

    return {
        paymentType: additionalLessonPaymentType,
        ...restFields,
    };
};
