import { ColorMapper, ComponentMapper } from 'types';
import { colors } from 'theme/styledComponents/colors';
import { BannerMode } from './AppFormBanner.types';
import { StyledAlertOctagon, StyledAlertTriangle } from './AppFormBanner.styled';

export const iconsByBannerMode: ComponentMapper<BannerMode> = {
    WARNING: <StyledAlertTriangle />,
    ERROR: <StyledAlertOctagon />,
};

export const bannerTextColorsByBannerMode: ColorMapper<BannerMode> = {
    ERROR: {
        textColor: colors.red.red7,
        backgroundColor: colors.red.red2,
        borderColor: colors.red.red3,
    },
    WARNING: {
        textColor: colors.yellow.yellow7,
        backgroundColor: colors.yellow.yellow2,
        borderColor: colors.yellow.yellow3,
    },
};
