import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { GroupConsultationFormFields, GroupConsultationId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { GroupJournalConsultationAddEditForm } from 'components/templates';
import { formFields } from 'consts/form/formFields';
import { parseGroupConsultationDTOIntoGroupConsultationFormFields } from 'utils/parsers/groupConsultation/parseGroupConsultationDTOIntoGroupConsultationFormFields';
import { parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO } from 'utils/parsers/groupConsultation/parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO';
import { journalTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalConsultationEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<GroupConsultationFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { consultationId: consultationIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const consultationId = (consultationIdParam || '') as GroupConsultationId;

    const {
        data: consultationData,
        isFetching: isConsultationDataFetching,
        isError: isFetchConsultationDataError,
    } = useAppQuery(
        'CONSULTATION',
        [journalId, consultationId],
        () => StaffHTTPService.groupConsultations.getGroupConsultation(journalId, consultationId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (consultationData && !form.getFieldValue(formFields.subject)) {
            setFormData(parseGroupConsultationDTOIntoGroupConsultationFormFields(consultationData));
        }
    }, [consultationData, form, setFormData]);

    const navigateToConsultationsPage = () =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.consultations.base,
                { journalId },
            ),
            { replace: true },
        );

    const handleUpdateConsultationSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.common.consultations.successfullyUpdatedConsultation,
        );
        navigateToConsultationsPage();
    };

    const {
        mutate: updateConsultation,
        error: updateConsultationError,
        isLoading: isUpdateConsultationLoading,
    } = useAppMutation(StaffHTTPService.groupConsultations.updateGroupConsultation, {
        key: ['UPDATE_CONSULTATION'],
        onSuccess: handleUpdateConsultationSuccess,
        invalidateQueryKeys: [
            ['CONSULTATIONS', journalId],
            ['CONSULTATION', journalId, consultationId],
        ],
    });

    const handleUpdateConsultationFinish = (consultationFormData: GroupConsultationFormFields) => {
        const parsedUpdateConsultationDTO =
            parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO(consultationFormData);
        updateConsultation({
            journalId,
            consultationId,
            updateGroupConsultationDTO: parsedUpdateConsultationDTO,
        });
    };

    if (isFetchConsultationDataError) {
        return <ErrorDisplay />;
    }

    return (
        <GroupJournalConsultationAddEditForm
            mode="edit"
            journalId={journalId}
            form={form}
            initialValues={formInitialData}
            axiosError={updateConsultationError}
            isLoading={isConsultationDataFetching || isUpdateConsultationLoading}
            onFinish={handleUpdateConsultationFinish}
        />
    );
};
