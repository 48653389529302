import { useTheme } from 'styled-components';
import {
    WeeklyScheduleCardBase,
    AppParagraph,
    WeeklyScheduleCardBaseProps,
} from 'components/atoms';
import { journalTexts } from 'consts/text';
import { StyledTextContainer } from './WeeklyScheduleCard.styled';

type WeeklyScheduleCardProps = Pick<WeeklyScheduleCardBaseProps, 'dayLabel'> & {
    description: string;
};

export const WeeklyScheduleCard = ({ dayLabel, description }: WeeklyScheduleCardProps) => {
    const theme = useTheme();

    const renderContent = () =>
        description ? (
            // eslint-disable-next-line @typescript-eslint/naming-convention
            <StyledTextContainer dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
            <AppParagraph margin={0} textType="BodyMedium" color={theme.colors.grayscale.gray9}>
                {
                    journalTexts.journalDetails.groupJournal.schedules.weeklySchedule
                        .noScheduledLessons
                }
            </AppParagraph>
        );

    return <WeeklyScheduleCardBase dayLabel={dayLabel}>{renderContent()}</WeeklyScheduleCardBase>;
};
