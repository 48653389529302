import { useParams } from 'react-router-dom';
import {
    ChildId,
    DiscountDTO,
    DiscountFormFields,
    DiscountId,
    DiscountTarget,
    DiscountType,
    EditDiscountFormFields,
} from 'types';
import { commonTexts, settlementTexts } from 'consts/text';
import {
    allowanceFields,
    AppForm,
    coFinancingFields,
    percentageDiscountFields,
} from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseDiscountDTOToDiscountFormFields } from 'utils/parsers/discounts/parseDiscountDTOToDiscountFormFields';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { discountTargetMapper, discountTypeMapper } from 'consts/discounts/discountsMappers';
import { FormNavButtons } from 'components/molecules';
import { GridItem } from 'components/templates/child/ChildDetails/InfoContainerBase.styled';
import { DetailsFrameBase, DetailsColumn, DetailsRow } from 'components/atoms';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { checkIfDiscountEditFormDataAreEqualAfterChanges } from 'utils/parsers/discounts/checkIfDiscountEditFormDataAreEqualAfterChanges';
import { parseEditDiscountsFormFieldsToAddDiscountDTO } from 'utils/parsers/discounts/parseAddDiscountsFormFieldsToAddDiscountDTO';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useChildrenDetailsPath } from 'hooks/useChildrenDetailsPath/useChildrenDetailsPath';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ChildEditDiscountPage = () => {
    const { form, setFormData, formInitialData } = useEditForm<DiscountFormFields>();
    const { discountId, childId } = useParams<{ discountId: DiscountId; childId: ChildId }>();
    const { displaySuccess, displayError } = useSimpleNotification();
    const { getChildrenDetailsPath } = useChildrenDetailsPath();
    const { statefulNavigate } = useNavigateWithState();

    const { data: discountData, isFetching: isFetchingDiscountData } = useAppQuery(
        'CHILD_DISCOUNT',
        [discountId, childId],
        () => StaffHTTPService.discounts.getDiscount(discountId as DiscountId, childId as ChildId),
        {
            onSuccess: (data: DiscountDTO) => {
                setFormData(parseDiscountDTOToDiscountFormFields(data));
            },
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const { data: extraLessonsData, isFetching: isFetchingExtraLessons } = useAppQuery(
        'EXTRA_LESSONS',
        [childId],
        () => StaffHTTPService.settlements.getExtraLessonsByChild(childId as ChildId),
        { enabled: discountData?.discountTarget === DiscountTarget.EXTRA_LESSONS },
    );

    const navigateToChildPage = () => {
        if (!childId) {
            return;
        }
        const path = getChildrenDetailsPath('', childId);
        statefulNavigate(path);
    };

    const handleEditDiscountSuccess = () => {
        displaySuccess(settlementTexts.discounts.general.editSuccess);
        navigateToChildPage();
    };

    const handleEditDiscountError = () => {
        displayError(commonTexts.errorMessages.actionExecution);
    };

    const { mutate: editDiscount, isLoading: isEditDiscountLoading } = useAppMutation(
        StaffHTTPService.discounts.editDiscount,
        {
            key: ['EDIT_DISCOUNT'],
            onSuccess: handleEditDiscountSuccess,
            onError: handleEditDiscountError,
            invalidateQueryKeys: [
                ['CHILD_DISCOUNTS', childId],
                ['CHILD_DISCOUNT', discountId, childId],
            ],
        },
    );

    const renderFieldsBasedOnType = () => {
        if (!discountData?.discountTarget || !discountData?.discountType) {
            return undefined;
        }

        switch (discountData.discountType) {
            case DiscountType.PERCENTAGE_DISCOUNT:
                return percentageDiscountFields(
                    discountData.discountTarget,
                    extraLessonsData,
                    isFetchingExtraLessons,
                );
            case DiscountType.CO_FINANCING:
                return coFinancingFields(
                    discountData.discountTarget,
                    extraLessonsData,
                    isFetchingExtraLessons,
                );
            case DiscountType.ALLOWANCE:
                return allowanceFields(
                    discountData.discountTarget,
                    extraLessonsData,
                    isFetchingExtraLessons,
                );
            default:
                return undefined;
        }
    };

    const onFinishForm = (data: EditDiscountFormFields) => {
        if (!childId || !discountId) {
            return;
        }

        editDiscount({
            discountId,
            childId,
            editDiscountData: parseEditDiscountsFormFieldsToAddDiscountDTO(data),
        });
    };

    return (
        <AppForm<EditDiscountFormFields>
            form={form}
            name="editDiscount"
            title={settlementTexts.discounts.addEditForm.editTitle}
            onFinish={onFinishForm}
            withGoBack
            isLoading={isFetchingDiscountData || isEditDiscountLoading}
        >
            <GridItem>
                <DetailsFrameBase hideHeader noFrame={false} marginEnd={16}>
                    <DetailsRow>
                        <DetailsColumn
                            title={settlementTexts.discounts.addEditForm.discountTargetEditLabel}
                        >
                            {discountData?.discountTarget
                                ? discountTargetMapper[discountData.discountTarget]
                                : EMPTY_VALUE_LABEL}
                        </DetailsColumn>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsColumn title={settlementTexts.discounts.addEditForm.discountType}>
                            {discountData?.discountType
                                ? discountTypeMapper[discountData.discountType]
                                : EMPTY_VALUE_LABEL}
                        </DetailsColumn>
                    </DetailsRow>
                </DetailsFrameBase>
            </GridItem>
            {renderFieldsBasedOnType()}
            <FormNavButtons
                // @ts-ignore
                formValuesBeforeChanges={formInitialData}
                checkEqualityHandler={checkIfDiscountEditFormDataAreEqualAfterChanges}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
