import { AppDatePickerProps, AppFormItem } from 'components/atoms';
import { StyledDatePicker } from 'components/molecules/FormElements/DatePickerElement/DatePickerElement';
import { DATE_LABEL, OPTIONAL_FIELD_LABEL } from 'consts/labels/common';

type SimpleDatePickerProps = Omit<AppDatePickerProps, 'onReset'> & {
    formItemLabel: string;
    optional?: boolean;
    isHalfParentWidth?: boolean;
    name: string;
};

export const SimpleDatePicker = ({
    formItemLabel,
    onChange,
    name,
    optional = false,
    isHalfParentWidth = false,
    disabledDate,
    disabled,
    ...restProps
}: SimpleDatePickerProps) => {
    const additionalText = optional ? `${DATE_LABEL} - ${OPTIONAL_FIELD_LABEL}` : `${DATE_LABEL}`;

    return (
        <AppFormItem
            name={name}
            label={<AppFormItem.Label mainText={formItemLabel} additionalText={additionalText} />}
            optional={optional}
            {...restProps}
        >
            <StyledDatePicker
                autoComplete="off"
                isHalfParentWidth={isHalfParentWidth}
                disabledDate={disabledDate}
                disabled={disabled}
                onChange={onChange}
            />
        </AppFormItem>
    );
};
