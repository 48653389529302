import { useState } from 'react';
import { ChildPerson } from 'assets';
import { Child, ChildGuardian, GuardianFormFields, WithId } from 'types';
import { ParentAndGuardianFormItemContentContainer } from 'components/molecules';
import { GuardianModalAddEditForm, GuardianTable } from 'components/templates/guardian';
import { useGuardianOrParent } from 'hooks/useGuardianOrParent/useGuardianOrParent';
import { ComponentBlockingAppLoader, DetailsFrameBase } from 'components/atoms';
import { parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber } from 'utils/parsers/guardian/parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber';
import { parseGuardianFormFieldsIntoChildGuardian } from 'utils/parsers/guardian/parseGuardianFormFieldsIntoChildGuardian';
import { useParentGuardianMutate } from 'hooks/useParentGuardianMutate/useParentGuardianMutate';
import { childrenTexts } from 'consts/text';

export type GuardiansProps = Pick<Child, 'guardians' | 'id'>;

export const ParentGuardians = ({ guardians, id }: GuardiansProps) => {
    const [editingGuardian, setEditingGuardian] = useState<ChildGuardian | null>(null);
    const { addGuardian, deleteGuardian, updateGuardian, isGuardianMutating } =
        useParentGuardianMutate({
            childId: id,
        });
    const onItemEditFinish = (childGuardian: WithId<ChildGuardian>) => {
        updateGuardian({
            childId: id,
            guardianId: childGuardian.id,
            childGuardian,
        });
    };
    const onItemAdd = (childGuardian: WithId<ChildGuardian>) => {
        addGuardian({
            childId: id,
            childGuardian,
        });
    };

    const {
        isModalFormOpen,
        handleModalFormOpen,
        handleModalFormClose,
        handleDataItemAdd: handleGuardianAdd,
        handleDataItemEdit: handleGuardianEdit,
        handleDataItemRemove: handleGuardianRemove,
        handleTableDataItemEditClick: handleGuardianTableItemEditClick,
    } = useGuardianOrParent<ChildGuardian>({
        onItemAdd,
        onItemEditFinish,
        onItemRemove: (childGuardian) => {
            deleteGuardian({ childId: id, guardianId: childGuardian.id });
        },
        onItemToEditPick: (childGuardian) => {
            setEditingGuardian(childGuardian);
        },
        clearSelectedItem: () => setEditingGuardian(null),
    });

    const isSelectedGuardianTableItemToEdit = !!editingGuardian;
    const modalFormMode = isSelectedGuardianTableItemToEdit ? 'edit' : 'add';
    const formName = isSelectedGuardianTableItemToEdit ? 'guardianEdit' : 'guardianAdd';

    const modalFormSubmitFormHandler = (data: GuardianFormFields) => {
        const formData = parseGuardianFormFieldsIntoChildGuardian(data, editingGuardian);
        return isSelectedGuardianTableItemToEdit
            ? handleGuardianEdit(formData)
            : handleGuardianAdd(formData);
    };

    return (
        <DetailsFrameBase
            icon={<ChildPerson />}
            title={childrenTexts.childDetails.guardians.guardianData}
            noFrame
            smallHeaderMargin
        >
            <GuardianModalAddEditForm
                open={isModalFormOpen}
                mode={modalFormMode}
                onSubmitForm={modalFormSubmitFormHandler}
                onCancel={handleModalFormClose}
                formName={formName}
                initialValues={
                    isSelectedGuardianTableItemToEdit
                        ? parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber(
                              editingGuardian,
                          )
                        : undefined
                }
            />
            <ParentAndGuardianFormItemContentContainer
                addButtonText={childrenTexts.addGuardianButtonLabel}
                onAddButtonClick={handleModalFormOpen}
                tableElement={
                    <GuardianTable
                        guardians={guardians}
                        onEditGuardianClick={handleGuardianTableItemEditClick}
                        onRemoveGuardianClick={handleGuardianRemove}
                        testId="Guardians_table"
                        canManageTable
                    />
                }
            />
            {isGuardianMutating && <ComponentBlockingAppLoader />}
        </DetailsFrameBase>
    );
};
