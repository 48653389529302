import styled, { css } from 'styled-components';

export const FiltersContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 40px;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            flex-direction: column-reverse;
        }
    `}
`;

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
`;

export const ChildNameWrapper = styled.div`
    height: 38px;
    display: flex;
    align-items: center;
`;
