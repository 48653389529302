import { useMemo } from 'react';
import { StaffAppModules } from 'types';
import { useAtom, useSetAtom } from 'jotai';
import { settingsTexts } from 'consts/text';
import { EDITABLE_PERMISSIONS_BY_MODULE } from 'consts/permissions/permissions';
import { omit } from 'lodash';
import { modulesEditAtom, permissionsEditAtom } from '../../roleAndPermissionState';
import { OnOffElement } from '../OnOffElement/OnOffElement';

export type LabelsOption = 'module' | 'access';
export type ModuleOnOffProps = {
    module: StaffAppModules;
    labelOption: LabelsOption;
    disabled?: boolean;
};

const getElements: (labelOptions: LabelsOption) => { label: string; value: boolean }[] = (
    labelOptions,
) => [
    {
        label: settingsTexts.rolesAndPermissions.modulesLabels[labelOptions].off,
        value: false,
    },
    {
        label: settingsTexts.rolesAndPermissions.modulesLabels[labelOptions].on,
        value: true,
    },
];

export const ModuleOnOff = ({ module, labelOption, disabled }: ModuleOnOffProps) => {
    const elements = useMemo(() => getElements(labelOption), [labelOption]);
    const [modules, setModules] = useAtom(modulesEditAtom);
    const setPermissions = useSetAtom(permissionsEditAtom);
    const isModuleActive = modules.includes(module);

    const clearPermissions = () => {
        setPermissions((prevPermissions) => {
            const nextPermissions = [...prevPermissions];
            const moduleToCheck = () => {
                if (module === 'SPECIAL_JOURNALS') {
                    if (EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS) {
                        return EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS.SPECIAL_JOURNALS;
                    }
                }
                if (module === 'GROUP_JOURNALS') {
                    if (EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS) {
                        return EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS.GROUP_JOURNALS;
                    }
                }
                if (module === 'JOURNALS') {
                    const newPermissions = {
                        ...EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS,
                        ...EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS!.GROUP_JOURNALS,
                        ...EDITABLE_PERMISSIONS_BY_MODULE.JOURNALS!.SPECIAL_JOURNALS,
                    };

                    return omit(newPermissions, ['GROUP_JOURNALS', 'SPECIAL_JOURNALS']);
                }
                return EDITABLE_PERMISSIONS_BY_MODULE[module];
            };

            const modulePermissions = Object.keys(moduleToCheck()!);

            return nextPermissions.filter(
                (permission) => !modulePermissions.includes(permission.name),
            );
        });
    };

    const onChange = (value: boolean) => {
        if (!value) {
            clearPermissions();
        }

        setModules((prevModules) => {
            if (value) {
                return [...prevModules, module];
            }
            const nextModules = prevModules.filter((currentModule) => currentModule !== module);

            if (!nextModules.includes('JOURNALS')) {
                return nextModules.filter(
                    (currentModule) =>
                        currentModule !== 'GROUP_JOURNALS' && currentModule !== 'SPECIAL_JOURNALS',
                );
            }

            return nextModules;
        });
    };

    return (
        <OnOffElement
            disabled={disabled}
            active={isModuleActive}
            elements={elements}
            onChange={onChange}
        />
    );
};
