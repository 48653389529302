import React, { useState } from 'react';
import {
    AppDivider,
    AppOptionsButton,
    AppOptionButton,
    AppOptionIconContainer,
    OpenAppOptionsButtonSize,
} from 'components/atoms/CommonAppComponents';

export type Option<ItemType> = {
    label: string;
    onClick?: (record: ItemType, index: number) => void;
    Icon?: React.ReactNode;
    checkIfVisible?: (record: ItemType) => boolean;
    checkIfIsLoading?: (record: ItemType) => boolean;
    isLoading?: boolean;
};

export type OptionsButtonProps<ItemType> = {
    options: Option<ItemType>[];
    record: ItemType;
    isOptionsLoading?: boolean;
    index: number;
    openOptionsButtonSize?: OpenAppOptionsButtonSize;
};

export const OptionsButton = <ItemType,>({
    options,
    record,
    index,
    isOptionsLoading,
    openOptionsButtonSize,
}: OptionsButtonProps<ItemType>) => {
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);

    const hidePopover = () => setIsVisiblePopover(false);

    const handleDotsButtonClick = () =>
        setIsVisiblePopover((prevIsVisiblePopover) => !prevIsVisiblePopover);

    const handleOptionClick = (onClick: Option<ItemType>['onClick']) => {
        onClick?.(record, index);
        hidePopover();
    };

    const visibleOptions = options.filter((option) =>
        option.checkIfVisible ? option.checkIfVisible(record) : true,
    );

    if (!visibleOptions.length) {
        return null;
    }

    const loadingOptions = visibleOptions.map((option) => {
        if (option.checkIfIsLoading) {
            return option.checkIfIsLoading(record)
                ? { ...option, isLoading: true }
                : { ...option, isLoading: false };
        }
        return option;
    });

    const mappedOptions = loadingOptions.map(({ label, Icon, onClick, isLoading }) => (
        <span key={label}>
            <AppOptionButton
                ghost
                icon={Icon && <AppOptionIconContainer>{Icon}</AppOptionIconContainer>}
                fullWidth
                onClick={() => handleOptionClick(onClick)}
                size="small"
                loading={isLoading}
            >
                {label}
            </AppOptionButton>
            <AppDivider withoutLast />
        </span>
    ));

    return (
        <AppOptionsButton
            optionButtons={mappedOptions}
            isVisiblePopover={isVisiblePopover}
            isOptionsLoading={isOptionsLoading}
            openOptionsButtonSize={openOptionsButtonSize}
            onPopoverHide={hidePopover}
            onDotsButtonClick={handleDotsButtonClick}
        />
    );
};
