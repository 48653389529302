import { IconPlus } from '@tabler/icons-react';
import {
    AppButton,
    AppButtonProps,
} from 'components/atoms/CommonAppComponents/AppButton/AppButton';

type UploadButtonProps = Pick<AppButtonProps, 'loading'>;

export const UploadButton = (props: UploadButtonProps) => (
    <AppButton type="text" icon={<IconPlus size={20} />} margin={0} {...props}>
        Dodaj plik
    </AppButton>
);
