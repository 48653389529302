import styled from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';
import { cellContentStyle } from 'consts/styles/cellContentStyle';

export const MobileExpandableRenderCellContent = styled.span`
    ${cellContentStyle};
    display: block;
    ${getTextStyle('BodyMSRegular')};
    .ant-typography {
        ${getTextStyle('BodyMSRegular')};
    }
`;
