import styled from 'styled-components';
import {
    AppModalIconContainer,
    AppModalTitle,
    AppModalButton,
    AppParagraph,
} from 'components/atoms';

export const IconContainer = styled(AppModalIconContainer)`
    margin-bottom: 32px;
`;

export const ModalTitle = styled(AppModalTitle)`
    &.ant-typography {
        margin-bottom: 16px;
    }
`;

export const ModalText = styled(AppParagraph)`
    color: ${({ theme }) => theme.colors.grayscale.gray9};

    &.ant-typography {
        margin: 0;
        text-align: center;
    }
`;

export const ModalButton = styled(AppModalButton)`
    flex: 1 0 0;
`;
