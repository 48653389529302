import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { journalTexts } from 'consts/text';
import { max300LengthRule } from 'consts/rules/textRules';

export const TopicNameInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label={journalTexts.journalDetails.groupJournal.topics.topicInputLabel}
        name={formFields.name}
        rules={[{ required: true, message: formElementRequiredText }, max300LengthRule]}
        {...props}
    >
        <AppInput />
    </AppFormItem>
);
