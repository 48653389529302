import { SettlementStaysDTO } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { settlementConfigurationLabels } from 'consts/settlement/settlementConfigurationLabels';
import { methodOfCalcTheFeeLabels } from 'consts/settlementStays/methodOfCalcTheFeeLabels';
import { getFormattedPrice } from 'utils/getFormattedPrice';

export const parseSettlementStaysDTOIntoJsonObject: (
    settlementStaysDTO: SettlementStaysDTO,
) => object = (settlementStaysDTO) => {
    const {
        settlementType,
        paymentType,
        freeHours,
        freeHoursAmount,
        freeHoursTimeFrame,
        freeHoursStart,
        freeHoursEnd,
        price,
    } = settlementStaysDTO;

    const settlementTypeLabel = settlementConfigurationLabels[settlementType];
    const paymentTypeLabel = methodOfCalcTheFeeLabels[paymentType];
    const formattedFreeHoursStart = freeHoursStart
        ? parseStringIntoDayjsTime(freeHoursStart)
        : EMPTY_VALUE_LABEL;
    const formattedFreeHoursEnd = freeHoursEnd
        ? parseStringIntoDayjsTime(freeHoursEnd)
        : EMPTY_VALUE_LABEL;
    const formattedPrice = price ? getFormattedPrice(price) : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.settlementStaysDTO.fields.settlementType]: settlementTypeLabel,
        [jsonObjectsTexts.settlementStaysDTO.fields.paymentType]: paymentTypeLabel,
        [jsonObjectsTexts.settlementStaysDTO.fields.freeHours]: freeHours
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.settlementStaysDTO.fields.freeHoursAmount]:
            freeHoursAmount || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.settlementStaysDTO.fields.freeHoursTimeFrame]: freeHoursTimeFrame
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.settlementStaysDTO.fields.freeHoursStart]: formattedFreeHoursStart,
        [jsonObjectsTexts.settlementStaysDTO.fields.freeHoursEnd]: formattedFreeHoursEnd,
        [jsonObjectsTexts.settlementStaysDTO.fields.price]: formattedPrice,
    };
};
