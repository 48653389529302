import { PaymentStatusSettlement, PaymentTypes } from 'types';
import { settlementTexts } from 'consts/text';

export const paymentTypeOptions = [
    {
        value: PaymentTypes.CASH,
        label: settlementTexts.children.paymentTypes.cash,
    },
    {
        value: PaymentTypes.WIRE_TRANSFER,
        label: settlementTexts.children.paymentTypes.transfer,
    },
];

export const paymentStatusOptions = [
    {
        value: PaymentStatusSettlement.RECORDED,
        label: settlementTexts.children.paymentStatuses.recorded,
    },
    {
        value: PaymentStatusSettlement.CANCELLED,
        label: settlementTexts.children.paymentStatuses.cancelled,
    },
    {
        value: PaymentStatusSettlement.IN_PROGRESS,
        label: settlementTexts.children.paymentStatuses.inProgress,
    },
];
