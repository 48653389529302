import { AppButton, AppDrawer, AppParagraph } from 'components/atoms';
import { BurgerMenu } from 'assets/icons';
import { useAtom } from 'jotai';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { useNavigate } from 'react-router-dom';
import { RightComponentProps } from '../../AuthUpperNavBar.types';
import { StyledBurgerMenuIcon, StyledLinkButton } from './RightComponentMobile.styled';

export const RightComponentMobile = ({ redirectLinks, buttons }: RightComponentProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useAtom(isLoggedUserDrawerMenuOpenAtom);
    const navigate = useNavigate();
    const showDrawer = () => setIsDrawerOpen(true);
    const onClose = () => setIsDrawerOpen(false);

    const mappedLinks = redirectLinks.map(({ path, label }) => (
        <StyledLinkButton
            type="link"
            key={`${path}${label}`}
            onClick={() => {
                navigate(path);
                onClose();
            }}
        >
            <AppParagraph margin={0} textType="BodyMedium">
                {label}
            </AppParagraph>
        </StyledLinkButton>
    ));

    return (
        <>
            <AppButton margin={0} type="link" onClick={showDrawer}>
                <StyledBurgerMenuIcon src={BurgerMenu} alt="Menu" />
            </AppButton>
            <AppDrawer onClose={onClose} open={isDrawerOpen}>
                <AppButton margin={0} marginBottom={10} {...buttons.testTheApp} fullWidth />
                <AppButton margin={0} marginBottom={10} {...buttons.login} fullWidth />
                {mappedLinks}
            </AppDrawer>
        </>
    );
};
