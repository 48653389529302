import { AppParagraph } from 'components/atoms';

export type CountWithLimitDisplayProps = {
    count: number;
    limit: number;
};

const SEPARATOR = '/';

export const CountWithLimitDisplay = ({ count, limit }: CountWithLimitDisplayProps) => {
    const countWithLimitText = `${count}${SEPARATOR}${limit}`;

    return <AppParagraph margin={0}>{countWithLimitText}</AppParagraph>;
};
