import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const TextContainer = styled.div`
    ${({ theme }) => css`
        p {
            margin: 0;
            color: ${theme.colors.grayscale.gray10};
            ${getTextStyle('BodyMedium')}
            word-break: break-all;
        }
    `}
`;
