import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import intersection from 'lodash/intersection';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { commonTexts } from 'consts/text';
import { AppButton, ExtendedDataNode } from 'components/atoms';
import { theme } from 'theme/styledComponents/theme';
import { DataNode, TreeProps } from 'antd/es/tree';
import _ from 'lodash';
import { parseTreeNodesIntoChildNodeKeys } from 'utils/treePickerUtils';
import { AppModal, AppModalProps } from '../../AppModal/AppModal';
import { NothingFoundDisplay } from '../../StatesComponents';
import { generateList, getParentKey, searchAndFilterTree } from './utils';
import {
    StyledAppCheckbox,
    Container,
    ListContainer,
    StyledAppTree,
} from './ModalTreePickerElement.styled';
import { SearchInput } from './components';

type ModalTreePickerElementProps = Pick<
    AppModalProps,
    'title' | 'open' | 'onCancel' | 'icon' | 'isLoading' | 'width'
> & {
    treeData: ExtendedDataNode[];
    onSave: (checkedKeys: React.Key[]) => void;
    initialValue: string[];
    showCheckAll?: boolean;
    showSearchBar?: boolean;
    multiple?: boolean;
};

export const ModalTreePickerElement = ({
    title,
    open,
    icon,
    onCancel,
    onSave,
    treeData,
    isLoading,
    initialValue,
    width,
    multiple = true,
    showCheckAll = true,
    showSearchBar = true,
}: ModalTreePickerElementProps) => {
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [temporaryCheckedKeys, setTemporaryCheckedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const isMobile = useIsMobile();

    const dataList: { key: React.Key; title: string }[] = [];
    generateList(treeData, dataList);

    const childNodeKeys = useMemo(() => parseTreeNodesIntoChildNodeKeys(treeData), [treeData]);

    useEffect(() => {
        if (initialValue) {
            setTemporaryCheckedKeys(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        if (childNodeKeys.length === temporaryCheckedKeys.length) {
            setSelectAllChecked(true);
        } else {
            setSelectAllChecked(false);
        }
    }, [temporaryCheckedKeys.length, childNodeKeys.length]);

    const onExpand = (newExpandedKeys: React.Key[]) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const onSearchInputChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const searchInputValue = event.target.value.toLowerCase().trim();
        const newExpandedKeys = dataList.map((item) => {
            const itemTitle = item.title.toLowerCase().trim();
            if (searchInputValue !== '' && itemTitle.indexOf(searchInputValue) > -1) {
                return getParentKey(item.key, treeData);
            }
            return null;
        });
        const newExpandedUniqueKeys = _.uniq(newExpandedKeys) as [];
        setExpandedKeys(newExpandedUniqueKeys);
        setSearchValue(searchInputValue);
        setAutoExpandParent(true);
    }, 300);

    const onSelectAllChange = (event: CheckboxChangeEvent) => {
        setSelectAllChecked(event.target.checked);

        if (event.target.checked) {
            setTemporaryCheckedKeys([...childNodeKeys]);
        } else {
            setTemporaryCheckedKeys([]);
        }
    };

    const onSaveClick = () => {
        onSave(temporaryCheckedKeys);
    };

    const onCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTemporaryCheckedKeys(initialValue);
        onCancel?.(event);
    };

    const treeDataMemorized = useMemo(
        () => searchAndFilterTree(treeData, searchValue.toLowerCase()),
        [searchValue, treeData],
    ) as DataNode[];

    const flattenedTreeDataForOnCheck = useMemo(
        () => parseTreeNodesIntoChildNodeKeys(treeDataMemorized as ExtendedDataNode[]),
        [treeDataMemorized],
    ) as React.Key[];

    const onCheck: TreeProps['onCheck'] = (checked) => {
        setTemporaryCheckedKeys((prevState) => {
            const checkedChildKeysNotInTree = prevState.filter(
                (key) => !flattenedTreeDataForOnCheck.includes(key),
            );
            const checkedChildKeys = [
                ...intersection(checked as React.Key[], childNodeKeys),
                ...checkedChildKeysNotInTree,
            ];
            return multiple
                ? checkedChildKeys
                : checkedChildKeys.filter(
                      (checkedValue) => !prevState.some((prevValue) => prevValue === checkedValue),
                  );
        });
    };

    const footerButton = (
        <AppButton
            fullWidth={isMobile}
            type="primary"
            icon={<IconPlus size={20} />}
            margin={0}
            onClick={onSaveClick}
        >
            {commonTexts.actionLabels.add}
        </AppButton>
    );

    return (
        <AppModal
            title={title}
            open={open}
            onCancel={onCancelClick}
            icon={icon}
            width={width || theme.sizes.modalWidth.large}
            footer={footerButton}
            isLoading={isLoading}
        >
            <Container>
                {showSearchBar && <SearchInput onChange={onSearchInputChange} />}
                <ListContainer>
                    {showCheckAll && (
                        <StyledAppCheckbox onChange={onSelectAllChange} checked={selectAllChecked}>
                            {commonTexts.forms.selectAll}
                        </StyledAppCheckbox>
                    )}
                    {!treeDataMemorized.length ? (
                        <NothingFoundDisplay />
                    ) : (
                        <StyledAppTree
                            checkable
                            checkedKeys={temporaryCheckedKeys}
                            onCheck={onCheck}
                            treeData={treeDataMemorized}
                            selectable={false}
                            blockNode
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                        />
                    )}
                </ListContainer>
            </Container>
        </AppModal>
    );
};
