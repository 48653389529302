import { StepsForm } from 'components/organisms/StepsForm/StepsForm';
import { PasswordResetForm } from './components/PasswordResetForm';
import { PasswordResetFinish } from './components/PasswordResetFinish';

export const PasswordReset = () => (
    <StepsForm
        formPagesNames={['passwordReset']}
        components={[PasswordResetForm]}
        FinishedComponent={PasswordResetFinish}
    />
);
