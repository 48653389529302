import { IconDeviceFloppy } from '@tabler/icons-react';
import { FormModeDependedTexts } from 'types';
import { commonTexts, subscriptionsTexts } from 'consts/text';

export const addModeTexts: FormModeDependedTexts = {
    name: 'subscriptionAdd',
    title: subscriptionsTexts.subscriptionAddEditModal.addTitle,
    buttonText: commonTexts.actionLabels.add,
};

export const editModeTexts: FormModeDependedTexts = {
    name: 'subscriptionEdit',
    title: subscriptionsTexts.subscriptionAddEditModal.editTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const CHILD_LIMIT_MIN_VALUE = 11;

export const CHILD_LIMIT_MAX_VALUE = 10000;
