import { createGlobalStyle, css, styled } from 'styled-components';
import { TimePicker } from 'antd';
import { IconClock } from '@tabler/icons-react';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import type { AppTimePickerProps } from './AppTimePicker';

export const AppTimePickerGlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
      @media (${theme.sizes.mediaQueries.mobileMax}) {
          .ant-picker-dropdown .ant-picker-time-panel-column {
              overflow-y: auto;
          }
      }
  `}`;

export const StyledTimePicker = styled(TimePicker).withConfig({
    shouldForwardProp: getShouldForwardProp(['isFitContentWidth']),
})<AppTimePickerProps>`
    ${({ theme, isFitContentWidth }) => css`
        width: ${isFitContentWidth ? 'auto' : '100%'};
        padding: 8px 16px;

        ${inputAndPickerBorderStyle}

        &.ant-picker-focused {
            border-color: ${theme.colors.primary.primary8};
        }
    `}
`;

export const StyledClockIcon = styled(IconClock)`
    height: 20px;
    width: 20px;
    color: ${({ theme }) => theme.colors.grayscale.gray7};
`;
