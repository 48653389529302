import { IconCheck, IconX } from '@tabler/icons-react';
import { AppRadioGroup } from 'components/atoms';
import { RadioChangeEvent } from 'antd';
import { NoButton, YesButton, RadioGroupWrapper } from './YesNoRadioItem.styled';

export type YesNoRadioParams = {
    onChange: (event: RadioChangeEvent) => void;
    value: string;
    yesValue: string;
    noValue: string;
    disabled?: boolean;
};

export const YesNoRadioItem = ({
    onChange,
    value,
    yesValue,
    noValue,
    disabled,
}: YesNoRadioParams) => (
    <RadioGroupWrapper>
        <AppRadioGroup
            value={value}
            size="middle"
            buttonStyle="solid"
            onChange={onChange}
            disabled={disabled}
        >
            <YesButton value={yesValue}>
                <IconCheck size={20} />
            </YesButton>
            <NoButton value={noValue}>
                <IconX size={20} />
            </NoButton>
        </AppRadioGroup>
    </RadioGroupWrapper>
);
