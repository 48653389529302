import { AppModal, AppModalProps, TableTagDisplay } from 'components/molecules';
import {
    AppButton,
    AppText,
    AppTitle,
    HistoryChangeRefersToInfo,
    AppReactJson,
} from 'components/atoms';
import { settingsTexts } from 'consts/text';
import { historyChangeTypeLabels } from 'consts/historyChange/historyChangeTypeLabels';
import { tagsColorsByHistoryChangeType } from 'consts/historyChange/tagsColorsByHistoryChangeType';
import { HistoryChangeDTO } from 'types';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseDtoDataIntoJsonObject } from 'utils/parsers/jsonObject/parseDtoDataIntoJsonObject';
import { HistoryChangeContainer, TextRow } from './HistoryChangeDetailsModal.styled';

type HistoryChangeDetailsModalProps = AppModalProps & {
    historyChangeData: HistoryChangeDTO;
};

export const HistoryChangeDetailsModal = ({
    historyChangeData,
    onCancel,
}: HistoryChangeDetailsModalProps) => {
    const {
        refersTo,
        refersToSub,
        specialAction,
        createdAt,
        userName,
        historyType,
        result,
        resultObjectType,
        unarchivedName,
        deletedName,
        nameChange,
    } = historyChangeData;

    const parsedDateTime = parseStringIntoFormattedStringDate(
        createdAt,
        'YYYY-MM-DDTHH:mm:ss',
        'DD MMMM YYYY, HH:mm',
    );
    const dateTimeInfo = `${settingsTexts.historyOfChanges.historyChangeDetails.actionDateTime} ${parsedDateTime}`;
    const parsedJsonObject = parseDtoDataIntoJsonObject({ type: resultObjectType, record: result });
    const objectName = unarchivedName || deletedName || nameChange || undefined;

    const goBackButton = (
        <AppButton type="primary" onClick={onCancel}>
            {settingsTexts.historyOfChanges.historyChangeDetails.understand}
        </AppButton>
    );

    return (
        <AppModal open footer={goBackButton} onCancel={onCancel}>
            <TextRow>
                <AppTitle level={4} margin={0}>
                    {dateTimeInfo}
                </AppTitle>
            </TextRow>
            <TextRow>
                <AppText margin={0} textType="BodyMediumSemiBold">
                    {settingsTexts.historyOfChanges.historyChangeDetails.user}
                </AppText>
                <AppText margin={0}>{userName}</AppText>
            </TextRow>
            <TextRow>
                <AppText margin={0} textType="BodyMediumSemiBold">
                    {settingsTexts.historyOfChanges.historyChangeDetails.activity}
                </AppText>
                <TableTagDisplay
                    value={historyType}
                    labelMapper={historyChangeTypeLabels}
                    colorMapper={tagsColorsByHistoryChangeType}
                />
            </TextRow>
            <HistoryChangeContainer>
                <HistoryChangeRefersToInfo
                    refersTo={refersTo}
                    refersToSub={refersToSub}
                    historyType={historyType}
                    specialAction={specialAction}
                    objectName={objectName}
                />
                {parsedJsonObject && (
                    <AppReactJson
                        src={parsedJsonObject}
                        collapsed={1}
                        enableClipboard={false}
                        displayObjectSize={false}
                        quotesOnKeys={false}
                        name={false}
                    />
                )}
            </HistoryChangeContainer>
        </AppModal>
    );
};
