import { chunk } from 'lodash';

const EXPECTED_COLOR_LENGTH = 6;
const getNumberByString = (string: string) =>
    string.split('').reduce((previousValue, currentValue) => {
        const code = currentValue.charCodeAt(0);
        return previousValue + code / 10 ** code.toString().length;
    }, 0);

const provideDarkerColor = (color: string) => {
    let colorComponents = color.split('');
    if (colorComponents.length < EXPECTED_COLOR_LENGTH) {
        colorComponents = colorComponents.concat(
            Array(EXPECTED_COLOR_LENGTH - colorComponents.length).fill('0'),
        );
    }
    const colorsParts = chunk(colorComponents, 2);
    const parsedColorsParts = colorsParts.map((colorPart) => {
        const firstColorItem = parseInt(colorPart[0], 16);
        const parsedColorItem = Math.round(firstColorItem / 2);

        return `${parsedColorItem.toString(16) || 0}${colorPart[1] || 0}`;
    });
    return parsedColorsParts.join('');
};

export const getColorsBySeed = (seed: string) => {
    const numberBySeed = getNumberByString(seed);
    const generatedColor = Math.floor(Math.abs(Math.sin(numberBySeed) * 16777215)).toString(16);
    const darkerColor = provideDarkerColor(generatedColor);
    return {
        mainColor: `#${darkerColor}`,
        secondaryColor: `#${darkerColor}22`,
    };
};
