import { AppButton, AppButtonProps } from 'components/atoms/CommonAppComponents';
import { StyledPlusIcon } from 'components/atoms/StyledIcons';

export type AddButtonProps = {
    addButtonLabel?: string;
    onAddButtonClick?: () => void;
} & AppButtonProps;

export const AddButton = ({
    addButtonLabel,
    onAddButtonClick,
    ...appButtonProps
}: AddButtonProps) => (
    <AppButton
        icon={<StyledPlusIcon />}
        onClick={onAddButtonClick}
        type="primary"
        margin={0}
        {...appButtonProps}
    >
        {addButtonLabel}
    </AppButton>
);
