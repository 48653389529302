import { AppCheckbox, AppTree } from 'components/atoms';
import styled from 'styled-components';
import { theme } from 'theme/styledComponents/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledAppCheckbox = styled(AppCheckbox)`
    padding: 10px;
`;

export const StyledAppTree = styled(AppTree)`
    &.ant-tree .ant-tree-list {
        @media ${theme.sizes.mediaQueries.desktopMin} {
            max-height: 50vh;
        }
    }
`;
