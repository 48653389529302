import { useEffect, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { PasswordUpdateForm } from 'components/templates';
import { UpdatePasswordFormFields } from 'types';
import { useAuth } from 'services/firebase/useAuth';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { accountTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { getFirebaseErrorLabel } from 'utils/handleFirebaseError/getFirebaseErrorLabel';

export const PasswordUpdatePage = () => {
    const [updatePasswordError, setUpdatePasswordError] = useState('');
    const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false);
    const { form, formInitialData, setFormData } = useEditForm<UpdatePasswordFormFields>();
    const { passwordUpdate, validateCredential } = useAuth();
    const { displaySuccess } = useSimpleNotification();

    useEffect(
        () => setFormData({ oldPassword: '', password: '', passwordRepeat: '' }),
        [setFormData],
    );

    const onUpdatePasswordFinish = async (updatePasswordData: UpdatePasswordFormFields) => {
        const { oldPassword, password } = updatePasswordData;
        try {
            setIsUpdatePasswordLoading(true);
            setUpdatePasswordError('');
            if (oldPassword === password) {
                setUpdatePasswordError(accountTexts.passwordUpdate.newPasswordEqualActualError);
                return;
            }
            await validateCredential(updatePasswordData.oldPassword);
            await passwordUpdate(updatePasswordData.password);
            displaySuccess(accountTexts.passwordUpdate.successfullyUpdatedPassword);
            form.resetFields();
        } catch (error) {
            if (error instanceof FirebaseError) {
                setUpdatePasswordError(getFirebaseErrorLabel(error, true));
                return;
            }
            const typedError = error as Error;
            setUpdatePasswordError(typedError.message);
        } finally {
            setIsUpdatePasswordLoading(false);
        }
    };

    return (
        <PasswordUpdateForm
            form={form}
            initialValues={formInitialData}
            isLoading={isUpdatePasswordLoading}
            error={updatePasswordError}
            onFinish={onUpdatePasswordFinish}
        />
    );
};
