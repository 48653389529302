import { generatePath } from 'react-router-dom';
import { TripAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { TripFormFields } from 'types/forms/trip';
import { parseTripFormFieldsIntoAddUpdateTripDTO } from 'utils/parsers/trip/parseTripFormFieldsIntoAddUpdateTripDTO';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalTripAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const handleAddTripSuccess = () => {
        displaySuccess('Wycieczka została dodana');
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addTrip,
        error: addTripError,
        isLoading: isAddTripLoading,
    } = useAppMutation(StaffHTTPService.trips.addTrip, {
        key: ['ADD_TRIP'],
        onSuccess: handleAddTripSuccess,
        invalidateQueryKeys: [['TRIPS', journalId]],
    });

    const handleAddTripFinish = (tripFormData: TripFormFields) => {
        const parsedAddTripDTO = parseTripFormFieldsIntoAddUpdateTripDTO(tripFormData);
        addTrip({ addTripDTO: parsedAddTripDTO, journalId });
    };

    return (
        <TripAddEditForm
            journalId={journalId}
            mode="add"
            axiosError={addTripError}
            isLoading={isAddTripLoading}
            onFinish={handleAddTripFinish}
        />
    );
};
