import styled from 'styled-components';
import { AppButton, AppSelect } from 'components/atoms';
import { PageBaseLayout } from 'components/layouts/PageBaseLayout/PageBaseLayout';

export const StyledPageLayout = styled(PageBaseLayout)`
    padding-bottom: 24px;
`;

export const StyledSelect = styled(AppSelect)`
    .ant-select-selector .ant-select-selection-item {
        padding-inline-end: 50px;
    }
`;

export const StyledButton = styled(AppButton)`
    height: 44px;
    margin: 0;
`;
