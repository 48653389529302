import { SettingsAbilitiesNames } from 'abilities';
import { appPaths } from 'consts/paths/paths';

export const settingsAbilitiesPaths: Record<SettingsAbilitiesNames, string> = {
    checkIfCanViewInstitutionData: appPaths.app.settings['institution-details'].base,
    checkIfCanManageInstitutionData: appPaths.app.settings['institution-details'].base,
    checkIfCanViewRolesAndPermissions: appPaths.app.settings['roles-and-permissions'].base,
    checkIfCanManageRolesAndPermissions: appPaths.app.settings['roles-and-permissions'].base,
    checkIfCanViewChangesHistory: appPaths.app.settings['history-of-changes'],
    checkIfCanViewPayment: appPaths.app.settings['invoice-details'].base,
    checkIfCanViewSettlementSettings: appPaths.app.settings.settlements.base,
    checkIfCanManageSettlementSettings: appPaths.app.settings.settlements.base,
    checkIfCanViewDietSettings: appPaths.app.settings.diets.base,
    checkIfCanManageDietSettings: appPaths.app.settings.diets.base,
    checkIfCanViewPrincipal: appPaths.app.settings['principal-details'],
    checkIfCanManagePrincipal: appPaths.app.settings['principal-details'],
};
