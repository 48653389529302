import { useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { Details } from 'components/organisms';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { PersonList } from 'components/molecules';
import { commonTexts, journalTexts } from 'consts/text';
import { specialConsultationDTOFields } from 'consts/consultation/specialConsultation/specialConsultationDTOFields';
import { SpecialConsultationId } from 'types';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const SpecialJournalConsultationDetailsPage = () => {
    const { journalId } = useJournalId();
    const { consultationId: consultationIdParam } = useParams();
    const {
        specialJournalsAbilities: { checkIfCanManageConsultations },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const canManageConsultations = checkIfCanManageConsultations();
    const consultationId = consultationIdParam as SpecialConsultationId;
    const editPagePath = generatePath(
        appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.edit
            .pickedConsultation,
        { consultationId, journalId },
    );

    const {
        data: consultationData,
        isFetching: isConsultationDataFetching,
        isError: isFetchConsultationDataError,
    } = useAppQuery(
        'SPECIAL_CONSULTATION',
        [journalId, consultationId],
        () =>
            StaffHTTPService.specialConsultations.getSpecialConsultation(journalId, consultationId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const childrenData = useMemo(() => {
        if (!consultationData) {
            return [];
        }
        return consultationData[specialConsultationDTOFields.childrenArray];
    }, [consultationData]);

    return (
        <Details
            title={journalTexts.journalDetails.common.consultations.consultationDetails}
            isLoading={isConsultationDataFetching}
            isError={isFetchConsultationDataError}
            editPagePath={canManageConsultations ? editPagePath : undefined}
        >
            <Details.Item
                title={journalTexts.journalDetails.common.consultations.consultationSubject}
            >
                {consultationData?.[specialConsultationDTOFields.subject]}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.date}>
                {consultationData &&
                    parseStringIntoFormattedStringDate(
                        consultationData[specialConsultationDTOFields.date],
                    )}
            </Details.Item>
            <Details.Item
                title={journalTexts.journalDetails.common.consultations.consultationDescription}
            >
                {consultationData?.[specialConsultationDTOFields.description]}
            </Details.Item>

            <Details.Item title={journalTexts.journalDetails.common.consultations.contactWith}>
                {consultationData?.[specialConsultationDTOFields.contactWith]}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.childList}>
                <PersonList personsData={childrenData} />
            </Details.Item>
        </Details>
    );
};
