import styled from 'styled-components';
import { Upload } from 'antd';

export const StyledUpload = styled(Upload)`
    &.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
        width: unset;
        .ant-upload.ant-upload-select {
            margin: 0;
            height: 120px;
            width: 120px;
        }
    }
`;
