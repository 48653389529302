import { OwnerAndEmployeeAccountFormFields, UserBaseAuthDTO } from 'types';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';

export const parseUserBaseAuthDTOIntoOwnerAndEmployeeFormFields: (
    userBaseAuthDTO: UserBaseAuthDTO,
) => OwnerAndEmployeeAccountFormFields = (userBaseAuthDTO) => ({
    firstName: userBaseAuthDTO.firstName || '',
    lastName: userBaseAuthDTO.lastName || '',
    phoneNumber: userBaseAuthDTO.phoneNumber
        ? parsePhoneNumberIntoFormattedPhoneNumber(userBaseAuthDTO.phoneNumber)
        : '',
});
