import { AxiosResponse } from 'axios';
import { RcFile } from 'antd/es/upload/interface';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { formFields } from 'consts/form/formFields';
import {
    AppFormItem,
    AppFormItemProps,
    AppUpload,
    AppUploadFile,
    AppUploadRequestOptions,
} from 'components/atoms';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { FileDirectory, AttachmentDTO, FileMetaDTO } from 'types';
import { ATTACHMENT_FORMATS } from 'consts/file/fileFormats';
import { commonTexts } from 'consts/text';
import { fileUploadErrorParser } from 'utils/errorHandlers/file/fileUploadErrorParser';
import { MAX_FILE_SIZE } from 'consts/file/maxFileSize';

export type AttachmentsUploadElementProps = AppFormItemProps & {
    uploadDirectory: FileDirectory;
    optional?: boolean;
    appFormItemLabel?: string;
    uploadAttachmentFunction?: ({
        directory,
        file,
    }: {
        directory: FileDirectory;
        file: string | Blob | RcFile;
    }) => Promise<AxiosResponse<FileMetaDTO>>;
};

export const AttachmentsUploadElement = ({
    uploadDirectory,
    appFormItemLabel,
    optional = true,
    className,
    uploadAttachmentFunction = StaffHTTPService.files.addFile,
    ...props
}: AttachmentsUploadElementProps) => {
    const { displayError } = useSimpleNotification();
    const { setFieldValue } = useFormInstance();
    const attachmentsFormData = useWatch<AttachmentDTO[] | undefined>(formFields.attachments);

    const { mutateAsync: uploadFile, isLoading: isUploadFileLoading } = useAppMutation(
        uploadAttachmentFunction,
        {
            key: ['ADD_FILE'],
        },
    );

    const handleUploadFile = async ({ file }: AppUploadRequestOptions) => {
        try {
            const { data: uploadFileResponseData } = await uploadFile({
                directory: uploadDirectory,
                file,
            });
            const newAttachmentsData: AttachmentDTO[] = [
                ...(attachmentsFormData || []),
                {
                    fileName: uploadFileResponseData.fileName,
                    fileUri: uploadFileResponseData.fileUri,
                },
            ];
            setFieldValue(formFields.attachments, newAttachmentsData);
        } catch (error) {
            const fileUploadError = fileUploadErrorParser(error);
            displayError(fileUploadError);
        }
    };

    const handleBeforeUpload = (file: RcFile) => {
        if (file.size > MAX_FILE_SIZE) {
            displayError(commonTexts.errorMessages.tooLargeFile);
            return false;
        }
        return true;
    };

    const handleRemoveFile = (fileToDelete: AppUploadFile) => {
        const newAttachmentsData = attachmentsFormData?.filter(
            (attachmentData) => attachmentData.fileUri !== fileToDelete.uid,
        );
        setFieldValue(formFields.attachments, newAttachmentsData);
    };

    const fileList: AppUploadFile[] | undefined = attachmentsFormData?.map(
        ({ fileName, fileUri }) => ({
            uid: fileUri,
            name: fileName,
            status: 'done',
        }),
    );

    return (
        <div className={className}>
            <AppFormItem
                label={appFormItemLabel || commonTexts.actionLabels.addDocument}
                name={formFields.attachments}
                optional={optional}
                marginBottom={0}
                hasOptionalText
                {...props}
            >
                <input type="hidden" />
            </AppFormItem>
            <AppUpload
                accept={ATTACHMENT_FORMATS}
                fileList={fileList || []}
                beforeUpload={handleBeforeUpload}
                customRequest={handleUploadFile}
                onRemove={handleRemoveFile}
            >
                <AppUpload.Button loading={isUploadFileLoading} />
            </AppUpload>
        </div>
    );
};
