import { AxiosError } from 'axios';

export const getErrorMessageAndCode = (error: unknown) => {
    if (error instanceof AxiosError) {
        if (error.response?.data) {
            return {
                message: error.response.data.message,
                code: error.response.data.code,
            };
        }
        return {
            code: error.code,
            message: error.message,
        };
    }
    return { message: '', code: '' };
};
