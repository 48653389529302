import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { TimetableLayout } from 'components/layouts';
import {
    TimetablePage,
    LessonAddPage,
    LessonEditPage,
    AbsenceAddPage,
    AbsenceEditPage,
    WorkingTimePage,
    EvidenceWorkingTimePage,
} from 'pages';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';

export const timetableRoutes: RouteObject[] = [
    {
        path: appPaths.app.timetable.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW_MODULE,
                    subject: STAFF_APP_MODULES.TIMETABLE,
                }}
            >
                <TimetableLayout />
            </AbilityRoute>
        ),
        children: [
            {
                path: appPaths.app.timetable.employeeSchedule.base,
                element: (
                    <AbilityRoute
                        can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.TIMETABLE_OWN }}
                    >
                        <TimetablePage />
                    </AbilityRoute>
                ),
                children: [
                    {
                        path: appPaths.app.timetable.employeeSchedule.absence.add,
                        element: (
                            <AbilityRoute
                                can={{
                                    action: AppActions.EDIT_AND_ADD,
                                    subject: PERMISSIONS_NAME.TIMETABLE_OWN,
                                }}
                            >
                                <AbsenceAddPage />
                            </AbilityRoute>
                        ),
                    },
                    {
                        path: appPaths.app.timetable.employeeSchedule.absence.edit.pickedAbsence,
                        element: (
                            <AbilityRoute
                                can={{
                                    action: AppActions.EDIT_AND_ADD,
                                    subject: PERMISSIONS_NAME.TIMETABLE_OWN,
                                }}
                            >
                                <AbsenceEditPage />
                            </AbilityRoute>
                        ),
                    },
                ],
            },
            {
                path: appPaths.app.timetable.evidenceWorkingTime.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.EDIT_AND_ADD,
                            subject: PERMISSIONS_NAME.TIMETABLE_OWN,
                        }}
                    >
                        <EvidenceWorkingTimePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.timetable.workingTime.base,
                element: (
                    <AbilityRoute
                        can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.TIMETABLE_OWN }}
                    >
                        <WorkingTimePage />
                    </AbilityRoute>
                ),
            },
        ],
    },
    {
        path: appPaths.app.timetable.employeeSchedule.add,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.TIMETABLE_OWN }}
            >
                <LessonAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.timetable.employeeSchedule.edit.base,
        element: <Navigate to={appPaths.app.timetable.employeeSchedule.base} replace />,
    },
    {
        path: appPaths.app.timetable.employeeSchedule.edit.pickedLesson,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.TIMETABLE_OWN }}
            >
                <LessonEditPage />
            </AbilityRoute>
        ),
    },
];
