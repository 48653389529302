import { DiscountFormFields } from 'types';
import { AppFormProps } from 'components/organisms';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { isEmptyString } from '../../isEmptyString';

export const checkIfDiscountEditFormDataAreEqualAfterChanges = (
    actualDiscountFormFields: DiscountFormFields,
    discountFormFieldsBeforeChanges: AppFormProps<DiscountFormFields>['initialValues'],
) => {
    const omitEmptyActualDiscountFormFields = omitBy(actualDiscountFormFields, isEmptyString);
    const omitEmptyDiscountFormFieldsBeforeChanges = omitBy(
        discountFormFieldsBeforeChanges,
        isEmptyString,
    );
    const omitNonFieldsDiscountFormFieldsBeforeChanges = omit(
        omitEmptyDiscountFormFieldsBeforeChanges,
        ['discountType', 'discountTarget'],
    );

    return isEqual(omitEmptyActualDiscountFormFields, omitNonFieldsDiscountFormFieldsBeforeChanges);
};
