import DOMPurify from 'dompurify';
import { GroupJournalSchedulesLayout } from 'components/layouts';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { DailyScheduleCard } from 'components/molecules';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const GroupJournalDailySchedulePage = () => {
    const { journalId } = useJournalId();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const {
        data: dailyScheduleData,
        isInitialLoading: isDailyAttendanceDataInitialLoading,
        isRefetching: isDailyAttendanceDataRefetching,
        isError: isDailyScheduleDataError,
    } = useAppQuery(
        'DAILY_SCHEDULE',
        [journalId],
        () => StaffHTTPService.schedules.dailySchedule.getDailySchedule(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const scheduleDescription = dailyScheduleData?.description
        ? DOMPurify.sanitize(dailyScheduleData?.description)
        : '';

    return (
        <GroupJournalSchedulesLayout
            scheduleType="DAILY"
            isDataInitialLoading={isDailyAttendanceDataInitialLoading}
            isDataRefetching={isDailyAttendanceDataRefetching}
            isError={isDailyScheduleDataError}
            isEmpty={!dailyScheduleData?.description}
        >
            <DailyScheduleCard description={scheduleDescription} />
        </GroupJournalSchedulesLayout>
    );
};
