import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';

export const InstitutionNameInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Nazwa placówki"
        name={formFields.institutionName}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...props}
    >
        <AppInput autoComplete="organization" />
    </AppFormItem>
);
