import { createContext, PropsWithChildren, useMemo } from 'react';
import { useAtomValue } from 'jotai/index';
import { createMongoAbility } from '@casl/ability';
import { AppMongoAbility, UserBaseAuthDTO } from 'types';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { defineAbilities } from './defineAbilities';

export const mongoAbility = createMongoAbility<AppMongoAbility>();
export const AbilityContext = createContext(mongoAbility);

export const AbilitiesProvider = ({ children }: PropsWithChildren) => {
    const userDetails = useAtomValue(userDetailsAtom);

    const context = useMemo(() => defineAbilities(userDetails as UserBaseAuthDTO), [userDetails]);

    return <AbilityContext.Provider value={context}>{children}</AbilityContext.Provider>;
};
