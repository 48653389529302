import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    GroupJournalParentTeacherConferenceAddPage,
    GroupJournalParentTeacherConferenceDetailsPage,
    GroupJournalParentTeacherConferenceEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalParentTeacherConferenceRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events
            .parentTeacherConferences.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalParentTeacherConferenceAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events
            .parentTeacherConferences.edit.base,
        element: (
            <JournalNavigate
                to={
                    appPaths.app.journals.groupJournalDetails.pickedJournal.events
                        .parentTeacherConferences.base
                }
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events
            .parentTeacherConferences.edit.pickedParentTeacherConference,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalParentTeacherConferenceEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events
            .parentTeacherConferences.details.base,
        element: (
            <JournalNavigate
                to={
                    appPaths.app.journals.groupJournalDetails.pickedJournal.events
                        .parentTeacherConferences.base
                }
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events
            .parentTeacherConferences.details.pickedParentTeacherConference,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalParentTeacherConferenceDetailsPage />
            </AbilityRoute>
        ),
    },
];
