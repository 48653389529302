import React, { useCallback, useEffect, useState } from 'react';
import { FormFinishPageProps, FormPageProps, FormTypes, StepsFormAnswers } from 'types/forms/forms';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { FormProviderProps } from 'rc-field-form/lib/FormContext';
import { Store } from 'rc-field-form/lib/interface';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import {
    getParamsToSet,
    getStepParamStepAsBoolean,
    getStepParamStepAsNumber,
} from './StepsForm.utils';

type Page = (props: FormPageProps) => React.ReactElement;
type FinishPage = (props: FormFinishPageProps) => React.ReactElement;

export type StepsFormProps = {
    mode?: FormPageProps['mode'];
    defaultPageMaxWidth?: FormPageProps['maxWidth'];
    formPagesMaxWidths?: FormPageProps['maxWidth'][];
    formPagesNames: FormTypes[];
    innerFormModalsNames?: FormTypes[];
    formPagesInitialStates?: Partial<Record<FormTypes, Store>>;
    formPagesValidation?: Partial<Record<FormTypes, (values: Store) => string | undefined>>;
    components: Page[];
    onFinish?: (data: StepsFormAnswers) => void;
    isLoading?: boolean;
    withGoBack?: FormPageProps['withGoBack'];
    error?: string;
    FinishedComponent?: FinishPage;
    //Set to true or use useStepsForm to finish form manually
    autoFinish?: boolean;
};

export const StepsForm = ({
    mode = 'add',
    isLoading,
    withGoBack,
    error,
    defaultPageMaxWidth,
    formPagesMaxWidths,
    formPagesNames,
    innerFormModalsNames,
    formPagesInitialStates,
    formPagesValidation,
    components,
    onFinish,
    FinishedComponent,
    autoFinish = false,
}: StepsFormProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { statefulNavigate } = useNavigateWithState();
    const { displayError } = useSimpleNotification();
    const [stepsData, setStepsData] = useState<StepsFormAnswers>({});
    const step = getStepParamStepAsNumber(searchParams);
    const isFinished = getStepParamStepAsBoolean(searchParams);
    const location = useLocation();
    const CurrentStepComponent = components[step];
    const formName = formPagesNames[step];
    const formWidth = formPagesMaxWidths?.[step] || defaultPageMaxWidth;
    const savedDataForCurrentStep = stepsData?.[formName];
    const enforcedInitialValuesForCurrentStep = formPagesInitialStates?.[formName];

    const clearStateAndRedirectUserToFirstStepWhenPreviousStepsDataIsMissing = useCallback(() => {
        const previousSteps = Array(step)
            .fill(null)
            .map((_, index) => formPagesNames[index]);
        const previousStepsData = previousSteps.map(
            (stepToCheck) => !stepsData || !!stepsData[stepToCheck],
        );
        const isDataMissing = !previousStepsData.every(Boolean);
        if (previousSteps.length && isDataMissing) {
            setStepsData({});
            statefulNavigate(location.pathname, { replace: true });
        }
    }, [formPagesNames, location.pathname, statefulNavigate, step, stepsData]);

    useEffect(() => {
        clearStateAndRedirectUserToFirstStepWhenPreviousStepsDataIsMissing();
    }, [clearStateAndRedirectUserToFirstStepWhenPreviousStepsDataIsMissing]);

    const onStepFinish: FormProviderProps['onFormFinish'] = (name, { values }) => {
        const submittedFormName = name as FormTypes;

        if (innerFormModalsNames?.includes(submittedFormName)) {
            return;
        }

        const errorMessageToDisplay = formPagesValidation?.[submittedFormName]?.(values);

        if (errorMessageToDisplay) {
            displayError(errorMessageToDisplay);
            return;
        }

        const isNextStepAvailable = components.length - 1 > step;
        const updatedState: StepsFormAnswers = { ...stepsData, [name]: values };

        setStepsData(updatedState);

        if (isNextStepAvailable) {
            setSearchParams(getParamsToSet(step + 1, { finished: isFinished ? 'true' : 'false' }));
        } else {
            if (autoFinish) {
                setSearchParams(getParamsToSet(step, { finished: 'true' }));
            }
            onFinish?.(updatedState);
        }
    };

    return (
        <Form.Provider onFormFinish={onStepFinish}>
            {isFinished && FinishedComponent ? (
                <FinishedComponent stepsData={stepsData} />
            ) : (
                <CurrentStepComponent
                    mode={mode}
                    error={error}
                    initialValues={savedDataForCurrentStep || undefined}
                    enforcedInitialValues={enforcedInitialValuesForCurrentStep}
                    maxWidth={formWidth}
                    stepsCount={components.length}
                    currentStep={step}
                    name={formName}
                    isLoading={isLoading}
                    withGoBack={withGoBack}
                />
            )}
        </Form.Provider>
    );
};
