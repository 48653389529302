import { Collapse, CollapseProps } from 'antd';
import styled from 'styled-components';

export type AppCollapseProps = CollapseProps;

const StyledCollapse = styled(Collapse)<AppCollapseProps>``;

export const AppCollapse = ({ ...props }: AppCollapseProps) => (
    <StyledCollapse expandIconPosition="end" {...props} />
);
