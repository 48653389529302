import { generatePath } from 'react-router-dom';
import { InspectionAddEditForm } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { InspectionFormFields } from 'types';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parseInspectionFormFieldsIntoAddUpdateInspectionDTO } from 'utils/parsers/inspection/parseInspectionFormFieldsIntoAddUpdateInspectionDTO';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { appPaths } from 'consts/paths/paths';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { journalTexts } from 'consts/text/index';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const JournalInspectionAddPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const handleAddInspectionSuccess = () => {
        displaySuccess(journalTexts.journalDetails.common.inspections.successfullyAddedInspection);
        statefulNavigate(
            generatePath(
                journalType === 'GROUP'
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.base
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addInspection,
        error: addInspectionError,
        isLoading: isAddInspectionLoading,
    } = useAppMutation(StaffHTTPService.inspections.addInspection, {
        key: ['INSPECTIONS', journalId],
        onSuccess: handleAddInspectionSuccess,
        invalidateQueryKeys: [['INSPECTIONS', journalId]],
    });

    const handleAddInspectionFinish = (inspectionFormData: InspectionFormFields) => {
        const parsedAddInspectionDTO =
            parseInspectionFormFieldsIntoAddUpdateInspectionDTO(inspectionFormData);
        addInspection({ journalId, addInspectionDTO: parsedAddInspectionDTO });
    };

    return (
        <InspectionAddEditForm
            mode="add"
            axiosError={addInspectionError}
            isLoading={isAddInspectionLoading}
            onFinish={handleAddInspectionFinish}
        />
    );
};
