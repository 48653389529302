import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { AppList, AppListProps } from '../../CommonAppComponents';

export type AppCardListBaseProps<ListItem> = Omit<AppListProps<ListItem>, 'itemLayout'>;

const StyledList: <ListItem>(props: AppCardListBaseProps<ListItem>) => ReactNode = styled(AppList)`
    ${({ theme, bordered }) => css`
        border: ${bordered ? `1px solid ${theme.colors.primary.primary5}` : `none`};
        border-radius: ${theme.borders.borderRadiusNormal}px;

        .ant-list-header {
            padding: 12px;
            border-block-end-color: ${theme.colors.primary.primary3};
        }

        .ant-list-items {
            padding: 12px;
            display: flex;
            flex-direction: column;
            gap: ${theme.sizes.marginNormal}px;
        }
    `}
`;
export const AppCardListBase = <ListItem,>(props: AppCardListBaseProps<ListItem>) => (
    <StyledList {...props} />
);
