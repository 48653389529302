import { useState } from 'react';
import { AppForm } from 'components/organisms';
import { useAuth } from 'services/firebase/useAuth';
import { SignInData } from 'types/forms/auth';
import { EmailInputElement, FormNavButtons, PasswordInputElement } from 'components/molecules';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { getFirebaseErrorLabel } from 'utils/handleFirebaseError/getFirebaseErrorLabel';
import { authTexts } from 'consts/text/index';

export const SignIn = () => {
    const [authError, setAuthError] = useState('');
    const [isSignInLoading, setIsSignInLoading] = useState(false);
    const { signIn } = useAuth();
    const navigate = useNavigate();

    const onFinish = (values: SignInData) => {
        setAuthError('');
        setIsSignInLoading(true);
        signIn(values)
            .then(() => navigate(appPaths.app.base))
            .catch((error) => setAuthError(getFirebaseErrorLabel(error)))
            .finally(() => setIsSignInLoading(false));
    };

    return (
        <AppForm<SignInData>
            maxWidth={440}
            title="Dzień dobry"
            name="signIn"
            onFinish={onFinish}
            linkButton={authTexts.registerButtonLabel}
            onLinkButtonClick={() => navigate(appPaths.auth['sign-up'])}
            overLinkText="Jesteś właścicielem lub dyrektorem placówki?"
            error={authError}
            isLoading={isSignInLoading}
        >
            <EmailInputElement withEmailValidatingRules={false} />
            <PasswordInputElement withPasswordForget withPasswordValidatingRules={false} />
            <FormNavButtons buttonText={authTexts.loginButtonLabel} />
        </AppForm>
    );
};
