import { Permission } from 'types';
import { jsonObjectsTexts, settingsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parsePermissionIntoJsonObject: (permission: Permission) => object = (permission) => {
    const { name, actions } = permission;

    const actionNames = actions?.map(
        (action) => settingsTexts.rolesAndPermissions.permissionSwitchLabels[action],
    );

    return {
        [jsonObjectsTexts.role.innerObjects.permission.fields.name]: name,
        [jsonObjectsTexts.role.innerObjects.permission.fields.action]:
            actionNames || EMPTY_VALUE_LABEL,
    };
};
