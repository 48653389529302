import { generatePath } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { DualValueCellContent } from 'components/atoms';
import { LinkDisplay } from 'components/molecules/Table/LinkDisplay/LinkDisplay';
import { appPaths } from 'consts/paths/paths';
import { GroupConsultationId, JournalId, JournalType, SpecialConsultationId } from 'types';

type ConsultationSubjectDisplayProps = {
    subject: string;
    date: string;
    journalId: JournalId;
    consultationId: GroupConsultationId | SpecialConsultationId;
    journalType: JournalType;
};

export const ConsultationSubjectDisplay = ({
    subject,
    date,
    journalId,
    consultationId,
    journalType,
}: ConsultationSubjectDisplayProps) => {
    const isMobile = useIsMobile();

    const parsedDate = parseStringIntoFormattedStringDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY');
    const inspectionDetailsPagePath = generatePath(
        journalType === 'GROUP'
            ? appPaths.app.journals.groupJournalDetails.pickedJournal.consultations.details
                  .pickedConsultation
            : appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.details
                  .pickedConsultation,
        {
            journalId,
            consultationId,
        },
    );

    return isMobile ? (
        <DualValueCellContent mainValue={subject} secondaryValue={parsedDate} />
    ) : (
        <LinkDisplay to={inspectionDetailsPagePath}>{subject}</LinkDisplay>
    );
};
