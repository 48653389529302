import { ChildCostAllocation } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { referToMapper } from 'consts/settlementChildren/referToMapper';
import { priceDeductionTypeLabels } from 'consts/childCostAllocation/priceDeductionTypeLabels';
import { getFullName } from 'utils/getFullName';
import { parseAddressIntoJsonObject } from 'utils/parsers/jsonObject/common/parseAddressIntoJsonObject';

export const parseChildCostAllocationIntoJsonObject: (
    childCostAllocation: ChildCostAllocation,
) => object = (childCostAllocation) => {
    const {
        foundationName,
        nip,
        address,
        bankAccountNumber,
        refersTo,
        type,
        specificAmount,
        percentageAmount,
        archived,
        child,
    } = childCostAllocation;

    const parsedBankAccountNumber = bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : EMPTY_VALUE_LABEL;
    const formattedSpecificAmount = specificAmount
        ? getFormattedPrice(specificAmount)
        : EMPTY_VALUE_LABEL;
    const formattedPercentageAmount = percentageAmount ? `${percentageAmount}%` : EMPTY_VALUE_LABEL;
    const parsedChildName = getFullName(child.firstName, child.lastName);

    const parsedBaseChildCostAllocationJsonObject = {
        [jsonObjectsTexts.childCostAllocation.fields.foundationName]: foundationName,
        [jsonObjectsTexts.childCostAllocation.fields.nip]: nip,
        [jsonObjectsTexts.childCostAllocation.fields.address]: parseAddressIntoJsonObject(address),
        [jsonObjectsTexts.childCostAllocation.fields.bankAccountNumber]: parsedBankAccountNumber,
        [jsonObjectsTexts.childCostAllocation.fields.refersTo]: referToMapper[refersTo],
        [jsonObjectsTexts.childCostAllocation.fields.type]: priceDeductionTypeLabels[type],
        [jsonObjectsTexts.childCostAllocation.fields.child]: parsedChildName,
        [jsonObjectsTexts.childCostAllocation.fields.archived]: archived
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };

    if (specificAmount) {
        return {
            ...parsedBaseChildCostAllocationJsonObject,
            [jsonObjectsTexts.childCostAllocation.fields.specificAmount]: formattedSpecificAmount,
        };
    }

    return {
        ...parsedBaseChildCostAllocationJsonObject,
        [jsonObjectsTexts.childCostAllocation.fields.percentageAmount]: formattedPercentageAmount,
    };
};
