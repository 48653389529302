import { Rule } from 'rc-field-form/es/interface';
import { includesCapitalLetter, includesLowerCaseLetter } from './regexps';
import { specialSymbols } from './specialSymbols';
import { formFields } from './form/formFields';
import { authTexts } from './text';

const noSpecialSymbolsError = 'Hasło musi zawierać minimum 1 znak specjalny np. !, @, #, $';

export const passwordRepeatValidatingRules: Rule[] = [
    ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue(formFields.password) === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error(authTexts.passwordsDontMatch));
        },
    }),
];
export const passwordValidatingRules: Rule[] = [
    { type: 'string', min: 8, message: 'Hasło musi mieć co najmniej 8 znaków', required: true },
    {
        pattern: includesCapitalLetter,
        message: 'Hasło musi zawierać wielką literę',
        required: true,
    },
    {
        pattern: includesLowerCaseLetter,
        message: 'Hasło musi zawierać małą literę',
        required: true,
    },
    {
        validator: (_, value) =>
            value && specialSymbols.some((symbol) => value.includes(symbol))
                ? Promise.resolve()
                : Promise.reject(new Error(noSpecialSymbolsError)),
        message: noSpecialSymbolsError,
    },
];
