import { GroupTeacherDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseGroupTeacherDTOIntoJsonObject = (groupTeacherDTO: GroupTeacherDTO) => {
    const { firstName, lastName, leaveDate } = groupTeacherDTO;

    const formattedLeaveDate = leaveDate
        ? parseStringIntoFormattedStringDate(leaveDate)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.groupDTO.innerObjects.groupTeacherDTO.fields.firstname]: firstName,
        [jsonObjectsTexts.groupDTO.innerObjects.groupTeacherDTO.fields.lastname]: lastName,
        [jsonObjectsTexts.groupDTO.innerObjects.groupTeacherDTO.fields.leaveDate]:
            formattedLeaveDate,
    };
};
