import { useAppAbility } from 'abilities/useAppAbility';
import { AppActions } from 'types';
import { ROLE_BASED_PERMISSIONS_NAME } from 'consts/permissions/permissions';

export const useMessagesAbilities = () => {
    const ability = useAppAbility();

    const messagesAbilities = {
        checkIfCanViewMessageReadStatus: () =>
            ability.can(AppActions.DO_ACTION, ROLE_BASED_PERMISSIONS_NAME.MESSAGE_READ_STATUS),
    };

    return { messagesAbilities };
};
