import { messagesTexts } from 'consts/text';
import { AppInfiniteScroll, AppList, MailThreadListItem } from 'components/atoms';
import { MailThreadDTO, MailThreadId } from 'types';
import { DynamicStateDisplayWrapper } from 'components/molecules/StatesComponents';
import { Container, StateDisplayContainer } from './MailThreadList.styled';

type MailThreadListProps = {
    mailThreadId?: MailThreadId;
    mailThreadsData?: MailThreadDTO[];
    searchValue?: string;
    isInitialLoading: boolean;
    isRefetching: boolean;
    isError: boolean;
    hasNextDataPage?: boolean;
    fetchNextDataPage: () => void;
    handleSelect: (selectedMailThreadId: MailThreadId) => void;
};

const MAIL_THREADS_SCROLLABLE_TARGET = 'MAIL_THREADS_SCROLLABLE_DIV';

export const MailThreadList = ({
    mailThreadId,
    mailThreadsData,
    searchValue,
    isInitialLoading,
    isRefetching,
    isError,
    hasNextDataPage,
    fetchNextDataPage,
    handleSelect,
}: MailThreadListProps) => {
    const isEmpty = !mailThreadsData?.length;
    const isNothingFound = searchValue && isEmpty;

    return (
        <DynamicStateDisplayWrapper
            statusData={{
                isDataInitialLoading: isInitialLoading,
                isDataRefetching: isRefetching,
                isDataError: isError,
                isEmpty,
                isNothingFound: !!isNothingFound,
            }}
            emptyStateConfig={{
                emptyInfo: messagesTexts.noMailThreads,
            }}
            StateDisplayContainer={StateDisplayContainer}
        >
            <Container id={MAIL_THREADS_SCROLLABLE_TARGET}>
                <AppInfiniteScroll
                    dataLength={mailThreadsData?.length || 0}
                    scrollableTarget={MAIL_THREADS_SCROLLABLE_TARGET}
                    hasMore={hasNextDataPage || false}
                    next={fetchNextDataPage}
                >
                    <AppList<MailThreadDTO>
                        dataSource={mailThreadsData}
                        renderItem={(mailThread) => (
                            <MailThreadListItem
                                key={mailThread.id}
                                mailThreadData={mailThread}
                                isActive={mailThread.id === mailThreadId}
                                onMailThreadClick={handleSelect}
                            />
                        )}
                    />
                </AppInfiniteScroll>
            </Container>
        </DynamicStateDisplayWrapper>
    );
};
