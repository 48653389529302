import { useContext, useMemo } from 'react';
import { AppFormContext } from 'contexts/AppFormContext';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { SimpleSelectElement } from 'components/molecules';
import { commonTexts, timetableTexts } from 'consts/text';
import { parseJournalDTOIntoSelectOptionType } from 'utils/parsers/journal/parseJournalDTOIntoSelectOptionType';
import { formFields } from 'consts/form/formFields';
import { EmployeeId, JournalDTO, JournalId } from 'types';
import { journalFields } from 'consts/journal/journalFields';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type JournalPickerProps = {
    onChangeJournal?: (journalData: JournalDTO) => void;
    onClear?: () => void;
    disabled?: boolean;
    teacherId?: EmployeeId;
};

export const JournalPicker = ({
    onChangeJournal,
    onClear,
    disabled = false,
    teacherId,
}: JournalPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);

    const {
        data: journalsData,
        isFetching: isJournalsDataFetching,
        isError: isJournalsDataError,
    } = useAppQuery(
        'LESSONS_JOURNALS',
        [teacherId],
        () => StaffHTTPService.employeeSchedule.getLessonsJournals({ teacherId, type: 'SPECIAL' }),
        {
            enabled: !!teacherId,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isJournalsDataFetching,
        isError: isJournalsDataError,
        actualError: formError,
        errorMessage: commonTexts.errorMessages.journalsFetching,
        setError: setFormError,
    });

    const handleChangeJournal = (journalId: JournalId) => {
        if (!journalsData) {
            return;
        }
        const selectedJournal = journalsData.find(
            (journalsDataItem) => journalsDataItem[journalFields.id] === journalId,
        );
        if (!selectedJournal) {
            return;
        }
        onChangeJournal?.(selectedJournal);
    };

    const journalsOptions = useMemo(() => {
        if (!journalsData) {
            return [];
        }
        return journalsData.map(parseJournalDTOIntoSelectOptionType);
    }, [journalsData]);

    return (
        <SimpleSelectElement
            name={formFields.journalId}
            label={timetableTexts.lessons.linkJournal}
            options={journalsOptions}
            loading={isJournalsDataFetching}
            onChange={handleChangeJournal}
            onClear={onClear}
            disabled={disabled}
        />
    );
};
