import { ParentAbsenceAddModal } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { parentTexts } from 'consts/text';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ParentAbsenceFormFields } from 'types';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { parseParentAbsenceFormFieldsIntoAddChildAbsencesParams } from 'utils/parsers/absence/parseParentAbsenceFormFieldsIntoAddChildAbsencesParams';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ParentAbsencesAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const navigateToGroupAttendanceBase = () =>
        statefulNavigate(appPaths.app.parent.news.attendance.base);

    const handleAddAbsenceSuccess = () => {
        displaySuccess(
            parentTexts.groupAttendance.addAbsenceForm.messages.addAbsenceSuccessMessage,
        );
        navigateToGroupAttendanceBase();
    };

    const {
        mutate: addAbsence,
        error: addAbsenceError,
        isLoading: isAddAbsenceLoading,
    } = useAppMutation(ParentHTTPService.groupAttendance.addAbsences, {
        key: ['ADD_ABSENCE'],
        onSuccess: handleAddAbsenceSuccess,
        invalidateQueryKeys: [['MONTHLY_ATTENDANCE']],
    });

    const { data: journalDates } = useAppQuery(
        'JOURNAL_DATES',
        [],
        () => ParentHTTPService.journal.getJournalDates(),
        {
            enabled: false,
        },
    );

    const handleAddAbsenceFinish = (parentAbsenceFormFields: ParentAbsenceFormFields) => {
        const parsedUpdateDailyChildGroupAttendanceParams =
            parseParentAbsenceFormFieldsIntoAddChildAbsencesParams(parentAbsenceFormFields);
        addAbsence(parsedUpdateDailyChildGroupAttendanceParams);
    };

    return (
        <ParentAbsenceAddModal
            open
            journalDates={journalDates}
            axiosError={addAbsenceError}
            isLoading={isAddAbsenceLoading}
            onCancel={navigateToGroupAttendanceBase}
            onFinish={handleAddAbsenceFinish}
        />
    );
};
