import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Navigation } from 'navigation/navigation';
import { theme } from 'theme/styledComponents/theme';
import { AntDesignConfigProvider } from 'theme/antd/AntDesignConfigProvider';
import { AppNotificationsGlobalStyle } from 'services/appNotification/appNotification';
import { AppQueryClientProvider } from 'services/reactQuery/AppQueryClientProvider';
import { AbilitiesProvider } from 'abilities/abilitiesProvider/abilitiesProvider';

export const App = () => (
    <ThemeProvider theme={theme}>
        <AntDesignConfigProvider>
            <AppQueryClientProvider>
                <BrowserRouter>
                    <AbilitiesProvider>
                        <AppNotificationsGlobalStyle />
                        <Navigation />
                    </AbilitiesProvider>
                </BrowserRouter>
                {import.meta.env.VITE_APP_ENV === 'DEV' && (
                    <ReactQueryDevtools initialIsOpen={false} />
                )}
            </AppQueryClientProvider>
        </AntDesignConfigProvider>
    </ThemeProvider>
);
