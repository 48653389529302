export const parsePaginationDataToTablePaginationData: ({
    currentPage,
    currentPageSize,
}: {
    currentPage: string;
    currentPageSize: string;
}) => {
    current: number;
    pageSize: number;
} = ({ currentPage, currentPageSize }) => ({
    current: Number(currentPage) + 1,
    pageSize: Number(currentPageSize),
});
