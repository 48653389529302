import { TitleProps } from 'antd/lib/typography/Title';
import { ReactNode } from 'react';
import { AppModalBase, AppModalBaseProps, ComponentBlockingAppLoader } from 'components/atoms';
import { IconContainer, ModalTitle } from './AppModal.styled';

export type AppModalProps = Omit<
    AppModalBaseProps,
    'title' | 'okButtonProps' | 'cancelButtonProps' | 'okText' | 'okType' | 'cancelText' | 'onOk'
> & {
    title?: string;
    titleLevel?: TitleProps['level'];
    isLoading?: boolean;
    icon?: ReactNode;
    children?: ReactNode;
};

export const AppModal = ({
    title,
    isLoading,
    icon,
    children,
    titleLevel = 4,
    isMobileFullScreenMode = true,
    ...props
}: AppModalProps) => (
    <AppModalBase isMobileFullScreenMode={isMobileFullScreenMode} footer={null} {...props}>
        {icon && <IconContainer>{icon}</IconContainer>}
        {title && <ModalTitle level={titleLevel}>{title}</ModalTitle>}
        {children}
        {isLoading && <ComponentBlockingAppLoader />}
    </AppModalBase>
);
