import {
    MealPaymentType,
    MethodOfCalcTheFee,
    SettlementStaysFormFields,
    UpdateSettlementStaysDTO,
} from 'types';
import { parseDayjsTimeIntoHoursMinutesFormat } from '../dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export const parseSettlementStaysFormFieldsIntoUpdateSettlementStaysDTO = (
    settlementStaysFormFields: SettlementStaysFormFields,
): UpdateSettlementStaysDTO => {
    const { freeHoursStart, freeHoursEnd, settlementType, paymentType, ...otherFields } =
        settlementStaysFormFields;

    return {
        ...otherFields,
        settlementType: settlementType as MealPaymentType,
        paymentType: paymentType as MethodOfCalcTheFee,
        freeHoursStart: freeHoursStart
            ? parseDayjsTimeIntoHoursMinutesFormat(freeHoursStart)
            : null,
        freeHoursEnd: freeHoursEnd ? parseDayjsTimeIntoHoursMinutesFormat(freeHoursEnd) : null,
    };
};
