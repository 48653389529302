import { SimpleSelectElement } from 'components/molecules';
import { settlementTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { CostAllocationRefersTo } from 'types';

const options = [
    { value: CostAllocationRefersTo.STAY, label: settlementTexts.children.costAllocation.stay },
    {
        value: CostAllocationRefersTo.MEALS,
        label: settlementTexts.children.costAllocation.meals,
    },
    {
        value: CostAllocationRefersTo.EXTRA_LESSONS,
        label: settlementTexts.children.costAllocation.extraLessons,
    },
];

export const CostAllocationTargetPicker = () => (
    <SimpleSelectElement
        name={formFields.refersTo}
        label={settlementTexts.children.costAllocation.costAllocationTarget}
        options={options}
    />
);
