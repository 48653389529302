import { useAtomValue } from 'jotai';
import { Badge } from 'antd';
import { AppFlex } from 'components/atoms/CommonAppComponents/AppFlex/AppFlex';
import { MessagesModuleIcon } from 'assets';
import { isUnreadMessageAvailableAtom } from 'jotaiAtoms/isUnreadMessageAvailableAtom';

export const MessagesModuleIconWithUnreadMessageIndicator = () => {
    const isUnreadMessageAvailableState = useAtomValue(isUnreadMessageAvailableAtom);

    return (
        <AppFlex>
            <Badge dot={isUnreadMessageAvailableState}>
                <MessagesModuleIcon />
            </Badge>
        </AppFlex>
    );
};
