import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useAtomValue } from 'jotai';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { ComponentBlockingAppLoader } from 'components/atoms';
import { auth } from 'services/firebase/firebase';
import { useUserDetailsFetch } from 'hooks/useUserDetailsFetch/useUserDetailsFetch';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { useMessaging } from 'services/firebase/useMessaging';
import { useUnreadMailThreadsStatus } from 'hooks/useUnreadMailThreadsStatus/useUnreadMailThreadsStatus';

export const LoggedAppLayout = () => {
    const { pathname } = useLocation();
    const { fetchUserDetails, isInitialFetching } = useUserDetailsFetch();
    const userDetails = useAtomValue(userDetailsAtom);
    const currentInstitution = useCurrentInstitution();
    const currentChild = useCurrentChild();
    const isUserEmailVerified = auth.currentUser?.emailVerified;
    const navigate = useNavigate();

    useMessaging();
    useUnreadMailThreadsStatus();

    const userType = userDetails?.type;

    useEffect(() => {
        if (!userDetails && isUserEmailVerified) {
            fetchUserDetails();
        }
    }, [fetchUserDetails, isUserEmailVerified, userDetails]);

    useEffect(() => {
        if (!isUserEmailVerified) {
            navigate(appPaths.app['user-confirmation'], { replace: true });
        }
    }, [isUserEmailVerified, navigate]);

    const isNecessaryDataReady = useMemo(() => {
        if (!userType) {
            return false;
        }
        if (userType === 'PARENT' && !currentChild.id) {
            return false;
        }
        const isStuffUserType = userType === 'OWNER' || userType === 'EMPLOYEE';
        return !(isStuffUserType && !currentInstitution.id);
    }, [currentChild.id, currentInstitution.id, userType]);

    const hasPathContent = isNecessaryDataReady || pathname === appPaths.app['user-confirmation'];
    const isContentVisible = hasPathContent && !isInitialFetching;

    return isContentVisible ? <Outlet /> : <ComponentBlockingAppLoader />;
};
