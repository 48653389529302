import { CalendarEventDto } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { eventTypeLabels } from 'consts/calendar/calendarTypeLabels';

export const parseCalendarEventDTOIntoJsonObject: (calendarEventDTO: CalendarEventDto) => object = (
    calendarEventDTO,
) => {
    const { name, description, type, freeFromSchool, startDate, endDate } = calendarEventDTO;

    const formattedStartDate = parseStringIntoFormattedStringDate(startDate);
    const formattedEndDate = parseStringIntoFormattedStringDate(endDate);

    return {
        [jsonObjectsTexts.calendarEventDTO.fields.name]: name,
        [jsonObjectsTexts.calendarEventDTO.fields.type]: eventTypeLabels[type],
        [jsonObjectsTexts.calendarEventDTO.fields.description]: description,
        [jsonObjectsTexts.calendarEventDTO.fields.startDate]: formattedStartDate,
        [jsonObjectsTexts.calendarEventDTO.fields.endDate]: formattedEndDate,
        [jsonObjectsTexts.calendarEventDTO.fields.freeFromSchool]: freeFromSchool
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
