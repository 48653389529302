import { EventAddEditModal } from 'components/templates/calendar';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { EventFormFields } from 'types/forms/event';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseEventFormFieldsIntoAddEventDTO } from 'utils/parsers/calendar/parseEventFormFieldsIntoAddEventDTO';
import { calendarTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const CalendarAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const onAddEventSuccess = () => {
        displaySuccess(calendarTexts.successfullyAddEventMessage);
        statefulNavigate(appPaths.app.calendar.base, {
            replace: true,
        });
    };

    const {
        mutate: addEvent,
        error: addEventError,
        isLoading: isAddEventLoading,
    } = useAppMutation(StaffHTTPService.calendar.createEvent, {
        key: ['ADD_EVENT'],
        onSuccess: onAddEventSuccess,
        invalidateQueryKeys: [['MONTHLY_EVENTS'], ['DAILY_EVENTS']],
    });

    const onAddGroupFinish = (eventData: EventFormFields) =>
        addEvent(parseEventFormFieldsIntoAddEventDTO(eventData));

    return (
        <EventAddEditModal
            mode="add"
            axiosError={addEventError}
            isLoading={isAddEventLoading}
            onFinish={onAddGroupFinish}
        />
    );
};
