import { JournalType } from 'types';
import { groupJournalMenuItems, specialJournalMenuItems } from '../../menuItems';

const PAGE_WITH_INNER_TABS_PATHNAME_SEGMENTS_NUMBER = 7;

export const getSelectedMenuItemKey = (currentPathname: string, journalType: JournalType) => {
    const menuItems = journalType === 'GROUP' ? groupJournalMenuItems : specialJournalMenuItems;
    const currentPathnameSegments = currentPathname.split('/');
    const lastCurrentPathnameSegment = currentPathnameSegments[currentPathnameSegments.length - 1];
    const secondToLastCurrentPathnameSegment =
        currentPathnameSegments[currentPathnameSegments.length - 2];
    const selectedMenuItem = Object.values(menuItems).find((menuItem) => {
        if (currentPathnameSegments.length === PAGE_WITH_INNER_TABS_PATHNAME_SEGMENTS_NUMBER) {
            return menuItem.key.includes(secondToLastCurrentPathnameSegment);
        }
        return menuItem.key.includes(lastCurrentPathnameSegment);
    });
    return selectedMenuItem?.key || '';
};
