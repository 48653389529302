import { useEffect, useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { MailThreadId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { messagesTexts } from 'consts/text';
import { useAppInfiniteQuery } from 'services/reactQuery/useAppInfiniteQuery';
import { parsePageableResponseDataIntoArray } from 'utils/parsers/api/parsePageableResponseDataIntoArray';
import { MESSAGES_PAGE_SIZE } from 'consts/pagination';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { useUpdateSelectedMailThreadReadStatus } from 'hooks/useUpdateSelectedMailThreadReadStatus/useUpdateSelectedMailThreadReadStatus';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { useInvalidateMailThreads } from 'hooks/useInvalidateMailThreads/useInvalidateMailThreads';

type ParentMailThreadDetailsPageNetworkManageProps = {
    mailThreadId: MailThreadId;
    onMessageAddRequestSuccess: () => void;
};

export const useParentMailThreadDetailsPageNetworkManage = ({
    mailThreadId,
    onMessageAddRequestSuccess,
}: ParentMailThreadDetailsPageNetworkManageProps) => {
    const { id: childId, institutionAddress: childInstitutionAddress } = useCurrentChild();
    const { displaySuccess, displayError } = useSimpleNotification();

    const { invalidateUnreadMailThreadsStatusData } = useInvalidateMailThreads();

    const {
        data: mailThreadData,
        isInitialLoading: isMailThreadDataInitialLoading,
        isRefetching: isMailThreadDataRefetching,
        isError: isMailThreadDataError,
    } = useAppQuery(
        'MAIL_THREAD',
        [mailThreadId],
        () => ParentHTTPService.mailThreads.getMailThread(mailThreadId),
        {
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        invalidateUnreadMailThreadsStatusData();
    }, [invalidateUnreadMailThreadsStatusData, isMailThreadDataRefetching]);

    const {
        data: replyMailThreadData,
        isInitialLoading: isReplyMailThreadDataInitialLoading,
        isRefetching: isReplyMailThreadDataRefetching,
        error: replyMailThreadDataError,
    } = useAppQuery(
        'REPLY_MAIL_THREAD',
        [mailThreadId],
        () => ParentHTTPService.mailThreads.getReplyMailThread(mailThreadId),
        { refetchOnWindowFocus: false, retry: false },
    );

    const {
        data: pageableMessagesData,
        isInitialLoading: isMessagesDataInitialLoading,
        isRefetching: isMessagesDataRefetching,
        isError: isMessagesDataError,
        fetchNextPage: fetchNextMessagesDataPage,
        hasNextPage: hasNextMessagesDataPage,
    } = useAppInfiniteQuery(
        'MESSAGES',
        [mailThreadId],
        ({ pageParam }) =>
            ParentHTTPService.mailThreads.messages.getMailThreadMessages({
                mailThreadId,
                paginationParams: {
                    lastReadMessageSeqNumber: pageParam,
                    pageSize: MESSAGES_PAGE_SIZE,
                },
            }),
        {
            getNextPageParam: (lastPage) =>
                lastPage.data.readAll ? undefined : lastPage.data.lastReadMessageNumber,
        },
    );

    const { mutate: addMessage, isLoading: isAddMessageLoading } = useAppMutation(
        ParentHTTPService.mailThreads.messages.addMessage,
        {
            key: ['ADD_MESSAGE'],
            onSuccess: () => {
                onMessageAddRequestSuccess();
                displaySuccess(messagesTexts.successfullyAddMessageInfo);
            },
            onError: () => displayError(messagesTexts.addMessageErrorInfo),
            invalidateQueryKeys: [
                ['MAIL_THREADS'],
                ['MAIL_THREAD', mailThreadId],
                ['MESSAGES', mailThreadId],
            ],
        },
    );

    useUpdateSelectedMailThreadReadStatus({
        mailThreadId,
        isLastMessageRead: mailThreadData?.lastMessage.read,
        institutionId: childInstitutionAddress.institutionId,
        childId,
    });

    const messagesData = useMemo(() => {
        if (!pageableMessagesData) {
            return undefined;
        }
        return parsePageableResponseDataIntoArray(pageableMessagesData);
    }, [pageableMessagesData]);

    return {
        mailThreadData,
        replyMailThreadId: replyMailThreadData?.id,
        messagesData,
        replyMailThreadDataError,
        addMessage,
        isMailThreadDataInitialLoading,
        isReplyMailThreadDataInitialLoading,
        isMessagesDataInitialLoading,
        isMailThreadDataRefetching,
        isReplyMailThreadDataRefetching,
        isMessagesDataRefetching,
        isAddMessageLoading,
        isMailThreadDataError,
        isMessagesDataError,
        hasNextMessagesDataPage,
        fetchNextMessagesDataPage,
    };
};
