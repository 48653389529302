import { InstitutionDto } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parsePrincipalDTOIntoJsonObject } from 'utils/parsers/jsonObject/principal/parsePrincipalDTOIntoJsonObject';
import { parseInstitutionBasicDtoIntoJsonObject } from 'utils/parsers/jsonObject/institution/parseInstitutionBasicDtoIntoJsonObject';

export const parseInstitutionDtoIntoJsonObject: (institutionDto: InstitutionDto) => object = (
    institutionDto,
) => {
    const { principal, ...basicInstitutionData } = institutionDto;

    const institutionBasicJsonObject = parseInstitutionBasicDtoIntoJsonObject(basicInstitutionData);
    const principalJsonObject = parsePrincipalDTOIntoJsonObject(principal);

    return {
        ...institutionBasicJsonObject,
        [jsonObjectsTexts.institutionDto.fields.principal]: principalJsonObject,
    };
};
