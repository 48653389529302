import { EmployeeFormFields, EmployeeUser } from 'types';

export const parseEmployeeUserIntoAddEmployeeUserForm: (
    employee: EmployeeUser,
) => EmployeeFormFields = (employee) => ({
    lastName: employee.lastName,
    firstName: employee.firstName,
    employeeRole: employee.role.id,
    groups: employee.groups.map(({ id }) => id),
    email: employee.email,
    phoneNumber: employee.phoneNumber || '',
});
