import { commonTexts, settlementTexts } from 'consts/text';
import { AppTable, AppTableProps } from 'components/organisms';
import { settlementChildrenBillComponentDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';
import { ChildBillComponent } from 'types';

const columns: AppTableProps<ChildBillComponent>['columns'] = [
    {
        title: settlementTexts.children.billParts.type,
        dataIndex: settlementChildrenBillComponentDTOFields.name,
        key: settlementChildrenBillComponentDTOFields.name,
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: commonTexts.dataLabels.amount,
        dataIndex: settlementChildrenBillComponentDTOFields.amountString,
        key: settlementChildrenBillComponentDTOFields.amountString,
    },
    {
        title: settlementTexts.children.billParts.discount,
        dataIndex: settlementChildrenBillComponentDTOFields.discountString,
        key: settlementChildrenBillComponentDTOFields.discountString,
        isInvisibleInMobile: true,
    },
];

type ChildrenBillComponentsTableProps = { billComponents: ChildBillComponent[] | null };

export const ChildrenBillComponentsTable = ({
    billComponents,
}: ChildrenBillComponentsTableProps) => (
    <AppTable<ChildBillComponent>
        isTableManageUtilsHidden
        isTableHeaderHidden
        emptyInfo={settlementTexts.children.details.billComponentsEmpty}
        columns={columns}
        rowKey={({ id }) => id}
        dataSource={billComponents ?? undefined}
        defaultExpandStyle={false}
    />
);
