import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Filter } from 'components/molecules';
import { GetJournalsFilterKey, JournalStatus, JournalType } from 'types';
import { isBeforeSchoolYear } from 'utils/isBeforeSchoolYear';
import { commonTexts } from 'consts/text/index';

const typeOptions: { label: string; value: JournalType }[] = [
    { label: 'Grupowy', value: 'GROUP' },
    { label: 'Specjalistyczny', value: 'SPECIAL' },
];

const statusOptions: { label: string; value: JournalStatus }[] = [
    { label: 'Aktywne', value: 'ACTIVE' },
    { label: 'Zarchiwizowane', value: 'ARCHIVED' },
];

const DEFAULT_STATUS_PARAM: JournalStatus = 'ACTIVE';

type JournalsPageFiltersProps = {
    type?: JournalType | null;
    status?: JournalStatus | null;
    fromYear?: number | null;
    toYear?: number | null;
    onChangeFilter?: (filterKey: GetJournalsFilterKey, value?: string) => void;
    onChangeFilters?: (filterValues: Partial<Record<GetJournalsFilterKey, string>>) => void;
};

export const JournalsPageFilters = ({
    type,
    status,
    fromYear,
    toYear,
    onChangeFilter,
    onChangeFilters,
}: JournalsPageFiltersProps) => {
    useEffect(() => {
        const currentDate = dayjs();
        const currentYear = currentDate.year();
        const isBeforeNewSchoolYear = isBeforeSchoolYear(currentDate, currentYear);
        onChangeFilters?.({
            status: DEFAULT_STATUS_PARAM,
            fromYear: isBeforeNewSchoolYear ? (currentYear - 1).toString() : currentYear.toString(),
            toYear: isBeforeNewSchoolYear ? currentYear.toString() : (currentYear + 1).toString(),
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Filter
                input={
                    <Filter.Select
                        value={type}
                        options={typeOptions}
                        placeholder="Wybierz"
                        onChange={(value) => onChangeFilter?.('type', value)}
                    />
                }
                label="Typ dziennika"
                width="15%"
            />
            <Filter
                input={
                    <Filter.Select
                        value={status}
                        options={statusOptions}
                        placeholder="Wybierz"
                        onChange={(value) => onChangeFilter?.('status', value)}
                    />
                }
                label={commonTexts.dataLabels.status}
                width="15%"
            />
            <Filter
                input={
                    <Filter.RangePicker
                        picker="year"
                        value={[
                            fromYear ? dayjs().year(fromYear) : null,
                            toYear ? dayjs().year(toYear) : null,
                        ]}
                        onChange={(_, dateStrings) =>
                            onChangeFilters?.({ fromYear: dateStrings[0], toYear: dateStrings[1] })
                        }
                    />
                }
                label="Rok szkolny"
                width="15%"
            />
        </>
    );
};
