import styled, { css } from 'styled-components';
import { AppRadioButton } from '../../atoms';

export const RadioGroupWrapper = styled.div`
    width: 76px;
`;

export const YesButton = styled(AppRadioButton)`
    ${({ theme }) => css`
        &.ant-radio-button-wrapper {
            width: 38px;
            height: 38px;
        }
        &.ant-radio-button-wrapper-checked {
            background-color: ${theme.colors.green.green5} !important;
            color: ${theme.colors.grayscale.gray1} !important;
            border-color: ${theme.colors.primary.primary5} !important;
        }
    `}
`;

export const NoButton = styled(AppRadioButton)`
    ${({ theme }) => css`
        &.ant-radio-button-wrapper {
            width: 38px;
            height: 38px;
        }
        &.ant-radio-button-wrapper-checked {
            background-color: ${theme.colors.red.red5} !important;
            color: ${theme.colors.grayscale.gray1} !important;
            border-color: ${theme.colors.primary.primary5} !important;
        }
    `}
`;
