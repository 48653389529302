import { ImportantEventDTO, ImportantEventFormFields } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';

export const parseImportantEventDTOIntoImportantEventFormFields: (
    importantEventDTO: ImportantEventDTO,
) => ImportantEventFormFields = ({ name, date, description }) => ({
    name,
    date: parseStringIntoDayjsDate(date),
    description,
});
