import { useAppQuery } from 'services/reactQuery/useAppQuery';
import AdminHTTPService from 'services/HTTPService/admin/AdminHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, subscriptionsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type AdminInstitutionsPageNetworkManageProps = {
    search?: string;
};

export const useAdminInstitutionsPageNetworkManage = ({
    search,
}: AdminInstitutionsPageNetworkManageProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: institutionSubscriptionDataItems,
        isInitialLoading: isInstitutionSubscriptionDataItemsInitialLoading,
        isRefetching: isInstitutionSubscriptionDataItemsRefetching,
        isError: isInstitutionSubscriptionDataItemsError,
    } = useAppQuery(
        'INSTITUTION_SUBSCRIPTION_DATA_ITEMS',
        [search],
        () => AdminHTTPService.clientSubscriptions.getInstitutionSubscriptionDataItems(search),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const handleBlockSubscriptionSuccess = () =>
        displaySuccess(subscriptionsTexts.list.actionMessages.successfullyBlockedSubscription);

    const handleBlockSubscriptionError = () =>
        displayError(commonTexts.errorMessages.actionExecution);

    const { mutate: blockSubscription, isLoading: isBlockSubscriptionLoading } = useAppMutation(
        AdminHTTPService.clientSubscriptions.blockSubscription,
        {
            key: ['BLOCK_SUBSCRIPTION'],
            onSuccess: handleBlockSubscriptionSuccess,
            onError: handleBlockSubscriptionError,
            invalidateQueryKeys: [['INSTITUTION_SUBSCRIPTION_DATA_ITEMS']],
        },
    );

    return {
        institutionSubscriptionDataItems,
        isInstitutionSubscriptionDataItemsInitialLoading,
        isInstitutionSubscriptionDataItemsRefetching,
        isBlockSubscriptionLoading,
        isInstitutionSubscriptionDataItemsError,
        blockSubscription,
    };
};
