import { AppForm, AppFormProps, TeachersPicker } from 'components/organisms';
import { AxiosError } from 'axios';
import { CommonFormProps, FormModeDependedTexts, GroupFormFields } from 'types';
import { FormNavButtons, GroupInputElement, TeacherPickerVersion } from 'components/molecules';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { commonTexts, groupsTexts } from 'consts/text/index';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';
import { groupAddEditErrorParser } from 'utils/errorHandlers/group/groupAddEditErrorParser';
import { ChildrenPicker } from 'components/templates/child';

export type GroupAddEditFormProps = Omit<
    AppFormProps<GroupFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (groupData: GroupFormFields) => void;
    };

const addModeTexts: FormModeDependedTexts = {
    name: 'groupAdd',
    title: groupsTexts.addNewGroup,
    buttonText: groupsTexts.addNewGroup,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'groupEdit',
    title: 'Edytuj grupę',
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const GroupAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    ...props
}: GroupAddEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const groupAddEditError = axiosError ? groupAddEditErrorParser(axiosError) : '';

    return (
        <AppForm<GroupFormFields>
            maxWidth={570}
            title={title}
            name={name}
            error={groupAddEditError}
            withGoBack
            {...props}
        >
            <GroupInputElement />
            <TeachersPicker
                version={TeacherPickerVersion.GROUP}
                fetchConfig={{
                    fetchFunction: StaffHTTPService.groups.getGroupsEmployees,
                    fetchQueryKey: 'GROUPS_EMPLOYEES' as QueryMainKeysType,
                }}
            />
            <ChildrenPicker />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
            />
        </AppForm>
    );
};
