import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { max300LengthRule } from 'consts/rules/textRules';

export const FirstNameInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Imię"
        name={formFields.firstName}
        rules={[{ required: true, message: formElementRequiredText }, max300LengthRule]}
        {...props}
    >
        <AppInput autoComplete="given-name" />
    </AppFormItem>
);
