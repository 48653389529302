import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppParagraph } from 'components/atoms';

export type DietPriceDisplayProps = {
    value: number | null;
};

export const PriceDisplay = ({ value }: DietPriceDisplayProps) => {
    const priceText = value
        ? `${value.toLocaleString('pl-PL', { minimumFractionDigits: 2 })} zł`
        : EMPTY_VALUE_LABEL;

    return <AppParagraph margin={0}>{priceText}</AppParagraph>;
};
