import { useState } from 'react';
import { AppFormSummary } from 'components/organisms';
import { FormFinishPageProps } from 'types';
import { useAuth } from 'services/firebase/useAuth';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { getFirebaseErrorLabel } from 'utils/handleFirebaseError/getFirebaseErrorLabel';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { redirectToMailAgent } from 'utils/redirectToMailAgent';
import { CONTACT_EMAIL } from 'consts/contactEmail';
import { authTexts } from 'consts/text/index';

export const PasswordResetFinish = ({ stepsData }: FormFinishPageProps) => {
    const [isPasswordResetRequestLoading, setIsPasswordResetRequestLoading] = useState(false);
    const { passwordResetRequest } = useAuth();
    const { displaySuccess, displayError } = useSimpleNotification();
    const navigate = useNavigate();
    const navigateToPasswordReset = () => navigate(appPaths.auth['password-reset-request']);

    const onClick = () => {
        setIsPasswordResetRequestLoading(true);
        const email = stepsData?.passwordReset?.email;
        if (email) {
            passwordResetRequest({ email })
                .then(() => displaySuccess('Wiadomość została wysłana'))
                .catch((error) => {
                    displayError(getFirebaseErrorLabel(error));
                    navigateToPasswordReset();
                })
                .finally(() => setIsPasswordResetRequestLoading(false));
        } else {
            navigateToPasswordReset();
        }
    };

    return (
        <AppFormSummary
            title={authTexts.successfullySentEmailLinkMessage}
            subTitle="Jeżeli e-mail do ciebie nie dotarł, sprawdź zakładkę spam."
            buttonText="Wyslij jeszcze raz"
            onClick={onClick}
            overLinkText="Potrzebujesz pomocy?"
            linkButton="Skontaktuj się z nami"
            onLinkButtonClick={() => redirectToMailAgent(CONTACT_EMAIL)}
            isLoading={isPasswordResetRequestLoading}
        />
    );
};
