import { SelectOptionType } from '../../../../types';
import { CalendarEventType } from '../../../../types/calendar/calendar';
import { AppFormItem, AppFormItemProps, AppSelect } from '../../../atoms';
import { formFields } from '../../../../consts/form/formFields';
import { formElementRequiredText } from '../../../../consts/errors/forms';
import { calendarTexts } from '../../../../consts/text';

const options: SelectOptionType<CalendarEventType>[] = [
    {
        label: calendarTexts.typeLabels.holidays,
        value: CalendarEventType.HOLIDAY_AND_SPECIAL_EVENT,
    },
    {
        label: calendarTexts.typeLabels.schoolHolidays,
        value: CalendarEventType.SCHOOL_HOLIDAY,
    },
    {
        label: calendarTexts.typeLabels.others,
        value: CalendarEventType.OTHER,
    },
];

export const EventTypePickerElement = (props: AppFormItemProps) => (
    <AppFormItem
        label={calendarTexts.form.type}
        name={formFields.type}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...props}
    >
        <AppSelect options={options} />
    </AppFormItem>
);
