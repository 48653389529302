import { useState } from 'react';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps } from 'components/organisms/AppTable';
import { RemoveModal } from 'components/organisms';
import { DiscountDTO, DiscountId, DiscountTarget, DiscountType } from 'types/discounts/discounts';
import { discountsFields } from 'consts/discounts/discountsFields';
import { Option } from 'components/atoms';
import { commonTexts, settlementTexts } from 'consts/text/index';
import { useParams } from 'react-router-dom';
import { ChildId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { discountTargetMapper, discountTypeMapper } from 'consts/discounts/discountsMappers';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useChildrenDetailsPath } from 'hooks/useChildrenDetailsPath/useChildrenDetailsPath';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

type DiscountsTableProps = {
    discounts: DiscountDTO[];
    isError?: boolean;
    canManageTable?: boolean;
};

const mapCombinableBasedOnValue: Record<string, string> = {
    true: commonTexts.actionLabels.confirm,
    false: commonTexts.actionLabels.reject,
};

const columns: AppTableProps<DiscountDTO>['columns'] = [
    {
        title: settlementTexts.discounts.table.discountTarget,
        dataIndex: discountsFields.discountTarget,
        key: discountsFields.discountTarget,
        isVisibleAsExtendableInMobile: false,
        render: (value: DiscountTarget) => discountTargetMapper[value],
    },
    {
        title: settlementTexts.discounts.table.discountType,
        dataIndex: discountsFields.discountType,
        key: discountsFields.discountType,
        isVisibleAsExtendableInMobile: true,
        render: (value: DiscountType) => discountTypeMapper[value],
    },
    {
        title: settlementTexts.discounts.table.name,
        dataIndex: discountsFields.name,
        key: discountsFields.name,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: settlementTexts.discounts.table.amount,
        dataIndex: discountsFields.amount,
        key: discountsFields.amount,
        isVisibleAsExtendableInMobile: true,
        render: (value, record) =>
            record.discountType === DiscountType.PERCENTAGE_DISCOUNT ? `${value}%` : `${value} zł`,
    },
    {
        title: settlementTexts.discounts.table.combine,
        dataIndex: discountsFields.combinable,
        key: discountsFields.combinable,
        isVisibleAsExtendableInMobile: true,
        render: (value?: boolean) => mapCombinableBasedOnValue[String(value)] ?? '-',
    },
];

export const DiscountsTable = ({ discounts, isError, canManageTable }: DiscountsTableProps) => {
    const { childId } = useParams<{ childId: ChildId }>();
    const { displaySuccess, displayError } = useSimpleNotification();
    const { getChildrenDetailsPath } = useChildrenDetailsPath();
    const [selectedDiscountToRemove, setSelectedDiscountToRemove] = useState<DiscountDTO | null>(
        null,
    );

    const { statefulNavigate } = useNavigateWithState();

    const onRemoveSuccess = () => {
        displaySuccess(settlementTexts.discounts.general.removeSuccess);
        setSelectedDiscountToRemove(null);
    };

    const onRemoveError = () => {
        displayError(commonTexts.errorMessages.actionExecution);
        setSelectedDiscountToRemove(null);
    };

    const { mutate, isLoading: isRemoveLoading } = useAppMutation(
        StaffHTTPService.discounts.removeDiscount,
        {
            key: ['REMOVE_DISCOUNT'],
            onSuccess: onRemoveSuccess,
            onError: onRemoveError,
            invalidateQueryKeys: [['CHILD_DISCOUNTS', childId]],
        },
    );

    const handleRemoveDiscountConfirm = () => {
        if (!childId || !selectedDiscountToRemove?.id) {
            return;
        }
        mutate({ childId, discountId: selectedDiscountToRemove?.id });
    };

    const handleRemoveDiscountOptionClick = (discount: DiscountDTO) => {
        setSelectedDiscountToRemove(discount);
    };

    const handleEditDiscountClick = (discountId: DiscountId) => {
        if (!childId) {
            return;
        }
        const path = getChildrenDetailsPath(`/edit-discount/${discountId}`, childId);
        statefulNavigate(path);
    };

    const options: Option<DiscountDTO>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: (discount) => {
                handleEditDiscountClick(discount.id);
            },
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveDiscountOptionClick,
            Icon: <IconTrash />,
        },
    ];

    return (
        <>
            <RemoveModal
                open={!!selectedDiscountToRemove}
                title="Czy na pewno chcesz usunąć zniżkę?"
                onOk={handleRemoveDiscountConfirm}
                onCancel={() => setSelectedDiscountToRemove(null)}
                confirmActionLoading={isRemoveLoading}
            />
            <AppTable
                options={canManageTable ? options : undefined}
                columns={columns}
                rowKey={({ id }) => id}
                getRecordId={({ id }) => id}
                dataSource={discounts}
                isError={isError}
                isTableManageUtilsHidden
                isTableHeaderHidden
            />
        </>
    );
};
