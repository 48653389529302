import React, { useEffect, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/es/form/Form';
import { journalTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { GroupTopicFormFields } from 'types';
import { AppFormItem, AppSelect } from 'components/atoms';
import { ModalTreePickerElement } from 'components/molecules';
import {
    EducationalGuideTextbookId,
    EducationalGuideTopicId,
} from 'types/educationalGuide/educationalGuide';
import {
    mapTextbooksListToSelectOptionsList,
    mapUnitTopicsListToFlatList,
    parseTextbookToTreeSelect,
} from './utils';

export const TopicEducationalGuideFormSection = () => {
    const { setFieldValue, setFields } = useFormInstance<GroupTopicFormFields>();
    const educationalGuideSelectValue = useWatch<EducationalGuideTextbookId | undefined>(
        formFields.educationalGuide,
    );
    const educationalGuideTopicSelectValue = useWatch<EducationalGuideTopicId | undefined>(
        formFields.educationalGuideTopic,
    );

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const { data: educationalGuideTextbooks, isFetching: isEducationalGuideTextbooksDataFetching } =
        useAppQuery(
            'EDUCATIONAL_GUIDE_TEXTBOOKS',
            [],
            () => StaffHTTPService.educationalGuide.getEducationalGuideTextbooks(),
            { staleTime: Infinity },
        );

    const mappedEducationalGuideTextbooks = useMemo(
        () =>
            educationalGuideTextbooks
                ? mapTextbooksListToSelectOptionsList(educationalGuideTextbooks)
                : [],
        [educationalGuideTextbooks],
    );

    const {
        data: educationalGuideTextbookUnits,
        isFetching: isEducationalGuidesUnitsDataFetching,
    } = useAppQuery(
        'EDUCATIONAL_GUIDE_TEXTBOOK_UNITS',
        [educationalGuideSelectValue],
        () =>
            StaffHTTPService.educationalGuide.getEducationalGuideTextbookUnits(
                educationalGuideSelectValue as EducationalGuideTextbookId,
            ),
        { enabled: !!educationalGuideSelectValue, staleTime: Infinity },
    );

    const flattenEducationalGuideTopics = useMemo(
        () =>
            educationalGuideTextbookUnits
                ? mapUnitTopicsListToFlatList(educationalGuideTextbookUnits)
                : [],
        [educationalGuideTextbookUnits],
    );

    const { data: educationalGuideTopicData, isFetching: isEducationalGuideTopicDataFetching } =
        useAppQuery(
            'EDUCATIONAL_GUIDE_TOPIC',
            [educationalGuideTopicSelectValue],
            () =>
                StaffHTTPService.educationalGuide.getEducationalGuideTopic(
                    educationalGuideTopicSelectValue as EducationalGuideTopicId,
                ),
            { enabled: !!educationalGuideTopicSelectValue, staleTime: Infinity },
        );

    const handleOpenModal = () => setIsModalOpen(true);

    const handleCloseModal = () => setIsModalOpen(false);

    const onSaveHandler = (value: React.Key[]) => {
        setFieldValue(formFields.educationalGuideTopic, value[0]);
    };

    const parsedEducationalGuideUnitData = useMemo(
        () =>
            educationalGuideTextbookUnits
                ? parseTextbookToTreeSelect(educationalGuideTextbookUnits)
                : [],
        [educationalGuideTextbookUnits],
    );

    const handleClearEducationalGuideTopic = () => {
        setFieldValue(formFields.educationalGuideTopic, undefined);
    };

    useEffect(() => {
        if (educationalGuideTopicData) {
            handleCloseModal();
            setFields([
                { name: formFields.name, value: educationalGuideTopicData?.name },
                { name: formFields.description, value: educationalGuideTopicData?.description },
                {
                    name: formFields.coreCurriculums,
                    value: educationalGuideTopicData.coreCurriculums,
                },
            ]);
        }
    }, [educationalGuideTopicData, setFields]);

    return (
        <>
            <AppFormItem
                label={journalTexts.journalDetails.groupJournal.topics.educationalGuideTextbook}
                name={formFields.educationalGuide}
                optional
                hasOptionalText
            >
                <AppSelect
                    onChange={(value) => {
                        setFields([
                            { name: formFields.educationalGuide, value },
                            { name: formFields.educationalGuideTopic, value: undefined },
                        ]);
                    }}
                    options={mappedEducationalGuideTextbooks}
                    loading={isEducationalGuideTextbooksDataFetching}
                />
            </AppFormItem>
            <ModalTreePickerElement
                title={journalTexts.journalDetails.groupJournal.topics.educationalGuideTopic}
                open={isModalOpen}
                onCancel={handleCloseModal}
                onSave={onSaveHandler}
                treeData={parsedEducationalGuideUnitData}
                initialValue={
                    educationalGuideTopicSelectValue ? [educationalGuideTopicSelectValue] : []
                }
                isLoading={isEducationalGuideTopicDataFetching}
                width={800}
                showCheckAll={false}
                showSearchBar
                multiple={false}
            />
            <AppFormItem
                label={journalTexts.journalDetails.groupJournal.topics.educationalGuideTopic}
                name={formFields.educationalGuideTopic}
                disabled={!educationalGuideSelectValue}
                optional
                hasOptionalText
            >
                <AppSelect
                    value={educationalGuideTopicSelectValue}
                    suffixIcon={<IconPlus size={20} />}
                    open={false}
                    showSearch={false}
                    onClick={handleOpenModal}
                    onClear={handleClearEducationalGuideTopic}
                    loading={isEducationalGuidesUnitsDataFetching}
                    disabled={!educationalGuideSelectValue}
                    options={flattenEducationalGuideTopics}
                />
            </AppFormItem>
        </>
    );
};
