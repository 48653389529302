import { useAppQuery } from 'services/reactQuery/useAppQuery';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const useChildDetailsPageNetworkManage = () => {
    const { id: childId } = useCurrentChild();

    const {
        data: childDetailsData,
        isFetching: isChildDetailsFetching,
        isError: isChildDetailsError,
    } = useAppQuery('CHILD', [childId], () => ParentHTTPService.children.getChild(childId), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const {
        data: settlementChildDetails,
        isFetching: isSettlementChildDetailsFetching,
        isError: isSettlementChildDetailsError,
    } = useAppQuery(
        'SETTLEMENT_CHILD_DETAILS',
        [],
        ParentHTTPService.settlements.getSettlementForChild,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const {
        data: dietDetails,
        isFetching: isDietDetailsFetching,
        isError: isDietDetailsError,
    } = useAppQuery('DIETS_CHILD_DETAILS', [], ParentHTTPService.diets.getDietForChild, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    return {
        isChildDetailsFetching,
        isChildDetailsError,
        childDetailsData,
        isSettlementChildDetailsFetching,
        isSettlementChildDetailsError,
        settlementChildDetails,
        isDietDetailsFetching,
        isDietDetailsError,
        dietDetails,
    };
};
