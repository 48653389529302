import { EmployeeFormFields, UpdateEmployeeUserDTO } from 'types';
import { parseFormattedPhoneNumberIntoPhoneNumber } from '../phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';

export const parseEmployeeAddFormDataIntoEmployeeUpdateDTO: (
    employee: EmployeeFormFields,
) => UpdateEmployeeUserDTO = (employee) => ({
    firstName: employee.firstName,
    lastName: employee.lastName,
    email: employee.email,
    phoneNumber: employee.phoneNumber
        ? parseFormattedPhoneNumberIntoPhoneNumber(employee.phoneNumber)
        : undefined,
    groupIds: employee.groups,
    roleId: employee.employeeRole,
});
