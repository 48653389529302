import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { AuthLayout } from 'components/layouts';
import {
    AuthActionManagementPage,
    PasswordChange,
    PasswordReset,
    ActivateAccountLinkExpiredPage,
    PrivacyPolicy,
    SignIn,
    SignUp,
    TermsOfUse,
} from 'pages';

export const authRoutes: RouteObject = {
    path: appPaths.auth.base,
    element: <AuthLayout />,
    children: [
        { index: true, element: <Navigate to={appPaths.auth['sign-in']} /> },
        { path: appPaths.auth['sign-in'], element: <SignIn /> },
        { path: appPaths.auth['password-reset-request'], element: <PasswordReset /> },
        { path: appPaths.auth['sign-up'], element: <SignUp /> },
        {
            path: appPaths.auth['activate-account-link-expired'],
            element: <ActivateAccountLinkExpiredPage />,
        },
        { path: appPaths.auth['password-change'], element: <PasswordChange /> },
        { path: appPaths.auth.legals['privacy-policy'], element: <PrivacyPolicy /> },
        { path: appPaths.auth.legals['terms-of-use'], element: <TermsOfUse /> },
        { path: appPaths.auth['auth-action-management'], element: <AuthActionManagementPage /> },
    ],
};
