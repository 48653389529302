import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { PermissionName } from 'types';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import {
    GROUP_JOURNAL_DETAILS_PATHS_BY_PERMISSION,
    SPECIAL_JOURNAL_DETAILS_PATHS_BY_PERMISSION,
} from 'consts/paths/journalDetailsPathsByPermission';
import { NavigateToDefaultPageLayout } from 'components/layouts';
import { useJournalId } from 'hooks/useJournalId/useJournalId';

export const NavigateToDefaultJournalPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { role } = useUserDetails();
    const { journalId } = useJournalId();

    const defaultPagePath = useMemo(() => {
        const userPermissionsNames = role.permissions.map((permission) => permission.name);
        const journalDetailsPathByPermissionMapper =
            journalType === 'GROUP'
                ? GROUP_JOURNAL_DETAILS_PATHS_BY_PERMISSION
                : SPECIAL_JOURNAL_DETAILS_PATHS_BY_PERMISSION;
        const defaultActivePermission = (
            Object.keys(journalDetailsPathByPermissionMapper) as PermissionName[]
        ).find((permission) => userPermissionsNames.includes(permission));
        if (
            !defaultActivePermission ||
            !journalDetailsPathByPermissionMapper[defaultActivePermission]
        ) {
            return appPaths.error['not-found'];
        }
        return generatePath(
            journalDetailsPathByPermissionMapper[defaultActivePermission] as string,
            {
                journalId,
            },
        );
    }, [journalId, journalType, role.permissions]);

    return <NavigateToDefaultPageLayout to={defaultPagePath} />;
};
