import { NothingFoundIcon } from 'assets/icons';
import { StateInfoBase } from '../StateInfoBase/StateInfoBase';

export const NothingFoundDisplay = () => (
    <StateInfoBase
        title="Ups, Nic nie znaleźliśmy"
        subTitle="Spróbuj jeszcze raz"
        icon={<NothingFoundIcon />}
    />
);
