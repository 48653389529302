import { ChildDiscountsIcon } from 'assets';
import { childrenTexts } from 'consts/text';
import { ChildId } from 'types';
import { DiscountsTable } from 'components/templates/discounts/DiscountsTable/DiscountsTable';
import { IconPlus } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { AppButton, ComponentBlockingAppLoader, DetailsFrameBase } from 'components/atoms';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useChildrenDetailsPath } from 'hooks/useChildrenDetailsPath/useChildrenDetailsPath';
import { useChildAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const Discounts = () => {
    const { statefulNavigate } = useNavigateWithState();
    const { childId } = useParams<{ childId: ChildId }>();
    const { getChildrenDetailsPath } = useChildrenDetailsPath();
    const { childAbilities } = useChildAbilities();

    const canManageChildDetails = childAbilities.checkIfCanManageChildrenList();

    const {
        data: discountData,
        isFetching,
        isError,
    } = useAppQuery(
        'CHILD_DISCOUNTS',
        [childId],
        () => StaffHTTPService.discounts.getDiscounts(childId as ChildId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    const onAddDiscount = () => {
        if (!childId) {
            return;
        }
        const path = getChildrenDetailsPath('/add-discount', childId);
        statefulNavigate(path);
    };

    return (
        <DetailsFrameBase
            icon={<ChildDiscountsIcon />}
            title={childrenTexts.childDetails.discounts.discounts}
            noFrame
            smallHeaderMargin
        >
            {!!discountData?.length && !isError && (
                <DiscountsTable
                    discounts={discountData}
                    isError={isError}
                    canManageTable={canManageChildDetails}
                />
            )}
            {canManageChildDetails && (
                <AppButton icon={<IconPlus />} onClick={onAddDiscount} marginLeft={0}>
                    {childrenTexts.childDetails.discounts.addDiscount}
                </AppButton>
            )}
            {isFetching && <ComponentBlockingAppLoader />}
        </DetailsFrameBase>
    );
};
