import { useEffect } from 'react';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { AppFormItem, ConditionalRender } from 'components/atoms';
import {
    FormNavButtons,
    IsFreeHoursPickerElement,
    IsTimeIntervalPickerElement,
    MealPaymentTypePickerElement,
    MethodOfCalcTheFeePickerElement,
    CurrencyInputElement,
    SimpleTimePickerElement,
    SimpleInputNumberElement,
} from 'components/molecules';
import { AppForm, AppFormProps } from 'components/organisms';
import { formFields } from 'consts/form/formFields';
import { TIME_LABEL } from 'consts/labels/common';
import { commonTexts, settingsTexts } from 'consts/text';
import { useForm, useWatch } from 'rc-field-form';
import { MethodOfCalcTheFee, SettlementStaysFormFields } from 'types';
import { SHORT_TIME_FORMAT } from 'consts/timeFormats';
import { DAY_HOURS_NUMBER, THIRTY_MINUTES } from 'consts/timeConsts';
import { timeRangeInMinutesEqualLimitOrLessThanLimitBy30MinutesValidator } from 'utils/validators';

export type SettlementStaysEditFormProps = Omit<
    AppFormProps<SettlementStaysFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    onFinish: (settlementsStayData: SettlementStaysFormFields) => void;
};

const ONE_HOUR = 1;

export const SettlementStaysEditForm = ({
    form,
    axiosError,
    initialValues,
    ...props
}: SettlementStaysEditFormProps) => {
    const [formInstance] = useForm(form);
    const methodOfCalcTheFeeValue = useWatch<MethodOfCalcTheFee>(
        formFields.paymentType,
        formInstance,
    );
    const isFreeHours = useWatch<boolean>(formFields.freeHours, formInstance);
    const isFreeHoursTimeFrame = useWatch<boolean>(formFields.freeHoursTimeFrame, formInstance);

    const isMonthlyMethodOfCalTheFee = methodOfCalcTheFeeValue === MethodOfCalcTheFee.MONTHLY;
    const isHourlyMethodOfCalTheFee = methodOfCalcTheFeeValue === MethodOfCalcTheFee.HOURLY;
    const settlementStaysEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    useEffect(() => {
        if (methodOfCalcTheFeeValue === MethodOfCalcTheFee.MONTHLY) {
            formInstance.setFieldsValue({
                freeHours: undefined,
                freeHoursAmount: undefined,
                freeHoursStart: undefined,
                freeHoursEnd: undefined,
                freeHoursTimeFrame: undefined,
                workingHours: undefined,
            });
        }
    }, [formInstance, methodOfCalcTheFeeValue]);

    useEffect(() => {
        if (!isFreeHoursTimeFrame) {
            formInstance.setFieldsValue({
                freeHoursStart: undefined,
                freeHoursEnd: undefined,
            });
        }
    }, [formInstance, isFreeHoursTimeFrame]);

    useEffect(() => {
        if (!isFreeHours) {
            formInstance.setFieldsValue({
                freeHoursAmount: undefined,
                freeHoursStart: undefined,
                freeHoursEnd: undefined,
                freeHoursTimeFrame: undefined,
            });
        }
    }, [formInstance, isFreeHours]);

    return (
        <AppForm
            withHeader={false}
            form={form}
            name="settlementStaysEdit"
            error={settlementStaysEditError}
            {...props}
        >
            <MealPaymentTypePickerElement name={formFields.settlementType} />
            <MethodOfCalcTheFeePickerElement />
            <ConditionalRender condition={isMonthlyMethodOfCalTheFee}>
                <CurrencyInputElement
                    label={settingsTexts.settlements.stays.formLabels.tuitionLabel}
                    name={formFields.price}
                />
            </ConditionalRender>
            <ConditionalRender condition={isHourlyMethodOfCalTheFee}>
                <IsFreeHoursPickerElement />
            </ConditionalRender>
            <ConditionalRender condition={isFreeHours}>
                <SimpleInputNumberElement
                    name={formFields.freeHoursAmount}
                    label={settingsTexts.settlements.stays.formLabels.freeHoursAmountLabel}
                    additionalText={
                        settingsTexts.settlements.stays.formLabels.hoursNumberPerDayAdditionalText
                    }
                    hasOptionalText
                    precision={0}
                    rules={[
                        {
                            type: 'number',
                            min: ONE_HOUR,
                            max: DAY_HOURS_NUMBER - ONE_HOUR,
                            message:
                                settingsTexts.settlements.stays.formValidationMessages
                                    .invalidFreeHoursNumber,
                        },
                    ]}
                />
                <IsTimeIntervalPickerElement />
            </ConditionalRender>
            <ConditionalRender condition={isFreeHoursTimeFrame}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <SimpleTimePickerElement
                        mainLabelText={settingsTexts.settlements.stays.formLabels.timeFromLabel}
                        additionalLabelText={TIME_LABEL}
                        name={formFields.freeHoursStart}
                        format={SHORT_TIME_FORMAT}
                        minuteStep={THIRTY_MINUTES}
                    />
                    <SimpleTimePickerElement
                        mainLabelText={settingsTexts.settlements.stays.formLabels.timeToLabel}
                        additionalLabelText={TIME_LABEL}
                        name={formFields.freeHoursEnd}
                        format={SHORT_TIME_FORMAT}
                        minuteStep={THIRTY_MINUTES}
                        dependencies={[formFields.freeHoursAmount, formFields.freeHoursStart]}
                        rules={[
                            ({ getFieldValue }) =>
                                timeRangeInMinutesEqualLimitOrLessThanLimitBy30MinutesValidator(
                                    getFieldValue,
                                    {
                                        timeStartValueFieldName: formFields.freeHoursStart,
                                        timeLimitValueInHoursFieldName: formFields.freeHoursAmount,
                                        isTimeRangeNotWithinLimitErrorText:
                                            settingsTexts.settlements.stays.formValidationMessages
                                                .freeHoursTimeFrameDurationNotEqualFreeHoursNumber,
                                    },
                                ),
                        ]}
                    />
                </AppFormItem>
            </ConditionalRender>
            <ConditionalRender condition={isHourlyMethodOfCalTheFee}>
                <CurrencyInputElement
                    label={settingsTexts.settlements.stays.formLabels.chargeAmountLabel}
                    name={formFields.price}
                />
            </ConditionalRender>
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
