import { AppRadioButton } from 'components/atoms';
import styled, { css } from 'styled-components';

export const StyledAppRadioButton = styled(AppRadioButton)`
    ${({ theme }) => css`
        @media (${theme.sizes.mediaQueries.desktopMin}) {
            &.ant-radio-button-wrapper {
                flex: unset !important;
            }
        }
    `}
`;
