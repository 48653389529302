import styled, { css } from 'styled-components';

export const Container = styled.span`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        padding: ${theme.sizes.marginNormal}px;
        margin-bottom: ${theme.sizes.marginNormal}px;
        flex-direction: column;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            flex-direction: row;
            justify-content: space-between;
        }
    `}
`;

export const LeftSide = styled.span`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            flex-direction: row;
        }
    `}
`;
