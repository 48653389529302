import {
    AppDatePicker,
    AppDatePickerProps,
    AppFormItemProps,
    DurationDateTimeFormItemBase,
    DurationDateTimeFormItemBaseProps,
} from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { AppFormRangeItemMode } from 'types';
import { commonTexts } from 'consts/text';

export type SimpleDurationDatePickerProps = AppFormItemProps &
    Pick<AppDatePickerProps, 'disabledDate'> & {
        mode: AppFormRangeItemMode;
        startFormItemData?: DurationDateTimeFormItemBaseProps['startFormItemData'];
        finishFormItemData?: DurationDateTimeFormItemBaseProps['finishFormItemData'];
        isFinishValueBeforeStartValueErrorText?: string;
        isStartValueAfterFinishValueErrorText?: string;
        disabled?: boolean;
    };

export const SimpleDurationDatePicker = ({
    mode,
    startFormItemData = {
        name: formFields.startDate,
        label: commonTexts.dataLabels.dateFrom,
    },
    finishFormItemData = {
        name: formFields.finishDate,
        label: commonTexts.dataLabels.dateTo,
    },
    isFinishValueBeforeStartValueErrorText = commonTexts.errorMessages
        .dateToBeforeDateFromErrorText,
    isStartValueAfterFinishValueErrorText = commonTexts.errorMessages.dateFromAfterDateToErrorText,
    disabled,
    disabledDate,
    ...props
}: SimpleDurationDatePickerProps) => (
    <DurationDateTimeFormItemBase
        pickerType="date"
        rangeItemMode={mode}
        picker={
            <AppDatePicker disabled={disabled} autoComplete="off" disabledDate={disabledDate} />
        }
        startFormItemData={startFormItemData}
        finishFormItemData={finishFormItemData}
        isFinishValueBeforeStartValueErrorText={isFinishValueBeforeStartValueErrorText}
        isStartValueAfterFinishValueErrorText={isStartValueAfterFinishValueErrorText}
        {...props}
    />
);
