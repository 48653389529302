import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { rspoValidator } from 'utils/validators';

export const RspoNumberInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Numer RSPO"
        name={formFields.rspoNumber}
        rules={[
            { required: true, message: formElementRequiredText },
            {
                validator: (_, value) =>
                    rspoValidator(value)
                        ? Promise.resolve()
                        : Promise.reject(new Error('Niepoprawny numer RSPO')),
            },
        ]}
        {...props}
    >
        <AppInput autoComplete="off" />
    </AppFormItem>
);
