import { WeeklyScheduleDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseWeeklyScheduleDaysDTOIntoJsonObject } from 'utils/parsers/jsonObject/weeklySchedule/parseWeeklyScheduleDaysDTOIntoJsonObject';

export const parseWeeklyScheduleDTOIntoJsonObject: (
    weeklyScheduleDTO: WeeklyScheduleDTO,
) => object = (weeklyScheduleDTO) => {
    const { days } = weeklyScheduleDTO;

    const weeklyScheduleDaysJsonObjects = days.map(parseWeeklyScheduleDaysDTOIntoJsonObject);

    return {
        [jsonObjectsTexts.weeklyScheduleDTO.fields.days]: weeklyScheduleDaysJsonObjects,
    };
};
