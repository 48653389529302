import { commonTexts } from 'consts/text';
import { LabelMapper, WeekDays } from 'types';

export const weekDaysLabels: LabelMapper<WeekDays> = {
    MONDAY: commonTexts.dayLabels.monday,
    TUESDAY: commonTexts.dayLabels.tuesday,
    WEDNESDAY: commonTexts.dayLabels.wednesday,
    THURSDAY: commonTexts.dayLabels.thursday,
    FRIDAY: commonTexts.dayLabels.friday,
    SATURDAY: commonTexts.dayLabels.saturday,
    SUNDAY: commonTexts.dayLabels.sunday,
    EVERYDAY: commonTexts.dayLabels.everyday,
};
