import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconCalendarEvent } from '@tabler/icons-react';
import { AppTable, AppTableProps } from 'components/organisms';
import { JournalChildDTO } from 'types';
import {
    AddressDisplay,
    AppModal,
    ChildFirstAndLastNameDisplay,
    ChildGroupDisplay,
    ChildSexDisplay,
    ParentsNamesDisplay,
    ParentsPhoneNumbersDisplay,
} from 'components/molecules';
import { colors } from 'theme/styledComponents/colors';
import { appPaths } from 'consts/paths/paths';
import { journalChildDTOFields } from 'consts/child/journalChildDTOFields';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { Option } from 'components/atoms';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { journalTexts, commonTexts, childrenTexts } from 'consts/text';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { JournalChildDatesFormFields } from 'types/forms/journalChildDates';
import { parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO } from 'utils/parsers/journal/parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO';
import { JournalChildDatesForm } from 'components/templates/journal';
import { Form } from 'antd';
import { parseJournalChildDTOIntoJournalChildDatesFormFields } from 'utils/parsers/journal/parseJournalChildDTOIntoJournalChildDatesFormFields';
import { CalendarIcon } from 'assets';
import { useChildAbilities, useJournalAbilities } from 'abilities';
import dayjs from 'dayjs';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalChildrenPageNetworkManage } from './hooks/useJournalChildrenPageNetworkManage';

const columns: AppTableProps<JournalChildDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.firstAndLastName,
        dataIndex: journalChildDTOFields.firstName,
        key: journalChildDTOFields.firstName,
        render: (_, child) => <ChildFirstAndLastNameDisplay {...child} />,
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: childrenTexts.childrenListColumns.sex,
        dataIndex: journalChildDTOFields.sex,
        key: journalChildDTOFields.sex,
        render: (sex: JournalChildDTO['sex']) => <ChildSexDisplay sex={sex} />,
        align: 'center',
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.group,
        dataIndex: journalChildDTOFields.groups,
        key: journalChildDTOFields.groups,
        render: (groups: JournalChildDTO['groups']) => (
            <ChildGroupDisplay
                childGroups={groups}
                textType="BodyMediumSemiBold"
                color={colors.grayscale.gray9}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.parentName,
        dataIndex: journalChildDTOFields.parents,
        key: `${journalChildDTOFields.parents}/name`,
        render: (parents: JournalChildDTO['parents']) => <ParentsNamesDisplay parents={parents} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.phoneNumber,
        dataIndex: journalChildDTOFields.parents,
        key: `${journalChildDTOFields.parents}/phoneNumber`,
        render: (parents: JournalChildDTO['parents']) => (
            <ParentsPhoneNumbersDisplay parents={parents} />
        ),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.address,
        dataIndex: journalChildDTOFields.address,
        key: journalChildDTOFields.address,
        render: (address: JournalChildDTO['address']) => <AddressDisplay address={address} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.joinDate,
        dataIndex: journalChildDTOFields.joinDate,
        key: journalChildDTOFields.joinDate,
        render: (joinDate: JournalChildDTO['joinDate']) =>
            parseStringIntoFormattedStringDate(joinDate),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.leaveDate,
        dataIndex: journalChildDTOFields.leaveDate,
        key: journalChildDTOFields.leaveDate,
        render: (leaveDate: JournalChildDTO['leaveDate']) =>
            leaveDate ? parseStringIntoFormattedStringDate(leaveDate) : '',
        isVisibleAsExtendableInMobile: true,
    },
];

export const JournalChildrenPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const [form] = Form.useForm();
    const [selectedToPatchChild, setSelectedToPatchChild] = useState<JournalChildDTO | null>(null);
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const { genericAbilities } = useJournalAbilities();
    const { childAbilities } = useChildAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const canManageChildren = childAbilities.checkIfCanManageChildrenList();
    const canChangeChildDates = genericAbilities.checkIfCanChangeChildDates();
    const canViewChildDetails = childAbilities.checkIfCanViewChildrenList();

    const isGroupJournal = journalType === 'GROUP';

    const handlePatchChildDatesFinishOrCancel = () => setSelectedToPatchChild(null);

    const {
        childrenData,
        isChildrenDataInitialLoading,
        isChildrenDataRefetching,
        isPatchChildDatesLoading,
        isChildrenDataError,
        patchChildDates,
    } = useJournalChildrenPageNetworkManage({
        journalId,
        onChildPatchDatesRequestFinish: handlePatchChildDatesFinishOrCancel,
    });

    const handlePatchChildDatesConfirm = (formValues: JournalChildDatesFormFields) => {
        if (!selectedToPatchChild) {
            return;
        }
        patchChildDates({
            journalId,
            childId: selectedToPatchChild.id,
            values: parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO(formValues),
        });
    };

    const handlePatchChildDatesClick = ({ id: childId }: JournalChildDTO) => {
        const child = childrenData.find((childData) => childData.id === childId);
        if (child) {
            setSelectedToPatchChild(child);
            form.setFieldsValue(parseJournalChildDTOIntoJournalChildDatesFormFields(child));
        }
    };

    const navigateToChildEditPage = ({ id: childId }: JournalChildDTO) =>
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.children.edit
                          .pickedChild
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.children.edit
                          .pickedChild,
                {
                    journalId,
                    childId,
                },
            ),
        );

    const navigateToChildDetailsPage = ({ id: childId }: JournalChildDTO) =>
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.children.details
                          .pickedChild.base
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.children.details
                          .pickedChild.base,
                {
                    journalId,
                    childId,
                },
            ),
        );

    const options: Option<JournalChildDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToChildDetailsPage,
            Icon: <IconEye />,
            checkIfVisible: () => canViewChildDetails,
        },
        {
            label: journalTexts.journalDetails.common.children.editChild,
            onClick: navigateToChildEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageChildren,
        },
        {
            label: journalTexts.journalDetails.common.children.changeDates,
            onClick: handlePatchChildDatesClick,
            checkIfVisible: () => canChangeChildDates,
            Icon: <IconCalendarEvent />,
        },
    ];

    return (
        <>
            <AppModal
                width={400}
                open={!!selectedToPatchChild}
                title={journalTexts.journalDetails.common.children.changeDates}
                onCancel={handlePatchChildDatesFinishOrCancel}
                icon={<CalendarIcon />}
                isLoading={isPatchChildDatesLoading}
            >
                <JournalChildDatesForm
                    child={selectedToPatchChild!}
                    form={form}
                    onFinish={handlePatchChildDatesConfirm}
                />
            </AppModal>
            <AppTable
                title={journalTexts.journalDetails.common.children.childList}
                emptyInfo={journalTexts.journalDetails.common.children.emptyChildList}
                options={options}
                columns={columns}
                dataSource={childrenData}
                rowKey={({ id }) => id}
                isError={isChildrenDataError}
                isOptionsLoading={isPatchChildDatesLoading}
                isDataInitialLoading={isChildrenDataInitialLoading}
                isDataRefetching={isChildrenDataRefetching}
                isTableManageUtilsHidden
                onRow={(journalChild) => ({
                    style: {
                        opacity:
                            journalChild[journalChildDTOFields.leaveDate] &&
                            dayjs(journalChild[journalChildDTOFields.leaveDate]).isBefore(dayjs())
                                ? 0.6
                                : 1,
                    },
                })}
            />
        </>
    );
};
