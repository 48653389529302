import { Form } from 'antd';
import { ChildrenPaymentModalAddEditForm } from 'components/templates/settlement/ChildrenPaymentModalAddEditForm/ChildrenPaymentModalAddEditForm';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';

export const ChildrenEditPaymentPage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onCancel = () => {
        navigate(-1);
    };

    return (
        <ChildrenPaymentModalAddEditForm
            mode="edit"
            onCancel={onCancel}
            onFinish={noop}
            form={form}
        />
    );
};
