import { PrincipalFormFields, UpdatePrincipalDTO } from 'types';

export const parsePrincipalFormFieldsIntoUpdatePrincipalDTO: (
    principalFormFields: PrincipalFormFields,
) => UpdatePrincipalDTO = (principalFormFields) => {
    if (principalFormFields.selectFromTeacherList) {
        return {
            employeeId: principalFormFields.teacherId,
        };
    }
    return {
        name: principalFormFields.name,
    };
};
