import { UploadProps } from 'antd/es/upload';
import { Upload } from 'antd';
import styled from 'styled-components';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadButton, FileList } from './components';

export type AppUploadRequestOptions = UploadRequestOption;
export type AppUploadFile = UploadFile;
export type AppUploadProps = UploadProps;

const StyledUpload = styled(Upload)<AppUploadProps>`
    .ant-upload {
        width: 100%;
    }

    .ant-upload-list {
        display: none;
    }
`;

export const AppUpload = ({ fileList, onRemove, ...restProps }: AppUploadProps) => (
    <>
        <StyledUpload fileList={fileList} {...restProps} />
        {!!fileList?.length && <FileList files={fileList} onRemove={onRemove} />}
    </>
);

AppUpload.Button = UploadButton;
