import { useEffect, useState } from 'react';
import { RuleObject } from 'rc-field-form/es/interface';
import { validateRules } from 'rc-field-form/es/utils/validateUtil';
import { useWatch } from 'antd/es/form/Form';
import { passwordValidatingRules } from 'consts/passwordValidatingRules';
import { AppParagraph } from 'components/atoms';
import { IconCircleCheck, IconHelp } from '@tabler/icons-react';
import { useTheme } from 'styled-components';
import { RuleContainer, ValidatorContainer } from './PasswordRulesDisplay.styled';

type ErrorsValidationInstance = { errors: string[]; rule: { message: string } };
type ErrorsInfo = { message: string; valid: boolean };
export const PasswordRulesDisplay = () => {
    const { colors } = useTheme();
    const [errors, setErrors] = useState<ErrorsInfo[]>([]);
    const passwordValue = useWatch('password');

    useEffect(() => {
        validateRules(
            ['passwordValidationValue'],
            passwordValue || '',
            passwordValidatingRules as RuleObject[],
            {},
            false,
        ).catch((reason) => {
            setErrors(
                (reason as ErrorsValidationInstance[]).map<ErrorsInfo>(
                    ({ errors: validationErrors, rule }) => ({
                        message: rule.message,
                        valid: !validationErrors.length,
                    }),
                ),
            );
        });
    }, [passwordValue]);

    const mappedErrors = errors.map(({ message, valid }) => {
        const color = valid ? colors.green.green6 : colors.grayscale.gray8;
        const Icon = valid ? IconCircleCheck : IconHelp;

        return (
            <RuleContainer key={message}>
                <Icon size={14} color={color} />
                <AppParagraph margin={2} marginLeft={4} color={color} textType="BodySmall">
                    {message}
                </AppParagraph>
            </RuleContainer>
        );
    });

    return <ValidatorContainer>{mappedErrors}</ValidatorContainer>;
};
