import { SettlementMealsFormFields } from 'types';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { parseSettlementMealsDTOIntoSettlementMealsFormFields } from 'utils/parsers/settlementMeals/parseSettlementMealsDTOIntoSettlementMealsFormFields';
import { parseSettlementMealsFormFieldsIntoUpdateSettlementMealsDTO } from 'utils/parsers/settlementMeals/parseSettlementMealsFormFieldsIntoUpdateSettlementMealsDTO';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { PageBaseLayout } from 'components/layouts';
import { settingsTexts } from 'consts/text';
import { AxiosError } from 'axios';
import { SettlementMealsEditForm } from 'components/templates';
import { useSettingsAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useEffect } from 'react';

export const SettlementMealsEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SettlementMealsFormFields>();
    const currentInstitution = useCurrentInstitution();
    const { displaySuccess } = useSimpleNotification();
    const { settingsAbilities } = useSettingsAbilities();
    const canManageSettlementsStays = settingsAbilities.checkIfCanManageSettlementSettings();

    const {
        data: settlementMealsData,
        isError: isSettlementMealsDataError,
        isFetching: isSettlementMealsDataFetching,
        error: settlementMealDataError,
    } = useAppQuery(
        'SETTLEMENT_MEALS',
        [currentInstitution.id],
        () => StaffHTTPService.settings.getSettlementMeals(),
        {
            retry: false,
            staleTime: FIVE_MINS_IN_MILLIS,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        if (settlementMealsData) {
            setFormData(parseSettlementMealsDTOIntoSettlementMealsFormFields(settlementMealsData));
        }
    }, [setFormData, settlementMealsData]);

    const {
        mutate: editSettlementMeals,
        error: editSettlementMealsError,
        isLoading: isEditSettlementMealsLoading,
    } = useAppMutation(StaffHTTPService.settings.updateSettlementMeals, {
        key: ['UPDATE_SETTLEMENT_MEALS'],
        onSuccess: () =>
            displaySuccess(settingsTexts.settlements.meals.successNotifications.settlementEdited),
        invalidateQueryKeys: [['SETTLEMENT_MEALS', currentInstitution.id]],
    });

    const onEditSettlementMealsFinish = (settlementMealsFormFields: SettlementMealsFormFields) => {
        const parsedSettlementMealsFormFields =
            parseSettlementMealsFormFieldsIntoUpdateSettlementMealsDTO(settlementMealsFormFields);

        if (!parsedSettlementMealsFormFields) {
            return;
        }

        editSettlementMeals({
            settlementMealsDTO: parsedSettlementMealsFormFields,
        });
    };

    const isSettlementMealsDataErrorOnFetching = () => {
        const code =
            settlementMealDataError instanceof AxiosError
                ? settlementMealDataError?.response?.data?.code
                : '';

        if (isSettlementMealsDataError) {
            if (code === 'SETTLEMENT_SETTINGS_NOT_FOUND') {
                return false;
            }
            return true;
        }
        return false;
    };

    return (
        <PageBaseLayout isError={isSettlementMealsDataErrorOnFetching()}>
            <SettlementMealsEditForm
                form={form}
                initialValues={formInitialData}
                axiosError={editSettlementMealsError}
                isLoading={isEditSettlementMealsLoading || isSettlementMealsDataFetching}
                onFinish={onEditSettlementMealsFinish}
                isViewMode={!canManageSettlementsStays}
            />
        </PageBaseLayout>
    );
};
