import styled, { css } from 'styled-components';
import { AddButton } from 'components/atoms';

export const FiltersContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            flex-direction: column-reverse;
            align-items: center;
        }
    `}
`;

export const StyledAddButton = styled(AddButton)`
    ${({ theme }) => css`
        height: 44px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            width: 100%;
        }
    `}
`;
