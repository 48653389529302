import { Agreements, AgreementValue } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';

export const parseAgreementsIntoJsonObject: (agreements: Agreements<AgreementValue>) => object = (
    agreements,
) => {
    const { facebook, website, tiktok, instagram } = agreements;

    return {
        [jsonObjectsTexts.child.innerObjects.agreements.fields.facebook]: facebook
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.child.innerObjects.agreements.fields.website]: website
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.child.innerObjects.agreements.fields.tiktok]: tiktok
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.child.innerObjects.agreements.fields.instagram]: instagram
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
