import { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconArchive } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import { AppTable, AppTableProps, ArchiveRestoreModal } from 'components/organisms';
import {
    CountDisplay,
    PersonListDisplay,
    GroupTeacherDisplay,
    TableTagDisplay,
} from 'components/molecules';
import { groupDTOFields } from 'consts/group/groupDTOFields';
import { GroupDTO, GroupId } from 'types';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { appPaths } from 'consts/paths/paths';
import { tagColorsByGroupStatus } from 'consts/group/tagColorsByGroupStatus';
import { groupStatusesLabels } from 'consts/group/groupStatusesLabels';
import { Option } from 'components/atoms';
import { groupTableAdditionalColumnsKeys } from 'consts/group/groupTableAdditionalColumnsKeys';
import { commonTexts, groupsTexts } from 'consts/text';
import { useGroupAbilities, useJournalAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useGroupsPageNetworkManage } from './hooks/useGroupsPageNetworkManage';

const columns: AppTableProps<GroupDTO>['columns'] = [
    {
        title: 'Stworzone grupy',
        dataIndex: groupDTOFields.name,
        key: groupDTOFields.name,
        width: '15%',
        mobileWidth: '60%',
    },
    {
        title: 'Liczba dzieci',
        dataIndex: groupTableAdditionalColumnsKeys.childCount,
        key: groupTableAdditionalColumnsKeys.childCount,
        align: 'center',
        render: (_, group) => <CountDisplay count={group[groupDTOFields.childrenArray].length} />,
        width: '25%',
        mobileWidth: '30%',
    },
    {
        title: 'Nauczyciele',
        dataIndex: groupDTOFields.teachers,
        key: groupDTOFields.teachers,
        render: (teachers: GroupDTO['teachers']) => (
            <GroupTeacherDisplay groupTeachers={teachers} />
        ),
        isInvisibleInMobile: true,
        width: '30%',
    },
    {
        title: commonTexts.dataLabels.status,
        dataIndex: groupDTOFields.status,
        key: groupDTOFields.status,
        align: 'center',
        render: (status: GroupDTO['status']) => (
            <TableTagDisplay
                value={status}
                labelMapper={groupStatusesLabels}
                colorMapper={tagColorsByGroupStatus}
            />
        ),
        isVisibleAsExtendableInMobile: true,
        width: '30%',
    },
    {
        title: 'Obecne dzieci',
        dataIndex: groupDTOFields.childrenArray,
        key: groupDTOFields.childrenArray,
        render: (childrenArray: GroupDTO['childrenArray']) => (
            <PersonListDisplay personsData={childrenArray} />
        ),
        isInvisibleInDesktop: true,
        isVisibleAsExtendableInMobile: true,
    },
];

export const GroupsPage = () => {
    const [searchValue, setSearchValue] = useState('');
    const [selectedToArchiveGroupId, setSelectedToArchiveGroupId] = useState<GroupId | null>(null);
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const { groupAbilities } = useGroupAbilities();
    const {
        genericAbilities: { checkIfCanArchiveJournals },
    } = useJournalAbilities();

    const canManageGroupList = groupAbilities.checkIfCanManageGroupList();
    // JOURNALS_ARCHIVING is a permission that is used for both journals and groups
    const canArchiveGroups = checkIfCanArchiveJournals();

    const onGroupArchiveRequestFinish = () => setSelectedToArchiveGroupId(null);

    const {
        groupsData,
        isGroupsDataInitialLoading,
        isGroupsDataRefetching,
        isGroupsDataError,
        isArchiveGroupLoading,
        archiveGroup,
    } = useGroupsPageNetworkManage({
        search: searchValue,
        onGroupArchiveRequestFinish,
    });

    const handleArchiveGroupClick = (group: GroupDTO) => setSelectedToArchiveGroupId(group.id);

    const handleArchiveGroupConfirm = () => {
        if (!selectedToArchiveGroupId) {
            return;
        }
        archiveGroup(selectedToArchiveGroupId);
    };

    const handleArchiveGroupCancel = () => setSelectedToArchiveGroupId(null);

    const navigateToGroupAdd = () => statefulNavigate(appPaths.app.groups.add);

    const expandedRowRender = useCallback(
        (group: GroupDTO) => (
            <PersonListDisplay
                personsData={group[groupDTOFields.childrenArray]}
                desktopLabel="Obecne dzieci"
            />
        ),
        [],
    );

    const expandable = useMemo(
        () =>
            !isMobile
                ? {
                      expandedRowRender,
                      showExpandColumn: true,
                  }
                : undefined,
        [isMobile, expandedRowRender],
    );

    const options: Option<GroupDTO>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: ({ id }) =>
                statefulNavigate(
                    generatePath(appPaths.app.groups.edit.pickedGroup, { groupId: id }),
                ),
            checkIfVisible: () => canManageGroupList,
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.archive,
            onClick: handleArchiveGroupClick,
            checkIfVisible: (group: GroupDTO) => canArchiveGroups && group.status !== 'ARCHIVED',
            Icon: <IconArchive />,
        },
    ];

    return (
        <>
            <ArchiveRestoreModal
                open={!!selectedToArchiveGroupId}
                title={groupsTexts.archiveGroupModalTitle}
                confirmActionLoading={isArchiveGroupLoading}
                onOk={handleArchiveGroupConfirm}
                onCancel={handleArchiveGroupCancel}
            />
            <AppTable
                title="Lista grup"
                addButtonLabel={groupsTexts.addNewGroup}
                emptyInfo="Nie masz utworzonych żadnych grup"
                options={options}
                columns={columns}
                rowKey={({ id }) => id}
                dataSource={groupsData}
                expandable={expandable}
                isDataInitialLoading={isGroupsDataInitialLoading}
                isDataRefetching={isGroupsDataRefetching}
                isOptionsLoading={isArchiveGroupLoading}
                isError={isGroupsDataError}
                isHiddenAddButton={!canManageGroupList}
                onAddButtonClick={navigateToGroupAdd}
                tableManageUtilsProps={{
                    searchInputProps: { onInput: debounce(setSearchValue, DEBOUNCE_DELAY) },
                }}
            />
        </>
    );
};
