import styled, { css } from 'styled-components';
import { AppButton } from 'components/atoms';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`;

export const StyledButton = styled(AppButton)`
    ${({ theme }) => css`
        width: 190px;
        margin: 12px 0 0 0;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            width: 100%;
        }
    `}
`;
