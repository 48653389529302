import { ParentAccountFormFields, UpdateParentProfileDTO } from 'types';
import { parseFormattedPhoneNumberIntoPhoneNumber } from 'utils/parsers/phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';
import { parseFormattedBankAccountNumberIntoBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseFormattedBankAccountNumberIntoBankAccountNumber';

export const parseParentAccountFormFieldsIntoUpdateParentProfileDTO: (
    parentAccountFormFields: ParentAccountFormFields,
) => UpdateParentProfileDTO = (parentAccountFormFields) => {
    const { firstName, lastName, bankAccountNumber, phoneNumber, street, zipCode, city } =
        parentAccountFormFields;

    const address =
        street || zipCode || city
            ? {
                  street,
                  zipCode,
                  city,
              }
            : undefined;

    return {
        firstName,
        lastName,
        phoneNumber: phoneNumber
            ? parseFormattedPhoneNumberIntoPhoneNumber(phoneNumber)
            : undefined,
        bankAccountNumber: bankAccountNumber
            ? parseFormattedBankAccountNumberIntoBankAccountNumber(bankAccountNumber)
            : undefined,
        address,
    };
};
