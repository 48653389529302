import { ChildId, ParentId } from 'types';
import { journalChildDTOFields } from 'consts/child/journalChildDTOFields';

type ChildData = {
    id: ChildId;
    parents: { id: ParentId }[];
};

export const countChildrenParentsAmount = (children: ChildData[]) =>
    children.reduce(
        (totalParentAmount, currentChild) =>
            totalParentAmount + currentChild[journalChildDTOFields.parents].length,
        0,
    );
