import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { RefreshIcon } from 'assets';
import { RoleTemplatePickerElement } from 'components/molecules';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ComponentBlockingAppLoader } from 'components/atoms';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { RoleTemplate } from 'types';
import { useSetAtom } from 'jotai';
import { useWatch } from 'rc-field-form';
import { formFields } from 'consts/form/formFields';
import { settingsTexts } from 'consts/text';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { StyledAppFormItem, StyledErrorDisplay } from './TemplatePicker.styled';
import { StyledAppButton } from '../../InfoContainerBase.styled';
import { modulesEditAtom, permissionsEditAtom } from '../../roleAndPermissionState';

export type TemplatePickerProps = {
    maxWidth?: number;
};

const getRolesTemplateOptions = (roles: RoleTemplate[]): { label: ReactNode; value: string }[] =>
    roles.map((role) => ({ label: role.name, value: role.name }));

export const TemplatePicker = ({ maxWidth }: TemplatePickerProps) => {
    const isMobile = useIsMobile();
    const form = useFormInstance();
    const setPermissions = useSetAtom(permissionsEditAtom);
    const setModules = useSetAtom(modulesEditAtom);

    const pickedRoleTemplateName = useWatch(formFields.roleTemplateName, form);

    const {
        data: rolesTemplates,
        isFetching: isRolesTemplatesFetching,
        isError,
    } = useAppQuery('ROLES_TEMPLATES', [], StaffHTTPService.roles.getRolesTemplates, {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });

    const rolesTemplatesOptions = useMemo(
        () => (rolesTemplates ? getRolesTemplateOptions(rolesTemplates) : []),
        [rolesTemplates],
    );

    const writePickedTemplateModulesAndPermissions = useCallback(() => {
        if (!rolesTemplates) {
            return;
        }
        const pickedRoleTemplate = rolesTemplates.find(
            (currentRoleTemplate) => currentRoleTemplate.name === pickedRoleTemplateName,
        );
        if (!pickedRoleTemplate) {
            return;
        }
        setPermissions(pickedRoleTemplate.permissions);
        setModules(pickedRoleTemplate.modules);
    }, [pickedRoleTemplateName, rolesTemplates, setModules, setPermissions]);

    useEffect(
        () => {
            writePickedTemplateModulesAndPermissions();
        },
        [pickedRoleTemplateName], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <StyledAppFormItem marginLeft={0} justifyContent="space-between" doubleItem>
            {isError && <StyledErrorDisplay hideIcon />}
            {isRolesTemplatesFetching && <ComponentBlockingAppLoader />}
            <RoleTemplatePickerElement
                options={rolesTemplatesOptions}
                maxWidth={maxWidth}
                marginLeft={0}
                isLoading={isRolesTemplatesFetching}
            />
            <StyledAppButton
                icon={<RefreshIcon />}
                marginRight={0}
                onClick={writePickedTemplateModulesAndPermissions}
            >
                {!isMobile && settingsTexts.rolesAndPermissions.restoreDefaults}
            </StyledAppButton>
        </StyledAppFormItem>
    );
};
