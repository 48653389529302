import { AdditionalLessonDTO } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { getAdditionalLessonPriceText } from 'utils/getAdditionalLessonPriceText';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { additionalLessonPaymentMapper } from 'consts/additionalLessons/additionalLessonPaymentMapper';
import { additionalLessonSettlementMapper } from 'consts/additionalLessons/additionalLessonSettlementMapper';

export const parseAdditionalLessonDTOIntoJsonObject: (
    additionalLessonDTO: AdditionalLessonDTO,
) => object = (additionalLessonDTO) => {
    const { free, paymentType, settlementType, price, courseName, configured } =
        additionalLessonDTO;

    const formattedPrice =
        price && paymentType ? getAdditionalLessonPriceText(price, paymentType) : EMPTY_VALUE_LABEL;
    const paymentTypeLabel = paymentType
        ? additionalLessonPaymentMapper[paymentType]
        : EMPTY_VALUE_LABEL;
    const settlementTypeLabel = settlementType
        ? additionalLessonSettlementMapper[settlementType]
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.additionalLessonDTO.fields.courseName]: courseName,
        [jsonObjectsTexts.additionalLessonDTO.fields.configured]: configured
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.additionalLessonDTO.fields.free]: free
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.additionalLessonDTO.fields.price]: formattedPrice,
        [jsonObjectsTexts.additionalLessonDTO.fields.paymentType]: paymentTypeLabel,
        [jsonObjectsTexts.additionalLessonDTO.fields.settlementType]: settlementTypeLabel,
    };
};
