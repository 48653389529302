import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { ParentAccountFormFields } from 'types';
import { AppForm, AppFormProps } from 'components/organisms';
import {
    BankAccountNumberInputElement,
    CityInputElement,
    EmailInputElement,
    FirstNameInputElement,
    FormNavButtons,
    LastNameInputElement,
    PhoneNumberInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { accountTexts, commonTexts } from 'consts/text';
import { AppFormItemGroup } from 'components/atoms';

export type ParentAccountEditFormProps = Omit<
    AppFormProps<ParentAccountFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack' | 'mode'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    onFinish: (parentAccountData: ParentAccountFormFields) => void;
};

export const ParentAccountEditForm = ({
    initialValues,
    axiosError,
    ...props
}: ParentAccountEditFormProps) => {
    const parentAccountEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<ParentAccountFormFields>
            title={accountTexts.editAccount}
            name="parentAccountEdit"
            error={parentAccountEditError}
            withGoBack
            {...props}
        >
            <AppFormItemGroup title={commonTexts.dataLabels.contactData}>
                <FirstNameInputElement />
                <LastNameInputElement />
                <EmailInputElement disabled />
                <PhoneNumberInputElement optional marginBottom={0} />
            </AppFormItemGroup>
            <AppFormItemGroup title={commonTexts.dataLabels.settlement}>
                <BankAccountNumberInputElement optional marginBottom={0} />
            </AppFormItemGroup>
            <AppFormItemGroup title={commonTexts.dataLabels.address}>
                <StreetAddressInputElement optional />
                <ZipCodeInputElement optional />
                <CityInputElement optional marginBottom={0} />
            </AppFormItemGroup>
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
