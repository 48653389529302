import { ParentAccountFormFields, ParentProfileDTO } from 'types';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';

export const parseParentProfileDTOIntoParentAccountFormFields: (
    parentProfileDTO: ParentProfileDTO,
) => ParentAccountFormFields = (parentProfileDTO) => {
    const { firstName, lastName, email, phoneNumber, bankAccountNumber, address } =
        parentProfileDTO;
    const { street, zipCode, city } = address || { street: '', zipCode: '', city: '' };

    return {
        firstName: firstName || '',
        lastName: lastName || '',
        email,
        phoneNumber: phoneNumber ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber) : '',
        bankAccountNumber: bankAccountNumber
            ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
            : '',
        street: street || '',
        zipCode: zipCode || '',
        city: city || '',
    };
};
