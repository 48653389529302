import { TreeSelect } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyleTreeSelect = createGlobalStyle`
  .ant-select-tree-treenode:not(:has(.ant-select-tree-checkbox)) {
      font-size: 16px;
      font-weight: 700;
  }

  .ant-select-tree-switcher.ant-select-tree-switcher_open {
      display: none !important;
  }

  .ant-select-tree-indent {
      display: none !important;
  }

  .ant-select-tree-switcher.ant-select-tree-switcher-noop {
      display: none !important;
  }
`;

export const StyledTreeSelect = styled(TreeSelect)`
    width: 100%;
    height: 44px;
`;
