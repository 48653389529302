import uniqBy from 'lodash/uniqBy';
import { ChildWithParents } from 'types';
import { mergeChildrenWithParentsIntoParents } from 'utils/mergeChildrenWithParentsIntoParents';

export const getUniqueParentsFromChildrenWithParents = (
    childrenWithParents: ChildWithParents[],
) => {
    const parents = mergeChildrenWithParentsIntoParents(childrenWithParents);
    return uniqBy(parents, (parent) => parent.id);
};
