import { Rule } from 'antd/es/form';
import { commonTexts } from 'consts/text';

export const max300LengthRule: Rule = {
    type: 'string',
    max: 300,
    message: commonTexts.maxLengthRules.maxLength300,
};

export const max2000LengthRule: Rule = {
    type: 'string',
    max: 2000,
    message: commonTexts.maxLengthRules.maxLength2000,
};

export const max3000LengthRule: Rule = {
    type: 'string',
    max: 3000,
    message: commonTexts.maxLengthRules.maxLength3000,
};

export const max5000LengthRule: Rule = {
    type: 'string',
    max: 5000,
    message: commonTexts.maxLengthRules.maxLength5000,
};
