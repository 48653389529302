import styled, { css } from 'styled-components';

export const Header = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: ${theme.sizes.marginNormal}px;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            flex-direction: column-reverse;
        }
    `}
`;
