import { AppCardListItem, AppCardListItemProps, AppText } from 'components/atoms';
import { LessonId, SpecialTopicChildWork, SpecialTopicDTO } from 'types';
import { getFullName } from 'utils/getFullName';
import { getTimeDuration } from 'utils/getTimeDuration';
import { commonTexts, journalTexts, timetableTexts } from 'consts/text';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'theme/styledComponents/colors';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { getChildrenWorkDataForSpecialTopic } from 'utils/getChildrenWorkDataForSpecialTopic';

type SpecialJournalTopicCardProps = Omit<
    AppCardListItemProps<SpecialTopicDTO>,
    'listItem' | 'children'
> & {
    specialTopicData: SpecialTopicDTO;
    specialTopicLessonId: LessonId;
};

export const SpecialJournalTopicCard = ({
    index,
    specialTopicData,
    options,
    isOptionsLoading,
    specialTopicLessonId,
}: SpecialJournalTopicCardProps) => {
    const [mergedAllChildrenWorkData, setMergedAllChildrenWorkData] = useState<
        SpecialTopicChildWork[]
    >([]);
    const { journalId } = useJournalId();

    const { name, description, timeFrom, timeTo, childrenWork, notInLessonChildrenWork, teacher } =
        specialTopicData;
    const teacherFullName = teacher
        ? getFullName(teacher.firstName, teacher.lastName)
        : EMPTY_VALUE_LABEL;

    const { data: specialAttendanceData } = useAppQuery(
        'SPECIAL_ATTENDANCE',
        [journalId, specialTopicLessonId],
        () =>
            StaffHTTPService.specialAttendance.getAttendanceByLessonId(
                journalId,
                specialTopicLessonId,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS, enabled: !!specialTopicLessonId },
    );

    useEffect(() => {
        if (specialAttendanceData) {
            const { mergedChildrenWork, mergedNotInLessonChildrenWork } =
                getChildrenWorkDataForSpecialTopic({
                    childrenWork,
                    notInLessonChildrenWork,
                    childrenAttendance: specialAttendanceData.childrenAttendance,
                });
            setMergedAllChildrenWorkData(mergedChildrenWork.concat(mergedNotInLessonChildrenWork));
        }
    }, [specialAttendanceData, childrenWork, notInLessonChildrenWork]);

    const renderChildContent = (child: SpecialTopicChildWork) => {
        const noPresenceEntered = child.absentType === null;
        const wasChildAbsent = child.absentType === 'ABSENT' || noPresenceEntered;
        const attendanceContent = noPresenceEntered
            ? journalTexts.journalDetails.specialJournal.topic.noAttendanceRecord
            : journalTexts.journalDetails.common.attendance.absent;

        if (wasChildAbsent) {
            return (
                <>
                    {getFullName(child.firstName, child.lastName)}
                    <AppText
                        textType="BodySmall"
                        margin={0}
                        color={colors.red.red6}
                        marginLeft={12}
                    >
                        {attendanceContent}
                    </AppText>
                </>
            );
        }
        return getFullName(child.firstName, child.lastName);
    };

    const memoizedChildrenList = useMemo(
        () =>
            mergedAllChildrenWorkData.map((child) => (
                <React.Fragment key={child.id}>
                    <AppCardListItem.CardRow
                        label={commonTexts.dataLabels.child}
                        content={renderChildContent(child)}
                    />
                    {child.description && (
                        <AppCardListItem.CardRow
                            label={
                                journalTexts.journalDetails.specialJournal.topic
                                    .informationAboutChildrenWorkShort
                            }
                            content={child.description}
                        />
                    )}
                </React.Fragment>
            )),
        [mergedAllChildrenWorkData],
    );

    return (
        <AppCardListItem
            index={index}
            listItem={specialTopicData}
            options={options}
            isOptionsLoading={isOptionsLoading}
        >
            <AppCardListItem.CardRow label={commonTexts.dataLabels.topic} content={name} />
            <AppCardListItem.CardRow
                label={commonTexts.dataLabels.timeDuration}
                content={getTimeDuration(timeFrom, timeTo)}
            />
            <AppCardListItem.CardRow
                label={timetableTexts.lessons.lessonsDescription}
                content={description}
            />

            <AppCardListItem.CardRow
                label={commonTexts.dataLabels.teacher}
                content={teacherFullName}
            />
            {memoizedChildrenList}
        </AppCardListItem>
    );
};
