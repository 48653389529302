import { journalFields } from 'consts/journal/journalFields';
import { JournalDTO } from 'types';
import { SpecialJournalFormFields } from 'types/forms/journal';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseJournalDTOInfoSpecialJournalFormFields: (
    journalDTO: JournalDTO,
) => SpecialJournalFormFields = (journalDTO) => ({
    name: journalDTO[journalFields.name],
    startDate: parseStringIntoDayjsDate(journalDTO[journalFields.startDate]),
    finishDate: parseStringIntoDayjsDate(journalDTO[journalFields.endDate]),
    teacherIds: journalDTO[journalFields.group].teachers.map((teacher) => teacher.id),
    childIdsToAdd: [],
    lessonsType: journalDTO[journalFields.lessonsType] || '',
});
