import { IconDeviceFloppy } from '@tabler/icons-react';
import { CommonFormProps, JournalChildDatesFormFields, JournalChildDTO, JournalId } from 'types';
import { AppFormItem, DetailsItem } from 'components/atoms';
import { DayPeriodPickerElement, FormNavButtons } from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { commonTexts, journalTexts } from 'consts/text';
import { AxiosError } from 'axios';
import { getFullName } from 'utils/getFullName';
import { AppModalForm, AppModalFormProps } from 'components/organisms';
import { parseJournalChildDTOIntoJournalChildDatesFormFields } from 'utils/parsers/journal/parseJournalChildDTOIntoJournalChildDatesFormFields';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export type JournalChildDatesFormProps = Omit<
    AppModalFormProps<JournalChildDatesFormFields>,
    'width' | 'title' | 'name' | 'onFinish'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        onFinish: (values: JournalChildDatesFormFields) => void;
    } & { child: JournalChildDTO };

export const JournalChildDatesForm = ({ child, ...props }: JournalChildDatesFormProps) => {
    const { journalId } = useJournalId();

    const { data: journalData } = useAppQuery(
        'JOURNAL',
        [journalId],
        () => StaffHTTPService.journals.getJournal(journalId as JournalId),
        {
            enabled: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    return (
        <AppModalForm<JournalChildDatesFormFields> name="changeJournalChildDates" {...props}>
            <AppFormItem>
                <DetailsItem title={commonTexts.dataLabels.lastnameAndFirstname}>
                    {getFullName(child.firstName, child.lastName)}
                </DetailsItem>
            </AppFormItem>
            <DayPeriodPickerElement
                mode="start"
                endField={formFields.leaveDate}
                startField={formFields.joinDate}
                label={commonTexts.dataLabels.joinDate}
                minDate={journalData?.startDate}
                maxDate={journalData?.endDate}
            />
            <DayPeriodPickerElement
                optional
                mode="finish"
                endField={formFields.leaveDate}
                startField={formFields.joinDate}
                label={commonTexts.dataLabels.leaveDate}
                extra={journalTexts.journalDetails.common.children.changeDatesInfo}
                minDate={journalData?.startDate}
                maxDate={journalData?.endDate}
            />
            <FormNavButtons
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
                formValuesBeforeChanges={parseJournalChildDTOIntoJournalChildDatesFormFields(child)}
            />
        </AppModalForm>
    );
};
