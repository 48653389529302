import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { AppText } from '../AppText/AppText';

export type AppLabelProps = {
    label: string;
    isFitContentWidth?: boolean;
    children: ReactNode;
};

const Container = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['isFitContentWidth']),
})<Pick<AppLabelProps, 'isFitContentWidth'>>`
    ${({ theme, isFitContentWidth }) => css`
        width: ${isFitContentWidth ? 'auto' : '100%'};
        display: flex;
        flex-direction: column;
        gap: ${theme.sizes.marginSmall}px;
    `}
`;

export const AppLabel = ({ label, isFitContentWidth, children }: AppLabelProps) => (
    <Container isFitContentWidth={isFitContentWidth}>
        <AppText margin={0} textType="BodyMSSemiBold">
            {label}
        </AppText>
        {children}
    </Container>
);
