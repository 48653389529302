import styled, { css } from 'styled-components';
import { AppList } from 'components/atoms';

export const StyledListItem = styled(AppList.Item)`
    ${({ theme }) => css`
        &.ant-list-item {
            background-color: ${theme.colors.primary.primary2};
            border-bottom: 1px solid ${theme.colors.primary.primary3};
        }

        &.ant-list-item,
        .ant-list-bordered &.ant-list-item {
            padding: 16px 12px 16px 130px;
        }
    `}
`;

export const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.sizes.marginNormal}px;
`;
