import { AppFormItem, AppFormItemProps, AppTextArea } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { max2000LengthRule } from 'consts/rules/textRules';
import { Rule } from 'antd/es/form';

export const DescriptionInputElement = ({
    maxLengthRule = max2000LengthRule,
    ...props
}: AppFormItemProps & { maxLengthRule?: Rule }) => (
    <AppFormItem
        label="Opis"
        name={formFields.description}
        rules={[{ required: true, message: formElementRequiredText }, maxLengthRule]}
        {...props}
    >
        <AppTextArea rows={6} autoComplete="off" />
    </AppFormItem>
);
