const idDocumentNumberFirstDigitCharacterPosition = 3;

export const IdDocumentNumberDisplay = ({ idDocumentNumber }: { idDocumentNumber: string }) => {
    const letterCharactersSubstring = idDocumentNumber.slice(
        0,
        idDocumentNumberFirstDigitCharacterPosition,
    );
    const digitCharactersSubstring = idDocumentNumber.slice(
        idDocumentNumberFirstDigitCharacterPosition,
    );

    return `${letterCharactersSubstring} ${digitCharactersSubstring}`;
};
