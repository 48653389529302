import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { JournalId } from 'types';
import { commonTexts, journalTexts } from 'consts/text/index';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type JournalInspectionsPageNetworkManageProps = {
    journalId: JournalId;
    onInspectionRemoveRequestFinish: () => void;
};

export const useJournalInspectionsPageNetworkManage = ({
    journalId,
    onInspectionRemoveRequestFinish,
}: JournalInspectionsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: inspectionsData,
        isInitialLoading: isInspectionsDataInitialLoading,
        isRefetching: isInspectionsDataRefetching,
        isError: isInspectionsDataError,
    } = useAppQuery(
        'INSPECTIONS',
        [journalId],
        () => StaffHTTPService.inspections.getInspections(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: removeInspection, isLoading: isRemoveInspectionLoading } = useAppMutation(
        StaffHTTPService.inspections.removeInspection,
        {
            key: ['REMOVE_INSPECTION'],
            onSuccess: async (_, variables) => {
                onInspectionRemoveRequestFinish();
                await queryClient.invalidateQueries([
                    institutionId,
                    'INSPECTION',
                    variables.journalId,
                    variables.inspectionId,
                ]);
                displaySuccess(
                    journalTexts.journalDetails.common.inspections.successfullyRemovedInspection,
                );
            },
            onError: () => {
                onInspectionRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['INSPECTIONS', journalId]],
        },
    );

    return {
        inspectionsData,
        isInspectionsDataInitialLoading,
        isInspectionsDataRefetching,
        isInspectionsDataError,
        isRemoveInspectionLoading,
        removeInspection,
    };
};
