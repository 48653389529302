import { Row } from 'antd';
import { AppParagraph, AppText } from 'components/atoms';
import { colors } from 'theme/styledComponents/colors';
import { historyChangeTypeLabels } from 'consts/historyChange/historyChangeTypeLabels';
import { tagsColorsByHistoryChangeType } from 'consts/historyChange/tagsColorsByHistoryChangeType';
import { HistoryChangeDTO } from 'types';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { historyChangeRefersToLabels } from 'consts/historyChange/historyChangeRefersToLabels';
import { TableTagDisplay } from 'components/molecules/Table/TableTagDisplay/TableTagDisplay';
import { historyChangeRefersToSubLabels } from 'consts/historyChange/historyChangeRefersToSubLabels';

type HistoryChangeInfoMobileDisplayProps = {
    record: HistoryChangeDTO;
};

export const HistoryChangeInfoMobileDisplay = ({ record }: HistoryChangeInfoMobileDisplayProps) => {
    const { refersTo, refersToSub, historyType, createdAt } = record;

    const refersToText =
        refersToSub !== 'NONE'
            ? `${historyChangeRefersToLabels[refersTo]}/${historyChangeRefersToSubLabels[refersToSub]}`
            : historyChangeRefersToLabels[refersTo];
    const formattedCreatedAt = parseStringIntoFormattedStringDate(
        createdAt,
        'YYYY-MM-DDTHH:mm:ss',
        'DD/MM/YYYY, HH:mm',
    );

    return (
        <>
            <AppParagraph
                margin={0}
                marginBottom={8}
                textType="BodySmall"
                color={colors.grayscale.gray9}
            >
                {formattedCreatedAt}
            </AppParagraph>
            <Row justify="space-between" align="middle">
                <AppText margin={0} textType="BodyMSRegular" style={{ maxWidth: '50%' }}>
                    {refersToText}
                </AppText>
                <TableTagDisplay
                    value={historyType}
                    labelMapper={historyChangeTypeLabels}
                    colorMapper={tagsColorsByHistoryChangeType}
                />
            </Row>
        </>
    );
};
