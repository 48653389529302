import { useContext, useMemo } from 'react';
import {
    TeacherPickerVersion,
    TeachersPickerElement,
    TeachersPickerElementProps,
} from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { employeeTexts } from 'consts/text';
import { parseJournalTeacherIntoSelectOptionType } from 'utils/parsers/journal/parseJournalTeacherIntoSelectOptionType';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type JournalTeachersPickerProps = Pick<TeachersPickerElementProps, 'version' | 'disabled'> & {
    date?: string;
};

export const JournalTeachersPicker = ({
    version = TeacherPickerVersion.MULTIPLE,
    date,
    disabled,
}: JournalTeachersPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);
    const { journalId } = useJournalId();

    const {
        data: teachersData,
        isFetching: isTeachersDataFetching,
        isError: isTeachersDataError,
    } = useAppQuery(
        'JOURNALS_TEACHERS',
        [journalId, date],
        () =>
            StaffHTTPService.journals.getJournalTeachers(journalId, {
                dateFrom: date,
                dateTo: date,
            }),
        {
            enabled: !!date,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isTeachersDataFetching,
        isError: isTeachersDataError,
        actualError: formError,
        errorMessage: employeeTexts.fetchingEmployeesDataForInputError,
        setError: setFormError,
    });

    const teachersOptions = useMemo(() => {
        if (!teachersData) {
            return [];
        }
        return teachersData.map(parseJournalTeacherIntoSelectOptionType);
    }, [teachersData]);

    return (
        <TeachersPickerElement
            version={version}
            options={teachersOptions}
            loading={isTeachersDataFetching}
            disabled={disabled}
        />
    );
};
