import { Key } from 'react';
import difference from 'lodash/difference';
import { ExtendedDataNode } from 'components/atoms';
import { groupDTOFields } from 'consts/group/groupDTOFields';
import { ChildId, EmployeeUser, GroupChildDTO, GroupDTO, SelectOptionType } from 'types';

export const mapGroupsListToChildrenIdsList = (groupsData: GroupDTO[]): ChildId[] =>
    groupsData.flatMap((group) => group[groupDTOFields.childrenArray].map((child) => child.id));

export const mapGroupsListToSelectOptions = (groupsData: GroupDTO[]): SelectOptionType<ChildId>[] =>
    groupsData.flatMap((group) =>
        group[groupDTOFields.childrenArray].map((child) => ({
            value: child.id,
            label: `${child.firstName} ${child.lastName}`,
        })),
    );

export const mapGroupsListToTreeReducer = (acc: ExtendedDataNode[], currentGroup: GroupDTO) => {
    const newChildren = currentGroup[groupDTOFields.childrenArray].map((child) => ({
        title: `${child.firstName} ${child.lastName}`,
        key: child.id,
    }));

    // Add new group
    return acc.concat({
        title: currentGroup[groupDTOFields.name],
        key: currentGroup[groupDTOFields.id],
        children: newChildren,
    });
};

export const parseGroupDataToTreeNodes = (groupsData: GroupDTO[], withParentNode?: boolean) => {
    const groups = groupsData.filter(
        (group) =>
            group[groupDTOFields.type] === 'JOURNAL_GROUP' &&
            group[groupDTOFields.childrenArray].length,
    );

    const groupsTreeNodes = groups.reduce<ExtendedDataNode[]>(mapGroupsListToTreeReducer, []);

    if (!withParentNode) {
        return groupsTreeNodes;
    }

    const groupsParentNode = {
        title: 'Grupy',
        key: 'groups',
        children: groupsTreeNodes,
    };

    return [groupsParentNode];
};

export const parseGroupDataToJournalChildrenTreeNodes = (
    groupsData: GroupDTO[],
    addedChildren: GroupChildDTO[],
) => {
    const addedChildrenIds = addedChildren.map((child) => child.id);
    const baseJournalsGroupsWithChildrenToSelect = groupsData.reduce<GroupDTO[]>(
        (allGroups, currentGroup) => {
            const isBaseGroupWithChildren =
                currentGroup[groupDTOFields.type] === 'JOURNAL_GROUP' &&
                currentGroup[groupDTOFields.childrenArray].length;

            if (!isBaseGroupWithChildren) {
                return allGroups;
            }

            const groupChildrenIds = currentGroup[groupDTOFields.childrenArray].map(
                (child) => child.id,
            );
            const availableChildrenIdsToSelect = difference(groupChildrenIds, addedChildrenIds);

            if (!availableChildrenIdsToSelect.length) {
                return allGroups;
            }

            const childrenToSelect = currentGroup[groupDTOFields.childrenArray].filter((child) =>
                availableChildrenIdsToSelect.includes(child.id),
            );

            return [...allGroups, { ...currentGroup, childrenArray: childrenToSelect }];
        },
        [],
    );

    const extendedGroups = {
        title: 'Grupy',
        key: 'groups',
        children: baseJournalsGroupsWithChildrenToSelect.reduce<ExtendedDataNode[]>(
            mapGroupsListToTreeReducer,
            [],
        ),
    };

    return [extendedGroups];
};

export const parseEmployeeDataToTreeNodes = (employeesData: EmployeeUser[]) => [
    {
        title: 'Pracownicy',
        key: 'employees',
        children: employeesData.map((employee) => ({
            title: `${employee.firstName} ${employee.lastName}`,
            key: employee.employeeId,
        })),
    },
];

export const parseTreeNodesIntoChildNodeKeys: (treeNodes: ExtendedDataNode[]) => Key[] = (
    treeNodes,
) =>
    treeNodes.reduce<Key[]>((allChildNodeKeys, currentTreeNode) => {
        if (!currentTreeNode.children) {
            return [...allChildNodeKeys, currentTreeNode.key];
        }
        const childNodeKeys = parseTreeNodesIntoChildNodeKeys(currentTreeNode.children);
        return allChildNodeKeys.concat(childNodeKeys);
    }, []);
