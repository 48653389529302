import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { useAppAbility } from '../useAppAbility';

export const useRemoteLessonAbilities = () => {
    const ability = useAppAbility();

    const remoteLessonAbilities = {
        checkIfCanViewRemoteLessonsConfig: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.REMOTE_LESSONS_OWN),
        checkIfCanManageRemoteLessonsConfig: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.REMOTE_LESSONS_OWN),
        checkIfCanSeeAllRemoteLessons: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.REMOTE_LESSONS_ALL),
    };

    return { remoteLessonAbilities };
};
