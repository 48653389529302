import { DiscountDTO, DiscountType } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { discountTargetMapper, discountTypeMapper } from 'consts/discounts/discountsMappers';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseDiscountDTOIntoJsonObject: (discountDTO: DiscountDTO) => object = (
    discountDTO,
) => {
    const {
        name,
        discountTarget,
        discountType,
        startDate,
        endDate,
        amount,
        combinable,
        extraLessons,
    } = discountDTO;

    const formattedStartDate = parseStringIntoFormattedStringDate(startDate);
    const formattedEndDate = parseStringIntoFormattedStringDate(endDate);
    const amountInfo =
        discountType === DiscountType.PERCENTAGE_DISCOUNT ? `${amount}%` : `${amount} zł`;

    return {
        [jsonObjectsTexts.discountDTO.fields.discountTarget]: discountTargetMapper[discountTarget],
        [jsonObjectsTexts.discountDTO.fields.discountType]: discountTypeMapper[discountType],
        [jsonObjectsTexts.discountDTO.fields.extraLessons]: extraLessons?.name || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.discountDTO.fields.name]: name,
        [jsonObjectsTexts.discountDTO.fields.startDate]: formattedStartDate,
        [jsonObjectsTexts.discountDTO.fields.endDate]: formattedEndDate,
        [jsonObjectsTexts.discountDTO.fields.amount]: amountInfo,
        [jsonObjectsTexts.discountDTO.fields.combinable]: combinable
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
