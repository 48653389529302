import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { PageBaseLayout } from 'components/layouts';
import { Details } from 'components/organisms';
import { commonTexts, menusTexts } from 'consts/text/index';
import { Row } from 'antd';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { AppList } from 'components/atoms';
import { AttachmentListItem } from 'components/molecules';
import { useTheme } from 'styled-components';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';

export const ParentMenuDetailsPage = () => {
    const theme = useTheme();

    const {
        data: menuData,
        isInitialLoading: isInitialMenuDataLoading,
        isError: isFetchMenuDataError,
    } = useAppQuery('MENU', [], ParentHTTPService.menus.getMenu, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    return (
        <PageBaseLayout
            isError={isFetchMenuDataError}
            isDataInitialLoading={isInitialMenuDataLoading}
            isEmpty={!menuData}
            emptyStateData={{ emptyInfo: commonTexts.emptyInfo.noDiet }}
        >
            <Details title={menusTexts.menuPreview} isVisibleGoBackButton={false}>
                <Details.Item title={menusTexts.menuName}>{menuData?.name}</Details.Item>
                <Row>
                    <Details.Item title={menusTexts.appliesFrom}>
                        {menuData && parseStringIntoFormattedStringDate(menuData.dateFrom)}
                    </Details.Item>
                    <Details.Item title={menusTexts.appliesTo}>
                        {menuData && parseStringIntoFormattedStringDate(menuData.dateTo)}
                    </Details.Item>
                </Row>
                {!!menuData?.attachments?.length && (
                    <Details.Item title={commonTexts.dataLabels.attachment}>
                        <AppList
                            dataSource={menuData.attachments}
                            marginTop={theme.sizes.marginNormal}
                            renderItem={(fileData) => (
                                <AttachmentListItem
                                    key={fileData.fileUri}
                                    fileData={fileData}
                                    fetchAttachmentFunction={ParentHTTPService.files.getFile}
                                />
                            )}
                        />
                    </Details.Item>
                )}
            </Details>
        </PageBaseLayout>
    );
};
