import { SettlementOverallFormFields } from 'types';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { PageBaseLayout } from 'components/layouts';
import { settingsTexts } from 'consts/text';
import { SettlementOverallEditForm } from 'components/templates';
import { useSettingsAbilities } from 'abilities';
import { useEffect } from 'react';
import { formFields } from 'consts/form/formFields';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parseSettlementOverallDTOIntoSettlementOverallFormFields } from 'utils/parsers/settlementOverall/parseSettlementOverallDTOIntoSettlementOverallFormFields';
import { parseSettlementOverallFormFieldsIntoUpdateSettlementOverallDTO } from 'utils/parsers/settlementOverall/parseSettlementOverallFormFieldsIntoUpdateSettlementOverallDTO';

export const SettlementOverallEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SettlementOverallFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { settingsAbilities } = useSettingsAbilities();
    const canManageSettlementSettings = settingsAbilities.checkIfCanManageSettlementSettings();

    const {
        data: settlementOverallData,
        isError: isSettlementOverallDataError,
        isFetching: isSettlementOverallDataFetching,
    } = useAppQuery(
        'SETTLEMENT_OVERALL',
        [],
        () => StaffHTTPService.settings.getSettlementOverall(),
        {
            retry: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (settlementOverallData && !form.getFieldValue(formFields.paymentDueTime)) {
            const parsedSettlementOverallData =
                parseSettlementOverallDTOIntoSettlementOverallFormFields(settlementOverallData);
            setFormData(parsedSettlementOverallData);
        }
    }, [settlementOverallData, form, setFormData]);

    const {
        mutate: editSettlementOverall,
        error: editSettlementOverallError,
        isLoading: isEditSettlementOverallLoading,
    } = useAppMutation(StaffHTTPService.settings.updateSettlementOverall, {
        key: ['UPDATE_SETTLEMENT_OVERALL'],
        onSuccess: () =>
            displaySuccess(settingsTexts.settlements.meals.successNotifications.settlementEdited),
        invalidateQueryKeys: [['SETTLEMENT_OVERALL'], ['WEEKLY_SCHEDULE'], ['DAILY_ATTENDANCE']],
    });

    const onEditSettlementOverallFinish = (
        settlementOverallFormFields: SettlementOverallFormFields,
    ) => {
        const parsedSettlementOverallFormFields =
            parseSettlementOverallFormFieldsIntoUpdateSettlementOverallDTO(
                settlementOverallFormFields,
            );
        editSettlementOverall({
            settlementOverallDTO: parsedSettlementOverallFormFields,
        });
    };

    return (
        <PageBaseLayout isError={isSettlementOverallDataError}>
            <SettlementOverallEditForm
                form={form}
                initialValues={formInitialData}
                axiosError={editSettlementOverallError}
                isLoading={isEditSettlementOverallLoading || isSettlementOverallDataFetching}
                onFinish={onEditSettlementOverallFinish}
                isViewMode={!canManageSettlementSettings}
            />
        </PageBaseLayout>
    );
};
