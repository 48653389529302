import { InstitutionId } from '../institution';

export type EventId = string & { readonly type: unique symbol };

export enum CalendarEventType {
    HOLIDAY_AND_SPECIAL_EVENT = 'HOLIDAY_AND_SPECIAL_EVENT',
    SCHOOL_HOLIDAY = 'SCHOOL_HOLIDAY',
    OTHER = 'OTHER',
}

export type CalendarEventDto = {
    id: EventId;
    institutionId: InstitutionId;
    type: CalendarEventType;
    freeFromSchool: boolean;
    name: string;
    startDate: string;
    endDate: string;
    description: string;
};

export type CalendarEventDetailedDto = {
    id: EventId;
    institutionId: InstitutionId;
    type: CalendarEventType;
    freeFromSchool: boolean;
    name: string;
    startDate: string;
    endDate: string;
    description: string;
    canModify: boolean;
    journalName?: string;
};

export type CalendarEventDetailedDtos = CalendarEventDetailedDto[];

export type CreateCalendarEventDto = Omit<
    CalendarEventDetailedDto,
    'id' | 'institutionId' | 'canModify' | 'journalName'
>;

export type CalendarComponentEvent = Pick<CalendarEventDetailedDto, 'id'> & {
    title: string;
    start: string;
    end: string;
    textColor: string;
    backgroundColor: string;
    borderColor: string;
};
