import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppMenu, AppMenuProps } from 'components/atoms/CommonAppComponents/AppMenu/AppMenu';

export type TopAppMenuProps = Omit<AppMenuProps, 'mode'>;

const StyledTopAppMenu = styled(AppMenu)`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const TopAppMenu = ({ onClick, ...restProps }: TopAppMenuProps) => {
    const navigate = useNavigate();

    const onMenuItemClick: TopAppMenuProps['onClick'] = ({ key }) => navigate(key);

    return (
        <StyledTopAppMenu {...restProps} mode="horizontal" onClick={onClick || onMenuItemClick} />
    );
};
