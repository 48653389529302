import { RouteObject } from 'react-router-dom';
import { MessagePanelLayout, SideMenuLayout } from 'components/layouts';
import { appPaths } from 'consts/paths/paths';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { AppActions } from 'types';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import {
    EmailConfirmationRequiredInfo,
    InstitutionAddPage,
    InstitutionDetailsEditPage,
    InvoiceDetailsEditPage,
    MailThreadAddPage,
    MailThreadDetailsPage,
} from 'pages';
import { employeeRoutes } from './staff/employee';
import { childRoutes } from './staff/child';
import { groupRoutes } from './staff/group';
import { journalRoutes } from './staff/journal/journal';
import { timetableRoutes } from './staff/timetable';
import { settingsRoutes } from './staff/settings';
import { accountRoutes } from './staff/account';
import { menuRoutes } from './staff/menu';
import { remoteLessonsRoutes } from './staff/remoteLessons';
import { settlementRoutes } from './staff/settlement';
import { calendarRoutes } from './staff/calendar';

export const staffRoutes: RouteObject[] = [
    {
        element: <SideMenuLayout />,
        children: [
            ...employeeRoutes,
            ...childRoutes,
            ...groupRoutes,
            ...journalRoutes,
            ...timetableRoutes,
            ...settingsRoutes,
            ...accountRoutes,
            ...menuRoutes,
            ...remoteLessonsRoutes,
            ...settlementRoutes,
            ...calendarRoutes,
            {
                path: appPaths.app['remote-lessons'].base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW_MODULE,
                            subject: STAFF_APP_MODULES.REMOTE_LESSONS,
                        }}
                    >
                        <div />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.messages.add,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW_MODULE,
                            subject: STAFF_APP_MODULES.MESSAGES,
                        }}
                    >
                        <MailThreadAddPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app['add-institution'],
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.INSTITUTIONS_CREATE,
                        }}
                    >
                        <InstitutionAddPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['invoice-details'].edit,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.PAYMENTS_SETTINGS,
                        }}
                    >
                        <InvoiceDetailsEditPage />,
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['institution-details'].edit,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.EDIT_AND_ADD,
                            subject: PERMISSIONS_NAME.INSTITUTION_DATA,
                        }}
                    >
                        <InstitutionDetailsEditPage />
                    </AbilityRoute>
                ),
            },
        ],
    },
    {
        element: <SideMenuLayout withoutInnerLayout />,
        children: [
            {
                path: appPaths.app.messages.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW_MODULE,
                            subject: STAFF_APP_MODULES.MESSAGES,
                        }}
                    >
                        <MessagePanelLayout />
                    </AbilityRoute>
                ),
                children: [
                    {
                        path: appPaths.app.messages.pickedMailThread,
                        element: <MailThreadDetailsPage />,
                    },
                ],
            },
        ],
    },
    {
        path: appPaths.app['user-confirmation'],
        element: <EmailConfirmationRequiredInfo />,
    },
];
