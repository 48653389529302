import { SettlementMealsDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { settlementConfigurationLabels } from 'consts/settlement/settlementConfigurationLabels';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { absenceReportTypeLabels } from 'consts/settlementMeals/absenceReportTypeLabels';
import { mealProviderTypeLabels } from 'consts/settlementMeals/mealProviderTypeLabels';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { parseCateringPaymentDetailsDtoIntoJsonObject } from './parseCateringPaymentDetailsDtoIntoJsonObject';

export const parseSettlementMealsDTOIntoJsonObject: (
    settlementMealsDTO: SettlementMealsDTO,
) => object = (settlementMealsDTO) => {
    const {
        mealProvider,
        absenceReportType,
        absenceReportTime,
        mealPaymentType,
        cateringPaymentDetails,
    } = settlementMealsDTO;

    const mealPaymentTypeLabel = mealPaymentType
        ? settlementConfigurationLabels[mealPaymentType]
        : EMPTY_VALUE_LABEL;
    const absenceReportTypeLabel = absenceReportType
        ? absenceReportTypeLabels[absenceReportType]
        : EMPTY_VALUE_LABEL;
    const formattedAbsenceReportTime = absenceReportTime
        ? parseStringIntoFormattedStringTime(absenceReportTime)
        : EMPTY_VALUE_LABEL;
    const mealProviderLabel = mealProvider
        ? mealProviderTypeLabels[mealProvider]
        : EMPTY_VALUE_LABEL;
    const cateringPaymentDetailsJsonObject = cateringPaymentDetails
        ? parseCateringPaymentDetailsDtoIntoJsonObject(cateringPaymentDetails)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.settlementMealsDTO.fields.mealPaymentType]: mealPaymentTypeLabel,
        [jsonObjectsTexts.settlementMealsDTO.fields.absenceReportType]: absenceReportTypeLabel,
        [jsonObjectsTexts.settlementMealsDTO.fields.absenceReportTime]: formattedAbsenceReportTime,
        [jsonObjectsTexts.settlementMealsDTO.fields.mealProvider]: mealProviderLabel,
        [jsonObjectsTexts.settlementMealsDTO.fields.cateringPaymentDetails]:
            cateringPaymentDetailsJsonObject,
    };
};
