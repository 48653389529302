import { MailThreadDTO, ParentMailThreadFormFields } from 'types';
import { messagesTexts } from 'consts/text';

export const parseMailThreadDTOIntoReplyParentMailThreadFormFields: (
    mailThreadDTO: MailThreadDTO,
) => ParentMailThreadFormFields = (mailThreadDTO) => {
    const { topic, authorUserId } = mailThreadDTO;
    return {
        topic: `${messagesTexts.replyTopicPrefix} ${topic}`,
        content: '',
        userId: authorUserId,
    };
};
