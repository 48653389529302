import styled from 'styled-components';
import { AppModalIconContainer, AppModalTitle } from 'components/atoms';
import { getTextStyle } from 'utils/getTextStyle';

export const IconContainer = styled(AppModalIconContainer)`
    margin-block: 32px 16px;
`;

export const ModalTitle = styled(AppModalTitle)`
    &.ant-typography {
        margin-bottom: 32px;
    }
`;

export const ModalRedText = styled.span`
    color: ${({ theme }) => theme.colors.red.red6};
`;

export const ModalSemiBoldMediumText = styled.span`
    ${getTextStyle('BodyMediumSemiBold')};
    color: ${({ theme }) => theme.colors.grayscale.gray9};
`;

export const ModalSemiBoldSmallText = styled.span`
    ${getTextStyle('BodySmallSemiBold')};
    color: ${({ theme }) => theme.colors.grayscale.gray9};
`;
