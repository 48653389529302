import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { DualValueCellContent } from 'components/atoms';

type NameWithDateDisplayProps = {
    name: string;
    date: string;
};

export const NameWithDateDisplay = ({ name, date }: NameWithDateDisplayProps) => {
    const isMobile = useIsMobile();

    const parsedDate = parseStringIntoFormattedStringDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY');

    return isMobile ? <DualValueCellContent mainValue={name} secondaryValue={parsedDate} /> : name;
};
