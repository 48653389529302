import { ReactNode, ElementType } from 'react';
import { LoadingDisplay } from 'components/molecules/StatesComponents/LoadingDisplay/LoadingDisplay';
import { ErrorDisplay } from 'components/molecules/StatesComponents/ErrorDisplay/ErrorDisplay';
import {
    EmptyDisplay,
    EmptyDisplayProps,
} from 'components/molecules/StatesComponents/EmptyDisplay/EmptyDisplay';
import { NothingFoundDisplay } from 'components/molecules/StatesComponents/NothingFoundDisplay/NothingFoundDisplay';
import { ComponentBlockingAppLoader } from 'components/atoms';

type DynamicStateDisplayWrapperProps = {
    children: ReactNode;
    StateDisplayContainer?: ElementType;
    statusData: {
        isDataInitialLoading?: boolean;
        isDataRefetching?: boolean;
        isDataError?: boolean;
        isEmpty?: boolean;
        isNothingFound?: boolean;
    };
    emptyStateConfig?: EmptyDisplayProps;
};

export const DynamicStateDisplayWrapper = ({
    children,
    statusData,
    emptyStateConfig,
    StateDisplayContainer = 'div',
}: DynamicStateDisplayWrapperProps) => {
    const { isDataInitialLoading, isDataRefetching, isDataError, isEmpty, isNothingFound } =
        statusData;

    if (isDataInitialLoading) {
        return (
            <StateDisplayContainer>
                <LoadingDisplay />
            </StateDisplayContainer>
        );
    }
    if (isDataRefetching) {
        return (
            <StateDisplayContainer>
                <ComponentBlockingAppLoader />
            </StateDisplayContainer>
        );
    }
    if (isDataError) {
        return (
            <StateDisplayContainer>
                <ErrorDisplay />
            </StateDisplayContainer>
        );
    }
    if (isEmpty) {
        return (
            <StateDisplayContainer>
                <EmptyDisplay {...emptyStateConfig} />
            </StateDisplayContainer>
        );
    }
    if (isNothingFound) {
        return (
            <StateDisplayContainer>
                <NothingFoundDisplay />
            </StateDisplayContainer>
        );
    }

    return children;
};
