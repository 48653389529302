import { Row } from 'antd';
import { AppCheckbox, AppText } from 'components/atoms';
import { commonTexts } from 'consts/text';
import styled from 'styled-components';

type SelectAllCheckboxProps = {
    isCheckedAll: boolean;
    indeterminate: boolean;
    onToggleSelectAll: () => void;
};

export const StyledRow = styled(Row)`
    margin-bottom: 14px;
`;

export const SelectAllCheckbox = ({
    isCheckedAll,
    indeterminate,
    onToggleSelectAll,
}: SelectAllCheckboxProps) => (
    <StyledRow justify="space-between">
        <AppText textType="BodyMediumSemiBold" margin={0} marginLeft={6}>
            {commonTexts.forms.selectAll}
        </AppText>
        <AppCheckbox
            checked={isCheckedAll}
            indeterminate={indeterminate}
            onChange={onToggleSelectAll}
        />
    </StyledRow>
);
