import { atom, useAtomValue } from 'jotai';
import type { InstitutionBaseData, InstitutionId } from 'types';

export const defaultCurrentInstitutionData: InstitutionBaseData = {
    id: '' as InstitutionId,
    name: '',
};
export const currentInstitutionAtom = atom<InstitutionBaseData>(defaultCurrentInstitutionData);

export const useCurrentInstitution = () => useAtomValue(currentInstitutionAtom);
