import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MenuFormFields, MenuId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { MenuAddEditForm } from 'components/templates/menu/MenuAddEditForm/MenuAddEditForm';
import { parseMenuFormFieldsIntoAddUpdateMenuDTO } from 'utils/parsers/menus/parseMenuFormFieldsIntoAddMenuDTO';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { parseMenuDTOIntoMenusFormFields } from 'utils/parsers/menus/parseMenuDTOIntoMenusFormFields';
import { ErrorDisplay } from 'components/molecules';
import { menusTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const MenusEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<MenuFormFields>();
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();
    const { menuId: menuIdParam } = useParams();
    const menuId = (menuIdParam || '') as MenuId;

    const {
        data: menuData,
        isFetching: isMenuDataFetching,
        isError: isFetchMenuDataError,
    } = useAppQuery('MENU', [menuId], () => StaffHTTPService.menus.getMenu(menuId), {
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useEffect(() => {
        if (menuData) {
            setFormData(parseMenuDTOIntoMenusFormFields(menuData));
        }
    }, [form, menuData, setFormData]);

    const navigateToMenusPage = () => statefulNavigate(appPaths.app.menu.base, { replace: true });

    const handleUpdateMenuSuccess = () => {
        displaySuccess(menusTexts.successEditMessage);
        navigateToMenusPage();
    };

    const {
        mutate: updateMenu,
        isLoading: isUpdateMenuLoading,
        error: isUpdateMenuError,
    } = useAppMutation(StaffHTTPService.menus.updateMenu, {
        key: ['UPDATE_MENU'],
        onSuccess: handleUpdateMenuSuccess,
        invalidateQueryKeys: [['MENUS'], ['MENU', menuId]],
    });

    const onFinish = (menuFormFields: MenuFormFields) => {
        const parsedMenuData = parseMenuFormFieldsIntoAddUpdateMenuDTO(menuFormFields);
        updateMenu({ menuId, updateMenuDTO: parsedMenuData });
    };

    if (isFetchMenuDataError) {
        return <ErrorDisplay />;
    }

    return (
        <MenuAddEditForm
            mode="edit"
            isLoading={isUpdateMenuLoading || isMenuDataFetching}
            axiosError={isUpdateMenuError}
            onFinish={onFinish}
            form={form}
            initialValues={formInitialData}
        />
    );
};
