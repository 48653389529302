import { AppParagraph, RemoteLessonMessageContent } from 'components/atoms';
import { MessageRemoteLessonContentDTO } from 'types';

type MessageTextContentProps = {
    regularContent?: string;
    remoteLessonContent?: MessageRemoteLessonContentDTO;
};

export const MessageTextContent = ({
    regularContent,
    remoteLessonContent,
}: MessageTextContentProps) => {
    if (remoteLessonContent) {
        return <RemoteLessonMessageContent remoteLessonContent={remoteLessonContent} />;
    }

    return <AppParagraph margin={0}>{regularContent}</AppParagraph>;
};
