import { TableTag } from 'components/atoms';
import { ColorMapper, LabelMapper } from 'types';

type TableTagDisplayProps<ValueType extends string> = {
    value: ValueType;
    labelMapper: LabelMapper<ValueType>;
    colorMapper: ColorMapper<ValueType>;
};

export const TableTagDisplay = <ValueType extends string>({
    value,
    labelMapper,
    colorMapper,
}: TableTagDisplayProps<ValueType>) => {
    const label = labelMapper[value];
    const { textColor, backgroundColor } = colorMapper[value];

    return (
        <TableTag
            style={{
                color: textColor,
                backgroundColor,
            }}
        >
            {label}
        </TableTag>
    );
};
