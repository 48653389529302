import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { OwnerAndEmployeeAccountFormFields } from 'types';
import { AppForm, AppFormProps } from 'components/organisms';
import {
    FirstNameInputElement,
    FormNavButtons,
    LastNameInputElement,
    PhoneNumberInputElement,
} from 'components/molecules';
import { accountTexts, commonTexts } from 'consts/text';
import {
    employeeAccountModeData,
    ownerAccountModeData,
} from './OwnerAndEmployeeAccountEditForm.utils';

export type OwnerAndEmployeeAccountEditFormProps = Omit<
    AppFormProps<OwnerAndEmployeeAccountFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack' | 'mode'
> & {
    mode: 'OWNER_ACCOUNT' | 'EMPLOYEE_ACCOUNT';
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    onFinish: (ownerAndEmployeeAccountData: OwnerAndEmployeeAccountFormFields) => void;
};

export const OwnerAndEmployeeAccountEditForm = ({
    mode,
    initialValues,
    axiosError,
    ...props
}: OwnerAndEmployeeAccountEditFormProps) => {
    const ownerAndEmployeeAccountEditError = axiosError ? commonTexts.errorMessages.unknown : '';
    const { name, isPhoneNumberOptional } =
        mode === 'OWNER_ACCOUNT' ? ownerAccountModeData : employeeAccountModeData;

    return (
        <AppForm<OwnerAndEmployeeAccountFormFields>
            title={accountTexts.editAccount}
            name={name}
            error={ownerAndEmployeeAccountEditError}
            withGoBack
            {...props}
        >
            <FirstNameInputElement />
            <LastNameInputElement />
            <PhoneNumberInputElement
                optional={isPhoneNumberOptional}
                hasOptionalText={isPhoneNumberOptional}
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
