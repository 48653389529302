import { PsychologicalHelpDTO, PsychologicalHelpFormFields } from 'types';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parsePsychologicalHelpDTOIntoPsychologicalHelpFormFields: (
    psychologicalHelpDTO: PsychologicalHelpDTO,
) => PsychologicalHelpFormFields = ({
    helpForm,
    additionalInformation,
    description,
    attachments,
    basis,
    child,
    dateFrom,
    dateTo,
}) => ({
    helpForm,
    description,
    attachments,
    basis,
    dateFrom: parseStringIntoDayjsDate(dateFrom),
    dateTo: parseStringIntoDayjsDate(dateTo),
    childId: child.id,
    ...(additionalInformation && { additionalInformation }),
});
