import styled from 'styled-components';
import { AppLink, AppLinkProps } from 'components/atoms';

export type LinkDisplayProps = Omit<AppLinkProps, 'textType' | 'highlighted'>;

const StyledLink = styled(AppLink)<AppLinkProps>`
    &:hover {
        text-decoration-line: underline;
    }
`;

export const LinkDisplay = (props: LinkDisplayProps) => (
    <StyledLink textType="BodyMedium" highlighted {...props} />
);
