import { IconDeviceFloppy } from '@tabler/icons-react';
import { useEffect } from 'react';
import { childAddEditErrorParser } from 'utils/errorHandlers/child/childAddEditErrorParser';
import { AppForm, AppFormProps, DietPicker } from 'components/organisms';
import { AppFormItem, AppFormItemGroup } from 'components/atoms';
import { childrenTexts, commonTexts } from 'consts/text';
import {
    AccountingPeriodDatePickerElement,
    AllergensInputElement,
    BirthDateDatePickerElement,
    BirthPlaceInputElement,
    CityInputElement,
    DeclaredStayHoursTimePicker,
    DiseasesInputElement,
    FirstNameInputElement,
    FormNavButtons,
    GenderPickerElement,
    LastNameInputElement,
    PeselInputElement,
    SocialNetworksImageSharingAgreementPicker,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { EditChildFormFields } from 'types';
import { AxiosError } from 'axios';
import staffHTTPService from 'services/HTTPService/staff/StaffHTTPService';

type ChildEditFormProps = Pick<AppFormProps<EditChildFormFields>, 'onFinish' | 'isLoading'> & {
    axiosError?: AxiosError | null;
    initialValues: EditChildFormFields | null;
};

export const ChildEditForm = ({
    initialValues,
    axiosError,
    isLoading,
    onFinish,
}: ChildEditFormProps) => {
    const { form, setFormData, formInitialData } = useEditForm<EditChildFormFields>();

    const childEditError = axiosError ? childAddEditErrorParser(axiosError) : '';

    useEffect(() => {
        if (!initialValues) {
            return;
        }

        setFormData(initialValues);
    }, [initialValues, setFormData]);

    return (
        <AppForm
            name="editChild"
            form={form}
            title={childrenTexts.editChildrenButtonLabel}
            desktopTitleLevel={4}
            isFormHeaderAtBottom
            error={childEditError}
            onFinish={onFinish}
            isLoading={isLoading}
            maxWidth={695}
        >
            <AppFormItemGroup title={childrenTexts.childForm.personalData.personalData}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    {/*TODO: change labels to jsons after inputs refactor in forms*/}
                    <FirstNameInputElement />
                    <LastNameInputElement />
                </AppFormItem>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <PeselInputElement />
                    <GenderPickerElement />
                </AppFormItem>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <BirthDateDatePickerElement />
                    <BirthPlaceInputElement />
                </AppFormItem>
            </AppFormItemGroup>
            <AppFormItemGroup title={childrenTexts.childForm.addressData.addressData}>
                {/*TODO: change labels to jsons after inputs refactor in forms*/}
                <StreetAddressInputElement optional hasOptionalText />
                <AppFormItem justifyContent="space-between" doubleItem>
                    <ZipCodeInputElement optional hasOptionalText />
                    <CityInputElement optional hasOptionalText />
                </AppFormItem>
            </AppFormItemGroup>
            <AppFormItemGroup title={childrenTexts.childForm.settlementData.settlementData}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <AccountingPeriodDatePickerElement mode="start" />
                    <AccountingPeriodDatePickerElement mode="finish" />
                </AppFormItem>
            </AppFormItemGroup>
            {/*TODO: check if institution has settlements per hour*/}
            <AppFormItemGroup title={childrenTexts.childForm.settlementData.declaredStayHours}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <DeclaredStayHoursTimePicker mode="start" />
                    <DeclaredStayHoursTimePicker mode="finish" />
                </AppFormItem>
            </AppFormItemGroup>
            <DietPicker httpMethod={{ getDiets: staffHTTPService.diets.getDiets }} />
            <DiseasesInputElement optional hasOptionalText />
            <AllergensInputElement optional hasOptionalText />
            <SocialNetworksImageSharingAgreementPicker />
            <FormNavButtons
                formValuesBeforeChanges={formInitialData}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
