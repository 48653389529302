import { SpecialJournalAddEditForm } from 'components/templates/journal';
import { appPaths } from 'consts/paths/paths';
import { journalTexts } from 'consts/text';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { SpecialJournalFormFields } from 'types/forms/journal';
import { parseSpecialJournalFormFieldsIntoAddSpecialJournalDTO } from 'utils/parsers/journal/parseSpecialJournalFormFieldsIntoAddUpdateSpecialJournalDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const SpecialJournalAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const onAddSpecialJournalSuccess = () => {
        displaySuccess(journalTexts.journals.addSpecialJournalSuccess);
        statefulNavigate(appPaths.app.journals.base, {
            replace: true,
        });
    };

    const {
        mutate: addSpecialJournal,
        error: addSpecialJournalError,
        isLoading: isAddSpecialJournalLoading,
    } = useAppMutation(StaffHTTPService.journals.addSpecialJournal, {
        key: ['ADD_SPECIAL_JOURNAL'],
        onSuccess: onAddSpecialJournalSuccess,
        invalidateQueryKeys: [['JOURNALS'], ['LESSONS_JOURNALS'], ['MAIL_THREAD_RECEIVERS']],
    });

    const onAddSpecialJournalFinish = (journalData: SpecialJournalFormFields) => {
        if (!journalData.childIdsToAdd) {
            return;
        }
        const parsedSpecialJournalDTO = parseSpecialJournalFormFieldsIntoAddSpecialJournalDTO(
            // typescript childIdsToAdd issue solution
            { ...journalData, childIdsToAdd: journalData.childIdsToAdd },
        );
        addSpecialJournal(parsedSpecialJournalDTO);
    };

    return (
        <SpecialJournalAddEditForm
            mode="add"
            onFinish={onAddSpecialJournalFinish}
            axiosError={addSpecialJournalError}
            isLoading={isAddSpecialJournalLoading}
        />
    );
};
