import { InstitutionBasicDto } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { getInstitutionTypeLabel } from 'utils/getInstitutionTypeLabel';

export const parseInstitutionBasicDtoIntoJsonObject: (
    institutionBasicDto: InstitutionBasicDto,
) => object = (institutionBasicDto) => {
    const { name, regon, rspo, street, zipCode, city, type, publicInstitution } =
        institutionBasicDto;

    const institutionTypeLabel = getInstitutionTypeLabel(type);

    return {
        [jsonObjectsTexts.institutionDto.fields.name]: name,
        [jsonObjectsTexts.institutionDto.fields.regon]: regon,
        [jsonObjectsTexts.institutionDto.fields.rspo]: rspo,
        [jsonObjectsTexts.institutionDto.fields.street]: street,
        [jsonObjectsTexts.institutionDto.fields.zipCode]: zipCode,
        [jsonObjectsTexts.institutionDto.fields.city]: city,
        [jsonObjectsTexts.institutionDto.fields.publicInstitution]: publicInstitution
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.institutionDto.fields.type]: institutionTypeLabel,
    };
};
