import { commonTexts } from 'consts/text/index';
import { StyledBlueFolderIcon, StyledRedFolderIcon } from 'components/atoms/StyledIcons';
import { AppConfirmModal, AppConfirmModalProps } from '../../AppConfirmModal/AppConfirmModal';

type ArchiveRestoreModalProps = Pick<
    AppConfirmModalProps,
    'open' | 'title' | 'confirmActionLoading' | 'onOk' | 'onCancel'
> & { isArchive?: boolean };

export const ArchiveRestoreModal = ({ isArchive = true, ...props }: ArchiveRestoreModalProps) => (
    <AppConfirmModal
        icon={isArchive ? <StyledRedFolderIcon /> : <StyledBlueFolderIcon />}
        okButtonProps={{ title: commonTexts.actionLabels.confirm, danger: isArchive }}
        cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
        {...props}
    />
);
