import { useMemo } from 'react';
import { ChildBillComponent, ChildCostAllocationBasic, MonthlySettlementSimpleDTO } from 'types';
import { DetailsColumn, DetailsFrameBase, DetailsRow } from 'components/atoms';
import {
    GridItem,
    ParentGrid,
} from 'components/templates/child/ChildDetails/InfoContainerBase.styled';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { ChildrenBillComponentsTable } from 'components/templates/settlement/ChildrenBillPartsTable/ChildrenBillComponentsTable';
import { useTheme } from 'styled-components';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { settlementTexts } from 'consts/text';
import { parseObjectsIntoSettlementArray } from 'utils/parsers/settlementChildren/parseObjectsIntoSettelmentArray';
import { AppCardList } from 'components/organisms';
import { referToMapper } from 'consts/settlementChildren/referToMapper';
import { getBillTitle } from 'utils/getBillTitle';
import { getDateRangeText } from 'utils/getDateRangeText';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const ParentSettlementItem = ({
    startDate,
    endDate,
    totalCost,
    mealSettlement,
    lessonSettlement,
    staySettlement,
    costSplits,
    child,
}: MonthlySettlementSimpleDTO & { costSplits?: ChildCostAllocationBasic[] }) => {
    const theme = useTheme();

    const billComponents: ChildBillComponent[] | null = useMemo(
        () =>
            parseObjectsIntoSettlementArray({
                staySettlement,
                lessonSettlement,
                mealSettlement,
            }),
        [staySettlement, mealSettlement, lessonSettlement],
    );

    const parsedStartDate = parseStringIntoFormattedStringDate(startDate);
    const parsedToMonthDate = parseStringIntoFormattedStringDate(
        startDate,
        'YYYY-MM-DD',
        'MM/YYYY',
    );
    const parsedEndDate = parseStringIntoFormattedStringDate(endDate);

    const settlementCalculated =
        mealSettlement.mealCosts.length ||
        lessonSettlement.lessonCosts.length ||
        staySettlement.daysPriceSummaries?.length ||
        staySettlement.hoursPriceSummaries?.length;

    return (
        <DetailsFrameBase
            title={getDateRangeText(parsedStartDate, parsedEndDate)}
            smallHeaderMargin
        >
            <ParentGrid>
                <GridItem width={12}>
                    <DetailsFrameBase
                        title={settlementTexts.children.details.monthlyBill}
                        headerColor={theme.colors.primary.primary9}
                        noFrame
                        smallHeaderMargin
                        noMarginLeft
                    >
                        <DetailsRow noMarginTop>
                            <DetailsColumn
                                title={settlementTexts.children.details.billTitle}
                                span={8}
                            >
                                {getBillTitle(parsedToMonthDate, child)}
                            </DetailsColumn>
                            <DetailsColumn
                                title={settlementTexts.children.details.issueData}
                                span={4}
                            >
                                {settlementCalculated ? parsedStartDate : EMPTY_VALUE_LABEL}
                            </DetailsColumn>
                            <DetailsColumn
                                title={settlementTexts.children.details.amountToPay}
                                span={4}
                            >
                                {settlementCalculated
                                    ? getFormattedPrice(totalCost)
                                    : EMPTY_VALUE_LABEL}
                            </DetailsColumn>
                        </DetailsRow>
                    </DetailsFrameBase>
                </GridItem>
                <GridItem width={12}>
                    <DetailsFrameBase
                        title={settlementTexts.children.details.billComponents}
                        headerColor={theme.colors.primary.primary9}
                        noFrame
                        smallHeaderMargin
                        noMarginLeft
                    >
                        <ChildrenBillComponentsTable billComponents={billComponents} />
                    </DetailsFrameBase>
                </GridItem>
                <GridItem width={12}>
                    <AppCardList
                        isHiddenAddButton
                        isHiddenHeader
                        marginTop={16}
                        bordered={false}
                        emptyInfo={settlementTexts.children.costAllocation.emptyInfo}
                        dataSource={costSplits}
                        renderItem={(item, index) => (
                            <DetailsFrameBase
                                key={index}
                                smallHeaderMargin
                                title={settlementTexts.children.details.costSplit}
                                noMarginLeft
                                headerColor={theme.colors.primary.primary9}
                            >
                                <DetailsRow>
                                    <DetailsColumn
                                        title={settlementTexts.children.details.foundationName}
                                        span={4}
                                    >
                                        {item.foundationName}
                                    </DetailsColumn>
                                    <DetailsColumn
                                        title={settlementTexts.children.details.nip}
                                        span={4}
                                    >
                                        {item.nip}
                                    </DetailsColumn>
                                    <DetailsColumn
                                        title={settlementTexts.children.details.refersTo}
                                        span={4}
                                    >
                                        {referToMapper[item.refersTo]}
                                    </DetailsColumn>
                                    <DetailsColumn
                                        title={settlementTexts.children.details.amount}
                                        span={4}
                                    >
                                        {item.specificAmount
                                            ? getFormattedPrice(item.specificAmount)
                                            : `${item.percentageAmount}%`}
                                    </DetailsColumn>
                                </DetailsRow>
                            </DetailsFrameBase>
                        )}
                    />
                </GridItem>
            </ParentGrid>
        </DetailsFrameBase>
    );
};
