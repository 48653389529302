import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        height: 100%;
        overflow: auto;
        padding-right: ${theme.sizes.marginSmall}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            padding-right: ${theme.sizes.marginNormal}px;
        }
    `}
`;

export const StateDisplayContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
