import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import styled, { css } from 'styled-components';
import { MarginsProps } from 'types/components/margins';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

const { Title } = Typography;

type AppTitleProps = TitleProps & MarginsProps & { textAlign?: 'right' | 'left' | 'center' };

const filteredProps = marginPropsToFilter.concat('textAlign');

const commonStyles = css`
    font-weight: 700;
    line-height: 120%;
`;

const StyledTitle = styled(Title).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppTitleProps>`
    ${({ textAlign = 'left', ...props }) => css`
        text-align: ${textAlign};

        &.ant-typography {
            ${getMarginsCss(props)}
        }

        &:is(h1) {
            font-size: 42px;
            letter-spacing: -1.26px;
            ${commonStyles}
        }
        &:is(h2) {
            font-size: 35px;
            letter-spacing: -1.05px;
            ${commonStyles}
        }
        &:is(h3) {
            font-size: 28px;
            letter-spacing: -0.84px;
            ${commonStyles}
        }
        &:is(h4) {
            font-size: 22px;
            letter-spacing: -0.66px;
            ${commonStyles}
        }
        &:is(h5) {
            font-size: 20px;
            letter-spacing: -0.6px;
            ${commonStyles}
        }
    `}
`;

export const AppTitle = (props: AppTitleProps) => <StyledTitle {...props} />;
