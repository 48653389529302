import { formatIncompletePhoneNumber } from 'libphonenumber-js/min';
import { ChildParent } from 'types';
import { AppParagraph } from 'components/atoms';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const ParentsPhoneNumbersDisplay = ({ parents }: { parents: ChildParent[] }) => {
    if (!parents?.length) {
        return EMPTY_VALUE_LABEL;
    }

    return parents.map(({ id, phoneNumber }) => (
        <AppParagraph key={id} margin={0}>
            {phoneNumber ? formatIncompletePhoneNumber(phoneNumber, 'PL') : EMPTY_VALUE_LABEL}
        </AppParagraph>
    ));
};
