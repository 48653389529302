import { ChildDetailed, ChildMealType, EditChildFormFields } from 'types';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { childMealTypesArray } from 'consts/child/childMealTypes';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseChildIntoChildEditFormFields: (
    childData: ChildDetailed,
) => EditChildFormFields = (childData) => {
    const {
        firstName,
        lastName,
        pesel: peselNumber,
        sex,
        birthDate,
        birthPlace,
        address,
        groups,
        diseases,
        allergies,
        agreements,
    } = childData.child;

    const journalGroupId = groups.find(
        (group) => group.type === 'JOURNAL_GROUP' && !group.leftGroup,
    );

    const dietId = childData?.diet?.institutionDiet?.id;
    const selectedMeals = childData?.diet
        ? Object.entries(childData?.diet)
              .filter(
                  ([key, value]) =>
                      value === true && childMealTypesArray.includes(key as ChildMealType),
              )
              .map(([key]) => key)
        : null;

    return {
        firstName,
        lastName,
        peselNumber,
        sex,
        birthDate: parseStringIntoDayjsDate(birthDate),
        birthPlace,
        street: address?.street || '',
        city: address?.city || '',
        zipCode: address?.zipCode || '',
        groupName: journalGroupId?.id,
        declaredStayHoursFromTime: childData.settlementDetails?.stayStart
            ? parseStringIntoDayjsTime(childData.settlementDetails.stayStart)
            : null,
        declaredStayHoursToTime: childData.settlementDetails?.stayEnd
            ? parseStringIntoDayjsTime(childData.settlementDetails.stayEnd)
            : null,
        accountingPeriodStartDate: childData.settlementDetails?.periodStart
            ? parseStringIntoDayjsDate(childData.settlementDetails.periodStart)
            : null,
        accountingPeriodFinishDate: childData.settlementDetails?.periodEnd
            ? parseStringIntoDayjsDate(childData.settlementDetails.periodEnd)
            : null,
        ...(dietId && { diet: dietId }),
        ...(selectedMeals && { selectMealsType: selectedMeals as ChildMealType[] }),
        diseases: diseases || '',
        allergens: allergies || '',
        socialNetworksImageSharingAgreementInstagram: String(agreements.instagram),
        socialNetworksImageSharingAgreementFacebook: String(agreements.facebook),
        socialNetworksImageSharingAgreementTikTok: String(agreements.tiktok),
        socialNetworksImageSharingAgreementWebsite: String(agreements.website),
    };
};
