import { generatePath, useParams } from 'react-router-dom';
import { ChildId, JournalId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const useChildrenDetailsPath = () => {
    const { journalId } = useParams<{ journalId: JournalId }>();

    const { data: journalData } = useAppQuery(
        'JOURNAL',
        [journalId],
        () => StaffHTTPService.journals.getJournal(journalId as JournalId),
        {
            enabled: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const getChildrenDetailsPath = (pathString: string, childId: ChildId) => {
        if (!journalData) {
            return generatePath(appPaths.app.children.details.pickedChild.base + pathString, {
                childId,
            });
        } else if (journalData.type === 'GROUP') {
            return generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.children.details.pickedChild
                    .base + pathString,
                { childId, journalId },
            );
        }
        return generatePath(
            appPaths.app.journals.specialJournalDetails.pickedJournal.children.details.pickedChild
                .base + pathString,
            { childId, journalId },
        );
    };

    return {
        getChildrenDetailsPath,
    };
};
