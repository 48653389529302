import { getAppMenuItem, getIconWithBookmark, MenuItem } from 'components/atoms';
import { routesPl } from 'consts/paths/routesPl';
import { appPaths, LOGOUT_PATH } from 'consts/paths/paths';
import { AccountModuleIcon, LogOutModuleIcon } from 'assets';
import { authTexts } from 'consts/text';

const logoutMenuItems = getAppMenuItem(
    authTexts.logoutButtonLabel,
    LOGOUT_PATH,
    getIconWithBookmark(<LogOutModuleIcon />),
);

export const staffAccountManageMenuItems: MenuItem[] = [
    getAppMenuItem(
        routesPl.app.account.base,
        appPaths.app.account.base,
        getIconWithBookmark(<AccountModuleIcon />),
    ),
    logoutMenuItems,
];

export const parentAccountManageMenuItems: MenuItem[] = [
    getAppMenuItem(
        routesPl.app.parent.account.base,
        appPaths.app.parent.account.base,
        getIconWithBookmark(<AccountModuleIcon />),
    ),
    logoutMenuItems,
];

export const adminAccountManageMenuItems: MenuItem[] = [logoutMenuItems];
