import styled from 'styled-components';
import { AppSelect } from 'components/atoms/CommonAppComponents/AppSelect/AppSelect';

export const StyledSelect = styled(AppSelect)`
    .ant-select-selector .ant-select-selection-item {
        padding-inline-end: 180px;
    }
`;

export const LabelContainer = styled.span`
    display: flex;
    align-items: center;
    gap: 10px;
`;
