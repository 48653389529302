import { settingsTexts } from 'consts/text';
import { historyChangeTypeLabels } from 'consts/historyChange/historyChangeTypeLabels';
import { historyChangeRefersToLabels } from 'consts/historyChange/historyChangeRefersToLabels';

export const historyChangesFiltersOptions = [
    {
        title: settingsTexts.historyOfChanges.historyType,
        value: 'historyTypes',
        checkable: false,
        expandable: false,
        children: Object.entries(historyChangeTypeLabels).map(([key, label]) => ({
            label,
            value: key,
        })),
    },
    {
        title: settingsTexts.historyOfChanges.columnNames.historyChangeRefersTo,
        value: 'referTo',
        checkable: false,
        children: Object.entries(historyChangeRefersToLabels).map(([key, label]) => ({
            label,
            value: key,
        })),
    },
].map((option) => ({
    ...option,
    children: option.children.map((child) => ({
        ...child,
        value: `${option.value}/${child.value}`,
    })),
}));
