import { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const iconMarginLeft = 24;

export const inlineItemStyle = css`
    ${({ theme }) => css`
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 0 !important;
        color: ${theme.colors.primary.primary3};
        cursor: pointer;
        margin: 0;
        overflow: hidden;

        &.ant-menu-item:not(.ant-menu-item-selected):hover {
            background-color: ${theme.colors.primary.primary3};
        }
        &.ant-menu-submenu-title:hover {
            background-color: ${theme.colors.primary.primary3};
        }

        .ant-menu-item-icon {
            height: 100%;

            .menu-bookmark {
                display: none;
            }
            svg {
                fill: ${theme.colors.grayscale.gray10};
            }
        }

        .ant-menu-title-content {
            height: unset !important;
            color: ${theme.colors.grayscale.gray10};
            ${getTextStyle('BodyMedium')};
            line-height: 1;
        }
    `}
`;

export const selectedInlineItemStyle = css`
    ${({ theme }) => css`
        background-color: ${theme.colors.primary.primary3};

        .ant-menu-title-content {
            color: ${theme.colors.primary.primary9};
            ${getTextStyle('BodyMediumSemiBold')}
        }

        .ant-menu-item-icon {
            .menu-bookmark {
                display: unset;
            }
            .subitem-dot-icon {
                background-color: ${theme.colors.primary.primary9};
            }
            svg {
                fill: ${theme.colors.primary.primary9};
            }
        }
    `}
`;

export const collapsedInlineItemStyle = css`
    width: unset;
    padding: 10px ${iconMarginLeft}px !important;
    .ant-menu-item-icon {
        min-width: 20px;
    }
`;

export const inlineMenuStyle = css`
    border-inline-end: none !important;
    width: unset;

    .ant-menu-item {
        ${inlineItemStyle}
    }

    .ant-menu-item-selected {
        ${selectedInlineItemStyle}
    }

    &.ant-menu-inline-collapsed {
        .ant-menu-item {
            ${collapsedInlineItemStyle}
        }
    }
`;

export const inlineAppMenuStyles = css`
    &.ant-menu.ant-menu-root {
        &.ant-menu-inline,
        &.ant-menu-vertical {
            ${inlineMenuStyle}
        }
    }
`;
