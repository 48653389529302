import { Navigate, RouteObject } from 'react-router-dom';
import { JournalsPage, SpecialJournalAddPage, SpecialJournalEditPage } from 'pages';
import { appPaths } from 'consts/paths/paths';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { groupJournalRoutes } from './groupJournal/groupJournal';
import { specialJournalRoutes } from './specialJournal/specialJournal';

export const journalRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW_MODULE,
                    subject: STAFF_APP_MODULES.JOURNALS,
                }}
            >
                <JournalsPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.DO_ACTION,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_MANAGE,
                }}
            >
                <SpecialJournalAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.edit.base,
        element: <Navigate to={appPaths.app.journals.base} />,
    },
    {
        path: appPaths.app.journals.edit.pickedJournal,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.DO_ACTION,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_MANAGE,
                }}
            >
                <SpecialJournalEditPage />
            </AbilityRoute>
        ),
    },
    ...groupJournalRoutes,
    ...specialJournalRoutes,
];
