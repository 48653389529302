import { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const cellContentStyle = css`
    ${({ theme }) => css`
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;
        align-items: center;
        ${getTextStyle('BodyMedium')};
        color: ${theme.colors.grayscale.gray10};
    `}
`;
