import type { DayAttendanceType } from 'types';
import { TableTagDisplay } from 'components/molecules/Table/TableTagDisplay/TableTagDisplay';
import { dayAttendanceAbsentTypesLabels } from 'consts/attendance/dayAttendanceAbsentTypesLabels';
import { tagColorsByDayAttendanceAbsentType } from 'consts/attendance/tagColorsByDayAttendanceAbsentType';
import { AppText } from 'components/atoms';
import { NO_TIME } from 'consts/timeConsts';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { StyledClockIcon, TimeContainer } from './ParentDailyAttendanceTypeDisplay.styled';

type ParentDailyAttendanceTypeDisplayProps = {
    type: DayAttendanceType | null;
    time: string | null;
};

export const ParentDailyAttendanceTypeDisplay = ({
    type,
    time,
}: ParentDailyAttendanceTypeDisplayProps) => {
    if (type === null || type === 'PRESENT') {
        const timeText = time ? parseStringIntoFormattedStringTime(time) : NO_TIME;
        return (
            <TimeContainer>
                <AppText textType="BodyMSRegular" margin={0}>
                    {timeText}
                </AppText>
                {<StyledClockIcon />}
            </TimeContainer>
        );
    }

    return (
        <TableTagDisplay
            value={type}
            labelMapper={dayAttendanceAbsentTypesLabels}
            colorMapper={tagColorsByDayAttendanceAbsentType}
        />
    );
};
