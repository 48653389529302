import { generatePath } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { DualValueCellContent } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { ImportantEventId, JournalId } from 'types';
import { LinkDisplay } from '../LinkDisplay/LinkDisplay';

type ImportantEventNameDisplayProps = {
    name: string;
    date: string;
    journalId: JournalId;
    importantEventId: ImportantEventId;
};

export const ImportantEventNameDisplay = ({
    name,
    date,
    journalId,
    importantEventId,
}: ImportantEventNameDisplayProps) => {
    const isMobile = useIsMobile();

    const parsedDate = parseStringIntoFormattedStringDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY');
    const importantEventDetailsPagePath = generatePath(
        appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.details
            .pickedImportantEvent,
        {
            journalId,
            importantEventId,
        },
    );

    return isMobile ? (
        <DualValueCellContent mainValue={name} secondaryValue={parsedDate} />
    ) : (
        <LinkDisplay to={importantEventDetailsPagePath}>{name}</LinkDisplay>
    );
};
