import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppModalButton, DetailsItem } from 'components/atoms';
import { AppModal } from 'components/molecules/AppModal/AppModal';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, timetableTexts } from 'consts/text';
import { generatePath } from 'react-router-dom';
import { AbsenceDTO } from 'types';
import { getFullName } from 'utils/getFullName';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { getAbsenceTypeLabel } from 'consts/absence/absenceType';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { Container, DataRow } from '../LessonDetailsModal/LessonDetailsModal.styled';

type AbsenceDetailsModalProps = {
    open: boolean;
    absence: AbsenceDTO;
    editable?: boolean;
    onCancel: () => void;
    onRemove: () => void;
};

export const AbsenceDetailsModal = ({
    open,
    absence,
    editable,
    onCancel,
    onRemove,
}: AbsenceDetailsModalProps) => {
    const { statefulNavigate } = useNavigateWithState();
    const { startDate, endDate, firstName, lastName, type, description, id, paid } = absence;

    const navigateToAbsenceEdit = () =>
        statefulNavigate(
            generatePath(appPaths.app.timetable.employeeSchedule.absence.edit.pickedAbsence, {
                absenceId: id,
            }),
        );

    const footer = (
        <>
            <AppModalButton type="text" icon={<IconTrash />} onClick={onRemove}>
                {commonTexts.actionLabels.delete}
            </AppModalButton>
            <AppModalButton type="primary" icon={<IconEdit />} onClick={navigateToAbsenceEdit}>
                {commonTexts.actionLabels.edit}
            </AppModalButton>
        </>
    );

    const teacherFullName = absence ? getFullName(firstName, lastName) : EMPTY_VALUE_LABEL;
    const startDateParsed = parseStringIntoFormattedStringDate(startDate);
    const endDateParsed = parseStringIntoFormattedStringDate(endDate);
    const absenceTypeAndPaymentStatus = `${getAbsenceTypeLabel(type)} (${
        paid ? timetableTexts.absence.paid : timetableTexts.absence.unpaid
    })`;

    return (
        <AppModal
            open={open}
            onCancel={onCancel}
            title={timetableTexts.absence.absencePreview}
            footer={editable ? footer : null}
        >
            <Container>
                <DataRow>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.employee}>
                        {teacherFullName}
                    </DetailsItem>
                </DataRow>

                <DataRow>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.startDate}>
                        {startDateParsed}
                    </DetailsItem>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.endDate}>
                        {endDateParsed}
                    </DetailsItem>
                </DataRow>

                <DataRow>
                    <DetailsItem title={timetableTexts.absence.absenceType}>
                        {absenceTypeAndPaymentStatus}
                    </DetailsItem>
                </DataRow>
                <DataRow>
                    <DetailsItem title={commonTexts.dataLabels.description}>
                        {description}
                    </DetailsItem>
                </DataRow>
            </Container>
        </AppModal>
    );
};
