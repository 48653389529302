import { AddUpdateMenuDTO, MenuFormFields } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseMenuFormFieldsIntoAddUpdateMenuDTO: (
    menuFormFields: MenuFormFields,
) => AddUpdateMenuDTO = ({ dateFrom, dateTo, ...restFormFields }) => ({
    ...restFormFields,
    dateFrom: parseDayjsDateIntoString(dateFrom, 'YYYY-MM-DD'),
    dateTo: parseDayjsDateIntoString(dateTo, 'YYYY-MM-DD'),
});
