import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
        margin-top: 20px;
        padding-block: 20px;
        border-block-start: 1px solid ${theme.colors.primary.primary3};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            margin-top: 18px;
            padding-block: 18px;
        }
    `}
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
