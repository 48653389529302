import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export const PageContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['stickToTop']),
})<{ stickToTop?: boolean }>`
    ${({ theme, stickToTop }) => css`
        display: flex;
        flex-direction: row;
        overflow-y: auto;

        ${!stickToTop &&
        css`
            flex: 1;
        `}

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            align-items: center;
            padding: 16px;
        }
    `}
`;

export const ContentContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['fillSpaceWithIsland']),
})<{ fillSpaceWithIsland?: boolean }>`
    ${({ theme, fillSpaceWithIsland }) => css`
        background-color: ${theme.colors.grayscale.gray1};
        padding: 24px;
        position: relative;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
            overflow: auto;
        }

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            ${fillSpaceWithIsland
                ? css`
                      display: flex;
                      flex: 1;
                      flex-direction: column;
                  `
                : ''}
            border: 1px solid ${theme.colors.primary.primary4};
            border-radius: 12px;
            min-width: 100px;
            justify-self: center;
            margin: auto;
        }
    `}
`;
