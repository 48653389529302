import { AppList, AppText } from 'components/atoms';
import { settingsTexts } from 'consts/text';
import { StyledList } from './SubscriptionPaymentDetails.styled';
import { getFormattedPrice } from '../../../../../utils/getFormattedPrice';

type PaymentDetailsProps = { childLimit: number; price: number };

export const SubscriptionPaymentDetails = ({ childLimit, price }: PaymentDetailsProps) => (
    <>
        <AppText textType="BodyMedium" margin={0} marginBottom={16} marginTop={16}>
            {settingsTexts.accountType.paymentEnabled}
        </AppText>
        <AppList
            dataSource={[
                {
                    title: settingsTexts.accountType.childrenLimit,
                    description: childLimit,
                },
                {
                    title: settingsTexts.accountType.paymentAmountPerMonth,
                    description: getFormattedPrice(price),
                },
            ]}
            renderItem={(item) => (
                <StyledList>
                    <li>
                        <AppText textType="BodyMedium" margin={0}>{`${item.title} - `}</AppText>
                        <AppText
                            textType="BodyMediumSemiBold"
                            margin={0}
                        >{`${item.description}`}</AppText>
                    </li>
                </StyledList>
            )}
        />
    </>
);
