import styled, { css } from 'styled-components';
import { BorderedContainerStyled } from 'components/atoms';

export const TextRow = styled.div`
    margin-bottom: 12px;

    &:not(:empty) {
        display: flex;
        gap: 6px;
        align-items: center;
    }
`;

export const HistoryChangeContainer = styled(BorderedContainerStyled)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 12px;
        background-color: ${theme.colors.primary.primary2};
        max-height: 480px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {
            border-radius: ${theme.borders.borderRadiusNormal}px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.primary.primary5};
            border: 4px solid transparent;
            border-radius: ${theme.borders.borderRadiusNormal}px;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            max-height: calc(100vh - 300px);
        }
    `}
`;
