import { formFields } from 'consts/form/formFields';

export const socialNetworksFormFields = {
    facebook: formFields.socialNetworksImageSharingAgreementFacebook,
    instagram: formFields.socialNetworksImageSharingAgreementInstagram,
    tiktok: formFields.socialNetworksImageSharingAgreementTikTok,
    website: formFields.socialNetworksImageSharingAgreementWebsite,
} as const;

export type SocialNetworksFormFieldsValues =
    (typeof socialNetworksFormFields)[keyof typeof socialNetworksFormFields];
