import { generatePath } from 'react-router-dom';
import { IconSend, IconUsers } from '@tabler/icons-react';
import { AppButton, AppParagraph } from 'components/atoms';
import { messagesTexts } from 'consts/text';
import { MailThreadId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { isStaffUserType } from 'utils/isStaffUserType';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import {
    DescriptionContainer,
    OuterContainer,
} from './GroupMailThreadBlockingSendMessageFormInfo.styled';

type GroupMailThreadBlockingSendMessageFormInfoProps = {
    mailThreadId: MailThreadId;
    replyMailThreadId?: MailThreadId;
};

export const GroupMailThreadBlockingSendMessageFormInfo = ({
    mailThreadId,
    replyMailThreadId,
}: GroupMailThreadBlockingSendMessageFormInfoProps) => {
    const { type: userType } = useUserDetails();
    const { statefulNavigate } = useNavigateWithState();
    const isMobile = useIsMobile();

    const isParentUserType = !isStaffUserType(userType);

    const handleReplyButtonClick = () => {
        if (replyMailThreadId) {
            const path = isParentUserType
                ? appPaths.app.parent.messages.pickedMailThread
                : appPaths.app.messages.pickedMailThread;
            statefulNavigate(generatePath(path, { mailThreadId: replyMailThreadId }));
            return;
        }
        const path = isParentUserType
            ? appPaths.app.parent.messages.add
            : appPaths.app.messages.add;
        statefulNavigate(path, {
            state: { mailThreadId },
        });
    };

    return (
        <OuterContainer>
            <DescriptionContainer>
                <IconUsers size={20} />
                <div>
                    <AppParagraph margin={0}>
                        {messagesTexts.blockingSendMessageFormTextFirstLine}
                    </AppParagraph>
                    <AppParagraph margin={0}>
                        {messagesTexts.blockingSendMessageFormTextSecondLine}
                    </AppParagraph>
                </div>
            </DescriptionContainer>
            <AppButton
                type="primary"
                icon={<IconSend size={20} />}
                margin={0}
                fullWidth={isMobile}
                onClick={handleReplyButtonClick}
            >
                {messagesTexts.reply}
            </AppButton>
        </OuterContainer>
    );
};
