import { IconCopy } from '@tabler/icons-react';
import { AppText } from 'components/atoms/CommonAppComponents';
import { Row } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts } from 'consts/text';

type CopyContentTextProps = {
    text: string;
};

const StyledRow = styled(Row)`
    ${({ theme }) => css`
        cursor: pointer;
        margin: 2px;
        &:focus {
            background-color: ${theme.colors.grayscale.gray3};
        }
    `}
`;

export const CopyContentText = ({ text }: CopyContentTextProps) => {
    const theme = useTheme();
    const { displaySuccess } = useSimpleNotification();

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(text)
            .then(() => displaySuccess(commonTexts.successMessages.copiedToClipboard));
    };

    return (
        <StyledRow
            // @ts-ignore
            tabindex="0"
            align="middle"
            onClick={copyToClipboard}
        >
            <AppText>{text}</AppText> <IconCopy color={theme.colors.grayscale.gray6} />
        </StyledRow>
    );
};
