import { MealPaymentType } from '../settlementMeals';

export enum MethodOfCalcTheFee {
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
}

export type SettlementStaysDTO = {
    settlementType: MealPaymentType;
    paymentType: MethodOfCalcTheFee;
    freeHours: boolean | null;
    price: number | null;
    freeHoursAmount: number | null;
    freeHoursTimeFrame: boolean | null;
    freeHoursStart: string | null;
    freeHoursEnd: string | null;
};

export type UpdateSettlementStaysDTO = SettlementStaysDTO;
