import { Space } from 'antd';
import { UserEdit } from 'assets';
import { AppModalButton, AppRadio, AppRadioGroup } from 'components/atoms';
import { AppModal, AppModalProps } from 'components/molecules/AppModal/AppModal';
import { commonTexts, timetableTexts } from 'consts/text';
import { Dispatch, SetStateAction } from 'react';
import { colors } from 'theme/styledComponents/colors';

type AffectNextModalProps = Pick<AppModalProps, 'open' | 'onCancel'> & {
    onConfirm: (affectNext: boolean) => void;
    affectNext: boolean;
    setAffectNext: Dispatch<SetStateAction<boolean>>;
};
export const EditAffectNextLessonsModal = ({
    open,
    onCancel,
    onConfirm,
    affectNext,
    setAffectNext,
}: AffectNextModalProps) => {
    const footer = (
        <>
            <AppModalButton fullWidth type="default" onClick={onCancel}>
                {commonTexts.actionLabels.cancel}
            </AppModalButton>
            <AppModalButton fullWidth type="primary" onClick={() => onConfirm(affectNext)}>
                {commonTexts.actionLabels.edit}
            </AppModalButton>
        </>
    );

    return (
        <AppModal
            icon={<UserEdit style={{ color: colors.primary.primary10 }} />}
            title={timetableTexts.lessons.editAffectNextModalTitle}
            titleLevel={5}
            open={open}
            onCancel={onCancel}
            footer={footer}
            closable={false}
            width={400}
            isMobileFullScreenMode={false}
        >
            <AppRadioGroup
                value={affectNext}
                fullWidth={false}
                onChange={(event) => setAffectNext(event.target.value)}
            >
                <Space direction="vertical" size={0}>
                    <AppRadio value={false}>{timetableTexts.lessons.currentLesson}</AppRadio>
                    <AppRadio value>{timetableTexts.lessons.currentAndNextLessons}</AppRadio>
                </Space>
            </AppRadioGroup>
        </AppModal>
    );
};
