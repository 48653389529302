import styled, { css } from 'styled-components';
import { IconFidgetSpinner } from '@tabler/icons-react';

export type AppLoaderProps = { size?: number; color?: string };

const IconFidgetSpinnerStyled = styled(IconFidgetSpinner)<AppLoaderProps>`
    ${({ theme, color }) => css`
        color: ${color || theme.colors.primary.primary9};
        align-self: center;
        animation: spinner 1.2s linear infinite;
        @keyframes spinner {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `}
`;

const BlockingComponent = styled.div`
    ${({ theme }) => css`
        position: absolute;
        display: flex;
        z-index: 1000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${theme.colors.backdrop};
        overflow: hidden;
        align-items: center;
        justify-content: center;
        border-radius: ${theme.borders.borderRadiusLarge}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            position: fixed;
        }
    `}
`;

export const AppLoader = ({ size = 40, color }: AppLoaderProps) => (
    <IconFidgetSpinnerStyled size={size} color={color} />
);
export const ComponentBlockingAppLoader = ({ size = 80, color }: AppLoaderProps) => (
    <BlockingComponent>
        <IconFidgetSpinnerStyled size={size} color={color} />
    </BlockingComponent>
);
