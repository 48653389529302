import { UpdateOwnerDTO, OwnerAndEmployeeAccountFormFields } from 'types';
import { parseFormattedPhoneNumberIntoPhoneNumber } from 'utils/parsers/phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';

export const parseOwnerAndEmployeeFormFieldsIntoUpdateOwnerDTO: (
    ownerAndEmployeeFormFields: OwnerAndEmployeeAccountFormFields,
) => UpdateOwnerDTO = (ownerAndEmployeeFormFields) => ({
    firstName: ownerAndEmployeeFormFields.firstName,
    lastName: ownerAndEmployeeFormFields.lastName,
    phoneNumber: parseFormattedPhoneNumberIntoPhoneNumber(ownerAndEmployeeFormFields.phoneNumber),
});
