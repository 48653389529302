import { ReactNode } from 'react';
import { StyledEditIcon, StyledPlusIcon } from 'components/atoms';
import { SelectOptionType, ScheduleType } from 'types';
import { appPaths } from 'consts/paths/paths';
import { journalTexts } from 'consts/text';

type ScheduleTypeTexts = {
    emptyInfo: string;
    emptyAdditionalInfo: string;
};

type ButtonActionMode = {
    buttonText: string;
    buttonIcon: ReactNode;
};

export const dailyScheduleTypeTexts: ScheduleTypeTexts = {
    emptyInfo: journalTexts.journalDetails.groupJournal.schedules.dailySchedule.noDailySchedule,
    emptyAdditionalInfo:
        journalTexts.journalDetails.groupJournal.schedules.dailySchedule.addDailySchedule,
};

export const weeklyScheduleTypeTexts: ScheduleTypeTexts = {
    emptyInfo: journalTexts.journalDetails.groupJournal.schedules.weeklySchedule.noWeeklySchedule,
    emptyAdditionalInfo:
        journalTexts.journalDetails.groupJournal.schedules.weeklySchedule.addWeeklySchedule,
};

export const buttonAddScheduleMode: ButtonActionMode = {
    buttonText: journalTexts.journalDetails.groupJournal.schedules.addSchedule,
    buttonIcon: <StyledPlusIcon />,
};

export const buttonEditScheduleMode: ButtonActionMode = {
    buttonText: journalTexts.journalDetails.groupJournal.schedules.editSchedule,
    buttonIcon: <StyledEditIcon />,
};

export const scheduleOptions: SelectOptionType<ScheduleType>[] = [
    {
        label: journalTexts.journalDetails.groupJournal.schedules.daily,
        value: 'DAILY',
    },
    {
        label: journalTexts.journalDetails.groupJournal.schedules.weekly,
        value: 'WEEKLY',
    },
];

export const getSchedulePagePathToRedirect = (scheduleType: ScheduleType) => {
    if (scheduleType === 'DAILY') {
        return appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.base;
    }
    return appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.base;
};
