import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
        padding: 24px 16px;
        border-radius: ${theme.borders.borderRadiusLarge}px;
        border: 1px solid ${theme.colors.primary.primary5};
        background-color: ${theme.colors.primary.primary1};
        box-sizing: border-box;
    `}
`;

export const StyledTextEditor = styled(ReactQuill)`
    ${({ theme }) => css`
        .ql-toolbar {
            border: none;
        }
        .ql-container {
            border-radius: 8px;
            ${getTextStyle('BodyMedium')}
            color: ${theme.colors.grayscale.gray10};
            line-height: 140%;
            letter-spacing: 0.48px;

            .ql-editor {
                min-height: 300px;
                padding: 10px 16px;
            }
        }

        .ql-toolbar.ql-snow + .ql-container.ql-snow {
            border: 1px solid ${theme.colors.primary.primary5};
        }
    `}
`;
