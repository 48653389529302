import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { Rule } from 'rc-field-form/es/interface';
import styled, { css } from 'styled-components';
import { MarginsProps } from 'types/components/margins';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { OPTIONAL_FIELD_LABEL } from 'consts/labels/common';
import { formElementRequiredText } from 'consts/errors/forms';
import { getTextStyle } from 'utils/getTextStyle';
import { Label } from './components/Label';

const { Item } = Form;

export type AppFormItemProps = FormItemProps &
    MarginsProps & {
        justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
        doubleItem?: boolean;
        optional?: boolean;
        maxWidth?: number;
        hasOptionalText?: boolean;
        disabled?: boolean;
        isHalfParentWidth?: boolean;
        additionalText?: string;
    };
const filteredProps = marginPropsToFilter.concat(['justifyContent', 'doubleItem', 'maxWidth']);

export const StyledFormItem = styled(Item).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppFormItemProps>`
    ${({
        theme,
        doubleItem,
        justifyContent = 'flex-start',
        maxWidth,
        isHalfParentWidth,
        ...props
    }) => css`
        margin-bottom: ${theme.sizes.marginNormal}px;
        ${getMarginsCss(props)}

        ${doubleItem &&
        css`
            margin-bottom: 0;
            @media ${theme.sizes.mediaQueries.desktopMin} {
                .ant-form-item:first-child {
                    margin-right: ${theme.sizes.marginSmall}px;
                    flex: 1;
                }

                .ant-form-item:last-child {
                    margin-left: ${theme.sizes.marginSmall}px;
                    flex: 1;
                }
            }
        `}
        
        ${isHalfParentWidth &&
        css`
            @media ${theme.sizes.mediaQueries.desktopMin} {
                &.ant-form-item {
                    max-width: 50%;
                }
            }
        `}

        .ant-form-item-label > label {
            font-weight: 700;
            line-height: 120%;
            height: unset !important;
        }

        &.ant-form-item .ant-form-item-control-input {
            min-height: fit-content;
            ${maxWidth &&
            css`
                max-width: ${maxWidth}px;
            `}

            .ant-form-item-control-input-content {
                display: flex;
                justify-content: ${justifyContent};

                @media ${theme.sizes.mediaQueries.mobileMax} {
                    flex-direction: column;
                }
            }
        }

        &.ant-form-item .ant-form-item-extra {
            margin-block-start: ${theme.sizes.marginSmall}px;
            color: ${theme.colors.grayscale.gray8};
            ${getTextStyle('BodySmall')}
        }

        .ant-radio-button-wrapper {
            border: 1px solid ${theme.colors.primary.primary4};
            &:not(:first-child)::before {
                background-color: ${theme.colors.primary.primary4};
            }
            &.ant-radio-button-wrapper-checked {
                border: 1px solid ${theme.colors.primary.primary10};
                color: ${theme.colors.primary.primary10};
                &:not(:first-child)::before {
                    background-color: ${theme.colors.primary.primary10};
                }
            }
        }

        &.ant-form-item-has-error {
            .ant-radio-button-wrapper {
                &:not(.ant-radio-button-wrapper-checked) {
                    border: 1px solid ${theme.colors.red.red6};
                    color: ${theme.colors.red.red6};

                    &:not(:first-child)::before {
                        background-color: ${theme.colors.red.red6};
                    }
                }
            }
        }

        .ant-form-item-explain-error {
            margin-top: 8px;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.36px;
            margin-bottom: ${theme.sizes.marginNormal}px;
        }
    `}
`;

export const AppFormItem = ({
    label,
    optional = false,
    hasOptionalText = false,
    rules,
    disabled,
    isHalfParentWidth = false,
    additionalText = OPTIONAL_FIELD_LABEL,
    ...props
}: AppFormItemProps) => {
    const defaultRules: Rule[] = optional
        ? []
        : [{ required: true, message: formElementRequiredText }];
    const formItemRules = rules ? defaultRules.concat(rules) : defaultRules;

    return (
        <StyledFormItem
            validateFirst
            label={
                typeof label === 'string' ? (
                    <AppFormItem.Label
                        mainText={label}
                        additionalText={hasOptionalText ? additionalText : undefined}
                        disabled={disabled}
                    />
                ) : (
                    label
                )
            }
            rules={formItemRules}
            isHalfParentWidth={isHalfParentWidth}
            {...props}
        />
    );
};

AppFormItem.Label = Label;
