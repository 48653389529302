import { ReactNode } from 'react';
import { StyledPlusIcon } from 'components/atoms';
import { ContentContainer, StyledButton } from './ParentAndGuardianFormItemContentContainer.styled';

type FormItemContentContainerProps = {
    tableElement?: ReactNode;
    addButtonText?: string;
    onAddButtonClick?: () => void;
    isInvisibleAddButton?: boolean;
};

export const ParentAndGuardianFormItemContentContainer = ({
    tableElement,
    addButtonText,
    onAddButtonClick,
    isInvisibleAddButton,
}: FormItemContentContainerProps) => (
    <ContentContainer>
        {tableElement}
        {!isInvisibleAddButton && (
            <StyledButton icon={<StyledPlusIcon />} onClick={onAddButtonClick}>
                {addButtonText}
            </StyledButton>
        )}
    </ContentContainer>
);
