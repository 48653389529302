import { generatePath, Navigate } from 'react-router-dom';
import { useJournalId } from 'hooks/useJournalId/useJournalId';

type JournalNavigateProps = {
    to: string;
};

export const JournalNavigate = ({ to }: JournalNavigateProps) => {
    const { journalId } = useJournalId();

    const journalPath = generatePath(to, { journalId });

    return <Navigate to={journalPath} replace />;
};
