import { SettlementStaysFormFields } from 'types';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { PageBaseLayout } from 'components/layouts';
import { settingsTexts } from 'consts/text';
import { AxiosError } from 'axios';
import { parseSettlementStaysDTOIntoSettlementStaysFormFields } from 'utils/parsers/settlementStays/parseSettlementStaysDTOIntoSettlementStaysFormFields';
import { parseSettlementStaysFormFieldsIntoUpdateSettlementStaysDTO } from 'utils/parsers/settlementStays/parseSettlementStaysFormFieldsIntoUpdateSettlementStaysDTO';
import { SettlementStaysEditForm } from 'components/templates';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useEffect } from 'react';

export const SettlementStaysEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SettlementStaysFormFields>();
    const { displaySuccess } = useSimpleNotification();

    const {
        data: settlementStaysData,
        isError: isSettlementStaysDataError,
        isFetching: isSettlementStaysDataFetching,
        error: settlementStaysDataError,
    } = useAppQuery('SETTLEMENT_STAYS', [], StaffHTTPService.settings.getSettlementStays, {
        retry: false,
        staleTime: FIVE_MINS_IN_MILLIS,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (settlementStaysData) {
            setFormData(parseSettlementStaysDTOIntoSettlementStaysFormFields(settlementStaysData));
        }
    }, [setFormData, settlementStaysData]);

    const {
        mutate: editSettlementStays,
        error: editSettlementStaysError,
        isLoading: isEditSettlementStaysLoading,
    } = useAppMutation(StaffHTTPService.settings.updateSettlementStays, {
        key: ['UPDATE_SETTLEMENT_STAYS'],
        onSuccess: () =>
            displaySuccess(settingsTexts.settlements.meals.successNotifications.settlementEdited),
        invalidateQueryKeys: [['SETTLEMENT_STAYS']],
    });

    const onEditSettlementStaysFinish = (settlementStaysFormFields: SettlementStaysFormFields) => {
        const parsedSettlementStaysFormFields =
            parseSettlementStaysFormFieldsIntoUpdateSettlementStaysDTO(settlementStaysFormFields);

        if (!parsedSettlementStaysFormFields) {
            return;
        }

        editSettlementStays({
            settlementStaysDTO: parsedSettlementStaysFormFields,
        });
    };

    const isSettlementStaysDataErrorOnFetching = () => {
        const code =
            settlementStaysDataError instanceof AxiosError
                ? settlementStaysDataError?.response?.data?.code
                : '';

        if (isSettlementStaysDataError) {
            if (code === 'SETTLEMENT_SETTINGS_NOT_FOUND') {
                return false;
            }
            return true;
        }
        return false;
    };

    return (
        <PageBaseLayout isError={isSettlementStaysDataErrorOnFetching()}>
            <SettlementStaysEditForm
                form={form}
                initialValues={formInitialData}
                axiosError={editSettlementStaysError}
                isLoading={isEditSettlementStaysLoading || isSettlementStaysDataFetching}
                onFinish={onEditSettlementStaysFinish}
            />
        </PageBaseLayout>
    );
};
