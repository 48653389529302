import { Tag } from 'antd';
import styled from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const TableTag = styled(Tag)`
    ${getTextStyle('BodySmallSemiBold')};
    padding: 4px 12px;
    border-radius: 4px;
    margin-inline-end: 0;
    border-width: 0;
`;
