import { axiosErrors } from 'consts/errors/axiosErrors';
import { commonTexts } from 'consts/text';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { institutionAddErrorHandler } from './institution/institutionAdd';
import { getErrorMessageAndCode } from './getErrorMessageAndCode';

export const signUpErrorHandler = (error: unknown) => {
    const { code, message } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (code === apiErrorCodes.USER_EMAIL_TAKEN) {
        return commonTexts.errorMessages.userEmailTakenEnterAnother;
    }
    if (message.includes('The user with the provided email already exists')) {
        return commonTexts.errorMessages.userEmailTaken;
    }
    return institutionAddErrorHandler(error);
};
