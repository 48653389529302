import { ChildDetailed } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parseChildIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildIntoJsonObject';
import { parseChildSettlementDetailsIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildSettlementDetailsIntoJsonObject';
import { parseChildDietIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildDietIntoJsonObject';

export const parseChildDetailedIntoJsonObject: (childDetailed: ChildDetailed) => object = (
    childDetailed,
) => {
    const { child, settlementDetails, diet } = childDetailed;

    const parsedChildJsonObject = parseChildIntoJsonObject(child);
    const parsedChildSettlementDetailsJsonObject = settlementDetails
        ? parseChildSettlementDetailsIntoJsonObject(settlementDetails)
        : EMPTY_VALUE_LABEL;
    const parsedChildDietJsonObject = diet ? parseChildDietIntoJsonObject(diet) : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.childDetailed.fields.child]: parsedChildJsonObject,
        [jsonObjectsTexts.childDetailed.fields.diet]: parsedChildDietJsonObject,
        [jsonObjectsTexts.childDetailed.fields.settlementDetails]:
            parsedChildSettlementDetailsJsonObject,
    };
};
