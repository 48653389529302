import styled, { css } from 'styled-components';

export const BaseContainer = styled.div`
    ${({ theme }) => css`
        height: 100%;
        display: flex;
        background-color: ${theme.colors.grayscale.gray1};
    `}
`;

export const MailThreadOverviewContainer = styled.div`
    ${({ theme }) => css`
        width: calc(100% - 350px);
        display: flex;
        flex-direction: column;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
            height: calc(100% - ${theme.sizes.sideMenu.mobileUpperNavBarHeight}px);
            position: fixed;
            top: ${theme.sizes.sideMenu.mobileUpperNavBarHeight}px;
            left: 0;
            z-index: 99;
            background-color: ${theme.colors.grayscale.gray1};
        }
    `}
`;
