import styled, { css } from 'styled-components';

export const WeeklyScheduleInputsContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: ${theme.borders.borderRadiusLarge}px;
        border: 1px solid ${theme.colors.primary.primary5};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            flex-direction: column;
        }
    `}
`;
