import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import {
    RemoteLessonAddPage,
    RemoteLessonDetailsPage,
    RemoteLessonEditPage,
    RemoteLessonsPage,
} from 'pages';

export const remoteLessonsRoutes: RouteObject[] = [
    {
        path: appPaths.app['remote-lessons'].base,
        element: (
            <AbilityRoute
                can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.REMOTE_LESSONS_OWN }}
            >
                <RemoteLessonsPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app['remote-lessons'].add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.REMOTE_LESSONS_OWN,
                }}
            >
                <RemoteLessonAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app['remote-lessons'].edit.base,
        element: <Navigate to={appPaths.app['remote-lessons'].base} />,
    },
    {
        path: appPaths.app['remote-lessons'].edit.pickedRemoteLesson,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.REMOTE_LESSONS_OWN,
                }}
            >
                <RemoteLessonEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app['remote-lessons'].details.pickedRemoteLesson,
        element: (
            <AbilityRoute
                can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.REMOTE_LESSONS_OWN }}
            >
                <RemoteLessonDetailsPage />
            </AbilityRoute>
        ),
    },
];
