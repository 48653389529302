import { MessageDTO } from 'types';
import { IndividualMailThreadMessageReadStatus } from 'components/atoms';
import { GroupMailThreadMessageReadStatus } from 'components/molecules/GroupMailThreadMessageReadStatus/GroupMailThreadMessageReadStatus';
import { INDIVIDUAL_MAIL_THREAD_RECEIVERS_MAX_COUNT } from 'consts/mailThread/individualMailThreadReceiversMaxCount';
import { isGroupMailThreadVariant } from 'utils/isGroupMailThreadVariant';

type MessageReadStatusProps = Pick<MessageDTO, 'receivers' | 'readByCount' | 'receiversCount'>;

export const MessageReadStatus = ({
    receivers,
    readByCount,
    receiversCount,
}: MessageReadStatusProps) => {
    const isGroupMailThread = isGroupMailThreadVariant(receiversCount);

    return isGroupMailThread ? (
        <GroupMailThreadMessageReadStatus
            receivers={receivers}
            readByCount={readByCount}
            receiversCount={receiversCount}
        />
    ) : (
        <IndividualMailThreadMessageReadStatus
            read={readByCount === INDIVIDUAL_MAIL_THREAD_RECEIVERS_MAX_COUNT}
        />
    );
};
