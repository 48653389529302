import { validatePolish } from 'validate-polish';
import { AppFormItem, AppFormItemProps, AppMaskedInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { peselNumberRegExp } from 'consts/regexps';
import { childrenTexts } from 'consts/text';

const INVALID_PESEL_FORMAT_ERROR = 'Niepoprawny numer PESEL';

export const PeselInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label={childrenTexts.childForm.personalData.pesel}
        name={formFields.peselNumber}
        rules={[
            { required: true, message: formElementRequiredText },
            { pattern: peselNumberRegExp, message: INVALID_PESEL_FORMAT_ERROR, required: true },
            {
                validator: (_, value) =>
                    validatePolish.pesel(value)
                        ? Promise.resolve()
                        : Promise.reject(new Error(INVALID_PESEL_FORMAT_ERROR)),
                message: INVALID_PESEL_FORMAT_ERROR,
            },
        ]}
        {...props}
    >
        <AppMaskedInput mask="00000000000" autoComplete="off" />
    </AppFormItem>
);
