import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type LocationChangeProps = {
    action: () => void;
};

export const useLocationChange = ({ action }: LocationChangeProps) => {
    const location = useLocation();

    useEffect(() => action(), [location, action]);
};
