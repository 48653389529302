import { AppModal } from 'components/molecules';
import { AppModalButton, AppParagraph } from 'components/atoms';
import { commonTexts } from 'consts/text';
import { StyledAddButton } from 'pages/app/parent/groupAttendance/ParentGroupAttendancePage/ParentGroupAttendancePage.styled';
import { IconCreditCard, IconCreditCardOff } from '@tabler/icons-react';
import { appPaths } from 'consts/paths/paths';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
    IconContainer,
    ModalTitle,
    ModalRedText,
    ModalSemiBoldSmallText,
    ModalSemiBoldMediumText,
} from './AppReminderModal.styled';

type AppReminderModalProps = {
    onCancel: () => void;
};

export const AppReminderModal = ({ onCancel }: AppReminderModalProps) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const onPayClick = () => {
        navigate(appPaths.app.settings.payments);
        onCancel();
    };

    const buttons = (
        <>
            <AppModalButton onClick={onCancel}>{commonTexts.actionLabels.cancel}</AppModalButton>
            <StyledAddButton
                addButtonLabel={commonTexts.reminderModal.payNow}
                onClick={onPayClick}
                icon={<IconCreditCard />}
            />
        </>
    );

    return (
        <AppModal open footer={buttons} onCancel={onCancel}>
            <IconContainer>
                <IconCreditCardOff color={theme.colors.red.red6} />
            </IconContainer>
            <ModalTitle level={5}>
                {commonTexts.reminderModal.titlePaymentPartOne}{' '}
                <ModalRedText>{commonTexts.reminderModal.titlePaymentPartTwo}</ModalRedText>
            </ModalTitle>
            <AppParagraph textAlign="center" textType="BodySmall" marginBottom={16}>
                {commonTexts.reminderModal.contentOne}{' '}
                <ModalSemiBoldSmallText>
                    {commonTexts.reminderModal.contentTwo}
                </ModalSemiBoldSmallText>
            </AppParagraph>
            <AppParagraph textAlign="center">
                {commonTexts.reminderModal.contentThree}{' '}
                <ModalSemiBoldMediumText>
                    {commonTexts.reminderModal.contentFour}{' '}
                </ModalSemiBoldMediumText>
                {commonTexts.reminderModal.contentFive}{' '}
                <ModalSemiBoldMediumText>
                    {commonTexts.reminderModal.contentSix}{' '}
                </ModalSemiBoldMediumText>
            </AppParagraph>
        </AppModal>
    );
};
