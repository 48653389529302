import { useContext, useEffect } from 'react';
import { SubscriptionAddEditModal } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import AdminHTTPService from 'services/HTTPService/admin/AdminHTTPService';
import { subscriptionsTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { SubscriptionFormFields } from 'types';
import { AppInstitutionSubscriptionContext } from 'contexts/AppInstitutionSubscriptionContext';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO } from 'utils/parsers/subscription/parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO';
import { parseSubscriptionBasicDataDTOIntoSubscriptionFormFields } from 'utils/parsers/subscription/parseSubscriptionBasicDataDTOIntoSubscriptionFormFields';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const SubscriptionEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SubscriptionFormFields>();
    const { institutionSubscriptionDataItem } = useContext(AppInstitutionSubscriptionContext);
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useEffect(() => {
        if (!institutionSubscriptionDataItem) {
            return;
        }
        setFormData(
            parseSubscriptionBasicDataDTOIntoSubscriptionFormFields(
                institutionSubscriptionDataItem.subscriptionBasicData,
            ),
        );
    }, [institutionSubscriptionDataItem, form, setFormData]);

    const navigateToInstitutionsBase = () => statefulNavigate(appPaths.app.admin.institutions.base);

    const handleEditSubscriptionSuccess = () => {
        displaySuccess(subscriptionsTexts.messages.successfullyEditedSubscription);
        navigateToInstitutionsBase();
    };

    const {
        mutate: editSubscription,
        error: editSubscriptionError,
        isLoading: isEditSubscriptionLoading,
    } = useAppMutation(AdminHTTPService.clientSubscriptions.editSubscription, {
        key: ['EDIT_SUBSCRIPTION'],
        onSuccess: handleEditSubscriptionSuccess,
        invalidateQueryKeys: [
            ['INSTITUTION_SUBSCRIPTION_DATA_ITEMS'],
            [
                'INSTITUTION_SUBSCRIPTION_DATA_ITEM',
                institutionSubscriptionDataItem?.subscriptionBasicData.id,
            ],
        ],
    });

    const handleEditSubscriptionFinish = (subscriptionFormFields: SubscriptionFormFields) => {
        const institutionId = institutionSubscriptionDataItem?.institution.id;
        const subscriptionId = institutionSubscriptionDataItem?.subscriptionBasicData.id;
        if (!institutionId || !subscriptionId) {
            return;
        }
        const parsedUpdateSubscriptionDTO =
            parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO(subscriptionFormFields);
        editSubscription({
            updateSubscriptionDTO: parsedUpdateSubscriptionDTO,
            subscriptionId,
            institutionId,
        });
    };

    return (
        <SubscriptionAddEditModal
            open
            mode="edit"
            institutionName={institutionSubscriptionDataItem?.institution.name}
            activeChildren={institutionSubscriptionDataItem?.subscriptionBasicData.activeChildren}
            form={form}
            initialValues={formInitialData}
            axiosError={editSubscriptionError}
            isFetchDataError={!institutionSubscriptionDataItem}
            isLoading={isEditSubscriptionLoading}
            onCancel={navigateToInstitutionsBase}
            onFinish={handleEditSubscriptionFinish}
        />
    );
};
