import { AdditionalLessonDTO, SettlementConfiguration } from 'types';
import { AppText } from 'components/atoms/CommonAppComponents';
import { settlementTexts } from 'consts/text';
import { noAdditionalLessonConfigurationColor } from 'consts/additionalLessons/noAdditionalLessonConfigurationColor';
import { additionalLessonSettlementMapper } from 'consts/additionalLessons/additionalLessonSettlementMapper';

export const AdditionalLessonsSettlementTypeColumn = ({
    additionalLesson,
}: {
    additionalLesson: AdditionalLessonDTO;
}) =>
    additionalLesson.configured ? (
        <AppText margin={0}>
            {
                additionalLessonSettlementMapper[
                    additionalLesson.settlementType as SettlementConfiguration
                ]
            }
        </AppText>
    ) : (
        <AppText margin={0} color={noAdditionalLessonConfigurationColor}>
            {settlementTexts.extraLessons.noConfiguration}
        </AppText>
    );
