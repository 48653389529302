import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'antd/es/form/Form';
import { ModalTreePickerElement } from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { formFields } from 'consts/form/formFields';
import { UserCheckBottomIcon } from 'assets';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { commonTexts, messagesTexts } from 'consts/text';
import { IconPlus } from '@tabler/icons-react';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parseRemoteLessonChildrenGroupDTOsIntoSelectOptionTypeItems } from 'utils/parsers/remoteLessons/parseRemoteLessonChildrenGroupDTOsIntoSelectOptionTypeItems';
import { parseRemoteLessonChildrenGroupDTOsIntoExtendedDataNodes } from 'utils/parsers/remoteLessons/parseRemoteLessonChildrenGroupDTOsIntoExtendedDataNodes';
import { parseRemoteLessonReceiverDTOsIntoExtendedDataNodes } from 'utils/parsers/remoteLessons/parseRemoteLessonReceiverDTOsIntoExtendedDataNodes';
import { parseRecipientKeyIntoRecipientId } from 'utils/parsers/mailThread/parseRecipientKeyIntoRecipientId';
import uniq from 'lodash/uniq';
import { ChildId, UserId } from 'types';
import { AppFormItem, AppSelect } from 'components/atoms';
import { parseRemoteLessonEmployeeRecipientDTOIntoSelectOptionType } from 'utils/parsers/remoteLessons/parseRemoteLessonEmployeeRecipientDTOIntoSelectOptionType';
import styled from 'styled-components';
import { getChildrenTreePickerKeys } from 'utils/getChildrenTreePickerKeys';

const StyledAppSelect = styled(AppSelect)`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const ReceiversPicker = () => {
    const { setFieldValue, setFieldsValue } = useFormInstance();
    const { formError, setFormError } = useContext(AppFormContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedReceiversTreePickerKeys, setSelectedReceiversTreePickerKeys] = useState<
        string[]
    >([]);
    const selectedChildIds = useWatch<ChildId[] | undefined>(formFields.childIds);
    const selectedEmployeeIds = useWatch<UserId[] | undefined>(formFields.userIds);

    const selectedRecipients = useMemo(() => {
        if (selectedChildIds && selectedEmployeeIds) {
            return [...selectedChildIds, ...selectedEmployeeIds];
        }
        if (selectedChildIds) {
            return selectedChildIds;
        }
        if (selectedEmployeeIds) {
            return selectedEmployeeIds;
        }
        return [];
    }, [selectedChildIds, selectedEmployeeIds]);

    const {
        data: remoteLessonReceiversData,
        isFetching: isRemoteLessonReceiversDataFetching,
        isError: isRemoteLessonReceiversDataError,
    } = useAppQuery(
        'REMOTE_LESSON_RECEIVERS',
        [],
        StaffHTTPService.remoteLessons.getRemoteLessonRecipients,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isRemoteLessonReceiversDataFetching,
        isError: isRemoteLessonReceiversDataError,
        actualError: formError,
        errorMessage: messagesTexts.recipientsPicker.fetchingError,
        setError: setFormError,
    });

    const parsedRecipientsData = useMemo(() => {
        if (!remoteLessonReceiversData) {
            return {
                selectOptions: [],
                treeData: [],
                employeeIds: [],
                childrenIds: [],
                groups: [],
                specialGroups: [],
            };
        }
        const { employees, groups, specialGroups } = remoteLessonReceiversData;

        const mappedEmployeeDataForTree = employees.length
            ? parseRemoteLessonReceiverDTOsIntoExtendedDataNodes(employees)
            : [];
        const mappedEmployeeDataForPicker = employees.map((employee) =>
            parseRemoteLessonEmployeeRecipientDTOIntoSelectOptionType(employee),
        );

        const mappedGroupsDataForPicker =
            parseRemoteLessonChildrenGroupDTOsIntoSelectOptionTypeItems(groups);
        const mappedGroupsDataForTree = groups.length
            ? parseRemoteLessonChildrenGroupDTOsIntoExtendedDataNodes(groups, 'JOURNAL_GROUP')
            : [];
        const groupsChildrenIds = mappedGroupsDataForPicker.map((child) => child.value);

        const mappedSpecialGroupsDataForPicker =
            parseRemoteLessonChildrenGroupDTOsIntoSelectOptionTypeItems(specialGroups);
        const mappedSpecialGroupsDataForTree = specialGroups.length
            ? parseRemoteLessonChildrenGroupDTOsIntoExtendedDataNodes(
                  specialGroups,
                  'JOURNAL_SPECIAL',
              )
            : [];
        const specialGroupsChildrenIds = mappedSpecialGroupsDataForPicker.map(
            (child) => child.value,
        );

        const selectOptions = [
            ...mappedEmployeeDataForPicker,
            ...mappedGroupsDataForPicker,
            ...mappedSpecialGroupsDataForPicker,
        ];
        const treeData = [
            ...mappedEmployeeDataForTree,
            ...mappedGroupsDataForTree,
            ...mappedSpecialGroupsDataForTree,
        ];

        const employeeIds = employees.map((employeeMember) => employeeMember.userId);
        const childrenIds = [...groupsChildrenIds, ...specialGroupsChildrenIds];

        return {
            selectOptions,
            treeData,
            employeeIds,
            childrenIds,
            groups,
            specialGroups,
        };
    }, [remoteLessonReceiversData]);

    const setReceiversTreePickerInitialData = useCallback(() => {
        const receiversTreePickerInitialData: string[] = [];
        if (selectedEmployeeIds) {
            receiversTreePickerInitialData.push(...selectedEmployeeIds);
        }
        if (selectedChildIds && parsedRecipientsData) {
            const { groups, specialGroups } = parsedRecipientsData;
            const recipientsAllGroups = [...groups, ...specialGroups];
            const childrenTreePickerKeys = getChildrenTreePickerKeys(
                selectedChildIds,
                recipientsAllGroups,
            );
            receiversTreePickerInitialData.push(...childrenTreePickerKeys);
        }
        setSelectedReceiversTreePickerKeys(receiversTreePickerInitialData);
    }, [parsedRecipientsData, selectedChildIds, selectedEmployeeIds]);

    useEffect(() => {
        if (selectedReceiversTreePickerKeys.length) {
            return;
        }
        setReceiversTreePickerInitialData();
    }, [selectedReceiversTreePickerKeys.length, setReceiversTreePickerInitialData]);

    const onClear = () =>
        setFieldsValue({ [formFields.childIds]: undefined, [formFields.userIds]: undefined });
    const onModalOpen = () => setIsModalOpen(true);
    const onModalClose = () => setIsModalOpen(false);

    const onSave = (receiversTreePickerKeys: React.Key[]) => {
        const receiversKeys = receiversTreePickerKeys as string[];
        const { employeeIds, childrenIds } = parsedRecipientsData;
        const recipientsIds = receiversKeys.map(parseRecipientKeyIntoRecipientId);
        const uniqueRecipientsIds = uniq(recipientsIds);
        const filteredEmployeeId = uniqueRecipientsIds.filter((recipientsId) =>
            employeeIds.includes(recipientsId as UserId),
        );
        const filteredChildrenIds = uniqueRecipientsIds.filter((recipientsId) =>
            childrenIds.includes(recipientsId as ChildId),
        );
        setSelectedReceiversTreePickerKeys(receiversKeys);
        setFieldValue(formFields.childIds, filteredChildrenIds);
        setFieldValue(formFields.userIds, filteredEmployeeId);
        onModalClose();
    };

    const { treeData, selectOptions } = parsedRecipientsData;

    return (
        <>
            <AppFormItem name={formFields.childIds} optional marginBottom={0}>
                <input type="hidden" />
            </AppFormItem>
            <AppFormItem name={formFields.userIds} optional marginBottom={0}>
                <input type="hidden" />
            </AppFormItem>
            <ModalTreePickerElement
                title={commonTexts.actionLabels.addReceivers}
                open={isModalOpen}
                onCancel={onModalClose}
                onSave={onSave}
                treeData={treeData}
                initialValue={selectedReceiversTreePickerKeys}
                icon={<UserCheckBottomIcon />}
                isLoading={isRemoteLessonReceiversDataFetching}
            />
            <StyledAppSelect
                loading={isRemoteLessonReceiversDataFetching}
                options={selectOptions}
                label={commonTexts.actionLabels.addReceivers}
                mode="multiple"
                value={selectedRecipients}
                suffixIcon={<IconPlus size={20} />}
                open={false}
                showSearch={false}
                onClick={onModalOpen}
                onClear={onClear}
            />
        </>
    );
};
