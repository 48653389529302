const SCRIPT_URLS: Record<ImportMeta['env']['VITE_APP_ENV'], string> = {
    DEV: 'https://dev.dziennik.elfedu.pl/firebase-messaging-sw.js',
    UAT: 'https://uat.dziennik.elfedu.pl/firebase-messaging-sw.js',
    PROD: 'https://dziennik.elfedu.pl/firebase-messaging-sw.js',
};

export const registerServiceWorker = async () => {
    const isLocalhost =
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === '[::1]';

    const scriptUrl = isLocalhost
        ? 'http://localhost:5173/firebase-messaging-sw.js'
        : SCRIPT_URLS[import.meta.env.VITE_APP_ENV];

    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register(scriptUrl);
        } catch (error) {
            console.error(error);
        }
    }
};
