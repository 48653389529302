import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { WeeklyScheduleAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { WeeklyScheduleFormFields } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { formFields } from 'consts/form/formFields';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { journalTexts } from 'consts/text';
import { isWeeklyScheduleEmpty } from 'utils/isWeeklyScheduleEmpty';
import { sanitizeWeeklyScheduleDTODataIntoWeeklyScheduleFormFields } from 'utils/sanitizeWeeklyScheduleDTOData';
import { parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO } from 'utils/parsers/schedule/parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalWeeklyScheduleEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<WeeklyScheduleFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const {
        data: weeklyScheduleData,
        isFetching: isWeeklyScheduleDataFetching,
        isError: isWeeklyScheduleDataError,
    } = useAppQuery(
        'WEEKLY_SCHEDULE',
        [journalId],
        () => StaffHTTPService.schedules.weeklySchedule.getWeeklySchedule(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (!weeklyScheduleData || !isWeeklyScheduleEmpty(weeklyScheduleData)) {
            return;
        }
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.add,
                {
                    journalId,
                },
            ),
            { replace: true },
        );
    }, [weeklyScheduleData, journalId, statefulNavigate]);

    useEffect(() => {
        if (weeklyScheduleData && !form.getFieldValue(formFields.monday)) {
            setFormData(
                sanitizeWeeklyScheduleDTODataIntoWeeklyScheduleFormFields(weeklyScheduleData),
            );
        }
    }, [weeklyScheduleData, form, setFormData]);

    const onEditWeeklyScheduleSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.schedules.weeklySchedule
                .successfullyUpdatedWeeklySchedule,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: updateWeeklySchedule,
        error: updateWeeklyScheduleError,
        isLoading: isUpdateWeeklyScheduleLoading,
    } = useAppMutation(StaffHTTPService.schedules.weeklySchedule.updateWeeklySchedule, {
        key: ['UPDATE_WEEKLY_SCHEDULE'],
        onSuccess: onEditWeeklyScheduleSuccess,
        invalidateQueryKeys: [['WEEKLY_SCHEDULE', journalId]],
    });

    const onEditWeeklyScheduleFinish = (weeklyScheduleFormData: WeeklyScheduleFormFields) => {
        const parsedUpdateWeeklyScheduleDTO =
            parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO(weeklyScheduleFormData);
        updateWeeklySchedule({
            journalId,
            addUpdateWeeklyScheduleDTO: parsedUpdateWeeklyScheduleDTO,
        });
    };

    if (isWeeklyScheduleDataError) {
        return <ErrorDisplay />;
    }

    return (
        <WeeklyScheduleAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={updateWeeklyScheduleError}
            isLoading={isWeeklyScheduleDataFetching || isUpdateWeeklyScheduleLoading}
            onFinish={onEditWeeklyScheduleFinish}
            institutionWeeklySchedule={weeklyScheduleData}
        />
    );
};
