import { DietDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseDietDTOIntoJsonObject: (dietDTO: DietDTO) => object = (dietDTO) => {
    const { name, breakfastPrice, soupPrice, snackPrice, dinnerPrice } = dietDTO;

    const breakfastPriceData = breakfastPrice
        ? getFormattedPrice(breakfastPrice)
        : EMPTY_VALUE_LABEL;
    const soupPriceData = soupPrice ? getFormattedPrice(soupPrice) : EMPTY_VALUE_LABEL;
    const dinnerPriceData = dinnerPrice ? getFormattedPrice(dinnerPrice) : EMPTY_VALUE_LABEL;
    const snackPriceData = snackPrice ? getFormattedPrice(snackPrice) : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.dietDTO.fields.name]: name,
        [jsonObjectsTexts.dietDTO.fields.breakfastPrice]: breakfastPriceData,
        [jsonObjectsTexts.dietDTO.fields.soupPrice]: soupPriceData,
        [jsonObjectsTexts.dietDTO.fields.dinnerPrice]: dinnerPriceData,
        [jsonObjectsTexts.dietDTO.fields.snackPrice]: snackPriceData,
    };
};
