import styled from 'styled-components';
import { HistoryChangeDTO } from 'types';
import { AppFlex, AppMoreButton, HistoryChangeRefersToInfo } from 'components/atoms';

export type HistoryChangeRefersToDisplayProps = {
    historyChangeDTO: HistoryChangeDTO;
    onMoreButtonClick: (historyChangeData: HistoryChangeDTO) => void;
};

const StyledMoreButton = styled(AppMoreButton)`
    &.ant-btn.ant-btn-icon-only {
        padding: 0;
        width: auto;
    }
`;

export const HistoryChangeRefersToDisplay = ({
    historyChangeDTO,
    onMoreButtonClick,
}: HistoryChangeRefersToDisplayProps) => {
    const {
        refersTo,
        refersToSub,
        specialAction,
        unarchivedName,
        deletedName,
        nameChange,
        historyType,
        result,
    } = historyChangeDTO;

    const objectName = unarchivedName || deletedName || nameChange || undefined;

    return (
        <AppFlex vertical gap={4} align="flex-start">
            <HistoryChangeRefersToInfo
                refersTo={refersTo}
                refersToSub={refersToSub}
                specialAction={specialAction}
                historyType={historyType}
                objectName={objectName}
            />
            {result && (
                <StyledMoreButton margin={0} onClick={() => onMoreButtonClick(historyChangeDTO)} />
            )}
        </AppFlex>
    );
};
