import { useContext, useMemo } from 'react';
import { SimpleSelectElement, SimpleSelectElementProps } from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { messagesTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { formFields } from 'consts/form/formFields';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { parseMailThreadReceiverDTOIntoSelectOptionType } from 'utils/parsers/mailThread/parseMailThreadReceiverDTOIntoSelectOptionType';

type ParentMailThreadRecipientsPickerProps = Pick<SimpleSelectElementProps, 'disabled'>;

export const ParentMailThreadRecipientsPicker = ({
    disabled,
}: ParentMailThreadRecipientsPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);

    const {
        data: mailThreadReceiversData,
        isFetching: isMailThreadReceiversDataFetching,
        isError: isMailThreadReceiversDataError,
    } = useAppQuery(
        'MAIL_THREAD_RECEIVERS',
        [],
        ParentHTTPService.mailThreads.getMailThreadReceivers,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isMailThreadReceiversDataFetching,
        isError: isMailThreadReceiversDataError,
        actualError: formError,
        errorMessage: messagesTexts.recipientsPicker.fetchingError,
        setError: setFormError,
    });

    const recipientsOptions = useMemo(() => {
        if (!mailThreadReceiversData) {
            return [];
        }

        return mailThreadReceiversData.map(parseMailThreadReceiverDTOIntoSelectOptionType);
    }, [mailThreadReceiversData]);

    return (
        <SimpleSelectElement
            name={formFields.userId}
            label={messagesTexts.to}
            options={recipientsOptions}
            loading={isMailThreadReceiversDataFetching}
            disabled={disabled}
        />
    );
};
