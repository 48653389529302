import { AppText } from 'components/atoms';
import { colors } from 'theme/styledComponents/colors';
import { ChildrenCountDisplay } from './SubscriptionChildrenQuantity.styled';

type PackageChildrenQuantityProps = {
    currentChildrenCount: number;
    maxChildrenLimit: number;
    titleEnd: string;
    getBarColor: (current: number, max: number) => string;
};

export const SubscriptionChildrenQuantity = ({
    currentChildrenCount,
    maxChildrenLimit,
    titleEnd,
    getBarColor,
}: PackageChildrenQuantityProps) => (
    <ChildrenCountDisplay>
        <AppText
            textType="H3"
            color={getBarColor(currentChildrenCount, maxChildrenLimit)}
            margin={0}
            marginRight={5}
        >
            {currentChildrenCount}
        </AppText>
        <AppText textType="H3Regular" color={colors.primary.primary9} margin={0} marginRight={5}>
            |
        </AppText>
        <AppText textType="H3" color={colors.primary.primary9} margin={0} marginRight={5}>
            {maxChildrenLimit}
        </AppText>
        <AppText textType="BodyLarge" margin={0} marginBottom={3}>
            {titleEnd}
        </AppText>
    </ChildrenCountDisplay>
);
