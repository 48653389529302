import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { useAppAbility } from '../useAppAbility';

export const useGroupAbilities = () => {
    const ability = useAppAbility();

    const groupAbilities = {
        checkIfCanViewGroupList: () => ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUPS_LIST),
        checkIfCanManageGroupList: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUPS_LIST),
    };

    return { groupAbilities };
};
