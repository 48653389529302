import { ReactNode } from 'react';
import { ColProps } from 'antd';
import { AppParagraph } from 'components/atoms/CommonAppComponents';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { Container } from './DetailsColumn.styled';

export type DetailsColumnProps = Pick<ColProps, 'span'> & {
    title: string;
    children?: ReactNode;
};

export const DetailsColumn = ({ title, children, span }: DetailsColumnProps) => {
    const content =
        typeof children === 'string' || !children ? (
            <AppParagraph margin={0} marginBottom={4} textType="BodyMedium">
                {children || EMPTY_VALUE_LABEL}
            </AppParagraph>
        ) : (
            children
        );

    return (
        <Container span={span}>
            <AppParagraph margin={0} marginBottom={4} textType="BodyMSSemiBold">
                {title}
            </AppParagraph>
            {content}
        </Container>
    );
};
