import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import styled from 'styled-components';
import { appPaths } from 'consts/paths/paths';
import { AppMenu, AppMenuProps } from 'components/atoms';
import { settingsTexts } from 'consts/text';

const StyledAppMenu = styled(AppMenu)`
    width: max-content;
    margin: auto;
`;

export type SimpleMenuItem = {
    key: string;
    label: string;
};

export const settingsSettlementNavBarOptions: SimpleMenuItem[] = [
    {
        key: appPaths.app.settings.settlements.general.base,
        label: settingsTexts.settlements.meals.settlementNavBarOptions.general,
    },
    {
        key: appPaths.app.settings.settlements.stays.base,
        label: settingsTexts.settlements.meals.settlementNavBarOptions.stays,
    },
    {
        key: appPaths.app.settings.settlements.meals.base,
        label: settingsTexts.settlements.meals.settlementNavBarOptions.meals,
    },
];

export const SettingsSettlementLayout = () => {
    const navigate = useNavigate();
    const outlet = useOutlet();
    const { pathname } = useLocation();

    const onMenuItemClick: AppMenuProps['onClick'] = ({ key }) => navigate(key);

    useEffect(() => {
        if (!outlet) {
            navigate(appPaths.app.settings.settlements.general.base);
        }
    }, [navigate, outlet]);

    return (
        <>
            <StyledAppMenu
                mode="horizontal"
                items={settingsSettlementNavBarOptions}
                selectedKeys={[pathname]}
                onClick={onMenuItemClick}
            />
            <Outlet />
        </>
    );
};
