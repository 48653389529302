import { Modal, ModalProps } from 'antd';
import { css, styled } from 'styled-components';
import { StyledXIcon } from 'components/atoms/StyledIcons';

export type AppModalBaseProps = ModalProps & {
    isMobileFullScreenMode?: boolean;
};

const ModalCloseIcon = styled(StyledXIcon)`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray10};

        &:hover {
            color: ${theme.colors.primary.primary8};
        }
    `}
`;

const StyledModal = styled(Modal)<AppModalBaseProps>`
    ${({ theme }) => css`
        & .ant-modal-content {
            padding: 24px;
            border-radius: ${theme.borders.borderRadiusLarge}px;
            box-shadow:
                0 0 0 0 ${theme.colors.grayscale.gray9}1a,
                0 4px 9px 0 ${theme.colors.grayscale.gray9}1a,
                0 16px 16px 0 ${theme.colors.grayscale.gray9}17,
                0 37px 22px 0 ${theme.colors.grayscale.gray9}0d,
                0 66px 26px 0 ${theme.colors.grayscale.gray9}03,
                0 102px 29px 0 ${theme.colors.grayscale.gray9}00;

            & .ant-modal-body {
                margin-top: 0;
            }

            &:has(.ant-modal-close) {
                & .ant-modal-body {
                    margin-top: 20px;
                }
            }

            & .ant-modal-footer {
                margin-top: ${theme.sizes.marginLarge}px;
                display: flex;
                justify-content: center;
                gap: 16px;

                & .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
                    margin-inline-start: 0;
                }
            }

            & .ant-modal-close {
                height: 26px;
                width: 26px;
                border-radius: ${theme.borders.borderRadiusSmall}px;

                &:hover {
                    background-color: transparent;
                }

                &:focus-visible {
                    outline: none;

                    & svg {
                        color: ${theme.colors.primary.primary8};
                    }
                }

                &:active {
                    background-color: ${theme.colors.primary.primary3};
                }
            }
        }
    `}
`;

const FullScreenModal = styled(StyledModal)<AppModalBaseProps>`
    ${({ theme }) => css`
        @media (${theme.sizes.mediaQueries.mobileMax}) {
            & {
                margin-block: 0;
                position: fixed;
                top: 0;
                left: 0;
                width: 100% !important;
                max-width: 100% !important;
                height: 100%;
                margin: 0 !important;

                & > div {
                    height: 100%;
                }

                & .ant-modal-content {
                    height: 100%;
                    padding: 16px;
                    border-radius: 0;
                    overflow: auto;
                }
            }
        }
    `}
`;

const modalMaskStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    backdropFilter: 'blur(10px)',
};

export const AppModalBase = ({ isMobileFullScreenMode, ...props }: AppModalBaseProps) =>
    isMobileFullScreenMode ? (
        <FullScreenModal
            centered
            closeIcon={<ModalCloseIcon />}
            styles={{ mask: modalMaskStyle }}
            {...props}
        />
    ) : (
        <StyledModal
            centered
            closeIcon={<ModalCloseIcon />}
            styles={{ mask: modalMaskStyle }}
            {...props}
        />
    );
