import { AppButton, AppParagraph } from 'components/atoms';
import styled from 'styled-components';
import { colors } from 'theme/styledComponents/colors';

export const UpgradeSubscriptionContainer = styled.div`
    margin: 32px 0;
    background: ${colors.primary.primary9};
    border-radius: 12px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ResponsiveAppParagraph = styled(AppParagraph)`
    text-align: ${(props) => props.textAlign || 'initial'};

    @media (max-width: 350px) {
        text-align: left !important;
    }
`;
export const StyledAppButton = styled(AppButton)`
    background: ${colors.orange.orange1};
    margin: 0 auto;
`;
