import { AppCheckbox, AppCheckboxProps, AppFormItem, AppFormItemProps } from 'components/atoms';

type SimpleCheckboxElementProps = AppFormItemProps & Pick<AppCheckboxProps, 'onChange'>;

export const SimpleCheckboxElement = ({
    label,
    disabled,
    onChange,
    ...restProps
}: SimpleCheckboxElementProps) => (
    <AppFormItem valuePropName="checked" disabled={disabled} {...restProps}>
        <AppCheckbox disabled={disabled} onChange={onChange}>
            {label}
        </AppCheckbox>
    </AppFormItem>
);
