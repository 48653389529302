import { AddParentDTO, ChildParent } from 'types';

export const parseChildParentIntoAddParentDTO: (childParent: ChildParent) => AddParentDTO = (
    childParent,
) => {
    const { firstName, lastName, email, bankAccountNumber, phoneNumber, address } = childParent;

    return {
        firstName,
        lastName,
        email,
        bankAccountNumber,
        withAccount: !!email,
        phoneNumber,
        address,
    };
};
