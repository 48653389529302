import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { LoggedAppLayout } from 'components/layouts';
import { UserType } from 'types';
import { EmailConfirmationRequiredInfo } from 'pages';
import { staffRoutes } from './routes/staffRoutes';
import { adminRoutes } from './routes/adminRoutes';
import { parentRoutes } from './routes/parentRoutes';
import { blockedStaffRoutes } from './routes/blockedStaffRoutes';
import { NavigateToDefaultPage } from './components';

export const getLoggedAppRoutes: (userType?: UserType, isBlocked?: boolean) => RouteObject = (
    userType,
    isBlocked,
) => {
    let roleBasedRoutes: RouteObject[] | null = null;

    switch (userType) {
        case 'OWNER':
        case 'EMPLOYEE':
            if (isBlocked) {
                roleBasedRoutes = blockedStaffRoutes;
            } else {
                roleBasedRoutes = staffRoutes;
            }
            break;
        case 'PARENT':
            roleBasedRoutes = parentRoutes;
            break;
        case 'SYS_ADMIN':
            roleBasedRoutes = adminRoutes;
            break;
        default:
            // temporary route until the user type is fetched
            roleBasedRoutes = [{ path: `${appPaths.app.base}/*`, element: null }];
    }

    return {
        path: appPaths.app.base,
        element: <LoggedAppLayout />,
        children: [
            { index: true, element: <NavigateToDefaultPage /> },
            {
                path: appPaths.app['user-confirmation'],
                element: <EmailConfirmationRequiredInfo />,
            },
            ...roleBasedRoutes,
        ],
    };
};
