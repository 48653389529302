import { ChildGuardian, GuardianFormFields, GuardianId } from 'types';
import { uniqueId } from 'lodash';
import { parseFormattedPhoneNumberIntoPhoneNumber } from '../phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';

export const parseGuardianFormFieldsIntoChildGuardian: (
    guardianFormFields: GuardianFormFields,
    previousGuardian?: ChildGuardian | null,
) => ChildGuardian = (guardianFormFields, previousGuardian) => ({
    ...guardianFormFields,
    id: previousGuardian?.id || (uniqueId() as GuardianId),
    phoneNumber: guardianFormFields.phoneNumber
        ? parseFormattedPhoneNumberIntoPhoneNumber(guardianFormFields.phoneNumber)
        : null,
});
