import { AppTable } from 'components/organisms';
import styled, { css } from 'styled-components';

export const StyledAppTable = styled(AppTable)`
    ${({ theme }) => css`
        .ant-table-cell {
            padding: ${theme.sizes.marginNormal}px 12px !important;
        }
    `}
`;
