import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, parentTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const absenceAddDeleteErrorParser: (
    error: unknown,
    defaultErrorMessage?: string,
) => string = (error, defaultErrorMessage = commonTexts.errorMessages.unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.ABSENCE_DATES_NOT_CORRECT) {
        return parentTexts.groupAttendance.table.tooLateToRemoveAbsenceErrorMessage;
    }

    return defaultErrorMessage;
};
