import { AppFormItem } from 'components/atoms';
import { ErrorDisplay } from 'components/molecules';
import styled, { css } from 'styled-components';

export const StyledAppFormItem = styled(AppFormItem)`
    ${({ theme }) => css`
        &.ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content {
            flex-direction: row;
            .ant-form-item:first-child {
                margin-right: ${theme.sizes.marginSmall}px;
                flex: 1;
            }

            .ant-form-item:last-child {
                margin-left: ${theme.sizes.marginSmall}px;
                flex: 1;
            }
        }
    `}
`;

export const StyledErrorDisplay = styled(ErrorDisplay)`
    ${({ theme }) => css`
        z-index: 1000;
        width: 100%;
        height: 100%;
        position: absolute;
        max-width: unset;
        margin: 0;
        background-color: ${theme.colors.backdrop};
    `}
`;
