import { ChildId, JournalId } from 'types';

export const JOURNALS = '/api/v1/journals';
export const JOURNAL = (journalId: JournalId) => `/api/v1/journals/${journalId}`;
export const ARCHIVE_JOURNAL = (journalId: JournalId) => `/api/v1/journals/${journalId}/archive`;
export const JOURNAL_CHILDREN = (journalId: JournalId) => `/api/v1/journals/${journalId}/children`;
export const JOURNAL_CHILD_DATES = (journalId: JournalId, childId: ChildId) =>
    `/api/v1/journals/${journalId}/children/${childId}/dates`;
export const JOURNAL_TEACHERS = (journalId: JournalId) => `/api/v1/journals/${journalId}/teachers`;
export const JOURNALS_GROUPS = '/api/v1/journals/groups';
export const JOURNALS_EMPLOYEES = '/api/v1/journals/employees';
