import { AppRadioGroup } from 'components/atoms';
import { StyledAppRadioButton } from './OnOffElement.styled';

export type OnOffElementProps<ElementName> = {
    active: boolean;
    elements: { label: ElementName; value: boolean }[];
    onChange: (value: boolean) => void;
    disabled?: boolean;
};

export const OnOffElement = <ElementName extends string>({
    active,
    elements,
    onChange,
    disabled,
}: OnOffElementProps<ElementName>) => {
    const radioElements = elements.map(({ value, label }) => (
        <StyledAppRadioButton key={label} value={value}>
            {label}
        </StyledAppRadioButton>
    ));

    return (
        <AppRadioGroup
            disabled={disabled}
            value={active}
            onChange={(event) => onChange(event.target.value)}
        >
            {radioElements}
        </AppRadioGroup>
    );
};
