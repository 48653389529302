import { colors } from 'theme/styledComponents/colors';
import { ColorMapper, LabelMapper, PaymentStatus } from 'types';

export const paymentsFields = {
    id: 'id',
    institutionId: 'institutionId',
    paid: 'paid',
    fee: 'fee',
    date: 'date',
    paidAt: 'paidAt',
    description: 'description',
    productId: 'productId',
    productType: 'productType',
    totalAmount: 'totalAmount',
};

export const paymentStatusesLabels: LabelMapper<PaymentStatus> = {
    paid: 'Opłacono',
    unpaid: 'Nieopłacono',
};

export const tagColorsByPaymentStatus: ColorMapper<PaymentStatus> = {
    paid: {
        textColor: colors.green.green8,
        backgroundColor: colors.green.green3,
    },
    unpaid: { textColor: colors.red.red8, backgroundColor: colors.red.red2 },
};
