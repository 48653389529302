import { Dayjs } from 'dayjs';
import {
    DatePickerArrowButtonType,
    DatePickerWithArrows,
} from 'components/molecules/DatePickerWithArrows/DatePickerWithArrows';
import { AppDatePickerWithLabel } from 'components/molecules/ComponenentsWithLabel';
import { commonTexts, journalTexts } from 'consts/text';
import { SelectOptionType, Attendance } from 'types';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { isBeforeSchoolYear } from 'utils/isBeforeSchoolYear';
import { isAfterSchoolYear } from 'utils/isAfterSchoolYear';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { StyledSelect } from './JournalAttendanceFilters.styled';

const attendanceTypeOptions: SelectOptionType<Attendance>[] = [
    {
        label: journalTexts.journalDetails.common.attendance.dailyPlan,
        value: 'DAILY',
    },
    {
        label: journalTexts.journalDetails.common.attendance.monthlyPlan,
        value: 'MONTHLY',
    },
];

type JournalAttendanceFiltersProps = {
    attendanceType: Attendance;
    date: Dayjs;
    firstSchoolYear?: string;
    lastSchoolYear?: string;
    isDatePickerDisabled?: boolean;
    onDateChange: (newDate: Dayjs | null) => void;
    onClickNextOrPrevDate?: (arrowButtonType: DatePickerArrowButtonType) => void;
    onAttendanceTypeChange: (selectedAttendanceType: Attendance) => void;
};

export const JournalAttendanceFilters = ({
    date,
    attendanceType,
    firstSchoolYear,
    lastSchoolYear,
    isDatePickerDisabled,
    onDateChange,
    onClickNextOrPrevDate,
    onAttendanceTypeChange,
}: JournalAttendanceFiltersProps) => {
    const isMobile = useIsMobile();

    const renderDisabledDate = (dateValue: Dayjs) => {
        if (!firstSchoolYear || !lastSchoolYear) {
            return true;
        }
        const isBeforeFirstSchoolYear = isBeforeSchoolYear(dateValue, Number(firstSchoolYear));
        const isAfterLastSchoolYear = isAfterSchoolYear(dateValue, Number(lastSchoolYear));
        return isBeforeFirstSchoolYear || isAfterLastSchoolYear;
    };

    const renderDatePicker = () => {
        if (attendanceType === 'DAILY') {
            return (
                <DatePickerWithArrows
                    value={date}
                    disabled={isDatePickerDisabled}
                    onChange={onDateChange}
                    onArrowButtonClick={onClickNextOrPrevDate}
                    disabledDate={renderDisabledDate}
                />
            );
        }

        return (
            <AppDatePickerWithLabel
                label={commonTexts.actionLabels.selectMonth}
                value={date}
                picker="month"
                format={FULL_MONTH_FORMAT}
                allowClear={false}
                disabled={isDatePickerDisabled}
                isFitContentWidth={!isMobile}
                disabledDate={renderDisabledDate}
                onChange={onDateChange}
            />
        );
    };

    return (
        <>
            {renderDatePicker()}
            <StyledSelect
                label={commonTexts.actionLabels.selectRange}
                value={attendanceType}
                options={attendanceTypeOptions}
                onChange={onAttendanceTypeChange}
                allowClear={false}
                isFitContentWidth={!isMobile}
            />
        </>
    );
};
