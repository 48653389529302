import {
    AppFormItemProps,
    AppTimePicker,
    DurationDateTimeFormItemBaseProps,
    DurationDateTimeFormItemBase,
} from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { AppFormRangeItemMode } from 'types';
import { commonTexts } from 'consts/text';

export type SimpleDurationTimePickerProps = AppFormItemProps & {
    mode: AppFormRangeItemMode;
    startFormItemData?: DurationDateTimeFormItemBaseProps['startFormItemData'];
    finishFormItemData?: DurationDateTimeFormItemBaseProps['finishFormItemData'];
    isFinishValueBeforeStartValueErrorText?: string;
    isStartValueAfterFinishValueErrorText?: string;
};

export const SimpleDurationTimePicker = ({
    mode,
    startFormItemData = { name: formFields.timeFrom, label: commonTexts.dataLabels.timeFrom },
    finishFormItemData = { name: formFields.timeTo, label: commonTexts.dataLabels.timeTo },
    isFinishValueBeforeStartValueErrorText = commonTexts.errorMessages
        .timeToBeforeTimeFromErrorText,
    isStartValueAfterFinishValueErrorText = commonTexts.errorMessages.timeFromAfterTimeToErrorText,
    ...props
}: SimpleDurationTimePickerProps) => (
    <DurationDateTimeFormItemBase
        pickerType="time"
        rangeItemMode={mode}
        picker={<AppTimePicker autoComplete="off" />}
        startFormItemData={startFormItemData}
        finishFormItemData={finishFormItemData}
        isFinishValueBeforeStartValueErrorText={isFinishValueBeforeStartValueErrorText}
        isStartValueAfterFinishValueErrorText={isStartValueAfterFinishValueErrorText}
        {...props}
    />
);
