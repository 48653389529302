import { AbsenceFormFields, CreateAbsenceDTO } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseAbsenceFormFieldsIntoCreateAbsenceDTO = (
    absenceFormFieldsData: AbsenceFormFields,
): CreateAbsenceDTO => {
    const { teacherIds, dateFrom, dateTo, description, absenceType, paid } = absenceFormFieldsData;

    return {
        teacherIds,
        startDate: parseDayjsDateIntoString(dateFrom, 'YYYY-MM-DD'),
        endDate: parseDayjsDateIntoString(dateTo, 'YYYY-MM-DD'),
        description,
        type: absenceType,
        paid,
    };
};
