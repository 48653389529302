import styled, { css } from 'styled-components';
import { AppList } from 'components/atoms';
import { AbsenceDTO } from 'types';
import { TimetableInfo } from 'components/organisms/LessonList/components/LessonListItem/components';
import { timetableTexts } from 'consts/text';

type AbsenceListItemProps = {
    absence: AbsenceDTO;
    onAbsenceClick: (absence: AbsenceDTO) => void;
    noPreview?: boolean;
};

const StyledAbsenceListItem = styled(AppList.Item)<{ noPreview?: boolean }>`
    ${({ theme, noPreview }) => css`
        height: 44px;
        cursor: ${noPreview ? 'default' : 'pointer'};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        opacity: 0.9;

        &.ant-list-item {
            padding: 8px 12px;
            border: 1px solid ${theme.colors.red.red6};
            border-radius: ${theme.borders.borderRadiusExtraSmall}px;
            background-color: ${theme.colors.red.red2};
            list-style: none;
            margin-bottom: ${theme.sizes.marginSmall}px;
            color:${theme.colors.red.red6};
            }
        }
    `}
`;

export const AbsenceListItem = ({ absence, onAbsenceClick, noPreview }: AbsenceListItemProps) => (
    <StyledAbsenceListItem onClick={() => onAbsenceClick(absence)} noPreview={noPreview}>
        <TimetableInfo isShrinked isSupportedEllipsis elementType="absence">
            {timetableTexts.absence.absence}
        </TimetableInfo>
    </StyledAbsenceListItem>
);
