import { EmployeeDTO, EmployeeId, SelectOptionType } from 'types';
import { employeeUserFields } from 'consts/tables/employee/employeeUserFields';
import { getFullName } from '../../getFullName';

export const parseEmployeeDTOIntoSelectOptionType: (
    employeeUser: EmployeeDTO,
) => SelectOptionType<EmployeeId> = (employeeUser) => ({
    value: employeeUser.id,
    label: getFullName(
        employeeUser[employeeUserFields.firstName],
        employeeUser[employeeUserFields.lastName],
    ),
});
