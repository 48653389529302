import { generatePath } from 'react-router-dom';
import { HelpForm, JournalId, PsychologicalHelpId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { psychologicalHelpFormsLabels } from 'consts/psychologicalHelp/psychologicalHelpFormsLabels';
import { LinkDisplay } from '../LinkDisplay/LinkDisplay';

type PsychologicalHelpFormDisplayProps = {
    id: PsychologicalHelpId;
    journalId: JournalId;
    helpForm: HelpForm;
};

export const PsychologicalHelpFormDisplay = ({
    id,
    journalId,
    helpForm,
}: PsychologicalHelpFormDisplayProps) => {
    const pathname = generatePath(
        appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.details
            .pickedPsychologicalHelp,
        {
            psychologicalHelpId: id,
            journalId,
        },
    );
    return <LinkDisplay to={pathname}>{psychologicalHelpFormsLabels[helpForm]}</LinkDisplay>;
};
