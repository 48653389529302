import { useEffect } from 'react';
import { OwnerAndEmployeeAccountEditForm } from 'components/templates';
import { PageBaseLayout } from 'components/layouts';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { OwnerAndEmployeeAccountFormFields } from 'types';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { formFields } from 'consts/form/formFields';
import { accountTexts } from 'consts/text';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { parseUserBaseAuthDTOIntoOwnerAndEmployeeFormFields } from 'utils/parsers/user/parseUserBaseAuthDTOIntoOwnerAndEmployeeFormFields';
import { parseOwnerAndEmployeeFormFieldsIntoUpdateOwnerDTO } from 'utils/parsers/user/parseOwnerAndEmployeeFormFieldsIntoUpdateOwnerDTO';
import AuthHTTPService from 'services/HTTPService/auth/AuthHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const OwnerAccountEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<OwnerAndEmployeeAccountFormFields>();
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const {
        data: ownerDetails,
        isFetching: isOwnerDetailsFetching,
        isError: isOwnerDetailsError,
    } = useAppQuery(
        'USER_DETAILS',
        [institutionId],
        () => AuthHTTPService.authentication.getUser(institutionId),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (ownerDetails && !form.getFieldValue(formFields.firstName)) {
            setFormData(parseUserBaseAuthDTOIntoOwnerAndEmployeeFormFields(ownerDetails));
        }
    }, [ownerDetails, form, setFormData]);

    const onOwnerAccountEditSuccess = () => {
        displaySuccess(accountTexts.successfullyUpdatedAccountData);
        statefulNavigate(appPaths.app.account.details.base, { replace: true });
    };

    const {
        mutate: updateOwnerAccount,
        error: updateOwnerAccountError,
        isLoading: isUpdateOwnerAccountLoading,
    } = useAppMutation(StaffHTTPService.owner.updateOwner, {
        key: ['UPDATE_OWNER'],
        onSuccess: onOwnerAccountEditSuccess,
        invalidateQueryKeys: [['USER_DETAILS']],
    });

    const onEditOwnerAccountFinish = (
        ownerAccountFormFields: OwnerAndEmployeeAccountFormFields,
    ) => {
        if (!ownerDetails) {
            return;
        }
        updateOwnerAccount(
            parseOwnerAndEmployeeFormFieldsIntoUpdateOwnerDTO(ownerAccountFormFields),
        );
    };

    return (
        <PageBaseLayout isError={isOwnerDetailsError}>
            <OwnerAndEmployeeAccountEditForm
                mode="OWNER_ACCOUNT"
                form={form}
                initialValues={formInitialData}
                axiosError={updateOwnerAccountError}
                isLoading={isOwnerDetailsFetching || isUpdateOwnerAccountLoading}
                onFinish={onEditOwnerAccountFinish}
            />
        </PageBaseLayout>
    );
};
