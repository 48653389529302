import { MailThreadId } from 'types';

export const SUBSCRIBE_TO_NOTIFICATIONS = '/api/v1/mail/notification/subscribe';
export const UNREAD_MAIL_THREADS = '/api/v1/mail/notification/unread-threads';
export const MAIL_THREADS = '/api/v1/mail/threads';
export const MAIL_THREAD = (mailThreadId: MailThreadId) => `/api/v1/mail/threads/${mailThreadId}`;
export const MAIL_THREAD_RECEIVERS = '/api/v1/mail/receivers';
export const MAIL_THREAD_MESSAGES = (mailThreadId: MailThreadId) =>
    `/api/v1/mail/threads/${mailThreadId}/messages`;
export const REPLY_MAIL_THREAD = (mailThreadId: MailThreadId) =>
    `/api/v1/mail/threads/${mailThreadId}/reply-thread`;
