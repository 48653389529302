import { css } from 'styled-components';

export const upperBarBaseStyle = css`
    ${({ theme }) => css`
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        flex-direction: row;
        background-color: ${theme.colors.grayscale.gray1};
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        z-index: 100;
        border-bottom: 1px solid ${theme.colors.primary.primary4};
    `}
`;
