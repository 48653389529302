import { getColorsBySeed } from 'utils/getColorsBySeed';
import { RoleSimple } from 'types/user/role';
import { TableTag } from 'components/atoms';

export const RoleDisplay = ({ role }: { role: RoleSimple }) => {
    const { id, name } = role;
    const { mainColor, secondaryColor } = getColorsBySeed(name);

    return (
        <TableTag
            style={{
                color: mainColor,
                backgroundColor: secondaryColor,
            }}
            key={id}
        >
            {name}
        </TableTag>
    );
};
