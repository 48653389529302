import styled, { css } from 'styled-components';
import { AppGoBackButton, AppTitle } from 'components/atoms';

export const Container = styled.div`
    ${({ theme }) => css`
        padding: ${theme.sizes.marginNormal}px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${theme.colors.primary.primary4};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            padding-inline: ${theme.sizes.marginNormal}px;
        }
    `}
`;

export const StyledAppGoBackButton = styled(AppGoBackButton)`
    &.ant-btn-sm {
        padding: 7px;
    }
`;

export const DataContainer = styled.div`
    width: calc(100% - 65px);
`;

export const StyledAppTitle = styled(AppTitle)`
    ${({ theme }) => css`
        @media (${theme.sizes.mediaQueries.mobileMax}) {
            &:is(h5) {
                font-size: 16px;
            }
        }
    `}
`;
