import { PersonData } from 'types';
import { getFullName } from 'utils/getFullName';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { LinkDisplay, LinkDisplayProps } from 'components/molecules/Table/LinkDisplay/LinkDisplay';
import { AppParagraph } from 'components/atoms';

type FirstAndLastNameDisplayProps = PersonData & Partial<Pick<LinkDisplayProps, 'to'>>;

export const FirstAndLastNameDisplay = ({
    firstName,
    lastName,
    to,
}: FirstAndLastNameDisplayProps) => {
    const isMobile = useIsMobile();

    const content = getFullName(firstName, lastName);
    const renderAsLink = !isMobile && to;

    if (!renderAsLink) {
        return <AppParagraph margin={0}>{content}</AppParagraph>;
    }

    return <LinkDisplay to={to}>{content}</LinkDisplay>;
};
