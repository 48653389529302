import { FiltersParams, MailThreadsFilters, MailThreadSideBarMenuItemsType } from 'types';
import { mailThreadFilters } from 'consts/filters/mailThread/mailThreadFilters';

export const parseFilterParamsIntoMailThreadsFiltersValues: (
    filterParams: FiltersParams,
) => MailThreadsFilters = (filterParams) => {
    const filterParamType = filterParams[mailThreadFilters.type]
        ? (filterParams[mailThreadFilters.type] as MailThreadSideBarMenuItemsType)
        : undefined;
    return {
        type: filterParamType !== 'ALL' ? filterParamType : undefined,
        search: filterParams[mailThreadFilters.search]
            ? (filterParams[mailThreadFilters.search] as string)
            : undefined,
    };
};
