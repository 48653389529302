import styled, { css } from 'styled-components';
import { IconList } from '@tabler/icons-react';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export type ContainerProps = { mode: 'LIST' | 'TABLE' };

export const Container = styled.span.withConfig({
    shouldForwardProp: getShouldForwardProp(['mode']),
})<ContainerProps>`
    ${({ theme, mode }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        ${mode === 'TABLE' &&
        css`
            border: 1px solid ${theme.colors.primary.primary5};
            border-bottom: none;
            border-start-start-radius: 8px;
            border-start-end-radius: 8px;
            padding: 12px;
        `}
    `}
`;

export const LeftSideContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StyledListIcon = styled(IconList)`
    ${({ theme }) => css`
        margin-right: ${theme.sizes.marginSmall}px;
        width: 20px;
        height: 20px;
    `}
`;
