import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { max300LengthRule } from 'consts/rules/textRules';

export const NameInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Nazwa"
        name={formFields.name}
        rules={[{ required: true, message: formElementRequiredText }, max300LengthRule]}
        {...props}
    >
        <AppInput autoComplete="off" />
    </AppFormItem>
);
