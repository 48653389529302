import { IconFileExport } from '@tabler/icons-react';
import { Space } from 'antd';
import { AppButton, AppRadio, AppRadioGroup, AppSelect, AppText } from 'components/atoms';
import { AppModal } from 'components/molecules';
import { SelectAllCheckbox } from 'components/organisms';
import { commonTexts, groupsTexts, menusTexts } from 'consts/text';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useMemo, useState } from 'react';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { colors } from 'theme/styledComponents/colors';
import { GroupDTO, GroupId } from 'types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type GenerateReportModalProps = {
    open: boolean;
    onClose: () => void;
    confirmActionLoading: boolean;
    onOk: (chosenOption: 'catering' | 'giveAway', data?: GroupId[]) => void;
    title: string;
};

type GenerateMenuReportModalSelectType = {
    label: string;
    value: GroupId;
};

const mapGroupsListToSelectOptions = (
    groupsData: GroupDTO[],
): GenerateMenuReportModalSelectType[] =>
    groupsData.map((group) => ({
        label: group.name,
        value: group.id,
    }));

export const GenerateReportModal = ({
    open,
    onClose,
    confirmActionLoading,
    onOk,
    title,
}: GenerateReportModalProps) => {
    const [chosenOption, setChosenOption] = useState<'catering' | 'giveAway'>();
    const [selectedGroupsIds, setSelectedGroupsIds] = useState<GroupId[]>([]);
    const isChosenGiveAwayOption = chosenOption === 'giveAway';
    const { displayError } = useSimpleNotification();

    const { data: groupsData, isFetching } = useAppQuery(
        'MENUS_GROUPS',
        [],
        StaffHTTPService.menus.getMenusGroups,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            refetchOnWindowFocus: false,
            onSuccess: (dataGroups) => setSelectedGroupsIds(dataGroups.map((group) => group.id)),
            onError: () => displayError(groupsTexts.fetchingError),
        },
    );

    const groupsSelectOptions = useMemo(
        () => groupsData && mapGroupsListToSelectOptions(groupsData),
        [groupsData],
    );

    const isCheckedAllGroupsIds = selectedGroupsIds.length === groupsData?.length;

    const indeterminate =
        selectedGroupsIds.length > 0 && selectedGroupsIds.length < (groupsData?.length ?? 0);

    const dropdownRender = (menu: React.ReactElement) => (
        <>
            <SelectAllCheckbox
                isCheckedAll={isCheckedAllGroupsIds}
                indeterminate={indeterminate}
                onToggleSelectAll={() => {
                    if (groupsData) {
                        setSelectedGroupsIds(
                            isCheckedAllGroupsIds ? [] : groupsData.map((group) => group.id),
                        );
                    }
                }}
            />
            {menu}
        </>
    );

    const renderGiveAwayOptionSelect = () => (
        <AppSelect
            label={groupsTexts.chooseGroupsLabel}
            options={groupsSelectOptions}
            value={selectedGroupsIds}
            allowClear={false}
            loading={isFetching}
            onChange={(options) => setSelectedGroupsIds(options)}
            mode="multiple"
            dropdownRender={dropdownRender}
        />
    );

    return (
        <AppModal
            title={title}
            open={open}
            onCancel={onClose}
            footer={
                <AppButton
                    type="primary"
                    onClick={() => (chosenOption ? onOk(chosenOption, selectedGroupsIds) : null)}
                    icon={<IconFileExport width={20} height={20} />}
                    disabled={
                        !chosenOption || (isChosenGiveAwayOption && !selectedGroupsIds.length)
                    }
                    loading={confirmActionLoading}
                >
                    {commonTexts.actionLabels.generate}
                </AppButton>
            }
            isLoading={confirmActionLoading}
        >
            <AppRadioGroup
                onChange={({ target: { value } }) => {
                    setChosenOption(value);
                }}
                sameSizeOptionButtons
            >
                <Space direction="vertical" size={16} style={{ flex: 1 }}>
                    <AppRadio value="giveAway">{menusTexts.giveAwayReportLabel}</AppRadio>
                    {isChosenGiveAwayOption && renderGiveAwayOptionSelect()}
                    <AppRadio value="catering">{menusTexts.cateringReportLabel}</AppRadio>
                </Space>
            </AppRadioGroup>
            <AppText textType="BodySmall" color={colors.grayscale.gray9} margin={0} marginLeft={48}>
                {menusTexts.generatedForAll}
            </AppText>
        </AppModal>
    );
};
