export const qualificationFields = {
    id: 'id',
    firstName: 'firstName',
    lastName: 'lastName',
    sex: 'sex',
    groups: 'groups',
    contact: 'contact',
    hasAttachments: 'hasAttachments',
    filled: 'filled',
    pesel: 'pesel',
    childId: 'childId',
} as const;
