import { AppCalendarContext } from 'contexts/AppCalendarContext';
import { CalendarDayModal, CalendarFilters } from 'components/templates/calendar/';
import { CalendarApi } from '@fullcalendar/core';
import { StyledCalendar } from 'components/molecules/StyledCalendar/StyledCalendar';
import { calendarTexts } from 'consts/text/index';
import { AppTitle, ComponentBlockingAppLoader } from 'components/atoms';
import dayjs from 'dayjs';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { ErrorDisplay } from 'components/molecules';
import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { parseCalendarEventDetailedDtoIntoCalendarComponentEvent } from 'utils/parsers/calendar/parseCalendarEventDetailedDtoIntoCalendarComponentEvent';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const ParentCalendarPage = () => {
    const calendarRef = useRef();

    const [dayModalOpen, setDayModalOpen] = useState<boolean>(false);

    const [searchParams] = useSearchParams();
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });

    const [modalSelectedDate, setModalSelectedDate] = useState<string>(
        searchParams.get('date') ?? parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'),
    );
    const contextValue = useMemo(
        () => ({ selectedDate: modalSelectedDate, setSelectedDate: setModalSelectedDate }),
        [modalSelectedDate],
    );

    const selectedDate = dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs();
    const selectedYear = selectedDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDate.month());

    const monthName = dayjs()
        .month(selectedMonth - 1)
        .format(FULL_MONTH_FORMAT);

    const displayTitleDate = `${
        monthName.charAt(0).toUpperCase() + monthName.slice(1)
    } ${selectedYear}`;

    const calendarPageTitle = (
        <AppTitle level={3} margin={0} marginBottom={16} textAlign="center">
            {displayTitleDate}
        </AppTitle>
    );

    const {
        data: eventsData,
        isFetching: isEventsDataFetching,
        isError: isFetchEventsDataError,
    } = useAppQuery(
        'MONTHLY_EVENTS',
        [selectedMonth, selectedYear],
        () =>
            ParentHTTPService.calendar.getMonthlyEvents({
                month: selectedMonth,
                year: selectedYear,
            }),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    // change parser based on data for parent
    const calendarParsedEvents = useMemo(() => {
        if (!eventsData) {
            return [];
        }
        return eventsData.map(parseCalendarEventDetailedDtoIntoCalendarComponentEvent);
    }, [eventsData]);

    if (isFetchEventsDataError) {
        return <ErrorDisplay />;
    }

    const handleDateClick = (props: { dateStr: string }) => {
        // eslint-disable-next-line react/prop-types
        setModalSelectedDate(props?.dateStr);
        setDayModalOpen(true);
    };

    const handleMoreClick = (props: { date: Date }) => {
        // eslint-disable-next-line react/prop-types
        setModalSelectedDate(parseDayjsDateIntoString(dayjs(props.date), 'YYYY-MM-DD'));
        setDayModalOpen(true);
    };

    return (
        <AppCalendarContext.Provider value={contextValue}>
            {dayModalOpen && (
                <CalendarDayModal
                    showDetails={false}
                    open={dayModalOpen}
                    dailyFetchFunction={ParentHTTPService.calendar.getDailyEvents}
                    monthlyFetchFunction={ParentHTTPService.calendar.getMonthlyEvents}
                    onCancel={() => setDayModalOpen(false)}
                />
            )}
            {calendarPageTitle}
            <CalendarFilters
                // @ts-ignore
                calendarApi={() => calendarRef?.current?.getApi() as CalendarApi}
                handleChangeDateFilter={handleChangeDateFilter}
                handleClickNextOrPrevDate={handleClickNextOrPrevDate}
                selectedDate={selectedDate}
            />
            <StyledCalendar
                // @ts-ignore
                ref={calendarRef}
                dateClick={handleDateClick}
                initialDate={modalSelectedDate}
                moreLinkClick={handleMoreClick}
                moreLinkContent={(arg) => `+${arg.num} ${calendarTexts.more}`}
                events={calendarParsedEvents}
                eventClassNames={() => 'defaultCursor'}
            />
            {isEventsDataFetching && <ComponentBlockingAppLoader />}
        </AppCalendarContext.Provider>
    );
};
