import { css } from 'styled-components';
import { MarginOptions, MarginsProps } from 'types/components/margins';
import { AppTheme } from 'theme/styledComponents/theme';

export const marginPropsToFilter: string[] = [
    'margin',
    'marginLeft',
    'marginTop',
    'marginRight',
    'marginBottom',
];
const getParsedMargin = (theme: AppTheme, margin?: MarginOptions) => {
    if (typeof margin === 'number') {
        return `${margin}px`;
    }
    switch (margin) {
        case 'marginSmall':
            return `${theme.sizes.marginSmall}px`;
        case 'marginNormal':
            return `${theme.sizes.marginNormal}px`;
        case 'marginLarge':
            return `${theme.sizes.marginLarge}px`;
        default:
            return '';
    }
};

export const getMarginsCss = ({
    margin,
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
}: MarginsProps) => css`
    ${({ theme }) => css`
        margin: ${getParsedMargin(theme, margin)};
        margin-top: ${getParsedMargin(theme, marginTop)};
        margin-right: ${getParsedMargin(theme, marginRight)};
        margin-bottom: ${getParsedMargin(theme, marginBottom)};
        margin-left: ${getParsedMargin(theme, marginLeft)};
    `}
`;
