import styled, { css } from 'styled-components';
import { iconMarginLeft } from './InlineAppMenuStyles.styled';
import { getShouldForwardProp } from '../../../../utils/getShouldForwardProp';

export const AppMenuIconContainer = styled.div`
    height: 20px;
    margin-right: 12px;
    margin-left: ${iconMarginLeft}px;
`;

export const AppMenuBookmark = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['noMarginLeft']),
})<{ noMarginLeft?: boolean }>`
    ${({ theme, noMarginLeft = false }) => css`
        position: absolute;
        width: 3px;
        margin-left: ${noMarginLeft ? 0 : -iconMarginLeft}px;
        border-radius: 0 8px 8px 0;
        height: 28px;
        background-color: ${theme.colors.primary.primary10};
    `}
`;
