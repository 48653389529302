import { ReactNode } from 'react';

export type TableBodyWrapperProps = {
    children: ReactNode;
    colSpan: number;
};

export const TableBodyWrapper = ({ children, colSpan }: TableBodyWrapperProps) => (
    <tbody>
        <tr>
            <td colSpan={colSpan}>{children}</td>
        </tr>
    </tbody>
);
