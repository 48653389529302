import { useLocation, useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { MainAppModule } from 'types';
import {
    AppMenu,
    AppMenuBookmark,
    AppMenuIconContainer,
    getAppMenuItem,
    getIconWithBookmark,
} from 'components/atoms/CommonAppComponents';
import { getFullPath } from 'utils/paths/getFullPath';
import { ModuleButtonEntry } from 'components/molecules/SideMenu/components/SideMenuNavigation/modulesButtons';
import { SubItemDotIcon, SubItemLabel, SubItemLabelContainer } from './ModulesMenu.styled';

type ModulesMenuProps = {
    moduleButtonsData: Record<string, ModuleButtonEntry>;
};

export const ModulesMenu = ({ moduleButtonsData }: ModulesMenuProps) => {
    const { pathname } = useLocation();
    const { role } = useUserDetails();
    const navigate = useNavigate();
    const modulesToRender = Object.keys(moduleButtonsData).filter((module) =>
        role.modules.includes(module as MainAppModule),
    );

    const buttons = modulesToRender.map((module) => {
        const { name, path, Icon, children, getDefaultQueryParams } = moduleButtonsData[module];
        const fullPath = getDefaultQueryParams ? getFullPath(path, getDefaultQueryParams()) : path;
        const parsedChildren = children
            ? children.map((child) => {
                  const childFullPath = child.getDefaultQueryParams
                      ? getFullPath(child.path, child.getDefaultQueryParams())
                      : child.path;
                  return getAppMenuItem(
                      <SubItemLabelContainer>
                          <AppMenuBookmark noMarginLeft className="menu-bookmark" />
                          <SubItemLabel>{child.name}</SubItemLabel>
                      </SubItemLabelContainer>,
                      childFullPath,
                      <AppMenuIconContainer>
                          <SubItemDotIcon className="subitem-dot-icon" />
                      </AppMenuIconContainer>,
                  );
              })
            : undefined;

        return getAppMenuItem(name, fullPath, getIconWithBookmark(Icon), parsedChildren);
    });

    const selectedModuleButtonData = Object.values<ModuleButtonEntry>(moduleButtonsData).find(
        (moduleButtonData) => pathname.includes(moduleButtonData.path),
    );

    const onClick: MenuProps['onClick'] = ({ key }) => navigate(key);

    return (
        <AppMenu
            mode="inline"
            items={buttons}
            selectedKeys={[selectedModuleButtonData?.path || '']}
            onClick={onClick}
        />
    );
};
