import { AbsenceDTO, AbsenceFormFields } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';

export const parseAbsenceDTOIntoAbsenceFormFields = (
    absenceData: AbsenceDTO,
): AbsenceFormFields => {
    const { teacherId, startDate, endDate, description, type, paid } = absenceData;

    return {
        teacherIds: [teacherId],
        dateFrom: parseStringIntoDayjsDate(startDate),
        dateTo: parseStringIntoDayjsDate(endDate),
        description,
        absenceType: type,
        paid,
    };
};
