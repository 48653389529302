export const flattenObject = <ObjectType extends object>(objectToFlatted: object) =>
    Object.entries(objectToFlatted).reduce<ObjectType>(
        (accumulator, [currentKey, currentValue]) => {
            if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
                const nested = flattenObject(currentValue);
                Object.entries(nested).forEach(([nestedKey, nestedValue]) => {
                    // @ts-ignore
                    accumulator[nestedKey] = nestedValue;
                });
            } else {
                // @ts-ignore
                accumulator[currentKey] = currentValue;
            }
            return accumulator;
        },
        {} as ObjectType,
    );
