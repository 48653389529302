import { useState } from 'react';
import { IconEdit, IconMailForward, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps } from 'components/organisms/AppTable';
import { AddressDisplay, BankAccountNumberDisplay, PhoneNumberDisplay } from 'components/molecules';
import { ChildParent, ChildStatus } from 'types';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parentTableItemFields } from 'consts/parent/parentTableItemFields';
import { getFullName } from 'utils/getFullName';
import { AppConfirmModal } from 'components/organisms';
import { Option } from 'components/atoms';
import { UserRemoveIcon } from 'assets';
import { useSendActivationEmail } from 'hooks/useSendActivationEmail/useSendActivationEmail';
import { childrenTexts, commonTexts } from 'consts/text/index';

type GuardianTableProps = {
    parents: ChildParent[];
    onEditParentClick: Option<ChildParent>['onClick'];
    onRemoveParentClick: (parentTableItem: ChildParent) => void;
    testId?: string;
    canManageTable?: boolean;
    childStatus?: ChildStatus;
};

const columns: AppTableProps<ChildParent>['columns'] = [
    {
        title: childrenTexts.childDetails.parents.parentTable.columns.name,
        dataIndex: parentTableItemFields.firstName,
        key: parentTableItemFields.firstName,
        render: (_, record) => getFullName(record.firstName, record.lastName),
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: childrenTexts.childDetails.parents.parentTable.columns.email,
        dataIndex: parentTableItemFields.email,
        key: parentTableItemFields.email,
        render: (email) => email || EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childDetails.parents.parentTable.columns.phoneNumber,
        dataIndex: parentTableItemFields.phoneNumber,
        key: parentTableItemFields.phoneNumber,
        render: (phoneNumber) => <PhoneNumberDisplay phoneNumber={phoneNumber} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childDetails.parents.parentTable.columns.bankAccount,
        dataIndex: parentTableItemFields.bankAccountNumber,
        key: parentTableItemFields.bankAccountNumber,
        render: (bankAccountNumber) => (
            <BankAccountNumberDisplay bankAccountNumber={bankAccountNumber} />
        ),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childDetails.parents.parentTable.columns.address,
        dataIndex: parentTableItemFields.street,
        key: parentTableItemFields.street,
        render: (_, record) => (
            <AddressDisplay
                address={{
                    city: record.address?.city,
                    street: record.address?.street,
                    zipCode: record.address?.zipCode,
                }}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childDetails.parents.parentTable.columns.account.account,
        dataIndex: parentTableItemFields.email,
        key: parentTableItemFields.email,
        render: (_, record) => {
            if (record.withAccount) {
                if (record.active) {
                    return childrenTexts.childDetails.parents.parentTable.columns.account.active;
                }
                return childrenTexts.childDetails.parents.parentTable.columns.account
                    .waitingForConfirm;
            }
            return childrenTexts.childDetails.parents.parentTable.columns.account.noAccount;
        },
        isVisibleAsExtendableInMobile: true,
    },
];

export const ParentTable = ({
    parents,
    onEditParentClick,
    onRemoveParentClick,
    testId,
    canManageTable,
    childStatus,
}: GuardianTableProps) => {
    const [selectedToRemoveParent, setSelectedToRemoveParent] = useState<ChildParent | null>(null);

    const { sendActivationEmail, isActivationEmailSendLoading } = useSendActivationEmail({
        mainKey: 'CHILDREN',
    });

    const handleRemoveParentConfirm = () => {
        if (!selectedToRemoveParent) {
            return;
        }
        onRemoveParentClick(selectedToRemoveParent);
        setSelectedToRemoveParent(null);
    };

    const handleRemoveParentCancel = () => setSelectedToRemoveParent(null);

    const handleRemoveParentOptionClick = (parent: ChildParent) =>
        parent.active ? setSelectedToRemoveParent(parent) : onRemoveParentClick(parent);

    const isChildActive = childStatus === 'ACTIVE';

    const options: Option<ChildParent>[] = [
        { label: commonTexts.actionLabels.edit, onClick: onEditParentClick, Icon: <IconEdit /> },
        {
            label: commonTexts.actionLabels.retryResendEmail,
            onClick: ({ userId }) => (userId ? sendActivationEmail(userId) : undefined),
            Icon: <IconMailForward />,
            checkIfVisible: ({ withAccount, active }) => withAccount && !active && isChildActive,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveParentOptionClick,
            Icon: <IconTrash />,
        },
    ];

    return (
        <>
            <AppConfirmModal
                open={!!selectedToRemoveParent}
                title={childrenTexts.childDetails.parents.parentTable.deleteModalTitle}
                text={childrenTexts.childDetails.parents.parentTable.deleteModalDescription}
                icon={<UserRemoveIcon />}
                okButtonProps={{ title: commonTexts.actionLabels.confirm, danger: true }}
                cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
                onOk={handleRemoveParentConfirm}
                onCancel={handleRemoveParentCancel}
            />
            <AppTable
                options={canManageTable ? options : undefined}
                columns={columns}
                rowKey={({ id }) => id}
                getRecordId={({ id }) => id}
                dataSource={parents}
                isTableManageUtilsHidden
                isTableHeaderHidden
                isOptionsLoading={isActivationEmailSendLoading}
                id={testId}
            />
        </>
    );
};
