import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AppButton, AppParagraph, AppTitle } from 'components/atoms';
import { authTexts } from 'consts/text';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { redirectToMailAgent } from 'utils/redirectToMailAgent';
import { CONTACT_EMAIL } from 'consts/contactEmail';
import { appPaths } from 'consts/paths/paths';
import {
    StyledAppParagraph,
    Container,
    StyledUnlinkIcon,
    GoToLoginButton,
} from './ActivateAccountLinkExpiredPage.styled';

export const ActivateAccountLinkExpiredPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useIsMobile();

    const handleGoToLoginClick = () => navigate(appPaths.auth['sign-in']);

    const handleContactWithUsClick = () => redirectToMailAgent(CONTACT_EMAIL);

    return (
        <Container vertical align="center">
            <StyledUnlinkIcon />
            <AppTitle
                textAlign="center"
                margin={0}
                marginBottom={theme.sizes.marginSmall}
                level={isMobile ? 4 : 3}
            >
                {authTexts.activateAccountLinkExpired.title}
            </AppTitle>
            <StyledAppParagraph
                textAlign="center"
                margin={0}
                marginBottom={isMobile ? 48 : theme.sizes.marginLarge}
            >
                {authTexts.activateAccountLinkExpired.description}
            </StyledAppParagraph>
            <GoToLoginButton
                type="default"
                margin={0}
                marginBottom={isMobile ? 48 : theme.sizes.marginLarge}
                onClick={handleGoToLoginClick}
            >
                {authTexts.activateAccountLinkExpired.goToLoginButtonText}
            </GoToLoginButton>
            <AppParagraph textAlign="center" margin={0}>
                {authTexts.activateAccountLinkExpired.needHelpText}
            </AppParagraph>
            <AppButton
                type="link"
                margin={0}
                fullWidth={isMobile}
                onClick={handleContactWithUsClick}
            >
                {authTexts.activateAccountLinkExpired.contactWithUsButtonText}
            </AppButton>
        </Container>
    );
};
