import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        border-radius: ${theme.borders.borderRadiusSmall}px;
        border: 1px solid ${theme.colors.primary.primary5};
        background-color: ${theme.colors.primary.primary1};
        box-sizing: border-box;
        padding: 12px ${theme.sizes.marginNormal}px;

        display: flex;
        flex-direction: column;
        gap: ${theme.sizes.marginNormal}px;
    `}
`;

export const DataRow = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: ${theme.sizes.marginNormal}px;
        border-bottom: 1px solid ${theme.colors.primary.primary3};
    `}
`;

export const BottomSectionContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.sizes.marginNormal}px;
    `}
`;

export const ChildrenListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 20px;
`;
