import { generatePath, useParams } from 'react-router-dom';
import { Details } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { TripId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { tripDTOFields } from 'consts/trip/tripDTOFields';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { PersonList } from 'components/molecules';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const GroupJournalTripDetailsPage = () => {
    const { journalId } = useJournalId();
    const { tripId: tripIdParam } = useParams();
    const {
        groupJournalsAbilities: { checkIfCanManageEvents },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageEvents = checkIfCanManageEvents();
    const tripId = (tripIdParam || '') as TripId;
    const editPagePath = generatePath(
        appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.edit.pickedTrip,
        { tripId, journalId },
    );

    const {
        data: tripData,
        isFetching: isTripDataFetching,
        isError: isFetchTripDataError,
    } = useAppQuery(
        'TRIP',
        [journalId, tripId],
        () => StaffHTTPService.trips.getTrip(journalId, tripId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title="Podgląd wycieczki"
            isLoading={isTripDataFetching}
            isError={isFetchTripDataError}
            editPagePath={canManageEvents ? editPagePath : undefined}
        >
            <Details.Item title="Nazwa wycieczki">{tripData?.[tripDTOFields.name]}</Details.Item>
            <Details.Item title="Data">
                {tripData && parseStringIntoFormattedStringDate(tripData[tripDTOFields.date])}
            </Details.Item>
            <Details.Item title="Opis wycieczki">
                {tripData?.[tripDTOFields.description]}
            </Details.Item>
            {!!tripData?.[tripDTOFields.childrenArray].length && (
                <Details.Item title="Lista obecności">
                    <PersonList personsData={tripData[tripDTOFields.childrenArray]} />
                </Details.Item>
            )}
        </Details>
    );
};
