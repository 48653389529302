import { useEffect } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ParentMailThreadAddForm } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { MailThreadDTO, MailThreadId, ParentMailThreadFormFields } from 'types';
import { messagesTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { formFields } from 'consts/form/formFields';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { parseMailThreadDTOIntoReplyParentMailThreadFormFields } from 'utils/parsers/mailThread/parseMailThreadDTOIntoReplyParentMailThreadFormFields';
import { parseReplyParentMailThreadFormFieldsIntoParentAddMailThreadDTO } from 'utils/parsers/mailThread/parseReplyParentMailThreadFormFieldsIntoParentAddMailThreadDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ParentMailThreadAddPage = () => {
    const { form, setFormData } = useEditForm<ParentMailThreadFormFields>();
    const location = useLocation();
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const mailThreadIdToReply = location.state?.mailThreadId as MailThreadId | undefined;
    const replyMode = !!mailThreadIdToReply;

    const { data: mailThreadData } = useAppQuery(
        'MAIL_THREAD',
        [mailThreadIdToReply],
        () => ParentHTTPService.mailThreads.getMailThread(mailThreadIdToReply as MailThreadId),
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (!mailThreadData || form.getFieldValue(formFields.content)) {
            return;
        }
        const parsedReplyParentMailThreadFormFields =
            parseMailThreadDTOIntoReplyParentMailThreadFormFields(mailThreadData);
        setFormData(parsedReplyParentMailThreadFormFields);
    }, [mailThreadData, form, setFormData]);

    const onAddMailThreadSuccess = (addMailThreadResponse: AxiosResponse<MailThreadDTO>) => {
        const addedMailThreadId = addMailThreadResponse.data.id;
        displaySuccess(messagesTexts.successfullyAddMessageInfo);
        statefulNavigate(
            generatePath(appPaths.app.parent.messages.pickedMailThread, {
                mailThreadId: addedMailThreadId,
            }),
            { replace: true },
        );
    };

    const {
        mutate: addMailThread,
        error: addMailThreadError,
        isLoading: isAddMailThreadLoading,
    } = useAppMutation(ParentHTTPService.mailThreads.addMailThread, {
        key: ['ADD_MAIL_THREAD'],
        onSuccess: onAddMailThreadSuccess,
        invalidateQueryKeys: [['MAIL_THREADS']],
    });

    const onAddMailThreadFinish = (parentMailThreadFormFields: ParentMailThreadFormFields) => {
        const parentAddMailThreadDTO = replyMode
            ? parseReplyParentMailThreadFormFieldsIntoParentAddMailThreadDTO(
                  parentMailThreadFormFields,
                  mailThreadIdToReply,
              )
            : parentMailThreadFormFields;
        addMailThread(parentAddMailThreadDTO);
    };

    return (
        <ParentMailThreadAddForm
            mode={replyMode ? 'reply' : 'add'}
            form={form}
            axiosError={addMailThreadError}
            isLoading={isAddMailThreadLoading}
            onFinish={onAddMailThreadFinish}
        />
    );
};
