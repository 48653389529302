import { HistoryChangeJournalDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { journalStatusesLabels } from 'consts/journal/journalStatuses';
import { journalTypesLabels } from 'consts/journal/journalTypes';
import { parseGroupChildDTOIntoJsonObject } from 'utils/parsers/jsonObject/group/parseGroupChildDTOIntoJsonObject';
import { parseGroupTeacherDTOIntoJsonObject } from 'utils/parsers/jsonObject/group/parseGroupTeacherDTOIntoJsonObject';

export const parseJournalDTOIntoJsonObject: (journalDTO: HistoryChangeJournalDTO) => object = (
    journalDTO,
) => {
    const { name, status, type, startDate, endDate, group, lessonsType, schoolYears } = journalDTO;
    const { children, teachers } = group;

    const statusLabel = journalStatusesLabels[status];
    const typeLabel = journalTypesLabels[type];
    const formattedStartDate = parseStringIntoFormattedStringDate(startDate);
    const formattedEndDate = parseStringIntoFormattedStringDate(endDate);
    const childrenJsonObjects = children.map(parseGroupChildDTOIntoJsonObject);
    const teachersJsonObjects = teachers.map(parseGroupTeacherDTOIntoJsonObject);

    return {
        [jsonObjectsTexts.journalDTO.fields.name]: name,
        [jsonObjectsTexts.journalDTO.fields.type]: typeLabel,
        [jsonObjectsTexts.journalDTO.fields.status]: statusLabel,
        [jsonObjectsTexts.journalDTO.fields.startDate]: formattedStartDate,
        [jsonObjectsTexts.journalDTO.fields.endDate]: formattedEndDate,
        [jsonObjectsTexts.journalDTO.fields.children]: childrenJsonObjects,
        [jsonObjectsTexts.journalDTO.fields.teachers]: teachersJsonObjects,
        [jsonObjectsTexts.journalDTO.fields.lessonsType]: lessonsType,
        [jsonObjectsTexts.journalDTO.fields.schoolYears]: schoolYears,
    };
};
