import { EmployeeUser } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';

export const parseEmployeeUserIntoJsonObject: (employeeUser: EmployeeUser) => object = (
    employeeUser: EmployeeUser,
) => {
    const { firstName, lastName, phoneNumber, active, email, role, groups } = employeeUser;
    const { name: roleName } = role;

    const groupNames = groups.map((group) => group.name);
    const parsedPhoneNumber = phoneNumber
        ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.employeeUser.fields.firstname]: firstName,
        [jsonObjectsTexts.employeeUser.fields.lastname]: lastName,
        [jsonObjectsTexts.employeeUser.fields.email]: email,
        [jsonObjectsTexts.employeeUser.fields.phoneNumber]: parsedPhoneNumber,
        [jsonObjectsTexts.employeeUser.fields.role]: roleName,
        [jsonObjectsTexts.employeeUser.fields.groups]: groupNames,
        [jsonObjectsTexts.employeeUser.fields.active]: active
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
