import { AppFormItemProps } from 'components/atoms';
import { ParentModalAddEditForm } from 'components/templates/parent/ParentModalAddEditForm/ParentModalAddEditForm';
import { ParentFormElementWithTable } from 'components/templates/parent';
import { useGuardianAndParentChildFormSection } from 'hooks/useGuardianAndParentChildFormSection/useGuardianAndParentChildFormSection';
import { formFields } from 'consts/form/formFields';
import { ChildParent, ParentFormFields } from 'types';
import { parseParentFormFieldsIntoChildParent } from 'utils/parsers/parent/parseParentFormFieldsIntoChildParent';
import { parseChildParentIntoParentFormFields } from 'utils/parsers/parent/parseChildParentIntoParentFormFields';

type ParentFormSectionProps = AppFormItemProps;

export const ParentFormSection = ({ ...props }: ParentFormSectionProps) => {
    const {
        tableDataItems: parentsTableItems,
        isModalFormOpen,
        selectedTableDataItemToEdit: selectedParentTableItemToEdit,
        handleModalFormOpen,
        handleModalFormClose,
        handleDataItemAdd: handleParentAdd,
        handleDataItemEdit: handleParentEdit,
        handleDataItemRemove: handleParentRemove,
        handleTableDataItemEditClick: handleParentTableItemEditClick,
    } = useGuardianAndParentChildFormSection<ChildParent>({
        formValuePath: formFields.parents,
    });

    const isSelectedParentTableItemToEdit = !!selectedParentTableItemToEdit;
    const modalFormMode = isSelectedParentTableItemToEdit ? 'edit' : 'add';
    const formName = isSelectedParentTableItemToEdit ? 'parentEdit' : 'parentAdd';

    const modalFormSubmitFormHandler = (data: ParentFormFields) => {
        const formData = parseParentFormFieldsIntoChildParent(data, selectedParentTableItemToEdit);
        return isSelectedParentTableItemToEdit
            ? handleParentEdit(formData)
            : handleParentAdd(formData);
    };

    return (
        <>
            <ParentModalAddEditForm
                formName={formName}
                open={isModalFormOpen}
                isInnerChildForm
                mode={modalFormMode}
                onSubmitForm={modalFormSubmitFormHandler}
                onCancel={handleModalFormClose}
                initialValues={
                    selectedParentTableItemToEdit
                        ? parseChildParentIntoParentFormFields(selectedParentTableItemToEdit)
                        : undefined
                }
            />
            <ParentFormElementWithTable
                parentsTableItems={parentsTableItems}
                onAddParentClick={handleModalFormOpen}
                onParentRemove={handleParentRemove}
                onParentTableItemEditClick={handleParentTableItemEditClick}
                {...props}
            />
        </>
    );
};
