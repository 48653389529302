import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    GroupJournalImportantEventAddPage,
    GroupJournalImportantEventDetailsPage,
    GroupJournalImportantEventEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalImportantEventRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalImportantEventAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.edit
            .base,
        element: (
            <JournalNavigate
                to={
                    appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents
                        .base
                }
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.edit
            .pickedImportantEvent,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalImportantEventEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.details
            .base,
        element: (
            <JournalNavigate
                to={
                    appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents
                        .base
                }
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.details
            .pickedImportantEvent,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalImportantEventDetailsPage />
            </AbilityRoute>
        ),
    },
];
