import { QualificationBasisDto } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { psychologicalHelpBasesLabels } from 'consts/psychologicalHelp/psychologicalHelpBasesLabels';

export const parseQualificationBasisDTOIntoJsonObject: (
    qualificationBasisDto: QualificationBasisDto,
) => object = (qualificationBasisDto) => {
    const { basis, description, attachments, therapyProgram, notes, progressAnalysis, child } =
        qualificationBasisDto;

    const childJsonObject = {
        [jsonObjectsTexts.qualificationBasisDto.innerObjects.qualificationBasisChildDTO.fields
            .firstname]: child.firstName,
        [jsonObjectsTexts.qualificationBasisDto.innerObjects.qualificationBasisChildDTO.fields
            .lastname]: child.lastName,
    };
    const attachmentsNames = attachments.map((attachment) => attachment.fileName);

    return {
        [jsonObjectsTexts.qualificationBasisDto.fields.child]: childJsonObject,
        [jsonObjectsTexts.qualificationBasisDto.fields.basis]: psychologicalHelpBasesLabels[basis],
        [jsonObjectsTexts.qualificationBasisDto.fields.description]: description,
        [jsonObjectsTexts.qualificationBasisDto.fields.attachments]: attachmentsNames,
        [jsonObjectsTexts.qualificationBasisDto.fields.therapyProgram]: therapyProgram,
        [jsonObjectsTexts.qualificationBasisDto.fields.notes]: notes,
        [jsonObjectsTexts.qualificationBasisDto.fields.progressAnalysis]: progressAnalysis,
    };
};
