import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';
import { employeeTexts } from 'consts/text/index';
import { useSimpleNotification } from '../useSimpleNotification/useSimpleNotification';

type SendActivationEmailProps = {
    mainKey: QueryMainKeysType;
};

export const useSendActivationEmail = ({ mainKey }: SendActivationEmailProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const { mutate: sendActivationEmail, isLoading: isActivationEmailSendLoading } = useAppMutation(
        StaffHTTPService.authentication.sendActivationEmail,
        {
            onSuccess: () => displaySuccess(employeeTexts.successfullyResendEmail),
            onError: () => displayError('Wystąpił błąd przy wysyłaniu emaila aktywacyjnego'),
            key: [mainKey],
        },
    );

    return {
        sendActivationEmail,
        isActivationEmailSendLoading,
    };
};
