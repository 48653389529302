import { useCallback } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { CollapseProps } from 'antd/es/collapse/Collapse';
import { useTheme } from 'styled-components';
import {
    CityInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { AppParagraph } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { StyledCollapse } from './AddressInputsSection.styled';
import { ExpandIcon } from './components/ExpandIcon/ExpandIcon';

const collapseKey = 'addressInputs';

export const AddressInputsSection = () => {
    const { getFieldValue } = useFormInstance();
    const theme = useTheme();

    // PanelProps type is not exported
    const expandIconRender = useCallback<Required<CollapseProps>['expandIcon']>(
        (panelProps) => <ExpandIcon isActive={panelProps.isActive} />,
        [],
    );

    const doesFormContainAddressData =
        getFieldValue(formFields.street) &&
        getFieldValue(formFields.zipCode) &&
        getFieldValue(formFields.city);

    const content = (
        <>
            <AppParagraph
                margin={0}
                marginBottom={16}
                textType="BodySmall"
                color={theme.colors.grayscale.gray9}
            >
                Jeżeli adres zamieszkania rodzica jest inny niż dziecka, dodaj adres.
            </AppParagraph>
            <StreetAddressInputElement optional />
            <ZipCodeInputElement optional />
            <CityInputElement optional />
        </>
    );

    return (
        <StyledCollapse
            defaultActiveKey={doesFormContainAddressData ? collapseKey : undefined}
            destroyInactivePanel
            expandIcon={expandIconRender}
            expandIconPosition="start"
            items={[{ key: collapseKey, label: 'Adres', children: content }]}
        />
    );
};
