import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import useForm from 'antd/es/form/hooks/useForm';
import styled from 'styled-components';
import { isAxiosError } from 'axios';
import { MailThreadId, MessageFormFields } from 'types';
import { PageBaseLayout } from 'components/layouts';
import { MailThreadOverview } from 'components/templates';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { useParentMailThreadDetailsPageNetworkManage } from './hooks/useParentMailThreadDetailsPageNetworkManage';

const StyledPageBaseLayout = styled(PageBaseLayout)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ParentMailThreadDetailsPage = () => {
    const [messageAddFormInstance] = useForm<MessageFormFields>();
    const mailThreadId = useParams<{ mailThreadId: MailThreadId }>().mailThreadId as MailThreadId;
    const messageListRef = useRef<HTMLDivElement>(null);

    const handleMessageAddRequestSuccess = () => {
        messageAddFormInstance.resetFields();
        messageListRef.current?.scrollTo({ top: 0, behavior: 'instant' });
    };

    const {
        mailThreadData,
        replyMailThreadId,
        messagesData,
        replyMailThreadDataError,
        addMessage,
        isMailThreadDataInitialLoading,
        isReplyMailThreadDataInitialLoading,
        isMessagesDataInitialLoading,
        isMailThreadDataRefetching,
        isReplyMailThreadDataRefetching,
        isMessagesDataRefetching,
        isAddMessageLoading,
        isMailThreadDataError,
        isMessagesDataError,
        hasNextMessagesDataPage,
        fetchNextMessagesDataPage,
    } = useParentMailThreadDetailsPageNetworkManage({
        mailThreadId,
        onMessageAddRequestSuccess: handleMessageAddRequestSuccess,
    });

    const handleSendMessageFormFinish = (messageData: MessageFormFields) =>
        addMessage({ mailThreadId, addMessageDTO: messageData });

    const isReplyMailThreadNotFoundError =
        isAxiosError(replyMailThreadDataError) &&
        replyMailThreadDataError.response?.data.code === apiErrorCodes.REPLY_MAIL_THREAD_NOT_FOUND;
    const isReplyMailThreadDataError =
        !!replyMailThreadDataError && !isReplyMailThreadNotFoundError;
    const isDataInitialLoading =
        isMailThreadDataInitialLoading ||
        isReplyMailThreadDataInitialLoading ||
        isMessagesDataInitialLoading;
    const isDataRefetching =
        isMailThreadDataRefetching || isReplyMailThreadDataRefetching || isMessagesDataRefetching;
    const isError = isMailThreadDataError || isReplyMailThreadDataError || isMessagesDataError;

    return (
        <StyledPageBaseLayout
            isDataInitialLoading={isDataInitialLoading}
            isDataRefetching={isDataRefetching}
            isError={isError}
        >
            {mailThreadData && messagesData && (
                <MailThreadOverview
                    mailThread={mailThreadData}
                    replyMailThreadId={replyMailThreadId}
                    messages={messagesData}
                    addMessageFormInstance={messageAddFormInstance}
                    messageListRef={messageListRef}
                    isAddMessageLoading={isAddMessageLoading}
                    hasNextMessagesPage={hasNextMessagesDataPage}
                    fetchNextMessagesPageFunction={fetchNextMessagesDataPage}
                    uploadMessageAttachmentFunction={ParentHTTPService.files.addFile}
                    fetchMessageAttachmentFunction={ParentHTTPService.files.getFile}
                    fetchAllMessageAttachmentsInZipFunction={ParentHTTPService.files.getFilesInZip}
                    onSendMessageFormFinish={handleSendMessageFormFinish}
                />
            )}
        </StyledPageBaseLayout>
    );
};
