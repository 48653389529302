import { css, styled } from 'styled-components';
import { IconClockHour4 } from '@tabler/icons-react';

export const TimeContainer = styled.div`
    max-width: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledClockIcon = styled(IconClockHour4)`
    ${({ theme }) => css`
        height: 20px;
        width: 20px;
        color: ${theme.colors.grayscale.gray6};
    `}
`;
