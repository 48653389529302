import { ReactNode } from 'react';
import {
    EmptyDisplay,
    EmptyDisplayProps,
    ErrorDisplay,
    LoadingDisplay,
} from 'components/molecules';
import { ComponentBlockingAppLoader } from 'components/atoms';
import { Header } from './PageBaseLayout.styled';

export type PageBaseLayoutProps = {
    isDataInitialLoading?: boolean;
    isDataRefetching?: boolean;
    isError?: boolean;
    isEmpty?: boolean;
    className?: string;
    emptyStateData?: EmptyDisplayProps;
    headerContent?: ReactNode;
    children?: ReactNode;
};

export const PageBaseLayout = ({
    headerContent,
    isDataInitialLoading,
    isDataRefetching,
    isError,
    isEmpty,
    className,
    emptyStateData,
    children,
}: PageBaseLayoutProps) => {
    const renderContent = () => {
        if (isDataInitialLoading) {
            return <LoadingDisplay />;
        }
        if (isError) {
            return <ErrorDisplay />;
        }
        if (isEmpty) {
            return (
                <EmptyDisplay
                    emptyInfo={emptyStateData?.emptyInfo}
                    emptyAdditionalInfo={emptyStateData?.emptyAdditionalInfo}
                    emptyIcon={emptyStateData?.emptyIcon}
                />
            );
        }
        return children;
    };

    return (
        <>
            <div className={className}>
                {headerContent && <Header>{headerContent}</Header>}
                {renderContent()}
            </div>
            {isDataRefetching && <ComponentBlockingAppLoader />}
        </>
    );
};
