import { JournalType, LabelMapper } from 'types';

export const journalTypes: Record<JournalType, JournalType> = {
    GROUP: 'GROUP',
    SPECIAL: 'SPECIAL',
};

export const journalTypesLabels: LabelMapper<JournalType> = {
    GROUP: 'Grupowy',
    SPECIAL: 'Specjalistyczny',
};
