import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { commonTexts, parentTexts } from 'consts/text';
import { absenceAddDeleteErrorParser } from 'utils/errorHandlers/absence/absenceAddDeleteErrorParser';

type ParentGroupAttendancePageNetworkManageProps = {
    dateFilterValue: string;
};

export const useParentGroupAttendancePageNetworkManage = ({
    dateFilterValue,
}: ParentGroupAttendancePageNetworkManageProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const selectedDayjsDate = parseStringIntoDayjsDate(dateFilterValue);
    const selectedYear = selectedDayjsDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDayjsDate.month());

    const {
        data: monthlyAttendanceData,
        isInitialLoading: isMonthlyAttendanceDataInitialLoading,
        isRefetching: isMonthlyAttendanceDataRefetching,
        isError: isMonthlyAttendanceDataError,
        error: monthlyAttendanceDataError,
    } = useAppQuery(
        'MONTHLY_ATTENDANCE',
        [selectedMonth, selectedYear],
        () =>
            ParentHTTPService.groupAttendance.getGroupMonthlyAttendance(
                selectedMonth,
                selectedYear,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: deleteAbsence, isLoading: isDeleteAbsenceLoading } = useAppMutation(
        ParentHTTPService.groupAttendance.deleteAbsence,
        {
            key: ['DELETE_DAILY_ATTENDANCE'],
            onSuccess: () =>
                displaySuccess(parentTexts.groupAttendance.table.deleteAbsenceSuccessMessage),
            onError: (error) =>
                displayError(
                    absenceAddDeleteErrorParser(error, commonTexts.errorMessages.actionExecution),
                ),
            invalidateQueryKeys: [['MONTHLY_ATTENDANCE', selectedMonth, selectedYear]],
        },
    );

    return {
        monthlyAttendanceData,
        isMonthlyAttendanceDataError,
        isMonthlyAttendanceDataInitialLoading,
        isDeleteDailyAttendanceLoading: isDeleteAbsenceLoading,
        isMonthlyAttendanceDataRefetching,
        monthlyAttendanceDataError,
        deleteDailyAttendance: deleteAbsence,
    };
};
