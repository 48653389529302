import { colors } from 'theme/styledComponents/colors';

export const getBarColor = (currentValue: number, maximumNumber: number) => {
    const percentageValue = (currentValue / maximumNumber) * 100;
    if (percentageValue >= 85) {
        return colors.red.red7;
    }
    if (percentageValue >= 60 && percentageValue < 85) {
        return colors.yellow.yellow6;
    }
    return colors.green.green7;
};
