import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type GroupJournalParentTeacherConferencesPageNetworkManageProps = {
    journalId: JournalId;
    onParentTeacherConferenceRemoveRequestFinish: () => void;
};

export const useGroupJournalParentTeacherConferencesPageNetworkManage = ({
    journalId,
    onParentTeacherConferenceRemoveRequestFinish,
}: GroupJournalParentTeacherConferencesPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: parentTeacherConferencesData,
        isInitialLoading: isParentTeacherConferencesDataInitialLoading,
        isRefetching: isParentTeacherConferencesDataRefetching,
        isError: isParentTeacherConferencesDataError,
    } = useAppQuery(
        'PARENT_TEACHER_CONFERENCES',
        [journalId],
        () => StaffHTTPService.parentTeacherConferences.getParentTeacherConferences(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const {
        mutate: removeParentTeacherConference,
        isLoading: isRemoveParentTeacherConferenceLoading,
    } = useAppMutation(StaffHTTPService.parentTeacherConferences.removeParentTeacherConference, {
        key: ['REMOVE_PARENT_TEACHER_CONFERENCE'],
        onSuccess: async (_, variables) => {
            onParentTeacherConferenceRemoveRequestFinish();
            await queryClient.invalidateQueries([
                institutionId,
                'PARENT_TEACHER_CONFERENCE',
                variables.journalId,
                variables.parentTeacherConferenceId,
            ]);
            displaySuccess(
                journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                    .successfullyRemovedParentTeacherConference,
            );
        },
        onError: () => {
            onParentTeacherConferenceRemoveRequestFinish();
            displayError(commonTexts.errorMessages.actionExecution);
        },
        invalidateQueryKeys: [['PARENT_TEACHER_CONFERENCES', journalId]],
    });

    return {
        parentTeacherConferencesData,
        isParentTeacherConferencesDataInitialLoading,
        isParentTeacherConferencesDataRefetching,
        isParentTeacherConferencesDataError,
        isRemoveParentTeacherConferenceLoading,
        removeParentTeacherConference,
    };
};
