import { useEffect, useState } from 'react';
import { Divider } from 'antd';
import { useAtomValue } from 'jotai/index';
import { ModulesMenu } from 'components/atoms/ModulesMenu/ModulesMenu';
import { AccountManageMenu } from 'components/atoms/AccountManageMenu/AccountManageMenu';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { NavigationChildInstitutionInfo } from 'components/atoms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { getSideMenuNavigationConfig } from './sideMenuNavigationConfig';

type SideMenuNavigationProps = {
    collapsed: boolean;
};

export const SideMenuNavigation = ({ collapsed }: SideMenuNavigationProps) => {
    const userDetails = useAtomValue(userDetailsAtom);
    const { institutionAddress: childInstitutionAddress } = useCurrentChild();

    const [isVisibleChildInstitutionInfo, setIsVisibleChildInstitutionInfo] = useState(false);

    const isMobile = useIsMobile();

    useEffect(() => {
        const isVisibleChildInstitutionInfoValue =
            (!collapsed || isMobile) && childInstitutionAddress.institutionId;
        if (!isVisibleChildInstitutionInfoValue) {
            setIsVisibleChildInstitutionInfo(false);
            return undefined;
        }
        const timeoutId = setTimeout(() => {
            setIsVisibleChildInstitutionInfo(true);
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [childInstitutionAddress.institutionId, collapsed, isMobile]);

    const sideMenuNavigationConfig = userDetails?.type
        ? getSideMenuNavigationConfig(userDetails.type)
        : null;

    if (!sideMenuNavigationConfig) {
        return null;
    }

    const { Dropdown, modulesButtonsData, accountManageItems } = sideMenuNavigationConfig;

    return (
        <>
            {Dropdown}
            <Divider />
            <ModulesMenu moduleButtonsData={modulesButtonsData} />
            <Divider />
            <AccountManageMenu menuItems={accountManageItems} />
            {isVisibleChildInstitutionInfo && (
                <NavigationChildInstitutionInfo institutionData={childInstitutionAddress} />
            )}
        </>
    );
};
