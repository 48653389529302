import { OptionGroupSelectListItem } from 'components/molecules/List/OptionGroupSelectListItem/OptionGroupSelectListItem';
import { ChildId, ChildWithParents, ChildWithParentsIds, ParentId } from 'types';
import { getFullName } from 'utils/getFullName';
import { parseParentBaseIntoSelectOptionType } from 'utils/parsers/parent/parseParentBaseIntoSelectOptionType';
import { findParentsByChildId } from './ChildrenWithParentsListItem.utils';

type ChildrenWithParentsListItemProps = {
    selectedChildrenWithParentsIds: ChildWithParentsIds[];
    childWithParents: ChildWithParents;
    onChangeOption: (isChecked: boolean, parentId: ParentId, childId: ChildId) => void;
};

export const ChildrenWithParentsListItem = ({
    childWithParents,
    selectedChildrenWithParentsIds,
    onChangeOption,
}: ChildrenWithParentsListItemProps) => {
    const selectedParentsIds = findParentsByChildId(
        selectedChildrenWithParentsIds,
        childWithParents.id,
    );
    const groupLabel = getFullName(childWithParents.firstName, childWithParents.lastName);
    const options = childWithParents.parents.map(parseParentBaseIntoSelectOptionType);

    return (
        <OptionGroupSelectListItem
            key={childWithParents.id}
            groupId={childWithParents.id}
            groupLabel={groupLabel}
            selectedValues={selectedParentsIds}
            options={options}
            onChangeOption={onChangeOption}
        />
    );
};
