import { MenuFormFields } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { MenuAddEditForm } from 'components/templates/menu/MenuAddEditForm/MenuAddEditForm';
import { parseMenuFormFieldsIntoAddUpdateMenuDTO } from 'utils/parsers/menus/parseMenuFormFieldsIntoAddMenuDTO';
import { menusTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const MenusAddPage = () => {
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const onSuccess = () => {
        displaySuccess(menusTexts.successMessage);
        statefulNavigate(appPaths.app.menu.base, { replace: true });
    };

    const {
        mutate: addMenu,
        isLoading: isAddMenuLoading,
        error: isAddMenuError,
    } = useAppMutation(StaffHTTPService.menus.addMenu, {
        key: ['ADD_MENU'],
        onSuccess,
        invalidateQueryKeys: [['MENUS']],
    });

    const onFinish = (menuData: MenuFormFields) => {
        const parsedMenuData = parseMenuFormFieldsIntoAddUpdateMenuDTO(menuData);
        addMenu({
            addMenuData: parsedMenuData,
        });
    };

    return (
        <MenuAddEditForm
            mode="add"
            isLoading={isAddMenuLoading}
            axiosError={isAddMenuError}
            onFinish={onFinish}
        />
    );
};
