import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { TripAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { TripFormFields } from 'types/forms/trip';
import { parseTripFormFieldsIntoAddUpdateTripDTO } from 'utils/parsers/trip/parseTripFormFieldsIntoAddUpdateTripDTO';
import { TripId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { formFields } from 'consts/form/formFields';
import { ErrorDisplay } from 'components/molecules';
import { parseTripDTOIntoTripFormFields } from 'utils/parsers/trip/parseTripDTOIntoTripFormFields';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalTripEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<TripFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { tripId: tripIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const tripId = (tripIdParam || '') as TripId;

    const {
        data: tripData,
        isFetching: isTripDataFetching,
        isError: isFetchTripDataError,
    } = useAppQuery(
        'TRIP',
        [journalId, tripId],
        () => StaffHTTPService.trips.getTrip(journalId, tripId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (tripData && !form.getFieldValue(formFields.name)) {
            setFormData(parseTripDTOIntoTripFormFields(tripData));
        }
    }, [tripData, form, setFormData]);

    const onEditTripSuccess = () => {
        displaySuccess('Wycieczka została edytowana');
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: updateTrip,
        error: editTripError,
        isLoading: isEditTripLoading,
    } = useAppMutation(StaffHTTPService.trips.updateTrip, {
        key: ['UPDATE_TRIP'],
        onSuccess: onEditTripSuccess,
        invalidateQueryKeys: [
            ['TRIPS', journalId],
            ['TRIP', journalId, tripId],
        ],
    });

    const onEditTripFinish = (tripFormData: TripFormFields) => {
        const parsedUpdateTripDTO = parseTripFormFieldsIntoAddUpdateTripDTO(tripFormData);
        updateTrip({ updateTripDTO: parsedUpdateTripDTO, journalId, tripId });
    };

    if (isFetchTripDataError) {
        return <ErrorDisplay />;
    }

    return (
        <TripAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            journalId={journalId}
            axiosError={editTripError}
            isLoading={isTripDataFetching || isEditTripLoading}
            onFinish={onEditTripFinish}
        />
    );
};
