import { ChildParent } from 'types';
import { AppParagraph } from 'components/atoms';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const ParentsNamesDisplay = ({ parents }: { parents: ChildParent[] }) => {
    if (!parents.length) {
        return EMPTY_VALUE_LABEL;
    }

    return parents.map(({ id, firstName }) => (
        <AppParagraph key={id} margin={0}>
            {firstName}
        </AppParagraph>
    ));
};
