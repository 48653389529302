import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { AppText } from 'components/atoms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { commonTexts, journalTexts } from 'consts/text';

type MonthlyAttendanceTableHeaderProps = {
    fullMonth: string;
    year: number;
    totalPresences: number | string;
    totalAbsences: number | string;
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const MonthlyAttendanceTableHeader = ({
    fullMonth,
    year,
    totalPresences,
    totalAbsences,
}: MonthlyAttendanceTableHeaderProps) => {
    const isMobile = useIsMobile();

    const dateText = `${capitalize(fullMonth)} ${year}`;
    const summaryTextMobile = `${journalTexts.journalDetails.common.attendance.presentShort}:${totalPresences} ${journalTexts.journalDetails.common.attendance.absentShort}:${totalAbsences}`;
    const summaryTextDesktop = `${journalTexts.journalDetails.common.attendance.totalPresences}:${totalPresences}, ${journalTexts.journalDetails.common.attendance.totalAbsences}:${totalAbsences}`;

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    <AppText textType="BodyMediumSemiBold" margin={0}>
                        {dateText}
                    </AppText>
                    <AppText textType="BodyMedium" margin={0}>
                        {summaryTextMobile}
                    </AppText>
                </>
            );
        }
        return (
            <>
                <AppText textType="BodyLargeSemiBold" margin={0}>
                    {dateText}
                </AppText>
                <AppText textType="BodyMediumSemiBold" margin={0}>
                    {commonTexts.otherLabels.all}:
                    <AppText textType="BodyMedium" margin={0}>
                        {summaryTextDesktop}
                    </AppText>
                </AppText>
            </>
        );
    };

    return <Container>{renderContent()}</Container>;
};
