import { Outlet } from 'react-router-dom';
import dayjs from 'dayjs';
import { IconTrash } from '@tabler/icons-react';
import { AppColumnsType, ChildSimpleDailyGroupAttendanceDTO } from 'types';
import { commonTexts, parentTexts } from 'consts/text';
import {
    DateDisplay,
    DatePickerWithArrows,
    ParentDailyAttendanceTypeDisplay,
} from 'components/molecules';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { AppTable } from 'components/organisms';
import { childSimpleDailyGroupAttendanceDTOFields } from 'consts/attendance/childSimpleDailyGroupAttendanceDTOFields';
import { Option } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { ParentChildJournalDataLayout } from 'components/layouts';
import { useDisabledDate } from 'hooks/useDisabledDate/useDisabledDate';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useParentGroupAttendancePageNetworkManage } from './hooks/useParentGroupAttendancePageNetworkManage';
import { FiltersContainer, StyledAddButton } from './ParentGroupAttendancePage.styled';

const columns: AppColumnsType<ChildSimpleDailyGroupAttendanceDTO> = [
    {
        title: parentTexts.groupAttendance.table.date,
        dataIndex: childSimpleDailyGroupAttendanceDTOFields.date,
        key: childSimpleDailyGroupAttendanceDTOFields.date,
        render: (date: ChildSimpleDailyGroupAttendanceDTO['date']) => <DateDisplay date={date} />,
        width: '30%',
        mobileWidth: '80%',
    },
    {
        title: parentTexts.groupAttendance.table.entryTime,
        dataIndex: childSimpleDailyGroupAttendanceDTOFields.entryTime,
        key: childSimpleDailyGroupAttendanceDTOFields.entryTime,
        render: (_, childDailyGroupAttendanceDTO) => (
            <ParentDailyAttendanceTypeDisplay
                type={childDailyGroupAttendanceDTO.type}
                time={childDailyGroupAttendanceDTO.entryTime}
            />
        ),
        width: '20%',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: parentTexts.groupAttendance.table.exitTime,
        dataIndex: childSimpleDailyGroupAttendanceDTOFields.exitTime,
        key: childSimpleDailyGroupAttendanceDTOFields.exitTime,
        render: (_, childDailyGroupAttendanceDTO) => (
            <ParentDailyAttendanceTypeDisplay
                type={childDailyGroupAttendanceDTO.type}
                time={childDailyGroupAttendanceDTO.exitTime}
            />
        ),
        width: '50%',
        isVisibleAsExtendableInMobile: true,
    },
];

export const ParentGroupAttendancePage = () => {
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const { statefulNavigate } = useNavigateWithState();

    const {
        data: journalDates,
        isInitialLoading: isJournalDatesDataInitialLoading,
        isError: isJournalDatesDataError,
    } = useAppQuery('JOURNAL_DATES', [], () => ParentHTTPService.journal.getJournalDates(), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const renderDisabledDate = useDisabledDate(journalDates);

    const parsedDateFilterValue = dateFilterValue
        ? parseStringIntoDayjsDate(dateFilterValue)
        : dayjs();
    const selectedDate = dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD');

    const {
        monthlyAttendanceData,
        isMonthlyAttendanceDataError,
        isMonthlyAttendanceDataInitialLoading,
        isMonthlyAttendanceDataRefetching,
        isDeleteDailyAttendanceLoading,
        monthlyAttendanceDataError,
        deleteDailyAttendance,
    } = useParentGroupAttendancePageNetworkManage({
        dateFilterValue: selectedDate,
    });

    const options: Option<ChildSimpleDailyGroupAttendanceDTO>[] = [
        {
            label: commonTexts.actionLabels.delete,
            checkIfVisible: (childDailyAttendanceDTO: ChildSimpleDailyGroupAttendanceDTO) =>
                childDailyAttendanceDTO[childSimpleDailyGroupAttendanceDTOFields.type] ===
                'ABSENT_PLANNED',
            onClick: (childDailyAttendanceDTO: ChildSimpleDailyGroupAttendanceDTO) =>
                deleteDailyAttendance(
                    childDailyAttendanceDTO[childSimpleDailyGroupAttendanceDTOFields.date],
                ),
            Icon: <IconTrash />,
        },
    ];

    const handleAddAbsenceButtonClick = () =>
        statefulNavigate(appPaths.app.parent.news.attendance['add-absent']);

    const isBefore = journalDates?.startDate
        ? dayjs(dateFilterValue)
              .subtract(1, 'month')
              .isBefore(parseStringIntoDayjsDate(journalDates.startDate, 'YYYY-MM-DD'), 'month')
        : true;
    const isAfter = journalDates?.endDate
        ? dayjs(dateFilterValue)
              .add(1, 'month')
              .isAfter(parseStringIntoDayjsDate(journalDates.endDate, 'YYYY-MM-DD'), 'month')
        : true;

    const additionalFilters = (
        <FiltersContainer>
            <DatePickerWithArrows
                picker="month"
                format="MMMM"
                label={parentTexts.groupAttendance.table.selectMonth}
                value={parsedDateFilterValue}
                onChange={handleChangeDateFilter}
                prevDisabled={isBefore}
                nextDisabled={isAfter}
                onArrowButtonClick={(arrowButtonType) => {
                    if (arrowButtonType === 'NEXT' && !isAfter) {
                        handleClickNextOrPrevDate?.('NEXT', 'month');
                    } else if (arrowButtonType === 'PREVIOUS' && !isBefore) {
                        handleClickNextOrPrevDate?.('PREVIOUS', 'month');
                    }
                }}
                disabledDate={renderDisabledDate}
            />
            <StyledAddButton
                addButtonLabel={parentTexts.groupAttendance.table.addAbsence}
                disabled={!monthlyAttendanceData?.days}
                onClick={handleAddAbsenceButtonClick}
            />
        </FiltersContainer>
    );

    return (
        <ParentChildJournalDataLayout error={monthlyAttendanceDataError}>
            <Outlet />
            <AppTable
                emptyInfo={commonTexts.emptyInfo.noLessons}
                options={options}
                columns={columns}
                dataSource={monthlyAttendanceData?.days}
                rowKey={({ date }) => date}
                isTableHeaderHidden
                isError={isMonthlyAttendanceDataError || isJournalDatesDataError}
                isDataInitialLoading={
                    isJournalDatesDataInitialLoading || isMonthlyAttendanceDataInitialLoading
                }
                isDataRefetching={isMonthlyAttendanceDataRefetching}
                isOptionsLoading={isDeleteDailyAttendanceLoading}
                tableManageUtilsProps={{
                    isVisibleSearchFilter: false,
                    additionalFilters,
                }}
            />
        </ParentChildJournalDataLayout>
    );
};
