import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';

export type AppCheckboxGroupProps = CheckboxGroupProps;

const StyledCheckboxGroup = styled(Checkbox.Group)`
    &.ant-checkbox-group {
        flex-direction: column;
        gap: 10px;
        padding-left: 12px;
    }
`;

export const AppCheckboxGroup = ({ ...props }: AppCheckboxGroupProps) => (
    <StyledCheckboxGroup {...props} />
);
