import { createGlobalStyle, css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';
import { IconCircleCheck, IconCircleX, IconAlertTriangle, IconX } from '@tabler/icons-react';
import { ArgsProps } from 'antd/es/notification/interface';

export const getNotificationConfig: (
    icon: React.ReactNode,
) => (options: string | ArgsProps) => ArgsProps = (icon) => (options) => {
    const isMessageOnly = typeof options === 'string';

    const baseOptions: Omit<ArgsProps, 'message'> = {
        placement: 'bottomRight',
        duration: 10,
        closeIcon: <IconX size={20} />,
        icon,
    };

    return isMessageOnly
        ? {
              ...baseOptions,
              message: options,
          }
        : {
              ...baseOptions,
              ...options,
          };
};

export const getSuccessNotificationConfig = getNotificationConfig(<IconCircleCheck size={20} />);
export const getErrorNotificationConfig = getNotificationConfig(<IconCircleX size={20} />);
export const getWarningNotificationConfig = getNotificationConfig(<IconAlertTriangle size={20} />);

export const AppNotificationsGlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
      .ant-notification .ant-notification-notice {
          padding: 16px;

          .ant-notification-notice-with-icon {
              display: flex;
              align-items: center;

              .ant-notification-notice-message {
                  ${getTextStyle('BodyMedium')};
                  margin-bottom: 0;
                  line-height: 1.4;
              }
          }
          .ant-notification-notice-close {
              top: 16px;
          }
      }
      .ant-notification .ant-notification-notice-error {
          background-color: ${theme.colors.red.red2};
          border: 1px solid ${theme.colors.red.red3};
          .ant-notification-notice-with-icon {
              .ant-notification-notice-icon {
                  color: ${theme.colors.red.red7};
              }
              .ant-notification-notice-message {
                  color: ${theme.colors.red.red7};
              }
          }
          .ant-notification-notice-close {
              color: ${theme.colors.red.red7};
              &:hover {
                  color: ${theme.colors.red.red8};
              }
          }
      }
      .ant-notification .ant-notification-notice-success {
          background-color: ${theme.colors.green.green2};
          border: 1px solid ${theme.colors.green.green3};
          .ant-notification-notice-with-icon {
              .ant-notification-notice-icon {
                  color: ${theme.colors.green.green7};
              }
              .ant-notification-notice-message {
                  color: ${theme.colors.green.green7};
              }
          }
          .ant-notification-notice-close {
              color: ${theme.colors.green.green7};

              &:hover {
                  color: ${theme.colors.green.green8};
              }
          }
      }

      .ant-notification .ant-notification-notice-warning {
          background-color: ${theme.colors.yellow.yellow2};
          border: 1px solid ${theme.colors.yellow.yellow3};
          .ant-notification-notice-with-icon {
              .ant-notification-notice-icon {
                  color: ${theme.colors.yellow.yellow7};
              }
              .ant-notification-notice-message {
                  color: ${theme.colors.yellow.yellow7};
              }
          }
          .ant-notification-notice-close {
              color: ${theme.colors.yellow.yellow7};

              &:hover {
                  color: ${theme.colors.yellow.yellow8};
              }
          }
      }
  `}

`;
