import { AxiosError } from 'axios';
import { CommonFormProps, FormModeDependedTexts, ImportantEventFormFields } from 'types';
import { AppForm, AppFormProps } from 'components/organisms';
import {
    DatePickerElement,
    DescriptionInputElement,
    FormNavButtons,
    NameInputElement,
} from 'components/molecules';
import { commonTexts, journalTexts } from 'consts/text';

const addModeTexts: FormModeDependedTexts = {
    name: 'importantEventAdd',
    title: journalTexts.journalDetails.groupJournal.events.importantEvents.addImportantEvent,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'importantEventEdit',
    title: journalTexts.journalDetails.groupJournal.events.importantEvents.editImportantEvent,
    buttonText: commonTexts.actionLabels.save,
};

type ImportantEventAddEditFormProps = Omit<
    AppFormProps<ImportantEventFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (importantEventData: ImportantEventFormFields) => void;
    };

export const ImportantEventAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    ...props
}: ImportantEventAddEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const importantEventAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<ImportantEventFormFields>
            name={name}
            title={title}
            error={importantEventAddEditError}
            withGoBack
            {...props}
        >
            <NameInputElement
                label={journalTexts.journalDetails.groupJournal.events.importantEvents.eventName}
            />
            <DatePickerElement isHalfParentWidth />
            <DescriptionInputElement
                label={
                    journalTexts.journalDetails.groupJournal.events.importantEvents.eventDescription
                }
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
                withDeviceFloppy={!isAddMode}
            />
        </AppForm>
    );
};
