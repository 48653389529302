import { AppFormItem, AppFormItemProps, AppTimePicker, AppTimePickerProps } from 'components/atoms';

type TimePickerElementProps = AppFormItemProps &
    Pick<AppTimePickerProps, 'format' | 'minuteStep'> & {
        mainLabelText: string;
        additionalLabelText: string;
        disabled?: boolean;
    };

export const SimpleTimePickerElement = ({
    mainLabelText,
    additionalLabelText,
    disabled,
    format,
    minuteStep,
    ...props
}: TimePickerElementProps) => (
    <AppFormItem
        label={
            <AppFormItem.Label
                mainText={mainLabelText}
                additionalText={additionalLabelText}
                disabled={disabled}
            />
        }
        disabled={disabled}
        {...props}
    >
        <AppTimePicker disabled={disabled} format={format} minuteStep={minuteStep} />
    </AppFormItem>
);
