import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { JournalId } from 'types';
import { commonTexts, journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type GroupJournalConsultationsPageNetworkManageProps = {
    journalId: JournalId;
    onConsultationRemoveRequestFinish: () => void;
};

export const useGroupJournalConsultationsPageNetworkManage = ({
    journalId,
    onConsultationRemoveRequestFinish,
}: GroupJournalConsultationsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: consultationsData,
        isInitialLoading: isConsultationsDataInitialLoading,
        isRefetching: isConsultationsDataRefetching,
        isError: isConsultationsDataError,
    } = useAppQuery(
        'CONSULTATIONS',
        [journalId],
        () => StaffHTTPService.groupConsultations.getGroupConsultations(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: removeGroupConsultation, isLoading: isRemoveConsultationLoading } =
        useAppMutation(StaffHTTPService.groupConsultations.removeGroupConsultation, {
            key: ['CONSULTATION', journalId],
            onSuccess: async (_, variables) => {
                onConsultationRemoveRequestFinish();
                await queryClient.invalidateQueries([
                    institutionId,
                    'CONSULTATION',
                    variables.journalId,
                    variables.consultationId,
                ]);
                displaySuccess(
                    journalTexts.journalDetails.common.consultations
                        .successfullyRemovedConsultation,
                );
            },
            onError: () => {
                onConsultationRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['CONSULTATIONS', journalId]],
        });

    return {
        consultationsData,
        isConsultationsDataRefetching,
        isConsultationsDataError,
        isRemoveConsultationLoading,
        isConsultationsDataInitialLoading,
        removeConsultation: removeGroupConsultation,
    };
};
