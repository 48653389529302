import { ChildGuardian, GuardianFormFields } from 'types';
import { parsePhoneNumberIntoFormattedPhoneNumber } from '../phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';

export const parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber: (
    childGuardian: ChildGuardian,
) => GuardianFormFields = ({ firstName, lastName, idNumber, phoneNumber }) => ({
    firstName,
    lastName,
    idNumber,
    phoneNumber: phoneNumber ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber) : '',
});
