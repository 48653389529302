import { useEffect } from 'react';
import styled from 'styled-components';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { datesTimelineValidator } from 'utils/validators';
import { dayAttendanceDTOFields } from 'consts/attendance/dayAttendanceDTOFields';
import { formFields } from 'consts/form/formFields';
import { journalTexts } from 'consts/text';
import { AppFormItem, AppTimePicker, AppTimePickerProps } from 'components/atoms';
import { GroupChildDailyAttendanceDTO, DailyAttendanceEditableRowFormFields } from 'types';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { parseDayjsTimeIntoHoursMinutesFormat } from 'utils/parsers/dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export type DailyAttendanceTimePickerElementProps = Pick<AppTimePickerProps, 'disabled'> & {
    cellKey?: string;
    childDailyAttendance?: GroupChildDailyAttendanceDTO;
    selectedDate?: string;
    handleSave?: (childDailyAttendance: GroupChildDailyAttendanceDTO) => void;
};

const StyledAppFormItem = styled(AppFormItem)`
    width: 200px;

    .ant-form-item-explain-error {
        margin-bottom: 0;
    }
    @media ${(props) => props.theme.sizes.mediaQueries.mobileMax} {
        width: auto;
    }
`;

export const DailyAttendanceTimePickerElement = ({
    cellKey,
    childDailyAttendance,
    selectedDate,
    disabled,
    handleSave,
}: DailyAttendanceTimePickerElementProps) => {
    const { setFieldsValue, validateFields } =
        useFormInstance<DailyAttendanceEditableRowFormFields>();

    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        setFieldsValue({
            entryTime: childDailyAttendance?.attendance?.[dayAttendanceDTOFields.entryTime]
                ? parseStringIntoDayjsTime(
                      childDailyAttendance.attendance[dayAttendanceDTOFields.entryTime] as string,
                  )
                : null,
            exitTime: childDailyAttendance?.attendance?.[dayAttendanceDTOFields.exitTime]
                ? parseStringIntoDayjsTime(
                      childDailyAttendance.attendance[dayAttendanceDTOFields.exitTime] as string,
                  )
                : null,
        });
    }, [childDailyAttendance?.attendance, selectedDate, setFieldsValue]);

    const handleChangeTime = async () => {
        if (!childDailyAttendance) {
            return;
        }
        try {
            const attendanceTimesValues = await validateFields();
            handleSave?.({
                child: childDailyAttendance.child,
                attendance: {
                    type: 'PRESENT',
                    entryTime: attendanceTimesValues.entryTime
                        ? parseDayjsTimeIntoHoursMinutesFormat(attendanceTimesValues.entryTime)
                        : null,
                    exitTime: attendanceTimesValues.exitTime
                        ? parseDayjsTimeIntoHoursMinutesFormat(attendanceTimesValues.exitTime)
                        : null,
                },
            });
            // Intentional, I just don't want to do anything if there is an error
        } catch (error) {
            /* empty */
        }
    };

    return (
        <StyledAppFormItem
            name={cellKey}
            initialValue={null}
            margin={0}
            optional
            hasOptionalText
            rules={[
                ({ getFieldValue: getFieldFormValue }) =>
                    datesTimelineValidator(getFieldFormValue, {
                        mode: cellKey === dayAttendanceDTOFields.entryTime ? 'start' : 'finish',
                        startValueFieldName: formFields.entryTime,
                        finishValueFieldName: formFields.exitTime,
                        isFinishValueBeforeStartValueErrorText:
                            journalTexts.journalDetails.groupJournal.attendance.daily
                                .invalidExitTimeErrorMessage,
                        isStartValueAfterFinishValueErrorText:
                            journalTexts.journalDetails.groupJournal.attendance.daily
                                .invalidEntryTimeErrorMessage,
                        unitToCompare: 'minute',
                    }),
            ]}
        >
            <AppTimePicker
                onChange={handleChangeTime}
                allowClear={false}
                isFitContentWidth
                disabled={disabled}
            />
        </StyledAppFormItem>
    );
};
