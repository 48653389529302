import { SubscriptionBasicDataDTO, SubscriptionFormFields } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';

export const parseSubscriptionBasicDataDTOIntoSubscriptionFormFields: (
    subscriptionBasicData: SubscriptionBasicDataDTO,
) => SubscriptionFormFields = (subscriptionBasicData) => {
    const { childLimit, price, periodStart } = subscriptionBasicData;
    return {
        childLimit,
        price,
        periodStart: parseStringIntoDayjsDate(periodStart),
    };
};
