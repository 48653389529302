import type { AdminAppModules, ParentAppModules, StaffAppModules } from 'types';

export const STAFF_MODULE_NAMES: Record<StaffAppModules, string> = {
    JOURNALS: 'Dzienniki',
    SETTLEMENT: 'Rozliczenia',
    // TODO: to change to MENU
    DIET: 'Wyżywienie',
    TIMETABLE: 'Harmonogram',
    MESSAGES: 'Wiadomości',
    REMOTE_LESSONS: 'e-Zajęcia',
    CALENDAR: 'Kalendarz',
    EMPLOYEES: 'Pracownicy',
    CHILDREN: 'Dzieci',
    GROUPS: 'Grupy',
    SETTINGS: 'Ustawienia',
    GROUP_JOURNALS: 'Dzienniki grupowe',
    SPECIAL_JOURNALS: 'Dzienniki specjalistyczne',
} as const;

export const PARENT_MODULE_NAMES: Record<ParentAppModules, string> = {
    PARENT_CHILD_PROFILE: 'Profil dziecka',
    PARENT_NEWS: 'Aktualne',
    // TODO: to change to PARENT_MENU
    PARENT_DIET: 'Wyżywienie',
    PARENT_SETTLEMENTS: 'Rozliczenia',
    PARENT_MESSAGES: 'Wiadomości',
} as const;

export const ADMIN_MODULE_NAMES: Record<AdminAppModules, string> = {
    ADMIN_INSTITUTIONS: 'Placówki',
} as const;

export const ALL_MODULE_NAMES = {
    ...STAFF_MODULE_NAMES,
    ...PARENT_MODULE_NAMES,
    ...ADMIN_MODULE_NAMES,
};
