import styled, { css } from 'styled-components';

export const Container = styled.span`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.sizes.marginNormal}px;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.desktopMin} {
            flex-direction: row;
            align-items: center;
        }
    `}
`;
