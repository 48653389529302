import { ReactNode } from 'react';
import { IconAlertTriangle, IconChecks } from '@tabler/icons-react';
import { messagesTexts } from 'consts/text';
import { colors } from 'theme/styledComponents/colors';

type ReadStatusDataConfigData = {
    text: string;
    icon: ReactNode;
    textColor: string;
};

export const readStatusData: ReadStatusDataConfigData = {
    text: messagesTexts.read,
    icon: <IconChecks size={20} color={colors.green.green6} />,
    textColor: colors.green.green6,
};

export const unreadStatusData: ReadStatusDataConfigData = {
    text: messagesTexts.unread,
    icon: <IconAlertTriangle size={20} color={colors.grayscale.gray8} />,
    textColor: colors.grayscale.gray8,
};
