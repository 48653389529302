import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { max300LengthRule } from 'consts/rules/textRules';

export const SubjectInputElement = ({ label, ...restProps }: AppFormItemProps) => (
    <AppFormItem
        label={label || 'Temat'}
        name={formFields.subject}
        rules={[{ required: true, message: formElementRequiredText }, max300LengthRule]}
        {...restProps}
    >
        <AppInput autoComplete="off" />
    </AppFormItem>
);
