import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { generatePath, useParams } from 'react-router-dom';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildAddEditCostAllocationForm } from 'components/templates/settlement/ChildAddEditCostAllocationForm/ChildAddEditCostAllocationForm';
import { ErrorDisplay } from 'components/molecules';
import { settlementTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { ChildId, CostAllocationFormFields, CostAllocationId } from 'types';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useEffect } from 'react';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parseCostAllocationFormFieldsToEditCostAllocationDTO } from 'utils/parsers/costAllocation/parseCostAllocationFormFieldsToEditCostAllocationDTO';
import { parseCostAllocationDTOToCostAllocationFormFields } from 'utils/parsers/costAllocation/parseCostAllocationDTOToCostAllocationFormFields';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ChildrenEditCostAllocationPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<CostAllocationFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { childId, costAllocationId } = useParams<{
        childId: ChildId;
        costAllocationId: CostAllocationId;
    }>();

    const {
        data: costAllocationData,
        isFetching: isCostAllocationDataFetching,
        isError: isFetchCostAllocationDataError,
    } = useAppQuery(
        'COST_ALLOCATION',
        [costAllocationId],
        () =>
            StaffHTTPService.settlements.getCostAllocation({
                costAllocationId: costAllocationId as CostAllocationId,
            }),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (costAllocationData) {
            setFormData(parseCostAllocationDTOToCostAllocationFormFields(costAllocationData));
        }
    }, [costAllocationData, form, setFormData]);

    const onEditCostAllocationSuccess = () => {
        displaySuccess(settlementTexts.children.costAllocation.editSuccess);
        statefulNavigate(
            generatePath(appPaths.app.settlement.children.pickedChild.details.base, {
                childId: childId as ChildId,
            }),
        );
    };

    const {
        mutate: editCostAllocation,
        error: editCostAllocationError,
        isLoading: isEditCostAllocationLoading,
    } = useAppMutation(StaffHTTPService.settlements.editCostAllocation, {
        key: ['EDIT_COST_ALLOCATION'],
        onSuccess: onEditCostAllocationSuccess,
        invalidateQueryKeys: [
            ['COST_ALLOCATION', costAllocationId],
            ['CHILD_SETTLEMENT', childId],
            ['CHILD_SETTLEMENT_COST_SPLIT', childId],
        ],
    });

    const onEditCostAllocationFinish = (costAllocation: CostAllocationFormFields) =>
        editCostAllocation({
            costAllocation: parseCostAllocationFormFieldsToEditCostAllocationDTO(
                costAllocation,
                costAllocationId as CostAllocationId,
            ),
        });

    const redirectToChildrenSettlementPage = (replace?: boolean) =>
        statefulNavigate(appPaths.app.settlement.children.base, { replace });

    if (!childId || !costAllocationId) {
        redirectToChildrenSettlementPage();
    }

    if (isFetchCostAllocationDataError) {
        return <ErrorDisplay />;
    }

    return (
        <ChildAddEditCostAllocationForm
            mode="edit"
            form={form}
            axiosError={editCostAllocationError}
            isLoading={isEditCostAllocationLoading || isCostAllocationDataFetching}
            onFinish={onEditCostAllocationFinish}
            initialValues={formInitialData}
        />
    );
};
