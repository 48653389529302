import { AppFormItemProps } from 'components/atoms';
import { SimpleInputNumberElement } from 'components/molecules/SimpleFormElements';
import { ZlotyIcon } from 'assets';
import { commonTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';

type CurrencyInputProps = AppFormItemProps;

const TWO_DECIMAL_PLACES = 2;

export const CurrencyInputElement = ({
    name = formFields.amount,
    label = commonTexts.dataLabels.amount,
    ...restProps
}: CurrencyInputProps) => (
    <SimpleInputNumberElement
        name={name}
        label={label}
        precision={TWO_DECIMAL_PLACES}
        addonAfter={<ZlotyIcon />}
        {...restProps}
    />
);
