import styled, { css } from 'styled-components';
import { AppButton } from 'components/atoms';

export const OuterContainer = styled.div`
    ${({ theme }) => css`
        display: inline-flex;
        flex-direction: column;
        gap: ${theme.sizes.marginSmall}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
        }
    `}
`;

export const InnerContainer = styled.div`
    display: inline-flex;
    gap: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const ArrowButtonsContainer = styled.div`
    display: inline-flex;
    gap: 12px;
`;

export const ArrowButton = styled(AppButton)`
    &.ant-btn.ant-btn-icon-only {
        width: auto;
        margin: 0;
        padding: 10px;
    }
`;
