export const historyChangeFields = {
    id: 'id',
    createdAt: 'createdAt',
    userName: 'userName',
    historyType: 'historyType',
    refersTo: 'refersTo',
    refersToSub: 'refersToSub',
    specialAction: 'specialAction',
    nameChanged: 'nameChanged',
    nameChange: 'nameChange',
    deletedName: 'deletedName',
    unarchivedName: 'unarchivedName',
    resultObjectType: 'resultObjectType',
    result: 'result',
} as const;
