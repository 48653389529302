import { RemoteLessonEmployeeRecipientDTO } from 'types';
import { ExtendedDataNode } from 'components/atoms';

export const parseRemoteLessonReceiverDTOIntoExtendedDataNode: (
    remoteLessonReceiverDTO: RemoteLessonEmployeeRecipientDTO,
) => ExtendedDataNode = (remoteLessonReceiverDTO) => {
    const { userId, name } = remoteLessonReceiverDTO;
    return {
        title: name,
        key: userId,
    };
};
