import {
    Query,
    QueryCache,
    QueryClient,
    QueryClientProvider,
    QueryClientProviderProps,
} from '@tanstack/react-query';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';

type AppQueryClientProviderProps = Pick<QueryClientProviderProps, 'children'>;

export const AppQueryClientProvider = ({ children }: AppQueryClientProviderProps) => {
    const { displayError } = useSimpleNotification();

    const handleError = (_: unknown, query: Query) => {
        const errorMessage = query.meta?.errorMessage;
        if (!errorMessage || typeof errorMessage !== 'string') {
            return;
        }
        displayError(errorMessage);
    };

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: handleError,
        }),
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
