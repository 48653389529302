import { AdditionalLessonDTO } from 'types';
import { AppText } from 'components/atoms/CommonAppComponents';
import { settlementTexts } from 'consts/text';
import { noAdditionalLessonConfigurationColor } from 'consts/additionalLessons/noAdditionalLessonConfigurationColor';
import { getAdditionalLessonPriceText } from 'utils/getAdditionalLessonPriceText';

export const AdditionalLessonsPriceColumn = ({
    additionalLesson,
}: {
    additionalLesson: AdditionalLessonDTO;
}) =>
    additionalLesson.configured && !additionalLesson.free ? (
        <AppText margin={0}>
            {additionalLesson.price &&
                additionalLesson.paymentType &&
                getAdditionalLessonPriceText(additionalLesson.price, additionalLesson.paymentType)}
        </AppText>
    ) : (
        <AppText
            margin={0}
            color={additionalLesson.configured ? '' : noAdditionalLessonConfigurationColor}
        >
            {settlementTexts.extraLessons.noPrice}
        </AppText>
    );
