import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import { styled } from 'styled-components';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import { StyledCalendarEventIcon, StyledXIcon } from 'components/atoms/StyledIcons';
import { pickerFocusStyle } from 'consts/styles/pickerFocusStyle';

export type AppDatePickerProps = DatePickerProps;

const DATE_FORMAT = 'DD/MM/YYYY';

const StyledDatePicker = styled(DatePicker)<AppDatePickerProps>`
    width: 100%;
    padding: 10px 16px;
    ${inputAndPickerBorderStyle}
    ${pickerFocusStyle}
`;

export const AppDatePicker = (props: AppDatePickerProps) => (
    <StyledDatePicker
        suffixIcon={<StyledCalendarEventIcon />}
        allowClear={{ clearIcon: <StyledXIcon /> }}
        placeholder=""
        format={DATE_FORMAT}
        {...props}
    />
);
