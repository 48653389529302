import styled, { css } from 'styled-components';
import { IconChevronRight } from '@tabler/icons-react';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export const StyledExpandIcon = styled(IconChevronRight).withConfig({
    shouldForwardProp: getShouldForwardProp(['expanded']),
})<{ expanded?: boolean }>`
    ${({ theme, expanded }) => css`
        transition: rotate 0.3s;
        color: ${theme.colors.grayscale.gray10};

        ${expanded &&
        css`
            rotate: 90deg;
        `};
    `}
`;
