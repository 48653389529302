import { AppFormItem, AppFormItemProps, AppTextArea } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { max2000LengthRule } from 'consts/rules/textRules';

export const SupervisionFormInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Forma nadzoru"
        name={formFields.supervisionForm}
        rules={[{ required: true, message: formElementRequiredText }, max2000LengthRule]}
        {...props}
    >
        <AppTextArea autoComplete="off" />
    </AppFormItem>
);
