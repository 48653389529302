import DOMPurify from 'dompurify';
import { DailyScheduleDTO, DailyScheduleFormFields } from 'types';

export const parseDailyScheduleDTOIntoDailyScheduleFormFields: (
    dailyScheduleDTO: DailyScheduleDTO,
) => DailyScheduleFormFields = (dailyScheduleDTO) => ({
    description: dailyScheduleDTO.description
        ? DOMPurify.sanitize(dailyScheduleDTO.description)
        : '',
});
