import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import {
    CateringPaymentAdditionalInformationElement,
    FormNavButtons,
    MealAbsenceReportTypePickerElement,
    MealPaymentTypePickerElement,
    MealProviderTypePickerElement,
    SimpleTimePickerElement,
} from 'components/molecules';
import { AppForm, AppFormProps } from 'components/organisms';
import { formFields } from 'consts/form/formFields';
import { TIME_LABEL } from 'consts/labels/common';
import { commonTexts, settingsTexts } from 'consts/text';
import { useForm, useWatch } from 'rc-field-form';
import { SettlementMealsFormFields } from 'types/forms/settlementMeals';
import { useEffect } from 'react';

export type SettlementMealsEditFormProps = Omit<
    AppFormProps<SettlementMealsFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    onFinish: (principalData: SettlementMealsFormFields) => void;
    isViewMode?: boolean;
};

export const SettlementMealsEditForm = ({
    form,
    axiosError,
    initialValues,
    isViewMode,
    ...props
}: SettlementMealsEditFormProps) => {
    const settlementMealEditError = axiosError ? commonTexts.errorMessages.unknown : '';
    const [formInstance] = useForm<SettlementMealsFormFields>(form);
    const mealProviderTypeValue = useWatch(formFields.mealProviderType, formInstance);
    const isMealProviderTypeCatering = mealProviderTypeValue === 'CATERING';

    useEffect(() => {
        if (mealProviderTypeValue === 'OWN_KITCHEN') {
            formInstance.setFieldsValue({
                cateringAccountNumber: undefined,
                cateringTransferName: undefined,
                recipientName: undefined,
            });
        }
    }, [formInstance, mealProviderTypeValue]);

    return (
        <AppForm<SettlementMealsFormFields>
            withHeader={false}
            form={form}
            name="settlementMealsEdit"
            error={settlementMealEditError}
            {...props}
        >
            <MealPaymentTypePickerElement disabled={isViewMode} />
            <MealAbsenceReportTypePickerElement disabled={isViewMode} />
            <SimpleTimePickerElement
                mainLabelText={
                    settingsTexts.settlements.meals.formLabels.absenceReportTimeMainLabel
                }
                additionalLabelText={TIME_LABEL}
                name={formFields.absenceReportTime}
                disabled={isViewMode}
            />
            <MealProviderTypePickerElement disabled={isViewMode} />
            {isMealProviderTypeCatering && (
                <CateringPaymentAdditionalInformationElement
                    form={formInstance}
                    title={
                        settingsTexts.settlements.meals.cateringPaymentAdditionalInformationTitle
                    }
                    description={
                        settingsTexts.settlements.meals
                            .cateringPaymentAdditionalInformationDescription
                    }
                    disabled={isViewMode}
                />
            )}
            {!isViewMode && (
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={commonTexts.actionLabels.save}
                    leftIcon={<IconDeviceFloppy size={20} />}
                />
            )}
        </AppForm>
    );
};
