import { formFields } from 'consts/form/formFields';
import { AppFormItem, AppFormItemProps } from 'components/atoms';
import { ParentAndGuardianFormItemContentContainer } from 'components/molecules';
import { ChildGuardian } from 'types';
import { WithId } from 'types/common/WithId';
import { childrenTexts } from 'consts/text/index';
import { GuardianTable, GuardianTableProps } from '../GuardianTable/GuardianTable';

type GuardianFormElementProps = AppFormItemProps &
    Pick<GuardianTableProps, 'onEditGuardianClick' | 'onRemoveGuardianClick'> & {
        guardiansTableItems: WithId<ChildGuardian>[];
        onAddGuardianClick: () => void;
    };

export const GuardianFormElementWithTable = ({
    guardiansTableItems,
    onAddGuardianClick,
    onRemoveGuardianClick,
    onEditGuardianClick,

    ...props
}: GuardianFormElementProps) => {
    const tableElement = guardiansTableItems.length ? (
        <GuardianTable
            guardians={guardiansTableItems}
            canManageTable
            onEditGuardianClick={onEditGuardianClick}
            onRemoveGuardianClick={onRemoveGuardianClick}
        />
    ) : null;

    return (
        <AppFormItem name={formFields.guardians} label="Osoby upoważnione do odbioru" {...props}>
            <ParentAndGuardianFormItemContentContainer
                addButtonText={childrenTexts.addGuardianButtonLabel}
                onAddButtonClick={onAddGuardianClick}
                tableElement={tableElement}
            />
        </AppFormItem>
    );
};
