import { ChildParent, ParentFormFields, ParentId, UserId } from 'types';
import { uniqueId } from 'lodash';
import { parseFormattedPhoneNumberIntoPhoneNumber } from '../phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';
import { parseFormattedBankAccountNumberIntoBankAccountNumber } from '../bankAccountNumber/parseFormattedBankAccountNumberIntoBankAccountNumber';

export const parseParentFormFieldsIntoChildParent: (
    parentFormFields: ParentFormFields,
    previousParent: ChildParent | null,
) => ChildParent = (parentFormFields, previousParent) => {
    const { firstName, lastName, bankAccountNumber, phoneNumber, email, street, zipCode, city } =
        parentFormFields;

    const address =
        street || zipCode || city
            ? {
                  street,
                  zipCode,
                  city,
              }
            : null;
    const active = previousParent?.email === email ? previousParent?.active : null;

    return {
        id: previousParent?.id || (uniqueId() as ParentId),
        userId: previousParent?.userId || ('' as UserId),
        firstName,
        lastName,
        email: email || null,
        phoneNumber: phoneNumber ? parseFormattedPhoneNumberIntoPhoneNumber(phoneNumber) : null,
        bankAccountNumber: bankAccountNumber
            ? parseFormattedBankAccountNumberIntoBankAccountNumber(bankAccountNumber)
            : null,
        address,
        active: !!active,
        withAccount: !!email,
    };
};
