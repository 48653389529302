import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppTable } from 'components/organisms';
import { DatePickerWithArrows, TeacherAttendanceTypeDisplay } from 'components/molecules';
import { getFullName } from 'utils/getFullName';
import { commonTexts, journalTexts, timetableTexts } from 'consts/text';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import debounce from 'lodash/debounce';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { useSearchFilter } from 'hooks/useSearchFilter/useSearchFilter';
import { workingTimeDTOFields } from 'consts/workingTime/workingTime';
import { useTimetableAbilities } from 'abilities';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { AppColumnsType, WorkingTimeTeacherDTO } from 'types';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { WorkingTimeEditableRow } from './components/WorkingTimeEditableRow';
import { WorkingTimeEditableCell } from './components/WorkingTimeEditableCell';

const baseColumnsData: AppColumnsType<WorkingTimeTeacherDTO> = [
    {
        title: commonTexts.dataLabels.lastnameAndFirstname,
        dataIndex: workingTimeDTOFields.firstName,
        key: workingTimeDTOFields.firstName,
        render: (_, workingTime: WorkingTimeTeacherDTO) =>
            getFullName(workingTime.firstName, workingTime.lastName),
        width: '30%',
        mobileWidth: '100%',
    },
    {
        title: journalTexts.journalDetails.groupJournal.attendance.daily.entryTime,
        dataIndex: workingTimeDTOFields.absent,
        key: workingTimeDTOFields.timeFrom,
        render: () => <TeacherAttendanceTypeDisplay isAbsent />,
        width: '20%',
        editable: true,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.attendance.daily.exitTime,
        dataIndex: workingTimeDTOFields.absent,
        key: workingTimeDTOFields.timeTo,
        render: () => <TeacherAttendanceTypeDisplay isAbsent />,
        width: '50%',
        editable: true,
        isVisibleAsExtendableInMobile: true,
    },
];

export const WorkingTimePage = () => {
    const { handleSearchInput, searchFilterValue } = useSearchFilter({});
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });

    const { timetableAbilities } = useTimetableAbilities();
    const { displayError } = useSimpleNotification();
    // check only own because backend will display only own working time
    const { checkIfCanManageOwnTimetable } = timetableAbilities;
    const canManageOwnTimetable = checkIfCanManageOwnTimetable();
    const parsedDateFilterValue = dateFilterValue
        ? parseStringIntoDayjsDate(dateFilterValue)
        : dayjs();
    const selectedDate = dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD');

    const {
        data: workingTimeData,
        isError: isWorkingTimeDataError,
        isFetching: isWorkingTimeDataFetching,
    } = useAppQuery(
        'WORKING_TIME',
        [selectedDate, searchFilterValue],
        () => StaffHTTPService.employeeSchedule.getWorkingTime(selectedDate, searchFilterValue),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const handleUpdateWorkingTimeError = () => {
        displayError(commonTexts.errorMessages.actionExecution);
    };

    const { mutate: updateWorkingTime } = useAppMutation(
        StaffHTTPService.employeeSchedule.updateWorkingTime,
        {
            key: ['UPDATE_WORKING_TIME'],
            onError: handleUpdateWorkingTimeError,
            invalidateQueryKeys: [['WORKING_TIME', selectedDate]],
        },
    );

    const handleSetWorkingTime = useCallback(
        (workingTime: WorkingTimeTeacherDTO) => {
            updateWorkingTime({
                teacherId: workingTime.teacherId,
                date: selectedDate,
                updateCreateWorkingTimeDTO: {
                    timeFrom: workingTime.timeFrom,
                    timeTo: workingTime.timeTo,
                },
            });
        },
        [selectedDate, updateWorkingTime],
    );

    const columns = useMemo(
        () =>
            baseColumnsData.map((baseColumnData) => {
                if (!baseColumnData.editable) {
                    return baseColumnData;
                }
                return {
                    ...baseColumnData,
                    onCell: (teacherWorkingTime: WorkingTimeTeacherDTO) => ({
                        teacherWorkingTime,
                        selectedDate,
                        editable: baseColumnData.editable,
                        title: baseColumnData.title,
                        cellKey: baseColumnData.key,
                        disabled: !canManageOwnTimetable,
                        handleSave: handleSetWorkingTime,
                    }),
                };
            }),
        [selectedDate, canManageOwnTimetable, handleSetWorkingTime],
    );

    const filters = (
        <DatePickerWithArrows
            label={commonTexts.actionLabels.selectDate}
            value={parsedDateFilterValue}
            onChange={handleChangeDateFilter}
            onArrowButtonClick={(arrowButtonType) =>
                handleClickNextOrPrevDate(arrowButtonType, 'day')
            }
        />
    );

    return (
        <AppTable
            title={timetableTexts.workingTime.workingTimetableTitle}
            emptyInfo={timetableTexts.workingTime.emptyWorkingTimetable}
            columns={columns as AppColumnsType<WorkingTimeTeacherDTO>}
            dataSource={workingTimeData?.teachers}
            rowKey={({ teacherId }) => teacherId}
            isError={isWorkingTimeDataError}
            isDataInitialLoading={isWorkingTimeDataFetching}
            tableManageUtilsProps={{
                searchInputProps: {
                    defaultValue: searchFilterValue,
                    onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
                    width: '25%',
                },
                additionalFilters: filters,
                isDisplayedAsSeparateMenuOnMobile: true,
            }}
            editableCellsProps={{
                editableRow: WorkingTimeEditableRow,
                editableCell: WorkingTimeEditableCell,
            }}
        />
    );
};
