import { get, memoize } from 'lodash';
import { appRoutes } from 'consts/paths/paths';
import {
    IGNORED_BASE,
    OFFSET_FOR_PATH,
    OFFSET_FOR_SNIPPETS_WITH_BASE,
} from 'components/atoms/SideMenuBreadcrumb/consts';

export const getMatchedPath = memoize((pathSnippets: string[], routesNames: typeof appRoutes) =>
    pathSnippets.reduce(
        (previous, current) => {
            const updated = previous.concat(current);
            const possibleRouteName = get(routesNames, updated);
            const previousPath = get(routesNames, previous);
            const routeName =
                typeof possibleRouteName === 'object' ? possibleRouteName.base : possibleRouteName;
            if (routeName) {
                return updated;
            }
            const nestedObject = Object.keys(previousPath).filter((key) => key !== 'base');
            const isDeepest =
                typeof get(routesNames, previous.concat(nestedObject[0])) === 'string';
            if (nestedObject.length !== 1 && !isDeepest) {
                throw new Error(
                    'appRoutes has been misdefined! Nested id route should include 2 keys only. Base and string or nested object with base :id',
                );
            }
            return previous.concat(nestedObject[0]);
        },
        [IGNORED_BASE],
    ),
);

export const getPathSnippetDataByIndex = (
    index: number,
    pathSnippets: string[],
    routes: typeof appRoutes,
    routesNames: typeof appRoutes,
    searchParams: URLSearchParams,
) => {
    const recreatedPath = getMatchedPath(pathSnippets, routes).slice(
        0,
        index + OFFSET_FOR_SNIPPETS_WITH_BASE,
    );
    const url = `/${[IGNORED_BASE]
        .concat(pathSnippets.slice(0, index + OFFSET_FOR_PATH))
        .join('/')}`;
    const isLast = pathSnippets.length === index + 1;

    // check path value
    const routeKeyValue = get(routes, recreatedPath);
    const routePath: string =
        typeof routeKeyValue === 'object' ? routeKeyValue.base : routeKeyValue;
    const isId = routePath.startsWith(':');
    const renamedId = searchParams?.get(routePath);

    // check path requested name
    const possibleRouteName = get(routesNames, recreatedPath);
    const routeName: string =
        typeof possibleRouteName === 'object' ? possibleRouteName.base : possibleRouteName;

    const nameForId = isId && renamedId ? renamedId : undefined;
    return {
        isLast,
        routeName: isId ? nameForId : routeName,
        url,
        recreatedPath,
        isId,
    };
};
