import { ChangeEvent, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import debounce from 'lodash/debounce';
import { AppSearchInput, NewMailThreadButton } from 'components/atoms';
import { Filter, MailThreadList } from 'components/molecules';
import { commonTexts } from 'consts/text';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { MailThreadId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { useSearchFilter } from 'hooks/useSearchFilter/useSearchFilter';
import { useAppInfiniteQuery } from 'services/reactQuery/useAppInfiniteQuery';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'consts/pagination';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { parsePageableResponseDataIntoArray } from 'utils/parsers/api/parsePageableResponseDataIntoArray';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import {
    Container,
    FiltersContainer,
    MailThreadListContainer,
} from './ParentMailThreadsPreviewSideBar.styled';

export const ParentMailThreadsPreviewSideBar = () => {
    const { searchFilterValue, handleSearchInput } = useSearchFilter({});
    const { mailThreadId } = useParams<{ mailThreadId: MailThreadId }>();
    const { statefulNavigate } = useNavigateWithState();
    const isMobile = useIsMobile();
    const theme = useTheme();

    const {
        data: pageableMailThreadsData,
        isInitialLoading: isMailThreadsDataInitialLoading,
        isRefetching: isMailThreadsDataRefetching,
        isError: isMailThreadsDataError,
        fetchNextPage: fetchNextMailThreadsDataPage,
        hasNextPage: hasNextMailThreadsDataPage,
    } = useAppInfiniteQuery('MAIL_THREADS', [searchFilterValue], ({ pageParam = DEFAULT_PAGE }) =>
        ParentHTTPService.mailThreads.getMailThreads({
            search: searchFilterValue,
            paginationParams: { page: pageParam, size: DEFAULT_PAGE_SIZE },
        }),
    );

    const handleInputSearchValue = (event: ChangeEvent<HTMLInputElement>) =>
        handleSearchInput(event.target.value);

    const handleMailThreadSelect = (selectedMailThreadId: MailThreadId) =>
        statefulNavigate(
            generatePath(appPaths.app.parent.messages.pickedMailThread, {
                mailThreadId: selectedMailThreadId,
            }),
        );

    const handleNewMailThreadButtonClick = () => statefulNavigate(appPaths.app.parent.messages.add);

    const mailThreadsData = useMemo(() => {
        if (!pageableMailThreadsData) {
            return undefined;
        }
        return parsePageableResponseDataIntoArray(pageableMailThreadsData);
    }, [pageableMailThreadsData]);

    return (
        <Container>
            <FiltersContainer>
                {isMobile && (
                    <NewMailThreadButton
                        marginBottom={12}
                        onClick={handleNewMailThreadButtonClick}
                    />
                )}
                <Filter
                    input={
                        <AppSearchInput
                            onInput={debounce(handleInputSearchValue, DEBOUNCE_DELAY)}
                        />
                    }
                    label={commonTexts.actionLabels.searchTwo}
                />
            </FiltersContainer>
            <MailThreadListContainer>
                <MailThreadList
                    mailThreadId={mailThreadId}
                    mailThreadsData={mailThreadsData}
                    searchValue={searchFilterValue}
                    isInitialLoading={isMailThreadsDataInitialLoading}
                    isRefetching={isMailThreadsDataRefetching}
                    isError={isMailThreadsDataError}
                    hasNextDataPage={hasNextMailThreadsDataPage}
                    fetchNextDataPage={fetchNextMailThreadsDataPage}
                    handleSelect={handleMailThreadSelect}
                />
                {!isMobile && (
                    <NewMailThreadButton
                        marginTop={theme.sizes.marginNormal}
                        onClick={handleNewMailThreadButtonClick}
                    />
                )}
            </MailThreadListContainer>
        </Container>
    );
};
