import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { JournalId, JournalType } from 'types';

export const getJournalDetailsPathname = (journalId: JournalId, journalType: JournalType) =>
    generatePath(
        journalType === 'GROUP'
            ? appPaths.app.journals.groupJournalDetails.pickedJournal.base
            : appPaths.app.journals.specialJournalDetails.pickedJournal.base,
        {
            journalId,
        },
    );
