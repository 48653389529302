import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { GroupId, SelectOptionType } from 'types';
import { formFields } from 'consts/form/formFields';

const employeeModeFormItemData = {
    name: formFields.groups,
    label: 'Przypisz do grupy',
};

const childModeFormItemData = {
    name: formFields.group,
    label: 'Dodaj do grupy',
};

export type ChildGroupPickerElementProps = AppFormItemProps & {
    options?: SelectOptionType<GroupId>[];
    isLoading?: boolean;
    version?: 'EMPLOYEE' | 'CHILD';
};

export const ChildGroupPickerElement = ({
    options,
    isLoading,
    version = 'CHILD',
    ...props
}: ChildGroupPickerElementProps) => {
    const isEmployee = version === 'EMPLOYEE';
    const { name, label } = isEmployee ? employeeModeFormItemData : childModeFormItemData;

    return (
        <AppFormItem label={label} optional name={name} hasOptionalText {...props}>
            <AppSelect
                mode={isEmployee ? 'multiple' : undefined}
                options={options}
                loading={isLoading}
            />
        </AppFormItem>
    );
};
