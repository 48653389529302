import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ParentTeacherConferenceAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ParentTeacherConferenceFormFields, ParentTeacherConferenceId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { formFields } from 'consts/form/formFields';
import { ErrorDisplay } from 'components/molecules';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { journalTexts } from 'consts/text';
import { parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO } from 'utils/parsers/parentTeacherConference/parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO';
import { parseParentTeacherConferenceDTOIntoParentTeacherConferenceFormFields } from 'utils/parsers/parentTeacherConference/parseParentTeacherConferenceDTOIntoParentTeacherConferenceFormFields';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalParentTeacherConferenceEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<ParentTeacherConferenceFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { parentTeacherConferenceId: parentTeacherConferenceIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const parentTeacherConferenceId = parentTeacherConferenceIdParam as ParentTeacherConferenceId;

    const {
        data: parentTeacherConferenceData,
        isFetching: isParentTeacherConferenceDataFetching,
        isError: isFetchParentTeacherConferenceDataError,
    } = useAppQuery(
        'PARENT_TEACHER_CONFERENCE',
        [journalId, parentTeacherConferenceId],
        () =>
            StaffHTTPService.parentTeacherConferences.getParentTeacherConference(
                journalId,
                parentTeacherConferenceId,
            ),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (parentTeacherConferenceData && !form.getFieldValue(formFields.subject)) {
            setFormData(
                parseParentTeacherConferenceDTOIntoParentTeacherConferenceFormFields(
                    parentTeacherConferenceData,
                ),
            );
        }
    }, [parentTeacherConferenceData, form, setFormData]);

    const onEditParentTeacherConferenceSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                .successfullyUpdatedParentTeacherConference,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events
                    .parentTeacherConferences.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: updateParentTeacherConference,
        error: editParentTeacherConferenceError,
        isLoading: isEditParentTeacherConferenceLoading,
    } = useAppMutation(StaffHTTPService.parentTeacherConferences.updateParentTeacherConference, {
        key: ['UPDATE_PARENT_TEACHER_CONFERENCE'],
        onSuccess: onEditParentTeacherConferenceSuccess,
        invalidateQueryKeys: [
            ['PARENT_TEACHER_CONFERENCES', journalId],
            ['PARENT_TEACHER_CONFERENCE', journalId, parentTeacherConferenceId],
        ],
    });

    const onEditParentTeacherConferenceFinish = (
        parentTeacherConferenceFormData: ParentTeacherConferenceFormFields,
    ) => {
        const parsedUpdateParentTeacherConferenceDTO =
            parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO(
                parentTeacherConferenceFormData,
            );
        updateParentTeacherConference({
            journalId,
            parentTeacherConferenceId,
            updateParentTeacherConferenceDTO: parsedUpdateParentTeacherConferenceDTO,
        });
    };

    if (isFetchParentTeacherConferenceDataError) {
        return <ErrorDisplay />;
    }

    return (
        <ParentTeacherConferenceAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            journalId={journalId}
            axiosError={editParentTeacherConferenceError}
            isLoading={
                isParentTeacherConferenceDataFetching || isEditParentTeacherConferenceLoading
            }
            onFinish={onEditParentTeacherConferenceFinish}
        />
    );
};
