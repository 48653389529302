import React from 'react';
import { Tooltip } from 'antd';
import { createGlobalStyle, useTheme } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export type AppTooltipProps = {
    children: React.ReactNode;
    tooltip: string;
};

const GlobalStyle = createGlobalStyle`
  .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
        text-align: center;
        ${getTextStyle('BodyMedium')}
  }
`;

export const AppTooltip = ({ children, tooltip }: AppTooltipProps) => {
    const theme = useTheme();
    return (
        <Tooltip title={tooltip} color={theme.colors.tooltip.tooltip2}>
            <GlobalStyle />
            {children}
        </Tooltip>
    );
};
