import { colors } from 'theme/styledComponents/colors';
import { ColorMapper, SubscriptionType } from 'types';

export const tagColorsBySubscriptionType: ColorMapper<SubscriptionType> = {
    [SubscriptionType.ACTIVE]: {
        textColor: colors.green.green8,
        backgroundColor: colors.green.green3,
    },
    [SubscriptionType.BLOCKED]: {
        textColor: colors.grayscale.gray9,
        backgroundColor: colors.grayscale.gray3,
    },
    [SubscriptionType.TESTING]: {
        textColor: colors.violet.violet2,
        backgroundColor: colors.violet.violet1,
    },
};
