import { LoadingDisplay } from 'components/molecules';
import { TableBodyWrapperProps, TableBodyWrapper } from '../TableBodyWrapper/TableBodyWrapper';

export type TableLoadingDisplayProps = Omit<TableBodyWrapperProps, 'children'>;

export const TableLoadingDisplay = (props: TableLoadingDisplayProps) => (
    <TableBodyWrapper {...props}>
        <LoadingDisplay />
    </TableBodyWrapper>
);
