import { Form } from 'antd';
import { ChildrenPaymentModalAddEditForm } from 'components/templates/settlement/ChildrenPaymentModalAddEditForm/ChildrenPaymentModalAddEditForm';
import { noop } from 'lodash';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ChildrenAddPaymentPage = () => {
    const [form] = Form.useForm();
    const { statefulNavigate } = useNavigateWithState();

    const onCancel = () => {
        statefulNavigate('../');
    };

    return <ChildrenPaymentModalAddEditForm onCancel={onCancel} onFinish={noop} form={form} />;
};
