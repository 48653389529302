import { useEffect, useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { MailThreadId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, messagesTexts } from 'consts/text';
import { useAppInfiniteQuery } from 'services/reactQuery/useAppInfiniteQuery';
import { parsePageableResponseDataIntoArray } from 'utils/parsers/api/parsePageableResponseDataIntoArray';
import { useUpdateSelectedMailThreadReadStatus } from 'hooks/useUpdateSelectedMailThreadReadStatus/useUpdateSelectedMailThreadReadStatus';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useInvalidateMailThreads } from 'hooks/useInvalidateMailThreads/useInvalidateMailThreads';

type MailThreadDetailsPageNetworkManageProps = {
    mailThreadId: MailThreadId;
    onMailThreadRemoveRequestFinish: (isSuccess: boolean) => void;
    onMessageAddRequestSuccess: () => void;
};

const MESSAGES_PAGE_SIZE = 20;

export const useMailThreadDetailsPageNetworkManage = ({
    mailThreadId,
    onMailThreadRemoveRequestFinish,
    onMessageAddRequestSuccess,
}: MailThreadDetailsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();
    const { displaySuccess, displayError } = useSimpleNotification();

    const { invalidateUnreadMailThreadsStatusData } = useInvalidateMailThreads();

    const {
        data: mailThreadData,
        isInitialLoading: isMailThreadDataInitialLoading,
        isRefetching: isMailThreadDataRefetching,
        isError: isMailThreadDataError,
    } = useAppQuery(
        'MAIL_THREAD',
        [mailThreadId],
        () => StaffHTTPService.mailThreads.getMailThread(mailThreadId),
        {
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        invalidateUnreadMailThreadsStatusData();
    }, [invalidateUnreadMailThreadsStatusData, isMailThreadDataRefetching]);

    const {
        data: replyMailThreadData,
        isInitialLoading: isReplyMailThreadDataInitialLoading,
        isRefetching: isReplyMailThreadDataRefetching,
        error: replyMailThreadDataError,
    } = useAppQuery(
        'REPLY_MAIL_THREAD',
        [mailThreadId],
        () => StaffHTTPService.mailThreads.getReplyMailThread(mailThreadId),
        { refetchOnWindowFocus: false, retry: false },
    );

    const {
        data: pageableMessagesData,
        isInitialLoading: isMessagesDataInitialLoading,
        isRefetching: isMessagesDataRefetching,
        isError: isMessagesDataError,
        fetchNextPage: fetchNextMessagesDataPage,
        hasNextPage: hasNextMessagesDataPage,
    } = useAppInfiniteQuery(
        'MESSAGES',
        [mailThreadId],
        ({ pageParam }) =>
            StaffHTTPService.mailThreads.messages.getMailThreadMessages({
                mailThreadId,
                paginationParams: {
                    lastReadMessageSeqNumber: pageParam,
                    pageSize: MESSAGES_PAGE_SIZE,
                },
            }),
        {
            getNextPageParam: (lastPage) =>
                lastPage.data.readAll ? undefined : lastPage.data.lastReadMessageNumber,
        },
    );

    const { mutate: removeMailThread, isLoading: isRemoveMailThreadLoading } = useAppMutation(
        StaffHTTPService.mailThreads.removeMailThread,
        {
            key: ['REMOVE_MAIL_THREAD'],
            onSuccess: () => {
                onMailThreadRemoveRequestFinish(true);
                displaySuccess(messagesTexts.successfullyRemoveMailThreadInfo);
            },
            onError: () => {
                onMailThreadRemoveRequestFinish(false);
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [
                ['MAIL_THREADS'],
                ['MAIL_THREAD', mailThreadId],
                ['MESSAGES', mailThreadId],
            ],
        },
    );

    const { mutate: addMessage, isLoading: isAddMessageLoading } = useAppMutation(
        StaffHTTPService.mailThreads.messages.addMessage,
        {
            key: ['ADD_MESSAGE'],
            onSuccess: () => {
                onMessageAddRequestSuccess();
                displaySuccess(messagesTexts.successfullyAddMessageInfo);
            },
            onError: () => displayError(messagesTexts.addMessageErrorInfo),
            invalidateQueryKeys: [
                ['MAIL_THREADS'],
                ['MAIL_THREAD', mailThreadId],
                ['MESSAGES', mailThreadId],
            ],
        },
    );

    useUpdateSelectedMailThreadReadStatus({
        mailThreadId,
        isLastMessageRead: mailThreadData?.lastMessage.read,
        institutionId,
    });

    const messagesData = useMemo(() => {
        if (!pageableMessagesData) {
            return undefined;
        }
        return parsePageableResponseDataIntoArray(pageableMessagesData);
    }, [pageableMessagesData]);

    return {
        mailThreadData,
        replyMailThreadId: replyMailThreadData?.id,
        messagesData,
        replyMailThreadDataError,
        removeMailThread,
        addMessage,
        isMailThreadDataInitialLoading,
        isReplyMailThreadDataInitialLoading,
        isMessagesDataInitialLoading,
        isMailThreadDataRefetching,
        isReplyMailThreadDataRefetching,
        isMessagesDataRefetching,
        isAddMessageLoading,
        isRemoveMailThreadLoading,
        isMailThreadDataError,
        isMessagesDataError,
        hasNextMessagesDataPage,
        fetchNextMessagesDataPage,
    };
};
