import { ParentAbsenceFormFields, AddChildAbsencesParams } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseParentAbsenceFormFieldsIntoAddChildAbsencesParams: (
    parentAbsenceFormFields: ParentAbsenceFormFields,
) => AddChildAbsencesParams = (parentAbsenceFormFields) => {
    const { startDate, endDate } = parentAbsenceFormFields;
    return {
        startDate: parseDayjsDateIntoString(startDate, 'YYYY-MM-DD'),
        endDate: parseDayjsDateIntoString(endDate, 'YYYY-MM-DD'),
    };
};
