import { AppFormItem, AppFormItemProps, AppMaskedInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { zipCodeRegExp } from 'consts/regexps';
import { commonTexts } from 'consts/text';

export const ZipCodeInputElement = ({ optional, ...props }: AppFormItemProps) => (
    <AppFormItem
        label={commonTexts.inputLabels.zipCode}
        name={formFields.zipCode}
        optional={optional}
        hasOptionalText={optional}
        rules={[
            {
                pattern: zipCodeRegExp,
                message: commonTexts.errorMessages.invalidZipCode,
                required: !optional,
            },
        ]}
        {...props}
    >
        <AppMaskedInput mask="00-000" autoComplete="postal-code" />
    </AppFormItem>
);
