import { ReactNode } from 'react';
import { IconDotsVertical } from '@tabler/icons-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ComponentBlockingAppLoader } from 'components/atoms/CommonAppComponents/AppLoader/AppLoader';
import {
    AppPopover,
    AppPopoverProps,
} from 'components/atoms/CommonAppComponents/AppPopover/AppPopover';
import { OpenAppOptionsButton, OpenAppOptionsButtonSize } from './AppOptionsButton.styled';

export type AppOptionsButtonProps = Pick<AppPopoverProps, 'placement'> & {
    optionButtons: ReactNode;
    isVisiblePopover: boolean;
    isOptionsLoading?: boolean;
    openOptionsButtonSize?: OpenAppOptionsButtonSize;
    onPopoverHide: () => void;
    onDotsButtonClick: () => void;
};

export const AppOptionsButton = ({
    optionButtons,
    isVisiblePopover,
    isOptionsLoading,
    openOptionsButtonSize,
    placement = 'leftTop',
    onPopoverHide,
    onDotsButtonClick,
}: AppOptionsButtonProps) => {
    const popoverContent = (
        <>
            {optionButtons}
            {isOptionsLoading && <ComponentBlockingAppLoader />}
        </>
    );

    return (
        <OutsideClickHandler onOutsideClick={onPopoverHide}>
            <AppPopover
                open={isVisiblePopover}
                placement={placement}
                arrow={false}
                content={popoverContent}
            >
                <OpenAppOptionsButton
                    icon={<IconDotsVertical size={20} />}
                    withoutBorderRadius
                    ghost
                    margin={0}
                    onClick={onDotsButtonClick}
                    size={openOptionsButtonSize}
                />
            </AppPopover>
        </OutsideClickHandler>
    );
};
