import { AdminAppModules, ModuleData, StaffMainAppModules, ParentAppModules } from 'types';
import { STAFF_MODULES_DATA } from 'consts/modules/staffModules';
import { ADMIN_MODULES_DATA } from 'consts/modules/adminModules';
import { PARENT_MODULES_DATA } from 'consts/modules/parentModules';

export type ModuleButtonEntry = ModuleData & {
    children?: Omit<ModuleButtonEntry, 'Icon' | 'children'>[];
    getDefaultQueryParams?: () => string;
};

export type StaffModulesButtonsData = Record<StaffMainAppModules, ModuleButtonEntry>;

export const STAFF_MODULES_BUTTONS_DATA: StaffModulesButtonsData = {
    EMPLOYEES: STAFF_MODULES_DATA.EMPLOYEES,
    CHILDREN: STAFF_MODULES_DATA.CHILDREN,
    GROUPS: STAFF_MODULES_DATA.GROUPS,
    JOURNALS: {
        ...STAFF_MODULES_DATA.JOURNALS,
    },
    SETTLEMENT: STAFF_MODULES_DATA.SETTLEMENT,
    CALENDAR: STAFF_MODULES_DATA.CALENDAR,
    TIMETABLE: STAFF_MODULES_DATA.TIMETABLE,
    REMOTE_LESSONS: STAFF_MODULES_DATA.REMOTE_LESSONS,
    DIET: STAFF_MODULES_DATA.DIET,
    SETTINGS: STAFF_MODULES_DATA.SETTINGS,
    MESSAGES: STAFF_MODULES_DATA.MESSAGES,
};

export type ParentModulesButtonsData = Record<ParentAppModules, ModuleButtonEntry>;

export const PARENT_MODULES_BUTTONS_DATA: ParentModulesButtonsData = {
    PARENT_NEWS: PARENT_MODULES_DATA.PARENT_NEWS,
    PARENT_CHILD_PROFILE: PARENT_MODULES_DATA.PARENT_CHILD_PROFILE,
    PARENT_DIET: PARENT_MODULES_DATA.PARENT_DIET,
    PARENT_SETTLEMENTS: PARENT_MODULES_DATA.PARENT_SETTLEMENTS,
    PARENT_MESSAGES: PARENT_MODULES_DATA.PARENT_MESSAGES,
};

export type AdminModulesButtonsData = Record<AdminAppModules, ModuleButtonEntry>;

export const ADMIN_MODULES_BUTTONS_DATA: AdminModulesButtonsData = {
    ADMIN_INSTITUTIONS: ADMIN_MODULES_DATA.ADMIN_INSTITUTIONS,
};

export type ModulesButtonsData =
    | StaffModulesButtonsData
    | ParentModulesButtonsData
    | AdminModulesButtonsData;
