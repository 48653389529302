import styled from 'styled-components';
import { AppParagraph } from 'components/atoms/CommonAppComponents';

type RemoteLessonMessageDataItemProps = {
    title: string;
    description: string;
};

const Container = styled.div`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const RemoteLessonMessageDataItem = ({
    title,
    description,
}: RemoteLessonMessageDataItemProps) => (
    <Container>
        <AppParagraph textType="BodyMediumSemiBold" margin={0}>
            {title}
        </AppParagraph>
        <AppParagraph margin={0}>{description}</AppParagraph>
    </Container>
);
