import { Filter } from 'components/molecules';
import { GetHistoryOfChangesFilterKey, GetHistoryOfChangesFilters } from 'types';
import { RawValueType } from 'rc-select/lib/interface';
import { commonTexts } from 'consts/text';
import { AppTreeSelect } from 'components/atoms';
import { historyChangesFiltersOptions } from 'consts/historyChange/historyChangesFiltersOptions';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { MULTIPLE_FILTER_VALUE_SEPARATOR } from 'consts/filters/common/multipleFilterValueSeparator';

type FiltersValues = Pick<GetHistoryOfChangesFilters, 'startDate' | 'endDate'> & {
    referTo?: string[];
    historyTypes?: string[];
};

type HistoryOfChangesPageFiltersProps = {
    filtersValues?: FiltersValues;
    onChangeFilter?: (filterKey: GetHistoryOfChangesFilterKey, value: string | string[]) => void;
    onChangeFilters?: (filterValues: Partial<Record<GetHistoryOfChangesFilterKey, string>>) => void;
};

export const HistoryOfChangesPageFilters = ({
    filtersValues,
    onChangeFilter,
    onChangeFilters,
}: HistoryOfChangesPageFiltersProps) => {
    const onChangeFiltersTreeValues = (values: string[], triggerValue: RawValueType) => {
        if (typeof triggerValue === 'string') {
            const [filterKey] = triggerValue.split(MULTIPLE_FILTER_VALUE_SEPARATOR);
            onChangeFilter?.(filterKey as GetHistoryOfChangesFilterKey, values);
        }
    };

    const startDateValue = filtersValues?.startDate
        ? parseStringIntoDayjsDate(filtersValues?.startDate)
        : null;
    const endDateValue = filtersValues?.endDate
        ? parseStringIntoDayjsDate(filtersValues?.endDate)
        : null;

    return (
        <>
            <Filter
                input={
                    <Filter.RangePicker
                        picker="date"
                        value={[startDateValue, endDateValue]}
                        onChange={(_, dateStrings) =>
                            onChangeFilters?.({
                                startDate: dateStrings[0],
                                endDate: dateStrings[1],
                            })
                        }
                    />
                }
                label={commonTexts.actionLabels.selectRange}
            />
            <Filter
                input={
                    <AppTreeSelect
                        treeData={historyChangesFiltersOptions}
                        value={[
                            ...(filtersValues?.referTo || []),
                            ...(filtersValues?.historyTypes || []),
                        ]}
                        placeholder={commonTexts.actionLabels.select}
                        treeCheckable
                        onChange={(value, _, { triggerValue }) =>
                            onChangeFiltersTreeValues(value, triggerValue)
                        }
                        switcherIcon={null}
                        treeDefaultExpandAll
                        maxTagCount="responsive"
                        showCheckedStrategy="SHOW_CHILD"
                    />
                }
                label={commonTexts.dataLabels.filters}
                width="25%"
            />
        </>
    );
};
