import { UpdatePasswordFormFields } from 'types';
import { FormNavButtons, PasswordInputElement } from 'components/molecules';
import { AppForm, AppFormProps } from 'components/organisms';
import { accountTexts, authTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';

type PasswordUpdateFormProps = Omit<
    AppFormProps<UpdatePasswordFormFields>,
    'width' | 'title' | 'name' | 'onFinish'
> & {
    isLoading?: boolean;
    onFinish: (updatePasswordData: UpdatePasswordFormFields) => void;
};

export const PasswordUpdateForm = ({ initialValues, ...props }: PasswordUpdateFormProps) => (
    <AppForm<UpdatePasswordFormFields>
        name="passwordUpdate"
        initialValues={initialValues}
        title={authTexts.changePasswordLabel}
        {...props}
    >
        <PasswordInputElement
            name={formFields.oldPassword}
            labelPrefix={accountTexts.passwordUpdate.oldWithCapitalCharacter}
            withPasswordValidatingRules={false}
        />
        <PasswordInputElement
            labelPrefix={accountTexts.passwordUpdate.newWithCapitalCharacter}
            withRulesVisible
            newPassword
        />
        <PasswordInputElement
            labelPrefix={accountTexts.passwordUpdate.new}
            passwordRepeat
            newPassword
        />
        <FormNavButtons
            formValuesBeforeChanges={initialValues}
            buttonText={authTexts.changePasswordLabel}
        />
    </AppForm>
);
