import { ChildParent, ParentFormFields } from 'types';
import { parsePhoneNumberIntoFormattedPhoneNumber } from '../phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from '../bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';

export const parseChildParentIntoParentFormFields: (
    childParent: ChildParent,
) => ParentFormFields = ({
    firstName,
    lastName,
    phoneNumber,
    bankAccountNumber,
    email,
    address,
}) => ({
    firstName,
    lastName,
    email: email || '',
    phoneNumber: phoneNumber ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber) : '',
    bankAccountNumber: bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : '',
    street: address?.street || '',
    zipCode: address?.zipCode || '',
    city: address?.city || '',
});
