import InfiniteScroll, { Props } from 'react-infinite-scroll-component';
import { AppLoader } from 'components/atoms/CommonAppComponents';
import { AppInfiniteScrollGlobalStyle, LoaderContainer } from './AppInfiniteScroll.styled';

type AppInfiniteScrollProps = Omit<Props, 'loader'>;

export const AppInfiniteScroll = ({ children, inverse, ...restProps }: AppInfiniteScrollProps) => (
    <>
        <AppInfiniteScrollGlobalStyle />
        <InfiniteScroll
            inverse={inverse}
            loader={
                <LoaderContainer>
                    <AppLoader />
                </LoaderContainer>
            }
            style={inverse ? { display: 'flex', flexDirection: 'column-reverse' } : undefined}
            {...restProps}
        >
            {children}
        </InfiniteScroll>
    </>
);
