import React, { Dispatch } from 'react';

type Props = { formError: string; setFormError: Dispatch<string> };

export const AppFormContext = React.createContext<Props>({
    formError: '',
    setFormError: (newState) => {
        console.error(`FormContext is not ready. Error ${newState} has not been set`);
    },
});
