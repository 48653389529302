import React from 'react';
import { FullInstitutionSubscriptionDTO } from 'types';

export type AppInstitutionSubscriptionContextProps = {
    institutionSubscriptionDataItem: FullInstitutionSubscriptionDTO | null;
};

export const AppInstitutionSubscriptionContext =
    React.createContext<AppInstitutionSubscriptionContextProps>({
        institutionSubscriptionDataItem: null,
    });
