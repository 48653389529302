import { ChildId, RemoteLessonGroupRecipientDTO, SelectOptionType } from 'types';
import { parseMailThreadChildReceiverDTOIntoSelectOptionType } from 'utils/parsers/mailThread/parseMailThreadChildReceiverDTOIntoSelectOptionType';

export const parseRemoteLessonChildrenGroupDTOsIntoSelectOptionTypeItems: (
    remoteLessonChildrenGroupDTOs: RemoteLessonGroupRecipientDTO[],
) => SelectOptionType<ChildId, string>[] = (remoteLessonChildrenGroupDTOs) =>
    remoteLessonChildrenGroupDTOs.flatMap((remoteLessonChildrenGroupDTO) =>
        remoteLessonChildrenGroupDTO.children.map((child) =>
            parseMailThreadChildReceiverDTOIntoSelectOptionType(child),
        ),
    );
