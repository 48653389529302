import styled from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';

export type LegalTextProps = {
    type: 'TERMS_OF_USE' | 'PRIVACY_POLICY';
    children: string;
};

export const LegalDocumentRedirectText = styled.span`
    ${getTextStyle('BodySmallSemiBold')};
    cursor: pointer;
`;

export const LegalText = ({ type, children }: LegalTextProps) => {
    const navigate = useNavigate();
    const placeToNavigate: Record<LegalTextProps['type'], string> = {
        PRIVACY_POLICY: appPaths.auth.legals['privacy-policy'],
        TERMS_OF_USE: appPaths.auth.legals['terms-of-use'],
    };

    return (
        <LegalDocumentRedirectText onClick={() => navigate(placeToNavigate[type])}>
            {children}
        </LegalDocumentRedirectText>
    );
};
