import { useMemo } from 'react';
import { generatePath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { AppGoBackButton, SimpleMenuItem, TopAppMenu, TopAppMenuProps } from 'components/atoms';
import { JournalId, JournalType, PermissionName } from 'types';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { groupJournalMenuItems, specialJournalMenuItems } from './menuItems';
import { getSelectedMenuItemKey } from './utils';

type JournalDetailsLayoutProps = {
    journalType: JournalType;
};

export const JournalDetailsLayout = ({ journalType }: JournalDetailsLayoutProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { journalId: journalIdParam } = useParams();
    const userDetails = useUserDetails();

    const journalId = journalIdParam as JournalId;
    const defaultMenuSelectedKey = generatePath(getSelectedMenuItemKey(pathname, journalType), {
        journalId,
    });

    const handleGoToJournalsPage = () => navigate(appPaths.app.journals.base);

    const onMenuItemClick: TopAppMenuProps['onClick'] = ({ key }) => navigate(key);

    const menuItems = useMemo<SimpleMenuItem[]>(() => {
        const userPermissionsNames = userDetails.role.permissions.map(
            (permission) => permission.name,
        );
        const journalMenuItems =
            journalType === 'GROUP' ? groupJournalMenuItems : specialJournalMenuItems;
        const userPermissionsForTabs = Object.keys(journalMenuItems).filter((tabPermission) =>
            userPermissionsNames.includes(tabPermission as PermissionName),
        );
        return userPermissionsForTabs.map((tabPermission) => {
            const menuItem = journalMenuItems[tabPermission as PermissionName];
            return {
                key: generatePath(menuItem?.key || '', { journalId }),
                label: menuItem?.label || '',
            };
        });
    }, [journalId, journalType, userDetails.role.permissions]);

    if (!journalId) {
        navigate(appPaths.app.journals.base);
    }

    return (
        <div>
            <AppGoBackButton onClick={handleGoToJournalsPage} />
            <TopAppMenu
                items={menuItems}
                selectedKeys={[defaultMenuSelectedKey]}
                onClick={onMenuItemClick}
            />
            <Outlet />
        </div>
    );
};
