import { PropsWithChildren } from 'react';
import { isAxiosError } from 'axios';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { ErrorDisplay } from 'components/molecules';
import { parentTexts } from 'consts/text';
import { ApiErrorType } from 'services/reactQuery/apiErrorType';

type ParentChildJournalDataLayoutProps = PropsWithChildren & {
    error: ApiErrorType | null;
};

export const ParentChildJournalDataLayout = ({
    error,
    children,
}: ParentChildJournalDataLayoutProps) => {
    const isNoChildJournalError =
        isAxiosError(error) && error.response?.data.code === apiErrorCodes.JOURNAL_NOT_FOUND;

    if (isNoChildJournalError) {
        return <ErrorDisplay title={parentTexts.noChildJournalErrorMessage} hideSubTitle />;
    }

    return children;
};
