import { Row } from 'antd';
import { Details } from 'components/organisms';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, settingsTexts } from 'consts/text';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const InvoiceDetailsPage = () => {
    const {
        data: invoiceData,
        isFetching: isInvoiceDataFetching,
        isError: isInvoiceDataError,
    } = useAppQuery('SETTINGS_INVOICE_DATA', [], StaffHTTPService.invoiceData.getInvoiceData, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    return (
        <Details
            title={settingsTexts.invoiceData.invoiceData}
            isLoading={isInvoiceDataFetching}
            editPagePath={appPaths.app.settings['invoice-details'].edit}
            isVisibleGoBackButton={false}
            isError={isInvoiceDataError}
        >
            <Details.Item title={settingsTexts.invoiceData.form.companyNameLabel}>
                {invoiceData?.name || EMPTY_VALUE_LABEL}
            </Details.Item>
            <Details.Item title={settingsTexts.invoiceData.form.nipNumberLabel}>
                {invoiceData?.nip || EMPTY_VALUE_LABEL}
            </Details.Item>
            <Details.Item title={commonTexts.inputLabels.streetAddress}>
                {invoiceData?.street || EMPTY_VALUE_LABEL}
            </Details.Item>
            <Row>
                <Details.Item title={commonTexts.inputLabels.zipCode}>
                    {invoiceData?.zipCode || EMPTY_VALUE_LABEL}
                </Details.Item>
                <Details.Item title={commonTexts.inputLabels.city}>
                    {invoiceData?.city || EMPTY_VALUE_LABEL}
                </Details.Item>
            </Row>
        </Details>
    );
};
