import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, settingsTexts } from 'consts/text';
import { generatePath } from 'react-router-dom';
import { DietDTO, DietId } from 'types';
import { dietFields } from 'consts/diets/dietFields';
import { useMemo, useState } from 'react';
import { PriceDisplay } from 'components/molecules';
import { useSettingsAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useDietsPageNetworkManage } from './hooks/useDietsPageNetworkManage';

const columns: AppTableProps<DietDTO>['columns'] = [
    {
        title: settingsTexts.diets.columnNames.name,
        dataIndex: dietFields.name,
        key: dietFields.name,
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: settingsTexts.diets.columnNames.breakfastPrice,
        dataIndex: dietFields.breakfastPrice,
        key: dietFields.breakfastPrice,
        render: (breakfast) => <PriceDisplay value={breakfast} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: settingsTexts.diets.columnNames.soupPrice,
        dataIndex: dietFields.soupPrice,
        key: dietFields.soupPrice,
        render: (soup) => <PriceDisplay value={soup} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: settingsTexts.diets.columnNames.dinnerPrice,
        dataIndex: dietFields.dinnerPrice,
        key: dietFields.dinnerPrice,
        render: (dinner) => <PriceDisplay value={dinner} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: settingsTexts.diets.columnNames.snackPrice,
        dataIndex: dietFields.snackPrice,
        key: dietFields.snackPrice,
        render: (snack) => <PriceDisplay value={snack} />,
        isVisibleAsExtendableInMobile: true,
    },
];

export const DietsPage = () => {
    const [selectedToRemoveDietId, setSelectedToRemoveDietId] = useState<DietId | null>(null);
    const { statefulNavigate } = useNavigateWithState();
    const { settingsAbilities } = useSettingsAbilities();
    const { checkIfCanManageDietSettings } = settingsAbilities;
    const canManageDietSettings = checkIfCanManageDietSettings();

    const handleRemoveDietClick = (diet: DietDTO) => setSelectedToRemoveDietId(diet.id);
    const onDietRemoveRequestFinish = () => setSelectedToRemoveDietId(null);

    const getOptions = useMemo(() => {
        if (!canManageDietSettings) {
            return undefined;
        }

        return [
            {
                label: commonTexts.actionLabels.edit,
                onClick: (diet: DietDTO) =>
                    statefulNavigate(
                        generatePath(appPaths.app.settings.diets.edit.pickedDiet, {
                            dietId: diet.id,
                        }),
                    ),
                Icon: <IconEdit />,
            },
            {
                label: commonTexts.actionLabels.delete,
                onClick: handleRemoveDietClick,
                Icon: <IconTrash />,
            },
        ];
    }, [canManageDietSettings, statefulNavigate]);

    const {
        dietsData,
        isDietsDataInitialLoading,
        isDietsDataRefetching,
        isDietsDataError,
        removeDiet,
        isRemoveDietLoading,
    } = useDietsPageNetworkManage({
        onDietRemoveRequestFinish,
    });

    const handleRemoveDietConfirm = () => {
        if (!selectedToRemoveDietId) {
            return;
        }
        removeDiet(selectedToRemoveDietId);
    };

    const navigateToDietAdd = () => statefulNavigate(appPaths.app.settings.diets.add);

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveDietId}
                title={settingsTexts.diets.removeModalTitle}
                confirmActionLoading={isRemoveDietLoading}
                onOk={handleRemoveDietConfirm}
                onCancel={onDietRemoveRequestFinish}
            />
            <AppTable<DietDTO>
                title={settingsTexts.diets.dietListTitle}
                addButtonLabel={canManageDietSettings ? settingsTexts.diets.addDiet : undefined}
                onAddButtonClick={navigateToDietAdd}
                options={getOptions}
                columns={columns}
                dataSource={dietsData || []}
                isError={isDietsDataError}
                emptyInfo={settingsTexts.diets.emptyDietList}
                isDataInitialLoading={isDietsDataInitialLoading}
                isDataRefetching={isDietsDataRefetching}
                isOptionsLoading={isRemoveDietLoading}
                isTableManageUtilsHidden
                rowKey={({ id }) => id}
            />
        </>
    );
};
