import { GroupConsultationChildDTO } from 'types';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppParagraph } from 'components/atoms';
import { getFullName } from 'utils/getFullName';

type ConsultationParentsDisplayProps = {
    consultationChildren: GroupConsultationChildDTO[];
};

export const ConsultationParentsDisplay = ({
    consultationChildren,
}: ConsultationParentsDisplayProps) => {
    if (!consultationChildren.length) {
        return EMPTY_VALUE_LABEL;
    }

    return consultationChildren.map(({ parents }) =>
        parents.map(({ id, firstName, lastName }) => (
            <AppParagraph key={id} margin={0}>
                {getFullName(firstName, lastName)}
            </AppParagraph>
        )),
    );
};
