import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { IconDownload } from '@tabler/icons-react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FileData } from 'types';
import { fileDownload } from 'utils/fileDownload';
import { FileListItem } from 'components/atoms';
import { DownloadButton } from './AttachmentListItem.styled';

export type AttachmentListItemProps = {
    fileData: FileData;
    fetchAttachmentFunction?: (fileUri: string) => Promise<AxiosResponse<string>>;
};

export const AttachmentListItem = ({
    fileData,
    fetchAttachmentFunction = StaffHTTPService.files.getFile,
}: AttachmentListItemProps) => {
    const [isEnabledFileFetching, setIsEnabledFileFetching] = useState<boolean>(false);

    const { data: file, isFetching: isFileFetching } = useAppQuery(
        'FILES',
        [fileData.fileUri],
        () => fetchAttachmentFunction(fileData.fileUri || ''),
        {
            enabled: isEnabledFileFetching,
            refetchOnWindowFocus: false,
            meta: {
                errorMessage:
                    'Podczas pobierania pliku wystąpił błąd, odśwież stronę i spróbuj ponownie',
            },
        },
    );

    useEffect(() => {
        if (file && isEnabledFileFetching) {
            const blob = new Blob([file]);
            const url = URL.createObjectURL(blob);
            fileDownload(url, fileData.fileName);
            setIsEnabledFileFetching(false);
        }
    }, [file, isEnabledFileFetching, fileData.fileName]);

    const handleEnableFileFetching = () => setIsEnabledFileFetching(true);

    const downloadButton = (
        <DownloadButton
            type="link"
            icon={<IconDownload size={20} />}
            loading={isFileFetching}
            onClick={handleEnableFileFetching}
        >
            Pobierz
        </DownloadButton>
    );

    return (
        <FileListItem
            key={fileData.fileUri}
            mode="DETAILS"
            fileData={fileData}
            actionButton={downloadButton}
        />
    );
};
