import { generatePath, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Details } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { PsychologicalHelpId } from 'types';
import { psychologicalHelpFormsLabels } from 'consts/psychologicalHelp/psychologicalHelpFormsLabels';
import { psychologicalHelpDTOFields } from 'consts/psychologicalHelp/psychologicalHelpDTOFields';
import { getFullName } from 'utils/getFullName';
import { psychologicalHelpBasesLabels } from 'consts/psychologicalHelp/psychologicalHelpBasesLabels';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { appPaths } from 'consts/paths/paths';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { journalTexts } from 'consts/text';
import { AttachmentListItem } from 'components/molecules';
import { AppList } from 'components/atoms';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalPsychologicalHelpDetailsPage = () => {
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const { psychologicalHelpId: psychologicalHelpIdParam } = useParams();
    const theme = useTheme();
    const {
        groupJournalsAbilities: { checkIfCanManagePsychologicalHelp },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManagePsychologicalHelp = checkIfCanManagePsychologicalHelp();
    const psychologicalHelpId = (psychologicalHelpIdParam || '') as PsychologicalHelpId;
    const editPagePath = generatePath(
        appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.edit
            .pickedPsychologicalHelp,
        { psychologicalHelpId, journalId },
    );

    const {
        data: psychologicalHelpData,
        isFetching: isPsychologicalHelpDataFetching,
        isError: isFetchPsychologicalHelpDataError,
    } = useAppQuery(
        'PSYCHOLOGICAL_HELP',
        [journalId, psychologicalHelpId],
        () =>
            StaffHTTPService.psychologicalHelps.getPsychologicalHelp(
                journalId,
                psychologicalHelpId,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    if (!psychologicalHelpId) {
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base,
                { journalId },
            ),
            { replace: true },
        );
    }

    const isVisibleAttachmentsList =
        !!psychologicalHelpData?.[psychologicalHelpDTOFields.attachments].length;

    return (
        <Details
            title={journalTexts.journalDetails.groupJournal.psychologicalHelp.view}
            isLoading={isPsychologicalHelpDataFetching}
            isError={isFetchPsychologicalHelpDataError}
            editPagePath={canManagePsychologicalHelp ? editPagePath : undefined}
        >
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.formOfHelp}
            >
                {psychologicalHelpData &&
                    psychologicalHelpFormsLabels[
                        psychologicalHelpData[psychologicalHelpDTOFields.helpForm]
                    ]}
            </Details.Item>
            {psychologicalHelpData?.[psychologicalHelpDTOFields.additionalInformation] && (
                <Details.Item
                    title={
                        journalTexts.journalDetails.groupJournal.psychologicalHelp.additionalInfo
                    }
                >
                    {psychologicalHelpData[psychologicalHelpDTOFields.additionalInformation]}
                </Details.Item>
            )}
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.childData}
            >
                {psychologicalHelpData &&
                    getFullName(
                        psychologicalHelpData[psychologicalHelpDTOFields.child].firstName,
                        psychologicalHelpData[psychologicalHelpDTOFields.child].lastName,
                    )}
            </Details.Item>
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.assistanceBasis}
            >
                {psychologicalHelpData &&
                    psychologicalHelpBasesLabels[
                        psychologicalHelpData[psychologicalHelpDTOFields.basis]
                    ]}
            </Details.Item>
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.description}
            >
                {psychologicalHelpData?.[psychologicalHelpDTOFields.description]}
            </Details.Item>
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.startDate}
            >
                {psychologicalHelpData &&
                    parseStringIntoFormattedStringDate(
                        psychologicalHelpData[psychologicalHelpDTOFields.dateFrom],
                    )}
            </Details.Item>
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.finishDate}
            >
                {psychologicalHelpData &&
                    parseStringIntoFormattedStringDate(
                        psychologicalHelpData[psychologicalHelpDTOFields.dateTo],
                    )}
            </Details.Item>
            {isVisibleAttachmentsList && (
                <Details.Item
                    title={journalTexts.journalDetails.groupJournal.psychologicalHelp.documents}
                >
                    <AppList
                        dataSource={psychologicalHelpData[psychologicalHelpDTOFields.attachments]}
                        marginTop={theme.sizes.marginNormal}
                        renderItem={(fileData) => (
                            <AttachmentListItem key={fileData.fileUri} fileData={fileData} />
                        )}
                    />
                </Details.Item>
            )}
        </Details>
    );
};
