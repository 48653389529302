import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { EmployeeAdd, EmployeeEdit, EmployeesPage } from 'pages';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';

export const employeeRoutes: RouteObject[] = [
    {
        path: appPaths.app.employees.base,
        element: (
            <AbilityRoute
                can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.EMPLOYEES_LIST }}
            >
                <EmployeesPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.employees.add,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.EMPLOYEES_LIST }}
            >
                <EmployeeAdd />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.employees.edit.base,
        element: <Navigate to={appPaths.app.employees.base} />,
    },
    {
        path: appPaths.app.employees.edit.pickedEmployee,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.EMPLOYEES_LIST }}
            >
                <EmployeeEdit />
            </AbilityRoute>
        ),
    },
];
