import { useTheme } from 'styled-components';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { UserCheckBottomIcon } from 'assets';
import type { FormTypes } from 'types';
import {
    FormNavButtons,
    IdDocumentNumberInputElement,
    LastNameInputElement,
    FirstNameInputElement,
    PhoneNumberInputElement,
} from 'components/molecules';
import { AppModal, AppModalProps } from 'components/molecules/AppModal/AppModal';
import { AppModalForm, AppModalFormProps } from 'components/organisms/AppModalForm/AppModalForm';
import { FormPageModeDependedTexts, GuardianFormFields } from 'types';
import { childrenTexts, commonTexts } from 'consts/text/index';

type GuardianAddModalProps = Pick<AppModalProps, 'open' | 'onCancel'> &
    Omit<AppModalFormProps<GuardianFormFields>, 'withGoBack' | 'width' | 'name' | 'onFinish'> & {
        formName: FormTypes;
        onSubmitForm: (values: GuardianFormFields) => void;
    };

const addModeTexts: FormPageModeDependedTexts = {
    title: childrenTexts.addGuardianTitle,
    buttonText: childrenTexts.addGuardianButtonLabel,
};

const editModeTexts: FormPageModeDependedTexts = {
    title: childrenTexts.updateGuardianTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const GuardianModalAddEditForm = ({
    initialValues,
    open,
    onCancel,
    formName,
    onSubmitForm,
    mode = 'add',
    ...props
}: GuardianAddModalProps) => {
    const theme = useTheme();
    const isAddMode = mode === 'add';
    const { title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    return (
        <AppModal
            title={title}
            width={theme.sizes.modalWidth.medium}
            icon={<UserCheckBottomIcon />}
            open={open}
            onCancel={onCancel}
            destroyOnClose
        >
            <AppModalForm<GuardianFormFields>
                name={formName}
                initialValues={initialValues}
                onFinish={onSubmitForm}
                {...props}
            >
                <FirstNameInputElement />
                <LastNameInputElement />
                <IdDocumentNumberInputElement />
                <PhoneNumberInputElement optional />
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={buttonText}
                    leftIcon={buttonIcon}
                    withPlus={isAddMode}
                    marginBottom={0}
                />
            </AppModalForm>
        </AppModal>
    );
};
