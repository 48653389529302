import { FormInstance } from 'antd';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { Role, RoleEditFormFields, RoleModifiedData } from 'types';
import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash';
import { useWatch } from 'rc-field-form';
import { AppButton } from 'components/atoms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { commonTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { Container } from './SaveButton.styled';
import { modulesEditAtom, permissionsEditAtom } from '../../roleAndPermissionState';

export type SaveButtonProps = {
    form?: FormInstance<RoleEditFormFields>;
    roleDetails?: Role;
    onSave: (roleModifiedData: RoleModifiedData) => void;
};

export const SaveButton = ({ form, roleDetails, onSave }: SaveButtonProps) => {
    const isMobile = useIsMobile();
    const updatedPermissions = useAtomValue(permissionsEditAtom);
    const updatedModules = useAtomValue(modulesEditAtom);
    const name = useWatch(formFields.name, form);

    const areModulesEqual = isEqual(roleDetails?.modules, updatedModules);
    const arePermissionsEqual = isEqual(roleDetails?.permissions, updatedPermissions);
    const isNameEqual = isEqual(roleDetails?.name, name);

    const isSaveDisabled = areModulesEqual && arePermissionsEqual && isNameEqual;

    const handleCreateNewRole = () => {
        onSave({
            modules: updatedModules,
            permissions: updatedPermissions,
            name,
        });
    };

    return (
        <Container>
            <AppButton
                disabled={isSaveDisabled}
                type="primary"
                icon={<IconDeviceFloppy size={20} />}
                onClick={handleCreateNewRole}
                fullWidth={isMobile}
                marginTop="marginLarge"
            >
                {commonTexts.actionLabels.save}
            </AppButton>
        </Container>
    );
};
