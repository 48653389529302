import { AddUpdateGroupTopicDTO, GroupTopicFormFields } from 'types';
import { EducationalGuideTopicId } from 'types/educationalGuide/educationalGuide';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';
import { parseDayjsTimeIntoHoursMinutesFormat } from '../dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export const parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO: (
    groupTopicFormFields: GroupTopicFormFields,
) => AddUpdateGroupTopicDTO = (groupTopicFormFields) => ({
    teacherIds: groupTopicFormFields.teacherIds,
    date: parseDayjsDateIntoString(groupTopicFormFields.date, 'YYYY-MM-DD'),
    timeFrom: parseDayjsTimeIntoHoursMinutesFormat(groupTopicFormFields.timeFrom),
    timeTo: parseDayjsTimeIntoHoursMinutesFormat(groupTopicFormFields.timeTo),
    coreCurriculums: groupTopicFormFields.coreCurriculums,
    name: groupTopicFormFields.name,
    description: groupTopicFormFields.description,
    educationGuideTopicId: groupTopicFormFields.educationalGuideTopic as EducationalGuideTopicId,
});
