import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';
import { unexpectedErrorLabel } from 'consts/errors/firebaseErrors';

export const activateUserErrorParser: (error: unknown, defaultErrorMessage?: string) => string = (
    error,
    defaultErrorMessage = unexpectedErrorLabel,
) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.USER_EMAIL_TAKEN) {
        return commonTexts.errorMessages.userEmailTaken;
    }

    return defaultErrorMessage;
};
