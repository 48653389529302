import { SpecialTopicDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { parseChildWorkDTOIntoJsonObject } from 'utils/parsers/jsonObject/specialTopic/parseChildWorkDTOIntoJsonObject';

export const parseSpecialTopicDTOIntoJsonObject: (specialTopicDTO: SpecialTopicDTO) => object = (
    specialTopicDTO,
) => {
    const { name, date, timeFrom, timeTo, lesson, description, teacher, childrenWork } =
        specialTopicDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const formattedTimeFrom = parseStringIntoFormattedStringTime(timeFrom);
    const formattedTimeTo = parseStringIntoFormattedStringTime(timeTo);
    const childrenWorksJsonObjects = childrenWork.map(parseChildWorkDTOIntoJsonObject);

    const baseSpecialTopicJsonObject = {
        [jsonObjectsTexts.specialTopicDTO.fields.name]: name,
        [jsonObjectsTexts.specialTopicDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.specialTopicDTO.fields.timeFrom]: formattedTimeFrom,
        [jsonObjectsTexts.specialTopicDTO.fields.timeTo]: formattedTimeTo,
        [jsonObjectsTexts.specialTopicDTO.fields.lesson]: lesson.name,
        [jsonObjectsTexts.specialTopicDTO.fields.description]: description,
        [jsonObjectsTexts.specialTopicDTO.fields.childrenWork]: childrenWorksJsonObjects,
    };

    if (!teacher) {
        return baseSpecialTopicJsonObject;
    }

    const { firstName: teacherFirstName, lastName: teacherLastName } = teacher;

    const teacherJsonObject = {
        [jsonObjectsTexts.specialTopicDTO.innerObjects.lessonTeacherDTO.fields.firstname]:
            teacherFirstName,
        [jsonObjectsTexts.specialTopicDTO.innerObjects.lessonTeacherDTO.fields.lastname]:
            teacherLastName,
    };

    return {
        ...baseSpecialTopicJsonObject,
        [jsonObjectsTexts.specialTopicDTO.fields.teacher]: teacherJsonObject,
    };
};
