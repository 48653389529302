import { ChildAgreementsIcon } from 'assets';
import { AppParagraph, DetailsFrameBase } from 'components/atoms';
import { socialNetworksLabels } from 'consts/socialNetworks/socialNetworksLabels';
import { childrenTexts } from 'consts/text';
import { Child, Agreements as AgreementsType, AgreementValue } from 'types';
import { socialNetworks } from 'consts/socialNetworks/socialNetworks';

export type AgreementsProps = Pick<Child, 'agreements'>;

const getSocialNetworksFilteredLabels = (agreements: AgreementsType<AgreementValue>) =>
    Object.values(socialNetworks)
        .filter((socialNetwork) => agreements[socialNetwork])
        .map((socialNetwork) => socialNetworksLabels[socialNetwork])
        .join(', ');

export const Agreements = ({ agreements }: AgreementsProps) => {
    const isAnyAgreementAccepted = Object.values(agreements).some(Boolean);

    return (
        <DetailsFrameBase
            icon={<ChildAgreementsIcon />}
            title={childrenTexts.childDetails.agreements.agreements}
        >
            <AppParagraph textType="BodyMedium" margin={0} marginBottom="marginLarge">
                {childrenTexts.childDetails.agreements.socialMediaAgreement}
            </AppParagraph>
            <AppParagraph textType="BodyMediumSemiBold" margin={0}>
                {isAnyAgreementAccepted
                    ? getSocialNetworksFilteredLabels(agreements)
                    : childrenTexts.childDetails.agreements.noAgreement}
            </AppParagraph>
        </DetailsFrameBase>
    );
};
