import { useLocation } from 'react-router-dom';
import { Row } from 'antd';
import { useTheme } from 'styled-components';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { PageBaseLayout } from 'components/layouts';
import { commonTexts, parentTexts } from 'consts/text';
import { AppFlex, AppFormBanner, AppGoBackButton, AppTitle } from 'components/atoms';
import { SummaryText } from 'components/atoms/SummaryText/SummaryText';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { getPaymentTitle } from 'utils/getPaymentTitle';
import { PaymentTransferDetails } from 'components/templates';

export const ParentPaymentPage = () => {
    const location = useLocation();

    const theme = useTheme();

    const paymentDateData = location.state as { year: string; month: string } | undefined;
    const parsedYear = paymentDateData?.year ? Number(paymentDateData.year) : undefined;
    const parsedMonth = paymentDateData?.month ? Number(paymentDateData.month) : undefined;
    const isPaymentDateDataAvailable = !!parsedYear && !!parsedMonth;

    const {
        data: paymentData,
        isInitialLoading: isInitialPaymentDataLoading,
        isRefetching: isRefetchingPaymentData,
        isError: isFetchPaymentDataError,
    } = useAppQuery(
        'PAYMENT_DATA',
        [parsedYear, parsedMonth],
        () =>
            ParentHTTPService.settlements.getSettlementTransferInformation({
                year: parsedYear || 0,
                month: parsedMonth || 0,
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: isPaymentDateDataAvailable,
        },
    );

    const summaryText = paymentData?.priceTotal
        ? getFormattedPrice(paymentData?.priceTotal)
        : EMPTY_VALUE_LABEL;

    const institutionTransferPaymentTitleText =
        isPaymentDateDataAvailable && paymentData
            ? getPaymentTitle(parsedMonth, parsedYear, paymentData.childSimpleDto)
            : EMPTY_VALUE_LABEL;

    const isInstitutionTransferInformationVisible = isPaymentDateDataAvailable && paymentData;
    const isCateringTransferInformationVisible =
        isPaymentDateDataAvailable && paymentData?.cateringTransferInformationDto?.transferTitle;

    return (
        <PageBaseLayout
            isDataInitialLoading={isInitialPaymentDataLoading}
            isDataRefetching={isRefetchingPaymentData}
            isError={isFetchPaymentDataError || !isPaymentDateDataAvailable}
            isEmpty={!paymentData}
            emptyStateData={{ emptyInfo: commonTexts.emptyInfo.noPaymentData }}
        >
            <AppGoBackButton margin={0} marginBottom={16} />
            <AppFormBanner mode="WARNING" text={parentTexts.settlement.paymentWarning} />
            <Row align="bottom" justify="space-between">
                <AppTitle level={4} marginTop={0}>
                    {parentTexts.settlement.paymentDetailsTitle}
                </AppTitle>
                <SummaryText text={summaryText} />
            </Row>
            <AppFlex gap={theme.sizes.marginNormal} vertical>
                {isInstitutionTransferInformationVisible && (
                    <PaymentTransferDetails
                        title={institutionTransferPaymentTitleText}
                        amount={paymentData.institutionTransferInformationDto.amount}
                        bankAccountNumber={
                            paymentData.institutionTransferInformationDto.bankAccountNumber
                        }
                        recipientName={paymentData.institutionTransferInformationDto.recipientName}
                    />
                )}
                {isCateringTransferInformationVisible && (
                    <PaymentTransferDetails
                        title={
                            paymentData.cateringTransferInformationDto?.transferTitle ||
                            EMPTY_VALUE_LABEL
                        }
                        amount={paymentData.cateringTransferInformationDto?.amount || 0}
                        bankAccountNumber={
                            paymentData.cateringTransferInformationDto?.bankAccountNumber ||
                            EMPTY_VALUE_LABEL
                        }
                        recipientName={
                            paymentData.cateringTransferInformationDto?.recipientName ||
                            EMPTY_VALUE_LABEL
                        }
                    />
                )}
            </AppFlex>
        </PageBaseLayout>
    );
};
