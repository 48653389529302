import { IconDots } from '@tabler/icons-react';
import {
    AppButton,
    AppButtonProps,
} from 'components/atoms/CommonAppComponents/AppButton/AppButton';

type AppMoreButtonProps = Omit<AppButtonProps, 'ghost' | 'icon'>;

export const AppMoreButton = (props: AppMoreButtonProps) => (
    <AppButton ghost icon={<IconDots size={20} />} {...props} />
);
