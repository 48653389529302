import styled, { css } from 'styled-components';
import { AppList, GroupMailThreadMessageReadStatusCount } from 'components/atoms';

export const PreviewReadStatusCount = styled(GroupMailThreadMessageReadStatusCount)`
    margin-top: 24px;
    cursor: pointer;
`;

export const PopoverTitleCloseIconContainer = styled.div`
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;
`;

export const DetailsReadStatusCount = styled(GroupMailThreadMessageReadStatusCount)`
    ${({ theme }) => css`
        padding-left: 12px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            margin-bottom: ${theme.sizes.marginLarge}px;
        }
    `}
`;

export const StyledAppList = styled(AppList)`
    ${({ theme }) => css`
        width: 335px;
        height: calc(50vh - 150px);

        overflow: auto;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
        }
    `}
`;
