import React from 'react';
import { CellProps } from 'antd/lib/descriptions/Cell';

export type AppCellProps = CellProps & {
    children: React.ReactNode;
    content?: string;
};

export const AppBodyCell = ({ children, ...wrapperDefaultProps }: AppCellProps) => (
    <td {...wrapperDefaultProps}>
        {/*TODO: to change, because at this moment there is error: "validateDOMNesting(...): <tbody> cannot appear as a child of <span>"*/}
        <span className="cell-content">{children}</span>
    </td>
);
