import { AppCheckbox, AppFormItem, AppFormItemProps } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { journalTexts } from 'consts/text';

export const SendConsentToParentsInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        name={formFields.sendConsentToParents}
        valuePropName="checked"
        initialValue={false}
        {...props}
    >
        <AppCheckbox>
            {journalTexts.journalDetails.groupJournal.events.trips.sendConsentToParentsInputLabel}
        </AppCheckbox>
    </AppFormItem>
);
