import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { parseEmployeeAddFormDataIntoEmployeeCreateDTO } from 'utils/parsers/employee/parseEmployeeAddFormDataIntoEmployeeCreateDTO';
import { appPaths } from 'consts/paths/paths';
import { EmployeeAddEditForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { EmployeeFormFields } from 'types';
import { employeeTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const EmployeeAdd = () => {
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const onSuccess = () => {
        displaySuccess(employeeTexts.successfullyAddedEmployee);
        statefulNavigate(appPaths.app.employees.base, { replace: true });
    };

    const { mutate, error, isLoading } = useAppMutation(StaffHTTPService.employees.createEmployee, {
        key: ['ADD_EMPLOYEE'],
        onSuccess,
        invalidateQueryKeys: [
            ['EMPLOYEES'],
            ['GROUPS_EMPLOYEES'],
            ['JOURNALS_EMPLOYEES'],
            ['INSTITUTIONS_EMPLOYEES'],
            ['LESSONS_EMPLOYEES'],
            ['STAFF'],
            ['MAIL_THREAD_RECEIVERS'],
        ],
    });

    const onFinish = (employeeData: EmployeeFormFields) =>
        mutate(parseEmployeeAddFormDataIntoEmployeeCreateDTO(employeeData));

    return (
        <EmployeeAddEditForm
            type="add"
            axiosError={error}
            isLoading={isLoading}
            onFinish={onFinish}
        />
    );
};
