import { Navigate, useRoutes } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { GlobalLayout } from 'components/layouts';
import { appPaths } from 'consts/paths/paths';
import { NotFound404 } from 'pages';
import { authRoutes } from './routes/auth';
import { getLoggedAppRoutes } from './routes/loggedApp/loggedApp';

export const Navigation = () => {
    const userDetails = useAtomValue(userDetailsAtom);

    const loggedAppRoutes = getLoggedAppRoutes(userDetails?.type, userDetails?.institutionBlocked);

    return useRoutes([
        {
            path: appPaths.base,
            element: <GlobalLayout />,
            children: [
                {
                    index: true,
                    element: <Navigate to={appPaths.app.base} />,
                },
                authRoutes,
                loggedAppRoutes,
                { path: '*', element: <NotFound404 /> },
            ],
        },
    ]);
};
