import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export const ParentGrid = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            grid-template-columns: repeat(12, 1fr);
        }
    `}
`;

export const GridItem = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['width']),
})<{ width?: number; height?: number }>`
    ${({ theme, width = 6 }) => css`
        @media (${theme.sizes.mediaQueries.desktopMin}) {
            display: grid;
            justify-items: stretch;
            align-items: stretch;
            grid-column-end: span ${width};
        }
    `}
`;

export const DobleRow = styled.div`
    ${({ theme }) => css`
        @media (${theme.sizes.mediaQueries.desktopMin}) {
            grid-column: 8 / span 5;
            grid-row: 2 / span 2;
            display: grid;
            justify-items: stretch;
            align-items: stretch;
        }
    `}
`;
