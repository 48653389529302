import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { PsychologicalHelpAddEditForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { PsychologicalHelpFormFields, PsychologicalHelpId } from 'types';
import { parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO } from 'utils/parsers/psychologicalHelp/parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { ErrorDisplay } from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { parsePsychologicalHelpDTOIntoPsychologicalHelpFormFields } from 'utils/parsers/psychologicalHelp/parsePsychologicalHelpDTOIntoPsychologicalHelpFormFields';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { journalTexts } from 'consts/text/index';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalPsychologicalHelpEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<PsychologicalHelpFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { psychologicalHelpId: psychologicalHelpIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const psychologicalHelpId = (psychologicalHelpIdParam || '') as PsychologicalHelpId;

    const {
        data: psychologicalHelpData,
        isFetching: isPsychologicalHelpDataFetching,
        isError: isFetchPsychologicalHelpDataError,
    } = useAppQuery(
        'PSYCHOLOGICAL_HELP',
        [journalId, psychologicalHelpId],
        () =>
            StaffHTTPService.psychologicalHelps.getPsychologicalHelp(
                journalId,
                psychologicalHelpId,
            ),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (psychologicalHelpData && !form.getFieldValue(formFields.helpForm)) {
            setFormData(
                parsePsychologicalHelpDTOIntoPsychologicalHelpFormFields(psychologicalHelpData),
            );
        }
    }, [psychologicalHelpData, form, setFormData]);

    const navigateToPsychologicalHelpsPage = () =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base,
                { journalId },
            ),
            { replace: true },
        );

    const onEditPsychologicalHelpSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.common.psychologicalHelp
                .successfullyUpdatedPsychologicalHelp,
        );
        navigateToPsychologicalHelpsPage();
    };

    const {
        mutate: updatePsychologicalHelp,
        error: editPsychologicalHelpError,
        isLoading: isEditPsychologicalHelpLoading,
    } = useAppMutation(StaffHTTPService.psychologicalHelps.updatePsychologicalHelp, {
        key: ['PSYCHOLOGICAL_HELP', journalId, psychologicalHelpId],
        onSuccess: onEditPsychologicalHelpSuccess,
        invalidateQueryKeys: [
            ['PSYCHOLOGICAL_HELPS', journalId],
            ['PSYCHOLOGICAL_HELP', journalId, psychologicalHelpId],
        ],
    });

    const onEditPsychologicalHelpFinish = (
        psychologicalHelpFormData: PsychologicalHelpFormFields,
    ) => {
        const parsedUpdatePsychologicalHelpDTO =
            parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO(
                psychologicalHelpFormData,
            );
        updatePsychologicalHelp({
            journalId,
            psychologicalHelpId,
            updatePsychologicalHelpDTO: parsedUpdatePsychologicalHelpDTO,
        });
    };

    if (!psychologicalHelpId) {
        navigateToPsychologicalHelpsPage();
    }

    if (isFetchPsychologicalHelpDataError) {
        return <ErrorDisplay />;
    }

    return (
        <PsychologicalHelpAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            journalId={journalId}
            axiosError={editPsychologicalHelpError}
            isLoading={isPsychologicalHelpDataFetching || isEditPsychologicalHelpLoading}
            onFinish={onEditPsychologicalHelpFinish}
        />
    );
};
