import { List } from 'antd';
import { StyledListItem } from './ListItem.styled';

export type ListItemProps = {
    actionComponent: React.ReactNode;
    permissionLabel: string;
    className?: string;
    dataTestId?: string;
};

export const ListItem = ({
    className,
    actionComponent,
    permissionLabel,
    dataTestId,
}: ListItemProps) => (
    <StyledListItem className={className} actions={[actionComponent]} data-testid={dataTestId}>
        <List.Item.Meta title={permissionLabel} />
    </StyledListItem>
);
