import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { Address } from 'types/common';
import { AppParagraph } from 'components/atoms';

export const AddressDisplay = ({ address }: { address: Partial<Address> | null }) => {
    if (!address) {
        return EMPTY_VALUE_LABEL;
    }

    const { street, zipCode, city } = address;

    return street || zipCode || city ? (
        <>
            <AppParagraph margin={0}>{street || EMPTY_VALUE_LABEL}</AppParagraph>
            <AppParagraph margin={0}>{`${zipCode || EMPTY_VALUE_LABEL} ${
                city || EMPTY_VALUE_LABEL
            }`}</AppParagraph>
        </>
    ) : (
        '-'
    );
};
