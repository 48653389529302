import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { AppActions, AppSubjects } from 'types';
import { useAppAbility } from 'abilities/useAppAbility';
import { appPaths } from 'consts/paths/paths';

type CanConfig = { action: AppActions; subject: AppSubjects };

type AbilityRouteProps = PropsWithChildren<{
    can: CanConfig | CanConfig[];
}>;

export const AbilityRoute = ({ can, children }: AbilityRouteProps) => {
    const ability = useAppAbility();

    if (Array.isArray(can)) {
        const cannotForAll = can.every(({ action, subject }) => ability.cannot(action, subject));
        if (cannotForAll) {
            return <Navigate to={appPaths.error['not-found']} />;
        }
    } else {
        const { action, subject } = can;
        if (ability.cannot(action, subject)) {
            return <Navigate to={appPaths.error['not-found']} />;
        }
    }

    return children;
};
