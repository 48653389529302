import { AppModal } from 'components/molecules';
import { commonTexts } from 'consts/text';
import { AppModalButton, AppParagraph } from 'components/atoms';
import { StyledAddButton } from 'pages/app/parent/groupAttendance/ParentGroupAttendancePage/ParentGroupAttendancePage.styled';
import { IconCreditCard, IconCreditCardOff, IconLockAccess } from '@tabler/icons-react';
import { useSettingsAbilities } from 'abilities';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useAuth } from 'services/firebase/useAuth';
import { useTheme } from 'styled-components';
import {
    IconContainer,
    ModalRedText,
    ModalTitle,
    ModalSemiBoldText,
    ModalTitleWithMaxWidth,
} from './AppBlockModal.styled';

export const AppBlockModal = () => {
    const { signUserOut } = useAuth();

    const theme = useTheme();
    const navigate = useNavigate();

    const { settingsAbilities } = useSettingsAbilities();
    const { checkIfCanViewPayment } = settingsAbilities;
    const canViewPayment = checkIfCanViewPayment();

    const onPayClick = () => navigate(appPaths.app.settings.payments);
    const onLogOutClick = () => signUserOut();

    const icon = canViewPayment ? (
        <IconCreditCardOff color={theme.colors.red.red6} />
    ) : (
        <IconLockAccess />
    );

    const title = canViewPayment ? (
        <ModalTitle level={5}>
            {commonTexts.blockModal.titlePaymentPartOne}{' '}
            <ModalRedText>{commonTexts.blockModal.titlePaymentPartTwo}</ModalRedText>
        </ModalTitle>
    ) : (
        <ModalTitleWithMaxWidth level={5}>
            {commonTexts.blockModal.titleNotPayment}
        </ModalTitleWithMaxWidth>
    );

    const content = canViewPayment ? (
        <>
            <AppParagraph textAlign="center" textType="BodySmall" marginBottom={16}>
                {commonTexts.blockModal.contentOne}
            </AppParagraph>
            <AppParagraph textAlign="center">
                {commonTexts.blockModal.contentTwo}{' '}
                <ModalSemiBoldText>{commonTexts.blockModal.contentThree} </ModalSemiBoldText>
            </AppParagraph>
        </>
    ) : null;

    const buttons = (
        <>
            <AppModalButton onClick={onLogOutClick}>{commonTexts.blockModal.logOut}</AppModalButton>
            {canViewPayment && (
                <StyledAddButton
                    addButtonLabel={commonTexts.blockModal.payNow}
                    onClick={onPayClick}
                    icon={<IconCreditCard />}
                />
            )}
        </>
    );
    return (
        <AppModal open closable={false} footer={buttons}>
            <IconContainer>{icon}</IconContainer>
            {title}
            {content}
        </AppModal>
    );
};
