import uniq from 'lodash/uniq';
import { ChildWithParentsIds, ParentId } from 'types';

export const getUniqueParentsIdsFromChildrenWithParentsIds = (
    childrenWithParentsIds: ChildWithParentsIds[],
) => {
    const parentsIds = childrenWithParentsIds.reduce<ParentId[]>(
        (allParentIds, currentChildWithParentIds) =>
            allParentIds.concat(currentChildWithParentIds.parentIds),
        [],
    );
    return uniq(parentsIds);
};
