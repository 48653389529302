import { useEffect } from 'react';
import { Form } from 'antd';
import { AppFormItem, AppFormItemGroup } from 'components/atoms';
import {
    AccountingPeriodDatePickerElement,
    BirthDateDatePickerElement,
    BirthPlaceInputElement,
    CityInputElement,
    DeclaredStayHoursTimePicker,
    FormNavButtons,
    GenderPickerElement,
    LastNameInputElement,
    FirstNameInputElement,
    PeselInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { AppForm, ChildGroupPicker } from 'components/organisms';
import { FormPageProps } from 'types';
import { formFields } from 'consts/form/formFields';
import { childrenTexts, commonTexts } from 'consts/text/index';

export const ChildAddStep1 = ({ enforcedInitialValues, ...props }: FormPageProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (enforcedInitialValues && !form.getFieldValue(formFields.firstName)) {
            form.setFieldsValue(enforcedInitialValues);
        }
    }, [enforcedInitialValues, form]);

    return (
        <AppForm
            form={form}
            title={childrenTexts.addChildrenButtonLabel}
            desktopTitleLevel={4}
            isFormHeaderAtBottom
            {...props}
        >
            <AppFormItemGroup title={childrenTexts.childForm.personalData.personalData}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    {/*TODO: change labels to jsons after inputs refactor in forms*/}
                    <FirstNameInputElement />
                    <LastNameInputElement />
                </AppFormItem>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <PeselInputElement />
                    <GenderPickerElement />
                </AppFormItem>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <BirthDateDatePickerElement />
                    <BirthPlaceInputElement />
                </AppFormItem>
            </AppFormItemGroup>
            <AppFormItemGroup title={childrenTexts.childForm.addressData.addressData}>
                {/*TODO: change labels to jsons after inputs refactor in forms*/}
                <StreetAddressInputElement optional hasOptionalText />
                <AppFormItem justifyContent="space-between" doubleItem>
                    <ZipCodeInputElement optional hasOptionalText />
                    <CityInputElement optional hasOptionalText />
                </AppFormItem>
                <ChildGroupPicker />
            </AppFormItemGroup>
            <AppFormItemGroup title={childrenTexts.childForm.settlementData.settlementData}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <AccountingPeriodDatePickerElement mode="start" />
                    <AccountingPeriodDatePickerElement mode="finish" />
                </AppFormItem>
            </AppFormItemGroup>
            {/*TODO: check if institution has settlements per hour*/}
            <AppFormItemGroup title={childrenTexts.childForm.settlementData.declaredStayHours}>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <DeclaredStayHoursTimePicker mode="start" />
                    <DeclaredStayHoursTimePicker mode="finish" />
                </AppFormItem>
            </AppFormItemGroup>
            <FormNavButtons buttonText={commonTexts.actionLabels.next} />
        </AppForm>
    );
};
