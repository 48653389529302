import { HistoryChangeSpecialAttendanceDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { baseDayAttendanceTypesLabels } from 'consts/attendance/baseDayAttendanceTypesLabels';

export const parseSpecialAttendanceDTOIntoJsonObject: (
    specialAttendanceDTO: HistoryChangeSpecialAttendanceDTO,
) => object = (specialAttendanceDTO) => {
    const { childFirstName, childLastName, lessonName, date, type } = specialAttendanceDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);

    return {
        [jsonObjectsTexts.specialAttendanceDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.specialAttendanceDTO.fields.lesson]: lessonName,
        [jsonObjectsTexts.specialAttendanceDTO.fields.firstName]: childFirstName,
        [jsonObjectsTexts.specialAttendanceDTO.fields.lastName]: childLastName,
        [jsonObjectsTexts.specialAttendanceDTO.fields.type]: baseDayAttendanceTypesLabels[type],
    };
};
