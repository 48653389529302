import { ChildGroup } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { groupStatusesLabels } from 'consts/group/groupStatusesLabels';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { groupTypeLabels } from 'consts/group/groupTypeLabels';

export const parseChildGroupIntoJsonObject: (childGroup: ChildGroup) => object = (childGroup) => {
    const { name, type, status, leftGroup, leaveDate } = childGroup;

    const formattedLeaveDate = leaveDate
        ? parseStringIntoFormattedStringDate(leaveDate)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.child.innerObjects.childGroup.fields.name]: name,
        [jsonObjectsTexts.child.innerObjects.childGroup.fields.type]: groupTypeLabels[type],
        [jsonObjectsTexts.child.innerObjects.childGroup.fields.status]: groupStatusesLabels[status],
        [jsonObjectsTexts.child.innerObjects.childGroup.fields.leftGroup]: leftGroup
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.child.innerObjects.childGroup.fields.leaveDate]: formattedLeaveDate,
    };
};
