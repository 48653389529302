import { ReactQuillProps } from 'react-quill';
import { Container, StyledTextEditor } from './AppTextEditor.styled';

const modules = {
    toolbar: ['bold', 'italic', 'underline'],
};

type AppTextEditorProps = Omit<ReactQuillProps, 'theme' | 'modules' | 'formats'>;

export const AppTextEditor = ({ className, ...restProps }: AppTextEditorProps) => (
    <Container className={className}>
        <StyledTextEditor modules={modules} {...restProps} />
    </Container>
);
