import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps, ArchiveRestoreModal } from 'components/organisms';
import { commonTexts, remoteLessonsTexts } from 'consts/text';
import { Option } from 'components/atoms';
import { ContractValidDisplay } from 'components/molecules';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { remoteLessonSimpleDTOFields } from 'consts/remoteLessons/remoteLessonSimpleDTOFields';
import { RemoteLessonSimpleDTO, RemoteLessonId } from 'types';
import { useState } from 'react';
import { useRemoteLessonAbilities } from 'abilities';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useRemoteLessonsPageNetworkManage } from './hooks/useRemoteLessonsPageNetworkManage';

const columns: AppTableProps<RemoteLessonSimpleDTO>['columns'] = [
    {
        title: remoteLessonsTexts.lessonListColumns.title,
        dataIndex: remoteLessonSimpleDTOFields.name,
        key: remoteLessonSimpleDTOFields.name,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.date,
        dataIndex: remoteLessonSimpleDTOFields.date,
        key: remoteLessonSimpleDTOFields.date,
        render: (date: RemoteLessonSimpleDTO['date']) =>
            parseStringIntoFormattedStringDate(date, 'YYYY-MM-DD', 'DD/MM/YYYY'),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.homeworkDateTo,
        dataIndex: remoteLessonSimpleDTOFields.homeworkDeadline,
        key: remoteLessonSimpleDTOFields.homeworkDeadline,
        render: (homeworkDeadline: RemoteLessonSimpleDTO['homeworkDeadline']) =>
            homeworkDeadline
                ? parseStringIntoFormattedStringDate(homeworkDeadline, 'YYYY-MM-DD', 'DD/MM/YYYY')
                : EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.reads,
        dataIndex: remoteLessonSimpleDTOFields.readBy,
        key: remoteLessonSimpleDTOFields.readBy,
        render: (_, remoteLessonDTO) =>
            `${remoteLessonDTO.readBy}/${remoteLessonDTO.totalAmountOfRecipients}`,
        align: 'center',
    },
    {
        title: commonTexts.dataLabels.attachments,
        dataIndex: remoteLessonSimpleDTOFields.attachmentAttached,
        key: remoteLessonSimpleDTOFields.attachmentAttached,
        render: (attachmentAttached: RemoteLessonSimpleDTO['attachmentAttached']) => (
            <ContractValidDisplay contractValid={attachmentAttached} dataTestId="attachments" />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const RemoteLessonsPage = () => {
    const [selectedToArchiveRemoteLessonId, setSelectedToArchiveRemoteLessonId] =
        useState<RemoteLessonId | null>(null);

    const { statefulNavigate } = useNavigateWithState();
    const { remoteLessonAbilities } = useRemoteLessonAbilities();
    const { checkIfCanManageRemoteLessonsConfig } = remoteLessonAbilities;
    const canManageRemoteLessons = checkIfCanManageRemoteLessonsConfig();

    const navigateToRemoteLessonDetails = (remoteLesson: RemoteLessonSimpleDTO) =>
        statefulNavigate(
            generatePath(appPaths.app['remote-lessons'].details.pickedRemoteLesson, {
                remoteLessonId: remoteLesson.id,
            }),
        );

    const navigateToRemoteLessonAdd = () => statefulNavigate(appPaths.app['remote-lessons'].add);

    const navigateToRemoteLessonEdit = (remoteLesson: RemoteLessonSimpleDTO) =>
        statefulNavigate(
            generatePath(appPaths.app['remote-lessons'].edit.pickedRemoteLesson, {
                remoteLessonId: remoteLesson.id,
            }),
        );

    const handleRemoveRemoteLessonClick = ({ id }: RemoteLessonSimpleDTO) =>
        setSelectedToArchiveRemoteLessonId(id);

    const options: Option<RemoteLessonSimpleDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToRemoteLessonDetails,
            Icon: <IconEye />,
        },

        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToRemoteLessonEdit,
            Icon: <IconEdit />,
            checkIfVisible: ({ editable }) => editable && checkIfCanManageRemoteLessonsConfig(),
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveRemoteLessonClick,
            Icon: <IconTrash />,
            checkIfVisible: ({ editable }) => editable && checkIfCanManageRemoteLessonsConfig(),
        },
    ];

    const handleUnselectToRemoveRemoteLessonId = () => setSelectedToArchiveRemoteLessonId(null);

    const {
        remoteLessonsData,
        isRemoteLessonsDataInitialLoading,
        isRemoteLessonsDataError,
        isRemoteLessonsDataRefetching,
        removeRemoteLesson,
        isRemoveRemoteLessonLoading,
    } = useRemoteLessonsPageNetworkManage({
        onJournalArchiveRequestFinish: handleUnselectToRemoveRemoteLessonId,
    });

    const handleRemoveRemoteLessonConfirm = () => {
        if (!selectedToArchiveRemoteLessonId) {
            return;
        }
        removeRemoteLesson(selectedToArchiveRemoteLessonId);
    };

    return (
        <>
            <ArchiveRestoreModal
                open={!!selectedToArchiveRemoteLessonId}
                title={remoteLessonsTexts.removeRemoteLessonModalTitle}
                confirmActionLoading={isRemoveRemoteLessonLoading}
                onOk={handleRemoveRemoteLessonConfirm}
                onCancel={handleUnselectToRemoveRemoteLessonId}
            />
            <AppTable
                title={remoteLessonsTexts.remoteLessonsList}
                emptyInfo={remoteLessonsTexts.emptyStateInfo}
                options={options}
                columns={columns}
                dataSource={remoteLessonsData || []}
                rowKey={({ id }) => id}
                isOptionsLoading={isRemoteLessonsDataInitialLoading}
                isError={isRemoteLessonsDataError}
                isDataInitialLoading={isRemoteLessonsDataInitialLoading}
                isDataRefetching={isRemoteLessonsDataRefetching}
                isTableManageUtilsHidden
                addButtonLabel={
                    canManageRemoteLessons ? remoteLessonsTexts.addRemoteLesson : undefined
                }
                onAddButtonClick={navigateToRemoteLessonAdd}
            />
        </>
    );
};
