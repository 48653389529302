import { Flex } from 'antd';
import { AppTooltipQuestionMarkIcon } from 'components/atoms';
import { colors } from 'theme/styledComponents/colors';

type ColumnTitleWithQuestionMarkTooltipProps = {
    title: string;
    tooltip: string;
};

export const ColumnTitleWithQuestionMarkTooltip = ({
    title,
    tooltip,
}: ColumnTitleWithQuestionMarkTooltipProps) => (
    <Flex gap={8} align="center" justify="center">
        <span>{title}</span>
        <AppTooltipQuestionMarkIcon tooltip={tooltip} color={colors.tooltip.tooltip3} />
    </Flex>
);
