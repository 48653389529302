import styled, { css } from 'styled-components';
import { AppCollapse } from 'components/atoms';
import { getTextStyle } from 'utils/getTextStyle';

export const StyledCollapse = styled(AppCollapse)`
    ${({ theme }) => css`
        border: none;

        & .ant-collapse-item {
            border-bottom: none;
            background-color: ${theme.colors.grayscale.gray1};

            & .ant-collapse-header {
                align-items: center;
                background-color: ${theme.colors.grayscale.gray1};

                & .ant-collapse-header-text {
                    color: ${theme.colors.primary.primary9};
                    ${getTextStyle('BodyMediumSemiBold')}
                }
            }

            & .ant-collapse-content {
                border-top: none;
            }

            & > .ant-collapse-content > .ant-collapse-content-box {
                padding: 0;
            }
        }
    `}
`;
