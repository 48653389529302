import { InspectionDTO, InspectionFormFields } from 'types';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseInspectionDTOIntoInspectionFormFields: (
    inspectionDTO: InspectionDTO,
) => InspectionFormFields = ({
    subject,
    documentNo,
    date,
    teacher,
    supervisor,
    supervisionForm,
}) => ({
    inspectionSubject: subject,
    inspectionIdNumber: documentNo,
    date: parseStringIntoDayjsDate(date),
    supervisionForm,
    supervisor,
    teacherId: teacher.id,
});
