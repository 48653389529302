import styled, { css } from 'styled-components';

export const RuleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const ValidatorContainer = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.sizes.marginNormal}px;
    `}
`;
