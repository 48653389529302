import { CalendarEventDetailedDto } from 'types';
import { pick } from 'lodash';
import { EventFormFields } from '../../../types/forms/event';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseEventDTOIntoEventFormFields: (
    eventDTO: CalendarEventDetailedDto,
) => EventFormFields = (eventDTO) => ({
    ...pick(eventDTO, ['type', 'name', 'description', 'freeFromSchool']),
    startDate: parseStringIntoDayjsDate(eventDTO.startDate),
    endDate: parseStringIntoDayjsDate(eventDTO.endDate),
});
