import styled, { css } from 'styled-components';
import { AppText } from 'components/atoms/CommonAppComponents';
import { getTextStyle } from 'utils/getTextStyle';
import { parentTexts } from 'consts/text';

const PaymentSummary = styled(AppText)`
    ${getTextStyle('H5')};
`;

const PaymentSummaryBlue = styled(PaymentSummary)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.primary9};
    `}
`;

export const SummaryText = ({ text }: { text: string }) => (
    <PaymentSummary>
        {parentTexts.settlement.summary} <PaymentSummaryBlue>{text}</PaymentSummaryBlue>
    </PaymentSummary>
);
