import { GroupDTO, GroupFormFields } from 'types';
import { groupDTOFields } from 'consts/group/groupDTOFields';

export const parseGroupDTOIntoGroupFormFields: (groupDTO: GroupDTO) => GroupFormFields = (
    groupDTO,
) => {
    const teacherIds = groupDTO[groupDTOFields.teachers].map((teacher) => teacher.id);

    return {
        name: groupDTO[groupDTOFields.name],
        teacherIds,
        childIdsToAdd: [],
    };
};
