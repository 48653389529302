import { generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SubscriptionId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { LinkDisplay } from 'components/molecules/Table/LinkDisplay/LinkDisplay';
import { AppText } from 'components/atoms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

type InstitutionNameDisplayProps = {
    subscriptionId: SubscriptionId;
    institutionName: string;
};

const Container = styled.div`
    ${({ theme }) => css`
        width: 130px;
        padding-right: ${theme.sizes.marginSmall}px;
    `}
`;

export const InstitutionNameDisplay = ({
    subscriptionId,
    institutionName,
}: InstitutionNameDisplayProps) => {
    const isMobile = useIsMobile();

    const institutionDetailsPath = generatePath(
        appPaths.app.admin.institutions.details.pickedInstitution.base,
        {
            subscriptionId,
        },
    );

    const link = <LinkDisplay to={institutionDetailsPath}>{institutionName}</LinkDisplay>;

    if (isMobile) {
        return link;
    }

    return (
        <Container>
            <AppText
                ellipsis={{
                    tooltip: {
                        title: institutionName,
                    },
                }}
            >
                {link}
            </AppText>
        </Container>
    );
};
