import styled, { css, useTheme } from 'styled-components';
import { AppText, AppTextProps } from 'components/atoms';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

type ContainerProps = { isShrinked?: boolean; isSupportedEllipsis?: boolean };

type LessonInfoProps = Pick<AppTextProps, 'children' | 'title'> &
    ContainerProps & {
        elementType?: 'lesson' | 'absence';
    };

const Container = styled.span.withConfig({
    shouldForwardProp: getShouldForwardProp(['isShrinked', 'isSupportedEllipsis']),
})<ContainerProps>`
    ${({ isShrinked, isSupportedEllipsis }) => css`
        flex: 0 ${isShrinked ? 1 : 0} auto;

        ${isSupportedEllipsis &&
        css`
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        `}
    `}
`;

export const TimetableInfo = ({
    isSupportedEllipsis,
    isShrinked,
    title,
    children,
    elementType = 'lesson',
}: LessonInfoProps) => {
    const theme = useTheme();

    return (
        <Container isShrinked={isShrinked} isSupportedEllipsis={isSupportedEllipsis}>
            <AppText
                title={title}
                textType="BodyMediumSemiBold"
                margin={0}
                color={
                    elementType === 'lesson'
                        ? theme.colors.primary.primary10
                        : theme.colors.red.red6
                }
            >
                {children}
            </AppText>
        </Container>
    );
};
