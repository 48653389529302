import { ChildProfilePhotoDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';

export const parseChildProfilePhotoDTOIntoJsonObject: (
    childProfilePhotoDTO: ChildProfilePhotoDTO,
) => object = (childProfilePhotoDTO) => {
    const { fileUri } = childProfilePhotoDTO;

    return {
        [jsonObjectsTexts.childProfilePhotoDTO.fields.fileUri]: fileUri,
    };
};
