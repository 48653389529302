import { ChildId, SpecialConsultationFormFields } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { SpecialConsultationDTO } from 'types/specialConstultation/specialConsultationDTO';

export const parseSpecialConsultationDTOIntoSpecialConsultationFormFields: (
    specialConsultationDTO: SpecialConsultationDTO,
) => SpecialConsultationFormFields = (specialConsultationDTO) => ({
    ...specialConsultationDTO,
    date: parseStringIntoDayjsDate(specialConsultationDTO.date),
    contactWith: specialConsultationDTO.contactWith,
    childIds: specialConsultationDTO.childrenArray.map((child) => child.id as ChildId),
});
