import { settingsTexts } from 'consts/text';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { PageBaseLayout } from 'components/layouts';
import { Subscription } from 'components/organisms';

export const AccountPackagePage = () => {
    const { data, isFetching, isError } = useAppQuery('ACCOUNT_PACKAGE', [], () =>
        StaffHTTPService.subscription.getAccountPackage(),
    );

    return (
        <PageBaseLayout isError={isError} isDataInitialLoading={isFetching}>
            {data && (
                <Subscription
                    titleBegin={settingsTexts.accountType.titleBegin}
                    titleEnd={settingsTexts.accountType.titleEnd}
                    packageData={data}
                />
            )}
        </PageBaseLayout>
    );
};
