export const remoteLessonSimpleDTOFields = {
    id: 'id',
    name: 'name',
    date: 'date',
    homeworkDeadline: 'homeworkDeadline',
    readBy: 'readBy',
    totalAmountOfRecipients: 'totalAmountOfRecipients',
    attachmentAttached: 'attachmentAttached',
    editable: 'editable',
} as const;
