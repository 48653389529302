import {
    AppFormItem,
    AppFormItemProps,
    AppRadioButton,
    AppRadioGroup,
    AppTooltipQuestionMarkIcon,
} from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { institutionTypeItems } from 'consts/institution/institutionType';

export const InstitutionTypePickerElement = (props: AppFormItemProps) => {
    const radioElements = institutionTypeItems.map(({ value, label }) => (
        <AppRadioButton key={value} value={value}>
            {label}
            {value === 'OTHER' && (
                <AppTooltipQuestionMarkIcon
                    marginLeft={8}
                    tooltip="Inna forma wychowania przedszkolnego"
                />
            )}
        </AppRadioButton>
    ));

    return (
        <AppFormItem
            label="Typ placówki"
            name={formFields.institutionType}
            rules={[{ required: true, message: formElementRequiredText }]}
            {...props}
        >
            <AppRadioGroup>{radioElements}</AppRadioGroup>
        </AppFormItem>
    );
};
