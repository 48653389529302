import { useCallback } from 'react';
import { App } from 'antd';
import {
    getErrorNotificationConfig,
    getSuccessNotificationConfig,
    getWarningNotificationConfig,
} from 'services/appNotification/appNotification';

export const useSimpleNotification = () => {
    const { notification } = App.useApp();

    const displaySuccess = useCallback(
        (message: string) => notification.success(getSuccessNotificationConfig(message)),
        [notification],
    );

    const displayError = useCallback(
        (message: string) => notification.error(getErrorNotificationConfig(message)),
        [notification],
    );

    const displayWarning = useCallback(
        (message: string) => notification.warning(getWarningNotificationConfig(message)),
        [notification],
    );

    return {
        displaySuccess,
        displayError,
        displayWarning,
    };
};
