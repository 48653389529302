import { AppFormItem, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { AbsenceTypePickerOptions } from 'consts/absence/absenceType';
import { timetableTexts } from 'consts/text';

export const AbsenceTypePickerElement = ({ ...props }) => (
    <AppFormItem label={timetableTexts.absence.selectAbsenceType} name={formFields.absenceType}>
        <AppSelect options={AbsenceTypePickerOptions} {...props} />
    </AppFormItem>
);
