import { AppModalForm } from 'components/organisms';
import { AppModal, FormHeader, FormNavButtons } from 'components/molecules';
import { HeaderContainer } from 'components/organisms/AppForm/AppForm.styled';
import { commonTexts, settlementTexts } from 'consts/text';
import { AppFormItem, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { CurrencyInputElement } from 'components/molecules/FormElements/CurrencyInputElement/CurrencyInputElement';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { AddBillFormFields } from 'types/forms/addBill';
import {
    ChildId,
    CommonFormProps,
    FormPageModeDependedTexts,
    PaymentId,
    PaymentTypes,
    TransferTypes,
} from 'types';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useEffect } from 'react';
import { parseTransactionDtoIntoTransactionFormFields } from 'utils/parsers/transaction/parseTransactionDtoIntoTransactionFormFields';

type ChildAddBillModalType = {
    childId?: ChildId | null;
    transactionId?: PaymentId | null;
    onCancel: () => void;
} & Pick<CommonFormProps, 'mode' | 'initialValues'>;

const addModeTexts: FormPageModeDependedTexts = {
    title: settlementTexts.children.addBill.title,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormPageModeDependedTexts = {
    title: settlementTexts.children.editBill.title,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const ChildAddEditBillModal = ({
    childId,
    transactionId,
    onCancel,
    mode = 'add',
}: ChildAddBillModalType) => {
    const isAddMode = mode === 'add';
    const { title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const { form, formInitialData, setFormData } = useEditForm<AddBillFormFields>();

    const { displaySuccess } = useSimpleNotification();

    const onAddBillSuccess = () => {
        displaySuccess(settlementTexts.children.successfullyAddTransactionMessage);
        onCancel();
    };

    const onEditBillSuccess = () => {
        displaySuccess(settlementTexts.children.successfullyEditTransactionMessage);
        onCancel();
    };

    const { data: transactionData, isFetching: isTransactionDataFetching } = useAppQuery(
        'CHILD_TRANSACTION',
        [childId, transactionId],
        () =>
            StaffHTTPService.settlements.getChildTransaction({
                childId: childId as ChildId,
                transactionId: transactionId as PaymentId,
            }),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: !isAddMode,
        },
    );

    useEffect(() => {
        if (transactionData) {
            setFormData(parseTransactionDtoIntoTransactionFormFields(transactionData));
        }
    }, [transactionData, form, setFormData]);

    const {
        mutate: addBill,
        error: addBillError,
        isLoading: isAddBillLoading,
    } = useAppMutation(StaffHTTPService.settlements.addChildTransaction, {
        key: ['ADD_BILL'],
        onSuccess: onAddBillSuccess,
        invalidateQueryKeys: [['SETTLEMENTS'], ['CHILD_SETTLEMENT'], ['CHILD_SETTLEMENT_PAYMENT']],
    });

    const {
        mutate: editBill,
        error: editBillError,
        isLoading: isEditBillLoading,
    } = useAppMutation(StaffHTTPService.settlements.editChildTransaction, {
        key: ['EDIT_BILL'],
        onSuccess: onEditBillSuccess,
        invalidateQueryKeys: [
            ['SETTLEMENTS'],
            ['CHILD_SETTLEMENT'],
            ['CHILD_SETTLEMENT_PAYMENT'],
            ['CHILD_TRANSACTION', childId, transactionId],
        ],
    });

    const onAddBillFinish = (billData: AddBillFormFields) =>
        isAddMode
            ? addBill({ transactionAddData: billData, childId: childId as ChildId })
            : editBill({
                  transactionEditData: billData,
                  transactionId: transactionId as PaymentId,
                  childId: childId as ChildId,
              });

    const billAddError = addBillError ? commonTexts.errorMessages.unknown : '';
    const billEditError = editBillError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppModal
            open={!!childId}
            onCancel={onCancel}
            isLoading={isAddBillLoading || isEditBillLoading || isTransactionDataFetching}
        >
            <AppModalForm<AddBillFormFields>
                error={isAddMode ? billAddError : billEditError}
                name="addBill"
                form={form}
                onFinish={onAddBillFinish}
            >
                <HeaderContainer isFormHeaderAtBottom={false}>
                    <FormHeader title={title} desktopTitleLevel={4} error={billAddError} />
                </HeaderContainer>
                <AppFormItem
                    label={settlementTexts.children.addBill.settlementType}
                    name={formFields.transactionType}
                >
                    <AppRadioGroup>
                        <AppRadioButton value={TransferTypes.PAYMENT}>
                            {settlementTexts.children.addBill.charge} (+)
                        </AppRadioButton>
                        <AppRadioButton value={TransferTypes.REFUND}>
                            {settlementTexts.children.addBill.refund} (-)
                        </AppRadioButton>
                    </AppRadioGroup>
                </AppFormItem>
                <AppFormItem
                    label={settlementTexts.children.addBill.refundMethod}
                    name={formFields.paymentFormType}
                >
                    <AppRadioGroup>
                        <AppRadioButton value={PaymentTypes.WIRE_TRANSFER}>
                            {settlementTexts.children.paymentTypes.transfer}
                        </AppRadioButton>
                        <AppRadioButton value={PaymentTypes.CASH}>
                            {settlementTexts.children.paymentTypes.cash}
                        </AppRadioButton>
                    </AppRadioGroup>
                </AppFormItem>
                <CurrencyInputElement
                    name={formFields.amount}
                    label={commonTexts.dataLabels.amount}
                />
                <FormNavButtons
                    formValuesBeforeChanges={formInitialData}
                    buttonText={buttonText}
                    withPlus={isAddMode}
                    leftIcon={buttonIcon}
                />
            </AppModalForm>
        </AppModal>
    );
};
