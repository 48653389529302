import { RemoteLessonsFormFields, RemoteLessonDTO } from 'types';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseRemoteLessonDTOIntoRemoteLessonsFormFields = (
    remoteLessonDTO: RemoteLessonDTO,
): RemoteLessonsFormFields => {
    const { date, homeworkDeadline, children, employees, ...restRemoteLessonDTO } = remoteLessonDTO;
    return {
        ...restRemoteLessonDTO,
        date: parseStringIntoDayjsDate(date),
        homeworkDeadline: homeworkDeadline ? parseStringIntoDayjsDate(homeworkDeadline) : null,
        userIds: employees.map((employee) => employee.userId),
        childIds: children.map((child) => child.childId),
        homeworkDescription: remoteLessonDTO.homework || '',
        attachments: remoteLessonDTO.attachments || [],
    };
};
