import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, timetableTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const staffAbsenceAddEditErrorParser: (
    error: unknown,
    defaultErrorMessage?: string,
) => string = (error, defaultErrorMessage = commonTexts.errorMessages.unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.ABSENCES_ALREADY_EXIST) {
        return timetableTexts.absence.absenceExistError;
    }
    if (code === apiErrorCodes.FORBIDDEN_TO_MODIFY_ABSENCE) {
        return timetableTexts.absence.forbiddenToModifyAbsence;
    }

    return defaultErrorMessage;
};
