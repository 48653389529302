import dayjs from 'dayjs';
import { PageBaseLayout } from 'components/layouts';
import { DatePickerArrowButtonType, DatePickerWithArrows } from 'components/molecules';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { customWeekStartEndFormat } from 'utils/customWeekStartEndFormat';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { TimetableSchedule } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useSpecialJournalLessonsPageNetworkManage } from './hooks/useSpecialJournalLessonsPageNetworkManage';

export const SpecialJournalLessonsPage = () => {
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const { journalId } = useJournalId();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const {
        lessonsScheduleData,
        isLessonsDataInitialLoading,
        isLessonsDataRefetching,
        isLessonsDataError,
    } = useSpecialJournalLessonsPageNetworkManage({
        selectedDate: dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'),
    });

    const weekPicker = (
        <DatePickerWithArrows
            picker="week"
            format={customWeekStartEndFormat}
            value={dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()}
            onChange={handleChangeDateFilter}
            onArrowButtonClick={(arrowButtonType: DatePickerArrowButtonType) =>
                handleClickNextOrPrevDate(arrowButtonType, 'week')
            }
        />
    );

    return (
        <PageBaseLayout
            headerContent={weekPicker}
            isDataInitialLoading={isLessonsDataInitialLoading}
            isDataRefetching={isLessonsDataRefetching}
            isError={isLessonsDataError}
        >
            {lessonsScheduleData && <TimetableSchedule timetableSchedule={lessonsScheduleData} />}
        </PageBaseLayout>
    );
};
