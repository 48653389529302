import { useEffect } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { MailThreadAddForm } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { MailThreadDTO, MailThreadFormFields, MailThreadId } from 'types';
import { messagesTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { formFields } from 'consts/form/formFields';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { parseMailThreadDTOIntoReplyMailThreadFormFields } from 'utils/parsers/mailThread/parseMailThreadDTOIntoReplyMailThreadFormFields';
import { parseReplyMailThreadFormFieldsIntoAddMailThreadDTO } from 'utils/parsers/mailThread/parseReplyMailThreadFormFieldsIntoAddMailThreadDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const MailThreadAddPage = () => {
    const { form, setFormData } = useEditForm<MailThreadFormFields>();
    const location = useLocation();
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const mailThreadIdToReply = location.state?.mailThreadId as MailThreadId | undefined;
    const replyMode = !!mailThreadIdToReply;

    const { data: mailThreadData } = useAppQuery(
        'MAIL_THREAD',
        [mailThreadIdToReply],
        () => StaffHTTPService.mailThreads.getMailThread(mailThreadIdToReply as MailThreadId),
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (!mailThreadData || form.getFieldValue(formFields.content)) {
            return;
        }
        const parsedReplyMailThreadFormFields =
            parseMailThreadDTOIntoReplyMailThreadFormFields(mailThreadData);
        setFormData(parsedReplyMailThreadFormFields);
    }, [mailThreadData, form, setFormData]);

    const onAddMailThreadSuccess = (addMailThreadResponse: AxiosResponse<MailThreadDTO>) => {
        const addedMailThreadId = addMailThreadResponse.data.id;
        displaySuccess(messagesTexts.successfullyAddMessageInfo);
        statefulNavigate(
            generatePath(appPaths.app.messages.pickedMailThread, {
                mailThreadId: addedMailThreadId,
            }),
            { replace: true },
        );
    };

    const {
        mutate: addMailThread,
        error: addMailThreadError,
        isLoading: isAddMailThreadLoading,
    } = useAppMutation(StaffHTTPService.mailThreads.addMailThread, {
        key: ['ADD_MAIL_THREAD'],
        onSuccess: onAddMailThreadSuccess,
        invalidateQueryKeys: [['MAIL_THREADS']],
    });

    const onAddMailThreadFinish = (mailThreadFormFields: MailThreadFormFields) => {
        const addMailThreadDTO = replyMode
            ? parseReplyMailThreadFormFieldsIntoAddMailThreadDTO(
                  mailThreadFormFields,
                  mailThreadIdToReply,
              )
            : mailThreadFormFields;
        addMailThread(addMailThreadDTO);
    };

    return (
        <MailThreadAddForm
            mode={replyMode ? 'reply' : 'add'}
            form={form}
            axiosError={addMailThreadError}
            isLoading={isAddMailThreadLoading}
            onFinish={onAddMailThreadFinish}
        />
    );
};
