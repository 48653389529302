import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';

export type DietPickerElementProps = AppFormItemProps & {
    options: { value: string; label: string }[];
    loading?: boolean;
    onChange?: (value: string) => void;
};

export const DietPickerElement = ({
    options,
    loading,
    onChange,
    ...props
}: DietPickerElementProps) => (
    <AppFormItem label="Dieta" name={formFields.diet} optional hasOptionalText {...props}>
        <AppSelect options={options} loading={loading} onChange={onChange} />
    </AppFormItem>
);
