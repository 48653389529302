import { ChildDiet } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseChildDietIntoJsonObject: (childDiet: ChildDiet) => object = (childDiet) => {
    const { institutionDiet, dinner, snack, breakfast, soup } = childDiet;

    if (!institutionDiet) {
        return {
            [jsonObjectsTexts.childDetailed.innerObjects.childDiet.fields.name]: EMPTY_VALUE_LABEL,
        };
    }

    return {
        [jsonObjectsTexts.childDetailed.innerObjects.childDiet.fields.name]: institutionDiet.name,
        [jsonObjectsTexts.childDetailed.innerObjects.childDiet.fields.breakfast]: breakfast
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.childDetailed.innerObjects.childDiet.fields.soup]: soup
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.childDetailed.innerObjects.childDiet.fields.dinner]: dinner
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
        [jsonObjectsTexts.childDetailed.innerObjects.childDiet.fields.snack]: snack
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
