export const groupDTOFields = {
    id: 'id',
    institutionId: 'institutionId',
    name: 'name',
    teachers: 'teachers',
    formerTeachers: 'formerTeachers',
    childrenArray: 'childrenArray',
    formerChildren: 'formerChildren',
    status: 'status',
    type: 'type',
} as const;
