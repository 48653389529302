import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { ChildId, SelectOptionType } from 'types';
import { formFields } from 'consts/form/formFields';

export type ChildPickerElementProps = AppFormItemProps & {
    options?: SelectOptionType<ChildId>[];
    loading?: boolean;
    multiple?: boolean;
};

type ModeData = {
    name: string;
    multipleSelect?: boolean;
};

const childIdModeData: ModeData = {
    name: formFields.childId,
};

const childrenIdsModeData: ModeData = {
    name: formFields.childIds,
    multipleSelect: true,
};

export const ChildPickerElement = ({
    label = 'Dziecko objęte pomocą',
    options,
    multiple,
    loading,
    disabled,
    ...props
}: ChildPickerElementProps) => {
    const { name, multipleSelect } = multiple ? childrenIdsModeData : childIdModeData;

    return (
        <AppFormItem label={label} name={name} disabled={disabled} {...props}>
            <AppSelect
                options={options}
                mode={multipleSelect ? 'multiple' : undefined}
                loading={loading}
                disabled={disabled}
            />
        </AppFormItem>
    );
};
