import mapValues from 'lodash/mapValues';
import { WeeklyScheduleFormFields } from 'types';
import { textEditorNoContentValidator } from 'utils/validators';

export const parseWeeklyScheduleFormFieldsIntoWeeklyScheduleFormFieldsWithoutTags: (
    weeklyScheduleFormFields: WeeklyScheduleFormFields,
) => WeeklyScheduleFormFields = (weeklyScheduleFormFields) =>
    mapValues(weeklyScheduleFormFields, (scheduleItemData) => {
        const isNoText = textEditorNoContentValidator(scheduleItemData);
        return isNoText ? '' : scheduleItemData;
    });
