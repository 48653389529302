import { ExtendedDataNode } from 'components/atoms';
import { CoreCurriculumDTO, CoreCurriculumName } from 'types';

export const mapCoreCurriculumListToCoreCurriculumNamesList = (
    coreCurriculumData: CoreCurriculumDTO[],
): CoreCurriculumName[] =>
    coreCurriculumData.flatMap((section) => section.points.map((point) => point.name));

export const coreCurriculumListToTreeReducer = (
    acc: ExtendedDataNode[],
    currentCoreCurriculum: CoreCurriculumDTO,
) => {
    const coreCurriculumPoints = currentCoreCurriculum.points.map((point) => ({
        title: `${point.name.slice(currentCoreCurriculum.number.length)}. ${point.description}`,
        key: point.name,
    }));

    return acc.concat({
        title: `${currentCoreCurriculum.number}. ${currentCoreCurriculum.title}`,
        key: currentCoreCurriculum.number,
        children: coreCurriculumPoints,
    });
};

export const parseCoreCurriculumDataToTreeNodes = (coreCurriculumData: CoreCurriculumDTO[]) =>
    coreCurriculumData.reduce<ExtendedDataNode[]>(coreCurriculumListToTreeReducer, []);
