import { EmployeeId, EmployeeUser, SelectOptionType } from 'types';
import { employeeUserFields } from 'consts/tables/employee/employeeUserFields';
import { getFullName } from '../../getFullName';

export const parseEmployeeUserIntoSelectOptionType: (
    employeeUser: EmployeeUser,
) => SelectOptionType<EmployeeId> = (employeeUser) => ({
    value: employeeUser[employeeUserFields.employeeId],
    label: getFullName(
        employeeUser[employeeUserFields.firstName],
        employeeUser[employeeUserFields.lastName],
    ),
});
