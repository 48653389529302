import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import {
    BankAccountNumberInputElement,
    FormNavButtons,
    SimpleInputElement,
} from 'components/molecules';
import { AppForm, AppFormProps } from 'components/organisms';
import { formFields } from 'consts/form/formFields';
import { commonTexts, settingsTexts } from 'consts/text';
import { SettlementOverallFormFields } from 'types';
import { DaysPickerElement } from 'components/molecules/FormElements/DaysPickerElement/DaysPickerElement';
import { AppText } from 'components/atoms';
import { colors } from 'theme/styledComponents/colors';

export type SettlementOverallEditFormProps = Omit<
    AppFormProps<SettlementOverallFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    onFinish: (overallData: SettlementOverallFormFields) => void;
    isViewMode?: boolean;
};

export const SettlementOverallEditForm = ({
    form,
    axiosError,
    initialValues,
    isViewMode,
    ...props
}: SettlementOverallEditFormProps) => {
    const settlementMealEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<SettlementOverallFormFields>
            withHeader={false}
            form={form}
            name="settlementOverallEdit"
            error={settlementMealEditError}
            {...props}
        >
            <AppText textType="BodyMediumSemiBold" color={colors.primary.primary10} marginLeft={0}>
                {settingsTexts.settlements.overall.paymentDueTitle}
            </AppText>
            <AppText
                textType="BodySmall"
                color={colors.grayscale.gray9}
                marginLeft={0}
                marginTop={6}
                marginBottom={16}
            >
                {settingsTexts.settlements.overall.paymentDueDescription}
            </AppText>
            <AppText
                textType="BodyMediumSemiBold"
                color={colors.primary.primary10}
                marginLeft={0}
                marginTop={0}
                marginBottom={16}
            >
                {settingsTexts.settlements.overall.paymentWorkingDaysTitle}
            </AppText>
            <DaysPickerElement selectMode="multiple" disabled={isViewMode} />
            <AppText
                textType="BodyMediumSemiBold"
                color={colors.primary.primary10}
                marginLeft={0}
                marginTop={0}
                marginBottom={16}
            >
                {settingsTexts.settlements.overall.paymentAccountNumberTitle}
            </AppText>
            <BankAccountNumberInputElement />
            <SimpleInputElement
                label={settingsTexts.settlements.overall.formLabels.recipentName}
                name={formFields.recipientName}
                disabled={isViewMode}
            />

            {!isViewMode && (
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={commonTexts.actionLabels.save}
                    leftIcon={<IconDeviceFloppy size={20} />}
                />
            )}
        </AppForm>
    );
};
