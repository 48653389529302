import { useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { LessonsFilters } from 'types';
import { parseEmployeeUserIntoSelectOptionType } from 'utils/parsers/employee/parseEmployeeUserIntoSelectOptionType';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { employeeUserFields } from 'consts/tables/employee/employeeUserFields';
import { getTimetableScheduleFromTimetable } from 'utils/getTimetableScheduleFromTimetable';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { findEmployeeIdByUserId } from 'utils/findEmployeeIdByUserId';
import { TimetableScheduleData } from 'components/templates/timetable/TimetableSchedule/TimetableSchedule.types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type TimetablePageFilters = Pick<LessonsFilters, 'teacherId'> & {
    date: string;
};

type TimetablePageNetworkManageParams = {
    filtersValues: TimetablePageFilters;
    canViewAllEmployeeTimetable?: boolean;
};

export const useTimetablePageNetworkManage = ({
    filtersValues,
    canViewAllEmployeeTimetable,
}: TimetablePageNetworkManageParams) => {
    const userDetails = useUserDetails();

    const {
        data: employeesData,
        isInitialLoading: isEmployeesDataInitialLoading,
        isFetching: isEmployeesDataFetching,
        isError: isEmployeesDataError,
    } = useAppQuery(
        'EMPLOYEE_SCHEDULE',
        [],
        StaffHTTPService.employeeSchedule.getLessonsEmployees,
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const userEmployeeId = useMemo(
        () => (employeesData ? findEmployeeIdByUserId(userDetails.id, employeesData) : undefined),
        [employeesData, userDetails.id],
    );

    const selectedDate = parseStringIntoDayjsDate(filtersValues.date);
    const defaultEmployeeIdFilter = employeesData?.[0]?.[employeeUserFields.employeeId];
    const employeeIdFilter = filtersValues.teacherId || defaultEmployeeIdFilter;
    const filters: TimetablePageFilters = {
        teacherId: canViewAllEmployeeTimetable ? employeeIdFilter : userEmployeeId,
        date: filtersValues.date,
    };

    const {
        data: timetableData,
        isInitialLoading: isTimetableDataInitialLoading,
        isRefetching: isTimetableDataRefetching,
        isError: isTimetableDataError,
    } = useAppQuery(
        'EMPLOYEE_SCHEDULE',
        [filters],
        () => StaffHTTPService.employeeSchedule.getEmployeeSchedule(filters),
        {
            enabled: !!filters.teacherId,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const timetableScheduleData = useMemo<TimetableScheduleData | null>(() => {
        if (!timetableData) {
            return null;
        }

        return getTimetableScheduleFromTimetable(timetableData, selectedDate);
    }, [timetableData, selectedDate]);

    const teachersOptions = useMemo(() => {
        if (!employeesData) {
            return [];
        }
        return employeesData.map(parseEmployeeUserIntoSelectOptionType);
    }, [employeesData]);

    return {
        timetableScheduleData,
        teachersOptions,
        defaultEmployeeIdFilter,
        isTimetableDataInitialLoading,
        isEmployeesDataInitialLoading,
        isTimetableDataRefetching,
        isEmployeesDataFetching,
        isTimetableDataError,
        isEmployeesDataError,
    };
};
