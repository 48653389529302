import { AppModal, FormNavButtons } from 'components/molecules';
import { AppModalForm } from 'components/organisms';
import { HeaderContainer } from 'components/organisms/AppForm/AppForm.styled';
import { commonTexts, settlementTexts } from 'consts/text';
import { AppFormItem, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { CourseId, AdditionalLessonJournalDTO } from 'types';
import { Form } from 'antd';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { IconArrowBarRight } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

type AdditionalLessonJournalProps = {
    onClose: () => void;
    courseId: CourseId;
};

export const AdditionalLessonJournalSelectModal = ({
    onClose,
    courseId,
}: AdditionalLessonJournalProps) => {
    const [form] = Form.useForm();
    const { statefulNavigate } = useNavigateWithState();

    const {
        data: journalsData,
        isInitialLoading: isJournalDataInitialLoading,
        isRefetching: isJournalDataRefetching,
        isError: isJournalDataError,
    } = useAppQuery(
        'SETTLEMENT_EXTRA_LESSON_JOURNAL',
        [courseId],
        () => StaffHTTPService.settlements.getJournalsForExtraLessons(courseId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (journalsData) {
            form.setFieldValue(formFields.journalId, journalsData[0].journalId);
        }
    }, [form, journalsData]);

    const extraLessonsEditErrorMessage = isJournalDataError
        ? commonTexts.errorMessages.unknown
        : '';

    const onFormSubmit = (values: AdditionalLessonJournalDTO) => {
        statefulNavigate(
            generatePath(appPaths.app.journals.specialJournalDetails.pickedJournal.base, {
                journalId: values.journalId,
            }),
        );
    };

    return (
        <AppModal
            width={440}
            open
            onCancel={onClose}
            isLoading={isJournalDataInitialLoading || isJournalDataRefetching}
        >
            <AppModalForm
                name="extraLessonEdit"
                form={form}
                onFinish={onFormSubmit}
                error={extraLessonsEditErrorMessage}
            >
                <HeaderContainer isFormHeaderAtBottom={false} />
                <AppFormItem
                    label={settlementTexts.extraLessons.form.chooseJournal}
                    name={formFields.journalId}
                    rules={[{ required: true, message: formElementRequiredText }]}
                >
                    <AppSelect
                        options={journalsData?.map((journal) => ({
                            label: journal.journalName,
                            value: journal.journalId,
                        }))}
                        loading={isJournalDataInitialLoading || isJournalDataRefetching}
                    />
                </AppFormItem>
                <FormNavButtons
                    buttonText={commonTexts.actionLabels.goToJournal}
                    leftIcon={<IconArrowBarRight />}
                />
            </AppModalForm>
        </AppModal>
    );
};
