import { FormFooter, FormFooterProps, FormHeader, FormHeaderProps } from 'components/molecules';
import {
    AppDotWithNumberSteps,
    AppFormBase,
    AppFormBaseProps,
    ComponentBlockingAppLoader,
    AppGoBackButton,
    AppFormContentContainer,
    AppFormBanner,
} from 'components/atoms';
import { FormPageProps, PossibleFormData } from 'types/forms/forms';
import { AppFormContext } from 'contexts/AppFormContext';
import { useAppFormInnerErrorContext } from 'hooks/useAppFormInnerErrorContext/useAppFormInnerErrorContext';
import { FullWidthContainer, HeaderContainer, OutsideSpreader } from './AppForm.styled';

export type AppFormProps<AppFormFields> = AppFormBaseProps<AppFormFields> &
    FormHeaderProps &
    FormFooterProps &
    Partial<FormPageProps> & {
        children?: React.ReactNode;
        withGoBack?: boolean;
        withHeader?: boolean;
        withOutsideSpreaders?: boolean;
        isFormHeaderAtBottom?: boolean;
    };

const DEFAULT_FORM_MAX_WIDTH = 440;

export const AppForm = <AppFormFields extends PossibleFormData>({
    children,
    title,
    desktopTitleLevel,
    subTitle,
    linkButton,
    onLinkButtonClick,
    overLinkText,
    error,
    withGoBack = false,
    withHeader = true,
    withOutsideSpreaders = true,
    isFormHeaderAtBottom = false,
    currentStep,
    stepsCount,
    isLoading,
    titleAlign,
    maxWidth = DEFAULT_FORM_MAX_WIDTH,
    ...props
}: AppFormProps<AppFormFields>) => {
    const { contextValue, innerError } = useAppFormInnerErrorContext();

    const isFormWithSteps = typeof currentStep === 'number' && typeof stepsCount === 'number';

    return (
        <AppFormContext.Provider value={contextValue}>
            <AppFormBase<AppFormFields>
                size="large"
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
                validateTrigger={['onSubmit']}
                {...props}
            >
                {(withGoBack || withOutsideSpreaders) && (
                    <FullWidthContainer>
                        {withGoBack ? <AppGoBackButton /> : <OutsideSpreader />}
                    </FullWidthContainer>
                )}
                <AppFormContentContainer maxWidth={maxWidth}>
                    {withHeader && (
                        <HeaderContainer isFormHeaderAtBottom={isFormHeaderAtBottom}>
                            <FormHeader
                                title={title}
                                desktopTitleLevel={desktopTitleLevel}
                                subTitle={subTitle}
                                error={error || innerError}
                                titleAlign={titleAlign}
                            />
                            {isFormWithSteps && (
                                <AppDotWithNumberSteps
                                    current={currentStep}
                                    stepsCount={stepsCount}
                                />
                            )}
                        </HeaderContainer>
                    )}
                    {!withHeader && error && <AppFormBanner mode="ERROR" text={error} />}
                    {children}
                    <FormFooter
                        linkButton={linkButton}
                        onLinkButtonClick={onLinkButtonClick}
                        overLinkText={overLinkText}
                    />
                </AppFormContentContainer>
                {withOutsideSpreaders && <OutsideSpreader />}
                {isLoading && <ComponentBlockingAppLoader />}
            </AppFormBase>
        </AppFormContext.Provider>
    );
};
