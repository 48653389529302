import { SettlementMealsFormFields, UpdateSettlementMealsDto } from 'types';
import { parseDayjsTimeIntoHoursMinutesFormat } from '../dateTime/parseDayjsTimeIntoHoursMinutesFormat';
import { parseFormattedBankAccountNumberIntoBankAccountNumber } from '../bankAccountNumber/parseFormattedBankAccountNumberIntoBankAccountNumber';

export const parseSettlementMealsFormFieldsIntoUpdateSettlementMealsDTO: (
    settlementMealsFormFields: SettlementMealsFormFields,
) => UpdateSettlementMealsDto | null = (settlementMealsFormFields) => {
    const {
        mealPaymentType,
        absenceReportType,
        mealProviderType,
        absenceReportTime,
        recipientName,
        cateringAccountNumber,
        cateringTransferName,
    } = settlementMealsFormFields;

    if (!absenceReportType || !mealProviderType || !mealPaymentType || !absenceReportTime) {
        return null;
    }

    const isCateringDetailsProvided =
        recipientName && cateringTransferName && cateringAccountNumber;

    const updateCateringPaymentDetailsDto = isCateringDetailsProvided
        ? {
              recipientName,
              transferTitle: cateringTransferName,
              bankAccountNumber:
                  parseFormattedBankAccountNumberIntoBankAccountNumber(cateringAccountNumber),
          }
        : null;

    return {
        mealPaymentType,
        absenceReportType,
        mealProvider: mealProviderType,
        absenceReportTime: parseDayjsTimeIntoHoursMinutesFormat(absenceReportTime),
        cateringPaymentDetails: updateCateringPaymentDetailsDto,
    };
};
