import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    ChildAddPage,
    ChildDetailsPage,
    ChildEditPage,
    ChildrenPage,
    ChildAddDiscountPage,
    ChildEditDiscountPage,
} from 'pages';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';

export const childRoutes: RouteObject[] = [
    {
        path: appPaths.app.children.base,
        element: (
            <AbilityRoute
                can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildrenPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.children.add,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.children.edit.base,
        element: <Navigate to={appPaths.app.children.base} />,
    },
    {
        path: appPaths.app.children.edit.pickedChild,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.children.details.base,
        element: <Navigate to={appPaths.app.children.base} />,
    },
    {
        path: appPaths.app.children.details.pickedChild.base,
        element: (
            <AbilityRoute
                can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildDetailsPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.children.details.pickedChild['add-discount'].base,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildAddDiscountPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.children.details.pickedChild['edit-discount'].base,
        element: <Navigate to={appPaths.app.children.base} />,
    },
    {
        path: appPaths.app.children.details.pickedChild['edit-discount'].pickedDiscount,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildEditDiscountPage />
            </AbilityRoute>
        ),
    },
];
