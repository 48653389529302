import {
    FiltersParams,
    GetHistoryOfChangesFilters,
    HistoryChangeRefersTo,
    HistoryChangeType,
} from 'types';
import { historyOfChangesFilterFields } from 'consts/historyChange/historyOfChangesFilterFields';
import { parseMultipleFilterValueIntoValue } from 'utils/parsers/filter/parseMultipleFilterValueIntoValue';

export const parseFilterParamsIntoGetHistoryChangesFiltersValues: (
    filterParams: FiltersParams,
    searchFilterParam: string,
) => GetHistoryOfChangesFilters = (filterParams, searchFilterParam) => {
    const historyTypeFilterParams = filterParams[historyOfChangesFilterFields.historyTypes];
    const refersToFilterParams = filterParams[historyOfChangesFilterFields.referTo];
    const historyTypeFilterValues = historyTypeFilterParams
        ? ((historyTypeFilterParams as string[]).map(
              parseMultipleFilterValueIntoValue,
          ) as HistoryChangeType[])
        : undefined;
    const refersToFilterValues = refersToFilterParams
        ? ((refersToFilterParams as string[]).map(
              parseMultipleFilterValueIntoValue,
          ) as HistoryChangeRefersTo[])
        : undefined;

    return {
        search: searchFilterParam || undefined,
        historyTypes: historyTypeFilterValues,
        referTo: refersToFilterValues,
        startDate: filterParams[historyOfChangesFilterFields.startDate]
            ? (filterParams[historyOfChangesFilterFields.startDate] as string)
            : undefined,
        endDate: filterParams[historyOfChangesFilterFields.endDate]
            ? (filterParams[historyOfChangesFilterFields.endDate] as string)
            : undefined,
    };
};
