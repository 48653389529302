import { IconCreditCard, IconHourglass } from '@tabler/icons-react';
import { AppConfirmModal, AppTable, AppTableProps } from 'components/organisms';
import { commonTexts, settingsTexts } from 'consts/text';
import { BillDTO, PaymentStatusEnum, PayuPaymentStatus } from 'types';
import { useMemo, useState } from 'react';
import { DateWithAmountDisplay, PriceDisplay, TableTagDisplay } from 'components/molecules';
import {
    paymentStatusesLabels,
    paymentsFields,
    tagColorsByPaymentStatus,
} from 'consts/payments/payments';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { paymentErrorParser } from 'utils/errorHandlers/payment/paymentErrorParser';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import styled, { css } from 'styled-components';

export const StyledHourglassIcon = styled(IconHourglass)`
    ${({ theme }) => css`
        & {
            color: ${theme.colors.primary.primary8};
        }
    `}
`;

const columns: AppTableProps<BillDTO>['columns'] = [
    {
        title: settingsTexts.payments.columnNames.dateIssue,
        dataIndex: paymentsFields.date,
        key: paymentsFields.date,
        render: (_, payment) => (
            <DateWithAmountDisplay amount={payment.totalAmount} date={payment.date} />
        ),
        width: '25%',
        isVisibleAsExtendableInMobile: false,
        mobileWidth: '100%',
    },
    {
        title: settingsTexts.payments.columnNames.status,
        dataIndex: paymentsFields.paid,
        key: paymentsFields.paid,
        render: (paid) => (
            <TableTagDisplay
                value={paid ? PaymentStatusEnum.paid : PaymentStatusEnum.unpaid}
                labelMapper={paymentStatusesLabels}
                colorMapper={tagColorsByPaymentStatus}
            />
        ),
        width: '15%',
        align: 'center',
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: settingsTexts.payments.columnNames.amount,
        dataIndex: paymentsFields.totalAmount,
        key: paymentsFields.totalAmount,
        render: (totalAnount) => <PriceDisplay value={totalAnount} />,
        isVisibleAsExtendableInMobile: false,
        isInvisibleInMobile: true,
    },
];

export const PaymentsPage = () => {
    const { displayError } = useSimpleNotification();
    const [modalOpen, setModalOpen] = useState(false);
    const { id: institutionId } = useCurrentInstitution();

    const { mutate: createPaymentRedirect, isLoading: isCreatingPaymentRedirectLoading } =
        useAppMutation(StaffHTTPService.institutions.getPaymentRedirect, {
            key: ['PAY_BILL'],
            onSuccess: ({ data }) => {
                if (data.status === PayuPaymentStatus.COMPLETED) {
                    setModalOpen(true);
                    return;
                }
                window.location.href = data.redirectUri;
            },
            onError: (error) => {
                const { code } = getErrorMessageAndCode(error);
                displayError(paymentErrorParser(error));
                if (code === apiErrorCodes.BILL_ALREADY_PAID) {
                    queryClient.invalidateQueries([institutionId, 'PAYMENTS']);
                }
            },
        });

    const getOptions = useMemo(
        () => [
            {
                label: settingsTexts.payments.listActions.pay,
                onClick: (bill: BillDTO) => createPaymentRedirect(bill.id),
                Icon: <IconCreditCard />,
                checkIfVisible: (bill: BillDTO) => !bill.paid,
            },
        ],
        [createPaymentRedirect],
    );

    const { data, isRefetching, isInitialLoading, isError } = useAppQuery(
        'PAYMENTS',
        [],
        () => StaffHTTPService.institutions.getPayments(),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <>
            <AppConfirmModal
                icon={<StyledHourglassIcon />}
                title={settingsTexts.payments.paymentCompletedModalTitle}
                onOk={() => setModalOpen(false)}
                okButtonProps={{ title: commonTexts.actionLabels.goBack }}
                open={modalOpen}
            />
            <AppTable<BillDTO>
                title={settingsTexts.payments.paymentsListTitle}
                options={getOptions}
                columns={columns}
                dataSource={data}
                isError={isError}
                emptyInfo={settingsTexts.payments.emptyPaymentsList}
                isDataInitialLoading={isInitialLoading}
                isDataRefetching={isRefetching || isCreatingPaymentRedirectLoading}
                isTableManageUtilsHidden
                rowKey={({ id }) => id}
                hideExpandColumn
            />
        </>
    );
};
