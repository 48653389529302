import styled, { css } from 'styled-components';
import { AppList } from 'components/atoms';

export const StyledAppListItem = styled(AppList.Item)`
    ${({ theme }) => css`
        &.ant-list-item {
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: ${theme.sizes.marginNormal}px;
            list-style-type: none;
        }

        &:not(:first-child) {
            margin-bottom: ${theme.sizes.marginNormal}px;
            border-block-end: 1px solid ${theme.colors.primary.primary3};
        }
    `}
`;

export const MessageInfoContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            flex-direction: column;
            gap: 4px;
        }
    `}
`;
