import { RemoteLessonDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseRemoteLessonDTOIntoJsonObject: (remoteLessonDTO: RemoteLessonDTO) => object = (
    remoteLessonDTO,
) => {
    const {
        name,
        date,
        description,
        homeworkDeadline,
        children,
        employees,
        homework,
        attachments,
    } = remoteLessonDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const formattedHomeworkDeadline = homeworkDeadline
        ? parseStringIntoFormattedStringDate(homeworkDeadline)
        : EMPTY_VALUE_LABEL;
    const childrenNames = children.map((child) => child.name);
    const employeesNames = employees.map((employee) => employee.name);
    const attachmentsNames = (attachments || []).map((attachment) => attachment.fileName);

    return {
        [jsonObjectsTexts.remoteLessonDTO.fields.name]: name,
        [jsonObjectsTexts.remoteLessonDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.remoteLessonDTO.fields.children]: childrenNames,
        [jsonObjectsTexts.remoteLessonDTO.fields.employees]: employeesNames,
        [jsonObjectsTexts.remoteLessonDTO.fields.description]: description,
        [jsonObjectsTexts.remoteLessonDTO.fields.homework]: homework || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.remoteLessonDTO.fields.homeworkDeadline]: formattedHomeworkDeadline,
        [jsonObjectsTexts.remoteLessonDTO.fields.attachments]: attachmentsNames,
    };
};
