import { Input } from 'antd';
import { PasswordProps } from 'antd/es/input';
import styled from 'styled-components';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';

const StyledInputPassword = styled(Input.Password)`
    ${inputAndPickerBorderStyle}
`;

export const AppPasswordInput = (props: PasswordProps) => <StyledInputPassword {...props} />;
