import { StaffMainAppModules } from 'types';
import { appPaths } from 'consts/paths/paths';

export const STAFF_APP_MODULES_PATHS: Record<StaffMainAppModules, string> = {
    EMPLOYEES: appPaths.app.employees.base,
    CHILDREN: appPaths.app.children.base,
    GROUPS: appPaths.app.groups.base,
    JOURNALS: appPaths.app.journals.base,
    SETTLEMENT: appPaths.app.settlement.base,
    CALENDAR: appPaths.app.calendar.base,
    TIMETABLE: appPaths.app.timetable.base,
    REMOTE_LESSONS: appPaths.app['remote-lessons'].base,
    DIET: appPaths.app.menu.base,
    SETTINGS: appPaths.app.settings.base,
    MESSAGES: appPaths.app.messages.base,
};
