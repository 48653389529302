import { LessonDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { lessonRepetitionLabels } from 'consts/lesson/lessonRepetitionLabels';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseLessonDTOIntoJsonObject: (lessonDTO: LessonDTO) => object = (lessonDTO) => {
    const { name, startDate, endDate, teacher, children, journal, timeFrom, timeTo, repetition } =
        lessonDTO;

    const teacherJsonObject = teacher
        ? {
              [jsonObjectsTexts.lessonDTO.innerObjects.lessonTeacherDTO.fields.firstname]:
                  teacher.firstName,
              [jsonObjectsTexts.lessonDTO.innerObjects.lessonTeacherDTO.fields.lastname]:
                  teacher.lastName,
          }
        : EMPTY_VALUE_LABEL;
    const formattedStartDate = parseStringIntoFormattedStringDate(startDate);
    const formattedEndDate = parseStringIntoFormattedStringDate(endDate);
    const formattedTimeFrom = parseStringIntoFormattedStringTime(timeFrom);
    const formattedTimeTo = parseStringIntoFormattedStringTime(timeTo);
    const childrenNames = children.map((child) => ({
        [jsonObjectsTexts.lessonDTO.innerObjects.lessonChildDTO.fields.firstname]: child.firstName,
        [jsonObjectsTexts.lessonDTO.innerObjects.lessonChildDTO.fields.lastname]: child.lastName,
    }));
    const repetitionLabel = repetition ? lessonRepetitionLabels[repetition] : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.lessonDTO.fields.name]: name,
        [jsonObjectsTexts.lessonDTO.fields.teacher]: teacherJsonObject,
        [jsonObjectsTexts.lessonDTO.fields.journal]: journal.name,
        [jsonObjectsTexts.lessonDTO.fields.startDate]: formattedStartDate,
        [jsonObjectsTexts.lessonDTO.fields.endDate]: formattedEndDate,
        [jsonObjectsTexts.lessonDTO.fields.timeFrom]: formattedTimeFrom,
        [jsonObjectsTexts.lessonDTO.fields.timeTo]: formattedTimeTo,
        [jsonObjectsTexts.lessonDTO.fields.children]: childrenNames,
        [jsonObjectsTexts.lessonDTO.fields.repetition]: repetitionLabel,
    };
};
