import { ElementColor } from 'types';

export const colors = {
    backdrop: '#FFFFFFBB',
    background: {
        main: '#f3f4fa',
    },
    primary: {
        primary10: '#2042e7',
        primary9: '#3456f3',
        primary8: '#506aed',
        primary7: '#7e91f1',
        primary6: '#acb9f6',
        primary5: '#cbd4fb',
        primary4: '#dae0fb',
        primary3: '#eaeefe',
        primary2: '#f9faff',
        primary1: '#fdfdff',
    },
    grayscale: {
        gray10: '#50505e',
        gray9: '#6a6a81',
        gray8: '#9d9eb3',
        gray7: '#b1b2c2',
        gray6: '#c2c3d0',
        gray5: '#d2d2dc',
        gray4: '#e0e0e7',
        gray3: '#ededf1',
        gray2: '#f9f9fa',
        gray1: '#ffffff',
    },
    green: {
        green10: '#1e4131',
        green9: '#23523a',
        green8: '#266b43',
        green7: '#25884f',
        green6: '#32a96f',
        green5: '#65c89c',
        green4: '#abe1c6',
        green3: '#d1efde',
        green2: '#ebf7f1',
        green1: '#f5f9f8',
    },
    yellow: {
        yellow10: '#6f3710',
        yellow9: '#89440e',
        yellow8: '#a95708',
        yellow7: '#cb7404',
        yellow6: '#df9806',
        yellow5: '#e7b813',
        yellow4: '#ffcc3d',
        yellow3: '#f6e780',
        yellow2: '#faf1c1',
        yellow1: '#f9f6e7',
    },
    red: {
        red10: '#8e1929',
        red9: '#b11d31',
        red8: '#db2338',
        red7: '#ff4949',
        red6: '#fb4c5f',
        red5: '#fa778c',
        red4: '#faacbd',
        red3: '#fbd2dc',
        red2: '#fceeee',
        red1: '#fcf8f6',
    },
    tooltip: {
        tooltip3: '#9D9EB3',
        tooltip2: '#E36C44',
        tooltip1: '#F1B6A2',
    },
    violet: {
        violet2: '#6451DF',
        violet1: '#EAE8FF',
    },
    orange: {
        orange1: '#FF8300',
    },
} as const;

export const elementColors: Record<'green' | 'gray' | 'yellow' | 'red', ElementColor> = {
    green: {
        textColor: colors.green.green8,
        backgroundColor: colors.green.green3,
    },
    gray: {
        textColor: colors.grayscale.gray9,
        backgroundColor: colors.grayscale.gray3,
    },
    yellow: {
        textColor: colors.yellow.yellow7,
        backgroundColor: colors.yellow.yellow2,
    },
    red: {
        textColor: colors.red.red7,
        backgroundColor: colors.red.red2,
    },
};
