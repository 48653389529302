import { axiosErrors } from 'consts/errors/axiosErrors';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const employeeAddErrorParser = (error: unknown) => {
    const { code, message } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (message.includes('phoneNumber')) {
        return commonTexts.errorMessages.invalidPhoneNumber;
    }
    if (code === apiErrorCodes.USER_EMAIL_TAKEN) {
        return commonTexts.errorMessages.userEmailTakenEnterAnother;
    }
    if (code === apiErrorCodes.USER_ALREADY_EXISTS) {
        return commonTexts.errorMessages.userEmailTakenEnterAnother;
    }

    return commonTexts.errorMessages.unknown;
};
