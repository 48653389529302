import { useState } from 'react';
import { WithId } from 'types/common/WithId';

type GuardianOrParentProps<FormFields> = {
    onItemAdd: (values: FormFields) => void;
    onItemToEditPick: (values: WithId<FormFields>) => void;
    onItemEditFinish: (values: FormFields) => void;
    onItemRemove: (values: WithId<FormFields>) => void;
    clearSelectedItem: () => void;
};

export const useGuardianOrParent = <FormFields>({
    onItemAdd,
    onItemToEditPick,
    onItemEditFinish,
    onItemRemove,
    clearSelectedItem,
}: GuardianOrParentProps<FormFields>) => {
    const [isModalFormOpen, setIsModalFormOpen] = useState(false);

    const handleModalFormOpen = () => setIsModalFormOpen(true);

    const handleModalFormClose = () => {
        setIsModalFormOpen(false);
        clearSelectedItem();
    };

    const handleDataItemAdd = (values: FormFields) => {
        onItemAdd(values);
        handleModalFormClose();
    };

    const handleDataItemEdit = (values: FormFields) => {
        onItemEditFinish(values);
        handleModalFormClose();
    };

    const handleDataItemRemove = (selectedTableDataItem: WithId<FormFields>) => {
        onItemRemove(selectedTableDataItem);
    };

    const handleTableDataItemEditClick = (selectedTableDataItem: WithId<FormFields>) => {
        onItemToEditPick(selectedTableDataItem);
        setIsModalFormOpen(true);
    };

    return {
        isModalFormOpen,
        handleModalFormOpen,
        handleModalFormClose,
        handleDataItemAdd,
        handleDataItemEdit,
        handleDataItemRemove,
        handleTableDataItemEditClick,
    };
};
