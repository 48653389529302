import { DetailsItem } from 'components/atoms/DetailsItem/DetailsItem';
import styled, { css } from 'styled-components';
import React from 'react';

type AppFormDetailsProps = {
    label: string;
    value?: React.ReactNode | string;
    className?: string;
};

const Container = styled.div`
    ${({ theme }) => css`
        margin-bottom: 16px;
        width: 100%;
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        background-color: ${theme.colors.primary.primary1};
        border: 1px solid ${theme.colors.primary.primary5};
        border-radius: ${theme.borders.borderRadiusLarge}px;
        box-sizing: border-box;
    `}
`;

export const AppFormDetails = ({ label, value, className }: AppFormDetailsProps) => (
    <Container className={className}>
        <DetailsItem title={label}>{value}</DetailsItem>
    </Container>
);
