import { Navigate, RouteObject } from 'react-router-dom';
import { MessagePanelLayout, SideMenuLayout } from 'components/layouts';
import { appPaths } from 'consts/paths/paths';
import { accountRoutes } from 'navigation/routes/loggedApp/routes/parent/account';
import { ParentMailThreadAddPage, ParentMailThreadDetailsPage, ParentMenuDetailsPage } from 'pages';
import { ParentSettlementPage } from 'pages/app/parent/settlement/ParentSettlementPage/ParentSettlementPage';
import { ParentPaymentPage } from 'pages/app/parent/settlement/ParentPaymentPage/ParentPaymentPage';
import { newsRoutes } from './parent/news';
import { childRoutes } from './parent/child';

export const parentRoutes: RouteObject[] = [
    {
        element: <SideMenuLayout />,
        children: [
            {
                path: appPaths.app.parent.base,
                element: <Navigate to={appPaths.app.parent.news.attendance.base} />,
            },
            {
                path: appPaths.app.parent.menu,
                element: <ParentMenuDetailsPage />,
            },
            {
                path: appPaths.app.parent.messages.add,
                element: <ParentMailThreadAddPage />,
            },
            { path: appPaths.app.parent.settlement.base, element: <ParentSettlementPage /> },
            { path: appPaths.app.parent.settlement.payment, element: <ParentPaymentPage /> },
            ...newsRoutes,
            ...childRoutes,
            ...accountRoutes,
        ],
    },
    {
        element: <SideMenuLayout withoutInnerLayout />,
        children: [
            {
                path: appPaths.app.parent.messages.base,
                element: <MessagePanelLayout isParent />,
                children: [
                    {
                        path: appPaths.app.parent.messages.pickedMailThread,
                        element: <ParentMailThreadDetailsPage />,
                    },
                ],
            },
        ],
    },
];
