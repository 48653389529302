import { useCallback, useState } from 'react';
import useForm from 'antd/es/form/hooks/useForm';
import { PossibleFormData } from 'types';

export const useEditForm = <AppFormFields extends PossibleFormData>() => {
    const [form] = useForm<AppFormFields>();
    const [formInitialData, setFormInitialData] = useState<AppFormFields>();

    const setFormData = useCallback(
        (formFields: AppFormFields) => {
            setFormInitialData(formFields);
            form.setFieldsValue(formFields);
        },
        [form],
    );

    return { form, formInitialData, setFormData };
};
