import styled, { css } from 'styled-components';
import { AppTitle } from 'components/atoms';

export const GoBackButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.sizes.marginLarge}px;
`;

export const OuterContainer = styled.div`
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`;

export const StyledTitle = styled(AppTitle)`
    &.ant-typography {
        margin: 0;
        font-weight: 700;
        line-height: 120%;
    }
`;

export const InnerContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        background-color: ${theme.colors.primary.primary1};
        border: 1px solid ${theme.colors.primary.primary5};
        border-radius: ${theme.borders.borderRadiusLarge}px;
        box-sizing: border-box;
    `}
`;
