import React, { useState } from 'react';
import { AppForm } from 'components/organisms';
import { useAuth } from 'services/firebase/useAuth';
import { ResetPasswordData } from 'types/forms/auth';
import { EmailInputElement, FormNavButtons } from 'components/molecules';
import { useLinkClickHandler } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { getFirebaseErrorLabel } from 'utils/handleFirebaseError/getFirebaseErrorLabel';
import { useStepsForm } from 'hooks/useStepsForm/useStepsForm';
import { authTexts } from 'consts/text/index';

export const PasswordResetForm = () => {
    const { finishForm } = useStepsForm();
    const [firebaseError, setFirebaseError] = useState('');
    const [isPasswordResetRequestLoading, setIsPasswordResetRequestLoading] = useState(false);
    const { passwordResetRequest } = useAuth();
    const navigateToSignIn = useLinkClickHandler(appPaths.auth['sign-in']);

    const onFinish = (values: ResetPasswordData) => {
        setIsPasswordResetRequestLoading(true);
        setFirebaseError('');
        passwordResetRequest(values)
            .then(() => finishForm())
            .catch((error) => setFirebaseError(getFirebaseErrorLabel(error)))
            .finally(() => setIsPasswordResetRequestLoading(false));
    };

    return (
        <AppForm<ResetPasswordData>
            name="passwordReset"
            maxWidth={440}
            title="Zresetuj hasło"
            subTitle="Na Twój adres e-mail zostanie wysłana wiadomość umożliwiająca Ci zmianę hasła."
            onFinish={onFinish}
            overLinkText="Pamiętasz swoje hasło?"
            linkButton={authTexts.loginButtonLabel}
            onLinkButtonClick={(e) =>
                navigateToSignIn(e as React.MouseEvent<HTMLAnchorElement, MouseEvent>)
            }
            error={firebaseError}
            isLoading={isPasswordResetRequestLoading}
        >
            <EmailInputElement />
            <FormNavButtons buttonText={authTexts.sendEmailLabel} />
        </AppForm>
    );
};
