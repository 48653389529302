import { AppFormItem, AppFormItemProps, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { settingsTexts } from 'consts/text';
import { SettlementConfiguration } from 'types';

type MealPaymentTypePickerElementProps = AppFormItemProps & {
    disabled?: boolean;
};

const items: { label: string; value: string }[] = [
    {
        label: settingsTexts.settlements.meals.formLabels.prePaid,
        value: SettlementConfiguration.PREPAID,
    },
    {
        label: settingsTexts.settlements.meals.formLabels.postPaid,
        value: SettlementConfiguration.POSTPAID,
    },
];

export const MealPaymentTypePickerElement = ({
    disabled,
    name = formFields.mealPaymentType,
    ...props
}: MealPaymentTypePickerElementProps) => {
    const radioElements = items.map(({ value, label }) => (
        <AppRadioButton key={value} value={value} disabled={disabled}>
            {label}
        </AppRadioButton>
    ));

    return (
        <AppFormItem
            label={settingsTexts.settlements.meals.formLabels.payments}
            name={name}
            disabled={disabled}
            {...props}
        >
            <AppRadioGroup disabled={disabled}>{radioElements}</AppRadioGroup>
        </AppFormItem>
    );
};
