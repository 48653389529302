import React, { useRef } from 'react';
import { useScrollToTop } from 'hooks/useScrollToTop/useScrollToTop';
import { ContentContainer, PageContainer } from './CentralIslandLayout.styled';

export type CentralIslandLayoutProps = {
    children: React.ReactNode;
    fillSpaceWithIsland?: boolean;
    stickToTop?: boolean;
};

export const CentralIslandLayout = ({
    children,
    fillSpaceWithIsland = false,
    stickToTop = false,
}: CentralIslandLayoutProps) => {
    const pageContainerRef = useRef<HTMLDivElement>(null);
    useScrollToTop({ elementRef: pageContainerRef });

    return (
        <PageContainer ref={pageContainerRef} stickToTop={stickToTop}>
            <ContentContainer fillSpaceWithIsland={fillSpaceWithIsland}>
                {children}
            </ContentContainer>
        </PageContainer>
    );
};
