import { generatePath, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SpecialTopicAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import {
    LessonId,
    SpecialTopicChildWork,
    SpecialTopicDTO,
    SpecialTopicFormFields,
    SpecialTopicId,
} from 'types';
import { ErrorDisplay } from 'components/molecules';
import { journalTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { parseSpecialTopicDTOIntoSpecialTopicFormFields } from 'utils/parsers/specialTopic/parseSpecialTopicDTOIntoSpecialTopicFormFields';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { getChildrenWorkDataForSpecialTopic } from 'utils/getChildrenWorkDataForSpecialTopic';
import { parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO } from 'utils/parsers/specialTopic/parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO';
import { useSpecialJournalTopicAddPageNetworkManage } from '../SpecialJournalTopicAddPage/hooks';

export const SpecialJournalTopicEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SpecialTopicFormFields>();
    const { journalId } = useJournalId();
    const [specialTopicLessonId, setSpecialTopicLessonId] = useState<LessonId>();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { topicId: specialTopicIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const topicId = (specialTopicIdParam || '') as SpecialTopicId;

    const {
        data: specialTopicData,
        error: specialTopicError,
        isFetching: isSpecialTopicDataFetching,
    } = useAppQuery(
        'SPECIAL_TOPIC',
        [journalId, topicId],
        () => StaffHTTPService.specialTopics.getSpecialTopic(journalId, topicId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { data: specialAttendanceData } = useAppQuery(
        'SPECIAL_ATTENDANCE',
        [journalId, specialTopicLessonId],
        () =>
            StaffHTTPService.specialAttendance.getAttendanceByLessonId(
                journalId,
                specialTopicLessonId,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS, enabled: !!specialTopicLessonId },
    );

    useEffect(() => {
        if (specialTopicData) {
            setSpecialTopicLessonId(specialTopicData.lesson.id);
        }
        if (specialAttendanceData && specialTopicData) {
            const { childrenAttendance } = specialAttendanceData;

            const { mergedChildrenWork, mergedNotInLessonChildrenWork } =
                getChildrenWorkDataForSpecialTopic({
                    childrenWork: specialTopicData.childrenWork,
                    notInLessonChildrenWork: specialTopicData.notInLessonChildrenWork,
                    childrenAttendance,
                });

            const newSpecialTopicData: Omit<
                SpecialTopicDTO,
                'childrenWork' | 'notInLessonChildrenWork'
            > & {
                childrenWork: SpecialTopicChildWork[];
                notInLessonChildrenWork: SpecialTopicChildWork[];
            } = {
                ...specialTopicData,
                childrenWork: mergedChildrenWork,
                notInLessonChildrenWork: mergedNotInLessonChildrenWork,
            };

            setFormData(parseSpecialTopicDTOIntoSpecialTopicFormFields(newSpecialTopicData));
        }
    }, [specialTopicData, setFormData, specialAttendanceData]);

    const navigateToTopicsPage = () => {
        statefulNavigate(
            generatePath(appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base, {
                journalId,
            }),
            {
                replace: true,
            },
        );
    };

    const onEditTopicSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.topics.topicEditSuccessNotification,
        );
        navigateToTopicsPage();
    };

    const {
        mutate: updateSpecialTopic,
        error: editSpecialTopicError,
        isLoading: isEditSpecialTopicLoading,
    } = useAppMutation(StaffHTTPService.specialTopics.editSpecialTopic, {
        key: ['UPDATE_SPECIAL_TOPIC'],
        onSuccess: onEditTopicSuccess,
        invalidateQueryKeys: [
            ['SPECIAL_TOPICS', journalId],
            ['SPECIAL_TOPIC', journalId, topicId],
        ],
    });

    const {
        specialLessonsData,
        isSpecialLessonsDataFetching,
        isSpecialLessonsDataError,
        handleSelectFormTopicDate,
    } = useSpecialJournalTopicAddPageNetworkManage({
        onAddTopicSuccess: () => {},
        journalId,
        initialTopicDate: specialTopicData?.date,
    });

    const onEditTopicFinish = (topicData: SpecialTopicFormFields) => {
        const parsedUpdateTopicDTO =
            parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO(topicData);
        updateSpecialTopic({
            journalId,
            specialTopicId: topicId,
            updateSpecialTopicDTO: parsedUpdateTopicDTO,
        });
    };

    if (!topicId) {
        navigateToTopicsPage();
    }

    if (specialTopicError) {
        return <ErrorDisplay />;
    }

    return (
        <SpecialTopicAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={editSpecialTopicError}
            isLoading={
                isEditSpecialTopicLoading ||
                isSpecialTopicDataFetching ||
                isSpecialLessonsDataFetching
            }
            onFinish={onEditTopicFinish}
            specialLessonsData={specialLessonsData}
            handleSelectFormTopicDate={handleSelectFormTopicDate}
            isSpecialLessonsDataFetching={isSpecialLessonsDataFetching}
            isSpecialLessonsDataError={isSpecialLessonsDataError}
        />
    );
};
