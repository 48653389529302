import React from 'react';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { StyledExpandIcon } from 'components/atoms';
import { ExpandButton } from './AppExpandIcon.styled';

export type AppExpandIconProps<RecordType> = Omit<
    RenderExpandIconProps<RecordType>,
    'expandable' | 'prefixCls'
>;

export const AppExpandIcon = <RecordType,>({
    expanded,
    onExpand,
    record,
}: AppExpandIconProps<RecordType>) => (
    <ExpandButton
        fullWidth
        ghost
        icon={<StyledExpandIcon size={20} expanded={expanded} />}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, event)}
        withoutBorderRadius
    />
);
