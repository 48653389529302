import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import {
    ChildId,
    CommonFormProps,
    FormModeDependedTexts,
    LessonDTO,
    LessonId,
    SpecialTopicChildWork,
} from 'types';
import { SpecialTopicFormFields } from 'types/forms';
import { commonTexts, journalTexts, timetableTexts } from 'consts/text';
import { AppForm, AppFormProps, UnassignedChildrenPicker } from 'components/organisms';
import {
    DatePickerElement,
    FormNavButtons,
    TopicHoursTimePicker,
    TopicNameInputElement,
    SimpleTextAreaElement,
    SimpleInputElement,
    SimpleCheckboxElement,
} from 'components/molecules';
import { AppFormItem, ConditionalRender } from 'components/atoms';
import { SpecialTopicPicker } from 'components/organisms/FormItems/TopicSpecialPicker/TopicSpecialPicker';
import { ChildrenWork } from 'components/organisms/FormItems/ChildrenWork/ChildrenWork';
import { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import useForm from 'antd/es/form/hooks/useForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { specialTopicAddEditErrorParser } from 'utils/errorHandlers/specialTopic/specialTopicAddEditErrorParser';
import { formFields } from 'consts/form/formFields';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { max5000LengthRule } from 'consts/rules/textRules';
import { useWatch } from 'antd/es/form/Form';
import { parseNotInLessonChildIntoSpecialTopicChildWork } from 'utils/parsers/specialTopic/parseNotInLessonChildIntoSpecialTopicChildWork';

const addModeTexts: FormModeDependedTexts = {
    name: 'specialTopicAdd',
    title: journalTexts.journalDetails.groupJournal.topics.topicAddFormTitle,
    buttonText: commonTexts.actionLabels.saveChanges,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'specialTopicEdit',
    title: journalTexts.journalDetails.groupJournal.topics.topicEditFormTitle,
    buttonText: commonTexts.actionLabels.saveChanges,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

type SpecialTopicAddEditFormProps = Omit<
    AppFormProps<SpecialTopicFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (specialTopicData: SpecialTopicFormFields) => void;
    } & {
        handleSelectFormTopicDate: (date: Dayjs | null) => void;
        specialLessonsData?: LessonDTO[];
        isSpecialLessonsDataError: boolean;
        isSpecialLessonsDataFetching: boolean;
        specialTopicToEditId?: LessonId;
    };

export const SpecialTopicAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    handleSelectFormTopicDate,
    specialLessonsData,
    isSpecialLessonsDataFetching,
    form,
    ...props
}: SpecialTopicAddEditFormProps) => {
    const [formInstance] = useForm<SpecialTopicFormFields>(form);
    const [selectedLessonId, setSelectedLessonId] = useState<LessonId | undefined>();
    const { journalId } = useJournalId();

    const withNotInLessonChildrenFormValue = useWatch(
        formFields.withNotInLessonChildren,
        formInstance,
    );
    const notInLessonChildrenFormValues = useWatch<ChildId[] | undefined>(
        formFields.notInLessonChildren,
        formInstance,
    );

    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const specialTopicAddEditError = axiosError ? specialTopicAddEditErrorParser(axiosError) : '';

    const { data: specialAttendanceData } = useAppQuery(
        'SPECIAL_ATTENDANCE',
        [journalId, selectedLessonId],
        () =>
            StaffHTTPService.specialAttendance.getAttendanceByLessonId(journalId, selectedLessonId),
        { staleTime: FIVE_MINS_IN_MILLIS, enabled: !!selectedLessonId },
    );

    const { data: unassignedChildrenData } = useAppQuery(
        'UNASSIGNED_CHILDREN',
        [selectedLessonId],
        () => StaffHTTPService.employeeSchedule.getUnassignedChildren(selectedLessonId as LessonId),
        {
            enabled: false,
        },
    );

    const childrenWork = useMemo(() => {
        const parsedSpecialAttendanceIntoChildrenWork: SpecialTopicChildWork[] | undefined =
            specialAttendanceData?.childrenAttendance?.map((childAttendance) => ({
                id: childAttendance.child.id,
                firstName: childAttendance.child.firstName,
                lastName: childAttendance.child.lastName,
                description: undefined,
                absentType: childAttendance.type,
            }));

        return mode === 'add'
            ? parsedSpecialAttendanceIntoChildrenWork
            : initialValues?.childrenWork;
    }, [initialValues?.childrenWork, mode, specialAttendanceData?.childrenAttendance]);

    const notInLessonChildrenWork = useMemo(() => {
        if (!isAddMode) {
            return initialValues?.notInLessonChildrenWork;
        }
        if (!unassignedChildrenData || !notInLessonChildrenFormValues) {
            return undefined;
        }
        const selectedNotInLessonChildren = unassignedChildrenData.filter((unassignedChild) =>
            notInLessonChildrenFormValues.includes(unassignedChild.id),
        );
        const parsedNotInLessonChildrenWork: SpecialTopicChildWork[] =
            selectedNotInLessonChildren.map(parseNotInLessonChildIntoSpecialTopicChildWork);
        return parsedNotInLessonChildrenWork;
    }, [
        initialValues?.notInLessonChildrenWork,
        isAddMode,
        notInLessonChildrenFormValues,
        unassignedChildrenData,
    ]);

    const onLessonChange = (lesson?: LessonDTO) => {
        if (!lesson) {
            setSelectedLessonId(undefined);
            formInstance.resetFields([
                'timeFrom',
                'timeTo',
                'lessonId',
                'teacherIds',
                formFields.notInLessonChildren,
                formFields.notInLessonChildrenWork,
            ]);
        }
        setSelectedLessonId(lesson?.id);
        formInstance.resetFields([
            formFields.notInLessonChildren,
            formFields.notInLessonChildrenWork,
        ]);
    };

    const onDateChange = (date: Dayjs | null) => {
        formInstance.resetFields([
            'timeFrom',
            'timeTo',
            'lessonId',
            'teacherIds',
            formFields.notInLessonChildren,
            formFields.notInLessonChildrenWork,
        ]);
        setSelectedLessonId(undefined);
        handleSelectFormTopicDate(date);
    };

    return (
        <AppForm<SpecialTopicFormFields>
            maxWidth={570}
            name={name}
            title={title}
            error={specialTopicAddEditError}
            withGoBack
            form={formInstance}
            {...props}
        >
            <TopicNameInputElement />
            <DatePickerElement isHalfParentWidth disabled={!isAddMode} onChange={onDateChange} />
            <SpecialTopicPicker
                specialLessonsData={specialLessonsData}
                isLoading={isSpecialLessonsDataFetching}
                disabled={!isAddMode}
                onChange={onLessonChange}
            />
            <AppFormItem justifyContent="space-between" doubleItem>
                <TopicHoursTimePicker mode="start" disabled />
                <TopicHoursTimePicker mode="finish" disabled />
            </AppFormItem>
            <SimpleTextAreaElement
                name="description"
                label={timetableTexts.lessons.lessonsDescription}
                rules={[max5000LengthRule]}
                rows={6}
            />
            <SimpleInputElement
                name={formFields.teacherFullName}
                label={commonTexts.dataLabels.teacher}
                hasOptionalText={false}
                disabled
                optional
            />
            <ConditionalRender condition={isAddMode}>
                <SimpleCheckboxElement
                    name={formFields.withNotInLessonChildren}
                    label={journalTexts.journalDetails.specialJournal.topic.selectChildOutOfLesson}
                    disabled={!isAddMode}
                    optional
                />
            </ConditionalRender>
            <ConditionalRender condition={isAddMode && !!withNotInLessonChildrenFormValue}>
                <UnassignedChildrenPicker lessonId={selectedLessonId} disabled={!isAddMode} />
            </ConditionalRender>
            <ChildrenWork childrenWork={childrenWork} />
            <ChildrenWork
                name={formFields.notInLessonChildrenWork}
                childrenWork={notInLessonChildrenWork}
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
            />
        </AppForm>
    );
};
