import { AddUpdateParentTeacherConferenceDTO, ParentTeacherConferenceFormFields } from 'types';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO: (
    parentTeacherConferenceFormFields: ParentTeacherConferenceFormFields,
) => AddUpdateParentTeacherConferenceDTO = ({ date, childrenWithParentsIds, ...restData }) => ({
    ...restData,
    date: parseDayjsDateIntoString(date, 'YYYY-MM-DD'),
    childrenWithParents: childrenWithParentsIds,
});
