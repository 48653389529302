import dayjs, { Dayjs } from 'dayjs';
import { FIRST_DAY_OF_MONTH, SCHOOL_YEAR_START_MONTH_INDEX } from 'consts/dateConsts';

export const isBeforeSchoolYear = (currentDate: Dayjs, schoolYearToCheck: number) =>
    currentDate.isBefore(
        dayjs()
            .date(FIRST_DAY_OF_MONTH)
            .month(SCHOOL_YEAR_START_MONTH_INDEX)
            .year(schoolYearToCheck),
        'date',
    );
