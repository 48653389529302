import { useLocation, useNavigate } from 'react-router-dom';
import type { NavigateOptions } from 'react-router/dist/lib/context';
import { useCallback } from 'react';

export const useNavigateWithState = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const statefulNavigate = useCallback(
        (pathname: string, options?: NavigateOptions) => {
            navigate(
                {
                    pathname,
                    search: location.search,
                },
                {
                    state: location.state,
                    ...options,
                },
            );
        },
        [navigate, location.search, location.state],
    );

    return { statefulNavigate };
};
