import { generatePath, useParams } from 'react-router-dom';
import { appPaths, appRoutes } from 'consts/paths/paths';
import { ChildId } from 'types';
import { ChildDetails } from 'components/templates';

export const ChildDetailsPage = () => {
    const { childId: childIdParam } = useParams();

    const childId = (childIdParam || '') as ChildId;
    const childEditPagePath = generatePath(appPaths.app.children.edit.pickedChild, { childId });

    return (
        <ChildDetails
            pageRoute={appRoutes.app.children.details.pickedChild.base}
            childEditPagePath={childEditPagePath}
        />
    );
};
