import dayjs from 'dayjs';
import { PersonData } from 'types';
import { parentTexts } from 'consts/text';
import { getFullName } from 'utils/getFullName';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const getPaymentTitle = (month: number, year: number, childData: PersonData) => {
    const childFullName = getFullName(childData.firstName, childData.lastName);
    const dateWithSelectedMonth = dayjs()
        .set('month', month - 1)
        .set('year', year);
    const parsedToMonthDate = parseDayjsDateIntoString(dateWithSelectedMonth, 'MM/YYYY');
    return `${parentTexts.settlement.monthlyBill} ${parsedToMonthDate} ${childFullName}`;
};
