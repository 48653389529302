import { AppButton } from 'components/atoms';
import styled from 'styled-components';

export const StyledBurgerMenuIcon = styled.img`
    max-height: 20px;
`;

export const StyledLinkButton = styled(AppButton)`
    padding: 8px 0;
    margin: 0;
`;
