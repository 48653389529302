import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        gap: ${theme.sizes.marginSmall}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            flex-direction: column-reverse;
            gap: ${theme.sizes.marginNormal}px;
        }
    `}
`;
