import { ChildWithParentsIds, ParentTeacherConferenceParentDTO } from 'types';

export const getChildrenWithParentsIdsFromParentTeacherConferenceParentDTOs = (
    parentTeacherConferenceParentDTOs: ParentTeacherConferenceParentDTO[],
) =>
    parentTeacherConferenceParentDTOs.reduce<ChildWithParentsIds[]>(
        (allChildrenWithParensIds, currentParentTeacherConferenceParent) => {
            const { id: parentId, childId } = currentParentTeacherConferenceParent;
            const foundGroup = allChildrenWithParensIds.find(
                (selectedChildrenWithParentsIdsItem) =>
                    selectedChildrenWithParentsIdsItem.childId === childId,
            );
            if (!foundGroup) {
                return [...allChildrenWithParensIds, { childId, parentIds: [parentId] }];
            }
            return allChildrenWithParensIds.map((selectedChildrenWithParentsIdsItem) => {
                if (selectedChildrenWithParentsIdsItem.childId !== foundGroup.childId) {
                    return selectedChildrenWithParentsIdsItem;
                }
                return {
                    childId: foundGroup.childId,
                    parentIds: [...foundGroup.parentIds, parentId],
                };
            });
        },
        [],
    );
