import { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const horizontalAppMenuStyles = css`
    ${({ theme }) => css`
        &.ant-menu.ant-menu-horizontal {
            padding: 6px;
            border-radius: 10px;
            border: 1px solid ${theme.colors.primary.primary5};

            .ant-menu-item {
                border-radius: 4px;

                &:hover {
                    background-color: ${theme.colors.primary.primary3};

                    &:after {
                        border-bottom: none;
                    }
                }

                &:after {
                    border-bottom: none;
                }

                &.ant-menu-item-selected {
                    background-color: ${theme.colors.primary.primary3};
                    &:after {
                        border-bottom: none;
                    }
                }
            }

            .ant-menu-title-content {
                ${getTextStyle('BodyMSSemiBold')}

                &:hover {
                }
            }

            .ant-menu-submenu-horizontal.ant-menu-overflow-item-rest {
                &:hover {
                    background-color: ${theme.colors.primary.primary3};
                }

                &:after {
                    border-bottom: none;
                }
            }

            .ant-menu-submenu-selected {
                border-radius: 4px;
                background-color: ${theme.colors.primary.primary3};
            }
        }

        .ant-menu-submenu {
            .ant-menu.ant-menu-sub {
                padding: 4px;

                .ant-menu-item {
                    display: flex;
                    align-items: center;
                    padding: 4px;

                    &:hover,
                    &.ant-menu-item-selected {
                        background-color: ${theme.colors.primary.primary3} !important;
                    }

                    .ant-menu-title-content {
                        ${getTextStyle('BodyMSSemiBold')}
                    }
                }
            }
        }
    `}
`;
