import { IconEdit, IconEye, IconFilePlus } from '@tabler/icons-react';
import { AppTable, AppTableProps } from 'components/organisms';
import { childrenTexts, commonTexts, groupsTexts, journalTexts } from 'consts/text';
import { Option } from 'components/atoms';
import { QualificationDTO } from 'types/qualifications';
import { qualificationFields } from 'consts/qualifications/qualificationFields';
import { getFullName } from 'utils/getFullName';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { ChildSexDisplay, ContractValidDisplay, EmployeeGroupsDisplay } from 'components/molecules';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useJournalAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useSpecialJournalQualificationsPageNetworkManage } from './hooks/useSpecialJournalQualificationsPageNetworkManage';

const columns: AppTableProps<QualificationDTO>['columns'] = [
    {
        title: childrenTexts.childrenListColumns.firstAndLastName,
        dataIndex: qualificationFields.firstName,
        key: qualificationFields.firstName,
        render: (_, child) =>
            getFullName(child[qualificationFields.firstName], child[qualificationFields.lastName]),
    },

    {
        title: childrenTexts.childDetails.personalData.sex,
        dataIndex: qualificationFields.sex,
        key: qualificationFields.sex,
        render: (sex) => <ChildSexDisplay sex={sex} />,
        align: 'center',
    },
    {
        title: groupsTexts.groups,
        dataIndex: qualificationFields.groups,
        key: qualificationFields.groups,
        render: (groups) => <EmployeeGroupsDisplay groups={groups} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childDetails.personalData.pesel,
        dataIndex: qualificationFields.pesel,
        key: qualificationFields.pesel,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.contact,
        dataIndex: qualificationFields.contact,
        key: qualificationFields.contact,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.attachment,
        dataIndex: qualificationFields.hasAttachments,
        key: qualificationFields.hasAttachments,
        render: (hasAttachments) => (
            <ContractValidDisplay contractValid={hasAttachments} dataTestId="attachments" />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.filled,
        dataIndex: qualificationFields.filled,
        key: qualificationFields.filled,
        render: (filled) => <ContractValidDisplay contractValid={filled} dataTestId="dataLabel" />,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const SpecialJournalQualificationPage = () => {
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const {
        specialJournalsAbilities: { checkIfCanManageQualificationBasis },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const canManageQualificationBasis = checkIfCanManageQualificationBasis();

    const isQualificationFilled = (qualification: QualificationDTO) => qualification.filled;

    const navigateToQualificationDetails = (qualification: QualificationDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.details
                    .pickedQualification,
                {
                    qualificationId: qualification.id,
                    journalId,
                },
            ),
        );

    const navigateToQualificationAdd = (qualification: QualificationDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.add,
                {
                    journalId,
                },
            ),
            {
                state: {
                    childId: qualification.childId,
                },
            },
        );

    const navigateToQualificationEdit = (qualification: QualificationDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.edit
                    .pickedQualification,
                {
                    journalId,
                    qualificationId: qualification.id,
                },
            ),
            {
                state: {
                    childId: qualification.childId,
                },
            },
        );

    const options: Option<QualificationDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToQualificationDetails,
            Icon: <IconEye />,
            checkIfVisible: (qualification) => isQualificationFilled(qualification),
        },
        {
            label: journalTexts.journalDetails.specialJournal.qualifications.addQualification,
            onClick: navigateToQualificationAdd,
            Icon: <IconFilePlus />,
            checkIfVisible: (qualification) =>
                canManageQualificationBasis && !isQualificationFilled(qualification),
        },
        {
            label: journalTexts.journalDetails.specialJournal.qualifications.editQualification,
            onClick: navigateToQualificationEdit,
            Icon: <IconEdit />,
            checkIfVisible: (qualification) =>
                canManageQualificationBasis && isQualificationFilled(qualification),
        },
    ];

    const {
        qualificationsData,
        isQualificationsDataInitialLoading,
        isQualificationsDataError,
        isQualificationsDataRefetching,
    } = useSpecialJournalQualificationsPageNetworkManage({ journalId });

    return (
        <AppTable
            title={commonTexts.dataLabels.childList}
            emptyInfo={
                journalTexts.journalDetails.specialJournal.qualifications.emptyQualificationList
            }
            options={options}
            columns={columns}
            dataSource={qualificationsData}
            rowKey={({ childId }) => childId}
            isOptionsLoading={isQualificationsDataInitialLoading}
            isError={isQualificationsDataError}
            isDataInitialLoading={isQualificationsDataInitialLoading}
            isDataRefetching={isQualificationsDataRefetching}
            isTableManageUtilsHidden
        />
    );
};
