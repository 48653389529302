import { commonTexts, settingsTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';
import { axiosErrors } from 'consts/errors/axiosErrors';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';

export const paymentErrorParser: (error: unknown, defaultErrorMessage?: string) => string = (
    error,
    defaultErrorMessage = commonTexts.errorMessages.unknown,
) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }

    if (code === apiErrorCodes.BILL_ALREADY_PAID) {
        return settingsTexts.payments.errors.alreadyPaid;
    }

    return defaultErrorMessage;
};
