import { formatIncompletePhoneNumber } from 'libphonenumber-js/min';
import { AppInput, AppInputProps } from '../AppInput/AppInput';

export type AppPhoneInputProps = AppInputProps & {
    onChange?: (phoneNumber: string) => void;
};

export const AppPhoneInput = ({ onChange, ...props }: AppPhoneInputProps) => {
    const phoneInputChanged = (phone: string) => {
        onChange?.(formatIncompletePhoneNumber(phone, 'PL'));
    };

    return <AppInput onChange={(event) => phoneInputChanged(event.target.value)} {...props} />;
};
