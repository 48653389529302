import { ChildWorkDTO, SpecialTopicChildWork } from 'types';
import { ChildDailySpecialAttendanceDTO } from 'types/specialAttendance/specialAttendance';
import { mergeChildrenWorkForSpecialTopic } from 'utils/mergeChldrenWorkForSpecialTopic';

export const getChildrenWorkDataForSpecialTopic = ({
    childrenWork,
    notInLessonChildrenWork,
    childrenAttendance,
}: {
    childrenWork: ChildWorkDTO[];
    notInLessonChildrenWork: ChildWorkDTO[];
    childrenAttendance: ChildDailySpecialAttendanceDTO[];
}): {
    mergedChildrenWork: SpecialTopicChildWork[];
    mergedNotInLessonChildrenWork: SpecialTopicChildWork[];
} => {
    const notInLessonChildIds = notInLessonChildrenWork.map((childWork) => childWork.child.id);

    const childrenWorkAttendance = childrenAttendance.filter(
        (attendance) => !notInLessonChildIds.includes(attendance.child.id),
    );
    const notInLessonChildrenWorkAttendance = childrenAttendance.filter((attendance) =>
        notInLessonChildIds.includes(attendance.child.id),
    );

    const mergedChildrenWork: SpecialTopicChildWork[] = mergeChildrenWorkForSpecialTopic({
        childrenWork,
        childrenAttendance: childrenWorkAttendance,
    });
    const mergedNotInLessonChildrenWork: SpecialTopicChildWork[] = mergeChildrenWorkForSpecialTopic(
        {
            childrenWork: notInLessonChildrenWork,
            childrenAttendance: notInLessonChildrenWorkAttendance,
        },
    );

    return { mergedChildrenWork, mergedNotInLessonChildrenWork };
};
