import { ChildSettlementsIcon } from 'assets';
import { childrenTexts } from 'consts/text';
import { ChildSettlementDetails } from 'types';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { DetailsColumn, DetailsRow, DetailsFrameBase } from 'components/atoms';

export type SettlementsProps = Pick<
    ChildSettlementDetails,
    'stayStart' | 'stayEnd' | 'periodStart' | 'periodEnd'
>;

export const Settlements = ({ stayStart, stayEnd, periodStart, periodEnd }: SettlementsProps) => {
    const periodStartParsed = periodStart
        ? parseStringIntoFormattedStringDate(periodStart)
        : undefined;
    const periodEndParsed = periodEnd ? parseStringIntoFormattedStringDate(periodEnd) : undefined;
    const stayStartParsed = stayStart ? parseStringIntoFormattedStringTime(stayStart) : undefined;
    const stayEndParsed = stayEnd ? parseStringIntoFormattedStringTime(stayEnd) : undefined;

    return (
        <DetailsFrameBase
            icon={<ChildSettlementsIcon />}
            title={childrenTexts.childDetails.settlementData.sectionTitle}
        >
            <DetailsRow>
                <DetailsColumn
                    title={childrenTexts.childDetails.settlementData.settlementPeriodStart}
                    span={8}
                >
                    {periodStartParsed}
                </DetailsColumn>
                <DetailsColumn
                    title={childrenTexts.childDetails.settlementData.settlementPeriodEnd}
                    span={8}
                >
                    {periodEndParsed}
                </DetailsColumn>
                <DetailsColumn
                    title={childrenTexts.childDetails.settlementData.declaredStayHours}
                    span={8}
                >{`${stayStartParsed}-${stayEndParsed}`}</DetailsColumn>
            </DetailsRow>
        </DetailsFrameBase>
    );
};
