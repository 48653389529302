import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { useAppAbility } from '../useAppAbility';

export const useDietAbilities = () => {
    const ability = useAppAbility();

    const dietAbilities = {
        checkIfCanViewDietConfig: () => ability.can(AppActions.VIEW, PERMISSIONS_NAME.DIET_CONFIG),
        checkIfCanManageDietConfig: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.DIET_CONFIG),
    };

    return { dietAbilities };
};
