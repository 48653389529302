export const mobileThreshold = 800;
const tabletThreshold = 1150;

const sideBarMaxWidth = 280;
export const sizes = {
    mediaQueries: {
        mobileThreshold,
        mobileMax: `(max-width: ${mobileThreshold}px)`,
        desktopMin: `(min-width: ${mobileThreshold + 1}px)`,
        tabletMax: `(max-width: ${tabletThreshold}px)`,
    },
    auth: {
        authUpperNavBarHeight: 80,
        authUpperNavBarHeightMobile: 60,
    },
    sideMenu: {
        sideBarWidth: sideBarMaxWidth,
        sideBarCollapsedWidth: 96,
        autoCollapseWidth: sideBarMaxWidth * 5,
        mobileUpperNavBarHeight: 48,
    },
    marginSmall: 8,
    marginNormal: 16,
    marginLarge: 32,
    modalWidth: {
        small: 300,
        medium: 400,
        large: 500,
    },
} as const;
