import { TreeSelectProps } from 'antd';
import { GlobalStyleTreeSelect, StyledTreeSelect } from './AppTreeSelect.styles';

type AppTreeSelectProps = TreeSelectProps;

export const AppTreeSelect = (props: AppTreeSelectProps) => (
    <>
        <GlobalStyleTreeSelect />
        <StyledTreeSelect {...props} />
    </>
);
