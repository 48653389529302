import { JournalId, JournalType } from 'types';
import { useJournalAbilities } from 'abilities';
import { getJournalDetailsPathname } from 'utils/paths/getJournalDetailsPathname';
import { LinkDisplay } from '../LinkDisplay/LinkDisplay';

type JournalNameDisplayProps = {
    id: JournalId;
    name: string;
    type: JournalType;
};

export const JournalNameDisplay = ({ id, name, type }: JournalNameDisplayProps) => {
    const {
        specialJournalsAbilities: { checkIfCanViewJournals: checkIfCanViewSpecialJournals },
        groupJournalsAbilities: { checkIfCanViewJournals: checkIfCanViewGroupJournals },
    } = useJournalAbilities();

    const canViewSpecialJournals = checkIfCanViewSpecialJournals();
    const canViewGroupJournals = checkIfCanViewGroupJournals();

    const displayAsLink =
        (type === 'GROUP' && canViewGroupJournals) ||
        (type === 'SPECIAL' && canViewSpecialJournals);

    return displayAsLink ? (
        <LinkDisplay to={getJournalDetailsPathname(id, type)}>{name}</LinkDisplay>
    ) : (
        name
    );
};
