import { DietsFormFields, AddUpdateDietDTO } from 'types';

export const parseDietsFormFieldsIntoAddUpdateDietDTO = (
    dietData: DietsFormFields,
): AddUpdateDietDTO => {
    const { name, breakfastPrice, dinnerPrice, soupPrice, snackPrice } = dietData;

    return {
        name,
        breakfastPrice: breakfastPrice ?? null,
        dinnerPrice: dinnerPrice ?? null,
        soupPrice: soupPrice ?? null,
        snackPrice: snackPrice ?? null,
    };
};
