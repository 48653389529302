import { useQueryClient } from '@tanstack/react-query';
import { auth } from 'services/firebase/firebase';
import {
    confirmPasswordReset,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
} from 'firebase/auth';
import { ChangePasswordData, ResetPasswordData, SignInData } from 'types/forms/auth';
import { useAtom, useSetAtom } from 'jotai';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { authStateAtom } from 'jotaiAtoms/authState';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    currentInstitutionAtom,
    defaultCurrentInstitutionData,
} from 'jotaiAtoms/currentInstitution';
import { removeLocalStorageInstitutionId } from 'services/localStorage/institutionId';
import { commonTexts } from 'consts/text';
import { removeLocalStorageChildId } from 'services/localStorage/childId';
import { currentChildAtom, defaultCurrentChildData } from 'jotaiAtoms/currentChild';

export const useAuth = () => {
    const [userDetails, setUserDetails] = useAtom(userDetailsAtom);
    const setLoggedState = useSetAtom(authStateAtom);
    const setCurrentInstitution = useSetAtom(currentInstitutionAtom);
    const setCurrentChild = useSetAtom(currentChildAtom);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const userType = userDetails?.type;

    const signIn = async (data: SignInData) =>
        signInWithEmailAndPassword(auth, data.email, data.password);

    const passwordResetRequest = async (data: ResetPasswordData) =>
        sendPasswordResetEmail(auth, data.email);

    const passwordChange = async (oobCode: string, data: ChangePasswordData) =>
        confirmPasswordReset(auth, oobCode, data.password);

    const validateCredential = async (oldPassword: string) => {
        const user = auth.currentUser;
        if (!user?.email) {
            throw new Error(commonTexts.errorMessages.userNotAuthenticated);
        }
        const credential = EmailAuthProvider.credential(user.email, oldPassword);
        return reauthenticateWithCredential(user, credential);
    };

    const passwordUpdate = async (newPassword: string) => {
        const user = auth.currentUser;
        if (!user) {
            throw new Error(commonTexts.errorMessages.userNotAuthenticated);
        }
        return updatePassword(user, newPassword);
    };

    const clearUserDetailsAndRedirectToSignIn = () => {
        setLoggedState('unauthorized');
        setUserDetails(null);
        if (userType === 'OWNER' || userType === 'EMPLOYEE') {
            setCurrentInstitution(defaultCurrentInstitutionData);
            removeLocalStorageInstitutionId();
        }
        if (userType === 'PARENT') {
            setCurrentChild(defaultCurrentChildData);
            removeLocalStorageChildId();
            removeLocalStorageInstitutionId();
        }
        queryClient.clear();
        navigate(appPaths.auth['sign-in']);
    };
    const signUserOut = () =>
        signOut(auth)
            .then(clearUserDetailsAndRedirectToSignIn)
            .catch(clearUserDetailsAndRedirectToSignIn);

    return {
        signIn,
        passwordResetRequest,
        passwordChange,
        signUserOut,
        validateCredential,
        passwordUpdate,
    };
};
