import { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { commonTexts } from 'consts/text';
import { AbsenceDTO, AbsenceId, LessonDTO } from 'types';
import {
    AbsenceDetailsModal,
    AbsenceRemoveModal,
    LessonDetailsModal,
    LessonList,
    LessonRemoveModals,
    TimetableScheduleCard,
} from 'components/organisms';
import { AbsenceListItem } from 'components/organisms/LessonList/components/AbsenceListItem';
import { TimetableScheduleData } from 'components/templates/timetable/TimetableSchedule/TimetableSchedule.types';

type TimetableScheduleProps = {
    timetableSchedule: TimetableScheduleData;
    editable?: boolean;
    noPreview?: boolean;
};

type Day = keyof typeof commonTexts.dayLabels;

const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        border-radius: ${theme.borders.borderRadiusLarge}px;
        border: 1px solid ${theme.colors.primary.primary5};
        @media (${theme.sizes.mediaQueries.tabletMax}) {
            flex-direction: column;
            width: 100%;
        }
    `}
`;

export const TimetableSchedule = ({
    timetableSchedule,
    editable,
    noPreview,
}: TimetableScheduleProps) => {
    const [previewedLesson, setPreviewedLesson] = useState<LessonDTO | undefined>();
    const [previewedAbsenceId, setPreviewedAbsenceId] = useState<AbsenceId | undefined>();

    const [lessonDetailsModalOpen, setLessonDetailsModalOpen] = useState(false);
    const [absenceDetailsModalOpen, setAbsenceDetailsModalOpen] = useState(false);

    const [removeLessonProcessInProgress, setRemoveLessonProcessInProgress] = useState(false);
    const [removeAbsenceProcessInProgress, setRemoveAbsenceProcessInProgress] = useState(false);

    const onLessonClick = useCallback(
        (lesson: LessonDTO) => {
            if (noPreview) {
                return;
            }
            setPreviewedLesson(lesson);
            setLessonDetailsModalOpen(true);
        },
        [noPreview],
    );

    const onAbsenceClick = useCallback(
        (absence: AbsenceDTO) => {
            if (noPreview) {
                return;
            }
            setPreviewedAbsenceId(absence.id);
            setAbsenceDetailsModalOpen(true);
        },
        [noPreview],
    );

    const onCancelLessonPreview = () => {
        setLessonDetailsModalOpen(false);
    };

    const onCancelAbsencePreview = () => {
        setAbsenceDetailsModalOpen(false);
    };

    const onLessonRemoveClick = () => {
        setRemoveLessonProcessInProgress(true);
    };

    const onAbsenceRemoveClick = () => {
        setRemoveAbsenceProcessInProgress(true);
    };

    const onCancelLessonRemove = () => {
        setRemoveLessonProcessInProgress(false);
    };

    const onCancelAbsenceRemove = () => {
        setRemoveAbsenceProcessInProgress(false);
    };

    const onFinishLessonRemove = () => {
        setRemoveLessonProcessInProgress(false);
        setLessonDetailsModalOpen(false);
    };

    const onFinishAbsenceRemove = () => {
        setRemoveAbsenceProcessInProgress(false);
        setAbsenceDetailsModalOpen(false);
    };

    const scheduleCards = useMemo(
        () =>
            Object.entries(timetableSchedule).map(([day, dayLessonsSchedule]) => {
                const hasAbsences =
                    dayLessonsSchedule.absences && dayLessonsSchedule.absences.length > 0;

                return (
                    <TimetableScheduleCard
                        key={day}
                        dayLabel={commonTexts.dayLabels[day as Day]}
                        date={dayLessonsSchedule.date}
                        shouldDisplayEmptyLessonElement={!dayLessonsSchedule.lessons.length}
                    >
                        {hasAbsences &&
                            dayLessonsSchedule.absences!.map((absence) => (
                                <AbsenceListItem
                                    absence={absence}
                                    onAbsenceClick={onAbsenceClick}
                                    noPreview={noPreview}
                                />
                            ))}
                        {dayLessonsSchedule.lessons.length ? (
                            <LessonList
                                lessons={dayLessonsSchedule.lessons}
                                onLessonClick={onLessonClick}
                                noPreview={noPreview}
                            />
                        ) : null}
                    </TimetableScheduleCard>
                );
            }),
        [timetableSchedule, onAbsenceClick, onLessonClick, noPreview],
    );

    const previewedAbsence = useMemo(() => {
        const absencesListById = Object.entries(timetableSchedule).map(
            ([_, dayLessonsSchedule]) => {
                const findAbsenceById = dayLessonsSchedule.absences?.find(
                    (absence) => absence.id === previewedAbsenceId,
                );

                if (findAbsenceById) {
                    return findAbsenceById;
                }
                return undefined;
            },
        );
        return absencesListById.filter(Boolean)[0];
    }, [previewedAbsenceId, timetableSchedule]);

    return (
        <>
            {!noPreview && previewedLesson && (
                <>
                    <LessonDetailsModal
                        open={lessonDetailsModalOpen}
                        lesson={previewedLesson}
                        editable={editable && previewedLesson.editable}
                        onCancel={onCancelLessonPreview}
                        onRemove={onLessonRemoveClick}
                    />
                    <LessonRemoveModals
                        inProgress={removeLessonProcessInProgress}
                        lesson={previewedLesson}
                        onCancel={onCancelLessonRemove}
                        onFinish={onFinishLessonRemove}
                    />
                </>
            )}
            {!noPreview && previewedAbsence && previewedAbsenceId && (
                <>
                    <AbsenceDetailsModal
                        open={absenceDetailsModalOpen}
                        absence={previewedAbsence}
                        onCancel={onCancelAbsencePreview}
                        onRemove={onAbsenceRemoveClick}
                        editable
                    />
                    <AbsenceRemoveModal
                        open={removeAbsenceProcessInProgress}
                        absenceId={previewedAbsenceId}
                        onCancel={onCancelAbsenceRemove}
                        onFinish={onFinishAbsenceRemove}
                    />
                </>
            )}
            <Container>{scheduleCards}</Container>
        </>
    );
};
