import { ParentTeacherConferenceDTO, ParentTeacherConferenceFormFields } from 'types';
import { getChildrenWithParentsIdsFromParentTeacherConferenceParentDTOs } from 'utils/getChildrenWithParentsIdsFromParentTeacherConferenceParentDTOs';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseParentTeacherConferenceDTOIntoParentTeacherConferenceFormFields: (
    parentTeacherConferenceDTO: ParentTeacherConferenceDTO,
) => ParentTeacherConferenceFormFields = ({ date, parents, subject, description }) => {
    const childrenWithParentsIds =
        getChildrenWithParentsIdsFromParentTeacherConferenceParentDTOs(parents);
    return {
        subject,
        date: parseStringIntoDayjsDate(date),
        description,
        childrenWithParentsIds,
    };
};
