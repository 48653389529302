import { useState } from 'react';
import { useTheme } from 'styled-components';
import { TooltipProps } from 'antd';
import { IconTrash } from '@tabler/icons-react';
import {
    AppOptionsButton,
    AppOptionButton,
    AppOptionIconContainer,
    MailThreadReceiversNames,
} from 'components/atoms';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { commonTexts, messagesTexts } from 'consts/text';
import { MailThreadDTO } from 'types';
import { getMailThreadMembersNames } from 'utils/getMailThreadMembersNames';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import {
    Container,
    DataContainer,
    StyledAppGoBackButton,
    StyledAppTitle,
} from './MessagePanelTopBar.styled';

export type MessagePanelTopBarProps = {
    mailThread: MailThreadDTO;
    onOpenRemoveModal?: () => void;
};

export const MessagePanelTopBar = ({ mailThread, onOpenRemoveModal }: MessagePanelTopBarProps) => {
    const [isVisibleOptionsButtonPopover, setIsVisibleOptionsButtonPopover] = useState(false);
    const { id: userId } = useUserDetails();
    const theme = useTheme();
    const isMobile = useIsMobile();

    const { members, topic, authorUserId, remoteLessonThread } = mailThread;

    const ellipsisTooltipConfig: Omit<TooltipProps, 'title'> = {
        placement: 'bottomRight',
        color: theme.colors.tooltip.tooltip2,
    };
    const topicText = remoteLessonThread
        ? `${messagesTexts.remoteLessonTopicPrefixWithSemicolon} ${topic}`
        : topic;
    const isUserAuthor = authorUserId === userId;
    const isVisibleOptionsButton = onOpenRemoveModal && !remoteLessonThread && isUserAuthor;

    const hideOptionsButtonPopover = () => setIsVisibleOptionsButtonPopover(false);

    const handleDotsOptionsButtonClick = () =>
        setIsVisibleOptionsButtonPopover(
            (prevIsVisibleOptionsButtonPopover) => !prevIsVisibleOptionsButtonPopover,
        );

    const optionButtons = (
        <AppOptionButton
            ghost
            icon={<AppOptionIconContainer>{<IconTrash />}</AppOptionIconContainer>}
            fullWidth
            onClick={onOpenRemoveModal}
            size="middle"
        >
            {commonTexts.actionLabels.delete}
        </AppOptionButton>
    );

    return (
        <Container>
            {isMobile && <StyledAppGoBackButton size="small" margin={0} hasText={false} />}
            <DataContainer>
                <StyledAppTitle
                    level={5}
                    ellipsis={{
                        tooltip: {
                            ...ellipsisTooltipConfig,
                            title: topicText,
                        },
                    }}
                    margin={0}
                >
                    {topicText}
                </StyledAppTitle>
                <MailThreadReceiversNames
                    members={members}
                    authorUserId={authorUserId}
                    ellipsis={{
                        tooltip: {
                            ...ellipsisTooltipConfig,
                            title: getMailThreadMembersNames(members, true),
                            overlayStyle: { maxWidth: '600px' },
                            overlayInnerStyle: {
                                whiteSpace: 'pre-wrap',
                            },
                            placement: isMobile ? 'bottom' : 'bottomRight',
                        },
                    }}
                    subnameTextProps={{
                        color: theme.colors.grayscale.gray9,
                    }}
                />
            </DataContainer>
            {isVisibleOptionsButton && (
                <AppOptionsButton
                    optionButtons={optionButtons}
                    isVisiblePopover={isVisibleOptionsButtonPopover}
                    onPopoverHide={hideOptionsButtonPopover}
                    onDotsButtonClick={handleDotsOptionsButtonClick}
                    openOptionsButtonSize="BIG"
                    placement="bottomLeft"
                />
            )}
        </Container>
    );
};
