import { GroupDayAttendanceDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { dayAttendanceTypesLabels } from 'consts/attendance/dayAttendanceTypesLabels';

export const parseGroupDayAttendanceDTOIntoJsonObject: (
    groupDayAttendanceDTO: GroupDayAttendanceDTO,
) => object = (groupDayAttendanceDTO) => {
    const { type, entryTime, exitTime } = groupDayAttendanceDTO;

    const formattedEntryTime = entryTime
        ? parseStringIntoFormattedStringTime(entryTime)
        : EMPTY_VALUE_LABEL;
    const formattedExitTime = exitTime
        ? parseStringIntoFormattedStringTime(exitTime)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.groupDayAttendanceDTO.fields.type]: dayAttendanceTypesLabels[type],
        [jsonObjectsTexts.groupDayAttendanceDTO.fields.entryTime]: formattedEntryTime,
        [jsonObjectsTexts.groupDayAttendanceDTO.fields.exitTime]: formattedExitTime,
    };
};
