import { isAxiosError } from 'axios';
import { commonTexts } from 'consts/text';
import { axiosErrors } from 'consts/errors/axiosErrors';

export const fileUploadErrorParser = (error: unknown) => {
    const isRequestTimeout =
        isAxiosError(error) && error.code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE;

    if (isRequestTimeout) {
        return commonTexts.errorMessages.fileRequestTimeout;
    }

    return commonTexts.errorMessages.fileUploading;
};
