import { css } from 'styled-components';

export type TextType =
    | 'BodyLargeSemiBold'
    | 'BodyLarge'
    | 'BodyMediumSemiBold'
    | 'BodyMedium'
    | 'BodyMSRegular'
    | 'BodyMSSemiBold'
    | 'BodySmallSemiBold'
    | 'BodySmallBold'
    | 'BodySmall'
    | 'Caption'
    | 'MobileMenu'
    | 'MobileMenu2'
    | 'H3'
    | 'H3Regular'
    | 'H4'
    | 'H5';

export const getTextStyle = (textType: TextType) => {
    switch (textType) {
        case 'BodyLargeSemiBold':
            return css`
                font-weight: 700;
                font-size: 18px;
                line-height: 120%;
                letter-spacing: -0.03em;
            `;
        case 'BodyLarge':
            return css`
                font-weight: 400;
                font-size: 18px;
            `;
        case 'BodyMediumSemiBold':
            return css`
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: -0.03em;
            `;
        case 'BodyMedium':
            return css`
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            `;
        case 'BodyMSRegular':
            return css`
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            `;
        case 'BodyMSSemiBold':
            return css`
                font-size: 14px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.42px;
            `;
        case 'BodySmallSemiBold':
            return css`
                font-weight: 700;
                font-size: 12px;
            `;
        case 'BodySmallBold':
            return css`
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            `;
        case 'BodySmall':
            return css`
                font-weight: 400;
                font-size: 12px;
            `;
        case 'Caption':
            return css`
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
            `;
        case 'MobileMenu':
            return css`
                font-weight: 500;
                font-size: 10px;
                line-height: 17px;
            `;
        case 'MobileMenu2':
            return css`
                font-weight: 400;
                font-size: 10px;
                line-height: 17px;
            `;
        case 'H4':
            return css`
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
            `;
        case 'H5':
            return css`
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.6px;
            `;
        case 'H3':
            return css`
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.84px;
            `;
        case 'H3Regular':
            return css`
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.84px;
            `;
        default:
            return css``;
    }
};
