import { CalendarComponentEvent, CalendarEventDetailedDto } from 'types';
import { EventTypeMapper } from 'consts/calendar/calendarTypeColors';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';

const CALENDAR_COMPONENT_EVENT_END_DATE_OFFSET = 1;

export const parseCalendarEventDetailedDtoIntoCalendarComponentEvent: (
    calendarEventDetailedDto: CalendarEventDetailedDto,
) => CalendarComponentEvent = (calendarEventDetailedDto) => {
    const { id, name, type, startDate, endDate } = calendarEventDetailedDto;
    const parsedEndDate = parseStringIntoDayjsDate(endDate);
    const calendarComponentEndDate = parsedEndDate.add(
        CALENDAR_COMPONENT_EVENT_END_DATE_OFFSET,
        'day',
    );
    const end = parseDayjsDateIntoString(calendarComponentEndDate, 'YYYY-MM-DD');

    return {
        id,
        title: name,
        start: startDate,
        end,
        textColor: EventTypeMapper[type].color,
        backgroundColor: EventTypeMapper[type].backgroundColor,
        borderColor: EventTypeMapper[type].backgroundColor,
    };
};
