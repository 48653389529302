import { generatePath, Navigate, useParams } from 'react-router-dom';
import { Row } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { CalendarEventType, EventId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { AppModal } from 'components/molecules';
import { Details, RemoveModal } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { calendarTexts, commonTexts } from 'consts/text';
import { AppModalButton, AppText } from 'components/atoms';
import { eventTypeLabels } from 'consts/calendar/calendarTypeLabels';
import { AppCalendarContext } from 'contexts/AppCalendarContext';
import { useCalendarAbilities } from 'abilities';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { colors } from 'theme/styledComponents/colors';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const CalendarDetailsPage = () => {
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess, displayError } = useSimpleNotification();

    const { eventId } = useParams<{ eventId: string }>();

    const [isRemoveModalOpen, setRemoveModalOpen] = useState<boolean>(false);

    const { calendarAbilities } = useCalendarAbilities();
    const canManageEvents = calendarAbilities.checkIfCanManageEvents();

    const { selectedDate } = useContext(AppCalendarContext);
    const date = parseStringIntoDayjsDate(selectedDate as string);
    const selectedYear = date.year();
    const selectedMonth = getMonthFromDayjsMonth(date.month());

    const { data: eventsMonthlyData, isFetched: isEventsMonthlyDataFetched } = useAppQuery(
        'MONTHLY_EVENTS',
        [selectedMonth, selectedYear],
        () =>
            StaffHTTPService.calendar.getMonthlyEvents({
                month: selectedMonth,
                year: selectedYear,
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const navigateToCalendarBase = () => statefulNavigate(appPaths.app.calendar.base);

    const onRemoveEventSuccess = () => {
        displaySuccess(calendarTexts.successfullyRemoveEventMessage);
        navigateToCalendarBase();
    };

    const { mutate: deleteEvent, isLoading: isDeleteEventLoading } = useAppMutation(
        StaffHTTPService.calendar.removeEvent,
        {
            onSuccess: onRemoveEventSuccess,
            onError: () => displayError(commonTexts.errorMessages.actionExecution),
            key: ['REMOVE_EVENT'],
            invalidateQueryKeys: [['MONTHLY_EVENTS'], ['DAILY_EVENTS']],
        },
    );

    const event = useMemo(
        () =>
            eventsMonthlyData ? eventsMonthlyData.find((eventEl) => eventEl.id === eventId) : null,
        [eventId, eventsMonthlyData],
    );

    if (isEventsMonthlyDataFetched && !event) {
        return <Navigate to={appPaths.error['not-found']} />;
    }

    const navigateToEventEdit = () =>
        statefulNavigate(
            generatePath(appPaths.app.calendar.edit.pickedEvent, {
                eventId,
            }),
        );

    const footer = (
        <>
            <AppModalButton
                type="text"
                icon={<IconTrash />}
                onClick={() => setRemoveModalOpen(true)}
                disabled={!canManageEvents && !event?.canModify}
            >
                {commonTexts.actionLabels.delete}
            </AppModalButton>
            <AppModalButton type="primary" icon={<IconEdit />} onClick={navigateToEventEdit}>
                {commonTexts.actionLabels.edit}
            </AppModalButton>
        </>
    );

    return (
        <>
            <RemoveModal
                open={isRemoveModalOpen}
                title={calendarTexts.details.removeModalTitle}
                confirmActionLoading={isDeleteEventLoading}
                onOk={() => deleteEvent(eventId as EventId)}
                onCancel={() => setRemoveModalOpen(false)}
            />
            <AppModal
                open
                onCancel={navigateToCalendarBase}
                footer={canManageEvents && event?.canModify ? footer : null}
            >
                <Details isVisibleGoBackButton={false} title={calendarTexts.details.title}>
                    <Row>
                        <Details.Item title={calendarTexts.details.type}>
                            {eventTypeLabels[event?.type as CalendarEventType]}
                        </Details.Item>
                        <Details.Item title={calendarTexts.details.freeFromSchool}>
                            {event?.freeFromSchool
                                ? commonTexts.actionLabels.confirm
                                : commonTexts.actionLabels.reject}
                        </Details.Item>
                    </Row>
                    <Details.Item title={calendarTexts.details.name}>{event?.name}</Details.Item>
                    <Row>
                        <Details.Item title={calendarTexts.details.startDate}>
                            {event?.startDate &&
                                parseStringIntoFormattedStringDate(event?.startDate)}
                        </Details.Item>
                        <Details.Item title={calendarTexts.details.endDate}>
                            {event?.endDate && parseStringIntoFormattedStringDate(event?.endDate)}
                        </Details.Item>
                    </Row>
                    <Details.Item title={calendarTexts.details.description}>
                        {event?.description}
                    </Details.Item>
                    {event?.journalName && (
                        <AppText
                            margin={0}
                            textType="BodyMSRegular"
                            color={colors.grayscale.gray8}
                        >{`Dodane do: ${event.journalName}`}</AppText>
                    )}
                </Details>
            </AppModal>
        </>
    );
};
