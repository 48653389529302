import { AxiosError } from 'axios';
import {
    MutationFunction,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { handleNetworkError } from 'utils/handleNetworkError';
import {
    commonIdsIndependentQueryKeys,
    institutionIdIndependentQueryKeys,
} from 'services/reactQuery/idParamIndependentQueryKeys';
import { MutationMainKeysType } from './mutationMainKeysType';
import { QueryMainKeysType } from './queryMainKeysType';

type MutationKeyType = (MutationMainKeysType | string | undefined)[];
export type MutationInvalidateQueryKeysType = (
    | QueryMainKeysType
    | string
    | number
    | undefined
    | null
)[];
type UseMutationOptionsEx = {
    invalidateQueryKeys?: MutationInvalidateQueryKeysType[];
    key: MutationKeyType;
};

type MutationOptions<TData, TVariables, TContext> = UseMutationOptionsEx &
    UseMutationOptions<TData, AxiosError, TVariables, TContext>;

export const useAppMutation = <TData = unknown, TVariables = void, TContext = unknown>(
    func: MutationFunction<TData, TVariables>,
    options?: MutationOptions<TData, TVariables, TContext>,
): UseMutationResult<TData, AxiosError, TVariables, TContext> => {
    const { id: currentInstitutionId } = useCurrentInstitution();
    const { id: childId, institutionAddress: childInstitutionAddress } = useCurrentChild();
    const queryClient = useQueryClient();

    const wrapFunc: MutationFunction<TData, TVariables> = (props) => func(props);

    return useMutation(wrapFunc, {
        ...options,
        onError: (error, variables, context) => {
            handleNetworkError(error);

            if (options) {
                const { onError } = options;

                if (onError) {
                    onError(error, variables, context);
                }
            }
        },
        onSuccess: (response, variables, context) => {
            if (options) {
                const { onSuccess, invalidateQueryKeys: mutationInvalidateQueryKeys } = options;

                const invalidateQueryKeys: MutationInvalidateQueryKeysType[] =
                    mutationInvalidateQueryKeys
                        ? [['HISTORY_OF_CHANGES'], ...mutationInvalidateQueryKeys]
                        : [['HISTORY_OF_CHANGES']];

                if (invalidateQueryKeys?.length) {
                    invalidateQueryKeys?.forEach((keysToInvalidate) => {
                        if (currentInstitutionId) {
                            const isInstitutionIdIndependentInvalidateQuery =
                                keysToInvalidate.includes(
                                    institutionIdIndependentQueryKeys.GUS_INSTITUTION,
                                ) ||
                                keysToInvalidate.includes(
                                    commonIdsIndependentQueryKeys.USER_ACTIVE,
                                );
                            if (!isInstitutionIdIndependentInvalidateQuery) {
                                keysToInvalidate.unshift(currentInstitutionId);
                            }
                        }
                        if (childId) {
                            const isIdsIndependentInvalidateQuery = keysToInvalidate.includes(
                                commonIdsIndependentQueryKeys.USER_ACTIVE,
                            );
                            if (!isIdsIndependentInvalidateQuery) {
                                keysToInvalidate.unshift(childId);
                                keysToInvalidate.unshift(childInstitutionAddress.institutionId);
                            }
                        }
                        queryClient.invalidateQueries({
                            queryKey: keysToInvalidate,
                            refetchType: 'all',
                        });
                    });
                }
                if (onSuccess) {
                    onSuccess(response, variables, context);
                }
            }
        },
        retry: options?.retry,
    });
};
