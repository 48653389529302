import axios, { InternalAxiosRequestConfig } from 'axios';
import { getAuth } from 'firebase/auth';
import { URLS } from 'consts/api/urls';

const BASE_URL = URLS[import.meta.env.VITE_APP_ENV];
const TIMEOUT = 900000;

class AxiosBaseInstance {
    private axios = axios.create({ baseURL: BASE_URL, timeout: TIMEOUT });

    private isUserAccountVerified: boolean | undefined = undefined;

    constructor() {
        // @ts-ignore
        this.axios.interceptors.request.use(this.getConfigWithAuthorization, Promise.reject);
    }

    getConfigWithAuthorization = async (config: InternalAxiosRequestConfig) => {
        const headers = { ...config.headers } || {};
        try {
            const auth = getAuth();
            const emailVerifiedStatusHasBeenChanged =
                this.isUserAccountVerified !== auth?.currentUser?.emailVerified &&
                !this.isUserAccountVerified !== undefined;
            const idTokenJwt = await auth.currentUser?.getIdToken(
                emailVerifiedStatusHasBeenChanged,
            );
            if (idTokenJwt) {
                headers.Authorization = `Bearer ${idTokenJwt}`;
            }
            this.isUserAccountVerified = auth?.currentUser?.emailVerified;
        } catch (error) {
            return config;
        }
        return { ...config, headers };
    };

    public get: typeof axios.get = (...params) => this.axios.get(...params);

    public post: typeof axios.post = (...params) => this.axios.post(...params);

    public put: typeof axios.put = (...params) => this.axios.put(...params);

    public delete: typeof axios.delete = (...params) => this.axios.delete(...params);

    public patch: typeof axios.patch = (...params) => this.axios.patch(...params);
}

export default AxiosBaseInstance;
