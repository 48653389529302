import { AppTitle } from 'components/atoms/CommonAppComponents';
import { AddButton, AddButtonProps } from 'components/atoms';
import { Row } from 'antd';
import {
    Container,
    ContainerProps,
    LeftSideContainer,
    StyledListIcon,
} from './AppTableAndCardListHeader.styled';

export type AppTableAndCardListHeaderProps = {
    title?: string;
    isHiddenAddButton?: boolean;
    additionalRightElement?: React.ReactNode;
    additionalLeftElement?: React.ReactNode;
} & AddButtonProps &
    ContainerProps;

export const AppTableAndCardListHeader = ({
    title = '',
    addButtonLabel,
    onAddButtonClick,
    mode,
    isHiddenAddButton,
    additionalRightElement,
    additionalLeftElement,
}: AppTableAndCardListHeaderProps) => (
    <Container mode={mode}>
        <LeftSideContainer>
            <StyledListIcon />
            <AppTitle level={4} margin={0}>
                {title}
            </AppTitle>
            {additionalLeftElement}
        </LeftSideContainer>
        {(!isHiddenAddButton || !!additionalRightElement) && (
            <Row>
                {additionalRightElement}
                {!isHiddenAddButton && (
                    <AddButton
                        addButtonLabel={addButtonLabel}
                        onAddButtonClick={onAddButtonClick}
                    />
                )}
            </Row>
        )}
    </Container>
);
