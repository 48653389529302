import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTheme } from 'styled-components';
import { IconX } from '@tabler/icons-react';
import { AppButton, AppPopover } from 'components/atoms';
import { messagesTexts } from 'consts/text';
import { MessageDTO, MessageReceiverDTO } from 'types';
import { GroupMailThreadMessageReadStatusListItem } from 'components/molecules/List/GroupMailThreadMessageReadStatusListItem/GroupMailThreadMessageReadStatusListItem';
import { AppModal } from 'components/molecules/AppModal/AppModal';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import {
    DetailsReadStatusCount,
    PopoverTitleCloseIconContainer,
    PreviewReadStatusCount,
    StyledAppList,
} from './GroupMailThreadMessageReadStatus.styled';

type GroupMailThreadMessageReadStatusProps = Pick<
    MessageDTO,
    'receivers' | 'readByCount' | 'receiversCount'
>;

export const GroupMailThreadMessageReadStatus = ({
    receivers,
    readByCount,
    receiversCount,
}: GroupMailThreadMessageReadStatusProps) => {
    const [isVisibleDetails, setIsVisibleDetails] = useState(false);
    const theme = useTheme();
    const isMobile = useIsMobile();

    const hideDetails = () => setIsVisibleDetails(false);

    const handleReadStatusClick = () =>
        setIsVisibleDetails((prevIsVisiblePopover) => !prevIsVisiblePopover);

    const readStatusCountText = `${messagesTexts.read} ${readByCount}/${receiversCount}`;

    const popoverTitle = (
        <>
            <PopoverTitleCloseIconContainer>
                <AppButton type="text" size="small" margin={0} icon={<IconX size={20} />} />
            </PopoverTitleCloseIconContainer>
            <DetailsReadStatusCount
                readStatusCountText={readStatusCountText}
                textType="BodyMediumSemiBold"
            />
        </>
    );
    const receiversReadStatusContent = (
        <StyledAppList<MessageReceiverDTO>
            dataSource={receivers}
            renderItem={(receiver) => (
                <GroupMailThreadMessageReadStatusListItem key={receiver.userId} {...receiver} />
            )}
        />
    );

    if (isMobile) {
        return (
            <>
                <AppModal open={isVisibleDetails} onCancel={hideDetails}>
                    <DetailsReadStatusCount
                        readStatusCountText={readStatusCountText}
                        textType="BodyLargeSemiBold"
                    />
                    {receiversReadStatusContent}
                </AppModal>
                <PreviewReadStatusCount
                    readStatusCountText={readStatusCountText}
                    color={theme.colors.primary.primary9}
                    onClick={handleReadStatusClick}
                />
            </>
        );
    }

    return (
        <OutsideClickHandler onOutsideClick={hideDetails}>
            <AppPopover
                open={isVisibleDetails}
                placement="bottomLeft"
                arrow={false}
                title={popoverTitle}
                content={receiversReadStatusContent}
            >
                <PreviewReadStatusCount
                    readStatusCountText={readStatusCountText}
                    color={theme.colors.primary.primary9}
                    onClick={handleReadStatusClick}
                />
            </AppPopover>
        </OutsideClickHandler>
    );
};
