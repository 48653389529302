import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { settingsTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const rolesAndPermissionsDeleteErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.DELETE_ASSIGNED_ROLE) {
        return settingsTexts.rolesAndPermissions.role.deleteAssignedRoleErrorMessage;
    }

    return settingsTexts.rolesAndPermissions.role.deleteError;
};
