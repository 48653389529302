import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { WeeklyScheduleAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { WeeklyScheduleFormFields } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { journalTexts } from 'consts/text';
import { isWeeklyScheduleEmpty } from 'utils/isWeeklyScheduleEmpty';
import { parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO } from 'utils/parsers/schedule/parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalWeeklyScheduleAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const {
        data: weeklyScheduleData,
        isFetching: isWeeklyScheduleDataFetching,
        isError: isWeeklyScheduleDataError,
    } = useAppQuery(
        'WEEKLY_SCHEDULE',
        [journalId],
        () => StaffHTTPService.schedules.weeklySchedule.getWeeklySchedule(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (!weeklyScheduleData || isWeeklyScheduleEmpty(weeklyScheduleData)) {
            return;
        }
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.edit,
                {
                    journalId,
                },
            ),
            { replace: true },
        );
    }, [weeklyScheduleData, journalId, statefulNavigate]);

    const onAddWeeklyScheduleSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.schedules.weeklySchedule
                .successfullyAddedWeeklySchedule,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addWeeklySchedule,
        error: addWeeklyScheduleError,
        isLoading: isAddWeeklyScheduleLoading,
    } = useAppMutation(StaffHTTPService.schedules.weeklySchedule.updateWeeklySchedule, {
        key: ['UPDATE_WEEKLY_SCHEDULE'],
        onSuccess: onAddWeeklyScheduleSuccess,
        invalidateQueryKeys: [['WEEKLY_SCHEDULE', journalId]],
    });

    const onAddWeeklyScheduleFinish = (weeklyScheduleFormData: WeeklyScheduleFormFields) => {
        const parsedAddWeeklyScheduleDTO =
            parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO(weeklyScheduleFormData);
        addWeeklySchedule({ journalId, addUpdateWeeklyScheduleDTO: parsedAddWeeklyScheduleDTO });
    };

    if (isWeeklyScheduleDataError) {
        return <ErrorDisplay />;
    }

    return (
        <WeeklyScheduleAddEditForm
            mode="add"
            axiosError={addWeeklyScheduleError}
            isLoading={isWeeklyScheduleDataFetching || isAddWeeklyScheduleLoading}
            onFinish={onAddWeeklyScheduleFinish}
            institutionWeeklySchedule={weeklyScheduleData}
        />
    );
};
