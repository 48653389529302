import { MouseEventHandler } from 'react';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { AppButton, AppFormItem, AppParagraph } from 'components/atoms';

export type FormFooterProps = {
    linkButton?: string;
    onLinkButtonClick?: MouseEventHandler;
    overLinkText?: string;
};
export const FormFooter = ({ overLinkText, linkButton, onLinkButtonClick }: FormFooterProps) => {
    const isMobile = useIsMobile();

    return (
        <>
            {overLinkText && (
                <AppParagraph textAlign="center" margin={0} textType="BodyMedium">
                    {overLinkText}
                </AppParagraph>
            )}
            {linkButton && (
                <AppFormItem margin={0} justifyContent="center">
                    <AppButton
                        onClick={onLinkButtonClick}
                        margin={0}
                        fullWidth={isMobile}
                        type="link"
                        name="test"
                    >
                        {linkButton}
                    </AppButton>
                </AppFormItem>
            )}
        </>
    );
};
