import { InvoiceDTO } from 'types';
import { InvoiceEditFormFields } from 'types/forms/invoice';

export const parseInvoiceDTOIntoInvoiceEditFormFields = (
    invoice: InvoiceDTO,
): InvoiceEditFormFields => {
    const { name, nip, street, zipCode, city } = invoice;
    return {
        name: name || '',
        nip: nip || '',
        street: street || '',
        zipCode: zipCode || '',
        city: city || '',
    };
};
