import { axiosErrors } from 'consts/errors/axiosErrors';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, journalTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const specialTopicAddEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (code === apiErrorCodes.LESSON_ALREADY_HAS_TOPIC) {
        return journalTexts.journalDetails.specialJournal.topic.lessonAlreadyHasTopic;
    }

    return commonTexts.errorMessages.unknown;
};
