import { AxiosResponse } from 'axios';
import { useTheme } from 'styled-components';
import { IconDownload } from '@tabler/icons-react';
import { AttachmentDTO } from 'types';
import { AppButton, AppList, AppText } from 'components/atoms';
import { commonTexts, messagesTexts } from 'consts/text';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import {
    AttachmentListItem,
    AttachmentListItemProps,
} from 'components/molecules/List/AttachmentListItem/AttachmentListItem';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { fileDownload } from 'utils/fileDownload';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useState } from 'react';
import { Container, HeaderContainer } from './MessageAttachments.styled';

export type MessageAttachmentsProps = Pick<AttachmentListItemProps, 'fetchAttachmentFunction'> & {
    attachments: AttachmentDTO[];
    fetchAllAttachmentsInZipFunction?: (files: string[]) => Promise<AxiosResponse<string>>;
};

export const MessageAttachments = ({
    attachments,
    fetchAttachmentFunction,
    fetchAllAttachmentsInZipFunction = StaffHTTPService.files.getFilesInZip,
}: MessageAttachmentsProps) => {
    const [isDownloadingAttachments, setIsDownloadingAttachments] = useState(false);
    const { sizes } = useTheme();
    const isMobile = useIsMobile();
    const { displayError } = useSimpleNotification();

    const isVisibleDownloadAllButton = attachments.length > 1;

    const downloadAllAttachments = () => {
        setIsDownloadingAttachments(true);
        const mappedAttachmentDTO = attachments.map((attachment) => attachment.fileUri);

        fetchAllAttachmentsInZipFunction(mappedAttachmentDTO)
            .then((response) => {
                const binaryData = [];
                binaryData.push(response.data);
                const blob = new Blob(binaryData, { type: 'application/zip' });
                const href = URL.createObjectURL(blob);

                fileDownload(href, `attachments.zip`);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                displayError(messagesTexts.downloadAttachmentError);
                console.error(error);
            })
            .finally(() => setIsDownloadingAttachments(false));
    };
    return (
        <Container>
            <HeaderContainer>
                <AppText
                    textType="BodyMediumSemiBold"
                    margin={0}
                    marginBottom={!isVisibleDownloadAllButton ? sizes.marginSmall : undefined}
                >
                    {commonTexts.dataLabels.attachments}
                </AppText>
                {isVisibleDownloadAllButton && (
                    <AppButton
                        size="small"
                        margin={0}
                        icon={<IconDownload size={20} />}
                        onClick={downloadAllAttachments}
                        loading={isDownloadingAttachments}
                    >
                        {!isMobile && commonTexts.actionLabels.downloadAll}
                    </AppButton>
                )}
            </HeaderContainer>
            <AppList
                dataSource={attachments}
                renderItem={(fileData) => (
                    <AttachmentListItem
                        key={fileData.fileUri}
                        fileData={fileData}
                        fetchAttachmentFunction={fetchAttachmentFunction}
                    />
                )}
            />
        </Container>
    );
};
