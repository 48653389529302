import { dayAttendanceDTOFields } from 'consts/attendance/dayAttendanceDTOFields';
import { childDailyAttendanceDTOFields } from 'consts/attendance/childDailyAttendanceDTOFields';
import { AppBodyCell, AppCellProps, MobileExpandableRenderCellContent } from 'components/atoms';
import { GroupChildDailyAttendanceDTO } from 'types';
import {
    DailyAttendanceTimePickerElement,
    DailyAttendanceTimePickerElementProps,
} from 'components/molecules';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

type DailyAttendanceEditableCellProps = AppCellProps &
    Pick<DailyAttendanceTimePickerElementProps, 'handleSave' | 'selectedDate' | 'disabled'> & {
        cellKey?: string;
        childDailyAttendance?: GroupChildDailyAttendanceDTO;
        editable?: boolean;
    };

export const DailyAttendanceEditableCell = ({
    cellKey,
    children,
    childDailyAttendance,
    selectedDate,
    editable,
    disabled,
    handleSave,
    ...restProps
}: DailyAttendanceEditableCellProps) => {
    const isMobile = useIsMobile();

    const dayAttendance = childDailyAttendance?.[childDailyAttendanceDTOFields.attendance];
    const isChildAbsent =
        dayAttendance?.[dayAttendanceDTOFields.type] === 'ABSENT' ||
        dayAttendance?.[dayAttendanceDTOFields.type] === 'ABSENT_PLANNED' ||
        dayAttendance?.[dayAttendanceDTOFields.type] === 'REMOTE';
    const isUnsupportedKey =
        cellKey !== dayAttendanceDTOFields.entryTime && cellKey !== dayAttendanceDTOFields.exitTime;

    if (editable && !isChildAbsent && !isUnsupportedKey) {
        const cellContent = (
            <DailyAttendanceTimePickerElement
                cellKey={cellKey}
                childDailyAttendance={childDailyAttendance}
                selectedDate={selectedDate}
                disabled={disabled}
                handleSave={handleSave}
            />
        );

        if (isMobile) {
            return (
                <MobileExpandableRenderCellContent>{cellContent}</MobileExpandableRenderCellContent>
            );
        }

        return <AppBodyCell {...restProps}>{cellContent}</AppBodyCell>;
    }

    if (isMobile) {
        return <MobileExpandableRenderCellContent>{children}</MobileExpandableRenderCellContent>;
    }

    return <AppBodyCell {...restProps}>{children}</AppBodyCell>;
};
