import { AbsenceFormFields } from 'types';
import { AbsenceAddEditModal } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { timetableTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import useForm from 'antd/es/form/hooks/useForm';
import { parseAbsenceFormFieldsIntoCreateAbsenceDTO } from 'utils/parsers/absence/parseAbsenceFormFieldsIntoCreateAbsenceDTO';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const AbsenceAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const [form] = useForm();

    const onAddAbsenceSuccess = () => {
        displaySuccess(timetableTexts.absence.notyficationMessages.successfullyAddedAbsenceMessage);
        statefulNavigate(appPaths.app.timetable.employeeSchedule.base, {
            replace: true,
        });
    };
    const {
        mutate: addAbsence,
        error: addAbsenceError,
        isLoading: isAddAbsenceLoading,
    } = useAppMutation(StaffHTTPService.employeeSchedule.addAbsence, {
        key: ['ADD_ABSENCE'],
        onSuccess: onAddAbsenceSuccess,
        invalidateQueryKeys: [['EMPLOYEE_SCHEDULE'], ['WORKING_TIME']],
    });

    const onAddAbsenceFinish = (absenceData: AbsenceFormFields) =>
        addAbsence(parseAbsenceFormFieldsIntoCreateAbsenceDTO(absenceData));

    return (
        <AbsenceAddEditModal
            onFinish={onAddAbsenceFinish}
            mode="add"
            axiosError={addAbsenceError}
            isLoading={isAddAbsenceLoading}
            form={form}
        />
    );
};
