import styled, { useTheme } from 'styled-components';
import { IconCheck, IconX } from '@tabler/icons-react';
import { AppList, MessageMemberName } from 'components/atoms';
import { MessageReceiverDTO } from 'types';

type GroupMailThreadMessageReadStatusListItemProps = MessageReceiverDTO;

const StyledListItem = styled(AppList.Item)`
    &.ant-list-item {
        padding: 7px 12px;
        display: flex;
        justify-content: space-between;
    }
`;

export const GroupMailThreadMessageReadStatusListItem = ({
    name,
    subName,
    read,
}: GroupMailThreadMessageReadStatusListItemProps) => {
    const theme = useTheme();

    return (
        <StyledListItem>
            <MessageMemberName
                authorName={name}
                authorSubname={subName}
                subnameTextProps={{
                    color: theme.colors.grayscale.gray9,
                }}
            />
            {read ? (
                <IconCheck size={20} color={theme.colors.green.green6} />
            ) : (
                <IconX size={20} color={theme.colors.red.red6} />
            )}
        </StyledListItem>
    );
};
