import { Details } from 'components/organisms';
import { commonTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import AuthHTTPService from 'services/HTTPService/auth/AuthHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

export const OwnerAndEmployeeAccountDetailsPage = () => {
    const { id: institutionId } = useCurrentInstitution();

    const { data: userDetails, isFetching: isUserDetailsFetching } = useAppQuery(
        'USER_DETAILS',
        [institutionId],
        () => AuthHTTPService.authentication.getUser(institutionId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title={commonTexts.otherLabels.yourData}
            isLoading={isUserDetailsFetching}
            editPagePath={appPaths.app.account.details.edit}
            isVisibleGoBackButton={false}
        >
            <Details.Item title={commonTexts.dataLabels.firstName}>
                {userDetails?.firstName}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.lastName}>
                {userDetails?.lastName}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.email}>{userDetails?.email}</Details.Item>
            <Details.Item title={commonTexts.dataLabels.phoneNumber}>
                {userDetails?.phoneNumber
                    ? parsePhoneNumberIntoFormattedPhoneNumber(userDetails.phoneNumber)
                    : EMPTY_VALUE_LABEL}
            </Details.Item>
        </Details>
    );
};
