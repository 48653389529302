import { AppBodyCell, AppCellProps, MobileExpandableRenderCellContent } from 'components/atoms';
import { WorkingTimeTeacherDTO } from 'types';
import { WorkingTimePickerElement, WorkingTimePickerElementProps } from 'components/molecules';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { workingTimeDTOFields } from 'consts/workingTime/workingTime';

type WorkingTimeEditableCellProps = AppCellProps &
    Pick<WorkingTimePickerElementProps, 'handleSave' | 'selectedDate' | 'disabled'> & {
        cellKey?: string;
        teacherWorkingTime?: WorkingTimeTeacherDTO;
        editable?: boolean;
    };

export const WorkingTimeEditableCell = ({
    cellKey,
    children,
    teacherWorkingTime,
    selectedDate,
    editable,
    disabled,
    handleSave,
    ...restProps
}: WorkingTimeEditableCellProps) => {
    const isMobile = useIsMobile();

    const isTeacherAbsent = !!teacherWorkingTime?.absent;

    const isUnsupportedKey =
        cellKey !== workingTimeDTOFields.timeFrom && cellKey !== workingTimeDTOFields.timeTo;

    if (editable && !isTeacherAbsent && !isUnsupportedKey) {
        const cellContent = (
            <WorkingTimePickerElement
                cellKey={cellKey}
                teacherWorkingTime={teacherWorkingTime}
                selectedDate={selectedDate}
                disabled={disabled}
                handleSave={handleSave}
            />
        );

        if (isMobile) {
            return (
                <MobileExpandableRenderCellContent>{cellContent}</MobileExpandableRenderCellContent>
            );
        }

        return <AppBodyCell {...restProps}>{cellContent}</AppBodyCell>;
    }

    if (isMobile) {
        return <MobileExpandableRenderCellContent>{children}</MobileExpandableRenderCellContent>;
    }

    return <AppBodyCell {...restProps}>{children}</AppBodyCell>;
};
