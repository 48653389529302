import { AppFormItem, AppRadioButton, AppRadioGroup, AppText } from 'components/atoms';
import {
    socialNetworksFormFields,
    SocialNetworksFormFieldsValues,
} from 'consts/socialNetworks/formFields';
import { socialMediaAgreement } from 'consts/socialNetworks/labels';
import { socialNetworks } from 'consts/socialNetworks/socialNetworks';
import { socialNetworksLabels } from 'consts/socialNetworks/socialNetworksLabels';
import { commonTexts } from 'consts/text';
import styled, { css } from 'styled-components';
import { AgreementValueStringified } from 'types';

const socialNetworkOptions: {
    label: string;
    fieldName: SocialNetworksFormFieldsValues;
}[] = Object.values(socialNetworks).map((socialNetwork) => ({
    label: socialNetworksLabels[socialNetwork],
    fieldName: socialNetworksFormFields[socialNetwork],
}));

// string values are used because antd casts it to string under the hood when submitting form
const optionValues: { label: string; value: AgreementValueStringified }[] = [
    {
        label: commonTexts.actionLabels.reject,
        value: 'false',
    },
    {
        label: commonTexts.actionLabels.confirm,
        value: 'true',
    },
    {
        label: commonTexts.otherLabels.notApplicable,
        value: 'null',
    },
];

const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 445px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            flex-direction: column;
        }
    `}
`;

export const SocialNetworksImageSharingAgreementPicker = () => {
    const defaultRadioValue: AgreementValueStringified = 'null';

    const radioValues = optionValues.map(({ value, label }) => (
        <AppRadioButton key={label} value={value}>
            {label}
        </AppRadioButton>
    ));

    const radioOptions = socialNetworkOptions.map(({ label, fieldName }) => (
        <Container key={fieldName}>
            <AppText>{label}</AppText>
            <AppFormItem optional name={fieldName} hasOptionalText>
                <AppRadioGroup
                    defaultValue={defaultRadioValue}
                    fullWidth={false}
                    sameSizeOptionButtons={false}
                >
                    {radioValues}
                </AppRadioGroup>
            </AppFormItem>
        </Container>
    ));

    return (
        <>
            <AppText textType="BodyMSSemiBold" margin={0} marginBottom={24}>
                {socialMediaAgreement}
            </AppText>
            {radioOptions}
        </>
    );
};
