import { GusInstitutionDetails } from 'types/institution/gus';
import { InstitutionAddEditFormFields } from 'types/forms/institution';
import { formFields } from 'consts/form/formFields';

export const parseInstitutionGusDataIntoFormData: (
    gusData: GusInstitutionDetails,
) => Partial<InstitutionAddEditFormFields> = (gusData) => ({
    [formFields.institutionName]: gusData.name,
    [formFields.city]: gusData.mailCity,
    [formFields.zipCode]: gusData.zipCode,
    [formFields.regonNumber]: gusData.regon,
    [formFields.rspoNumber]: gusData.rspo,
    [formFields.street]: `${gusData.street} ${gusData.buildingNumber}${
        gusData.premisesNumber ? `/${gusData.premisesNumber}` : ''
    }`,
});
