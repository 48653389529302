import styled, { css } from 'styled-components';
import { AppList } from 'components/atoms';
import { LessonDTO } from 'types';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { TimetableInfo } from './components';

type LessonListItemProps = {
    lesson: LessonDTO;
    onLessonClick: (lesson: LessonDTO) => void;
    noPreview?: boolean;
};

const StyledListItem = styled(AppList.Item)<{ noPreview?: boolean }>`
    ${({ theme, noPreview }) => css`
        height: 44px;
        cursor: ${noPreview ? 'default' : 'pointer'};

        &.ant-list-item {
            padding: 8px 12px;
            border: 1px solid ${theme.colors.primary.primary9};
            border-radius: ${theme.borders.borderRadiusExtraSmall}px;
            background-color: ${theme.colors.primary.primary3};
            gap: ${theme.sizes.marginSmall}px;

            &:last-child {
                border-block-end: 1px solid ${theme.colors.primary.primary9};
            }

            @media (${theme.sizes.mediaQueries.mobileMax}) {
                flex-wrap: nowrap;
            }
        }
    `}
`;

export const LessonListItem = ({ lesson, onLessonClick, noPreview }: LessonListItemProps) => {
    const { name, timeFrom } = lesson;

    return (
        <StyledListItem onClick={() => onLessonClick(lesson)} noPreview={noPreview}>
            <TimetableInfo title={name} isShrinked isSupportedEllipsis>
                {name}
            </TimetableInfo>
            <TimetableInfo>{parseStringIntoFormattedStringTime(timeFrom)}</TimetableInfo>
        </StyledListItem>
    );
};
