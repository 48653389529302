import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { JournalDetailsLayout } from 'components/layouts';
import {
    GroupJournalDailyAttendancePage,
    GroupJournalTopicsPage,
    GroupJournalPsychologicalHelpsPage,
    GroupJournalTripsPage,
    JournalChildrenPage,
    GroupJournalConsultationsPage,
    JournalInspectionsPage,
    GroupJournalImportantEventsPage,
    GroupJournalParentTeacherConferencesPage,
    GroupJournalMonthlyAttendancePage,
    GroupJournalDailySchedulePage,
    GroupJournalWeeklySchedulePage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import {
    JournalNavigate,
    NavigateToDefaultJournalPage,
} from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalDetails: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.base,
        element: <Navigate to={appPaths.app.journals.base} />,
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW_MODULE,
                    subject: STAFF_APP_MODULES.GROUP_JOURNALS,
                }}
            >
                <JournalDetailsLayout journalType="GROUP" />
            </AbilityRoute>
        ),
        children: [
            { index: true, element: <NavigateToDefaultJournalPage journalType="GROUP" /> },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.base,
                element: (
                    <JournalNavigate
                        to={
                            appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.daily
                                .base
                        }
                    />
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.daily.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_ATTENDANCE,
                        }}
                    >
                        <GroupJournalDailyAttendancePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.monthly
                    .base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_ATTENDANCE,
                        }}
                    >
                        <GroupJournalMonthlyAttendancePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.base,
                element: (
                    <JournalNavigate
                        to={
                            appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily
                                .base
                        }
                    />
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE,
                        }}
                    >
                        <GroupJournalDailySchedulePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE,
                        }}
                    >
                        <GroupJournalWeeklySchedulePage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.base,
                element: (
                    <JournalNavigate
                        to={
                            appPaths.app.journals.groupJournalDetails.pickedJournal.events
                                .importantEvents.base
                        }
                    />
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents
                    .base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                        }}
                    >
                        <GroupJournalImportantEventsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                        }}
                    >
                        <GroupJournalTripsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.events
                    .parentTeacherConferences.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                        }}
                    >
                        <GroupJournalParentTeacherConferencesPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_CHILDREN_LIST_VIEW,
                        }}
                    >
                        <JournalChildrenPage journalType="GROUP" />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.topics.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_TOPICS,
                        }}
                    >
                        <GroupJournalTopicsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_INSPECTIONS,
                        }}
                    >
                        <JournalInspectionsPage journalType="GROUP" />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.consultations.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_CONSULTATIONS,
                        }}
                    >
                        <GroupJournalConsultationsPage />
                    </AbilityRoute>
                ),
            },

            {
                path: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps
                    .base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.GROUP_JOURNALS_PSYCHOLOGICAL_HELP,
                        }}
                    >
                        <GroupJournalPsychologicalHelpsPage />
                    </AbilityRoute>
                ),
            },
        ],
    },
];
