import { useTheme } from 'styled-components';
import { AppText, MessageMemberName } from 'components/atoms';
import { MessageReadStatus } from 'components/molecules/MessageReadStatus/MessageReadStatus';
import {
    MessageAttachments,
    MessageAttachmentsProps,
} from 'components/molecules/MessageAttachments/MessageAttachments';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import {
    AttachmentDTO,
    MailThreadUserDTO,
    MessageReceiverDTO,
    MessageRemoteLessonContentDTO,
} from 'types';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useMessagesAbilities } from 'abilities';
import { MessageTextContent } from './components';
import { MessageInfoContainer, StyledAppListItem } from './MessageListItem.styled';

export type MessageListItemProps = Pick<
    MessageAttachmentsProps,
    'fetchAttachmentFunction' | 'fetchAllAttachmentsInZipFunction'
> & {
    messageAuthorDto: MailThreadUserDTO;
    content?: string;
    remoteLessonContent?: MessageRemoteLessonContentDTO;
    attachments: AttachmentDTO[];
    sentAt: string;
    receivers?: MessageReceiverDTO[];
    readByCount?: number;
    receiversCount?: number;
};

export const MessageListItem = ({
    messageAuthorDto,
    sentAt,
    content,
    remoteLessonContent,
    attachments,
    receivers,
    readByCount,
    receiversCount,
    fetchAttachmentFunction,
    fetchAllAttachmentsInZipFunction,
}: MessageListItemProps) => {
    const { id: userId } = useUserDetails();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const { messagesAbilities } = useMessagesAbilities();

    const { userId: authorId, name: authorName, subName: authorSubname } = messageAuthorDto;
    const sendAtDateTime = parseStringIntoFormattedStringDate(
        sentAt,
        'YYYY-MM-DDTHH:mm:ss',
        'DD MMMM YYYY, HH:mm',
    );

    const canViewMessageReadStatus = messagesAbilities.checkIfCanViewMessageReadStatus();
    const isMessageAuthor = authorId === userId;
    const hasAccessToReadStatusData =
        receivers && receiversCount && typeof readByCount === 'number';
    const isVisibleMessageReadStatus =
        canViewMessageReadStatus && isMessageAuthor && hasAccessToReadStatusData;

    return (
        <StyledAppListItem>
            <MessageInfoContainer>
                <MessageMemberName
                    authorName={authorName}
                    authorSubname={authorSubname}
                    baseTextProps={{
                        textType: isMobile ? 'BodyMediumSemiBold' : 'BodyLargeSemiBold',
                    }}
                    subnameTextProps={{
                        color: theme.colors.grayscale.gray9,
                    }}
                />
                <AppText textType="Caption" margin={0} color={theme.colors.grayscale.gray9}>
                    {sendAtDateTime}
                </AppText>
            </MessageInfoContainer>
            <MessageTextContent
                regularContent={content}
                remoteLessonContent={remoteLessonContent}
            />
            {isVisibleMessageReadStatus && (
                <MessageReadStatus
                    receivers={receivers}
                    receiversCount={receiversCount}
                    readByCount={readByCount}
                />
            )}
            {!!attachments.length && (
                <MessageAttachments
                    attachments={attachments}
                    fetchAttachmentFunction={fetchAttachmentFunction}
                    fetchAllAttachmentsInZipFunction={fetchAllAttachmentsInZipFunction}
                />
            )}
        </StyledAppListItem>
    );
};
