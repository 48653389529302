import { AppFormItem } from 'components/atoms';
import { AppForm } from 'components/organisms';
import { OwnerSignUp } from 'types/forms/auth';
import {
    EmailInputElement,
    FormNavButtons,
    LastNameInputElement,
    FirstNameInputElement,
    PasswordInputElement,
    PhoneNumberInputElement,
    LegalText,
} from 'components/molecules';
import { useLinkClickHandler } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { FormPageProps } from 'types/forms/forms';
import { authTexts } from 'consts/text/index';
import { StyledLegalInfo } from './Step1.styled';

export const SignUpStep1 = (props: FormPageProps) => {
    const navigateToSignIn = useLinkClickHandler(appPaths.auth['sign-in']);

    return (
        <AppForm<OwnerSignUp>
            title="Rejestracja"
            subTitle="Podaj swoje dane osobowe do utworzenia konta."
            overLinkText="Masz już konto?"
            linkButton={authTexts.loginButtonLabel}
            onLinkButtonClick={navigateToSignIn}
            {...props}
        >
            <AppFormItem justifyContent="space-between" doubleItem>
                <FirstNameInputElement />
                <LastNameInputElement />
            </AppFormItem>
            <PhoneNumberInputElement />
            <EmailInputElement />
            <PasswordInputElement newPassword withRulesVisible />
            <PasswordInputElement newPassword passwordRepeat />
            <FormNavButtons buttonText={authTexts.nextStepButtonLabel} withArrow />
            <StyledLegalInfo textType="BodySmall" textAlign="center">
                Przechodząc dalej zgadzasz się na warunki zawarte w{' '}
                <LegalText type="TERMS_OF_USE">Regulaminie</LegalText> i{' '}
                <LegalText type="PRIVACY_POLICY">Politykę prywatności</LegalText>.
            </StyledLegalInfo>
        </AppForm>
    );
};
