import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { DatePickerArrowButtonType } from 'components/molecules';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { Pagination } from 'types';
import { DEFAULT_PAGE, PAGE_PARAM_KEY } from 'consts/pagination';

type DateFilterProps = {
    filterKey: string;
    currentPage?: Pagination['currentPage'];
};

export const useDateFilter = ({ filterKey, currentPage }: DateFilterProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();

    const dateParam = searchParams.get(filterKey);

    const isDefaultReplace = currentPage === undefined || currentPage === DEFAULT_PAGE;

    const setDateSearchParam = (selectedDate: Dayjs, replace?: boolean) =>
        setSearchParams(
            (prevSearchParams) => {
                prevSearchParams.set(
                    filterKey,
                    parseDayjsDateIntoString(selectedDate, 'YYYY-MM-DD'),
                );
                if (currentPage && currentPage !== DEFAULT_PAGE) {
                    prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
                }
                return prevSearchParams;
            },
            { replace: replace ?? isDefaultReplace },
        );

    useEffect(() => {
        if (dateParam) {
            return;
        }
        setDateSearchParam(dayjs(), true);
    }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeDateFilter = (selectedDate: Dayjs | null) => {
        if (!selectedDate) {
            return;
        }
        setDateSearchParam(selectedDate);
    };

    const handleClickNextOrPrevDate: (
        selectedDate: DatePickerArrowButtonType,
        unit?: 'day' | 'week' | 'month',
    ) => void = (selectedDate, unit = 'day') => {
        const currentDate = dateParam ? parseStringIntoDayjsDate(dateParam) : null;
        if (!currentDate) {
            return;
        }
        if (selectedDate === 'NEXT') {
            handleChangeDateFilter(currentDate.add(1, unit));
        }
        if (selectedDate === 'PREVIOUS') {
            handleChangeDateFilter(currentDate.subtract(1, unit));
        }
    };

    return {
        dateFilterValue: dateParam,
        handleChangeDateFilter,
        handleClickNextOrPrevDate,
    };
};
