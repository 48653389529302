import { AppText } from 'components/atoms/CommonAppComponents/AppText/AppText';
import { styled } from 'styled-components';

export type LabelProps = {
    mainText: string;
    additionalText?: string;
    disabled?: boolean;
};

const StyledText = styled(AppText)`
    font-size: 14px;
    line-height: 120%;
`;

const MainText = styled(StyledText)`
    font-weight: 700;
`;

const AdditionalText = styled(StyledText)`
    color: ${({ theme }) => theme.colors.grayscale.gray9};
`;

export const Label = ({ mainText, additionalText, disabled }: LabelProps) => (
    <>
        <MainText margin={0} disabled={disabled}>
            {mainText}
        </MainText>
        {additionalText && (
            <AdditionalText margin={0} marginLeft={4} disabled={disabled}>
                ({additionalText})
            </AdditionalText>
        )}
    </>
);
