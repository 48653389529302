import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { useAppAbility } from '../useAppAbility';

export const useTimetableAbilities = () => {
    const ability = useAppAbility();

    const timetableAbilities = {
        checkIfCanViewAllEmployeeTimetable: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.TIMETABLE_OWN) &&
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.TIMETABLE_ALL_EMPLOYEES),
        checkIfCanManageAllEmployeeTimetable: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.TIMETABLE_OWN) &&
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.TIMETABLE_ALL_EMPLOYEES),
        checkIfCanViewOwnTimetable: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.TIMETABLE_OWN),
        checkIfCanManageOwnTimetable: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.TIMETABLE_OWN),
    };

    return { timetableAbilities };
};
