import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppModalButton, AppOrderedList, AppText, DetailsItem } from 'components/atoms';
import { AppModal } from 'components/molecules/AppModal/AppModal';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, timetableTexts } from 'consts/text';
import { capitalize } from 'lodash';
import { generatePath } from 'react-router-dom';
import { LessonDTO } from 'types';
import { getFullName } from 'utils/getFullName';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';
import { useMemo } from 'react';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import {
    BottomSectionContainer,
    ChildrenListContainer,
    Container,
    DataRow,
} from './LessonDetailsModal.styled';
import { HelperText } from './components';

type LessonDetailsModalProps = {
    open: boolean;
    lesson: LessonDTO;
    editable?: boolean;
    onCancel: () => void;
    onRemove: () => void;
};

const getRepeatLabel = (repetition: LessonDTO['repetition']) => {
    let label;
    switch (repetition) {
        case 'EVERY_DAY':
            label = timetableTexts.lessons.lessonsRepetitionLabels.everyDay;
            break;
        case 'EVERY_WEEK':
            label = timetableTexts.lessons.lessonsRepetitionLabels.everyWeek;
            break;
        case 'EVERY_MONTH':
            label = timetableTexts.lessons.lessonsRepetitionLabels.everyMonth;
            break;
        case 'EVERY_2_WEEKS':
            label = timetableTexts.lessons.lessonsRepetitionLabels.everyTwoWeeks;
            break;
        default:
            label = '';
    }
    return label.toLowerCase();
};

export const LessonDetailsModal = ({
    open,
    lesson,
    editable,
    onCancel,
    onRemove,
}: LessonDetailsModalProps) => {
    const { statefulNavigate } = useNavigateWithState();
    const { name, date, timeFrom, timeTo, teacher, startDate, endDate, id, children, repetition } =
        lesson;

    const navigateToLessonEdit = () =>
        statefulNavigate(
            generatePath(appPaths.app.timetable.employeeSchedule.edit.pickedLesson, {
                lessonId: id,
            }),
            { state: { employeeId: teacher?.id } },
        );

    const footer = (
        <>
            <AppModalButton type="text" icon={<IconTrash />} onClick={onRemove}>
                {commonTexts.actionLabels.delete}
            </AppModalButton>
            <AppModalButton type="primary" icon={<IconEdit />} onClick={navigateToLessonEdit}>
                {commonTexts.actionLabels.edit}
            </AppModalButton>
        </>
    );

    const teacherFullName = teacher
        ? getFullName(teacher.firstName, teacher.lastName)
        : EMPTY_VALUE_LABEL;
    const weekday = capitalize(parseStringIntoDayjsDate(date).format('dddd'));
    const startDateParsed = parseStringIntoFormattedStringDate(startDate);
    const endDateParsed = parseStringIntoFormattedStringDate(endDate);
    const timeFromParsed = parseStringIntoFormattedStringTime(timeFrom);
    const timeToParsed = parseStringIntoFormattedStringTime(timeTo);
    const timeFromToUnified = `${timeFromParsed} - ${timeToParsed}`;
    const childrenList = useMemo(
        () =>
            children ? (
                <AppOrderedList>
                    {children.map((child) => (
                        <li>{getFullName(child.firstName, child.lastName)}</li>
                    ))}
                </AppOrderedList>
            ) : null,
        [children],
    );
    return (
        <AppModal
            open={open}
            onCancel={onCancel}
            title={lesson.name}
            footer={editable ? footer : null}
        >
            <Container>
                <DataRow>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.employee}>
                        {teacherFullName}
                    </DetailsItem>
                </DataRow>
                <DataRow>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.weekday}>
                        {weekday}
                    </DetailsItem>
                </DataRow>
                <DataRow>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.startDate}>
                        {startDateParsed}
                    </DetailsItem>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.endDate}>
                        {endDateParsed}
                    </DetailsItem>
                </DataRow>
                <DataRow>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.lessonName}>
                        {name}
                    </DetailsItem>
                    <DetailsItem title={timetableTexts.lessons.lessonDetailsModal.LessonDuration}>
                        {timeFromToUnified}
                    </DetailsItem>
                </DataRow>
                <BottomSectionContainer>
                    {repetition && (
                        <HelperText
                            text={`${
                                timetableTexts.lessons.lessonDetailsModal.lessonRepeatLabel
                            } ${getRepeatLabel(repetition)}`}
                        />
                    )}
                    {childrenList && (
                        <ChildrenListContainer>
                            <AppText margin={0} textType="BodyMSSemiBold">
                                {timetableTexts.lessons.lessonDetailsModal.attendingChildren}
                            </AppText>
                            {childrenList}
                        </ChildrenListContainer>
                    )}
                </BottomSectionContainer>
            </Container>
        </AppModal>
    );
};
