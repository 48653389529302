import { AbsenceFormFields, UpdateAbsenceDTO } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseAbsenceFormFieldsIntoUpdateAbsenceDTO = (
    absenceFormFields: AbsenceFormFields,
): UpdateAbsenceDTO => {
    const { teacherIds, dateFrom, dateTo, description, absenceType, paid } = absenceFormFields;

    return {
        teacherId: teacherIds[0],
        startDate: parseDayjsDateIntoString(dateFrom, 'YYYY-MM-DD'),
        endDate: parseDayjsDateIntoString(dateTo, 'YYYY-MM-DD'),
        description,
        type: absenceType,
        paid,
    };
};
