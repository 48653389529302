import { InstitutionAddEditFormFields, InstitutionCreateData } from 'types';

export const parseInstitutionCreateDataIntoInstitutionAddFormFields: (
    institutionCreateData: InstitutionCreateData,
) => InstitutionAddEditFormFields = (institutionCreateData) => ({
    regonNumber: institutionCreateData.regon,
    institutionName: institutionCreateData.name,
    rspoNumber: institutionCreateData.rspo,
    street: institutionCreateData.street,
    zipCode: institutionCreateData.zipCode,
    city: institutionCreateData.city,
    institutionAffiliation: institutionCreateData.publicInstitution ? 'public' : 'private',
    institutionType: institutionCreateData.type,
});
