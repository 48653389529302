import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { appPaths } from 'consts/paths/paths';
import { DietAddEditForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { DietsFormFields } from 'types';
import { parseDietsFormFieldsIntoAddUpdateDietDTO } from 'utils/parsers/diets/parseDietsFormFieldsIntoAddUpdateDietDTO';
import { settingsTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const DietsAddPage = () => {
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const onSuccess = () => {
        displaySuccess(settingsTexts.diets.successNotifications.dietAdded);
        statefulNavigate(appPaths.app.settings.diets.base, { replace: true });
    };

    const { mutate, error, isLoading } = useAppMutation(StaffHTTPService.diets.addDiet, {
        key: ['ADD_DIET'],
        onSuccess,
        invalidateQueryKeys: [['DIETS']],
    });

    const onFinish = (dietData: DietsFormFields) => {
        mutate(parseDietsFormFieldsIntoAddUpdateDietDTO(dietData));
    };
    return (
        <DietAddEditForm type="add" axiosError={error} isLoading={isLoading} onFinish={onFinish} />
    );
};
