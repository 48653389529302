import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { DailyScheduleAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { DailyScheduleFormFields } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { parseDailyScheduleFormFieldsIntoAddUpdateDailyScheduleDTO } from 'utils/parsers/schedule/parseDailyScheduleFormFieldsIntoAddUpdateDailyScheduleDTO';
import { journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalDailyScheduleAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const {
        data: dailyScheduleData,
        isFetching: isDailyScheduleDataFetching,
        isError: isDailyScheduleDataError,
    } = useAppQuery(
        'DAILY_SCHEDULE',
        [journalId],
        () => StaffHTTPService.schedules.dailySchedule.getDailySchedule(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (!dailyScheduleData || !dailyScheduleData.description) {
            return;
        }
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.edit,
                {
                    journalId,
                },
            ),
            { replace: true },
        );
    }, [dailyScheduleData, dailyScheduleData?.description, journalId, statefulNavigate]);

    const onAddDailyScheduleSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.schedules.dailySchedule
                .successfullyAddedDailySchedule,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addDailySchedule,
        error: addDailyScheduleError,
        isLoading: isAddDailyScheduleLoading,
    } = useAppMutation(StaffHTTPService.schedules.dailySchedule.updateDailySchedule, {
        key: ['UPDATE_DAILY_SCHEDULE'],
        onSuccess: onAddDailyScheduleSuccess,
        invalidateQueryKeys: [['DAILY_SCHEDULE', journalId]],
    });

    const onAddDailyScheduleFinish = (dailyScheduleFormData: DailyScheduleFormFields) => {
        const parsedAddDailyScheduleDTO =
            parseDailyScheduleFormFieldsIntoAddUpdateDailyScheduleDTO(dailyScheduleFormData);
        addDailySchedule({ journalId, addUpdateDailyScheduleDTO: parsedAddDailyScheduleDTO });
    };

    if (isDailyScheduleDataError) {
        return <ErrorDisplay />;
    }

    return (
        <DailyScheduleAddEditForm
            mode="add"
            axiosError={addDailyScheduleError}
            isLoading={isDailyScheduleDataFetching || isAddDailyScheduleLoading}
            onFinish={onAddDailyScheduleFinish}
        />
    );
};
