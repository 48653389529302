import { ParentTeacherConferenceDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseParentTeacherConferenceDTOIntoJsonObject: (
    parentTeacherConferenceDTO: ParentTeacherConferenceDTO,
) => object = (parentTeacherConferenceDTO) => {
    const { subject, date, description, parents } = parentTeacherConferenceDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const parentsJsonObjects = parents.map((parent) => ({
        [jsonObjectsTexts.parentTeacherConferenceDTO.innerObjects.parentTeacherConferenceParentDTO
            .fields.firstname]: parent.firstName,
        [jsonObjectsTexts.parentTeacherConferenceDTO.innerObjects.parentTeacherConferenceParentDTO
            .fields.lastname]: parent.lastName,
    }));

    return {
        [jsonObjectsTexts.parentTeacherConferenceDTO.fields.subject]: subject,
        [jsonObjectsTexts.parentTeacherConferenceDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.parentTeacherConferenceDTO.fields.description]: description,
        [jsonObjectsTexts.parentTeacherConferenceDTO.fields.parents]: parentsJsonObjects,
    };
};
