import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { applyActionCode } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { auth } from 'services/firebase/firebase';
import { appPaths } from 'consts/paths/paths';
import { authStateAtom } from 'jotaiAtoms/authState';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { LoadingDisplay } from 'components/molecules';
import { getFirebaseErrorLabel } from 'utils/handleFirebaseError/getFirebaseErrorLabel';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import AuthHTTPService from 'services/HTTPService/auth/AuthHTTPService';
import { authTexts } from 'consts/text';

export const AuthActionManagementPage = () => {
    const authState = useAtomValue(authStateAtom);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const { displaySuccess, displayError } = useSimpleNotification();

    const link = searchParams.get('link');
    const oobCode = searchParams.get('oobCode');
    const mode = searchParams.get('mode');

    const {
        data: tokenExpirationData,
        isFetching: isTokenExpirationDataFetching,
        isError: isTokenExpirationDataError,
    } = useAppQuery(
        'TOKEN_EXPIRATION',
        [link],
        () => AuthHTTPService.authentication.verifyTokenExpiration(link || ''),
        { enabled: !!link },
    );

    const navigateToSignIn = useCallback(
        () => navigate(appPaths.auth['sign-in'], { replace: true }),
        [navigate],
    );

    const handleNoToken = useCallback(() => {
        displayError(
            'Brak tokenu uwierzytelniającego akcję, spróbuj ponownie otworzyć link, który znajduje się w mailu',
        );
        navigateToSignIn();
    }, [displayError, navigateToSignIn]);

    const handlePasswordChange = useCallback(
        () =>
            navigate(
                { pathname: appPaths.auth['password-change'], search: searchParams.toString() },
                { replace: true },
            ),
        [navigate, searchParams],
    );

    const handleActivateAccountLinkExpired = useCallback(
        () =>
            navigate(
                { pathname: appPaths.auth['activate-account-link-expired'] },
                { replace: true },
            ),
        [navigate],
    );

    const handleEmailVerification = useCallback(
        async (oobCodeValue: string) => {
            try {
                await applyActionCode(auth, oobCodeValue);
                displaySuccess('Konto zostało aktywowane');
                if (authState === 'authorized') {
                    await auth.currentUser?.reload();
                    navigate(appPaths.app.base, { replace: true });
                } else {
                    navigateToSignIn();
                }
            } catch (error) {
                const errorMessage =
                    error instanceof FirebaseError
                        ? getFirebaseErrorLabel(error)
                        : 'Podczas wykonania akcji wystąpił błąd';
                displayError(errorMessage);
            }
        },
        [authState, navigate, navigateToSignIn, displaySuccess, displayError],
    );

    useEffect(() => {
        if (link && isTokenExpirationDataFetching) {
            return;
        }
        if (isTokenExpirationDataError) {
            displayError(authTexts.verifyTokenErrorMessage);
            navigate(appPaths.auth['sign-in'], { replace: true });
            return;
        }
        if (tokenExpirationData?.tokenExpired) {
            handleActivateAccountLinkExpired();
            return;
        }
        if (!oobCode && !link) {
            handleNoToken();
            return;
        }
        if (mode !== 'verifyEmail') {
            handlePasswordChange();
            return;
        }
        handleEmailVerification(oobCode as string);
    }, [
        oobCode,
        link,
        mode,
        authState,
        navigate,
        handleNoToken,
        handlePasswordChange,
        handleEmailVerification,
        navigateToSignIn,
        displayError,
        handleActivateAccountLinkExpired,
        isTokenExpirationDataFetching,
        tokenExpirationData?.tokenExpired,
        isTokenExpirationDataError,
    ]);

    return <LoadingDisplay />;
};
