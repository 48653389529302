import { MailThreadId, ParentAddMailThreadDTO, ParentMailThreadFormFields } from 'types';

export const parseReplyParentMailThreadFormFieldsIntoParentAddMailThreadDTO: (
    parentMailThreadFormFields: ParentMailThreadFormFields,
    sourceThreadId: MailThreadId,
) => ParentAddMailThreadDTO = (parentMailThreadFormFields, sourceThreadId) => {
    const { topic, content, attachments } = parentMailThreadFormFields;
    return {
        topic,
        content,
        attachments,
        sourceThreadId,
    };
};
