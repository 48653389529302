import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconTrash, IconEye, IconFileExport } from '@tabler/icons-react';
import { AppTable, AppTableProps, ArchiveRestoreModal } from 'components/organisms';
import { MenuDTO, MenuId } from 'types';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { appPaths } from 'consts/paths/paths';
import { AppButton, Option } from 'components/atoms';
import { menusDTOFields } from 'consts/menus/menusDTOFields';
import { AppDatePickerWithLabel } from 'components/molecules';
import dayjs from 'dayjs';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { commonTexts, menusTexts } from 'consts/text';
import { useDietAbilities } from 'abilities';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { GenerateReportModal } from './components/GenerateReportModal/GenerateReportModal';
import { useMenusPageNetworkManage } from './hooks/useMenusPageNetworkManage';

const columns: AppTableProps<MenuDTO>['columns'] = [
    {
        title: commonTexts.dataLabels.name,
        dataIndex: menusDTOFields.name,
        key: menusDTOFields.name,
        width: '50%',
    },
    {
        title: menusTexts.appliesFrom,
        dataIndex: menusDTOFields.dateFrom,
        key: menusDTOFields.dateFrom,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
        render: (date: string) => parseStringIntoFormattedStringDate(date),
    },
    {
        title: menusTexts.appliesTo,
        dataIndex: menusDTOFields.dateTo,
        key: menusDTOFields.dateTo,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
        render: (date: string) => parseStringIntoFormattedStringDate(date),
    },
];

export const MenusPage = () => {
    const [selectedToRemoveMenuId, setSelectedToRemoveMenuId] = useState<MenuId | null>(null);
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const { dietAbilities } = useDietAbilities();
    const { checkIfCanManageDietConfig } = dietAbilities;
    const canManageDietSettings = checkIfCanManageDietConfig();

    const onRemoveMenuClick = (menu: MenuDTO) => setSelectedToRemoveMenuId(menu.id);

    const options: Option<MenuDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: ({ id }) =>
                statefulNavigate(
                    generatePath(appPaths.app.menu.details.pickedMenu, {
                        menuId: id,
                    }),
                ),
            Icon: <IconEye />,
        },

        {
            label: commonTexts.actionLabels.edit,
            onClick: ({ id }) =>
                statefulNavigate(generatePath(appPaths.app.menu.edit.pickedMenu, { menuId: id })),
            Icon: <IconEdit />,
            checkIfVisible: () => canManageDietSettings,
        },

        {
            label: commonTexts.actionLabels.delete,
            onClick: onRemoveMenuClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManageDietSettings,
        },
    ];

    const onMenuRemoveRequestFinish = () => setSelectedToRemoveMenuId(null);

    const { dateFilterValue, handleChangeDateFilter } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });

    const {
        menusData,
        isMenusDataInitialLoading,
        isMenusDataRefetching,
        isMenusDataError,
        isRemoveMenuLoading,
        isMenusDataFetching,
        removeMenu,
        isGenerateReportModalVisible,
        setIsGenerateReportModalVisible,
        isGenerateReportModalLoading,
        handleGenerateReport,
    } = useMenusPageNetworkManage({
        date: dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'),
        onMenuRemoveRequestFinish,
    });

    const handleRemoveMenuConfirm = () => {
        if (!selectedToRemoveMenuId) {
            return;
        }
        removeMenu(selectedToRemoveMenuId);
    };

    const handleRemoveMenuCancel = () => setSelectedToRemoveMenuId(null);

    const handleGenerateReportModalClose = () => setIsGenerateReportModalVisible(false);

    const navigateToMenuAdd = () => statefulNavigate(appPaths.app.menu.add);

    const filters = (
        <AppDatePickerWithLabel
            label={commonTexts.actionLabels.selectMonth}
            value={dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()}
            picker="month"
            format={FULL_MONTH_FORMAT}
            allowClear={false}
            disabled={isMenusDataFetching}
            isFitContentWidth={!isMobile}
            onChange={handleChangeDateFilter}
        />
    );

    return (
        <>
            <GenerateReportModal
                open={isGenerateReportModalVisible}
                onClose={handleGenerateReportModalClose}
                confirmActionLoading={isGenerateReportModalLoading}
                onOk={handleGenerateReport}
                title={menusTexts.createRapport}
            />
            <ArchiveRestoreModal
                open={!!selectedToRemoveMenuId}
                title={menusTexts.modalDeleteTitle}
                confirmActionLoading={isRemoveMenuLoading}
                onOk={handleRemoveMenuConfirm}
                onCancel={handleRemoveMenuCancel}
            />
            <AppTable
                title={menusTexts.menusList}
                addButtonLabel={canManageDietSettings ? menusTexts.addMenu : undefined}
                emptyInfo={menusTexts.emptyMenuList}
                additionalRightTableHeaderContent={
                    <AppButton
                        type="default"
                        fullWidth={isMobile}
                        onClick={() => setIsGenerateReportModalVisible(true)}
                        icon={<IconFileExport width={20} height={20} />}
                        margin={0}
                        marginRight={isMobile ? 0 : 16}
                        marginBottom={isMobile ? 12 : 0}
                    >
                        {menusTexts.createRapport}
                    </AppButton>
                }
                options={options}
                columns={columns}
                rowKey={({ id }) => id}
                dataSource={menusData}
                isDataInitialLoading={isMenusDataInitialLoading}
                isDataRefetching={isMenusDataRefetching}
                isOptionsLoading={isRemoveMenuLoading}
                isError={isMenusDataError}
                onAddButtonClick={navigateToMenuAdd}
                tableManageUtilsProps={{
                    isVisibleSearchFilter: false,
                    additionalFilters: filters,
                }}
            />
        </>
    );
};
