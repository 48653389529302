import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        width: 16%;
        padding: 12px;
        display: flex;
        flex-direction: column;
        flex: 1;

        &:first-child {
            border-radius: ${theme.borders.borderRadiusLarge}px 0 0
                ${theme.borders.borderRadiusLarge}px;
        }

        &:nth-child(odd) {
            background-color: ${theme.colors.primary.primary2};
        }

        &:last-child {
            border-radius: ${theme.borders.borderRadiusLarge}px;
        }

        @media (${theme.sizes.mediaQueries.tabletMax}) {
            width: 100%;

            &:first-child {
                border-radius: ${theme.borders.borderRadiusLarge}px
                    ${theme.borders.borderRadiusLarge}px 0 0;
            }
        }
    `}
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
`;
