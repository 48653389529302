import { FormPageProps } from 'types/forms/forms';
import { InstitutionAddEditForm } from 'components/organisms/InstitutionAddEditForm/InstitutionAddEditForm';
import { FormNavButtons } from 'components/molecules';
import { redirectToMailAgent } from 'utils/redirectToMailAgent';
import { CONTACT_EMAIL } from 'consts/contactEmail';
import { authTexts } from 'consts/text/index';

export const SignUpStep2 = (props: FormPageProps) => {
    const navButtons = (
        <FormNavButtons
            buttonsFlexWidth={0.3}
            withGoBackButton
            buttonText={authTexts.registerButtonLabel}
            withArrow
        />
    );

    return (
        <InstitutionAddEditForm
            overLinkText="Potrzebujesz pomocy?"
            linkButton="Skontaktuj się z nami"
            subTitle="Wprowadź dane zarządzanej placówki."
            onLinkButtonClick={() => redirectToMailAgent(CONTACT_EMAIL)}
            formNavButtons={navButtons}
            mode="add"
            {...props}
        />
    );
};
