import { SettlementStaysDTO, SettlementStaysFormFields } from 'types';
import { parseStringIntoDayjsTime } from '../dateTime/parseStringIntoDayjsTime';

export const parseSettlementStaysDTOIntoSettlementStaysFormFields: (
    settlementStaysDTO: SettlementStaysDTO,
) => SettlementStaysFormFields = ({ freeHoursEnd, freeHoursStart, ...otherFields }) => ({
    ...otherFields,
    freeHoursEnd: freeHoursEnd ? parseStringIntoDayjsTime(freeHoursEnd) : null,
    freeHoursStart: freeHoursStart ? parseStringIntoDayjsTime(freeHoursStart) : null,
});
