import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { settingsTexts } from 'consts/text';
import { SelectOptionType } from 'types';

export type RoleTemplatePickerElementProps = AppFormItemProps & {
    options?: SelectOptionType<string>[];
    isLoading?: boolean;
};

export const RoleTemplatePickerElement = ({
    options,
    isLoading,
    ...props
}: RoleTemplatePickerElementProps) => (
    <AppFormItem
        label={settingsTexts.rolesAndPermissions.chooseTemplate}
        name={formFields.roleTemplateName}
        optional
        hasOptionalText
        {...props}
    >
        <AppSelect options={options} loading={isLoading} />
    </AppFormItem>
);
