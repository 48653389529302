import {
    AbsenceTypePickerElement,
    AppModal,
    DayPeriodPickerElement,
    DescriptionInputElement,
    FormHeader,
    FormNavButtons,
    SimpleCheckboxElement,
    TeacherPickerVersion,
} from 'components/molecules';
import { appPaths } from 'consts/paths/paths';
import {
    AppConfirmModal,
    AppModalForm,
    AppModalFormProps,
    TeachersPicker,
} from 'components/organisms';
import { AbsenceFormFields, AbsenceType, CommonFormProps, FormModeDependedTexts } from 'types';
import { HeaderContainer } from 'components/organisms/AppForm/AppForm.styled';
import { AppFormItem } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts, employeeTexts, timetableTexts } from 'consts/text';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useForm } from 'rc-field-form';
import { AbsenceTypeToPaymentStatusMap } from 'consts/absence/absenceType';
import { ApiErrorType } from 'services/reactQuery/apiErrorType';
import { UserEdit } from 'assets';
import { colors } from 'theme/styledComponents/colors';
import { useState } from 'react';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { staffAbsenceAddEditErrorParser } from 'utils/errorHandlers/absence/staffAbsenceAddEditErrorParser';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export type AbsenceAddEditModalProps = Omit<
    AppModalFormProps<AbsenceFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null | ApiErrorType;
        isLoading?: boolean;
        onFinish: (eventDate: AbsenceFormFields) => void;
    };

const addModeTexts: FormModeDependedTexts = {
    name: 'absenceAdd',
    title: timetableTexts.absence.absneceAdd,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'absenceEdit',
    title: timetableTexts.absence.absenceEdit,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};
export const AbsenceAddEditModal = ({
    mode,
    initialValues,
    axiosError,
    form,
    ...props
}: AbsenceAddEditModalProps) => {
    const [isConfirmEditModalVisible, setIsConfirmEditModalVisible] = useState<boolean>(false);
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const absenceAddError = axiosError
        ? staffAbsenceAddEditErrorParser(axiosError, commonTexts.errorMessages.unknown)
        : '';
    const [formInstance] = useForm<AbsenceFormFields>(form);
    const { statefulNavigate } = useNavigateWithState();

    const handleAbsenceTypeChange = (absenceTypeValueFromPicker: AbsenceType) => {
        const paidStatus = AbsenceTypeToPaymentStatusMap[absenceTypeValueFromPicker].paid;
        formInstance.setFieldsValue({ [formFields.paid]: paidStatus });
    };

    const teacherPickerLabel = isAddMode
        ? employeeTexts.teacherPickerLabels.addTeachers
        : employeeTexts.teacherPickerLabels.selectTeacher;

    const onFormFinish = (formValues: AbsenceFormFields) => {
        if (isAddMode) {
            props.onFinish(formValues);
        } else {
            setIsConfirmEditModalVisible(true);
        }
    };
    const onConfirmEdit = () => {
        setIsConfirmEditModalVisible(false);
        props.onFinish(formInstance.getFieldsValue());
    };

    return (
        <>
            <AppConfirmModal
                icon={<UserEdit style={{ color: colors.red.red6 }} />}
                okButtonProps={{ title: commonTexts.actionLabels.confirm, danger: true }}
                cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
                open={isConfirmEditModalVisible}
                title={timetableTexts.absence.editAbsenceAffectNextModalTitle}
                onCancel={() => setIsConfirmEditModalVisible(false)}
                onOk={onConfirmEdit}
                text={timetableTexts.absence.editAbsenceAffectNextModalDescription}
            />
            <AppModal
                open
                onCancel={() => statefulNavigate(appPaths.app.timetable.employeeSchedule.base)}
            >
                <AppModalForm<AbsenceFormFields>
                    name={name}
                    form={form}
                    {...props}
                    onFinish={onFormFinish}
                >
                    <HeaderContainer isFormHeaderAtBottom={false}>
                        <FormHeader title={title} desktopTitleLevel={4} error={absenceAddError} />
                    </HeaderContainer>

                    <TeachersPicker
                        fetchConfig={{
                            fetchQueryKey: 'LESSONS_EMPLOYEES',
                            fetchFunction: () =>
                                StaffHTTPService.employeeSchedule.getLessonsEmployees(),
                        }}
                        version={TeacherPickerVersion.MULTIPLE}
                        disabled={!isAddMode}
                        label={teacherPickerLabel}
                    />
                    <AppFormItem justifyContent="space-between" doubleItem>
                        <DayPeriodPickerElement
                            mode="start"
                            endField={formFields.dateTo}
                            startField={formFields.dateFrom}
                            label={commonTexts.dataLabels.dateFrom}
                        />
                        <DayPeriodPickerElement
                            mode="finish"
                            endField={formFields.dateTo}
                            startField={formFields.dateFrom}
                            label={commonTexts.dataLabels.dateTo}
                        />
                    </AppFormItem>
                    <AbsenceTypePickerElement onChange={handleAbsenceTypeChange} />
                    <SimpleCheckboxElement
                        label={commonTexts.dataLabels.paid}
                        name={formFields.paid}
                    />
                    <DescriptionInputElement />
                    <FormNavButtons
                        formValuesBeforeChanges={initialValues}
                        buttonText={buttonText}
                        leftIcon={buttonIcon}
                        withPlus={isAddMode}
                    />
                </AppModalForm>
            </AppModal>
        </>
    );
};
