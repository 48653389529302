import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useSendActivationEmail } from 'hooks/useSendActivationEmail/useSendActivationEmail';
import { employeeTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type EmployeesPageNetworkManageParams = {
    search: string;
    onEmployeeRemoveRequestFinish: () => void;
};
export const useEmployeesPageNetworkManage = ({
    search,
    onEmployeeRemoveRequestFinish,
}: EmployeesPageNetworkManageParams) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const { sendActivationEmail, isActivationEmailSendLoading } = useSendActivationEmail({
        mainKey: 'EMPLOYEES',
    });

    const {
        isInitialLoading: isTableDataInitialLoading,
        isRefetching: isTableDataRefetching,
        isError: isTableDataError,
        data: tableData,
    } = useAppQuery('EMPLOYEES', [search], () => StaffHTTPService.employees.getEmployees(search), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { mutate: removeEmployee, isLoading: isRemoveEmployeeLoading } = useAppMutation(
        StaffHTTPService.employees.deleteEmployee,
        {
            key: ['DELETE_EMPLOYEE'],
            onSuccess: async (_, variables) => {
                onEmployeeRemoveRequestFinish();
                // variables is EmployeeId
                await queryClient.invalidateQueries([institutionId, 'EMPLOYEE', variables]);
                displaySuccess(employeeTexts.successfullyDeletedEmployee);
            },
            onError: () => {
                onEmployeeRemoveRequestFinish();
                displayError('Wystąpił błąd przy usuwaniu pracownika');
            },
            invalidateQueryKeys: [
                ['EMPLOYEES'],
                ['GROUPS_EMPLOYEES'],
                ['JOURNALS_EMPLOYEES'],
                ['INSTITUTIONS_EMPLOYEES'],
                ['LESSONS_EMPLOYEES'],
                ['JOURNALS_TEACHERS'],
                ['STAFF'],
                ['MAIL_THREAD_RECEIVERS'],
            ],
        },
    );

    return {
        isInitialLoading: isTableDataInitialLoading,
        isRefetching: isTableDataRefetching,
        isError: isTableDataError,
        employeesData: tableData,
        removeEmployee,
        sendActivationEmail,
        isOptionLoading: isRemoveEmployeeLoading || isActivationEmailSendLoading,
        isRemoveEmployeeLoading,
    };
};
