import { Button, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';
import { MarginOptions, MarginsProps } from 'types/components/margins';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import React, { Ref } from 'react';

export type AppButtonProps = ButtonProps &
    MarginsProps & {
        fullWidth?: boolean;
        flex?: number;
        withoutBorderRadius?: boolean;
    };

const filteredProps = marginPropsToFilter.concat(['fullWidth', 'flex', 'withoutBorderRadius']);

const getMargin = ({ fullWidth, margin }: { margin?: MarginOptions; fullWidth?: boolean }) => {
    if (!fullWidth && !margin && margin !== 0) {
        return 12;
    }
    if (fullWidth && !margin) {
        return 0;
    }
    return margin;
};

const StyledButton = styled(Button).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppButtonProps>`
    ${({ theme, margin, fullWidth = false, flex, withoutBorderRadius, ...props }) => css`
        display: flex;
        flex: ${!fullWidth ? flex : ''};
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.03em;
        padding: 10px 24px;
        height: unset;
        border: 0;
        border-radius: ${withoutBorderRadius ? 0 : 8}px;
        width: ${fullWidth ? '100%' : ''};
        ${getMarginsCss({
            margin: getMargin({ margin, fullWidth }),
            ...props,
        })}

        &.ant-btn-lg {
            padding: 12px 24px;
            height: unset;
        }

        &.ant-btn-sm {
            font-size: 14px;
            line-height: 16.8px;
            padding: 7px 12px;
            height: unset;
            border-radius: ${withoutBorderRadius ? 0 : 8}px;

            &.ant-btn-icon-only {
                width: auto;
                padding: 6px;
            }
        }

        &:focus-visible:not(:disabled) {
            outline: none;
            box-shadow: 0 0 0 1.5px ${theme.colors.primary.primary5}66;
            &.ant-btn-dangerous {
                box-shadow:
                    0 0 0 2px ${theme.colors.red.red4},
                    inset 0 0 0 1px ${theme.colors.red.red8};
            }
        }

        &.ant-btn-primary {
            &:focus-visible:not(:disabled) {
                background-color: ${theme.colors.primary.primary8};
                &.ant-btn-dangerous {
                    background-color: ${theme.colors.red.red5};
                }
            }
            &:hover:not(:disabled) {
                &.ant-btn-dangerous {
                    background-color: ${theme.colors.red.red5};
                }
            }
            &:active:not(:disabled) {
                &.ant-btn-dangerous {
                    background-color: ${theme.colors.red.red8};
                }
            }
        }

        &.ant-btn-default {
            color: ${theme.colors.grayscale.gray10};
            border: 1px solid ${theme.colors.primary.primary5};
            background-color: ${theme.colors.grayscale.gray1};

            &:focus-visible:not(:disabled) {
                color: ${theme.colors.primary.primary9};
                background-color: ${theme.colors.primary.primary3};
                border: 1px solid ${theme.colors.primary.primary9};
                &.ant-btn-dangerous {
                    background-color: ${theme.colors.red.red2};
                }
            }

            &.ant-btn-dangerous {
                color: ${theme.colors.red.red6};
                border: 1px solid ${theme.colors.red.red6};
            }

            &:disabled {
                color: ${theme.colors.grayscale.gray7};
                background-color: ${theme.colors.grayscale.gray2};
            }
            &:hover:not(:disabled) {
                color: ${theme.colors.primary.primary9};
                border: 1px solid ${theme.colors.primary.primary9};
                background-color: ${theme.colors.primary.primary3};
                &.ant-btn-dangerous {
                    color: ${theme.colors.red.red5};
                    border: 1px solid ${theme.colors.red.red5};
                    background-color: ${theme.colors.red.red2};
                }
            }
            &:active:not(:disabled) {
                color: ${theme.colors.primary.primary10};
                border: 1px solid ${theme.colors.primary.primary10};
                background-color: ${theme.colors.primary.primary4};
                &.ant-btn-dangerous {
                    color: ${theme.colors.red.red6};
                    border: 1px solid ${theme.colors.red.red8};
                    background-color: ${theme.colors.red.red3};
                }
            }
        }

        &.ant-btn-link {
            color: ${theme.colors.primary.primary9};

            &:disabled {
                color: ${theme.colors.grayscale.gray7};
            }
            &:focus-visible:not(:disabled) {
                color: ${theme.colors.primary.primary9};
                border: none;
                box-shadow: none;
            }
            &:hover:not(:disabled) {
                color: ${theme.colors.primary.primary8};
            }
            &:active:not(:disabled) {
                color: ${theme.colors.primary.primary10};
            }
        }
        &.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled) {
            border: 1px solid transparent;
            background-color: transparent;

            &:hover {
                border: 1px solid transparent;
                background-color: transparent;
                color: ${theme.colors.primary.primary9};
            }
        }
    `}
`;

// Default button is outlined
export const AppButton = React.forwardRef(
    ({ ...props }: AppButtonProps, ref: Ref<HTMLButtonElement>) => (
        <StyledButton ref={ref} {...props} />
    ),
);
