import IMask from 'imask';
import { useState } from 'react';
import { last } from 'lodash';
import { AppInput, AppInputProps } from '../AppInput/AppInput';
import { AppPopover } from '../AppPopover/AppPopover';

type AppMaskedInputProps = {
    mask: string;
    onChange?: (value: string) => void;
} & AppInputProps;

export const AppMaskedInput = ({ mask, onChange, ...props }: AppMaskedInputProps) => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    const numberInputChanged = (inputValue: string) => {
        const maskApplied = IMask.pipe(inputValue, {
            mask,
        });
        setPopoverVisible(
            last(inputValue) !== last(maskApplied) && maskApplied.length !== mask.length,
        );

        if (onChange) {
            onChange(maskApplied);
        }
    };

    const popoverContent = `Wprowadzono niepoprawny symbol dopuszczalny format to ${mask}`;

    return (
        <AppPopover open={popoverVisible} placement="top" content={popoverContent}>
            <AppInput {...props} onChange={(event) => numberInputChanged(event.target.value)} />
        </AppPopover>
    );
};
