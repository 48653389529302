import { pick } from 'lodash';
import { CalendarEventType, CreateCalendarEventDto } from 'types';
import { EventFormFields } from 'types/forms/event';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseEventFormFieldsIntoAddEventDTO: (
    eventFormFields: EventFormFields,
) => CreateCalendarEventDto = (eventFormFields) => ({
    ...pick(eventFormFields, ['description', 'freeFromSchool', 'name']),
    type: eventFormFields.type as CalendarEventType,
    startDate: parseDayjsDateIntoString(eventFormFields.startDate, 'YYYY-MM-DD'),
    endDate: parseDayjsDateIntoString(eventFormFields.endDate, 'YYYY-MM-DD'),
});
