import { Child } from 'types';
import { useChildAbilities } from 'abilities';
import { useChildrenDetailsPath } from 'hooks/useChildrenDetailsPath/useChildrenDetailsPath';
import { FirstAndLastNameDisplay } from 'components/molecules/Table/FirstAndLastNameDisplay/FirstAndLastNameDisplay';

type ChildFirstAndLastNameDisplayProps = Pick<Child, 'id' | 'firstName' | 'lastName'>;

export const ChildFirstAndLastNameDisplay = ({
    id,
    firstName,
    lastName,
}: ChildFirstAndLastNameDisplayProps) => {
    const { getChildrenDetailsPath } = useChildrenDetailsPath();
    const { childAbilities } = useChildAbilities();

    const canViewChildDetails = childAbilities.checkIfCanViewChildrenList();

    const childDetailsPath = getChildrenDetailsPath('', id);
    const to = canViewChildDetails ? childDetailsPath : undefined;

    return <FirstAndLastNameDisplay firstName={firstName} lastName={lastName} to={to} />;
};
