import { getFullName } from 'utils/getFullName';
import { GroupJoinAndLeaveData, PersonData } from 'types';
import { AppList } from 'components/atoms';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export type PersonListProps = {
    personsData: ({ id: string } & PersonData & Partial<GroupJoinAndLeaveData>)[];
};

export const PersonList = ({ personsData }: PersonListProps) => {
    const content = (firstName: string, lastName: string, leaveDate?: string | null) => {
        if (leaveDate) {
            const parsedLeaveDate = parseStringIntoFormattedStringDate(leaveDate);
            return `${getFullName(firstName, lastName)} (do ${parsedLeaveDate})`;
        }
        return getFullName(firstName, lastName);
    };
    return (
        <AppList
            dataSource={personsData}
            renderItem={({ id, firstName, lastName, leaveDate }, index) => (
                <AppList.Item key={id} orderNumber={index + 1}>
                    {content(firstName, lastName, leaveDate)}
                </AppList.Item>
            )}
        />
    );
};
