import { generatePath } from 'react-router-dom';
import { ChildEdit } from 'components/templates';
import { appPaths } from 'consts/paths/paths';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useJournalId } from 'hooks/useJournalId/useJournalId';

export const JournalChildEditPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { journalId } = useJournalId();
    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const childrenPagePath = generatePath(
        journalType === 'GROUP'
            ? appPaths.app.journals.groupJournalDetails.pickedJournal.children.base
            : appPaths.app.journals.specialJournalDetails.pickedJournal.children.base,
        { journalId },
    );

    return <ChildEdit childrenPagePath={childrenPagePath} />;
};
