import styled, { css } from 'styled-components';
import { AppButton } from 'components/atoms';
import { getTextStyle } from 'utils/getTextStyle';

export const DownloadButton = styled(AppButton)`
    ${({ theme }) => css`
        &.ant-btn-link {
            margin: 0;
            padding: 0;
            color: ${theme.colors.primary.primary9};
            ${getTextStyle('BodyMSSemiBold')}
        }
    `}
`;
