import { useSearchParams } from 'react-router-dom';
import {
    getParamsToSet,
    getStepParamStepAsNumber,
    Parameters,
} from 'components/organisms/StepsForm/StepsForm.utils';

export const useStepsForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const step = getStepParamStepAsNumber(searchParams);

    const finishForm = (customParameters?: Parameters) =>
        setSearchParams(getParamsToSet(step, { finished: 'true', customParameters }));

    return {
        finishForm,
    };
};
