import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId, TimetableFilters } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { getTimetableScheduleFromTimetable } from 'utils/getTimetableScheduleFromTimetable';
import { TimetableScheduleData } from 'components/templates/timetable/TimetableSchedule/TimetableSchedule.types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type SpecialJournalLessonsPageNetworkManageProps = {
    selectedDate: string;
};

export const useSpecialJournalLessonsPageNetworkManage = ({
    selectedDate,
}: SpecialJournalLessonsPageNetworkManageProps) => {
    const { journalId: journalIdParam } = useParams();

    const journalId = journalIdParam as JournalId;
    const parsedSelectedDate = parseStringIntoDayjsDate(selectedDate);

    const filters: TimetableFilters = {
        journalId,
        date: selectedDate,
    };

    const {
        data: lessonsData,
        isInitialLoading: isLessonsDataInitialLoading,
        isRefetching: isLessonsDataRefetching,
        isError: isLessonsDataError,
    } = useAppQuery(
        'EMPLOYEE_SCHEDULE',
        [filters],
        () => StaffHTTPService.employeeSchedule.getEmployeeSchedule(filters),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const lessonsScheduleData = useMemo<TimetableScheduleData | null>(() => {
        if (!lessonsData) {
            return null;
        }
        return getTimetableScheduleFromTimetable(lessonsData, parsedSelectedDate);
    }, [lessonsData, parsedSelectedDate]);

    return {
        lessonsScheduleData,
        isLessonsDataInitialLoading,
        isLessonsDataRefetching,
        isLessonsDataError,
    };
};
