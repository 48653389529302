import { useState } from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { PsychologicalHelpFormDisplay } from 'components/molecules';
import { PsychologicalHelpDTO, PsychologicalHelpId } from 'types/psychologicalHelp';
import { psychologicalHelpDTOFields } from 'consts/psychologicalHelp/psychologicalHelpDTOFields';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { getFullName } from 'utils/getFullName';
import { appPaths } from 'consts/paths/paths';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { Option } from 'components/atoms';
import { commonTexts, journalTexts } from 'consts/text/index';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useGroupJournalPsychologicalHelpsPageNetworkManage } from './hooks/useGroupJournalPsychologicalHelpsPageNetworkManage';

const columns: AppTableProps<PsychologicalHelpDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.psychologicalHelp.table.formOfHelp,
        dataIndex: psychologicalHelpDTOFields.helpForm,
        key: psychologicalHelpDTOFields.helpForm,
        render: (_, record) => (
            <PsychologicalHelpFormDisplay
                id={record.id}
                journalId={record.journalId}
                helpForm={record.helpForm}
            />
        ),
    },
    {
        title: journalTexts.journalDetails.groupJournal.psychologicalHelp.table.child,
        dataIndex: psychologicalHelpDTOFields.child,
        key: psychologicalHelpDTOFields.child,
        render: (child: PsychologicalHelpDTO['child']) =>
            getFullName(child.firstName, child.lastName),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.psychologicalHelp.table.startDate,
        dataIndex: psychologicalHelpDTOFields.dateFrom,
        key: psychologicalHelpDTOFields.dateFrom,
        render: (_, { dateFrom, dateTo }) => {
            const parsedStartDate = parseStringIntoFormattedStringDate(dateFrom);
            const parsedEndDate = parseStringIntoFormattedStringDate(dateTo);
            if (dateFrom === dateTo) {
                return parsedStartDate;
            }
            return `${parsedStartDate}-${parsedEndDate}`;
        },
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const GroupJournalPsychologicalHelpsPage = () => {
    const { journalId } = useJournalId();
    const [selectedToRemovePsychologicalHelpId, setSelectedToRemovePsychologicalHelpId] =
        useState<PsychologicalHelpId | null>(null);
    const { statefulNavigate } = useNavigateWithState();
    const {
        groupJournalsAbilities: { checkIfCanManagePsychologicalHelp },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManagePsychologicalHelp = checkIfCanManagePsychologicalHelp();

    const onPsychologicalHelpRemoveRequestFinishOrCancel = () =>
        setSelectedToRemovePsychologicalHelpId(null);

    const {
        psychologicalHelpsData,
        isPsychologicalHelpsDataInitialLoading,
        isPsychologicalHelpsDataRefetching,
        isPsychologicalHelpsDataError,
        isRemovePsychologicalHelpLoading,
        removePsychologicalHelp,
    } = useGroupJournalPsychologicalHelpsPageNetworkManage({
        journalId,
        onPsychologicalHelpRemoveRequestFinish: onPsychologicalHelpRemoveRequestFinishOrCancel,
    });

    const navigateToPsychologicalHelpAdd = () =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.add,
                { journalId },
            ),
        );

    const navigateToPsychologicalHelpEdit = ({ id }: PsychologicalHelpDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.edit
                    .pickedPsychologicalHelp,
                { psychologicalHelpId: id, journalId },
            ),
        );

    const navigateToPsychologicalHelpDetails = ({ id }: PsychologicalHelpDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.details
                    .pickedPsychologicalHelp,
                { psychologicalHelpId: id, journalId },
            ),
        );

    const handleRemovePsychologicalHelpClick = (record: PsychologicalHelpDTO) =>
        setSelectedToRemovePsychologicalHelpId(record.id);

    const handleRemovePsychologicalHelpConfirm = () => {
        if (!selectedToRemovePsychologicalHelpId) {
            return;
        }
        removePsychologicalHelp({
            journalId,
            psychologicalHelpId: selectedToRemovePsychologicalHelpId,
        });
    };

    const options: Option<PsychologicalHelpDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToPsychologicalHelpDetails,
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToPsychologicalHelpEdit,
            Icon: <IconEdit />,
            checkIfVisible: () => canManagePsychologicalHelp,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemovePsychologicalHelpClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManagePsychologicalHelp,
        },
    ];

    if (!journalId) {
        return <Navigate to={appPaths.app.journals.base} />;
    }

    return (
        <>
            <RemoveModal
                open={!!selectedToRemovePsychologicalHelpId}
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.removeModal.title}
                confirmActionLoading={isRemovePsychologicalHelpLoading}
                onOk={handleRemovePsychologicalHelpConfirm}
                onCancel={onPsychologicalHelpRemoveRequestFinishOrCancel}
            />
            <AppTable
                title={journalTexts.journalDetails.groupJournal.psychologicalHelp.table.title}
                addButtonLabel={
                    journalTexts.journalDetails.common.psychologicalHelp.addPsychologicalHelp
                }
                emptyInfo={
                    journalTexts.journalDetails.groupJournal.psychologicalHelp.table.emptyInfo
                }
                options={options}
                columns={columns}
                dataSource={psychologicalHelpsData}
                rowKey={({ id }) => id}
                isTableManageUtilsHidden
                isError={isPsychologicalHelpsDataError}
                isOptionsLoading={isRemovePsychologicalHelpLoading}
                isDataInitialLoading={isPsychologicalHelpsDataInitialLoading}
                isDataRefetching={isPsychologicalHelpsDataRefetching}
                isHiddenAddButton={!canManagePsychologicalHelp}
                onAddButtonClick={navigateToPsychologicalHelpAdd}
            />
        </>
    );
};
