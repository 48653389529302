import styled from 'styled-components';
import { MessageFormFields } from 'types';
import {
    AttachmentsUploadElement,
    AttachmentsUploadElementProps,
    FormNavButtons,
    SimpleTextAreaElement,
} from 'components/molecules';
import { AppForm, AppFormProps } from 'components/organisms/AppForm/AppForm';
import { messagesTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { StyledSendIcon } from 'components/atoms';
import { max2000LengthRule } from 'consts/rules/textRules';

export type MessageAddFormProps = Pick<AppFormProps<MessageFormFields>, 'form' | 'className'> &
    Pick<AttachmentsUploadElementProps, 'uploadAttachmentFunction'> & {
        isLoading?: boolean;
        onFinish: (messageData: MessageFormFields) => void;
    };

const StyledAttachmentsUploadElement = styled(AttachmentsUploadElement)`
    ul {
        max-height: 90px;
        overflow: auto;
    }
`;

export const MessageAddForm = ({
    form,
    isLoading,
    className,
    onFinish,
    uploadAttachmentFunction,
}: MessageAddFormProps) => {
    const isMobile = useIsMobile();

    return (
        <AppForm<MessageFormFields>
            name="messageAdd"
            form={form}
            maxWidth="none"
            withHeader={false}
            withOutsideSpreaders={false}
            isLoading={isLoading}
            className={className}
            onFinish={onFinish}
        >
            <SimpleTextAreaElement
                name={formFields.content}
                rules={[max2000LengthRule]}
                label={messagesTexts.messageContent}
                placeholder={messagesTexts.enterMessage}
                rows={4}
                resizable={false}
            />
            <StyledAttachmentsUploadElement
                uploadDirectory="MESSAGE"
                uploadAttachmentFunction={uploadAttachmentFunction}
            />
            <FormNavButtons
                justifyContent={isMobile ? 'center' : 'flex-end'}
                marginTop="marginNormal"
                marginBottom={0}
                buttonText={messagesTexts.sendMessage}
                leftIcon={<StyledSendIcon />}
            />
        </AppForm>
    );
};
