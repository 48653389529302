import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { SpecialJournalTopicAddPage, SpecialJournalTopicEditPage } from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from '../../components';

export const journalTopicRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.topics.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_TOPICS,
                }}
            >
                <SpecialJournalTopicAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.topics.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.topics.edit.pickedTopic,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_TOPICS,
                }}
            >
                <SpecialJournalTopicEditPage />
            </AbilityRoute>
        ),
    },
];
