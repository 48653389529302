import { appPaths } from 'consts/paths/paths';
import { commonTexts } from 'consts/text';
import { SimpleMenuItem } from 'components/atoms';
import { PermissionName } from 'types';

export const groupJournalMenuItems: Partial<Record<PermissionName, SimpleMenuItem>> = {
    GROUP_JOURNALS_ATTENDANCE: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.attendance.daily.base,
        label: commonTexts.menuItems.journalDetailsMenu.attendance,
    },
    GROUP_JOURNALS_SCHEDULE: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.base,
        label: commonTexts.menuItems.journalDetailsMenu.schedules,
    },
    GROUP_JOURNALS_CHILDREN_LIST_VIEW: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.children.base,
        label: commonTexts.menuItems.journalDetailsMenu.children,
    },
    GROUP_JOURNALS_TOPICS: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.topics.base,
        label: commonTexts.menuItems.journalDetailsMenu.topics,
    },
    GROUP_JOURNALS_EVENTS: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.base,
        label: commonTexts.menuItems.journalDetailsMenu.events,
    },
    GROUP_JOURNALS_CONSULTATIONS: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.consultations.base,
        label: commonTexts.menuItems.journalDetailsMenu.consultations,
    },
    GROUP_JOURNALS_INSPECTIONS: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.base,
        label: commonTexts.menuItems.journalDetailsMenu.inspections,
    },
    GROUP_JOURNALS_PSYCHOLOGICAL_HELP: {
        key: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base,
        label: commonTexts.menuItems.journalDetailsMenu.psychologicalHelps,
    },
};

export const specialJournalMenuItems: Partial<Record<PermissionName, SimpleMenuItem>> = {
    SPECIAL_JOURNALS_ATTENDANCE: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.daily.base,
        label: commonTexts.menuItems.journalDetailsMenu.attendance,
    },
    SPECIAL_JOURNALS_TIMETABLE_VIEW: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.lessons.base,
        label: commonTexts.menuItems.journalDetailsMenu.schedules,
    },
    SPECIAL_JOURNALS_CHILDREN_LIST_VIEW: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.children.base,
        label: commonTexts.menuItems.journalDetailsMenu.children,
    },
    SPECIAL_JOURNALS_TOPICS: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base,
        label: commonTexts.menuItems.journalDetailsMenu.topics,
    },
    SPECIAL_JOURNALS_CONSULTATIONS: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.base,
        label: commonTexts.menuItems.journalDetailsMenu.consultations,
    },
    SPECIAL_JOURNALS_INSPECTIONS: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base,
        label: commonTexts.menuItems.journalDetailsMenu.inspections,
    },
    SPECIAL_JOURNALS_QUALIFICATION_BASIS: {
        key: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base,
        label: commonTexts.menuItems.journalDetailsMenu.qualifications,
    },
};
