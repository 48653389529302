import { EmployeeBasicInfo } from 'types';
import { jsonObjectsTexts } from 'consts/text';

export const parseEmployeeBasicInfoIntoJsonObject: (
    employeeBasicInfo: EmployeeBasicInfo,
) => object = (employeeBasicInfo) => {
    const { firstName, lastName } = employeeBasicInfo;

    return {
        [jsonObjectsTexts.employeeBasicInfo.fields.firstname]: firstName,
        [jsonObjectsTexts.employeeBasicInfo.fields.lastname]: lastName,
    };
};
