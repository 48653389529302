import { AxiosError } from 'axios';
import { validatePolish } from 'validate-polish';
import { CommonFormProps } from 'types';
import { AppForm, AppFormProps } from 'components/organisms';
import {
    CityInputElement,
    FormNavButtons,
    SimpleInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { commonTexts, settingsTexts } from 'consts/text';
import { InvoiceEditFormFields } from 'types/forms/invoice';
import { formFields } from 'consts/form/formFields';
import { Col, Row } from 'antd';
import { nipNumberRegExp } from 'consts/regexps';

type InvoiceEditFormProps = Omit<
    AppFormProps<InvoiceEditFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (invoiceData: InvoiceEditFormFields) => void;
    };
export const InvoiceEditForm = ({ initialValues, axiosError, ...props }: InvoiceEditFormProps) => {
    const inspectionAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<InvoiceEditFormFields>
            name="invoiceEdit"
            title={settingsTexts.invoiceData.form.invoiceDataTitle}
            error={inspectionAddEditError}
            withGoBack
            {...props}
        >
            <SimpleInputElement
                name={formFields.name}
                label={settingsTexts.invoiceData.form.companyNameLabel}
            />
            <SimpleInputElement
                name={formFields.nip}
                label={settingsTexts.invoiceData.form.nipNumberLabel}
                rules={[
                    {
                        pattern: nipNumberRegExp,
                        message: commonTexts.errorMessages.invalidNipNumber,
                        required: true,
                    },
                    {
                        validator: (_, value) =>
                            validatePolish.nip(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                      new Error(commonTexts.errorMessages.invalidNipNumber),
                                  ),
                        message: commonTexts.errorMessages.invalidNipNumber,
                    },
                ]}
            />
            <StreetAddressInputElement />
            <Row style={{ gap: 15 }}>
                <Col flex={1}>
                    <ZipCodeInputElement />
                </Col>
                <Col flex={1}>
                    <CityInputElement />
                </Col>
            </Row>
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText="Zapisz"
                withDeviceFloppy
            />
        </AppForm>
    );
};
