import styled, { css } from 'styled-components';
import { AppCheckbox, AppList, AppListProps, AppModalButton } from 'components/atoms';
import { ChildWithParents } from 'types';

export const StyledCheckbox = styled(AppCheckbox)`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const StyledList = styled(AppList)<AppListProps<ChildWithParents>>`
    max-height: 500px;
    overflow: auto;
`;

export const StyledButton = styled(AppModalButton)`
    ${({ theme }) => css`
        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
        }
    `}
`;
