import { InstitutionId } from '../institution';

export type PaymentId = string & { readonly type: unique symbol };
export type OrderId = string & { readonly type: unique symbol };
export type ProductId = string & { readonly type: unique symbol };

export enum PaymentStatusEnum {
    'paid' = 'paid',
    'unpaid' = 'unpaid',
}

export type PaymentStatus = PaymentStatusEnum;

export enum BillType {
    INSTITUTION_BILL = 'INSTITUTION_BILL',
}

export type BillDTO = {
    id: PaymentId;
    institutionId: InstitutionId;
    paid: boolean;
    fee: number;
    date: string;
    paidAt: string;
    description: string;
    productId: ProductId;
    productType: BillType;
    totalAmount: number;
};

export enum PaymentMethod {
    PAYU = 'PAYU',
}

export enum PayuPaymentStatus {
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    EXPIRED = 'EXPIRED',
}

export type PaymentDto = {
    id: PaymentId;
    orderId: OrderId;
    productId: ProductId;
    customerIp: string;
    totalPriceAsMinor: number;
    productType: BillType;
    institutionId: InstitutionId;
    paymentMethod: PaymentMethod;
    description: string;
    status: PayuPaymentStatus;
    totalPrice: number;
    redirectUri: string;
    creationDateTime: string;
};
