import { AppCheckbox, AppCheckboxGroup, AppFormItem, AppText } from 'components/atoms';
import { childMealTypes } from 'consts/child/childMealTypes';
import { childrenTexts } from 'consts/text';
import { ChildMealType } from 'types';

type ChildrenMealSelectionProps = {
    meals: {
        breakfastPrice?: number | null;
        snackPrice?: number | null;
        soupPrice?: number | null;
        dinnerPrice?: number | null;
    };
};

export const ChildrenMealSelection = ({ meals }: ChildrenMealSelectionProps) => {
    const mealTypes: {
        label: string;
        name: ChildMealType;
        disabled: boolean;
    }[] = [
        {
            label: childrenTexts.childDetails.additionalInfo.dietMeals.breakfast,
            name: childMealTypes.breakfast,
            disabled: meals.breakfastPrice === null,
        },
        {
            label: childrenTexts.childDetails.additionalInfo.dietMeals.soup,
            name: childMealTypes.soup,
            disabled: meals.soupPrice === null,
        },
        {
            label: childrenTexts.childDetails.additionalInfo.dietMeals.dinner,
            name: childMealTypes.dinner,
            disabled: meals.dinnerPrice === null,
        },
        {
            label: childrenTexts.childDetails.additionalInfo.dietMeals.snack,
            name: childMealTypes.snack,
            disabled: meals.snackPrice === null,
        },
    ];

    return (
        <>
            <AppText textType="BodyMSSemiBold" margin={0} marginBottom={12}>
                {childrenTexts.childDetails.additionalInfo.dietMeals.title}
            </AppText>
            <AppFormItem name="selectMealsType" marginBottom={12}>
                <AppCheckboxGroup>
                    {mealTypes.map((meal) => (
                        <AppCheckbox key={meal.name} value={meal.name} disabled={meal.disabled}>
                            {meal.label}
                        </AppCheckbox>
                    ))}
                </AppCheckboxGroup>
            </AppFormItem>
        </>
    );
};
