import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd';
import { AppMenu, MenuItem } from 'components/atoms';
import { useAuth } from 'services/firebase/useAuth';
import { LOGOUT_PATH } from 'consts/paths/paths';
import { getAccountManageItemKey } from './AccountManageMenu.utils';

type AccountManageMenuProps = {
    menuItems: MenuItem[];
};

export const AccountManageMenu = ({ menuItems }: AccountManageMenuProps) => {
    const { signUserOut } = useAuth();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const onSelect: MenuProps['onSelect'] = ({ key }) => {
        if (key === LOGOUT_PATH) {
            signUserOut();
        } else {
            navigate(key, { replace: true });
        }
    };

    const selectedAccountManageItemKey = useMemo(() => {
        const selectedAccountManageItemData = menuItems.find((accountManageItemData) => {
            const accountManageItemKey = getAccountManageItemKey(accountManageItemData);
            return accountManageItemKey ? pathname.includes(accountManageItemKey) : false;
        });
        if (!selectedAccountManageItemData) {
            return '';
        }
        return getAccountManageItemKey(selectedAccountManageItemData) || '';
    }, [menuItems, pathname]);

    return (
        <AppMenu
            mode="inline"
            selectedKeys={[selectedAccountManageItemKey]}
            onSelect={onSelect}
            items={menuItems}
        />
    );
};
