import { AppFormSummary } from 'components/organisms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { redirectToMailAgent } from 'utils/redirectToMailAgent';
import { CONTACT_EMAIL } from 'consts/contactEmail';
import { authTexts } from 'consts/text/index';

export const PasswordChangeFinish = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const isFirstTimeLoginMode = params.get('isFirstTimeLoginMode') === 'true';
    const navigateToSignIn = () => navigate(appPaths.auth['sign-in']);

    const title = isFirstTimeLoginMode
        ? authTexts.successfullySetPasswordMessage
        : authTexts.successfullyChangedPasswordMessage;

    return (
        <AppFormSummary
            title={title}
            subTitle="Możesz się zalogować z użyciem nowego hasła."
            buttonText={authTexts.loginButtonLabel}
            onClick={navigateToSignIn}
            overLinkText="Potrzebujesz pomocy?"
            linkButton="Skontaktuj się z nami"
            onLinkButtonClick={() => redirectToMailAgent(CONTACT_EMAIL)}
        />
    );
};
