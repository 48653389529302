import type { ModuleData, ParentAppModules } from 'types';
import {
    MenuModuleIcon,
    PeopleModuleIcon,
    SettlementModuleIcon,
    TimetableModuleIcon,
} from 'assets';
import { appPaths } from 'consts/paths/paths';
import { PARENT_MODULE_NAMES } from 'consts/modules/moduleNames';
import { MessagesModuleIconWithUnreadMessageIndicator } from 'components/organisms/MessagesModuleIconWithUnreadMessageIndicator/MessagesModuleIconWithUnreadMessageIndicator';

export const PARENT_APP_MODULES = {
    PARENT_NEWS: 'PARENT_NEWS',
    PARENT_CHILD_PROFILE: 'PARENT_CHILD_PROFILE',
    // TODO: to change to PARENT_MENU
    PARENT_DIET: 'PARENT_DIET',
    PARENT_SETTLEMENTS: 'PARENT_SETTLEMENTS',
    PARENT_MESSAGES: 'PARENT_MESSAGES',
} as const;

export type ParentModulesData = Record<ParentAppModules, ModuleData>;

export const PARENT_MODULES_DATA: ParentModulesData = {
    PARENT_NEWS: {
        name: PARENT_MODULE_NAMES.PARENT_NEWS,
        Icon: <TimetableModuleIcon />,
        path: appPaths.app.parent.news.base,
    },
    PARENT_CHILD_PROFILE: {
        name: PARENT_MODULE_NAMES.PARENT_CHILD_PROFILE,
        Icon: <PeopleModuleIcon />,
        path: appPaths.app.parent['child-profile'].base,
    },
    PARENT_DIET: {
        name: PARENT_MODULE_NAMES.PARENT_DIET,
        Icon: <MenuModuleIcon />,
        path: appPaths.app.parent.menu,
    },
    PARENT_SETTLEMENTS: {
        name: PARENT_MODULE_NAMES.PARENT_SETTLEMENTS,
        Icon: <SettlementModuleIcon />,
        path: appPaths.app.parent.settlement.base,
    },
    PARENT_MESSAGES: {
        name: PARENT_MODULE_NAMES.PARENT_MESSAGES,
        alternativeName: 'Panel korespondencji',
        Icon: <MessagesModuleIconWithUnreadMessageIndicator />,
        path: appPaths.app.parent.messages.base,
    },
};
