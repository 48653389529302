import { PrincipalDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';

export const parsePrincipalDTOIntoJsonObject: (principalDTO: PrincipalDTO) => object = (
    principalDTO,
) => {
    const { name, employee } = principalDTO;

    if (!employee) {
        return {
            [jsonObjectsTexts.principalDTO.fields.name]: name,
        };
    }

    const { firstName: employeeFirstName, lastName: employeeLastName } = employee;

    const employeeJsonObject = {
        [jsonObjectsTexts.principalDTO.innerObjects.principalEmployeeDTO.fields.firstname]:
            employeeFirstName,
        [jsonObjectsTexts.principalDTO.innerObjects.principalEmployeeDTO.fields.lastname]:
            employeeLastName,
    };

    return {
        [jsonObjectsTexts.principalDTO.fields.employee]: employeeJsonObject,
    };
};
