import styled from 'styled-components';
import { AppList } from 'components/atoms';

type GroupLabelListItemProps = {
    label: string;
};

const StyledListItem = styled(AppList.Item)`
    &.ant-list-item,
    .ant-list-bordered &.ant-list-item {
        padding: 12px;
    }
`;

export const GroupLabelListItem = ({ label }: GroupLabelListItemProps) => (
    <StyledListItem>{label}</StyledListItem>
);
