import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { Details } from 'components/organisms/';
import { commonTexts } from 'consts/text/index';
import { appPaths } from 'consts/paths/paths';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { AppParagraph } from 'components/atoms/';
import styled, { css } from 'styled-components';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { getTextStyle } from 'utils/getTextStyle';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { useCurrentChild } from 'jotaiAtoms/currentChild';

const AccountSubtitles = styled(AppParagraph)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.primary10};
        ${getTextStyle('BodyMediumSemiBold')}
        margin: 0 0 -12px 0 !important;
        padding: 0;
    `}
`;

export const ParentAccountDetailsPage = () => {
    const { id: currentChildId } = useCurrentChild();

    const { data: parentProfileData, isFetching: isParentProfileDataFetching } = useAppQuery(
        'PARENT_PROFILE',
        [currentChildId],
        () => ParentHTTPService.children.parentProfile.getParentProfile(currentChildId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title={commonTexts.otherLabels.yourData}
            isLoading={isParentProfileDataFetching}
            editPagePath={appPaths.app.parent.account.details.edit}
            isVisibleGoBackButton={false}
        >
            <AccountSubtitles>{commonTexts.dataLabels.contactData}</AccountSubtitles>
            <Details.Item title={commonTexts.dataLabels.firstName}>
                {parentProfileData?.firstName}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.lastName}>
                {parentProfileData?.lastName}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.email}>
                {parentProfileData?.email}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.phoneNumber}>
                {parentProfileData?.phoneNumber
                    ? parsePhoneNumberIntoFormattedPhoneNumber(parentProfileData.phoneNumber)
                    : EMPTY_VALUE_LABEL}
            </Details.Item>
            <AccountSubtitles>{commonTexts.dataLabels.settlement}</AccountSubtitles>
            <Details.Item title={commonTexts.dataLabels.bankAccountNr}>
                {parentProfileData?.bankAccountNumber
                    ? parseBankAccountNumberIntoFormattedBankAccountNumber(
                          parentProfileData.bankAccountNumber,
                      )
                    : EMPTY_VALUE_LABEL}
            </Details.Item>
            <AccountSubtitles>{commonTexts.dataLabels.address}</AccountSubtitles>
            <Details.Item title={commonTexts.dataLabels.streetAddress}>
                {parentProfileData?.address?.street
                    ? parentProfileData?.address?.street
                    : EMPTY_VALUE_LABEL}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.zipCode}>
                {parentProfileData?.address?.zipCode
                    ? parentProfileData?.address?.zipCode
                    : EMPTY_VALUE_LABEL}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.city}>
                {parentProfileData?.address?.city
                    ? parentProfileData?.address?.city
                    : EMPTY_VALUE_LABEL}
            </Details.Item>
        </Details>
    );
};
