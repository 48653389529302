import { useSearchParams } from 'react-router-dom';
import {
    DEFAULT_PAGE,
    DEFAULT_PAGE_SIZE,
    PAGE_PARAM_KEY,
    PAGE_SIZE_PARAM_KEY,
} from 'consts/pagination';

export const useSearchParamsBasedPagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const currentPage = searchParams.get(PAGE_PARAM_KEY) || DEFAULT_PAGE;
    const currentPageSize = searchParams.get(PAGE_SIZE_PARAM_KEY) || DEFAULT_PAGE_SIZE;

    const handlePaginationValuesChange = (page: number, pageSize: number) => {
        const newPageSize = pageSize.toString();
        const newPage = (page - 1).toString();
        const replace = currentPage === newPage;

        setSearchParams(
            (prevSearchParams) => {
                prevSearchParams.set(PAGE_PARAM_KEY, newPage);
                prevSearchParams.set(PAGE_SIZE_PARAM_KEY, newPageSize);
                return prevSearchParams;
            },
            { replace },
        );
    };

    return {
        currentPage,
        currentPageSize,
        handlePaginationValuesChange,
    };
};
