import { ChildDiscountsIcon } from 'assets';
import { childrenTexts } from 'consts/text';
import { ChildId } from 'types';
import { DiscountsTable } from 'components/templates/discounts/DiscountsTable/DiscountsTable';
import { useParams } from 'react-router-dom';
import { ComponentBlockingAppLoader, DetailsFrameBase } from 'components/atoms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const ParentDiscounts = () => {
    const { childId } = useParams<{ childId: ChildId }>();

    const {
        data: discountData,
        isFetching,
        isError,
    } = useAppQuery('CHILD_DISCOUNTS', [childId], ParentHTTPService.discounts.getDiscounts, {
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    if (!discountData?.length || isError) {
        return null;
    }

    return (
        <DetailsFrameBase
            icon={<ChildDiscountsIcon />}
            title={childrenTexts.childDetails.discounts.discounts}
            noFrame
            smallHeaderMargin
        >
            {!!discountData?.length && !isError && (
                <DiscountsTable discounts={discountData} isError={isError} />
            )}
            {isFetching && <ComponentBlockingAppLoader />}
        </DetailsFrameBase>
    );
};
