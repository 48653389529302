import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useMatch } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { EmployeeAddEditForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { EmployeeFormFields, EmployeeId } from 'types';
import { ErrorDisplay } from 'components/molecules';
import { useEffect } from 'react';
import { employeeUserFields } from 'consts/tables/employee/employeeUserFields';
import { parseEmployeeUserIntoAddEmployeeUserForm } from 'utils/parsers/employee/parseEmployeeUserIntoAddEmployeeUserForm';
import { parseEmployeeAddFormDataIntoEmployeeUpdateDTO } from 'utils/parsers/employee/parseEmployeeAddFormDataIntoEmployeeUpdateDTO';
import { employeeTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const EmployeeEdit = () => {
    const { form, formInitialData, setFormData } = useEditForm<EmployeeFormFields>();
    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();
    const params = useMatch(appPaths.app.employees.edit.pickedEmployee);
    const employeeId = (params?.params.employeeId || '') as EmployeeId;

    const {
        data,
        isFetching: isUserFetching,
        isError,
    } = useAppQuery(
        'EMPLOYEE',
        [employeeId],
        () => StaffHTTPService.employees.getEmployee(employeeId),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (data && !form.getFieldValue(employeeUserFields.firstName)) {
            setFormData(parseEmployeeUserIntoAddEmployeeUserForm(data));
        }
    }, [data, form, setFormData]);

    const onSuccess = () => {
        displaySuccess(employeeTexts.successfullyUpdatedEmployee);
        statefulNavigate(appPaths.app.employees.base, { replace: true });
    };

    const {
        mutate,
        error,
        isLoading: isMutationLoading,
    } = useAppMutation(StaffHTTPService.employees.updateEmployee, {
        key: ['EMPLOYEE', employeeId],
        onSuccess,
        invalidateQueryKeys: [
            ['EMPLOYEES'],
            ['EMPLOYEE', employeeId],
            ['GROUPS_EMPLOYEES'],
            ['JOURNALS_EMPLOYEES'],
            ['INSTITUTIONS_EMPLOYEES'],
            ['LESSONS_EMPLOYEES'],
            ['MAIL_THREAD_RECEIVERS'],
            ['JOURNALS_TEACHERS'],
            ['STAFF'],
            ['USER_DETAILS'],
        ],
    });

    const onFinish = (employeeData: EmployeeFormFields) =>
        mutate({
            employeeId,
            employeeUserDTO: parseEmployeeAddFormDataIntoEmployeeUpdateDTO(employeeData),
        });

    if (!employeeId) {
        statefulNavigate(appPaths.app.employees.base);
    }

    if (isError) {
        return <ErrorDisplay />;
    }

    return (
        <EmployeeAddEditForm
            form={form}
            initialValues={formInitialData}
            type="edit"
            axiosError={error}
            isLoading={isMutationLoading || isUserFetching}
            onFinish={onFinish}
        />
    );
};
