import { TableTagDisplay } from 'components/molecules/Table/TableTagDisplay/TableTagDisplay';
import { tagColorForTeacherAbsentType } from 'consts/attendance/tagColorForTeacherAbsentType';
import { teacherAbsentTypesLabels } from 'consts/attendance/teacherAbsentTypesLabels';

type TeacherAttendanceTypeDisplayProps = {
    isAbsent: boolean | null;
};

export const TeacherAttendanceTypeDisplay = ({ isAbsent }: TeacherAttendanceTypeDisplayProps) =>
    isAbsent && (
        <TableTagDisplay
            value="ABSENT"
            labelMapper={teacherAbsentTypesLabels}
            colorMapper={tagColorForTeacherAbsentType}
        />
    );
