import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { RoleId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { settingsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const useRolesAndPermissionsEditPageNetworkManage = (roleId: RoleId) => {
    const { displaySuccess, displayError } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const {
        data: roleDetails,
        isFetching: isRoleFetching,
        isError,
    } = useAppQuery('ROLE', [roleId], () => StaffHTTPService.roles.getRoleDetails(roleId), {
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { isLoading: isRoleUpdating, mutate: updateRole } = useAppMutation(
        StaffHTTPService.roles.updateRole,
        {
            onSuccess: () => {
                statefulNavigate(appPaths.app.settings['roles-and-permissions'].base);
                displaySuccess(settingsTexts.rolesAndPermissions.role.edited);
            },
            onError: () => displayError(settingsTexts.rolesAndPermissions.role.editingError),
            key: ['ROLE_UPDATE', roleId],
            invalidateQueryKeys: [['ROLES'], ['ROLE', roleId], ['USER_DETAILS']],
        },
    );

    return {
        roleDetails,
        isRoleFetching,
        isError,
        updateRole,
        isRoleUpdating,
    };
};
