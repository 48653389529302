import { SpecialConsultationFormFields } from 'types';
import { AddUpdateSpecialConsultationDTO } from 'types/specialConstultation/specialConsultationDTO';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseSpecialConsultationFormFieldsIntoAddUpdateSpecialConsultationDTO: (
    groupConsultationFormFields: SpecialConsultationFormFields,
) => AddUpdateSpecialConsultationDTO = (groupConsultationFormFields) => ({
    subject: groupConsultationFormFields.subject,
    description: groupConsultationFormFields.description,
    date: parseDayjsDateIntoString(groupConsultationFormFields.date, 'YYYY-MM-DD'),
    contactWith: groupConsultationFormFields.contactWith,
    childrenArray: groupConsultationFormFields.childIds,
});
