import { Typography } from 'antd';
import styled, { css } from 'styled-components';
import { TextProps } from 'antd/lib/typography/Text';
import { MarginsProps } from 'types/components/margins';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { getTextStyle, TextType } from 'utils/getTextStyle';

const { Text } = Typography;

export type AppTextProps = TextProps &
    MarginsProps & {
        textType?: TextType;
        color?: string;
    };

const filteredProps = marginPropsToFilter.concat(['textType', 'color']);

export const AppStyledText = styled(Text).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppTextProps>`
    ${({ theme, margin = 10, color, textType = 'BodyMedium', ...props }) => css`
        letter-spacing: -0.48px;
        line-height: 120%;
        color: ${color};

        ${getTextStyle(textType)}

        &.ant-typography {
            ${getMarginsCss({ margin, ...props })}
        }

        &.ant-typography-disabled {
            color: ${theme.colors.grayscale.gray7};
        }
    `};
`;

export const AppText = ({ textType = 'BodyMedium', ...props }: AppTextProps) => (
    <AppStyledText textType={textType} {...props} />
);
