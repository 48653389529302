import { formFields } from 'consts/form/formFields';
import { ChildParent } from 'types';
import { AppFormItem, AppFormItemProps } from 'components/atoms';
import { ParentAndGuardianFormItemContentContainer } from 'components/molecules';
import { MAX_PARENTS_DATA_ITEMS_COUNT } from 'consts/child/generalValues';
import { ParentTable } from '../ParentTable/ParentTable';

type ParentFormElementProps = AppFormItemProps & {
    parentsTableItems: ChildParent[];
    onAddParentClick: () => void;
    onParentRemove: (parentTableItem: ChildParent) => void;
    onParentTableItemEditClick: (parentTableItem: ChildParent) => void;
};

export const ParentFormElementWithTable = ({
    parentsTableItems,
    onAddParentClick,
    onParentRemove,
    onParentTableItemEditClick,
    ...props
}: ParentFormElementProps) => {
    const tableElement = parentsTableItems.length ? (
        <ParentTable
            parents={parentsTableItems}
            canManageTable
            onEditParentClick={onParentTableItemEditClick}
            onRemoveParentClick={onParentRemove}
        />
    ) : null;

    return (
        <AppFormItem
            name={formFields.parents}
            label={<AppFormItem.Label mainText="Rodzice" additionalText="min. 1" />}
            {...props}
        >
            <ParentAndGuardianFormItemContentContainer
                addButtonText="Dodaj rodzica"
                onAddButtonClick={onAddParentClick}
                tableElement={tableElement}
                isInvisibleAddButton={parentsTableItems.length === MAX_PARENTS_DATA_ITEMS_COUNT}
            />
        </AppFormItem>
    );
};
