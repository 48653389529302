import styled, { css } from 'styled-components';
import { TextContainer } from 'components/atoms/index';

export const StyledTextContainer = styled(TextContainer)`
    ${({ theme }) => css`
        height: 400px;
        padding-right: 5px;
        overflow: auto;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            height: auto;
            padding-right: 0;
        }
    `}
`;
