import { ChildId, GroupId } from 'types';
import { parseChildIdIntoChildTreePickerKey } from 'utils/parsers/common/parseChildIdIntoChildTreePickerKey';

type Group = { groupId: GroupId; children: { childId: ChildId }[] };

export const getChildrenTreePickerKeys = (childIds: ChildId[], groups: Group[]) => {
    const parsedChildrenTreePickerKeys = childIds.map((childId) =>
        parseChildIdIntoChildTreePickerKey(childId, groups),
    );
    return parsedChildrenTreePickerKeys.filter(
        (parsedChildTreePickerKey): parsedChildTreePickerKey is string =>
            !!parsedChildTreePickerKey,
    );
};
