import type { AdminAppModules, ModuleData } from 'types';
import { SchoolIcon } from 'assets';
import { appPaths } from 'consts/paths/paths';
import { ADMIN_MODULE_NAMES } from 'consts/modules/moduleNames';

export const ADMIN_APP_MODULES = {
    ADMIN_INSTITUTIONS: 'ADMIN_INSTITUTIONS',
} as const;

export type AdminModulesData = Record<AdminAppModules, ModuleData>;

export const ADMIN_MODULES_DATA: AdminModulesData = {
    ADMIN_INSTITUTIONS: {
        name: ADMIN_MODULE_NAMES.ADMIN_INSTITUTIONS,
        Icon: < SchoolIcon />,
        path: appPaths.app.admin.institutions.base,
    },
};
