import { colors } from 'theme/styledComponents/colors';
import { TextType } from 'utils/getTextStyle';
import { AppParagraph } from '../../CommonAppComponents';

type DualValueCellContentProps = {
    mainValue: string | React.ReactNode;
    secondaryValue: string;
    textType?: {
        mainValue: TextType;
        secondaryValue: TextType;
    };
};

export const DualValueCellContent = ({
    mainValue,
    secondaryValue,
    textType = {
        mainValue: 'BodyMedium',
        secondaryValue: 'BodySmall',
    },
}: DualValueCellContentProps) => (
    <>
        <AppParagraph margin={0} textType={textType.secondaryValue} color={colors.grayscale.gray9}>
            {secondaryValue}
        </AppParagraph>
        <AppParagraph margin={0} textType={textType.mainValue}>
            {mainValue}
        </AppParagraph>
    </>
);
