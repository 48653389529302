import { EventType, SelectOptionType } from 'types';
import { FlagIcon, TrainIcon, UsersWithPlusIcon } from 'assets';
import { journalTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { LabelContainer } from './EventTypeSelect.styled';

export const getEventsPagePathToRedirect = (eventType: EventType) => {
    switch (eventType) {
        case 'IMPORTANT_EVENT':
            return appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents
                .base;
        case 'TRIP':
            return appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.base;
        case 'PARENT_TEACHER_CONFERENCE':
            return appPaths.app.journals.groupJournalDetails.pickedJournal.events
                .parentTeacherConferences.base;
        default:
            return appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents
                .base;
    }
};

export const eventOptions: SelectOptionType<EventType>[] = [
    {
        label: (
            <LabelContainer>
                {<FlagIcon />}
                {journalTexts.journalDetails.groupJournal.events.importantEventsLabel}
            </LabelContainer>
        ),
        value: 'IMPORTANT_EVENT',
    },
    {
        label: (
            <LabelContainer>
                {<TrainIcon />}
                {journalTexts.journalDetails.groupJournal.events.tripsEventsLabel}
            </LabelContainer>
        ),
        value: 'TRIP',
    },
    {
        label: (
            <LabelContainer>
                {<UsersWithPlusIcon />}
                {
                    journalTexts.journalDetails.groupJournal.events
                        .parentTeacherConferencesEventsLabel
                }
            </LabelContainer>
        ),
        value: 'PARENT_TEACHER_CONFERENCE',
    },
];
