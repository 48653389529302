import { InstitutionAddEditFormFields } from 'types/forms/institution';
import { InstitutionCreateData } from '../../../types';

export const parseInstitutionAddFormFieldsIntoInstitutionCreateData: (
    institutionAddFormFields: InstitutionAddEditFormFields,
) => InstitutionCreateData = (institutionAddFormFields) => ({
    regon: institutionAddFormFields.regonNumber,
    name: institutionAddFormFields.institutionName,
    rspo: institutionAddFormFields.rspoNumber,
    street: institutionAddFormFields.street,
    zipCode: institutionAddFormFields.zipCode,
    city: institutionAddFormFields.city,
    publicInstitution: institutionAddFormFields.institutionAffiliation === 'public',
    type: institutionAddFormFields.institutionType,
});
