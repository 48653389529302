import { AppParagraph } from 'components/atoms';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const BankAccountNumberDisplay = ({ bankAccountNumber }: { bankAccountNumber?: string }) => {
    if (!bankAccountNumber) {
        return EMPTY_VALUE_LABEL;
    }

    const formattedBankAccountNumber =
        parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber);
    const firstLine = formattedBankAccountNumber.substring(0, 17);
    const secondLine = formattedBankAccountNumber.substring(18, formattedBankAccountNumber.length);

    return (
        <>
            <AppParagraph margin={0}>{firstLine}</AppParagraph>
            <AppParagraph margin={0}>{secondLine}</AppParagraph>
        </>
    );
};
