import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';

export const FiltersContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            align-items: stretch;
            flex-direction: column;
        }
    `}
`;

export const ReversedRow = styled(Row)`
    ${({ theme }) => css`
        @media ${theme.sizes.mediaQueries.mobileMax} {
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
        }
    `}
`;

export const StyledColumn = styled(Col)`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const StyledRow = styled(Row)`
    align-items: center;
    margin-left: 32px;
`;
