import { generatePath, useSearchParams } from 'react-router-dom';
import { appPaths, appRoutes } from 'consts/paths/paths';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import { AppGoBackButton } from 'components/atoms';
import { Header } from 'components/templates/child/ChildDetails/components/Header/Header';
import {
    DobleRow,
    GridItem,
    ParentGrid,
} from 'components/templates/child/ChildDetails/InfoContainerBase.styled';
import {
    AdditionalInformation,
    Agreements,
    PersonalData,
    Settlements,
} from 'components/templates/child/ChildDetails/components';
import { ChildSettlementDetails } from 'types';
import { useEffect } from 'react';
import { getFullName } from 'utils/getFullName';
import { AddressData, ErrorDisplay, LoadingDisplay } from 'components/molecules';
import { ParentGuardians } from 'components/templates/child/ChildDetails/components/Guardians/ParentGuardians/ParentGuardians';
import { ParentDiscounts } from 'components/templates/child/ChildDetails/components/Discounts/ParentDiscounts/ParentDiscounts';
import parentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { useChildDetailsPageNetworkManage } from 'pages/app/parent/child/hooks/useChildDetailsPageNetworkManage';
import { childrenTexts } from 'consts/text';
import { ChildAddressDataIcon } from 'assets';

export const ParentChildDetailsPage = () => {
    const { id: childId } = useCurrentChild();
    const childEditPagePath = generatePath(appPaths.app.parent['child-profile'].edit, {
        childId,
    });
    const [_, setSearchParams] = useSearchParams();
    const pageRoute = appRoutes.app.parent['child-profile'].base;

    const {
        isChildDetailsFetching,
        isChildDetailsError,
        childDetailsData,
        isSettlementChildDetailsFetching,
        isSettlementChildDetailsError,
        settlementChildDetails,
        isDietDetailsFetching,
        isDietDetailsError,
        dietDetails,
    } = useChildDetailsPageNetworkManage();

    useEffect(() => {
        if (!childDetailsData) {
            return;
        }
        const params = new URLSearchParams(window.location.search);
        params.set(pageRoute, getFullName(childDetailsData.firstName, childDetailsData.lastName));
        setSearchParams(params, { replace: true });
    }, [childDetailsData, pageRoute, setSearchParams]);

    if (isChildDetailsFetching || isSettlementChildDetailsFetching || isDietDetailsFetching) {
        return <LoadingDisplay />;
    }

    if (
        isDietDetailsError ||
        !childDetailsData ||
        isSettlementChildDetailsError ||
        isChildDetailsError
    ) {
        return <ErrorDisplay />;
    }

    const childDetailsWithDietDetails = { ...childDetailsData, dietDetails };

    return (
        <div>
            <AppGoBackButton />
            <Header
                {...childDetailsData}
                childEditPagePath={childEditPagePath}
                canManageChildAvatar
                httpMethod={{
                    ...parentHTTPService.files,
                    ...parentHTTPService.children.profilePhoto,
                }}
            />
            <ParentGrid>
                <GridItem>
                    <PersonalData {...childDetailsData} />
                </GridItem>
                <GridItem>
                    <AddressData
                        heading={childrenTexts.childDetails.addressData.addressData}
                        icon={<ChildAddressDataIcon />}
                        {...childDetailsData}
                    />
                </GridItem>
                <GridItem width={7}>
                    <AdditionalInformation {...childDetailsWithDietDetails} />
                </GridItem>
                <GridItem width={7}>
                    <Settlements {...(settlementChildDetails as ChildSettlementDetails)} />
                </GridItem>
                <DobleRow>
                    <Agreements {...childDetailsData} />
                </DobleRow>
                <GridItem width={9}>
                    <ParentDiscounts />
                </GridItem>
                <GridItem width={6}>
                    <ParentGuardians {...childDetailsData} />
                </GridItem>
            </ParentGrid>
        </div>
    );
};
