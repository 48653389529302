import { generatePath, useParams } from 'react-router-dom';
import { Details } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ImportantEventId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { importantEventDTOFields } from 'consts/importantEvent/importantEventDTOFields';
import { commonTexts, journalTexts } from 'consts/text';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const GroupJournalImportantEventDetailsPage = () => {
    const { journalId } = useJournalId();
    const { importantEventId: importantEventIdParam } = useParams();
    const {
        groupJournalsAbilities: { checkIfCanManageEvents },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageEvents = checkIfCanManageEvents();
    const importantEventId = importantEventIdParam as ImportantEventId;
    const editPagePath = generatePath(
        appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.edit
            .pickedImportantEvent,
        { importantEventId, journalId },
    );

    const {
        data: importantEventData,
        isFetching: isImportantEventDataFetching,
        isError: isFetchImportantEventDataError,
    } = useAppQuery(
        'IMPORTANT_EVENT',
        [journalId, importantEventId],
        () => StaffHTTPService.importantEvents.getImportantEvent(journalId, importantEventId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title={
                journalTexts.journalDetails.groupJournal.events.importantEvents
                    .importantEventDetails
            }
            isLoading={isImportantEventDataFetching}
            isError={isFetchImportantEventDataError}
            editPagePath={canManageEvents ? editPagePath : undefined}
        >
            <Details.Item
                title={journalTexts.journalDetails.groupJournal.events.importantEvents.eventName}
            >
                {importantEventData?.[importantEventDTOFields.name]}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.date}>
                {importantEventData &&
                    parseStringIntoFormattedStringDate(
                        importantEventData[importantEventDTOFields.date],
                    )}
            </Details.Item>
            <Details.Item
                title={
                    journalTexts.journalDetails.groupJournal.events.importantEvents.eventDescription
                }
            >
                {importantEventData?.[importantEventDTOFields.description]}
            </Details.Item>
        </Details>
    );
};
