import { useTheme } from 'styled-components';
import { Details } from 'components/organisms';
import { appPaths } from 'consts/paths/paths';
import { psychologicalHelpBasesLabels } from 'consts/psychologicalHelp/psychologicalHelpBasesLabels';
import { commonTexts, journalTexts } from 'consts/text';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { generatePath } from 'react-router-dom';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { getFullName } from 'utils/getFullName';
import { AppList } from 'components/atoms';
import { AttachmentListItem } from 'components/molecules';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useQualificationId } from '../hooks/useQualificationId/useQualificationId';

export const SpecialJournalQualificationDetails = () => {
    const { journalId } = useJournalId();
    const { qualificationId } = useQualificationId();
    const theme = useTheme();
    const {
        specialJournalsAbilities: { checkIfCanManageQualificationBasis },
    } = useJournalAbilities();

    const {
        data: qualificationData,
        isFetching: isQualificationDataFetching,
        isError: isFetchQualificationDataError,
    } = useAppQuery(
        'QUALIFICATIONS_BASE',
        [journalId, qualificationId],
        () => StaffHTTPService.qualifications.getQualification(journalId, qualificationId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const canManageQualificationBasis = checkIfCanManageQualificationBasis();
    const isVisibleAttachmentsList = !!qualificationData?.attachments.length;
    const editPagePath = generatePath(
        appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.edit
            .pickedQualification,
        { qualificationId, journalId },
    );
    const navigateState = {
        childId: qualificationData?.child.id,
    };
    const childFullName = qualificationData
        ? getFullName(qualificationData.child.firstName, qualificationData.child.lastName)
        : undefined;

    return (
        <Details
            title={
                journalTexts.journalDetails.specialJournal.qualifications.previewQualificationTitle
            }
            isLoading={isQualificationDataFetching}
            isError={isFetchQualificationDataError}
            editPagePath={canManageQualificationBasis ? editPagePath : undefined}
            editPageNavigateState={navigateState}
        >
            <Details.Item
                title={
                    journalTexts.journalDetails.specialJournal.qualifications
                        .qualificationFullNameInputLabel
                }
            >
                {childFullName}
            </Details.Item>

            <Details.Item
                title={
                    journalTexts.journalDetails.common.psychologicalHelp.basisForPsychologicalHelp
                }
            >
                {qualificationData && psychologicalHelpBasesLabels[qualificationData.basis]}
            </Details.Item>

            <Details.Item title={commonTexts.dataLabels.description}>
                {qualificationData?.description}
            </Details.Item>
            <Details.Item
                title={
                    journalTexts.journalDetails.specialJournal.qualifications
                        .individualTherapyPlanInputLabel
                }
            >
                {qualificationData?.therapyProgram}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.comments}>
                {qualificationData?.notes}
            </Details.Item>
            <Details.Item
                title={
                    journalTexts.journalDetails.specialJournal.qualifications
                        .analysisOfProgressInputLabel
                }
            >
                {qualificationData?.progressAnalysis}
            </Details.Item>
            {isVisibleAttachmentsList && (
                <Details.Item title={commonTexts.dataLabels.documents}>
                    <AppList
                        dataSource={qualificationData.attachments}
                        marginTop={theme.sizes.marginNormal}
                        renderItem={(fileData) => (
                            <AttachmentListItem key={fileData.fileUri} fileData={fileData} />
                        )}
                    />
                </Details.Item>
            )}
        </Details>
    );
};
