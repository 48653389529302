import { AppText, AppTextProps } from 'components/atoms/CommonAppComponents';

export type MessageMemberNameProps = {
    authorName: string;
    authorSubname: string | null;
    baseTextProps?: Pick<AppTextProps, 'textType' | 'color'>;
    subnameTextProps?: Pick<AppTextProps, 'textType' | 'color'>;
    withComma?: boolean;
    className?: string;
};

export const MessageMemberName = ({
    authorName,
    authorSubname,
    baseTextProps,
    subnameTextProps,
    withComma,
    className,
}: MessageMemberNameProps) => (
    <AppText
        textType={baseTextProps?.textType}
        margin={0}
        color={baseTextProps?.color}
        className={className}
    >
        {authorName}
        {authorSubname && (
            <AppText
                textType={subnameTextProps?.textType}
                margin={0}
                color={subnameTextProps?.color}
            >
                {` (${authorSubname})`}
            </AppText>
        )}
        {withComma && ', '}
    </AppText>
);
