import { DayAttendanceAbsentType, DayAttendanceRemote, LabelMapper } from 'types';
import { journalTexts } from 'consts/text';

export const dayAttendanceAbsentTypesLabels: LabelMapper<
    DayAttendanceAbsentType | DayAttendanceRemote
> = {
    ABSENT: journalTexts.journalDetails.groupJournal.attendance.daily.notReportedAbsentShort,
    ABSENT_PLANNED: journalTexts.journalDetails.groupJournal.attendance.daily.plannedAbsentShort,
    REMOTE: journalTexts.journalDetails.groupJournal.attendance.daily.remoteAbsent,
};
