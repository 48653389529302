import { GroupTopicDTO, GroupTopicFormFields } from 'types';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseGroupTopicDTOIntoGroupTopicFormFields: (
    topicDTO: GroupTopicDTO,
) => GroupTopicFormFields = (topicDTO) => ({
    name: topicDTO.name,
    description: topicDTO.description,
    teacherIds: topicDTO.teachers.map((teacher) => teacher.id),
    timeFrom: parseStringIntoDayjsTime(topicDTO.timeFrom),
    timeTo: parseStringIntoDayjsTime(topicDTO.timeTo),
    date: parseStringIntoDayjsDate(topicDTO.date),
    coreCurriculums: topicDTO.coreCurriculums,
    educationalGuideTopic: topicDTO.educationGuide?.topicId,
    educationalGuide: topicDTO.educationGuide?.textbookId,
});
