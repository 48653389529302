import { AppTable, AppTableProps } from 'components/organisms/AppTable';
import { ChildId, ChildPayment, PaymentId, PaymentTypes } from 'types';
import { commonTexts, settlementTexts } from 'consts/text';
import { settlementChildPaymentDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useSearchParamsBasedPagination } from 'hooks/useSearchParamsBasedPagination/useSearchParamsBasedPagination';
import { useParams } from 'react-router-dom';
import { parsePaginationDataToTablePaginationData } from 'utils/parsers/table/parsePaginationDataToTablePaginationData';
import { Option } from 'components/atoms';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { RemoveModal } from 'components/organisms';
import { useState } from 'react';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { getChildPaymentTransactionTypeText } from 'utils/getChildPaymentTransactionTypeText';

const columns: AppTableProps<ChildPayment>['columns'] = [
    {
        title: commonTexts.dataLabels.date,
        dataIndex: settlementChildPaymentDTOFields.date,
        key: settlementChildPaymentDTOFields.date,
        render: (_, record) =>
            parseStringIntoFormattedStringDate(record.date, 'YYYY-MM-DD', 'DD/MM/YYYY'),
    },
    {
        title: settlementTexts.children.columnLabels.transactionType,
        dataIndex: settlementChildPaymentDTOFields.transactionType,
        key: settlementChildPaymentDTOFields.transactionType,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
        render: (value: ChildPayment['transactionType']) =>
            getChildPaymentTransactionTypeText(value),
    },
    {
        title: settlementTexts.children.columnLabels.paymentFormType,
        dataIndex: settlementChildPaymentDTOFields.paymentFormType,
        key: settlementChildPaymentDTOFields.paymentFormType,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
        render: (value) =>
            value === PaymentTypes.WIRE_TRANSFER
                ? settlementTexts.children.paymentTypes.transfer
                : settlementTexts.children.paymentTypes.cash,
    },
    {
        title: commonTexts.dataLabels.amount,
        dataIndex: settlementChildPaymentDTOFields.amount,
        key: settlementChildPaymentDTOFields.amount,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
        render: (_, record) => getFormattedPrice(record.amount),
    },
];

export const ChildSettlementPaymentTable = ({
    onAddClick,
    onEditClick,
}: {
    onAddClick: () => void;
    onEditClick: (transactionId: PaymentId) => void;
}) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const [selectedToRemoveTransactionId, setSelectedToRemoveTransactionId] =
        useState<PaymentId | null>(null);

    const { childId } = useParams<{ childId: ChildId }>();
    const { currentPage, currentPageSize, handlePaginationValuesChange } =
        useSearchParamsBasedPagination();

    const {
        data: childPaymentData,
        isRefetching: isChildPaymentRefetching,
        isInitialLoading: isChildPaymentInitialLoading,
        isError: isChildPaymentError,
    } = useAppQuery(
        'CHILD_SETTLEMENT_PAYMENT',
        [childId, currentPage, currentPageSize],
        () =>
            StaffHTTPService.settlements.getChildTransactions({
                childId: childId as ChildId,
                paginationParams: { size: currentPageSize, page: currentPage },
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const onRemoveTransactionSuccess = () => {
        displaySuccess(settlementTexts.children.successfullyRemoveTransactionMessage);
        setSelectedToRemoveTransactionId(null);
    };

    const { mutate: removeTransaction, isLoading: isRemoveTransactionLoading } = useAppMutation(
        StaffHTTPService.settlements.removeChildTransaction,
        {
            key: ['REMOVE_TRANSACTION'],
            onSuccess: onRemoveTransactionSuccess,
            onError: () => displayError(commonTexts.errorMessages.unknown),
            invalidateQueryKeys: [
                ['SETTLEMENTS'],
                ['CHILD_SETTLEMENT'],
                ['CHILD_SETTLEMENT_PAYMENT'],
            ],
        },
    );

    const transactionOptions: Option<ChildPayment>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: (record) => onEditClick(record.id),
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: (record) => {
                setSelectedToRemoveTransactionId(record.id);
            },
            Icon: <IconTrash />,
        },
    ];

    const onRemoveTransactionOk = () => {
        removeTransaction({ transactionId: selectedToRemoveTransactionId as PaymentId });
    };
    const onRemoveTransactionCancel = () => {
        setSelectedToRemoveTransactionId(null);
    };

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveTransactionId}
                title={settlementTexts.children.paymentRemove}
                confirmActionLoading={isRemoveTransactionLoading}
                onOk={onRemoveTransactionOk}
                onCancel={onRemoveTransactionCancel}
            />
            <AppTable
                options={transactionOptions}
                isError={isChildPaymentError}
                title={settlementTexts.children.details.paymentTableTitle}
                emptyInfo={settlementTexts.children.details.paymentTableEmpty}
                columns={columns}
                addButtonLabel={settlementTexts.children.actions.addBill}
                onAddButtonClick={onAddClick}
                isTableManageUtilsHidden
                rowKey={({ id }) => id}
                isDataInitialLoading={isChildPaymentInitialLoading}
                isDataRefetching={isChildPaymentRefetching}
                dataSource={childPaymentData?.content}
                pagination={{
                    ...parsePaginationDataToTablePaginationData({
                        currentPage,
                        currentPageSize,
                    }),
                    total: childPaymentData?.totalElements || 0,
                    onChange: handlePaginationValuesChange,
                }}
            />
        </>
    );
};
