import React, { LegacyRef } from 'react';
import { RowProps } from 'antd/lib/descriptions/Row';

export type AppBodyRowProps = RowProps;

export const AppBodyRow = React.forwardRef(
    ({ ...wrapperDefaultProps }: AppBodyRowProps, ref: LegacyRef<HTMLTableRowElement>) => (
        <tr ref={ref} {...wrapperDefaultProps} />
    ),
);
