import { OwnerDTO } from 'types/user';
import { InstitutionBasicDto, InstitutionId } from 'types/institution';
import { fullInstitutionSubscriptionDTOFields } from 'consts/subscription/fullInstitutionSubscriptionDTOFields';

export type SubscriptionId = string & { readonly type: unique symbol };

export enum SubscriptionType {
    TESTING = 'TESTING',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
}

export type SubscriptionDTO = {
    id: SubscriptionId;
    institutionId: InstitutionId;
    childLimit: number;
    price: number;
    periodStart: string;
    type: SubscriptionType;
};

export type StaffSubscriptionDTO = SubscriptionDTO & {
    activeChildren: number;
};

export type SubscriptionChildrenNumbersDto = {
    activeChildren: number;
    childLimit: number;
};

export type SubscriptionBasicDataDTO = Pick<
    SubscriptionDTO,
    'id' | 'childLimit' | 'price' | 'type' | 'periodStart'
> & {
    activeChildren: number;
};

export type InstitutionBalanceDTO = {
    institutionId: InstitutionId;
    balance: number;
    lastPayment: string | null;
};

export type FullInstitutionSubscriptionDTO = {
    [fullInstitutionSubscriptionDTOFields.balance]: InstitutionBalanceDTO;
    [fullInstitutionSubscriptionDTOFields.subscriptionBasicData]: SubscriptionBasicDataDTO;
    [fullInstitutionSubscriptionDTOFields.institution]: InstitutionBasicDto;
    [fullInstitutionSubscriptionDTOFields.institutionOwner]: OwnerDTO;
};

export type SubscriptionInstitutionAndOwnerDTO = Pick<
    FullInstitutionSubscriptionDTO,
    'institution' | 'institutionOwner' | 'subscriptionBasicData'
>;

export type CreateSubscriptionDTO = {
    childLimit: number;
    price: number;
    periodStart: string;
};

export type UpdateSubscriptionDTO = Pick<CreateSubscriptionDTO, 'childLimit' | 'price'>;
