import { Typography } from 'antd';
import styled, { css } from 'styled-components';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { MarginsProps } from 'types/components/margins';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { getTextStyle, TextType } from 'utils/getTextStyle';

const { Paragraph } = Typography;

export type AppParagraphProps = ParagraphProps &
    MarginsProps & {
        textType?: TextType;
        color?: string;
        textAlign?: 'right' | 'left' | 'center' | 'justify';
    };
const filteredProps = marginPropsToFilter.concat(['textType', 'color', 'textAlign']);

export const AppStyledParagraph = styled(Paragraph).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppParagraphProps>`
    ${({ margin = 10, color, textType = 'BodyMedium', textAlign = 'left', ...props }) => css`
        letter-spacing: 0.03em;
        line-height: 140%;
        color: ${color};

        ${getTextStyle(textType)}

        &.ant-typography {
            ${getMarginsCss({ margin, ...props })}
            text-align: ${textAlign};
        }
    `};
`;

export const AppParagraph = ({ textType = 'BodyMedium', ...props }: AppParagraphProps) => (
    <AppStyledParagraph textType={textType} {...props} />
);
