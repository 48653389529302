import { PermissionType, StaffAppModules, EditablePermissionsNames, PermissionAction } from 'types';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { settingsTexts } from 'consts/text';
import { PERMISSIONS_ACTION } from 'consts/permissions/permissions';
import { LabelsOption, PermissionOnOff } from '../PermissionOnOff/PermissionOnOff';
import { ListItem } from '../ListItem/ListItem';
import { PermissionViewEditAdd } from '../PermissionViewEditAdd/PermissionViewEditAdd';
import { modulesEditAtom } from '../../roleAndPermissionState';

export type PermissionListItemProps = {
    permissionName: EditablePermissionsNames;
    option: PermissionType;
    className?: string;
    moduleName: StaffAppModules;
    dataTestId?: string;
};

export const PermissionListItem = ({
    className,
    permissionName,
    option,
    moduleName,
    dataTestId,
}: PermissionListItemProps) => {
    const permissionLabel = settingsTexts.rolesAndPermissions.permissionsNames[permissionName];
    const modules = useAtomValue(modulesEditAtom);
    const isDisabled = !modules.find((moduleToCheck) => moduleToCheck === moduleName);

    const permissionAction = useMemo<LabelsOption | null>(() => {
        switch (option) {
            case 'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME':
                return 'month10day';
            case 'YES_OR_NO':
                return 'default';
            default:
                return null;
        }
    }, [option]);

    const defaultPermissionOnOffAction =
        option === 'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME' && modules.includes(moduleName)
            ? (PERMISSIONS_ACTION.EDIT_ALL_THE_TIME as PermissionAction)
            : undefined;

    const actionComponent = permissionAction ? (
        <PermissionOnOff
            defaultAction={defaultPermissionOnOffAction}
            disabled={isDisabled}
            labelOption={permissionAction}
            permissionName={permissionName}
        />
    ) : (
        <PermissionViewEditAdd disabled={isDisabled} permissionName={permissionName} />
    );

    return (
        <ListItem
            className={className}
            permissionLabel={permissionLabel}
            actionComponent={actionComponent}
            dataTestId={dataTestId}
        />
    );
};
