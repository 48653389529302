import { colors } from 'theme/styledComponents/colors';
import { ChildStatus, ColorMapper } from 'types';

export const tagColorsByChildStatus: ColorMapper<ChildStatus> = {
    ACTIVE: {
        textColor: colors.green.green8,
        backgroundColor: colors.green.green3,
    },
    INACTIVE: { textColor: colors.grayscale.gray9, backgroundColor: colors.grayscale.gray3 },
};
