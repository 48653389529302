import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';
import { FIREBASE_CONFIG } from './firebase.config';

const config = FIREBASE_CONFIG[import.meta.env.VITE_APP_ENV];

const app = initializeApp(config);
const auth = getAuth(app);
const messaging = getMessaging(app);

export { auth, messaging, app };
