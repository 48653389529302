import { createGlobalStyle } from 'styled-components';
import { Popover, PopoverProps } from 'antd';

export type AppPopoverProps = PopoverProps;

export const GlobalStyle = createGlobalStyle`
  .ant-popover .ant-popover-content  {
      .ant-popover-inner {padding: 8px 12px;}
    
      .ant-popover-title {
          margin-bottom: 12px;
      }
  }
`;

export const AppPopover = (props: AppPopoverProps) => (
    <>
        <GlobalStyle />
        <Popover trigger="click" {...props} />
    </>
);
