export const childMonthlyAttendanceDTOFields = {
    child: 'child',
    monthlyAttendance: 'monthlyAttendance',
    presences: 'presences',
    absences: 'absences',
} as const;

export const childSpecialMonthlyAttendanceDTOFields = {
    lessonChildDto: 'lessonChildDto',
    monthlyAttendance: 'monthlyAttendance',
    presences: 'presences',
    absences: 'absences',
} as const;
