import { AppFormItem, AppFormItemProps, AppTextArea, AppTextAreaProps } from 'components/atoms';

type SimpleTextAreaElementProps = AppFormItemProps &
    Pick<AppTextAreaProps, 'rows' | 'resizable' | 'placeholder' | 'onChange'>;

export const SimpleTextAreaElement = ({
    rows,
    resizable,
    placeholder,
    disabled,
    ...restProps
}: SimpleTextAreaElementProps) => (
    <AppFormItem disabled={disabled} {...restProps}>
        <AppTextArea
            autoComplete="off"
            rows={rows ?? 6}
            resizable={resizable}
            placeholder={placeholder}
            disabled={disabled}
        />
    </AppFormItem>
);
