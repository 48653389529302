import { InstitutionId } from 'types';

const INSTITUTION_ID_LOCAL_STORAGE_KEY = 'INSTITUTION_ID';

export const writeLocalStorageInstitutionId = (institutionId: InstitutionId) =>
    localStorage.setItem(INSTITUTION_ID_LOCAL_STORAGE_KEY, institutionId);

export const readLocalStorageInstitutionId = () =>
    localStorage.getItem(INSTITUTION_ID_LOCAL_STORAGE_KEY);

export const removeLocalStorageInstitutionId = () =>
    localStorage.removeItem(INSTITUTION_ID_LOCAL_STORAGE_KEY);
