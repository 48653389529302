import { ReactNode } from 'react';
import { Container, IconContainer, StyledTitle, Subtitle } from './StateInfoBase.styled';

export type StateInfoBaseProps = {
    title?: string;
    subTitle?: string;
    icon: ReactNode;
    className?: string;
};

export const StateInfoBase = ({ title = '', subTitle, icon, className }: StateInfoBaseProps) => (
    <Container className={className}>
        {title && (
            <StyledTitle margin={0} level={5} textAlign="center">
                {title}
            </StyledTitle>
        )}
        {subTitle && (
            <Subtitle margin={0} marginTop={6} textType="BodyMedium" textAlign="center">
                {subTitle}
            </Subtitle>
        )}
        {icon && <IconContainer>{icon}</IconContainer>}
    </Container>
);
