import { AppCheckbox } from 'components/atoms';
import { ContentContainer, StyledListItem } from './GroupOptionListItem.styled';

type GroupOptionListItemProps = {
    label: string;
    isChecked: boolean;
    onChange: (isChecked: boolean) => void;
};

export const GroupOptionListItem = ({ label, isChecked, onChange }: GroupOptionListItemProps) => (
    <StyledListItem>
        <ContentContainer>
            {label}
            <AppCheckbox
                checked={isChecked}
                onChange={(checkboxChangeEvent) => onChange(checkboxChangeEvent.target.checked)}
            />
        </ContentContainer>
    </StyledListItem>
);
