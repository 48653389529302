import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { PercentageIcon } from 'assets';
import { Rule } from 'antd/es/form';
import { commonTexts } from 'consts/text';

type CurrencyInputProps = AppFormItemProps & { name: string; label: string };

const MAX_PERCENTAGE = 100;

const MIN_PERCENTAGE = 0;

export const PercentageInputElement = ({
    optional,
    name,
    label,
    rules,
    ...props
}: CurrencyInputProps) => {
    const defaultRule: Rule = {
        transform: (value) => Number(value),
        type: 'number',
        max: MAX_PERCENTAGE,
        min: MIN_PERCENTAGE,
        message: commonTexts.forms.percentageRuleMessage,
    };

    return (
        <AppFormItem
            label={label}
            name={name}
            optional={optional}
            hasOptionalText={optional}
            rules={rules ? [...rules, defaultRule] : [defaultRule]}
            {...props}
        >
            <AppInput autoComplete="off" suffix={<PercentageIcon />} />
        </AppFormItem>
    );
};
