import { AddUpdateInspectionDTO, InspectionFormFields } from 'types';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseInspectionFormFieldsIntoAddUpdateInspectionDTO: (
    inspectionFormFields: InspectionFormFields,
) => AddUpdateInspectionDTO = ({
    inspectionSubject,
    inspectionIdNumber,
    date,
    ...restInspectionFormFields
}) => ({
    ...restInspectionFormFields,
    subject: inspectionSubject,
    documentNo: inspectionIdNumber,
    date: parseDayjsDateIntoString(date, 'YYYY-MM-DD'),
});
