import { SubscriptionFormFields, UpdateSubscriptionDTO } from 'types';

export const parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO: (
    subscriptionFormFields: SubscriptionFormFields,
) => UpdateSubscriptionDTO = (subscriptionFormFields) => {
    const { childLimit, price } = subscriptionFormFields;
    return {
        childLimit,
        price,
    };
};
