import {
    InstitutionAddEditFormFields,
    OwnerCreateData,
    OwnerSignUp,
    StepsFormAnswers,
} from 'types';
import { parseInstitutionAddFormFieldsIntoInstitutionCreateData } from '../institution/parseInstitutionAddFormFieldsIntoInstitutionCreateData';
import { parseFormattedPhoneNumberIntoPhoneNumber } from '../phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';

export const parseStepsFormAnswersIntoOwnerCreateData: (
    stepsFormAnswers: StepsFormAnswers,
) => OwnerCreateData = (stepsFormAnswers) => {
    const ownerSignUp = stepsFormAnswers.registerStep1 as OwnerSignUp;
    const institutionAdd = stepsFormAnswers.registerStep2 as InstitutionAddEditFormFields;

    return {
        user: {
            firstName: ownerSignUp.firstName,
            email: ownerSignUp.email,
            lastName: ownerSignUp.lastName,
            password: ownerSignUp.password,
            phoneNumber: parseFormattedPhoneNumberIntoPhoneNumber(ownerSignUp.phoneNumber),
        },
        institution: parseInstitutionAddFormFieldsIntoInstitutionCreateData(institutionAdd),
    };
};
