import { useTheme } from 'styled-components';
import { AppParagraph } from 'components/atoms/CommonAppComponents';
import { MessageRemoteLessonContentDTO } from 'types';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { messagesTexts } from 'consts/text';
import { RemoteLessonMessageDataItem } from './components';

type RemoteLessonMessageContentProps = {
    remoteLessonContent: MessageRemoteLessonContentDTO;
};

export const RemoteLessonMessageContent = ({
    remoteLessonContent,
}: RemoteLessonMessageContentProps) => {
    const theme = useTheme();

    const { date, description, homework, homeworkDeadline } = remoteLessonContent;
    const parsedDate = parseStringIntoFormattedStringDate(date);
    const parsedHomeworkDeadline = homeworkDeadline
        ? parseStringIntoFormattedStringDate(homeworkDeadline)
        : null;
    const title = `${messagesTexts.remoteLessonTopicPrefixWithSemicolon} ${messagesTexts.remoteLessonMessage.title} ${parsedDate}`;

    return (
        <>
            <AppParagraph
                textType="BodyMediumSemiBold"
                margin={0}
                marginBottom={theme.sizes.marginNormal}
            >
                {title}
            </AppParagraph>
            <RemoteLessonMessageDataItem
                title={messagesTexts.remoteLessonMessage.description}
                description={description}
            />
            <RemoteLessonMessageDataItem
                title={messagesTexts.remoteLessonMessage.homework}
                description={homework}
            />
            {parsedHomeworkDeadline && (
                <RemoteLessonMessageDataItem
                    title={messagesTexts.remoteLessonMessage.homeworkDateTo}
                    description={parsedHomeworkDeadline}
                />
            )}
        </>
    );
};
