import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { AuthUpperNavBar } from 'components/molecules';
import { CentralIslandLayout } from '../CentralIslandLayout/CentralIslandLayout';

const OutletContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex: 1;
        padding-top: ${theme.sizes.auth.authUpperNavBarHeightMobile}px;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            padding-top: ${theme.sizes.auth.authUpperNavBarHeight}px;
        }
    `}
`;
export const AuthLayout = () => (
    <>
        <AuthUpperNavBar />
        <OutletContainer>
            <CentralIslandLayout>
                <Outlet />
            </CentralIslandLayout>
        </OutletContainer>
    </>
);
