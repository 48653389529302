import { useSettingsAbilities } from 'abilities';
import { Row } from 'antd';
import { Details } from 'components/organisms';
import { appPaths } from 'consts/paths/paths';
import { settingsTexts } from 'consts/text';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { InstitutionAffiliationDisplay, InstitutionTypeDisplay } from 'components/molecules';

export const InstitutionDetailsPage = () => {
    const { id: currentInstitutionId } = useCurrentInstitution();
    const { settingsAbilities } = useSettingsAbilities();
    const { checkIfCanManageInstitutionData } = settingsAbilities;
    const canManageInstitutionData = checkIfCanManageInstitutionData();

    const {
        data: institutionDetailsData,
        isFetching: isInstitutionDetailsDataFetching,
        isError: isInstitutionDetailsDataError,
    } = useAppQuery(
        'INSTITUTION',
        [currentInstitutionId],
        () => StaffHTTPService.institutions.getInstitutionDetails(currentInstitutionId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title={settingsTexts.institutionDetails.institutionData}
            isLoading={isInstitutionDetailsDataFetching}
            editPagePath={
                canManageInstitutionData
                    ? appPaths.app.settings['institution-details'].edit
                    : undefined
            }
            isVisibleGoBackButton={false}
            isError={isInstitutionDetailsDataError}
        >
            <Details.Item title={settingsTexts.institutionDetails.labelNames.regonNumber}>
                {institutionDetailsData?.regon}
            </Details.Item>
            <Details.Item title={settingsTexts.institutionDetails.labelNames.institutionName}>
                {institutionDetailsData?.name}
            </Details.Item>
            <Details.Item title={settingsTexts.institutionDetails.labelNames.rspoNumber}>
                {institutionDetailsData?.rspo}
            </Details.Item>
            <Details.Item title={settingsTexts.institutionDetails.labelNames.address}>
                {institutionDetailsData?.street}
            </Details.Item>
            <Row>
                <Details.Item title={settingsTexts.institutionDetails.labelNames.postalCode}>
                    {institutionDetailsData?.zipCode}
                </Details.Item>
                <Details.Item title={settingsTexts.institutionDetails.labelNames.city}>
                    {institutionDetailsData?.city}
                </Details.Item>
            </Row>
            <Details.Item
                title={settingsTexts.institutionDetails.labelNames.institutionAffiliation}
            >
                <InstitutionAffiliationDisplay
                    isPublicInstitution={!!institutionDetailsData?.publicInstitution}
                />
            </Details.Item>
            <Details.Item title={settingsTexts.institutionDetails.labelNames.institutionType}>
                {institutionDetailsData?.type ? (
                    <InstitutionTypeDisplay type={institutionDetailsData.type} />
                ) : null}
            </Details.Item>
        </Details>
    );
};
