import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts } from 'consts/text';
import { max300LengthRule } from 'consts/rules/textRules';

export const CityInputElement = ({ optional, ...props }: AppFormItemProps) => (
    <AppFormItem
        label={commonTexts.inputLabels.city}
        name={formFields.city}
        optional={optional}
        hasOptionalText={optional}
        rules={[max300LengthRule]}
        {...props}
    >
        <AppInput autoComplete="address-level2" />
    </AppFormItem>
);
