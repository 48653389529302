export const inspectionDTOFields = {
    id: 'id',
    journalId: 'journalId',
    subject: 'subject',
    documentNo: 'documentNo',
    date: 'date',
    supervisionForm: 'supervisionForm',
    supervisor: 'supervisor',
    teacher: 'teacher',
} as const;
