import { AppListItem } from 'components/atoms/CommonAppComponents/AppList/components';
import styled, { css } from 'styled-components';

export const StyledListItem = styled(AppListItem)`
    ${({ theme }) => css`
        @media (${theme.sizes.mediaQueries.mobileMax}) {
            &.ant-list-item {
                flex-direction: column;
                align-items: unset;

                .ant-list-item-action {
                    margin-inline-start: 0;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        label {
                            align-self: stretch;
                            align-items: center;
                        }
                        label:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    `}
`;
