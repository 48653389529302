import { EmployeeGroup } from 'types/employee/employeeGroup';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppParagraph } from 'components/atoms';
import { colors } from 'theme/styledComponents/colors';

export const EmployeeGroupsDisplay = ({ groups }: { groups: EmployeeGroup[] }) => {
    const groupsNames = groups.length
        ? groups.map(({ name }) => name).join(', ')
        : EMPTY_VALUE_LABEL;

    return (
        <AppParagraph margin={0} textType="BodyMediumSemiBold" color={colors.grayscale.gray9}>
            {groupsNames}
        </AppParagraph>
    );
};
