import { useParams } from 'react-router-dom';
import { EventAddEditModal } from 'components/templates/calendar';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useContext, useEffect } from 'react';
import { calendarTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { parseEventFormFieldsIntoAddEventDTO } from 'utils/parsers/calendar/parseEventFormFieldsIntoAddEventDTO';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { EventFormFields } from 'types/forms/event';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { CalendarEventDetailedDto } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { formFields } from 'consts/form/formFields';
import { parseEventDTOIntoEventFormFields } from 'utils/parsers/calendar/parseEventDtoIntoEventFormFields';
import { AppCalendarContext } from 'contexts/AppCalendarContext';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const CalendarEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<EventFormFields>();
    const { displaySuccess } = useSimpleNotification();

    const { selectedDate } = useContext(AppCalendarContext);
    const date = parseStringIntoDayjsDate(selectedDate as string);
    const selectedYear = date.year();
    const selectedMonth = getMonthFromDayjsMonth(date.month());

    const { eventId } = useParams<{ eventId: string }>();
    const { statefulNavigate } = useNavigateWithState();

    const { data: eventsMonthlyData } = useAppQuery(
        'MONTHLY_EVENTS',
        [selectedMonth, selectedYear],
        () =>
            StaffHTTPService.calendar.getMonthlyEvents({
                month: selectedMonth,
                year: selectedYear,
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (eventsMonthlyData && !form.getFieldValue(formFields.name)) {
            const eventData = eventsMonthlyData.find((event) => event.id === eventId);
            setFormData(parseEventDTOIntoEventFormFields(eventData as CalendarEventDetailedDto));
        }
    }, [eventId, eventsMonthlyData, form, setFormData]);

    const onAddEventSuccess = () => {
        displaySuccess(calendarTexts.successfullyEditEventMessage);
        statefulNavigate(appPaths.app.calendar.base, {
            replace: true,
        });
    };

    const {
        mutate: editEvent,
        error: editEventError,
        isLoading: isAddEventLoading,
    } = useAppMutation(StaffHTTPService.calendar.editEvent, {
        key: ['EDIT_EVENT'],
        onSuccess: onAddEventSuccess,
        invalidateQueryKeys: [['MONTHLY_EVENTS'], ['DAILY_EVENTS']],
    });

    const onEditEventFinish = (eventData: EventFormFields) =>
        editEvent({ ...parseEventFormFieldsIntoAddEventDTO(eventData), id: eventId as string });

    return (
        <EventAddEditModal
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={editEventError}
            isLoading={isAddEventLoading}
            onFinish={onEditEventFinish}
        />
    );
};
