import { RouteObject } from 'react-router-dom';
import { journalDetails } from './routes/journalDetails';
import { journalChildRoutes } from './routes/journalChild';
import { journalInspectionRoutes } from './routes/journalInspection';
import { journalPsychologicalHelpRoutes } from './routes/journalPsychologicalHelp';
import { journalConsultationRoutes } from './routes/journalConsultation';
import { journalTripRoutes } from './routes/journalTrip';
import { journalParentTeacherConferenceRoutes } from './routes/journalParentTeacherConference';
import { journalImportantEventRoutes } from './routes/journalImportantEvent';
import { journalScheduleRoutes } from './routes/journalSchedule';
import { journalTopicRoutes } from './routes/journalTopic';

export const groupJournalRoutes: RouteObject[] = [
    ...journalDetails,
    ...journalTripRoutes,
    ...journalChildRoutes,
    ...journalInspectionRoutes,
    ...journalPsychologicalHelpRoutes,
    ...journalScheduleRoutes,
    ...journalConsultationRoutes,
    ...journalParentTeacherConferenceRoutes,
    ...journalImportantEventRoutes,
    ...journalTopicRoutes,
];
