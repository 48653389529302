import {
    StaffAppModules,
    ModuleSchemaDTO,
    ModuleWithPermissions,
    Permission,
    Role,
    RoleModifiedData,
} from 'types';
import { AppText, ComponentBlockingAppLoader } from 'components/atoms';
import { EDITABLE_PERMISSIONS_BY_MODULE } from 'consts/permissions/permissions';
import { ErrorDisplay, LoadingDisplay, NameInputElement } from 'components/molecules';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { AppForm } from 'components/organisms';
import { useTheme } from 'styled-components';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { RoleEditFormFields } from 'types/forms/role';
import { FormInstance } from 'antd';
import { settingsTexts } from 'consts/text';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { scrollToFirstElementWithClass } from 'utils/scrollToFirstElementWithClass';
import { ListEntryRenderer, ModuleListItem, SaveButton, TemplatePicker } from './components';
import { StyledList } from './InfoContainerBase.styled';
import { modulesEditAtom, permissionsEditAtom } from './roleAndPermissionState';
import { INVALID_PERMISSION_CLASS, MODULE_CLASS } from './classes';

/*
    Due to the props drilling, the state management has been moved to atoms in the roleAndPermissionState file
*/

export type RolesAndPermissionsAddEditFormProps = {
    isRoleFetching?: boolean;
    isRoleUpdatingError?: boolean;
    isMinorLoading: boolean;
    onSave: (roleModifiedData: RoleModifiedData) => void;
    roleDetails?: Role;
    form?: FormInstance;
    mode: 'add' | 'edit';
};

export const RolesAndPermissionsAddEditForm = ({
    isRoleFetching,
    isMinorLoading,
    isRoleUpdatingError,
    onSave,
    roleDetails,
    form,
    mode,
}: RolesAndPermissionsAddEditFormProps) => {
    const [
        notSelectedObligatoryPermissionsWithModule,
        setNotSelectedObligatoryPermissionsWithModule,
    ] = useState<ModuleWithPermissions[] | null>(null);
    const setPermissions = useSetAtom(permissionsEditAtom);
    const setModules = useSetAtom(modulesEditAtom);
    const theme = useTheme();
    const isMobile = useIsMobile();
    const headerFieldsMaxWidth = isMobile ? undefined : 400;
    const { displayError } = useSimpleNotification();

    useEffect(
        () => {
            setPermissions([]);
            setModules([]);
        },
        // onMount only
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const {
        data: rolesSchema,
        isFetching: isRolesSchemaFetching,
        isError: isRolesSchemaError,
    } = useAppQuery('ROLE_SCHEMA', [], StaffHTTPService.roles.getRolesSchema, {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });
    const validatePermissionsAndModules = (roleModifiedData: RoleModifiedData) => {
        const { modules: roleSchemaModules } = rolesSchema || { modules: [] };

        const missingPermissionsPerModule: ModuleWithPermissions[] =
            roleModifiedData.modules.flatMap((moduleName) => {
                const matchingSchemaModule = roleSchemaModules.find(
                    (moduleSchema: ModuleSchemaDTO) => moduleSchema.name === moduleName,
                );

                if (!matchingSchemaModule) {
                    return [];
                }

                return matchingSchemaModule.obligatoryPermissionsGroups.flatMap(
                    (obligatoryPermissions) => {
                        const missingPermissions = obligatoryPermissions.permissions.filter(
                            (requiredPermission) =>
                                !roleModifiedData.permissions.some(
                                    (userPermission: Permission) =>
                                        userPermission.name === requiredPermission,
                                ),
                        );

                        if (
                            missingPermissions.length === obligatoryPermissions.permissions.length
                        ) {
                            return [
                                {
                                    module: moduleName,
                                    permissions: missingPermissions,
                                },
                            ];
                        }

                        return [];
                    },
                );
            });

        const missingPermissionsResult: ModuleWithPermissions[] | null =
            missingPermissionsPerModule.length > 0 ? missingPermissionsPerModule : null;

        setNotSelectedObligatoryPermissionsWithModule(missingPermissionsResult);
        return missingPermissionsResult;
    };

    const validateRoleName = (name: string) => !!name?.trim();

    const handleSave = (roleModifiedData: RoleModifiedData) => {
        const missingPermissions = validatePermissionsAndModules(roleModifiedData);

        if (missingPermissions || !validateRoleName(roleModifiedData.name)) {
            if (missingPermissions) {
                displayError(
                    settingsTexts.rolesAndPermissions.notifications.permissionsDataIncomplete,
                );
            } else {
                displayError(settingsTexts.rolesAndPermissions.errors.emptyRoleName);
            }

            // to wait for the DOM to be updated
            setTimeout(() => {
                scrollToFirstElementWithClass(INVALID_PERMISSION_CLASS);
            }, 0);
            return;
        }
        onSave(roleModifiedData);
    };

    const permissionsLists = useMemo(
        () =>
            Object.entries(EDITABLE_PERMISSIONS_BY_MODULE).map(
                ([moduleName, permissionOrSubmoduleEntry]) => (
                    <StyledList
                        customizedPadding
                        key={moduleName}
                        itemLayout="horizontal"
                        bordered
                        header={
                            <ModuleListItem
                                className={MODULE_CLASS}
                                moduleName={moduleName as StaffAppModules}
                            />
                        }
                        dataSource={Object.entries(permissionOrSubmoduleEntry)}
                        renderItem={(item) => (
                            <ListEntryRenderer
                                moduleName={moduleName as StaffAppModules}
                                entry={item}
                                missingPermissionsPerModule={
                                    notSelectedObligatoryPermissionsWithModule
                                }
                            />
                        )}
                    />
                ),
            ),
        [notSelectedObligatoryPermissionsWithModule],
    );

    if (isRoleFetching || isRolesSchemaFetching) {
        return <LoadingDisplay />;
    }

    if (isRoleUpdatingError || isRolesSchemaError) {
        return <ErrorDisplay />;
    }

    return (
        <AppForm<RoleEditFormFields>
            name="roleEdit"
            title={
                mode === 'edit'
                    ? settingsTexts.rolesAndPermissions.role.editRole
                    : settingsTexts.rolesAndPermissions.role.add
            }
            withGoBack
            labelAlign="left"
            titleAlign="left"
            desktopTitleLevel={4}
            maxWidth={Number.MAX_SAFE_INTEGER}
            form={form}
            withoutFlexContainer
        >
            {isMinorLoading && <ComponentBlockingAppLoader />}
            <NameInputElement
                maxWidth={headerFieldsMaxWidth}
                marginLeft={0}
                label={settingsTexts.rolesAndPermissions.role.name}
            />
            <AppText
                margin={0}
                marginTop="marginNormal"
                marginBottom="marginLarge"
                textType="BodyMediumSemiBold"
                color={theme.colors.primary.primary9}
            >
                {settingsTexts.rolesAndPermissions.role.editRolePermissions}
            </AppText>
            <TemplatePicker maxWidth={headerFieldsMaxWidth} />
            {permissionsLists}
            <SaveButton form={form} onSave={handleSave} roleDetails={roleDetails} />
        </AppForm>
    );
};
