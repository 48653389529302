import { ReactNode } from 'react';
import { Rule } from 'antd/es/form';
import { AppFormItem, AppFormItemProps } from 'components/atoms/Form/AppFormItem/AppFormItem';
import { AppFormRangeItem } from 'components/atoms/Form/AppFormRangeItem/AppFormRangeItem';
import { AppFormRangeItemMode } from 'types';
import { commonTexts } from 'consts/text';
import { datesTimelineValidator } from 'utils/validators';
import { FormItemData, timePickerData, datePickerData } from './DurationDateTimeFormItemBase.utils';

export type DurationDateTimeFormItemBaseProps = AppFormItemProps & {
    pickerType: 'date' | 'time';
    rangeItemMode: AppFormRangeItemMode;
    startFormItemData: FormItemData;
    finishFormItemData: FormItemData;
    isFinishValueBeforeStartValueErrorText: string;
    isStartValueAfterFinishValueErrorText: string;
    picker: ReactNode;
};

export const DurationDateTimeFormItemBase = ({
    pickerType,
    rangeItemMode,
    startFormItemData,
    finishFormItemData,
    isFinishValueBeforeStartValueErrorText = commonTexts.errorMessages
        .dateToBeforeDateFromErrorText,
    isStartValueAfterFinishValueErrorText = commonTexts.errorMessages.dateFromAfterDateToErrorText,
    picker,
    ...props
}: DurationDateTimeFormItemBaseProps) => {
    const { unitToCompare, dataFormatLabel } =
        pickerType === 'date' ? datePickerData : timePickerData;

    const rules: Rule[] = [
        ({ getFieldValue }) =>
            datesTimelineValidator(getFieldValue, {
                mode: rangeItemMode,
                startValueFieldName: startFormItemData.name,
                finishValueFieldName: finishFormItemData.name,
                isFinishValueBeforeStartValueErrorText,
                isStartValueAfterFinishValueErrorText,
                unitToCompare,
            }),
    ];

    const getFormItemLabel = (itemMode: AppFormRangeItemMode) => (
        <AppFormItem.Label
            mainText={itemMode === 'start' ? startFormItemData.label : finishFormItemData.label}
            additionalText={dataFormatLabel}
        />
    );

    return (
        <AppFormRangeItem
            mode={rangeItemMode}
            startValues={{
                label: getFormItemLabel('start'),
                name: startFormItemData.name,
                rules,
            }}
            finishValues={{
                label: getFormItemLabel('finish'),
                name: finishFormItemData.name,
                rules,
            }}
            {...props}
        >
            {picker}
        </AppFormRangeItem>
    );
};
