import { Rule } from 'antd/es/form';
import { AppFormItem, AppFormItemProps, AppFormRangeItem, AppTimePicker } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { TIME_LABEL } from 'consts/labels/common';
import { journalTexts } from 'consts/text';
import { AppFormRangeItemMode } from 'types';
import { datesTimelineValidator } from 'utils/validators';

export type TopicHoursTimePickerProps = AppFormItemProps & {
    mode: AppFormRangeItemMode;
};

export const TopicHoursTimePicker = ({ mode, disabled, ...props }: TopicHoursTimePickerProps) => {
    const rules: Rule[] = [
        { required: true, message: formElementRequiredText },
        ({ getFieldValue }) =>
            datesTimelineValidator(getFieldValue, {
                mode,
                startValueFieldName: formFields.declaredStayHoursFromTime,
                finishValueFieldName: formFields.declaredStayHoursToTime,
                isFinishValueBeforeStartValueErrorText:
                    journalTexts.journalDetails.groupJournal.topics
                        .isFinishHoursBeforeStartHoursErrorText,
                isStartValueAfterFinishValueErrorText:
                    journalTexts.journalDetails.groupJournal.topics
                        .isStartHoursAfterFinishHoursErrorText,
                unitToCompare: 'minute',
            }),
    ];

    const getFormItemLabel = (itemMode: AppFormRangeItemMode) => (
        <AppFormItem.Label
            mainText={
                itemMode === 'start'
                    ? journalTexts.journalDetails.groupJournal.topics.timeFromInputLabel
                    : journalTexts.journalDetails.groupJournal.topics.timeToInputLabel
            }
            additionalText={TIME_LABEL}
        />
    );

    return (
        <AppFormRangeItem
            mode={mode}
            startValues={{
                label: getFormItemLabel('start'),
                name: formFields.timeFrom,
                rules,
            }}
            finishValues={{
                label: getFormItemLabel('finish'),
                name: formFields.timeTo,
                rules,
            }}
            disabled={disabled}
            {...props}
        >
            <AppTimePicker autoComplete="off" disabled={disabled} />
        </AppFormRangeItem>
    );
};
