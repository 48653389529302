import { AxiosError } from 'axios';
import useForm from 'antd/es/form/hooks/useForm';
import {
    CommonFormProps,
    FormModeDependedTexts,
    JournalId,
    ParentTeacherConferenceFormFields,
} from 'types';
import { AppForm, AppFormProps, ChildrenWithParentsPicker } from 'components/organisms';
import {
    DatePickerElement,
    DescriptionInputElement,
    FormNavButtons,
    SubjectInputElement,
} from 'components/molecules';
import { commonTexts, journalTexts } from 'consts/text';
import { useDependentFormFields } from 'hooks/useDependentFormFields/useDependentFormFields';
import { formFields } from 'consts/form/formFields';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

const addModeTexts: FormModeDependedTexts = {
    name: 'parentTeacherConferenceAdd',
    title: journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
        .addParentTeacherConference,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'parentTeacherConferenceEdit',
    title: journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
        .editParentTeacherConference,
    buttonText: commonTexts.actionLabels.save,
};

type ParentTeacherConferenceAddEditFormProps = Omit<
    AppFormProps<ParentTeacherConferenceFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        journalId: JournalId;
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (parentTeacherConferenceData: ParentTeacherConferenceFormFields) => void;
    };

export const ParentTeacherConferenceAddEditForm = ({
    mode,
    form,
    initialValues,
    journalId,
    axiosError,
    ...props
}: ParentTeacherConferenceAddEditFormProps) => {
    const [formInstance] = useForm<ParentTeacherConferenceFormFields>(form);
    const {
        watchedFieldsValues: watchedDateValue,
        handleWatchedFieldsValuesChange: handleWatchedDateValueChange,
    } = useDependentFormFields({
        watchedFields: [formFields.date],
        dependentFields: [formFields.childrenWithParentsIds],
        formInstance,
    });

    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const { date: dateValue } = watchedDateValue;
    const parsedDate = dateValue ? parseDayjsDateIntoString(dateValue, 'YYYY-MM-DD') : undefined;

    const parentTeacherConferenceAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<ParentTeacherConferenceFormFields>
            form={formInstance}
            name={name}
            title={title}
            error={parentTeacherConferenceAddEditError}
            withGoBack
            {...props}
        >
            <SubjectInputElement
                label={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .parentTeacherConferenceSubject
                }
            />
            <DatePickerElement isHalfParentWidth onChange={handleWatchedDateValueChange} />
            <DescriptionInputElement
                label={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .parentTeacherConferenceDescription
                }
            />
            <ChildrenWithParentsPicker
                journalId={journalId}
                date={parsedDate}
                optional
                disabled={!parsedDate}
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
                withDeviceFloppy={!isAddMode}
            />
        </AppForm>
    );
};
