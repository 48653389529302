import { useMemo } from 'react';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { GroupJournalSchedulesLayout } from 'components/layouts';
import { isWeeklyScheduleEmpty } from 'utils/isWeeklyScheduleEmpty';
import { sanitizeWeeklyScheduleDTOData } from 'utils/sanitizeWeeklyScheduleDTOData';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { WeeklyScheduleCards } from 'components/molecules';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const GroupJournalWeeklySchedulePage = () => {
    const { journalId } = useJournalId();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const {
        data: weeklyScheduleData,
        isInitialLoading: isWeeklyScheduleDataInitialLoading,
        isRefetching: isWeeklyScheduleDataRefetching,
        isError: isWeeklyScheduleDataError,
    } = useAppQuery(
        'WEEKLY_SCHEDULE',
        [journalId],
        () => StaffHTTPService.schedules.weeklySchedule.getWeeklySchedule(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const weeklyScheduleSanitizedData = useMemo(() => {
        if (!weeklyScheduleData) {
            return null;
        }
        return sanitizeWeeklyScheduleDTOData(weeklyScheduleData);
    }, [weeklyScheduleData]);

    const isScheduleEmpty = weeklyScheduleData ? isWeeklyScheduleEmpty(weeklyScheduleData) : false;

    return (
        <GroupJournalSchedulesLayout
            scheduleType="WEEKLY"
            isDataInitialLoading={isWeeklyScheduleDataInitialLoading}
            isDataRefetching={isWeeklyScheduleDataRefetching}
            isError={isWeeklyScheduleDataError}
            isEmpty={isScheduleEmpty}
        >
            {weeklyScheduleSanitizedData && (
                <WeeklyScheduleCards weeklySchedule={weeklyScheduleSanitizedData} />
            )}
        </GroupJournalSchedulesLayout>
    );
};
