import { AppFormItem, AppFormItemProps, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { authTexts } from 'consts/text';
import { InstitutionAffiliation } from 'types/institution/institution';

const items: { label: string; value: InstitutionAffiliation }[] = [
    {
        label: authTexts.publicInstitutionButtonLabel,
        value: 'public',
    },
    {
        label: authTexts.privateInstitutionButtonLabel,
        value: 'private',
    },
];
export const InstitutionAffiliationPickerElement = (props: AppFormItemProps) => {
    const radioElements = items.map(({ value, label }) => (
        <AppRadioButton key={value} value={value}>
            {label}
        </AppRadioButton>
    ));

    return (
        <AppFormItem
            label="Rodzaj placówki"
            name={formFields.institutionAffiliation}
            rules={[{ required: true, message: formElementRequiredText }]}
            {...props}
        >
            <AppRadioGroup>{radioElements}</AppRadioGroup>
        </AppFormItem>
    );
};
