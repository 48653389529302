const FIRST_CHUNK_LAST_CHAR_INDEX = 1;
const CHUNKS_SIZE = 4;
const CHUNKS_SEPARATOR = ' ';

export const parseBankAccountNumberIntoFormattedBankAccountNumber = (dtoValue: string) => {
    const firstChunkEnd = FIRST_CHUNK_LAST_CHAR_INDEX + 1;
    const firstTwoChars = dtoValue.slice(0, firstChunkEnd);
    const remainingChars = dtoValue.slice(firstChunkEnd);
    const chunks = [firstTwoChars];

    for (let charPosition = 0; charPosition < remainingChars.length; charPosition += CHUNKS_SIZE) {
        chunks.push(remainingChars.slice(charPosition, charPosition + CHUNKS_SIZE));
    }

    return chunks.join(CHUNKS_SEPARATOR);
};
