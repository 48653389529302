import styled, { css } from 'styled-components';
import { AppText } from 'components/atoms/CommonAppComponents';
import { getTextStyle } from 'utils/getTextStyle';
import { getMarginsCss } from 'utils/getMarginsCss';
import { SchoolIcon } from 'assets';

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        padding: 10px ${theme.sizes.marginNormal}px;
        margin-top: ${theme.sizes.marginLarge}px;
        background-color: ${theme.colors.grayscale.gray2};
        border: 1px solid ${theme.colors.grayscale.gray4};
        border-radius: 6px;
    `}
`;

export const InfoText = styled(AppText)`
    ${({ theme, margin = 0, marginTop }) => css`
        ${getTextStyle('BodyMSRegular')}

        color: ${theme.colors.grayscale.gray9};

        &.ant-typography {
            ${getMarginsCss({ margin, marginTop })}
        }
    `}
`;

export const StyledSchoolIcon = styled(SchoolIcon)`
    ${({ theme }) => css`
        fill: ${theme.colors.grayscale.gray10};
        height: 13px;
        width: 13px;
    `}
`;
