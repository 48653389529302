import { AppFormItem, AppFormItemProps, AppMaskedInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { bankAccountNumberValidator } from 'utils/validators';
import { commonTexts } from 'consts/text';

export const BankAccountNumberInputElement = ({
    disabled,
    optional,
    ...restProps
}: AppFormItemProps) => (
    <AppFormItem
        label={commonTexts.dataLabels.bankAccountNumber}
        name={formFields.bankAccountNumber}
        rules={[
            {
                validator: (_, value) => {
                    if (!value) {
                        return Promise.resolve();
                    }
                    return bankAccountNumberValidator(value)
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error(commonTexts.errorMessages.invalidBankAccountNumber),
                          );
                },
            },
        ]}
        disabled={disabled}
        optional={optional}
        hasOptionalText={optional}
        {...restProps}
    >
        <AppMaskedInput
            mask="00 0000 0000 0000 0000 0000 0000"
            autoComplete="cc-number"
            disabled={disabled}
        />
    </AppFormItem>
);
