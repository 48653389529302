import { ReactNode } from 'react';
import {
    DetailsHeaderWithIcon,
    DetailsHeaderWithIconProps,
} from 'components/atoms/Details/DetailsHeaderWithIcon/DetailsHeaderWithIcon';
import { Row } from 'antd';
import { Option, OptionsButton } from 'components/atoms/AppTableAndListCommon';
import { Container } from './DetailsFrameBase.styled';

export type DetailsFrameBaseProps<ItemType> = Partial<DetailsHeaderWithIconProps> & {
    children?: ReactNode;
    noFrame?: boolean;
    hideHeader?: boolean;
    marginEnd?: number;
    className?: string;
    options?: Option<ItemType>[];
    noMarginLeft?: boolean;
    headerColor?: string;
    iconColor?: string;
    record?: ItemType;
    index?: number;
};

// eslint-disable-next-line
export const DetailsFrameBase = <ItemType = any,>({
    icon,
    title,
    children,
    noFrame = false,
    smallHeaderMargin,
    hideHeader = false,
    marginEnd,
    className,
    options,
    noMarginLeft,
    headerColor,
    iconColor,
    record,
    index = 0,
}: DetailsFrameBaseProps<ItemType>) => (
    <Container noFrame={noFrame} marginEnd={marginEnd} className={className}>
        <Row justify="space-between">
            {!hideHeader && (
                <DetailsHeaderWithIcon
                    icon={icon}
                    title={title || ''}
                    smallHeaderMargin={smallHeaderMargin}
                    noMarginLeft={noMarginLeft}
                    headerColor={headerColor}
                    iconColor={iconColor}
                />
            )}
            {options && record && (
                <OptionsButton<ItemType>
                    options={options}
                    openOptionsButtonSize="BIG"
                    record={record}
                    index={index}
                />
            )}
        </Row>
        {children}
    </Container>
);
