import { AppParagraph, AppTitle } from 'components/atoms';

export const PrivacyPolicy = () => (
    <>
        <AppTitle>Privacy Policy</AppTitle>
        <AppParagraph textType="BodyMedium">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit, elit eu
            vulputate elementum, risus purus varius magna, sed sagittis ligula massa id nunc. Nullam
            malesuada elit a nunc lacinia sollicitudin. Nullam sollicitudin eros erat, vitae tempor
            est suscipit ut. Proin enim tortor, volutpat et venenatis non, dictum a urna. Curabitur
            ac ante sit amet quam dapibus eleifend. Mauris non orci viverra, pretium arcu et, congue
            eros. Vivamus volutpat sapien sit amet ligula vestibulum, et tempus metus tincidunt.
            Duis nibh purus, eleifend quis dapibus non, viverra cursus nulla. Integer ultrices
            posuere velit interdum ornare. Etiam vel gravida dui. Integer efficitur odio nisi, sed
            vestibulum ligula iaculis eu. Aliquam ornare lacinia velit id auctor. Suspendisse
            vestibulum, tellus in commodo pellentesque, sapien lacus lobortis elit, eu cursus justo
            leo eu dolor. Curabitur vestibulum quam at odio tincidunt dictum. Morbi ut malesuada
            felis. Nulla facilisis, velit semper aliquam pharetra, eros leo sagittis justo, ut
            accumsan dui nisl ut felis. Vivamus ut mauris a magna rhoncus eleifend. Cras imperdiet
            condimentum nibh, ut blandit risus iaculis pellentesque. Praesent nec felis ipsum. Duis
            arcu enim, lobortis quis convallis nec, tincidunt a dui. Nullam posuere lorem vel dolor
            molestie eleifend. Vestibulum eget lacus et tortor pellentesque luctus. Nullam vel est
            condimentum elit euismod condimentum. Donec eu libero viverra, convallis felis et,
            convallis ipsum. Mauris pellentesque eleifend est. Nam suscipit risus in molestie
            posuere. Suspendisse eget condimentum turpis. Suspendisse sed nunc ac turpis auctor
            dignissim nec vitae magna. Cras quis dui sodales, malesuada mi at, lacinia urna. Nulla
            sit amet luctus nisi. Proin porttitor nunc ut finibus dignissim. Morbi congue porta
            massa, eu dictum enim dictum ac. Duis mauris urna, pharetra eget porttitor id, convallis
            vitae elit. Nunc scelerisque commodo interdum. Suspendisse posuere ante at placerat
            viverra. Nulla consequat iaculis posuere. Morbi facilisis sit amet nisl vitae auctor.
            Fusce quis justo et nisi lacinia aliquam. Aliquam posuere velit nibh, quis pretium nisi
            consequat eget. Duis pellentesque elit placerat egestas laoreet. Pellentesque suscipit
            ornare libero, a pharetra nisi sollicitudin eget. In iaculis dolor cursus, rhoncus erat
            a, hendrerit risus. Morbi consequat at orci vitae pellentesque. Donec vel rhoncus metus.
            In hac habitasse platea dictumst. Donec cursus venenatis orci, ut dapibus metus
            vestibulum scelerisque. Mauris viverra, leo vel iaculis pulvinar, metus leo molestie
            lacus, sed finibus metus purus vel felis. Integer nec augue vitae magna dapibus
            tincidunt. Praesent posuere nunc ac diam ornare ultrices. Nulla facilisi. Vivamus
            ullamcorper euismod sapien, ut hendrerit magna egestas ut. Nulla facilisi. Curabitur
            fermentum dolor et molestie fringilla. Donec id pulvinar risus. Nunc imperdiet feugiat
            odio, mattis viverra tellus venenatis in. In accumsan, nibh sed rutrum luctus, leo est
            vestibulum eros, non sollicitudin lectus ipsum in velit. Ut non arcu nulla. Curabitur et
            lacus cursus, fringilla nunc non, lacinia nisl. Ut pharetra et enim quis tincidunt.
            Aliquam elementum lectus id nunc mattis, vel rutrum tellus venenatis. Sed fringilla elit
            dolor, ut facilisis nulla egestas sit amet. Etiam condimentum leo eget nibh eleifend,
            egestas venenatis mi feugiat. Mauris viverra nulla vel odio lobortis mattis. Aenean in
            nulla nec nisi ultrices sodales vitae nec quam. Curabitur urna ipsum, elementum
            vestibulum molestie mattis, molestie non augue. Quisque vel ex sit amet ante tempus
            scelerisque vel vel urna. Aenean tellus tellus, dignissim vel fermentum vitae, accumsan
            condimentum purus. Donec sagittis libero quis leo dignissim, eu tincidunt metus
            malesuada. Proin eget pharetra ante. Maecenas sed leo placerat, ullamcorper ante in,
            semper tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed purus
            felis, tincidunt sollicitudin lacus sit amet, tempus pharetra ante. Aliquam erat
            volutpat. Donec in erat turpis. Aliquam luctus odio rutrum, tincidunt arcu ut,
            pellentesque lectus. Aliquam pharetra efficitur mauris sit amet efficitur. Integer quis
            luctus risus, vel luctus neque. Praesent id sodales quam. Sed tempor facilisis metus,
            sed porttitor quam luctus nec. Fusce id justo sit amet sapien finibus placerat.
            Suspendisse sed lacus erat. Quisque et finibus lectus. Fusce vitae pretium eros. Donec
            sem erat, ullamcorper vel tempor at, lobortis ac turpis. Nulla at aliquam lorem. Nullam
            pharetra diam sem, sed vulputate leo molestie id. Nulla finibus lobortis orci eget
            ullamcorper. Donec arcu enim, rutrum pharetra commodo eget, rhoncus aliquet urna. Ut at
            diam pellentesque, dignissim lacus auctor, fringilla justo. Vivamus vitae massa ut arcu
            venenatis feugiat. Aenean faucibus sodales placerat. Praesent consequat purus vitae
            blandit volutpat. Vivamus tellus metus, eleifend sit amet arcu sed, dignissim
            consectetur tellus. Mauris cursus augue non convallis maximus. Phasellus sagittis
            tristique justo, vitae dapibus odio. Aliquam tristique auctor nulla vel gravida. Aliquam
            eget malesuada nunc, sed sollicitudin erat. Etiam sed ultricies quam. Nulla viverra
            felis eget quam ultrices, id hendrerit nisl efficitur. Morbi sodales mi ut nulla
            pellentesque, in facilisis quam vulputate. Nunc in nulla nunc. Donec scelerisque auctor
            tellus. Phasellus bibendum massa nisl, nec semper justo bibendum et. Duis eu purus
            neque. Proin et eros bibendum, efficitur sapien eu, ultricies nibh. Integer ex libero,
            commodo vel augue vel, ultricies lobortis quam. In blandit metus molestie hendrerit
            viverra. Nam scelerisque, quam et euismod aliquet, enim lectus fringilla quam, cursus
            lobortis tellus justo vel felis. Sed dignissim nulla eu congue consectetur. Sed semper
            enim et enim eleifend facilisis. Maecenas viverra risus urna, vel dignissim mi tincidunt
            fermentum. In aliquet lacus nec nisl lobortis tristique. Nam at nisl ullamcorper,
            posuere nisl at, maximus dui. Aliquam laoreet porta sapien. Proin tincidunt condimentum
            risus ac commodo.
        </AppParagraph>
    </>
);
