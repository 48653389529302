import { ChildId, MailThreadChildReceiverDTO, SelectOptionType } from 'types';

export const parseMailThreadChildReceiverDTOIntoSelectOptionType: (
    mailThreadChildReceiverDTO: MailThreadChildReceiverDTO,
) => SelectOptionType<ChildId, string> = (mailThreadChildReceiverDTO) => {
    const { childId, name } = mailThreadChildReceiverDTO;
    return {
        value: childId,
        label: name,
    };
};
