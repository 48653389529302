import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { useFirebaseSession } from 'services/firebase/useFirebaseSession';
import { ComponentBlockingAppLoader } from 'components/atoms';
import { authStateAtom } from 'jotaiAtoms/authState';
import { appPaths } from 'consts/paths/paths';
import { AppContainer } from './GlobalLayout.styled';

export const GlobalLayout = () => {
    useFirebaseSession();
    const { pathname } = useLocation();
    const authState = useAtomValue(authStateAtom);
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname.includes(appPaths.auth.base) && authState === 'authorized') {
            navigate(appPaths.app.base, { replace: true });
        }
    }, [authState, navigate, pathname]);

    const content = useMemo(() => {
        if (authState === 'unknown') {
            return <ComponentBlockingAppLoader />;
        }
        return <Outlet />;
    }, [authState]);

    return <AppContainer>{content}</AppContainer>;
};
