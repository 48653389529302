import { MailThreadReceiverDTO } from 'types';
import { ExtendedDataNode } from 'components/atoms';
import { messagesTexts } from 'consts/text';
import { parseMailThreadReceiverDTOIntoExtendedDataNode } from 'utils/parsers/mailThread/parseMailThreadReceiverDTOIntoExtendedDataNode';

const DATA_NODE_KEY = 'employees';

export const parseMailThreadReceiverDTOsIntoExtendedDataNodes: (
    mailThreadReceiverDTOs: MailThreadReceiverDTO[],
) => ExtendedDataNode[] = (mailThreadReceiverDTOs) => {
    const childrenDataNodes: ExtendedDataNode[] = mailThreadReceiverDTOs.map(
        parseMailThreadReceiverDTOIntoExtendedDataNode,
    );
    return [
        {
            title: messagesTexts.recipientsPicker.employees,
            key: DATA_NODE_KEY,
            children: childrenDataNodes,
        },
    ];
};
