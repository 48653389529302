import { MailThreadUserDTO } from 'types';
import { getFullAuthorName } from 'utils/getFullAuthorName';

export const getMailThreadMembersNames = (
    members: MailThreadUserDTO[],
    divideIntoLines?: boolean,
) =>
    members
        .map((member) => getFullAuthorName(member.name, member.subName))
        .join(`,${divideIntoLines ? '\n' : ''}`);
