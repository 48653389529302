import { Rule } from 'antd/es/form';
import styled from 'styled-components';
import { AppFormItem, AppFormItemProps, AppTextEditor } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { textEditorNoContentValidator } from 'utils/validators';
import { FormFieldsType } from 'types';
import { max2000LengthRule } from 'consts/rules/textRules';

export const StyledAppTextEditor = styled(AppTextEditor)`
    .ql-container {
        height: 400px;
    }
`;

export const DailyScheduleInputElement = ({ optional, ...restProps }: AppFormItemProps) => {
    const rules: Rule[] = [
        {
            validator: (_, description: FormFieldsType['description']) => {
                const isNoText = textEditorNoContentValidator(description);
                if (!optional && isNoText) {
                    return Promise.reject(new Error(formElementRequiredText));
                }
                return Promise.resolve();
            },
        },
        max2000LengthRule,
    ];

    return (
        <AppFormItem
            name={formFields.description}
            rules={rules}
            optional={optional}
            hasOptionalText
            initialValue=""
            {...restProps}
        >
            <StyledAppTextEditor />
        </AppFormItem>
    );
};
