import { ChildPersonalDataIcon } from 'assets';
import { Child } from 'types';
import { ChildGroupDisplay } from 'components/molecules';
import { childSexLabels } from 'consts/child/childSexLabels';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { childrenTexts } from 'consts/text';
import { DetailsColumn, DetailsRow, DetailsFrameBase } from 'components/atoms';

export type PersonalDataProps = Pick<
    Child,
    'sex' | 'pesel' | 'groups' | 'birthPlace' | 'birthDate'
>;

export const PersonalData = ({ sex, pesel, groups, birthPlace, birthDate }: PersonalDataProps) => (
    <DetailsFrameBase
        icon={<ChildPersonalDataIcon />}
        title={childrenTexts.childDetails.personalData.personalData}
    >
        <DetailsRow>
            <DetailsColumn title={childrenTexts.childDetails.personalData.sex} span={8}>
                {childSexLabels[sex]}
            </DetailsColumn>
            <DetailsColumn title={childrenTexts.childDetails.personalData.pesel} span={8}>
                {pesel}
            </DetailsColumn>
            <DetailsColumn title={childrenTexts.childDetails.personalData.group} span={8}>
                <ChildGroupDisplay childGroups={groups} />
            </DetailsColumn>
        </DetailsRow>
        <DetailsRow>
            <DetailsColumn title={childrenTexts.childDetails.personalData.birthPlace} span={12}>
                {birthPlace}
            </DetailsColumn>
            <DetailsColumn title={childrenTexts.childDetails.personalData.birthDate} span={12}>
                {parseStringIntoFormattedStringDate(birthDate)}
            </DetailsColumn>
        </DetailsRow>
    </DetailsFrameBase>
);
