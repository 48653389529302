const idDocumentNumberFirstDigitCharacterPosition = 3;

export function formatIdDocumentNumber(idDocumentNumber: string) {
    const letterCharactersSubstring = idDocumentNumber.slice(
        0,
        idDocumentNumberFirstDigitCharacterPosition,
    );
    const digitCharactersSubstring = idDocumentNumber.slice(
        idDocumentNumberFirstDigitCharacterPosition,
    );

    return `${letterCharactersSubstring} ${digitCharactersSubstring}`;
}
