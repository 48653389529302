import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconFilter } from '@tabler/icons-react';
import { AppButton } from 'components/atoms';
import { commonTexts } from 'consts/text/index';

const OuterContainer = styled.div`
    ${({ theme }) => css`
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 54px 12px 16px 12px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        z-index: 99;
        background-color: ${theme.colors.grayscale.gray1};
        box-sizing: border-box;
    `}
`;

const FiltersContentContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.sizes.marginNormal}px;
    `}
`;

const ButtonsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.sizes.marginNormal}px;
    `}
`;

type MobileFiltersProps = {
    filtersContent?: ReactNode;
    onClearFilters?: () => void;
};

export const MobileFilters = ({ filtersContent, onClearFilters }: MobileFiltersProps) => {
    const [isOpenedMobileFilters, setIsOpenedMobileFilters] = useState(false);

    return (
        <>
            <AppButton
                htmlType="button"
                icon={<IconFilter />}
                fullWidth
                onClick={() => setIsOpenedMobileFilters(true)}
            >
                Filtry
            </AppButton>
            {isOpenedMobileFilters && (
                <OuterContainer>
                    <FiltersContentContainer>{filtersContent}</FiltersContentContainer>
                    <ButtonsContainer>
                        <AppButton
                            type="primary"
                            htmlType="button"
                            fullWidth
                            onClick={() => setIsOpenedMobileFilters(false)}
                        >
                            Pokaż wyniki
                        </AppButton>
                        <AppButton htmlType="button" fullWidth onClick={onClearFilters}>
                            {commonTexts.actionLabels.clear}
                        </AppButton>
                    </ButtonsContainer>
                </OuterContainer>
            )}
        </>
    );
};
