import { useMemo, useState } from 'react';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { AppFormItemProps } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { ChildWithParentsIds } from 'types';
import { getUniqueParentsIdsFromChildrenWithParentsIds } from 'utils/getUniqueParentsIdsFromChildrenWithParentsIds';
import { ParentsPickerElement } from 'components/molecules';
import {
    ParentsPickerDataProps,
    useParentsPickerData,
} from 'hooks/useParentsPickerData/useParentsPickerData';
import { ChildrenWithParentsPickerModal } from 'components/organisms/ChildrenWithParentsPickerModal/ChildrenWithParentsPickerModal';

type ChildrenWithParentsPickerProps = AppFormItemProps & ParentsPickerDataProps;

export const ChildrenWithParentsPicker = ({
    journalId,
    date,
    ...restProps
}: ChildrenWithParentsPickerProps) => {
    const { childrenData, parentsOptions, isChildrenDataFetching } = useParentsPickerData({
        journalId,
        date,
    });
    const [isOpenedModal, setIsOpenedModal] = useState(false);
    const childrenWithParentsIds = useWatch<ChildWithParentsIds[] | undefined>(
        formFields.childrenWithParentsIds,
    );
    const { setFieldValue } = useFormInstance();

    const handleCloseModal = () => setIsOpenedModal(false);

    const handleSaveSelectedValues = (selectedChildrenWithParents: ChildWithParentsIds[]) => {
        setFieldValue(formFields.childrenWithParentsIds, selectedChildrenWithParents);
        handleCloseModal();
    };

    const selectValues = useMemo(() => {
        if (!childrenWithParentsIds) {
            return [];
        }
        return getUniqueParentsIdsFromChildrenWithParentsIds(childrenWithParentsIds);
    }, [childrenWithParentsIds]);

    return (
        <>
            <ChildrenWithParentsPickerModal
                open={isOpenedModal}
                isLoading={isChildrenDataFetching}
                initialSelectedChildrenWithParentsIds={childrenWithParentsIds || []}
                childrenData={childrenData || []}
                onSave={handleSaveSelectedValues}
                onCancel={handleCloseModal}
            />
            <ParentsPickerElement
                name={formFields.childrenWithParentsIds}
                selectProps={{
                    value: selectValues,
                    options: parentsOptions,
                    onClick: () => setIsOpenedModal(true),
                }}
                {...restProps}
            />
        </>
    );
};
