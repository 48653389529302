import { ChildAdditionalInformationIcon } from 'assets';
import { childrenTexts } from 'consts/text';
import { Child, ChildDiet } from 'types';
import { DetailsColumn, DetailsRow, DetailsFrameBase } from 'components/atoms';

export type AdditionalInformationProps = Pick<Child, 'diseases' | 'allergies'> & {
    dietDetails?: ChildDiet | null;
};

export const AdditionalInformation = ({
    diseases,
    allergies,
    dietDetails,
}: AdditionalInformationProps) => {
    const mealNames = childrenTexts.childDetails.additionalInfo.dietMeals;

    const filteredMeals =
        dietDetails?.institutionDiet &&
        Object.entries(dietDetails)
            .filter(
                ([meal, isMealSelected]) =>
                    isMealSelected && mealNames[meal as keyof typeof mealNames],
            )
            .map(([meal]) => mealNames[meal as keyof typeof mealNames])
            .join(', ');

    return (
        <DetailsFrameBase
            icon={<ChildAdditionalInformationIcon />}
            title={childrenTexts.childDetails.additionalInfo.additionalInfo}
        >
            <DetailsRow>
                <DetailsColumn title={childrenTexts.childDetails.additionalInfo.diet} span={12}>
                    {dietDetails?.institutionDiet?.name}
                </DetailsColumn>
                <DetailsColumn title={childrenTexts.childDetails.additionalInfo.meals} span={12}>
                    {filteredMeals}
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={childrenTexts.childDetails.additionalInfo.diseases}>
                    {diseases}
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={childrenTexts.childDetails.additionalInfo.allergies}>
                    {allergies}
                </DetailsColumn>
            </DetailsRow>
        </DetailsFrameBase>
    );
};
