import { authTexts } from 'consts/text/index';
import { Basis, LabelMapper } from 'types';

export const psychologicalHelpBasesLabels: LabelMapper<Basis> = {
    INDIVIDUAL_EDUCATION_NEED: 'Orzeczenie o potrzebie kształcenia specjalnego',
    EARLY_DEVELOPMENT_SUPPORT: 'Opinia o wczesnym wspomaganiu rozwoju',
    COUNSELING_OPINION: 'Opinia z poradni',
    DOCTOR_OPINION: 'Opinia lekarza',
    SPECIALIST_OPINION: 'Opinia specjalisty',
    OTHER: authTexts.otherInstitutionLabel,
};
