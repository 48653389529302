export const apiErrorCodes = {
    USER_EMAIL_TAKEN: 'USER_EMAIL_TAKEN',
    CHILD_GUARDIAN_ID_DUPLICATE: 'CHILD_GUARDIAN_ID_DUPLICATE',
    CHILD_LAST_PARENT_DELETE: 'CHILD_LAST_PARENT_DELETE',
    LESSON_ALREADY_HAS_TOPIC: 'LESSON_ALREADY_HAS_TOPIC',
    ARCHIVED_CHILD_UPDATE_FORBIDDEN: 'ARCHIVED_CHILD_UPDATE_FORBIDDEN',
    LESSON_MODIFICATION_FORBIDDEN: 'LESSON_MODIFICATION_FORBIDDEN',
    LESSON_ACCESS_FORBIDDEN: 'LESSON_ACCESS_FORBIDDEN',
    CHILD_PARENTS_WITHOUT_ACCOUNT: 'CHILD_PARENTS_WITHOUT_ACCOUNT',
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    INSTITUTION_CHILD_LIMIT_EXCEEDED: 'INSTITUTION_CHILD_LIMIT_EXCEEDED',
    ABSENCE_DATES_NOT_CORRECT: 'ABSENCE_DATES_NOT_CORRECT',
    ABSENCES_ALREADY_EXIST: 'ABSENCES_ALREADY_EXIST',
    FORBIDDEN_TO_MODIFY_ABSENCE: 'FORBIDDEN_TO_MODIFY_ABSENCE',
    JOURNAL_NOT_FOUND: 'JOURNAL_NOT_FOUND',
    GROUP_NAME_TAKEN: 'GROUP_NAME_TAKEN',
    DELETE_ASSIGNED_ROLE: 'DELETE_ASSIGNED_ROLE',
    DIET_IN_USE_REMOVE_ATTEMPT: 'DIET_IN_USE_REMOVE_ATTEMPT',
    MENU_DATE_OVERLAPPING: 'MENU_DATE_OVERLAPPING',
    CHILD_HAS_ALREADY_REPLIED_TO_THREAD: 'CHILD_HAS_ALREADY_REPLIED_TO_THREAD',
    REPLY_MAIL_THREAD_NOT_FOUND: 'REPLY_MAIL_THREAD_NOT_FOUND',
    NOT_LATEST_MONTHLY_SETTLEMENT: 'NOT_LATEST_MONTHLY_SETTLEMENT',
    CHILD_PARENT_EMAIL_DUPLICATE: 'CHILD_PARENT_EMAIL_DUPLICATE',
    BILL_ALREADY_PAID: 'BILL_ALREADY_PAID',
};
