import { AxiosError } from 'axios';
import { Store } from 'rc-field-form/lib/interface';
import { childAddEditErrorParser } from 'utils/errorHandlers/child/childAddEditErrorParser';
import { StepsForm, StepsFormProps } from 'components/organisms';
import { formFields } from 'consts/form/formFields';
import { AddChildFormFields, FormTypes } from 'types';
import { childrenTexts } from 'consts/text';
import { ChildAddStep1 } from './components/Step1/Step1';
import { ChildAddStep2 } from './components/Step2/Step2';
import { ChildAddEditStep3 } from './components/Step3/Step3';
import { checkIfParentHaveDuplicatedEmails } from './ChildAddStepsForm.utils';

type ChildAddStepsFormProps = Pick<
    StepsFormProps,
    'onFinish' | 'isLoading' | 'formPagesInitialStates'
> & {
    axiosError?: AxiosError | null;
};

const childAddStep2Validation = (formValues: Store) => {
    const parentsValue = formValues[formFields.parents] as AddChildFormFields['parents'];
    const isAddedParentWithAccount = parentsValue.some((parent) => parent.withAccount);
    if (!isAddedParentWithAccount) {
        return childrenTexts.minOneChildParentWithAccountErrorMessage;
    }
    const areParentEmailsDuplicated = checkIfParentHaveDuplicatedEmails(parentsValue);
    if (areParentEmailsDuplicated) {
        return childrenTexts.childParentEmailDuplicateErrorMessage2;
    }
    return '';
};

export const ChildAddStepsForm = ({
    formPagesInitialStates,
    axiosError,
    isLoading,
    onFinish,
}: ChildAddStepsFormProps) => {
    const formPagesNames: FormTypes[] = ['childAddStep1', 'childAddStep2', 'childAddStep3'];

    const childAddEditError = axiosError ? childAddEditErrorParser(axiosError) : '';

    return (
        <StepsForm
            mode="add"
            formPagesInitialStates={formPagesInitialStates}
            formPagesMaxWidths={[695, 1000, 695]}
            onFinish={onFinish}
            withGoBack
            isLoading={isLoading}
            formPagesNames={formPagesNames}
            innerFormModalsNames={['guardianAdd', 'parentAdd', 'guardianEdit', 'parentEdit']}
            components={[ChildAddStep1, ChildAddStep2, ChildAddEditStep3]}
            error={childAddEditError}
            formPagesValidation={{
                childAddStep2: childAddStep2Validation,
            }}
        />
    );
};
