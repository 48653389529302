import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, messagesTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const mailThreadAddEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.CHILD_HAS_ALREADY_REPLIED_TO_THREAD) {
        return messagesTexts.addSecondReplyMailThreadErrorMessage;
    }

    return commonTexts.errorMessages.unknown;
};
