import { useMemo } from 'react';
import { MailThreadUserDTO, UserId } from 'types';
import { AppText, AppTextProps } from 'components/atoms/CommonAppComponents';
import {
    MessageMemberName,
    MessageMemberNameProps,
} from 'components/atoms/MessageMemberName/MessageMemberName';
import { messagesTexts } from 'consts/text';

type MailThreadReceiversNamesProps = Pick<
    MessageMemberNameProps,
    'baseTextProps' | 'subnameTextProps'
> & {
    members: MailThreadUserDTO[];
    authorUserId: UserId;
    ellipsis?: AppTextProps['ellipsis'];
    className?: string;
};

export const MailThreadReceiversNames = ({
    members,
    authorUserId,
    baseTextProps,
    subnameTextProps,
    ellipsis,
    className,
}: MailThreadReceiversNamesProps) => {
    const content = useMemo(() => {
        // TODO: to change after backend changes
        const membersWithoutAuthor = members.filter((member) => member.userId !== authorUserId);
        return membersWithoutAuthor.map((member, index) => {
            const { userId, name, subName } = member;
            return (
                <MessageMemberName
                    key={userId}
                    authorName={name}
                    authorSubname={subName}
                    withComma={index !== membersWithoutAuthor.length - 1}
                    baseTextProps={baseTextProps}
                    subnameTextProps={subnameTextProps}
                />
            );
        });
    }, [authorUserId, baseTextProps, members, subnameTextProps]);

    return (
        <AppText ellipsis={ellipsis} className={className} margin={0}>
            {`${messagesTexts.to} `}
            {content}
        </AppText>
    );
};
