import { GroupAddEditForm } from 'components/templates/group';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { GroupFormFields } from 'types';
import { parseGroupFormFieldsIntoAddGroupDTO } from 'utils/parsers/group/parseGroupFormFieldsIntoAddGroupDTO';
import { groupsTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const onAddGroupSuccess = () => {
        displaySuccess(groupsTexts.successfullyAddGroupMessage);
        statefulNavigate(appPaths.app.groups.base, { replace: true });
    };

    const {
        mutate: addGroup,
        error: addGroupError,
        isLoading: isAddGroupLoading,
    } = useAppMutation(StaffHTTPService.groups.createGroup, {
        key: ['ADD_GROUP'],
        onSuccess: onAddGroupSuccess,
        invalidateQueryKeys: [
            ['GROUPS'],
            ['MENUS_GROUPS'],
            ['JOURNALS_GROUPS'],
            ['MAIL_THREAD_RECEIVERS'],
            ['CHILDREN'],
            ['CHILD'],
        ],
    });

    const onAddGroupFinish = (groupData: GroupFormFields) =>
        addGroup(parseGroupFormFieldsIntoAddGroupDTO(groupData));

    return (
        <GroupAddEditForm
            mode="add"
            axiosError={addGroupError}
            isLoading={isAddGroupLoading}
            onFinish={onAddGroupFinish}
        />
    );
};
