import { Form } from 'antd';
import { AppFormBase, AppBodyRow, AppBodyRowProps } from 'components/atoms';
import { WorkingTimeFormFields } from 'types';

export const WorkingTimeEditableRow = ({ ...props }: AppBodyRowProps) => {
    const [form] = Form.useForm<WorkingTimeFormFields>();

    return (
        <AppFormBase name="workingTimeEdit" form={form} component={false}>
            <AppBodyRow {...props} />
        </AppFormBase>
    );
};
