import React, { useContext, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/es/form/Form';
import { ModalTreePickerElement } from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { formFields } from 'consts/form/formFields';
import { UserCheckBottomIcon } from 'assets';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildId, UserId } from 'types';
import { commonTexts, messagesTexts } from 'consts/text';
import { AppFormItem, AppSelect, StyledPlusIcon } from 'components/atoms';
import { parseMailThreadReceiverDTOIntoSelectOptionType } from 'utils/parsers/mailThread/parseMailThreadReceiverDTOIntoSelectOptionType';
import { parseMailThreadReceiverDTOsIntoExtendedDataNodes } from 'utils/parsers/mailThread/parseMailThreadReceiverDTOsIntoExtendedDataNodes';
import { parseMailThreadChildReceiverDTOIntoSelectOptionType } from 'utils/parsers/mailThread/parseMailThreadChildReceiverDTOIntoSelectOptionType';
import { parseMailThreadChildReceiverDTOsIntoExtendedDataNodes } from 'utils/parsers/mailThread/parseMailThreadChildReceiverDTOsIntoExtendedDataNodes';
import { parseMailThreadChildrenGroupDTOsIntoSelectOptionTypeItems } from 'utils/parsers/mailThread/parseMailThreadChildrenGroupDTOsIntoSelectOptionTypeItems';
import { parseMailThreadChildrenGroupDTOsIntoExtendedDataNodes } from 'utils/parsers/mailThread/parseMailThreadChildrenGroupDTOsIntoExtendedDataNodes';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { parseRecipientKeyIntoRecipientId } from 'utils/parsers/mailThread/parseRecipientKeyIntoRecipientId';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type MailThreadRecipientsPickerProps = {
    disabled?: boolean;
};

const StyledAppSelect = styled(AppSelect)`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const MailThreadRecipientsPicker = ({ disabled }: MailThreadRecipientsPickerProps) => {
    const { setFieldValue, setFieldsValue } = useFormInstance();
    const { formError, setFormError } = useContext(AppFormContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedRecipientsTreePickerKeys, setSelectedRecipientsTreePickerKeys] = useState<
        string[]
    >([]);
    const selectedChildIds = useWatch<ChildId[] | undefined>(formFields.childIds);
    const selectedStaffIds = useWatch<UserId[] | undefined>(formFields.userIds);

    const selectedRecipients = useMemo(() => {
        if (selectedChildIds && selectedStaffIds) {
            return [...selectedChildIds, ...selectedStaffIds];
        }
        if (selectedChildIds) {
            return selectedChildIds;
        }
        if (selectedStaffIds) {
            return selectedStaffIds;
        }
        return [];
    }, [selectedChildIds, selectedStaffIds]);

    const {
        data: mailThreadReceiversData,
        isFetching: isMailThreadReceiversDataFetching,
        isError: isMailThreadReceiversDataError,
    } = useAppQuery(
        'MAIL_THREAD_RECEIVERS',
        [],
        StaffHTTPService.mailThreads.getMailThreadReceivers,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isMailThreadReceiversDataFetching,
        isError: isMailThreadReceiversDataError,
        actualError: formError,
        errorMessage: messagesTexts.recipientsPicker.fetchingError,
        setError: setFormError,
    });

    const parsedRecipientsData = useMemo(() => {
        if (!mailThreadReceiversData) {
            return {
                selectOptions: [],
                treeData: [],
                staffIds: [],
                childrenIds: [],
            };
        }
        const { staff, groups, specialGroups, childrenWithoutGroups } = mailThreadReceiversData;

        const mappedStaffDataForTree = staff.length
            ? parseMailThreadReceiverDTOsIntoExtendedDataNodes(staff)
            : [];
        const mappedStaffDataForPicker = staff.map(parseMailThreadReceiverDTOIntoSelectOptionType);

        const mappedGroupsDataForPicker =
            parseMailThreadChildrenGroupDTOsIntoSelectOptionTypeItems(groups);
        const mappedGroupsDataForTree = groups.length
            ? parseMailThreadChildrenGroupDTOsIntoExtendedDataNodes(groups, 'JOURNAL_GROUP')
            : [];
        const groupsChildrenIds = mappedGroupsDataForPicker.map((child) => child.value);

        const mappedSpecialGroupsDataForPicker =
            parseMailThreadChildrenGroupDTOsIntoSelectOptionTypeItems(specialGroups);
        const mappedSpecialGroupsDataForTree = specialGroups.length
            ? parseMailThreadChildrenGroupDTOsIntoExtendedDataNodes(
                  specialGroups,
                  'JOURNAL_SPECIAL',
              )
            : [];
        const specialGroupsChildrenIds = mappedSpecialGroupsDataForPicker.map(
            (child) => child.value,
        );

        const mappedChildrenWithoutGroupsDataForPicker = childrenWithoutGroups
            ? childrenWithoutGroups.map(parseMailThreadChildReceiverDTOIntoSelectOptionType)
            : [];
        const mappedChildrenWithoutGroupsDataForTree = childrenWithoutGroups?.length
            ? parseMailThreadChildReceiverDTOsIntoExtendedDataNodes(childrenWithoutGroups)
            : [];
        const childrenWithoutGroupsIds = childrenWithoutGroups
            ? childrenWithoutGroups.map((child) => child.childId)
            : [];

        const selectOptions = [
            ...mappedStaffDataForPicker,
            ...mappedGroupsDataForPicker,
            ...mappedSpecialGroupsDataForPicker,
            ...mappedChildrenWithoutGroupsDataForPicker,
        ];
        const treeData = [
            ...mappedStaffDataForTree,
            ...mappedGroupsDataForTree,
            ...mappedSpecialGroupsDataForTree,
            ...mappedChildrenWithoutGroupsDataForTree,
        ];

        const staffIds = staff.map((staffMember) => staffMember.userId);
        const childrenIds = [
            ...groupsChildrenIds,
            ...specialGroupsChildrenIds,
            ...childrenWithoutGroupsIds,
        ];

        return {
            selectOptions,
            treeData,
            staffIds,
            childrenIds,
        };
    }, [mailThreadReceiversData]);

    const onClear = () =>
        setFieldsValue({ [formFields.childIds]: undefined, [formFields.userIds]: undefined });

    const onModalOpen = () => setIsModalOpen(true);

    const onModalClose = () => setIsModalOpen(false);

    const onSave = (recipientsTreePickerKeys: React.Key[]) => {
        const recipientsKeys = recipientsTreePickerKeys as string[];
        const { staffIds, childrenIds } = parsedRecipientsData;
        const recipientsIds = recipientsKeys.map(parseRecipientKeyIntoRecipientId);
        const uniqueRecipientsIds = uniq(recipientsIds);
        const filteredStaffIds = uniqueRecipientsIds.filter((recipientsId) =>
            staffIds.includes(recipientsId as UserId),
        );
        const filteredChildrenIds = uniqueRecipientsIds.filter((recipientsId) =>
            childrenIds.includes(recipientsId as ChildId),
        );
        setSelectedRecipientsTreePickerKeys(recipientsKeys);
        setFieldValue(formFields.userIds, filteredStaffIds);
        setFieldValue(formFields.childIds, filteredChildrenIds);
        onModalClose();
    };

    const { treeData, selectOptions } = parsedRecipientsData;

    return (
        <>
            <AppFormItem name={formFields.childIds} optional marginBottom={0}>
                <input type="hidden" />
            </AppFormItem>
            <AppFormItem name={formFields.userIds} optional marginBottom={0}>
                <input type="hidden" />
            </AppFormItem>
            <ModalTreePickerElement
                title={commonTexts.actionLabels.addReceivers}
                open={isModalOpen}
                initialValue={selectedRecipientsTreePickerKeys}
                treeData={treeData}
                icon={<UserCheckBottomIcon />}
                isLoading={isMailThreadReceiversDataFetching}
                onCancel={onModalClose}
                onSave={onSave}
            />
            <StyledAppSelect
                label={messagesTexts.to}
                value={selectedRecipients}
                options={selectOptions}
                loading={isMailThreadReceiversDataFetching}
                mode="multiple"
                suffixIcon={<StyledPlusIcon />}
                open={false}
                disabled={disabled}
                showSearch={false}
                onClick={!disabled ? onModalOpen : undefined}
                onClear={onClear}
            />
        </>
    );
};
