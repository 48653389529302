import { ReactNode } from 'react';
import { AppButton, AppSearchInput } from 'components/atoms';
import { Filter, FilterProps } from 'components/molecules';
import { MobileFilters } from 'components/organisms/MobileFilters/MobileFilters';
import { commonTexts } from 'consts/text/index';
import { Container } from './TableManageUtils.styled';

export type SearchInputProps = {
    defaultValue?: string;
    isHiddenLabel?: boolean;
    width?: FilterProps['width'];
    onInput?: (input: string) => void;
};

export type TableManageUtilsProps = {
    searchInputProps?: SearchInputProps;
    additionalFilters?: ReactNode;
    isVisibleSearchFilter?: boolean;
    isMobile?: boolean;
    isDisplayedAsSeparateMenuOnMobile?: boolean;
    onClearFilters?: (clearSearchFilter?: boolean) => void;
};

const DEFAULT_SEARCH_INPUT_WIDTH = '30%';

export const TableManageUtils = ({
    searchInputProps,
    additionalFilters,
    isVisibleSearchFilter = true,
    isMobile,
    isDisplayedAsSeparateMenuOnMobile,
    onClearFilters,
}: TableManageUtilsProps) => {
    const searchInput = (
        <AppSearchInput
            defaultValue={searchInputProps?.defaultValue}
            onInput={(event) => searchInputProps?.onInput?.(event.currentTarget.value)}
        />
    );

    const desktopFilters = (
        <>
            {additionalFilters}
            {onClearFilters && (
                <AppButton onClick={() => onClearFilters(true)} margin={0} marginTop={24}>
                    {commonTexts.actionLabels.clear}
                </AppButton>
            )}
        </>
    );

    return (
        <Container>
            {isVisibleSearchFilter && (
                <Filter
                    input={searchInput}
                    label={!searchInputProps?.isHiddenLabel ? 'Wyszukaj' : undefined}
                    width={searchInputProps?.width || DEFAULT_SEARCH_INPUT_WIDTH}
                />
            )}
            {isMobile && additionalFilters && isDisplayedAsSeparateMenuOnMobile ? (
                <MobileFilters
                    filtersContent={additionalFilters}
                    onClearFilters={() => onClearFilters?.(false)}
                />
            ) : (
                desktopFilters
            )}
        </Container>
    );
};
