import { DietPickerElement } from 'components/molecules/FormElements/DietPickerElement/DietPickerElement';
import { ChildrenMealSelection } from 'components/templates/child/ChildAddStepsForm/components/Step3/components/ChildrenMealsElement';
import { formFields } from 'consts/form/formFields';
import { AppFormContext } from 'contexts/AppFormContext';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useWatch } from 'rc-field-form';
import { useContext, useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { DietDTO, DietId } from 'types';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { AxiosResponse } from 'axios';

type DietPickerProps = {
    httpMethod: {
        getDiets: () => Promise<AxiosResponse<DietDTO[]>>;
    };
};
export const DietPicker = ({ httpMethod }: DietPickerProps) => {
    const form = useFormInstance();
    const currentInstitution = useCurrentInstitution();
    const { formError, setFormError } = useContext(AppFormContext);
    const dietPickerValue = useWatch<DietId | undefined>(formFields.diet, form);

    const {
        data: dietsData,
        isFetching: isDietsDataFetching,
        isError: isDietsDataError,
    } = useAppQuery('DIETS', [currentInstitution.id], httpMethod.getDiets, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useDisplayFetchDataError({
        isFetching: isDietsDataFetching,
        isError: isDietsDataError,
        actualError: formError,
        errorMessage: 'Nie udało się pobrać danych o dietach',
        setError: setFormError,
    });

    const options = useMemo(() => {
        if (!dietsData) {
            return [];
        }
        return dietsData.map((diet) => ({
            value: diet.id,
            label: diet.name,
        }));
    }, [dietsData]);

    const { breakfastPrice, snackPrice, soupPrice, dinnerPrice } =
        dietsData?.find((diet) => diet.id === dietPickerValue) || {};

    const mealsForDiet = { breakfastPrice, snackPrice, soupPrice, dinnerPrice };

    return (
        <>
            <DietPickerElement
                options={options}
                loading={isDietsDataFetching}
                onChange={() => form.setFieldValue(formFields.selectMealsType, null)}
            />
            {dietPickerValue && <ChildrenMealSelection meals={mealsForDiet} />}
        </>
    );
};
