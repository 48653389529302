import { AxiosError } from 'axios';
import useForm from 'antd/es/form/hooks/useForm';
import {
    CommonFormProps,
    FormModeDependedTexts,
    JournalId,
    SpecialConsultationFormFields,
} from 'types';
import { AppForm, AppFormProps, JournalChildPicker } from 'components/organisms';
import {
    DatePickerElement,
    DescriptionInputElement,
    FormNavButtons,
    SimpleInputElement,
    SubjectInputElement,
} from 'components/molecules';
import { commonTexts, journalTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { useDependentFormFields } from 'hooks/useDependentFormFields/useDependentFormFields';
import { max300LengthRule } from 'consts/rules/textRules';

const addModeTexts: FormModeDependedTexts = {
    name: 'specialConsultationAdd',
    title: journalTexts.journalDetails.common.consultations.addConsultation,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'specialConsultationEdit',
    title: journalTexts.journalDetails.common.consultations.editConsultation,
    buttonText: commonTexts.actionLabels.save,
};

type ConsultationAddEditFormProps = Omit<
    AppFormProps<SpecialConsultationFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        journalId: JournalId;
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (consultationData: SpecialConsultationFormFields) => void;
    };
export const SpecialJournalConsultationAddEditForm = ({
    mode,
    form,
    journalId,
    axiosError,
    ...props
}: ConsultationAddEditFormProps) => {
    const [formInstance] = useForm<SpecialConsultationFormFields>(form);
    const {
        watchedFieldsValues: watchedDateValue,
        handleWatchedFieldsValuesChange: handleWatchedDateValueChange,
    } = useDependentFormFields({
        watchedFields: [formFields.date],
        dependentFields: [formFields.childIds],
        formInstance,
    });

    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const { date: dateValue } = watchedDateValue;
    const parsedDate = dateValue ? parseDayjsDateIntoString(dateValue, 'YYYY-MM-DD') : undefined;

    const consultationAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<SpecialConsultationFormFields>
            form={formInstance}
            name={name}
            title={title}
            error={consultationAddEditError}
            withGoBack
            {...props}
        >
            <SubjectInputElement
                label={journalTexts.journalDetails.common.consultations.consultationSubject}
            />
            <DatePickerElement isHalfParentWidth onChange={handleWatchedDateValueChange} />
            <DescriptionInputElement />
            <SimpleInputElement
                label={journalTexts.journalDetails.common.consultations.contactWith}
                name={formFields.contactWith}
                rules={[max300LengthRule]}
            />
            <JournalChildPicker
                label={commonTexts.actionLabels.selectChildren}
                journalId={journalId}
                dateFrom={parsedDate}
                dateTo={parsedDate}
                multiple
                disabled={!parsedDate}
            />
            <FormNavButtons
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
                withDeviceFloppy={!isAddMode}
            />
        </AppForm>
    );
};
