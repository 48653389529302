import { AxiosError, isAxiosError } from 'axios';

type HandleNetworkErrorOptions = object | undefined;

type HandleNetworkError = (
    error: Error | unknown | AxiosError,
    options?: HandleNetworkErrorOptions,
) => void;

const logError = (error: unknown) => {
    console.error(JSON.stringify(error, null, 4));
};

export const parseAxiosErrorToReadableFormat = (error: AxiosError) =>
    error?.request?.responseURL
        ? `Axios Error - ${JSON.stringify(
              {
                  url: error?.request?.responseURL,
                  method: error?.config?.method,
                  statusCode: error?.request?.status,
                  data: error?.response?.data,
              },
              null,
              4,
          )}`
        : error;

export const handleNetworkError: HandleNetworkError = (error) => {
    if (isAxiosError(error)) {
        const parsedError = parseAxiosErrorToReadableFormat(error);

        console.error(parsedError);
    } else {
        logError(error);
    }
};
