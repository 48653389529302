import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { ParentChildDetailsPage, ParentChildEditPage } from 'pages';

export const childRoutes: RouteObject[] = [
    {
        path: appPaths.app.parent['child-profile'].base,
        element: <ParentChildDetailsPage />,
    },
    {
        path: appPaths.app.parent['child-profile'].edit,
        element: <ParentChildEditPage />,
    },
];
