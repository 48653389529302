import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { AppDatePicker, AppDatePickerProps, AppText } from 'components/atoms';
import { commonTexts } from 'consts/text';
import {
    ArrowButton,
    ArrowButtonsContainer,
    InnerContainer,
    OuterContainer,
} from './DatePickerWithArrows.styled';

export type DatePickerArrowButtonType = 'NEXT' | 'PREVIOUS';

type DatePickerWithArrowsProps = Omit<AppDatePickerProps, 'allowClear'> & {
    onArrowButtonClick?: (arrowButtonType: DatePickerArrowButtonType) => void;
    label?: string;
    hideArrows?: boolean;
    prevDisabled?: boolean;
    nextDisabled?: boolean;
};

export const DatePickerWithArrows = ({
    onArrowButtonClick,
    label = commonTexts.actionLabels.selectDate,
    hideArrows = false,
    nextDisabled = false,
    prevDisabled = false,
    ...restProps
}: DatePickerWithArrowsProps) => (
    <OuterContainer>
        <AppText textType="BodyMSSemiBold" margin={0}>
            {label}
        </AppText>
        <InnerContainer>
            <AppDatePicker allowClear={false} {...restProps} />
            {!hideArrows && (
                <ArrowButtonsContainer>
                    <ArrowButton
                        icon={<IconChevronLeft size={20} />}
                        onClick={() => onArrowButtonClick?.('PREVIOUS')}
                        disabled={prevDisabled}
                    />
                    <ArrowButton
                        icon={<IconChevronRight size={20} />}
                        onClick={() => onArrowButtonClick?.('NEXT')}
                        disabled={nextDisabled}
                    />
                </ArrowButtonsContainer>
            )}
        </InnerContainer>
    </OuterContainer>
);
