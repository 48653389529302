import { AppDatePicker, AppDatePickerProps, AppLabel, AppLabelProps } from 'components/atoms';

type AppDatePickerWithLabelProps = AppDatePickerProps & Omit<AppLabelProps, 'children'>;

export const AppDatePickerWithLabel = ({
    label,
    isFitContentWidth,
    ...restProps
}: AppDatePickerWithLabelProps) => (
    <AppLabel label={label} isFitContentWidth={isFitContentWidth}>
        <AppDatePicker {...restProps} />
    </AppLabel>
);
