import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const DesktopRowContainer = styled.div`
    display: table-row;
`;

const DesktopCell = styled.div`
    ${({ theme }) => css`
        display: table-cell;
        border-bottom: 1px solid ${theme.colors.primary.primary3};
    `}
`;

export const DesktopLabelCell = styled(DesktopCell)`
    width: 20%;
    padding-right: 12px;
    text-align: right;
    color: ${({ theme }) => theme.colors.grayscale.gray8};
    ${getTextStyle('BodySmallSemiBold')}
`;

export const DesktopContentCell = styled(DesktopCell)`
    width: 80%;
    color: ${({ theme }) => theme.colors.grayscale.gray10};
    ${getTextStyle('BodyMedium')}
`;

export const MobileRowContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-bottom: 6px;
        border-bottom: 1px solid ${theme.colors.primary.primary3};

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    `}
`;

export const MobileLabel = styled.div`
    color: ${({ theme }) => theme.colors.grayscale.gray8};
    ${getTextStyle('BodySmallSemiBold')}
`;

export const MobileContentContainer = styled.div`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray10};
        ${getTextStyle('BodyMedium')};
    `}
`;
