import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { AppText } from 'components/atoms/CommonAppComponents/AppText/AppText';
import { getTextStyle } from 'utils/getTextStyle';
import { sizes } from 'theme/styledComponents/sizes';

type DetailsItemProps = {
    title: string;
    children: ReactNode;
    hasBorder?: boolean;
};

const Container = styled.div<{ hasBorder: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${({ hasBorder }) => css`
        border-bottom: ${hasBorder ? '1px solid #EAEEFE' : 'none'};
    `}
`;

const ContentContainer = styled.div`
    color: ${({ theme }) => theme.colors.grayscale.gray10};
    ${getTextStyle('BodyMedium')}
`;

export const DetailsItem = ({ title, children, hasBorder = false }: DetailsItemProps) => (
    <Container hasBorder={hasBorder}>
        <AppText margin={0} marginBottom={sizes.marginSmall} textType="BodyMSSemiBold">
            {title}
        </AppText>
        <ContentContainer>{children}</ContentContainer>
    </Container>
);
