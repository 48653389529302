import { FirebaseError } from 'firebase/app';
import {
    firebaseLoginErrors,
    unexpectedErrorLabel,
    WRONG_PASSWORD_FIREBASE_ERROR_CODE,
} from 'consts/errors/firebaseErrors';
import { commonTexts } from 'consts/text';

export const getFirebaseErrorLabel = (
    error: FirebaseError,
    isUpdatePasswordOperation?: boolean,
) => {
    const { code } = error;
    if (isUpdatePasswordOperation && code === WRONG_PASSWORD_FIREBASE_ERROR_CODE) {
        return commonTexts.errorMessages.wrongOldPassword;
    }
    const errorLabel = firebaseLoginErrors[code];
    return errorLabel || unexpectedErrorLabel;
};
