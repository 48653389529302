import { generatePath } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import { CalendarApi } from '@fullcalendar/core';
import { calendarTexts } from 'consts/text';
import styled, { css } from 'styled-components';
import { DatePickerArrowButtonType, DatePickerWithArrows } from 'components/molecules';
import { AppButton } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import dayjs, { Dayjs } from 'dayjs';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useCalendarAbilities } from 'abilities';
import { useContext } from 'react';
import { AppCalendarContext } from 'contexts/AppCalendarContext';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

type CalendarFiltersProps = {
    calendarApi: () => CalendarApi;
    handleClickNextOrPrevDate?: (
        selectedDateType: DatePickerArrowButtonType,
        unit?: 'day' | 'week' | 'month',
    ) => void;
    handleChangeDateFilter: (date: Dayjs) => void;
    selectedDate: Dayjs;
};

const FiltersContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 40px;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            flex-direction: column-reverse;
        }
    `}
`;

const AppButtonEnd = styled(AppButton)`
    margin-inline-start: auto;
`;

const AppButtonToday = styled(AppButton)`
    height: 44px;
`;

export const CalendarFilters = ({
    calendarApi,
    handleClickNextOrPrevDate,
    handleChangeDateFilter,
    selectedDate,
}: CalendarFiltersProps) => {
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const { setSelectedDate } = useContext(AppCalendarContext);

    const { calendarAbilities } = useCalendarAbilities();
    const canManageEvents = calendarAbilities.checkIfCanManageEvents();

    return (
        <FiltersContainer>
            <DatePickerWithArrows
                picker="month"
                format="MMMM"
                onArrowButtonClick={(arrowButtonType) => {
                    if (arrowButtonType === 'NEXT') {
                        handleClickNextOrPrevDate?.('NEXT', 'month');
                        calendarApi().next();
                    } else {
                        handleClickNextOrPrevDate?.('PREVIOUS', 'month');
                        calendarApi().prev();
                    }
                }}
                onChange={(date) => {
                    handleChangeDateFilter(date);
                    calendarApi().gotoDate(date.toDate());
                }}
                value={selectedDate}
                hideArrows={isMobile}
            />

            {!isMobile && (
                <AppButtonToday
                    margin={0}
                    onClick={() => {
                        handleChangeDateFilter(dayjs());
                        calendarApi().today();
                    }}
                >
                    {calendarTexts.today}
                </AppButtonToday>
            )}
            {canManageEvents && (
                <AppButtonEnd
                    margin={0}
                    onClick={() => {
                        setSelectedDate(parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'));
                        statefulNavigate(generatePath(appPaths.app.calendar.add));
                    }}
                    type="primary"
                    icon={<IconPlus />}
                    fullWidth={isMobile}
                >
                    {calendarTexts.addEvent}
                </AppButtonEnd>
            )}
        </FiltersContainer>
    );
};
