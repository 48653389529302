import { AppList } from 'components/atoms';

export type RemoteLessonPersonListProps = {
    personsData: {
        [key: string]: string;
        name: string;
    }[];
};

export const RemoteLessonPersonList = ({ personsData }: RemoteLessonPersonListProps) => (
        <AppList
            dataSource={personsData}
            renderItem={({ id, name }, index) => (
                <AppList.Item key={id} orderNumber={index + 1}>
                    {name}
                </AppList.Item>
            )}
        />
    );
