import { RouteObject } from 'react-router-dom';
import { journalDetails } from './routes/journalDetails';
import { journalInspectionRoutes } from './routes/journalInspection';
import { journalChildRoutes } from './routes/journalChild';
import { journalConsultationRoutes } from './routes/journalConsultation';
import { journalQualificationsRoutes } from './routes/journalQualifications';
import { journalTopicRoutes } from './routes/journalTopic';

export const specialJournalRoutes: RouteObject[] = [
    ...journalDetails,
    ...journalInspectionRoutes,
    ...journalChildRoutes,
    ...journalConsultationRoutes,
    ...journalQualificationsRoutes,
    ...journalTopicRoutes,
];
