import styled, { css } from 'styled-components';
import { CalendarEventType } from 'types';
import { EventTypeMapper } from 'consts/calendar/calendarTypeColors';
import { AppText } from '../CommonAppComponents';

type EventTagProps = {
    type: CalendarEventType;
    text: string;
    onTagClick: () => void;
    showDetails?: boolean;
};

const EventTagStyled = styled.div<{
    backgroundColor: string;
    hoverBackgroundColor: string;
    showDetails?: boolean;
}>`
    ${({ theme, backgroundColor, color, hoverBackgroundColor, showDetails }) => css`
        width: 100%;
        border-radius: ${theme.borders.borderRadiusExtraSmall}px;
        padding: 10px 24px;
        background-color: ${backgroundColor};
        color: ${color};
        &:hover {
            background-color: ${!showDetails ? backgroundColor : hoverBackgroundColor};
            cursor: ${!showDetails ? 'default' : 'pointer'};
        }
    `}
`;

export const EventTag = ({ text, onTagClick, type, showDetails }: EventTagProps) => (
    <EventTagStyled
        showDetails={showDetails}
        onClick={onTagClick}
        backgroundColor={EventTypeMapper[type].backgroundColor}
        hoverBackgroundColor={EventTypeMapper[type].hoverBackgroundColor}
    >
        <AppText color={EventTypeMapper[type].color} margin={0}>
            {text}
        </AppText>
    </EventTagStyled>
);
