import { ReactNode, useMemo } from 'react';
import { Table } from 'antd';
import { parseAppColumnsTypeIntoColumnsType } from 'utils/parsers/table/parseAppColumnsTypeIntoColumnsType';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { ColumnsType } from 'antd/es/table/interface';
import { OptionsButton } from 'components/atoms';
import type { AppTableProps } from '../AppTable';
import { AppExpandableRender, AppExpandableRenderProps } from '../components';

type ColumnsParsersParams<TableType> = Pick<
    AppTableProps<TableType>,
    'columns' | 'options' | 'isOptionsLoading'
> & {
    withRowSelection?: boolean;
    editableMobileCellContent?: AppExpandableRenderProps<TableType>['EditableMobileCellContent'];
};

type ColumnsParsersReturn<TableType> = {
    columnsToRender: ColumnsType<TableType>;
    expandedRowRender: (record: TableType) => ReactNode;
};
export const useColumnsParsers: <TableType extends object>(
    params: ColumnsParsersParams<TableType>,
) => ColumnsParsersReturn<TableType> = ({
    columns,
    options,
    isOptionsLoading,
    withRowSelection,
    editableMobileCellContent,
}) => {
    const isMobile = useIsMobile();
    const columnsExtendedByOptions = options
        ? columns?.concat({
              align: 'center',
              isVisibleAsExtendableInMobile: false,
              render: (_, record, index) => (
                  <OptionsButton
                      options={options}
                      index={index}
                      record={record}
                      isOptionsLoading={isOptionsLoading}
                  />
              ),
              width: 20,
          })
        : columns;

    const desktopColumns = columnsExtendedByOptions.filter(
        ({ isInvisibleInDesktop }) => !isInvisibleInDesktop,
    );
    const mobileColumns = columnsExtendedByOptions.filter(
        ({ isInvisibleInMobile, isVisibleAsExtendableInMobile }) =>
            !isInvisibleInMobile && !isVisibleAsExtendableInMobile,
    );
    const mobileExpandableFields = columnsExtendedByOptions.filter(
        ({ isInvisibleInMobile, isVisibleAsExtendableInMobile }) =>
            !isInvisibleInMobile && isVisibleAsExtendableInMobile,
    );

    const parsedColumns = useMemo(
        () => parseAppColumnsTypeIntoColumnsType(desktopColumns, mobileColumns, isMobile),
        [isMobile, mobileColumns, desktopColumns],
    );

    const columnsExtendedBySelection = withRowSelection
        ? parsedColumns.concat(Table.SELECTION_COLUMN)
        : parsedColumns;

    return {
        columnsToRender: columnsExtendedBySelection,
        expandedRowRender: (record) => (
            <AppExpandableRender
                mobileExpandableFields={mobileExpandableFields}
                record={record}
                EditableMobileCellContent={editableMobileCellContent}
            />
        ),
    };
};
