import { useEffect } from 'react';
import { CentralIslandLayout } from 'components/layouts/CentralIslandLayout/CentralIslandLayout';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { AppFormSummary } from 'components/organisms';
import { useAuth } from 'services/firebase/useAuth';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { appPaths } from 'consts/paths/paths';
import { authTexts } from 'consts/text';

export const EmailConfirmationRequiredInfo = () => {
    const { signUserOut } = useAuth();
    const userDetails = useAtomValue(userDetailsAtom);
    const navigate = useNavigate();

    useEffect(() => {
        if (userDetails) {
            navigate(appPaths.app.base);
        }
    }, [navigate, userDetails]);

    return (
        <CentralIslandLayout>
            <AppFormSummary
                title="Email przypisany do twojego konta nie został potwierdzony"
                subTitle="Przed przejściem dalej wymagane jest potwierdzenie adresu email. Sprawdź swoją skrzynkę pocztową."
                buttonText={authTexts.logoutButtonLabel}
                onClick={signUserOut}
                hideGoBackButton
            />
        </CentralIslandLayout>
    );
};
