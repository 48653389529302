import { useTheme } from 'styled-components';
import { ConfigProvider, App as AntApp } from 'antd';
import 'dayjs/locale/pl';
import locale from 'antd/locale/pl_PL';
import { ReactNode } from 'react';
import { getAntThemeBasedOnStyledComponentsTheme } from './getAntThemeBasedOnStyledComponentsTheme';

export const AntDesignConfigProvider = ({ children }: { children: ReactNode }) => {
    const theme = useTheme();

    return (
        <AntApp>
            <ConfigProvider
                theme={theme && getAntThemeBasedOnStyledComponentsTheme(theme)}
                locale={locale}
            >
                {children}
            </ConfigProvider>
        </AntApp>
    );
};
