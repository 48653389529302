import { AppFormItem, AppFormItemProps, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { ChildSex } from 'types';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { childSexLabels } from 'consts/child/childSexLabels';
import { childrenTexts } from 'consts/text';

const items: { label: string; value: ChildSex }[] = [
    {
        label: childSexLabels.MALE,
        value: 'MALE',
    },
    {
        label: childSexLabels.FEMALE,
        value: 'FEMALE',
    },
];

export const GenderPickerElement = (props: AppFormItemProps) => {
    const radioElements = items.map(({ value, label }) => (
        <AppRadioButton key={value} value={value}>
            {label}
        </AppRadioButton>
    ));

    return (
        <AppFormItem
            label={childrenTexts.childForm.personalData.sex}
            name={formFields.sex}
            rules={[{ required: true, message: formElementRequiredText }]}
            {...props}
        >
            <AppRadioGroup>{radioElements}</AppRadioGroup>
        </AppFormItem>
    );
};
