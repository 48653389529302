import styled, { css } from 'styled-components';
import { Tabs } from 'antd';
import { getTextStyle } from 'utils/getTextStyle';

export const StyledAppTabs = styled(Tabs)`
    ${({ theme }) => css`
        margin-bottom: ${theme.sizes.marginNormal}px;

        .ant-tabs-nav {
            padding: 6px;
            border-radius: 10px;
            border: 1px solid ${theme.colors.primary.primary5};

            .ant-tabs-nav-list {
                gap: 8px;

                .ant-tabs-tab {
                    margin: 0;
                    padding: 10px 14px;

                    &.ant-tabs-tab-active {
                        border-radius: 4px;
                        background-color: ${theme.colors.primary.primary3};

                        .ant-tabs-tab-btn {
                            text-shadow: none;
                        }
                    }

                    .ant-tabs-tab-btn {
                        ${getTextStyle('BodyMSSemiBold')}
                    }
                }

                .ant-tabs-ink-bar {
                    display: none;
                }
            }
        }
    `}
`;
