import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildSexDisplay, InfoText, PersonList } from 'components/molecules';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { AppFormContext } from 'contexts/AppFormContext';
import { formFields } from 'consts/form/formFields';
import { AppFormDetails, AppFormItem, AppFormItemProps } from 'components/atoms';
import { Child, ChildId, GetChildrenFilters, GroupId } from 'types';
import { childFields } from 'consts/child/childFields';
import { getFullName } from 'utils/getFullName';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { usePagination } from 'hooks/usePagination/usePagination';
import { parsePaginationDataToTablePaginationData } from 'utils/parsers/table/parsePaginationDataToTablePaginationData';
import { AppTable, AppTableProps } from 'components/organisms';
import { groupsTexts } from 'consts/text';
import { useParams } from 'react-router-dom';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const GroupChildrenDetails = styled(AppFormDetails)`
    ${({ theme }) => css`
        margin-block-start: ${theme.sizes.marginLarge}px;
    `}
`;

const columns: AppTableProps<Child>['columns'] = [
    {
        title: 'Nazwisko i imię',
        dataIndex: childFields.firstName,
        key: childFields.firstName,
        render: (_, record) =>
            getFullName(record[childFields.firstName], record[childFields.lastName]),
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: 'Płeć',
        dataIndex: childFields.sex,
        key: childFields.sex,
        render: (sex) => <ChildSexDisplay sex={sex} />,
        align: 'center',
        isVisibleAsExtendableInMobile: false,
        width: 80,
    },
    {
        title: 'PESEL',
        dataIndex: childFields.pesel,
        key: childFields.pesel,
        isVisibleAsExtendableInMobile: true,
    },
];

const fetchingChildrenDataError =
    'Nie udało się pobrać danych o dzieciach, odśwież stronę i spróbuj ponownie';

const baseFilters: GetChildrenFilters = { withJournalGroup: false, status: 'ACTIVE' };

type ChildrenPickerProps = AppFormItemProps;

export const ChildrenPicker = (props: ChildrenPickerProps) => {
    const { groupId } = useParams<{ groupId: GroupId }>();
    const [searchChildrenValue, setSearchChildrenValue] = useState('');
    const [selectedChildrenIds, setSelectedChildrenIds] = useState<ChildId[]>([]);
    const { currentPage, currentPageSize, handlePaginationValuesChange } = usePagination();
    const { formError, setFormError } = useContext(AppFormContext);
    const { setFieldValue } = useFormInstance();
    const isMobile = useIsMobile();
    const filters: GetChildrenFilters = baseFilters;

    const { data: groupData } = useAppQuery(
        'GROUP',
        [groupId],
        () => StaffHTTPService.groups.getGroup(groupId as GroupId),
        {
            enabled: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const {
        data: childrenData,
        isFetching: isChildrenDataFetching,
        isError: isChildrenDataError,
    } = useAppQuery(
        'CHILDREN',
        [searchChildrenValue, currentPage, currentPageSize, filters],
        () =>
            StaffHTTPService.children.getChildren({
                search: searchChildrenValue,
                filters,
                paginationParams: { page: currentPage, size: currentPageSize },
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useDisplayFetchDataError({
        isFetching: isChildrenDataFetching,
        isError: isChildrenDataError,
        actualError: formError,
        errorMessage: fetchingChildrenDataError,
        setError: setFormError,
    });

    const handleSelectChild = (record: Child, selected: boolean) =>
        setSelectedChildrenIds((prevSelectedChildrenIds) => {
            const childId = record[childFields.id];
            let newSelectedChildrenIds = [...prevSelectedChildrenIds];
            if (selected) {
                newSelectedChildrenIds.push(childId);
            } else {
                newSelectedChildrenIds = newSelectedChildrenIds.filter(
                    (newSelectedChildrenId) => newSelectedChildrenId !== childId,
                );
            }
            setFieldValue(formFields.childIdsToAdd, newSelectedChildrenIds);
            return newSelectedChildrenIds;
        });

    return (
        <>
            {groupData?.childrenArray && <InfoText text={groupsTexts.addEditForm.childInfo} />}
            <AppFormItem
                label={groupsTexts.addEditForm.addChildrenLabel}
                name={formFields.childIdsToAdd}
                optional
                hasOptionalText
                initialValue={[]}
                marginBottom={0}
                {...props}
            >
                <input type="hidden" />
            </AppFormItem>
            <Container>
                <AppTable
                    isTableHeaderHidden
                    emptyInfo={groupsTexts.addEditForm.childrenPickerEmptyState}
                    columns={columns}
                    dataSource={childrenData?.content}
                    rowKey={({ id }) => id}
                    isError={isChildrenDataError}
                    scroll={{ y: 420 }}
                    //TODO: to pass isDataInitialLoading and isDataRefetching from useAppQuery
                    isDataInitialLoading={isChildrenDataFetching}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedChildrenIds,
                        onSelect: handleSelectChild,
                        columnWidth: isMobile ? 60 : 80,
                        hideSelectAll: true,
                    }}
                    tableManageUtilsProps={{
                        searchInputProps: {
                            isHiddenLabel: true,
                            onInput: debounce(setSearchChildrenValue, DEBOUNCE_DELAY),
                            width: '100%',
                        },
                    }}
                    pagination={{
                        ...parsePaginationDataToTablePaginationData({
                            currentPage,
                            currentPageSize,
                        }),
                        total: childrenData?.totalElements,
                        onChange: handlePaginationValuesChange,
                    }}
                />
            </Container>
            {groupData?.childrenArray && (
                <GroupChildrenDetails
                    label={groupsTexts.addEditForm.childList}
                    value={<PersonList personsData={groupData.childrenArray} />}
                />
            )}
        </>
    );
};
