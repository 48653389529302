import { commonTexts, settlementTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';

export const childSettlementRecalculateErrorParser: (error: unknown) => string = (error) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.NOT_LATEST_MONTHLY_SETTLEMENT) {
        return settlementTexts.children.recalculate.notLatestMonthlySettlementErrorMessage;
    }

    return commonTexts.errorMessages.unknown;
};
