import { Container, BannerText } from './AppFormBanner.styled';
import { BannerMode } from './AppFormBanner.types';
import { bannerTextColorsByBannerMode, iconsByBannerMode } from './AppFormBanner.consts';

export type BannerProps = {
    mode: BannerMode;
    text: string;
};

export const AppFormBanner = ({ mode, text }: BannerProps) => {
    const icon = iconsByBannerMode[mode];
    const { textColor, backgroundColor, borderColor } = bannerTextColorsByBannerMode[mode];

    return (
        <Container backgroundColor={backgroundColor} borderColor={borderColor}>
            {icon}
            <BannerText textType="BodyMedium" color={textColor}>
                {text}
            </BannerText>
        </Container>
    );
};
