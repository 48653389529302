import { EmptyDisplay, EmptyDisplayProps } from 'components/molecules';
import { TableBodyWrapperProps, TableBodyWrapper } from '../TableBodyWrapper/TableBodyWrapper';

export type TableEmptyDisplayProps = Omit<TableBodyWrapperProps, 'children'> & EmptyDisplayProps;

export const TableEmptyDisplay = ({ emptyInfo, ...props }: TableEmptyDisplayProps) => (
    <TableBodyWrapper {...props}>
        <EmptyDisplay emptyInfo={emptyInfo} />
    </TableBodyWrapper>
);
