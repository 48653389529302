import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { FormPageProps } from 'types';

export const AppFormContentContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['maxWidth']),
})<Pick<FormPageProps, 'maxWidth'>>`
    ${({ theme, maxWidth }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        width: 100%;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
        ${
            maxWidth &&
            css`
              max-width: ${maxWidth === 'none' ? maxWidth : `${maxWidth}px`};
              width: 100%;
            }`
        } 
    `}
`;
