import { MailThreadChildReceiverDTO } from 'types';
import { ExtendedDataNode } from 'components/atoms';
import { messagesTexts } from 'consts/text';
import { parseMailThreadChildReceiverDTOIntoExtendedDataNode } from 'utils/parsers/mailThread/parseMailThreadChildReceiverDTOIntoExtendedDataNode';

const DATA_NODE_KEY = 'childrenWithoutGroups';

export const parseMailThreadChildReceiverDTOsIntoExtendedDataNodes: (
    mailThreadChildReceiverDTOs: MailThreadChildReceiverDTO[],
) => ExtendedDataNode[] = (mailThreadChildReceiverDTOs) => {
    const childrenDataNodes: ExtendedDataNode[] = mailThreadChildReceiverDTOs.map(
        (mailThreadChildReceiverDTO) =>
            parseMailThreadChildReceiverDTOIntoExtendedDataNode(mailThreadChildReceiverDTO),
    );
    return [
        {
            title: messagesTexts.recipientsPicker.childrenWithoutGroups,
            key: DATA_NODE_KEY,
            children: childrenDataNodes,
        },
    ];
};
