import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { Basis, SelectOptionType } from 'types';
import { psychologicalHelpFormsLabels } from 'consts/psychologicalHelp/psychologicalHelpFormsLabels';
import { psychologicalHelpBasesLabels } from 'consts/psychologicalHelp/psychologicalHelpBasesLabels';

const options: SelectOptionType<Basis>[] = [
    {
        label: psychologicalHelpBasesLabels.INDIVIDUAL_EDUCATION_NEED,
        value: 'INDIVIDUAL_EDUCATION_NEED',
    },
    {
        label: psychologicalHelpBasesLabels.EARLY_DEVELOPMENT_SUPPORT,
        value: 'EARLY_DEVELOPMENT_SUPPORT',
    },
    {
        label: psychologicalHelpBasesLabels.COUNSELING_OPINION,
        value: 'COUNSELING_OPINION',
    },
    {
        label: psychologicalHelpBasesLabels.DOCTOR_OPINION,
        value: 'DOCTOR_OPINION',
    },
    {
        label: psychologicalHelpBasesLabels.SPECIALIST_OPINION,
        value: 'SPECIALIST_OPINION',
    },
    {
        label: psychologicalHelpFormsLabels.OTHER,
        value: 'OTHER',
    },
];

export const PsychologicalHelpBasisPickerElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Podstawa objęcia pomocy"
        name={formFields.basis}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...props}
    >
        <AppSelect options={options} />
    </AppFormItem>
);
