import type { StaffAppModules, ModuleData } from 'types';
import {
    CalendarModuleIcon,
    ChildrenModuleIcon,
    GroupsModuleIcon,
    JournalsModuleIcon,
    MenuModuleIcon,
    PeopleModuleIcon,
    RemoteLessonsModuleIcon,
    SettingsModuleIcon,
    SettlementModuleIcon,
    TimetableModuleIcon,
} from 'assets';
import { appPaths } from 'consts/paths/paths';
import { STAFF_MODULE_NAMES } from 'consts/modules/moduleNames';
import { MessagesModuleIconWithUnreadMessageIndicator } from 'components/organisms/MessagesModuleIconWithUnreadMessageIndicator/MessagesModuleIconWithUnreadMessageIndicator';

export const STAFF_APP_MODULES = {
    JOURNALS: 'JOURNALS',
    SETTLEMENT: 'SETTLEMENT',
    // TODO: to change to MENU
    DIET: 'DIET',
    TIMETABLE: 'TIMETABLE',
    MESSAGES: 'MESSAGES',
    REMOTE_LESSONS: 'REMOTE_LESSONS',
    CALENDAR: 'CALENDAR',
    EMPLOYEES: 'EMPLOYEES',
    CHILDREN: 'CHILDREN',
    GROUPS: 'GROUPS',
    SETTINGS: 'SETTINGS',
    GROUP_JOURNALS: 'GROUP_JOURNALS',
    SPECIAL_JOURNALS: 'SPECIAL_JOURNALS',
} as const;

export type StaffModulesData = Record<StaffAppModules, ModuleData>;

export const STAFF_MODULES_DATA: StaffModulesData = {
    JOURNALS: {
        name: STAFF_MODULE_NAMES.JOURNALS,
        Icon: <JournalsModuleIcon />,
        path: appPaths.app.journals.base,
    },
    SETTLEMENT: {
        name: STAFF_MODULE_NAMES.SETTLEMENT,
        Icon: <SettlementModuleIcon />,
        path: appPaths.app.settlement.base,
    },
    DIET: {
        name: STAFF_MODULE_NAMES.DIET,
        Icon: <MenuModuleIcon />,
        path: appPaths.app.menu.base,
    },
    TIMETABLE: {
        name: STAFF_MODULE_NAMES.TIMETABLE,
        Icon: <TimetableModuleIcon />,
        path: appPaths.app.timetable.base,
    },
    MESSAGES: {
        name: STAFF_MODULE_NAMES.MESSAGES,
        alternativeName: 'Panel korespondencji',
        Icon: <MessagesModuleIconWithUnreadMessageIndicator />,
        path: appPaths.app.messages.base,
    },
    REMOTE_LESSONS: {
        name: STAFF_MODULE_NAMES.REMOTE_LESSONS,
        Icon: <RemoteLessonsModuleIcon />,
        path: appPaths.app['remote-lessons'].base,
    },
    CALENDAR: {
        name: STAFF_MODULE_NAMES.CALENDAR,
        Icon: <CalendarModuleIcon />,
        path: appPaths.app.calendar.base,
    },
    EMPLOYEES: {
        name: STAFF_MODULE_NAMES.EMPLOYEES,
        Icon: <PeopleModuleIcon />,
        path: appPaths.app.employees.base,
    },
    CHILDREN: {
        name: STAFF_MODULE_NAMES.CHILDREN,
        Icon: <ChildrenModuleIcon />,
        path: appPaths.app.children.base,
    },
    GROUPS: {
        name: STAFF_MODULE_NAMES.GROUPS,
        Icon: <GroupsModuleIcon />,
        path: appPaths.app.groups.base,
    },
    SETTINGS: {
        name: STAFF_MODULE_NAMES.SETTINGS,
        Icon: <SettingsModuleIcon />,
        path: appPaths.app.settings.base,
    },
    GROUP_JOURNALS: {
        name: STAFF_MODULE_NAMES.GROUP_JOURNALS,
        Icon: <SettingsModuleIcon />,
        path: appPaths.app.journals.groupJournalDetails.base,
    },
    SPECIAL_JOURNALS: {
        name: STAFF_MODULE_NAMES.SPECIAL_JOURNALS,
        Icon: <SettingsModuleIcon />,
        path: appPaths.app.journals.specialJournalDetails.base,
    },
};
