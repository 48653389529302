import useForm from 'antd/es/form/hooks/useForm';
import { useWatch } from 'antd/es/form/Form';
import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AppForm, AppFormProps, JournalChildPicker } from 'components/organisms';
import {
    CommonFormProps,
    FormModeDependedTexts,
    JournalId,
    PsychologicalHelpFormFields,
} from 'types';
import {
    DescriptionInputElement,
    FormNavButtons,
    HelpFormPickerElement,
    PsychologicalHelpBasisPickerElement,
    AttachmentsUploadElement,
    PsychologicalHelpAdditionalInformationInputElement,
    DayPeriodPickerElement,
} from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { commonTexts, journalTexts } from 'consts/text';
import { AppFormItem } from 'components/atoms';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { useDependentFormFields } from 'hooks/useDependentFormFields/useDependentFormFields';

const addModeTexts: FormModeDependedTexts = {
    name: 'psychologicalHelpAdd',
    title: journalTexts.journalDetails.groupJournal.psychologicalHelp.form.addTitle,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'psychologicalHelpEdit',
    title: journalTexts.journalDetails.groupJournal.psychologicalHelp.form.editTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

type PsychologicalHelpAddEditFormProps = Omit<
    AppFormProps<PsychologicalHelpFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        journalId: JournalId;
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (psychologicalHelpData: PsychologicalHelpFormFields) => void;
    };

export const PsychologicalHelpAddEditForm = ({
    mode,
    form,
    initialValues,
    journalId,
    axiosError,
    ...props
}: PsychologicalHelpAddEditFormProps) => {
    const [formInstance] = useForm(form);
    const helpFormValue = useWatch(formFields.helpForm, formInstance);
    const {
        watchedFieldsValues: watchedDatesValues,
        handleWatchedFieldsValuesChange: handleDatesValuesChange,
    } = useDependentFormFields({
        watchedFields: [formFields.dateFrom, formFields.dateTo],
        dependentFields: [formFields.childId],
        formInstance,
    });

    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const { dateFrom: dateFromValue, dateTo: dateToValue } = watchedDatesValues;
    const parsedDateFrom = dateFromValue
        ? parseDayjsDateIntoString(dateFromValue, 'YYYY-MM-DD')
        : undefined;
    const parsedDateTo = dateToValue
        ? parseDayjsDateIntoString(dateToValue, 'YYYY-MM-DD')
        : undefined;

    const psychologicalHelpAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<PsychologicalHelpFormFields>
            form={formInstance}
            name={name}
            title={title}
            error={psychologicalHelpAddEditError}
            withGoBack
            maxWidth={440}
            {...props}
        >
            <HelpFormPickerElement />
            {helpFormValue === 'OTHER' && (
                <PsychologicalHelpAdditionalInformationInputElement
                    label={
                        journalTexts.journalDetails.groupJournal.psychologicalHelp.form
                            .additionalInfo
                    }
                />
            )}
            <AppFormItem justifyContent="space-between" doubleItem>
                <DayPeriodPickerElement
                    mode="start"
                    endField={formFields.dateTo}
                    startField={formFields.dateFrom}
                    label={
                        journalTexts.journalDetails.groupJournal.psychologicalHelp.form.startDate
                    }
                    onChange={handleDatesValuesChange}
                />
                <DayPeriodPickerElement
                    mode="finish"
                    endField={formFields.dateTo}
                    startField={formFields.dateFrom}
                    label={journalTexts.journalDetails.groupJournal.psychologicalHelp.form.endDate}
                    onChange={handleDatesValuesChange}
                />
            </AppFormItem>
            <JournalChildPicker
                journalId={journalId}
                dateFrom={parsedDateFrom}
                dateTo={parsedDateTo}
                disabled={!(parsedDateFrom && parsedDateTo)}
            />
            <PsychologicalHelpBasisPickerElement />
            <DescriptionInputElement />
            <AttachmentsUploadElement uploadDirectory="PSYCHOLOGICAL_HELP" />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
            />
        </AppForm>
    );
};
