import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { journalFields } from 'consts/journal/journalFields';
import { JournalId, JournalType } from 'types';
import { appRoutes } from 'consts/paths/paths';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type JournalDetailsBreadcrumbProps = {
    journalId: JournalId;
    journalType: JournalType;
};

export const useJournalDetailsBreadcrumb = ({
    journalId,
    journalType,
}: JournalDetailsBreadcrumbProps) => {
    const [_, setSearchParams] = useSearchParams();
    const { data: journalData } = useAppQuery(
        'JOURNAL',
        [journalId],
        () => StaffHTTPService.journals.getJournal(journalId),
        { enabled: !!journalId, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (!journalData) {
            return;
        }
        const route =
            journalType === 'GROUP'
                ? appRoutes.app.journals.groupJournalDetails.pickedJournal.base
                : appRoutes.app.journals.specialJournalDetails.pickedJournal.base;
        const params = new URLSearchParams(window.location.search);
        params.set(route, journalData[journalFields.name]);
        setSearchParams(params, { replace: true });
    }, [setSearchParams, journalData, journalType]);
};
