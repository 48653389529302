import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { PageBaseLayout, ParentChildJournalDataLayout } from 'components/layouts';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parentTexts } from 'consts/text';
import { NoScheduleDataStateIcon } from 'assets';
import { WeeklyScheduleCards } from 'components/molecules';
import { useMemo } from 'react';
import { sanitizeWeeklyScheduleDTOData } from 'utils/sanitizeWeeklyScheduleDTOData';
import { isWeeklyScheduleEmpty } from 'utils/isWeeklyScheduleEmpty';

export const WeeklySchedulePage = () => {
    const {
        data: weeklyScheduleData,
        isInitialLoading: isWeeklyScheduleDataInitialLoading,
        isError: isWeeklyScheduleDataError,
        error: weeklyScheduleDataError,
    } = useAppQuery('WEEKLY_SCHEDULE', [], ParentHTTPService.schedules.getWeeklySchedule, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const weeklyScheduleSanitizedData = useMemo(() => {
        if (!weeklyScheduleData) {
            return null;
        }
        return sanitizeWeeklyScheduleDTOData(weeklyScheduleData);
    }, [weeklyScheduleData]);

    const isScheduleEmpty = weeklyScheduleData ? isWeeklyScheduleEmpty(weeklyScheduleData) : false;

    return (
        <ParentChildJournalDataLayout error={weeklyScheduleDataError}>
            <PageBaseLayout
                isDataInitialLoading={isWeeklyScheduleDataInitialLoading}
                isError={isWeeklyScheduleDataError}
                isEmpty={isScheduleEmpty}
                emptyStateData={{
                    emptyInfo: parentTexts.schedule.noWeeklySchedule,
                    emptyIcon: <NoScheduleDataStateIcon />,
                }}
            >
                {weeklyScheduleSanitizedData && (
                    <WeeklyScheduleCards weeklySchedule={weeklyScheduleSanitizedData} />
                )}
            </PageBaseLayout>
        </ParentChildJournalDataLayout>
    );
};
