import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { AppParagraph } from 'components/atoms';

export const PhoneNumberDisplay = ({ phoneNumber }: { phoneNumber: string }) => {
    const phoneNumberText = phoneNumber
        ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber)
        : EMPTY_VALUE_LABEL;

    return <AppParagraph margin={0}>{phoneNumberText}</AppParagraph>;
};
