import { useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { getFirstAndLastJournalSchoolYears } from 'utils/getFirstAndLastJournalSchoolYears';
import { journalFields } from 'consts/journal/journalFields';
import { JournalId } from 'types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type FirstAndLastJournalSchoolYearsProps = {
    journalId: JournalId;
};

export const useFirstAndLastJournalSchoolYears = ({
    journalId,
}: FirstAndLastJournalSchoolYearsProps) => {
    const {
        data: journalData,
        isFetching: isJournalDataFetching,
        isError: isJournalDataError,
    } = useAppQuery('JOURNAL', [journalId], () => StaffHTTPService.journals.getJournal(journalId), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const firstAndLastJournalSchoolYears = useMemo(() => {
        if (!journalData) {
            return null;
        }
        return getFirstAndLastJournalSchoolYears(journalData[journalFields.schoolYears]);
    }, [journalData]);

    return {
        firstAndLastJournalSchoolYears,
        isJournalDataFetching,
        isJournalDataError,
    };
};
