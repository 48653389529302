import { AppFormItem, AppFormItemProps, AppMaskedInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { idDocumentNumberRegExp } from 'consts/regexps';

export const IdDocumentNumberInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Numer dowodu"
        name={formFields.idNumber}
        rules={[
            { required: true, message: formElementRequiredText },
            {
                pattern: idDocumentNumberRegExp,
                message: 'Niepoprawny numer dowodu',
                required: true,
            },
        ]}
        {...props}
    >
        <AppMaskedInput mask="aaa000000" autoComplete="off" />
    </AppFormItem>
);
