import styled, { css } from 'styled-components';
import { AppList, AppListProps } from 'components/atoms';
import { LessonDTO } from 'types';
import { LessonListItem } from './components';

type LessonListProps = {
    lessons: LessonDTO[];
    onLessonClick: (lesson: LessonDTO) => void;
    noPreview?: boolean;
};

const StyledList = styled(AppList)<AppListProps<LessonDTO>>`
    ${({ theme }) => css`
        .ant-list-items {
            display: flex;
            flex-direction: column;
            gap: ${theme.sizes.marginSmall}px;
        }
    `}
`;

export const LessonList = ({ lessons, onLessonClick, noPreview }: LessonListProps) => (
    <StyledList
        dataSource={lessons}
        renderItem={(lesson) => (
            <LessonListItem
                key={lesson.id}
                lesson={lesson}
                onLessonClick={onLessonClick}
                noPreview={noPreview}
            />
        )}
    />
);
