import {
    DetailsFrameBase,
    DetailsColumn,
    DetailsRow,
    DetailsFrameBaseProps,
} from 'components/atoms';
import { InstitutionBaseDataIcon } from 'assets';
import { subscriptionsTexts } from 'consts/text';
import { InstitutionType } from 'types';
import { InstitutionAffiliationDisplay, InstitutionTypeDisplay } from 'components/molecules/Table';

// eslint-disable-next-line
type InstitutionDataProps = Pick<DetailsFrameBaseProps<any>, 'className'> & {
    regon: string;
    name: string;
    rspo: string;
    publicInstitution: boolean;
    type: InstitutionType;
};

export const InstitutionData = ({
    regon,
    name,
    rspo,
    publicInstitution,
    type,
    className,
}: InstitutionDataProps) => (
    <DetailsFrameBase
        icon={<InstitutionBaseDataIcon />}
        title={subscriptionsTexts.details.institutionData.heading}
        className={className}
    >
        <DetailsRow>
            <DetailsColumn title={subscriptionsTexts.details.institutionData.regonNumber} span={24}>
                {regon}
            </DetailsColumn>
        </DetailsRow>
        <DetailsRow>
            <DetailsColumn
                title={subscriptionsTexts.details.institutionData.institutionName}
                span={24}
            >
                {name}
            </DetailsColumn>
        </DetailsRow>
        <DetailsRow>
            <DetailsColumn title={subscriptionsTexts.details.institutionData.rspoNumber} span={24}>
                {rspo}
            </DetailsColumn>
        </DetailsRow>
        <DetailsRow>
            <DetailsColumn
                title={subscriptionsTexts.details.institutionData.institutionAffiliation}
                span={12}
            >
                <InstitutionAffiliationDisplay isPublicInstitution={publicInstitution} />
            </DetailsColumn>
            <DetailsColumn
                title={subscriptionsTexts.details.institutionData.institutionType}
                span={12}
            >
                <InstitutionTypeDisplay type={type} />
            </DetailsColumn>
        </DetailsRow>
    </DetailsFrameBase>
);
