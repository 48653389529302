import { Navigate } from 'react-router-dom';
import { ComponentBlockingAppLoader } from 'components/atoms';

type NavigateToDefaultPageLayoutProps = {
    to: string;
};

export const NavigateToDefaultPageLayout = ({ to }: NavigateToDefaultPageLayoutProps) => (
    <>
        <Navigate to={to} replace />
        <ComponentBlockingAppLoader />
    </>
);
