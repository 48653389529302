import {
    CostAllocationId,
    EditChildCostAllocation,
} from 'types/settlementChildren/settlementChildren';
import { CostAllocationFormFields } from 'types';
import { parseCostAllocationFormFieldsToAddCostAllocationDTO } from 'utils/parsers/costAllocation/parseCostAllocationFormFieldsToAddCostAllocationDTO';

export const parseCostAllocationFormFieldsToEditCostAllocationDTO = (
    costAllocation: CostAllocationFormFields,
    costAllocationId: CostAllocationId,
): EditChildCostAllocation => ({
    ...parseCostAllocationFormFieldsToAddCostAllocationDTO(costAllocation),
    id: costAllocationId,
});
