import { Role } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { ALL_MODULE_NAMES } from 'consts/modules/moduleNames';
import { parsePermissionIntoJsonObject } from 'utils/parsers/jsonObject/role/parsePermissionIntoJsonObject';

export const parseRoleIntoJsonObject: (role: Role) => object = (role) => {
    const { name, modules, permissions } = role;

    const moduleNames = modules.map((module) => ALL_MODULE_NAMES[module]);
    const parsedPermissionJsonObjects = permissions.map(parsePermissionIntoJsonObject);

    return {
        [jsonObjectsTexts.role.fields.name]: name,
        [jsonObjectsTexts.role.fields.modules]: moduleNames,
        [jsonObjectsTexts.role.fields.permissions]: parsedPermissionJsonObjects,
    };
};
