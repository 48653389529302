import { IconHelp } from '@tabler/icons-react';
import { AppText } from 'components/atoms';
import { Container } from './HelperText.styled';

type HelperTextProps = {
    text: string;
};

export const HelperText = ({ text }: HelperTextProps) => (
    <Container>
        <IconHelp size={14} />
        <AppText margin={0} textType="BodySmall">
            {text}
        </AppText>
    </Container>
);
