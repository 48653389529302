import { axiosErrors } from 'consts/errors/axiosErrors';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { childrenTexts, commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const parentDeleteErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (code === apiErrorCodes.CHILD_LAST_PARENT_DELETE) {
        return childrenTexts.deleteChildLastParentErrorMessage;
    }
    if (code === apiErrorCodes.CHILD_PARENTS_WITHOUT_ACCOUNT) {
        return childrenTexts.minOneChildParentWithAccountErrorMessage;
    }

    return childrenTexts.deleteChildParentErrorMessage;
};
