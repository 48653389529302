import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export const Container = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['noFrame']),
})<{ noFrame: boolean; marginEnd?: number }>`
    ${({ theme, noFrame, marginEnd }) => css`
        position: relative;
        border: ${noFrame ? 0 : 1}px solid ${theme.colors.primary.primary5};
        border-radius: 10px;
        padding: 12px ${noFrame ? 12 : 16}px;
        margin-block-end: ${marginEnd || 0}px;
    `}
`;
