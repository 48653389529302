import { ReactNode } from 'react';
import { AppParagraph } from 'components/atoms/CommonAppComponents';
import { colors } from 'theme/styledComponents/colors';
import { Header } from './DetailsHeaderWithIcon.styled';

export type DetailsHeaderWithIconProps = {
    icon: ReactNode;
    title: string;
    iconColor?: string;
    headerColor?: string;
    smallHeaderMargin?: boolean;
    noMarginLeft?: boolean;
};

export const DetailsHeaderWithIcon = ({
    icon,
    title,
    smallHeaderMargin = false,
    noMarginLeft = false,
    iconColor = colors.primary.primary9,
    headerColor = colors.grayscale.gray10,
}: DetailsHeaderWithIconProps) => (
    <Header smallHeaderMargin={smallHeaderMargin} headerColor={iconColor}>
        {icon}
        <AppParagraph
            color={headerColor}
            textType="BodyMediumSemiBold"
            margin={0}
            marginLeft={noMarginLeft ? 0 : 8}
        >
            {title}
        </AppParagraph>
    </Header>
);
