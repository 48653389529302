import styled, { css } from 'styled-components';
import { getShouldForwardProp } from '../../../utils/getShouldForwardProp';

type HeaderState = {
    isFormHeaderAtBottom: boolean;
};

export const OutsideSpreader = styled.div`
    height: 24px;
`;

export const FullWidthContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            flex: 1;
        }
    `}
`;

export const HeaderContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['isFormHeaderAtBottom']),
})<HeaderState>`
    display: flex;
    flex-direction: ${({ isFormHeaderAtBottom }) =>
        isFormHeaderAtBottom ? 'column-reverse' : 'column'};
    gap: 32px;
    margin-bottom: ${({ theme }) => theme.sizes.marginLarge}px;
`;
