import { ReactElement } from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown:has(.childrenDropdown) {
      min-width: 220px !important;
  }
`;

export const DropdownRender = ({ menu }: { menu: ReactElement }) => (
    <div className="childrenDropdown">
        <GlobalStyle />
        {menu}
    </div>
);
