import { atom, useAtomValue } from 'jotai';
import type { ChildAuthDTO, ChildId, ChildInstitutionDTO } from 'types';

export const defaultCurrentChildData: ChildAuthDTO = {
    id: '' as ChildId,
    firstName: '',
    lastName: '',
    institutionAddress: {} as ChildInstitutionDTO,
};
export const currentChildAtom = atom<ChildAuthDTO>(defaultCurrentChildData);

export const useCurrentChild = () => useAtomValue(currentChildAtom);
