import { AppFormItem, AppFormItemProps, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts, settingsTexts } from 'consts/text';

const items: { label: string; value: boolean }[] = [
    {
        label: commonTexts.actionLabels.confirm,
        value: true,
    },
    {
        label: commonTexts.actionLabels.reject,
        value: false,
    },
];

export const IsFreeHoursPickerElement = (props: AppFormItemProps) => {
    const radioElements = items.map(({ value, label }) => (
        <AppRadioButton key={label} value={value}>
            {label}
        </AppRadioButton>
    ));

    return (
        <AppFormItem
            label={settingsTexts.settlements.stays.formLabels.freeHoursLabel}
            name={formFields.freeHours}
            {...props}
        >
            <AppRadioGroup>{radioElements}</AppRadioGroup>
        </AppFormItem>
    );
};
