import { SpecialConsultationDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseSpecialConsultationDTOIntoJsonObject: (
    specialConsultationDTO: SpecialConsultationDTO,
) => object = (specialConsultationDTO) => {
    const { subject, date, description, contactWith, childrenArray } = specialConsultationDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const childrenJsonObjects = childrenArray.map((child) => ({
        [jsonObjectsTexts.specialConsultationDTO.innerObjects.specialConsultationChildDTO.fields
            .firstname]: child.firstName,
        [jsonObjectsTexts.specialConsultationDTO.innerObjects.specialConsultationChildDTO.fields
            .lastname]: child.lastName,
    }));

    return {
        [jsonObjectsTexts.specialConsultationDTO.fields.subject]: subject,
        [jsonObjectsTexts.specialConsultationDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.specialConsultationDTO.fields.description]: description,
        [jsonObjectsTexts.specialConsultationDTO.fields.contactWith]: contactWith,
        [jsonObjectsTexts.specialConsultationDTO.fields.childrenArray]: childrenJsonObjects,
    };
};
