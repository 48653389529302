import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    GroupJournalPsychologicalHelpAddPage,
    GroupJournalPsychologicalHelpDetailsPage,
    GroupJournalPsychologicalHelpEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalPsychologicalHelpRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_PSYCHOLOGICAL_HELP,
                }}
            >
                <GroupJournalPsychologicalHelpAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.edit
            .pickedPsychologicalHelp,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_PSYCHOLOGICAL_HELP,
                }}
            >
                <GroupJournalPsychologicalHelpEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.details
            .base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.details
            .pickedPsychologicalHelp,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_PSYCHOLOGICAL_HELP,
                }}
            >
                <GroupJournalPsychologicalHelpDetailsPage />
            </AbilityRoute>
        ),
    },
];
