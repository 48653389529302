import { JournalStatus, LabelMapper } from 'types';

export const journalStatuses: Record<JournalStatus, JournalStatus> = {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
};

export const journalStatusesLabels: LabelMapper<JournalStatus> = {
    ACTIVE: 'Aktywny',
    ARCHIVED: 'Zarchiwizowany',
};
