import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { GroupAddPage, GroupEditPage, GroupsPage } from 'pages';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';

export const groupRoutes: RouteObject[] = [
    {
        path: appPaths.app.groups.base,
        element: (
            <AbilityRoute can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.GROUPS_LIST }}>
                <GroupsPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.groups.add,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.GROUPS_LIST }}
            >
                <GroupAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.groups.edit.base,
        element: <Navigate to={appPaths.app.groups.base} />,
    },
    {
        path: appPaths.app.groups.edit.pickedGroup,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.GROUPS_LIST }}
            >
                <GroupEditPage />
            </AbilityRoute>
        ),
    },
];
