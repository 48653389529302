import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { InspectionAddEditForm } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { InspectionFormFields, InspectionId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parseInspectionFormFieldsIntoAddUpdateInspectionDTO } from 'utils/parsers/inspection/parseInspectionFormFieldsIntoAddUpdateInspectionDTO';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { ErrorDisplay } from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { parseInspectionDTOIntoInspectionFormFields } from 'utils/parsers/inspection/parseInspectionDTOIntoInspectionFormFields';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { journalTexts } from 'consts/text/index';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const JournalInspectionEditPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { form, formInitialData, setFormData } = useEditForm<InspectionFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { inspectionId: inspectionIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const inspectionId = (inspectionIdParam || '') as InspectionId;

    const {
        data: inspectionData,
        isFetching: isInspectionDataFetching,
        isError: isFetchInspectionDataError,
    } = useAppQuery(
        'INSPECTION',
        [journalId, inspectionId],
        () => StaffHTTPService.inspections.getInspection(journalId, inspectionId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (inspectionData && !form.getFieldValue(formFields.supervisionForm)) {
            setFormData(parseInspectionDTOIntoInspectionFormFields(inspectionData));
        }
    }, [inspectionData, form, setFormData]);

    const navigateToInspectionsPage = () =>
        statefulNavigate(
            generatePath(
                journalType === 'GROUP'
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.base
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base,
                { journalId },
            ),
            { replace: true },
        );

    const handleUpdateInspectionSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.common.inspections.successfullyUpdatedInspection,
        );
        navigateToInspectionsPage();
    };

    const {
        mutate: updateInspection,
        error: updateInspectionError,
        isLoading: isUpdateInspectionLoading,
    } = useAppMutation(StaffHTTPService.inspections.updateInspection, {
        key: ['UPDATE_INSPECTION'],
        onSuccess: handleUpdateInspectionSuccess,
        invalidateQueryKeys: [
            ['INSPECTIONS', journalId],
            ['INSPECTION', journalId, inspectionId],
        ],
    });

    const handleUpdateInspectionFinish = (inspectionFormData: InspectionFormFields) => {
        const parsedUpdateInspectionDTO =
            parseInspectionFormFieldsIntoAddUpdateInspectionDTO(inspectionFormData);
        updateInspection({
            journalId,
            inspectionId,
            updateInspectionDTO: parsedUpdateInspectionDTO,
        });
    };

    if (!inspectionId) {
        navigateToInspectionsPage();
    }

    if (isFetchInspectionDataError) {
        return <ErrorDisplay />;
    }

    return (
        <InspectionAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={updateInspectionError}
            isLoading={isInspectionDataFetching || isUpdateInspectionLoading}
            onFinish={handleUpdateInspectionFinish}
        />
    );
};
