import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { childrenTexts, commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const parentAddErrorParser: (error: unknown, defaultErrorMessage?: string) => string = (
    error,
    defaultErrorMessage = commonTexts.errorMessages.unknown,
) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.CHILD_PARENT_EMAIL_DUPLICATE) {
        return childrenTexts.childParentEmailDuplicateErrorMessage;
    }
    if (code === apiErrorCodes.USER_ALREADY_EXISTS) {
        return commonTexts.errorMessages.userEmailTakenEnterAnother;
    }

    return defaultErrorMessage;
};
