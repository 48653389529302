import { generatePath, useParams } from 'react-router-dom';
import { Details } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ParentTeacherConferenceId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { PersonList } from 'components/molecules';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { commonTexts, journalTexts } from 'consts/text';
import { parentTeacherConferenceDTOFields } from 'consts/parentTeacherConference/parentTeacherConferenceDTOFields';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const GroupJournalParentTeacherConferenceDetailsPage = () => {
    const { journalId } = useJournalId();
    const { parentTeacherConferenceId: parentTeacherConferenceIdParam } = useParams();
    const {
        groupJournalsAbilities: { checkIfCanManageEvents },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageEvents = checkIfCanManageEvents();
    const parentTeacherConferenceId = parentTeacherConferenceIdParam as ParentTeacherConferenceId;
    const editPagePath = generatePath(
        appPaths.app.journals.groupJournalDetails.pickedJournal.events.parentTeacherConferences.edit
            .pickedParentTeacherConference,
        { parentTeacherConferenceId, journalId },
    );

    const {
        data: parentTeacherConferenceData,
        isFetching: isParentTeacherConferenceDataFetching,
        isError: isFetchParentTeacherConferenceDataError,
    } = useAppQuery(
        'PARENT_TEACHER_CONFERENCE',
        [journalId, parentTeacherConferenceId],
        () =>
            StaffHTTPService.parentTeacherConferences.getParentTeacherConference(
                journalId,
                parentTeacherConferenceId,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title={
                journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                    .parentTeacherConferenceDetails
            }
            isLoading={isParentTeacherConferenceDataFetching}
            isError={isFetchParentTeacherConferenceDataError}
            editPagePath={canManageEvents ? editPagePath : undefined}
        >
            <Details.Item
                title={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .parentTeacherConferenceSubject
                }
            >
                {parentTeacherConferenceData?.[parentTeacherConferenceDTOFields.subject]}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.date}>
                {parentTeacherConferenceData &&
                    parseStringIntoFormattedStringDate(
                        parentTeacherConferenceData[parentTeacherConferenceDTOFields.date],
                    )}
            </Details.Item>
            <Details.Item
                title={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .parentTeacherConferenceDescription
                }
            >
                {parentTeacherConferenceData?.[parentTeacherConferenceDTOFields.description]}
            </Details.Item>
            {!!parentTeacherConferenceData?.[parentTeacherConferenceDTOFields.parents].length && (
                <Details.Item title={commonTexts.dataLabels.attendanceList}>
                    <PersonList
                        personsData={
                            parentTeacherConferenceData[parentTeacherConferenceDTOFields.parents]
                        }
                    />
                </Details.Item>
            )}
        </Details>
    );
};
