import { StepsForm } from 'components/organisms/StepsForm/StepsForm';
import { PasswordChangeForm } from './components/PasswordChangeForm';
import { PasswordChangeFinish } from './components/PasswordChangeFinish';

export const PasswordChange = () => (
    <StepsForm
        formPagesNames={['passwordChange']}
        components={[PasswordChangeForm]}
        FinishedComponent={PasswordChangeFinish}
    />
);
