import { useMemo } from 'react';
import { appPaths } from 'consts/paths/paths';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { StaffMainAppModules } from 'types';
import { STAFF_APP_MODULES_PATHS } from 'consts/paths/staffAppModulesPaths';
import { NavigateToDefaultPageLayout } from 'components/layouts';

export const NavigateToDefaultPage = () => {
    const { type, role } = useUserDetails();

    const defaultPagePath = useMemo(() => {
        switch (type) {
            case 'EMPLOYEE': {
                const defaultModule = (
                    Object.keys(STAFF_APP_MODULES_PATHS) as StaffMainAppModules[]
                ).find((appModule) => role.modules.includes(appModule));
                return defaultModule
                    ? STAFF_APP_MODULES_PATHS[defaultModule]
                    : appPaths.error['not-found'];
            }
            case 'PARENT':
                return appPaths.app.parent.news.attendance.base;
            case 'SYS_ADMIN':
                return appPaths.app.admin.institutions.base;
            case 'OWNER':
                return appPaths.app.employees.base;
            default:
                return appPaths.error['not-found'];
        }
    }, [role.modules, type]);

    return <NavigateToDefaultPageLayout to={defaultPagePath} />;
};
