import { AppFormItem, AppFormItemProps, AppSelect, AppSelectProps } from 'components/atoms';

export type SimpleSelectElementProps = AppFormItemProps & AppSelectProps;

export const SimpleSelectElement = ({
    options,
    loading,
    onChange,
    disabled,
    suffixIcon,
    mode,
    open,
    showSearch,
    onClick,
    onClear,
    ...restProps
}: SimpleSelectElementProps) => (
    <AppFormItem disabled={disabled} {...restProps}>
        <AppSelect
            options={options}
            loading={loading}
            disabled={disabled}
            onChange={onChange}
            mode={mode}
            suffixIcon={suffixIcon}
            open={open}
            showSearch={showSearch}
            onClick={onClick}
            onClear={onClear}
        />
    </AppFormItem>
);
