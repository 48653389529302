import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { upperBarBaseStyle } from 'consts/styles/upperBarBaseStyle';
import { AppButton } from 'components/atoms/CommonAppComponents';

export const Container = styled(Layout.Header)`
    ${({ theme }) => css`
        ${upperBarBaseStyle}
        &.ant-layout-header {
            padding-right: 0;
            height: ${theme.sizes.sideMenu.mobileUpperNavBarHeight}px;
        }
    `}
`;

export const MenuButton = styled(AppButton)`
    padding: 16px;
`;
