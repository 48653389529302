import { formFields } from 'consts/form/formFields';
import { GuardianModalAddEditForm } from 'components/templates/guardian/GuardianModalAddEditForm/GuardianModalAddEditForm';
import { useGuardianAndParentChildFormSection } from 'hooks/useGuardianAndParentChildFormSection/useGuardianAndParentChildFormSection';
import { GuardianFormElementWithTable } from 'components/templates/guardian';
import { ChildGuardian, GuardianFormFields } from 'types';
import { parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber } from 'utils/parsers/guardian/parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber';
import { parseGuardianFormFieldsIntoChildGuardian } from 'utils/parsers/guardian/parseGuardianFormFieldsIntoChildGuardian';

export const GuardianFormSection = () => {
    const {
        tableDataItems: guardiansTableItems,
        isModalFormOpen,
        selectedTableDataItemToEdit: selectedGuardianTableItemToEdit,
        handleModalFormOpen,
        handleModalFormClose,
        handleDataItemAdd: handleGuardianAdd,
        handleDataItemEdit: handleGuardianEdit,
        handleDataItemRemove: handleGuardianRemove,
        handleTableDataItemEditClick: handleGuardianTableItemEditClick,
    } = useGuardianAndParentChildFormSection<ChildGuardian>({
        formValuePath: formFields.guardians,
    });

    const isSelectedGuardianTableItemToEdit = !!selectedGuardianTableItemToEdit;
    const modalFormMode = isSelectedGuardianTableItemToEdit ? 'edit' : 'add';
    const formName = isSelectedGuardianTableItemToEdit ? 'guardianEdit' : 'guardianAdd';

    const modalFormSubmitFormHandler = (formData: GuardianFormFields) => {
        const parsedFormData = parseGuardianFormFieldsIntoChildGuardian(formData);
        return isSelectedGuardianTableItemToEdit
            ? handleGuardianEdit(parsedFormData)
            : handleGuardianAdd(parsedFormData);
    };

    return (
        <>
            <GuardianModalAddEditForm
                open={isModalFormOpen}
                mode={modalFormMode}
                onSubmitForm={modalFormSubmitFormHandler}
                onCancel={handleModalFormClose}
                formName={formName}
                initialValues={
                    isSelectedGuardianTableItemToEdit
                        ? parseChildGuardianIntoGuardianFormFieldsWithFormattedPhoneNumber(
                              selectedGuardianTableItemToEdit,
                          )
                        : undefined
                }
            />
            <GuardianFormElementWithTable
                guardiansTableItems={guardiansTableItems}
                onAddGuardianClick={handleModalFormOpen}
                onRemoveGuardianClick={handleGuardianRemove}
                onEditGuardianClick={handleGuardianTableItemEditClick}
                optional
                hasOptionalText
            />
        </>
    );
};
