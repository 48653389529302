import { CateringPaymentDetailsDto } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';

export const parseCateringPaymentDetailsDtoIntoJsonObject: (
    cateringPaymentDetailsDto: CateringPaymentDetailsDto,
) => object = (cateringPaymentDetailsDto) => {
    const { bankAccountNumber, transferTitle, recipientName } = cateringPaymentDetailsDto;

    const parsedBankAccountNumber = bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.settlementMealsDTO.innerObjects.cateringPaymentDetails.fields
            .transferTitle]: transferTitle,
        [jsonObjectsTexts.settlementMealsDTO.innerObjects.cateringPaymentDetails.fields
            .bankAccountNumber]: parsedBankAccountNumber,
        [jsonObjectsTexts.settlementMealsDTO.innerObjects.cateringPaymentDetails.fields
            .recipientName]: recipientName,
    };
};
