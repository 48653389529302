import { AppFormItemGroup, AppText } from 'components/atoms';
import { SimpleInputElement } from 'components/molecules/SimpleFormElements';
import { childrenTexts, settingsTexts } from 'consts/text';
import { FormInstance, useWatch } from 'rc-field-form';
import { useTheme } from 'styled-components';
import { formFields } from 'consts/form/formFields';
import { SettlementMealsFormFields } from 'types';
import { BankAccountNumberInputElement } from '../BankAccountNumberInputElement/BankAccountNumberInputElement';

type CateringPaymentInfoFormProps = {
    form?: FormInstance<SettlementMealsFormFields>;
    title: string;
    description: string;
    disabled?: boolean;
};

export const CateringPaymentAdditionalInformationElement = ({
    form,
    title,
    description,
    disabled,
}: CateringPaymentInfoFormProps) => {
    const theme = useTheme();
    const cateringTransferNameValue = useWatch('cateringTransferName', form);
    const cateringAccountNumberValue = useWatch('cateringAccountNumber', form);
    const recipientNameValue = useWatch('recipientName', form);

    const isOptional = !(
        cateringTransferNameValue ||
        cateringAccountNumberValue ||
        recipientNameValue
    );

    return (
        <>
            <AppText
                textType="BodySmall"
                color={theme.colors.grayscale.gray9}
                margin={0}
                marginBottom={16}
            >
                {description}
            </AppText>
            <AppFormItemGroup title={title} additionalTitleText>
                <SimpleInputElement
                    label={settingsTexts.settlements.meals.formLabels.transferTitle}
                    name={formFields.cateringTransferName}
                    optional={isOptional}
                    disabled={disabled}
                />
                <BankAccountNumberInputElement
                    label={childrenTexts.childDetails.parents.parentTable.columns.bankAccount}
                    name={formFields.cateringAccountNumber}
                    optional={isOptional}
                    disabled={disabled}
                />
                <SimpleInputElement
                    label={settingsTexts.settlements.overall.formLabels.recipentName}
                    name={formFields.recipientName}
                    optional={isOptional}
                    disabled={disabled}
                />
            </AppFormItemGroup>
        </>
    );
};
