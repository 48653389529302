import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { MarginsProps } from 'types';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';

export type AppDividerProps = MarginsProps & {
    withoutLast?: boolean;
};

const propsToFilter = marginPropsToFilter.concat(['withoutLast']);

export const AppDivider = styled.hr.withConfig({
    shouldForwardProp: getShouldForwardProp(propsToFilter),
})<AppDividerProps>`
    ${({ theme, withoutLast, ...props }) => css`
        width: 100%;
        border: 1px solid ${theme.colors.primary.primary3};
        margin: 4px 0;
        ${getMarginsCss(props)}

        ${withoutLast &&
        css`
            &:last-child {
                display: none;
            }
        `}
    `}
`;
