import { useTheme } from 'styled-components';
import { AppParagraph, AppText } from 'components/atoms/CommonAppComponents';
import { MailThreadDTO, MailThreadId } from 'types';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { messagesTexts } from 'consts/text';
import {
    InnerContainer,
    MailThreadInfoContainer,
    StyledAppListItem,
    MailThreadSenderName,
} from './MailThreadListItem.styled';

type MailThreadListItemProps = {
    mailThreadData: MailThreadDTO;
    isActive: boolean;
    onMailThreadClick: (mailThreadId: MailThreadId) => void;
};

export const MAIL_THREAD_LIST_ITEM_PREFIX = 'MAIL_THREAD_LIST_ITEM/';

export const MailThreadListItem = ({
    mailThreadData,
    isActive,
    onMailThreadClick,
}: MailThreadListItemProps) => {
    const theme = useTheme();

    const { id, topic, members, lastMessage, remoteLessonThread, authorUserId } = mailThreadData;
    const {
        read: lastMessageRead,
        sentAt: lastMessageSentAt,
        content: lastMessageContent,
    } = lastMessage;

    const formattedSendAtDate = parseStringIntoFormattedStringDate(
        lastMessageSentAt,
        'YYYY-MM-DDTHH:mm:ss',
        'DD MMMM YYYY',
    );
    const sender = members.find((member) => member.userId === authorUserId);
    const { name: senderName, subName: senderSubName } = sender || { name: '', subName: '' };
    const topicText = remoteLessonThread
        ? `${messagesTexts.remoteLessonTopicPrefixWithColon} ${topic}`
        : topic;

    return (
        <StyledAppListItem id={MAIL_THREAD_LIST_ITEM_PREFIX + id}>
            <InnerContainer isActive={isActive} onClick={() => onMailThreadClick(id)}>
                <MailThreadInfoContainer>
                    <MailThreadSenderName
                        authorName={senderName}
                        authorSubname={senderSubName}
                        baseTextProps={{
                            textType: 'Caption',
                            color: theme.colors.grayscale.gray9,
                        }}
                        subnameTextProps={{
                            textType: 'Caption',
                            color: theme.colors.grayscale.gray8,
                        }}
                    />
                    <AppText textType="Caption" margin={0} color={theme.colors.grayscale.gray9}>
                        {formattedSendAtDate}
                    </AppText>
                </MailThreadInfoContainer>
                <AppText
                    textType={lastMessageRead ? 'BodyMedium' : 'BodyMediumSemiBold'}
                    ellipsis
                    margin={0}
                >
                    {topicText}
                </AppText>
                <AppParagraph ellipsis margin={0} color={theme.colors.grayscale.gray9}>
                    {lastMessageContent}
                </AppParagraph>
            </InnerContainer>
        </StyledAppListItem>
    );
};
