import { MailThreadDTO, MailThreadFormFields } from 'types';
import { messagesTexts } from 'consts/text';

export const parseMailThreadDTOIntoReplyMailThreadFormFields: (
    mailThreadDTO: MailThreadDTO,
) => MailThreadFormFields = (mailThreadDTO) => {
    const { topic, authorUserId } = mailThreadDTO;
    return {
        topic: `${messagesTexts.replyTopicPrefix} ${topic}`,
        content: '',
        userIds: [authorUserId],
    };
};
