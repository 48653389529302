import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AppFormDetails, AppFormItem } from 'components/atoms';
import {
    DayPeriodPickerElement,
    FormNavButtons,
    InfoText,
    PersonList,
    SimpleInputElement,
    TeacherPickerVersion,
} from 'components/molecules';
import {
    AppForm,
    AppFormProps,
    SpecialJournalChildrenPicker,
    TeachersPicker,
} from 'components/organisms';
import { CommonFormProps, FormModeDependedTexts, GroupChildDTO } from 'types';
import { commonTexts, journalTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { SpecialJournalFormFields } from 'types/forms/journal';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';
import { max300LengthRule } from 'consts/rules/textRules';

export type JournalAddEditFormProps = Omit<
    AppFormProps<SpecialJournalFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        addedChildren?: GroupChildDTO[];
        onFinish: (journalData: SpecialJournalFormFields) => void;
    };

const addModeTexts: FormModeDependedTexts = {
    name: 'specialJournalAdd',
    title: journalTexts.journals.addSpecialJournalFormTitle,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'specialJournalEdit',
    title: journalTexts.journals.editSpecialJournalFormTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const SpecialJournalAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    addedChildren,
    ...props
}: JournalAddEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const journalAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<SpecialJournalFormFields>
            title={title}
            maxWidth={480}
            name={name}
            error={journalAddEditError}
            withGoBack
            {...props}
        >
            <SimpleInputElement
                label={journalTexts.journals.journalTitle}
                name={formFields.name}
                rules={[max300LengthRule]}
            />
            <SimpleInputElement
                label={journalTexts.journals.journalLessonsType}
                name={formFields.lessonsType}
                rules={[max300LengthRule]}
                optional
                hasOptionalText
            />
            <AppFormItem justifyContent="space-between" doubleItem>
                <DayPeriodPickerElement
                    mode="start"
                    endField={formFields.finishDate}
                    startField={formFields.startDate}
                    label={journalTexts.journals.addSpecialJournalDateFields.start}
                />
                <DayPeriodPickerElement
                    mode="finish"
                    endField={formFields.finishDate}
                    startField={formFields.startDate}
                    label={journalTexts.journals.addSpecialJournalDateFields.finish}
                />
            </AppFormItem>
            <TeachersPicker
                version={TeacherPickerVersion.MULTIPLE}
                fetchConfig={{
                    fetchFunction: StaffHTTPService.journals.getJournalsEmployees,
                    fetchQueryKey: 'JOURNALS_EMPLOYEES' as QueryMainKeysType,
                }}
            />
            {addedChildren && <InfoText text={journalTexts.journals.childInfo} />}
            <SpecialJournalChildrenPicker optional={!isAddMode} addedChildren={addedChildren} />
            {addedChildren && (
                <AppFormDetails
                    label={journalTexts.journals.childList}
                    value={<PersonList personsData={addedChildren} />}
                />
            )}
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
            />
        </AppForm>
    );
};
