import { ExtendedDataNode } from 'components/atoms';
import React from 'react';

/**
 * Function takes dataList as second argument
 * and modifies it by pushing new nodes. This
 * way of implementation instead of mapping through
 * elements and returning a new list is caused by
 * need to call function recursively in order to
 * iterate through tree children, so result list
 * have to be defined outside function
 */
export const generateList = (data: ExtendedDataNode[], dataList: ExtendedDataNode[]) => {
    data.forEach((node) => {
        const { key, title } = node;
        dataList.push({ key, title });
        if (node.children) {
            generateList(node.children, dataList);
        }
    });
};

export const getParentKey = (key: React.Key, tree: ExtendedDataNode[]): React.Key => {
    let parentKey: React.Key;
    tree.forEach((node) => {
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    });
    return parentKey!;
};

/**
 * Searching and filtering works correctly only
 * when tree node title has type string
 * it is caused by need to use case-insensitive
 * searching and filtering and toLowerCase
 * cannot be called on JSX elements.
 * For now there is no need to use not string
 * node titles, so I decided leave is as it is.
 */
export const searchAndFilterTree = (
    data: ExtendedDataNode[],
    searchValue: string,
    ignoreNextValues: boolean = false,
    depth: number = 1,
) => {
    const filteredData: ExtendedDataNode[] = [];

    data.forEach((node) => {
        const strTitle = node.title as string;
        const newNode: ExtendedDataNode = {
            ...node,
            title: <span className={`level${depth}`}>{strTitle}</span>,
            key: node.key,
        };

        const nodeTitle = typeof node.title === 'string' ? node.title.toLowerCase() : '';

        if (typeof node.title === 'string' && nodeTitle.includes(searchValue)) {
            if (node.children) {
                newNode.children = searchAndFilterTree(node.children, searchValue, true, depth + 1);
            }
            filteredData.push(newNode);
        } else if (node.children) {
            const filteredChildren = searchAndFilterTree(
                node.children,
                searchValue,
                ignoreNextValues || false,
                depth + 1,
            );
            if (filteredChildren.length > 0) {
                newNode.children = filteredChildren;
                filteredData.push(newNode);
            }
        } else if (ignoreNextValues) filteredData.push(newNode);
    });

    return filteredData;
};
