import {
    UpdateChildDTO,
    InstitutionDietId,
    AgreementValueStringified,
    ChildMealType,
    EditChildFormFields,
} from 'types';
import { childMealTypesArray } from 'consts/child/childMealTypes';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';
import { parseDayjsTimeIntoHoursMinutesFormat } from '../dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export const parseEditChildFormFieldsIntoUpdateChildDTO: (
    childFormFields: EditChildFormFields,
) => UpdateChildDTO = (childFormFields) => {
    const address =
        !childFormFields.street && !childFormFields.zipCode && !childFormFields.city
            ? null
            : {
                  street: childFormFields.street || null,
                  zipCode: childFormFields.zipCode || null,
                  city: childFormFields.city || null,
              };

    const parsedMealTypes = childMealTypesArray.reduce(
        (acc, meal) => {
            acc[meal] = !!childFormFields.selectMealsType?.includes(meal);
            return acc;
        },
        {} as { [key in ChildMealType]: boolean },
    );

    return {
        firstName: childFormFields.firstName,
        lastName: childFormFields.lastName,
        sex: childFormFields.sex,
        birthDate: parseDayjsDateIntoString(childFormFields.birthDate, 'YYYY-MM-DD'),
        birthPlace: childFormFields.birthPlace,
        address,
        diseases: childFormFields.diseases,
        allergies: childFormFields.allergens,
        agreements: {
            facebook:
                childFormFields.socialNetworksImageSharingAgreementFacebook as AgreementValueStringified,
            instagram:
                childFormFields.socialNetworksImageSharingAgreementInstagram as AgreementValueStringified,
            tiktok: childFormFields.socialNetworksImageSharingAgreementTikTok as AgreementValueStringified,
            website:
                childFormFields.socialNetworksImageSharingAgreementWebsite as AgreementValueStringified,
        },

        diet: childFormFields.diet
            ? {
                  dietId: childFormFields.diet as InstitutionDietId,
                  ...parsedMealTypes,
              }
            : undefined,
        settlementDetails: {
            periodStart: childFormFields.accountingPeriodStartDate
                ? parseDayjsDateIntoString(childFormFields.accountingPeriodStartDate, 'YYYY-MM-DD')
                : null,
            periodEnd: childFormFields.accountingPeriodFinishDate
                ? parseDayjsDateIntoString(childFormFields.accountingPeriodFinishDate, 'YYYY-MM-DD')
                : null,
            stayStart: childFormFields.declaredStayHoursFromTime
                ? parseDayjsTimeIntoHoursMinutesFormat(childFormFields.declaredStayHoursFromTime)
                : null,
            stayEnd: childFormFields.declaredStayHoursToTime
                ? parseDayjsTimeIntoHoursMinutesFormat(childFormFields.declaredStayHoursToTime)
                : null,
        },
        pesel: childFormFields.peselNumber,
    };
};
