import { SettingsAbilitiesNames } from 'abilities';
import { NavigateFunction } from 'react-router-dom';

export const checkAndRedirect = async (
    abilityCheckFunctions: Record<SettingsAbilitiesNames, () => boolean>,
    paths: Record<SettingsAbilitiesNames, string>,
    navigate: NavigateFunction,
) => {
    const [abilityName] = Object.entries(abilityCheckFunctions).find(([, value]) => value()) || [];

    if (abilityName) {
        const path = paths[abilityName as SettingsAbilitiesNames];
        navigate(path);
    }
};
