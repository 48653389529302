import { generatePath } from 'react-router-dom';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppTableProps } from 'components/organisms';
import { Option } from 'components/atoms';
import { Role } from 'types';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, settingsTexts } from 'consts/text';
import { permissionsFields } from 'consts/permissions/permissionsFields';
import { useSettingsAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useRolesPageNetworkManage } from './hooks';
import { StyledAppTable } from './RolesAndPermissionsPage.styled';

const columns: AppTableProps<Role>['columns'] = [
    {
        title: settingsTexts.rolesAndPermissions.role.createdRoles,
        dataIndex: permissionsFields.name,
        key: permissionsFields.name,
        render: (name) => name,
    },
];

export const RolesAndPermissionsPage = () => {
    const { statefulNavigate } = useNavigateWithState();

    const { rolesData, isRoleDeleteLoading, isRolesDataLoading, isRolesDataError, deleteRole } =
        useRolesPageNetworkManage();
    const { settingsAbilities } = useSettingsAbilities();
    const canManageRolesAndPermissions = settingsAbilities.checkIfCanManageRolesAndPermissions();

    const addRoleHandler = () =>
        statefulNavigate(appPaths.app.settings['roles-and-permissions'].add);

    const options: Option<Role>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: ({ id }) =>
                statefulNavigate(
                    generatePath(appPaths.app.settings['roles-and-permissions'].edit.pickedRole, {
                        roleId: id,
                    }),
                ),
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: ({ id }) => deleteRole(id),
            Icon: <IconTrash />,
        },
    ];

    return (
        <StyledAppTable<Role>
            title={settingsTexts.rolesAndPermissions.role.rolesListTitle}
            addButtonLabel={
                canManageRolesAndPermissions
                    ? settingsTexts.rolesAndPermissions.role.add
                    : undefined
            }
            onAddButtonClick={addRoleHandler}
            emptyInfo={settingsTexts.rolesAndPermissions.role.rolesEmptyInfo}
            options={canManageRolesAndPermissions ? options : undefined}
            columns={columns}
            dataSource={rolesData}
            rowKey={({ id }) => id}
            isOptionsLoading={isRoleDeleteLoading}
            isError={isRolesDataError}
            isDataInitialLoading={isRolesDataLoading}
            hideExpandColumn
            isTableManageUtilsHidden
        />
    );
};
