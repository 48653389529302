import { ReactNode } from 'react';
import { AppFormItem } from 'components/atoms';
import {
    CityInputElement,
    InstitutionAffiliationPickerElement,
    InstitutionNameInputElement,
    InstitutionTypePickerElement,
    RegonInputElement,
    RspoNumberInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { InstitutionAddEditFormFields } from 'types';
import { AppForm, AppFormProps } from '../AppForm/AppForm';

export type InstitutionAddFormProps = Omit<
    AppFormProps<InstitutionAddEditFormFields>,
    'initialValues'
> & {
    formNavButtons: ReactNode;
    mode: 'add' | 'edit';
};

export const InstitutionAddEditForm = ({
    formNavButtons,
    mode,
    ...props
}: InstitutionAddFormProps) => {
    const isEditMode = mode === 'edit';
    const formTitle = isEditMode ? 'Edytuj placówkę' : 'Dodaj placówkę';
    return (
        <AppForm<InstitutionAddEditFormFields> title={formTitle} {...props}>
            <RegonInputElement withFetchDataButton />
            <InstitutionNameInputElement />
            <RspoNumberInputElement />
            <StreetAddressInputElement />
            <AppFormItem justifyContent="space-between" doubleItem>
                <ZipCodeInputElement />
                <CityInputElement />
            </AppFormItem>
            <InstitutionAffiliationPickerElement />
            <InstitutionTypePickerElement />
            {formNavButtons}
        </AppForm>
    );
};
