import { ChildWithParentsIds, JournalChildDTO } from 'types';
import { journalChildDTOFields } from 'consts/child/journalChildDTOFields';

export const parseJournalChildDTOIntoChildWithParentsIds: (
    journalChildDTO: JournalChildDTO,
) => ChildWithParentsIds = (journalChildDTO) => {
    const parentIds = journalChildDTO[journalChildDTOFields.parents].map((parent) => parent.id);
    return {
        childId: journalChildDTO[journalChildDTOFields.id],
        parentIds,
    };
};
