import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    AdminInstitutionsPage,
    AdminInstitutionDetailsPage,
    SubscriptionAddPage,
    SubscriptionEditPage,
} from 'pages';

export const institutionRoutes: RouteObject[] = [
    {
        path: appPaths.app.admin.institutions.base,
        element: <AdminInstitutionsPage />,
        children: [
            {
                path: appPaths.app.admin.institutions['add-subscription'],
                element: <SubscriptionAddPage />,
            },
            {
                path: appPaths.app.admin.institutions['edit-subscription'],
                element: <SubscriptionEditPage />,
            },
        ],
    },
    {
        path: appPaths.app.admin.institutions.details.base,
        element: <Navigate to={appPaths.app.admin.institutions.base} />,
    },
    {
        path: appPaths.app.admin.institutions.details.pickedInstitution.base,
        element: <AdminInstitutionDetailsPage />,
    },
];
