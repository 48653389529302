import { IconArrowNarrowLeft } from '@tabler/icons-react';
import {
    AppButton,
    AppButtonProps,
} from 'components/atoms/CommonAppComponents/AppButton/AppButton';
import { commonTexts } from 'consts/text';
import { useNavigate } from 'react-router-dom';

type AppGoBackButtonProps = Pick<
    AppButtonProps,
    'margin' | 'marginTop' | 'marginBottom' | 'size' | 'className'
> & {
    hasText?: boolean;
    onClick?: () => void;
};

export const AppGoBackButton = ({
    hasText = true,
    onClick,
    ...restProps
}: AppGoBackButtonProps) => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <AppButton
            {...restProps}
            marginLeft={0}
            type="text"
            onClick={onClick || goBack}
            icon={<IconArrowNarrowLeft />}
        >
            {hasText && commonTexts.actionLabels.goBack}
        </AppButton>
    );
};
