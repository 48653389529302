import { AppFormItemProps } from 'components/atoms';
import {
    StyledAppFormItem,
    Label,
    StyledAppTextEditor,
} from './WeeklyScheduleDayInputElement.styled';

export const WeeklyScheduleDayInputElement = ({ label, ...restProps }: AppFormItemProps) => (
    <StyledAppFormItem
        label={<Label>{label}</Label>}
        initialValue=""
        optional
        hasOptionalText
        {...restProps}
    >
        <StyledAppTextEditor />
    </StyledAppFormItem>
);
