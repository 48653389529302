import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { AppTable } from 'components/organisms';
import { settingsTexts } from 'consts/text';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { PageFilter, usePageFilters } from 'hooks/usePageFilters/usePageFilters';
import { useSearchParamsBasedPagination } from 'hooks/useSearchParamsBasedPagination/useSearchParamsBasedPagination';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseFilterParamsIntoGetHistoryChangesFiltersValues } from 'utils/parsers/historyChange/parseFilterParamsIntoGetHistoryChangesFiltersValues';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { GetHistoryOfChangesFilterKey, HistoryChangeDTO } from 'types';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { parsePaginationDataToTablePaginationData } from 'utils/parsers/table/parsePaginationDataToTablePaginationData';
import { HistoryChangeDetailsModal } from 'components/templates';
import { historyOfChangesFilterFields } from 'consts/historyChange/historyOfChangesFilterFields';
import { getColumns } from './HistoryOfChangesPage.utils';
import { HistoryOfChangesPageFilters } from './components';

const filters: PageFilter<GetHistoryOfChangesFilterKey>[] = [
    { name: 'startDate', type: 'SINGLE_VALUE' },
    { name: 'endDate', type: 'SINGLE_VALUE' },
    { name: 'referTo', type: 'ARRAY_WITH_SPLIT_VALUES' },
    { name: 'historyTypes', type: 'ARRAY_WITH_SPLIT_VALUES' },
];

export const HistoryOfChangesPage = () => {
    const { id: currentInstitutionId } = useCurrentInstitution();

    const [selectedHistoryChangeData, setSelectedHistoryChangeData] =
        useState<HistoryChangeDTO | null>(null);

    const { currentPage, currentPageSize, handlePaginationValuesChange } =
        useSearchParamsBasedPagination();

    const {
        filtersParams,
        searchFilterValue,
        handleChangeFilter,
        handleChangeFilters,
        handleClearFilters,
        handleSearchInput,
    } = usePageFilters({
        filters,
        currentPage,
    });

    const filtersValues = useMemo(
        () => parseFilterParamsIntoGetHistoryChangesFiltersValues(filtersParams, searchFilterValue),
        [filtersParams, searchFilterValue],
    );

    const {
        data: historyOfChangesData,
        isInitialLoading: isHistoryOfChangesDataInitialLoading,
        isRefetching: isHistoryOfChangesDataRefetching,
        isError: isHistoryOfChangesDataError,
    } = useAppQuery(
        'HISTORY_OF_CHANGES',
        [currentInstitutionId, ...Object.values(filtersValues), currentPage, currentPageSize],
        () =>
            StaffHTTPService.historyOfChanges.getHistoryOfChanges({
                institutionId: currentInstitutionId,
                filters: filtersValues,
                paginationParams: { size: currentPageSize, page: currentPage },
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const handleHistoryChangeDetailsModalCancel = () => setSelectedHistoryChangeData(null);

    const columns = useMemo(() => getColumns(setSelectedHistoryChangeData), []);

    const filtersInternalValues = useMemo(
        () => ({
            startDate: filtersValues.startDate,
            endDate: filtersValues.endDate,
            historyTypes: filtersParams[historyOfChangesFilterFields.historyTypes] as
                | string[]
                | undefined,
            referTo: filtersParams[historyOfChangesFilterFields.referTo] as string[] | undefined,
        }),
        [filtersParams, filtersValues.endDate, filtersValues.startDate],
    );

    return (
        <>
            {selectedHistoryChangeData && (
                <HistoryChangeDetailsModal
                    historyChangeData={selectedHistoryChangeData}
                    onCancel={handleHistoryChangeDetailsModalCancel}
                />
            )}
            <AppTable<HistoryChangeDTO>
                title={settingsTexts.historyOfChanges.historyOfChangesListTitle}
                columns={columns}
                dataSource={historyOfChangesData?.content}
                isError={isHistoryOfChangesDataError}
                emptyInfo={settingsTexts.historyOfChanges.historyOfChangesEmptyInfo}
                isDataInitialLoading={isHistoryOfChangesDataInitialLoading}
                isDataRefetching={isHistoryOfChangesDataRefetching}
                rowKey={({ id }) => id}
                pagination={{
                    ...parsePaginationDataToTablePaginationData({
                        currentPage,
                        currentPageSize,
                    }),
                    onChange: handlePaginationValuesChange,
                    total: historyOfChangesData?.totalElements,
                }}
                tableManageUtilsProps={{
                    searchInputProps: {
                        defaultValue: searchFilterValue,
                        onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
                    },
                    additionalFilters: (
                        <HistoryOfChangesPageFilters
                            onChangeFilter={handleChangeFilter}
                            onChangeFilters={handleChangeFilters}
                            filtersValues={filtersInternalValues}
                        />
                    ),
                    isDisplayedAsSeparateMenuOnMobile: true,
                    onClearFilters: () => handleClearFilters(true),
                }}
            />
        </>
    );
};
