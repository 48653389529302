import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts, settingsTexts } from 'consts/text';
import { WeekDays } from 'types';

type DaysPickerElementProps = AppFormItemProps & {
    disabled?: boolean;
    optional?: boolean;
    selectMode?: 'multiple' | 'tags';
};

const items: { label: string; value: WeekDays }[] = [
    {
        label: commonTexts.dayLabels.monday,
        value: 'MONDAY',
    },
    {
        label: commonTexts.dayLabels.tuesday,
        value: 'TUESDAY',
    },
    {
        label: commonTexts.dayLabels.wednesday,
        value: 'WEDNESDAY',
    },
    {
        label: commonTexts.dayLabels.thursday,
        value: 'THURSDAY',
    },
    {
        label: commonTexts.dayLabels.friday,
        value: 'FRIDAY',
    },
    {
        label: commonTexts.dayLabels.saturday,
        value: 'SATURDAY',
    },
    {
        label: commonTexts.dayLabels.sunday,
        value: 'SUNDAY',
    },
];

export const DaysPickerElement = ({
    disabled,
    optional = false,
    selectMode,
    ...props
}: DaysPickerElementProps) => (
    <AppFormItem
        label={settingsTexts.settlements.overall.formLabels.overallWorkingDays}
        name={formFields.workingDays}
        optional={optional}
        disabled={disabled}
        {...props}
    >
        <AppSelect mode={selectMode} options={items} disabled={disabled} />
    </AppFormItem>
);
