import { InternalAxiosRequestConfig } from 'axios';
import { readLocalStorageInstitutionId } from 'services/localStorage/institutionId';
import AxiosBaseInstance from 'services/HTTPService/AxiosBaseInstance';

class StaffAxiosInstance extends AxiosBaseInstance {
    constructor() {
        super();
        // @ts-ignore
        this.axios.interceptors.request.use(this.addInstitutionIdParam, Promise.reject);
    }

    addInstitutionIdParam = async (config: InternalAxiosRequestConfig) => {
        const params = { ...config.params } || {};
        const institutionId = readLocalStorageInstitutionId();
        if (institutionId) {
            params.institutionId = institutionId;
        }
        return { ...config, params };
    };
}

export default StaffAxiosInstance;
