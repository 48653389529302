import { SimpleMenuItem } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { commonTexts } from 'consts/text';

export const accountMenuItems: SimpleMenuItem[] = [
    {
        key: appPaths.app.account.details.base,
        label: commonTexts.menuItems.accountMenu.yourProfile,
    },
    {
        key: appPaths.app.account['password-update'],
        label: commonTexts.menuItems.accountMenu.changePassword,
    },
];

export const parentAccountMenuItems: SimpleMenuItem[] = [
    {
        key: appPaths.app.parent.account.details.base,
        label: commonTexts.menuItems.accountMenu.yourProfile,
    },
    {
        key: appPaths.app.parent.account['password-update'],
        label: commonTexts.menuItems.accountMenu.changePassword,
    },
];
