import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type GroupJournalImportantEventsPageNetworkManageProps = {
    journalId: JournalId;
    onImportantEventRemoveRequestFinish: () => void;
};

export const useGroupJournalImportantEventsPageNetworkManage = ({
    journalId,
    onImportantEventRemoveRequestFinish,
}: GroupJournalImportantEventsPageNetworkManageProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: importantEventsData,
        isInitialLoading: isImportantEventsDataInitialLoading,
        isRefetching: isImportantEventsDataRefetching,
        isError: isImportantEventsDataError,
    } = useAppQuery(
        'IMPORTANT_EVENTS',
        [journalId],
        () => StaffHTTPService.importantEvents.getImportantEvents(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: removeImportantEvent, isLoading: isRemoveImportantEventLoading } =
        useAppMutation(StaffHTTPService.importantEvents.removeImportantEvent, {
            key: ['REMOVE_IMPORTANT_EVENT'],
            onSuccess: () => {
                onImportantEventRemoveRequestFinish();
                displaySuccess(
                    journalTexts.journalDetails.groupJournal.events.importantEvents
                        .successfullyRemovedImportantEvent,
                );
            },
            onError: () => {
                onImportantEventRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['IMPORTANT_EVENTS', journalId]],
        });

    return {
        importantEventsData,
        isImportantEventsDataInitialLoading,
        isImportantEventsDataRefetching,
        isImportantEventsDataError,
        isRemoveImportantEventLoading,
        removeImportantEvent,
    };
};
