import { LessonFormFields, UpdateLessonDTO } from 'types';
import { parseDayjsTimeIntoHoursMinutesFormat } from 'utils/parsers/dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export const parseLessonFormFieldsIntoUpdateLessonDTO: (
    lessonFormFields: LessonFormFields,
) => UpdateLessonDTO = ({ timeFrom, timeTo, ...restFormFields }) => ({
    ...restFormFields,
    timeFrom: parseDayjsTimeIntoHoursMinutesFormat(timeFrom),
    timeTo: parseDayjsTimeIntoHoursMinutesFormat(timeTo),
});
