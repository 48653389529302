import { LabelMapper, HelpForm } from 'types';

export const psychologicalHelpFormsLabels: LabelMapper<HelpForm> = {
    ADVICE_AND_CONSULTATION: 'Porady i konsultacje',
    CLASSES_WITH_PSYCHOLOGIST: 'Zajęcia z psychologiem',
    CORRECTIVE_COMPENSATORY_CLASSES: 'Zajęcia korekcyjno-kompensacyjne',
    PEDAGOGICAL_THERAPY: 'Terapia pedagogiczna',
    SOCIOTHERAPEUTIC_CLASSES: 'Zajęcia socjoterapeutyczne',
    TALENTS_DEVELOPING_CLASSES: 'Zajęcia rozwijające uzdolnienia',
    SPEECH_THERAPY_CLASSES: 'Zajęcia logopedyczne',
    OTHER: 'Inne o charakterze terapeutycznym',
};
