import styled, { css } from 'styled-components';
import { AppText, WeeklyScheduleCardBase, WeeklyScheduleCardBaseProps } from 'components/atoms';
import NoLessonsStateIcon from 'assets/icons/statesInfo/NoLessonsState.svg';
import { commonTexts } from 'consts/text';

type TimetableScheduleCardProps = WeeklyScheduleCardBaseProps & {
    shouldDisplayEmptyLessonElement: boolean;
};

const ContentContainer = styled.div<{ shouldDisplayEmptyLessonElement: boolean }>`
    ${({ theme, shouldDisplayEmptyLessonElement }) => css`
        min-height: 400px;
        background-image: ${shouldDisplayEmptyLessonElement
            ? `url(${NoLessonsStateIcon})`
            : 'none'};
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        @media (${theme.sizes.mediaQueries.tabletMax}) {
            height: auto;
            min-height: 170px;
        }
    `}
`;

const NoLessonsText = styled(AppText)<{ shouldDisplayEmptyLessonElement: boolean }>`
    ${({ shouldDisplayEmptyLessonElement }) =>
        shouldDisplayEmptyLessonElement
            ? css`
                  position: absolute;
                  top: 50%;
                  transform: translate(0%, 100%);
                  text-align: center;
                  width: 100%;
                  margin: 0 !important;
                  font-weight: bold;
              `
            : ''}
`;

export const TimetableScheduleCard = ({
    dayLabel,
    date,
    children,
    shouldDisplayEmptyLessonElement,
}: TimetableScheduleCardProps) => (
    <WeeklyScheduleCardBase dayLabel={dayLabel} date={date}>
        <ContentContainer shouldDisplayEmptyLessonElement={shouldDisplayEmptyLessonElement}>
            {
                <>
                    {children}
                    {shouldDisplayEmptyLessonElement && (
                        <NoLessonsText shouldDisplayEmptyLessonElement>
                            {commonTexts.emptyInfo.noLessons}
                        </NoLessonsText>
                    )}
                </>
            }
        </ContentContainer>
    </WeeklyScheduleCardBase>
);
