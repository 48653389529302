import { List, ListProps } from 'antd';
import styled, { css } from 'styled-components';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { getMarginsCss } from 'utils/getMarginsCss';
import { MarginsProps } from 'types';
import { AppListItem } from './components';

export type AppListProps<ListItem> = ListProps<ListItem> &
    MarginsProps & { customizedPadding?: boolean };

const StyledList = styled(List).withConfig({
    shouldForwardProp: getShouldForwardProp(['customizedPadding']),
})<AppListProps<never>>`
    ${({
        theme,
        margin,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        customizedPadding = false,
    }) => css`
        ${getMarginsCss({
            margin,
            marginTop,
            marginBottom,
            marginLeft,
            marginRight,
        })}

        &.ant-list-split .ant-list-header {
            border-block-end: 1px solid ${theme.colors.primary.primary3};
        }

        &.ant-list-bordered {
            ${inputAndPickerBorderStyle}

            ${!customizedPadding &&
            css`
                .ant-list-item {
                    padding: unset;
                }
            `}
        }

        .ant-list-empty-text {
            .ant-empty-image > svg > g {
                g {
                    stroke: ${theme.colors.grayscale.gray10};
                }

                ellipse {
                    fill: ${theme.colors.grayscale.gray10};
                }
            }

            .ant-empty-description {
                color: ${theme.colors.grayscale.gray10};
            }
        }
    `}
` as typeof List;

export const AppList = <ListItem,>(props: AppListProps<ListItem>) => <StyledList {...props} />;

AppList.Item = AppListItem;
