import {
    CountWithLimitDisplay,
    DateDisplay,
    FirstAndLastNameDisplay,
    PhoneNumberDisplay,
    PriceDisplay,
    TableTagDisplay,
    BalanceTag,
    InstitutionNameDisplay,
} from 'components/molecules';
import { subscriptionTableColumnKeys } from 'consts/subscription/subscriptionTableColumnKeys';
import { subscriptionTypeLabels } from 'consts/subscription/subscriptionTypeLabels';
import { tagColorsBySubscriptionType } from 'consts/subscription/tagColorsBySubscriptionType';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppTableProps } from 'components/organisms';
import { FullInstitutionSubscriptionDTO } from 'types';
import { commonTexts, subscriptionsTexts } from 'consts/text';
import { fullInstitutionSubscriptionDTOFields } from 'consts/subscription/fullInstitutionSubscriptionDTOFields';

export const columns: AppTableProps<FullInstitutionSubscriptionDTO>['columns'] = [
    {
        title: commonTexts.dataLabels.institutionName,
        dataIndex: fullInstitutionSubscriptionDTOFields.institution,
        key: subscriptionTableColumnKeys.institutionName,
        render: (_, fullInstitutionSubscriptionDTO) => (
            <InstitutionNameDisplay
                subscriptionId={fullInstitutionSubscriptionDTO.subscriptionBasicData.id}
                institutionName={fullInstitutionSubscriptionDTO.institution.name}
            />
        ),
    },
    {
        title: commonTexts.dataLabels.contact,
        dataIndex: fullInstitutionSubscriptionDTOFields.institutionOwner,
        key: subscriptionTableColumnKeys.phoneNumber,
        isVisibleAsExtendableInMobile: true,
        render: (institutionOwner: FullInstitutionSubscriptionDTO['institutionOwner']) => (
            <PhoneNumberDisplay phoneNumber={institutionOwner.phoneNumber} />
        ),
    },
    {
        title: commonTexts.dataLabels.lastnameAndFirstname,
        dataIndex: fullInstitutionSubscriptionDTOFields.institutionOwner,
        key: subscriptionTableColumnKeys.lastnameAndFirstname,
        isVisibleAsExtendableInMobile: true,
        render: (institutionOwner: FullInstitutionSubscriptionDTO['institutionOwner']) => (
            <FirstAndLastNameDisplay
                firstName={institutionOwner.firstName}
                lastName={institutionOwner.lastName}
            />
        ),
    },
    {
        title: commonTexts.dataLabels.children,
        dataIndex: fullInstitutionSubscriptionDTOFields.subscriptionBasicData,
        key: subscriptionTableColumnKeys.children,
        isVisibleAsExtendableInMobile: true,
        render: (
            subscriptionBasicData: FullInstitutionSubscriptionDTO['subscriptionBasicData'],
        ) => (
            <CountWithLimitDisplay
                count={subscriptionBasicData.activeChildren}
                limit={subscriptionBasicData.childLimit}
            />
        ),
    },
    {
        title: commonTexts.dataLabels.amount,
        dataIndex: fullInstitutionSubscriptionDTOFields.subscriptionBasicData,
        key: subscriptionTableColumnKeys.amount,
        isVisibleAsExtendableInMobile: true,
        render: (
            subscriptionBasicData: FullInstitutionSubscriptionDTO['subscriptionBasicData'],
        ) => <PriceDisplay value={subscriptionBasicData.price} />,
    },
    {
        title: subscriptionsTexts.list.columns.lastPayment,
        dataIndex: fullInstitutionSubscriptionDTOFields.balance,
        key: subscriptionTableColumnKeys.lastPayment,
        isVisibleAsExtendableInMobile: true,
        render: (balance: FullInstitutionSubscriptionDTO['balance']) =>
            balance.lastPayment ? <DateDisplay date={balance.lastPayment} /> : EMPTY_VALUE_LABEL,
    },
    {
        title: subscriptionsTexts.list.columns.balance,
        dataIndex: fullInstitutionSubscriptionDTOFields.balance,
        key: subscriptionTableColumnKeys.balance,
        isVisibleAsExtendableInMobile: true,
        render: (balance: FullInstitutionSubscriptionDTO['balance']) => (
            <BalanceTag amount={balance.balance} />
        ),
    },
    {
        title: commonTexts.dataLabels.status,
        dataIndex: fullInstitutionSubscriptionDTOFields.subscriptionBasicData,
        key: subscriptionTableColumnKeys.status,
        isVisibleAsExtendableInMobile: true,
        render: (
            subscriptionBasicData: FullInstitutionSubscriptionDTO['subscriptionBasicData'],
        ) => (
            <TableTagDisplay
                value={subscriptionBasicData.type}
                labelMapper={subscriptionTypeLabels}
                colorMapper={tagColorsBySubscriptionType}
            />
        ),
    },
];
