import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { UserCheckBottomIcon } from 'assets';
import { FormPageModeDependedTexts, FormTypes, ParentFormFields } from 'types';
import {
    BankAccountNumberInputElement,
    EmailInputElement,
    FormNavButtons,
    LastNameInputElement,
    FirstNameInputElement,
    PhoneNumberInputElement,
    AppModal,
    AppModalProps,
} from 'components/molecules';
import { AppModalForm, AppModalFormProps } from 'components/organisms';
import { checkIfParentModalEditFormDataAreEqualAfterChanges } from 'utils/parsers/checkIfParentModalEditFormDataAreEqualAfterChanges';
import { childrenTexts, commonTexts } from 'consts/text/index';
import {
    WithAccountCreationOrNoPicker,
    WithAccountCreationOrNoPickerValue,
} from './components/WithAccountCreationOrNoPicker/WithAccountCreationOrNoPicker';
import { AddressInputsSection } from './components/AddressInputsSection/AddressInputsSection';

type ParentModalAddEditFormProps = Pick<AppModalProps, 'open' | 'onCancel'> &
    Omit<AppModalFormProps<ParentFormFields>, 'withGoBack' | 'width' | 'name' | 'onFinish'> & {
        formName: FormTypes;
        isInnerChildForm?: boolean;
        onSubmitForm: (values: ParentFormFields) => void;
    };

const addModeTexts: FormPageModeDependedTexts = {
    title: 'Dodaj rodzica',
    buttonText: 'Dodaj rodzica',
};

const editModeTexts: FormPageModeDependedTexts = {
    title: 'Edytuj rodzica',
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const ParentModalAddEditForm = ({
    open,
    isInnerChildForm,
    onCancel,
    formName,
    onSubmitForm,
    mode = 'add',
    initialValues,
    ...props
}: ParentModalAddEditFormProps) => {
    const [withAccountCreationOrNoMode, setWithAccountCreationOrNoMode] =
        useState<WithAccountCreationOrNoPickerValue>('WITH_ACCOUNT_CREATION');
    const theme = useTheme();

    const isAddMode = mode === 'add';
    const { title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const isEditingParentWithoutAccount = !isAddMode && !!initialValues?.email;

    useEffect(() => {
        const withAccountCreation = isAddMode || isEditingParentWithoutAccount;
        setWithAccountCreationOrNoMode(
            withAccountCreation ? 'WITH_ACCOUNT_CREATION' : 'WITHOUT_ACCOUNT_CREATION',
        );
    }, [isAddMode, isEditingParentWithoutAccount]);

    return (
        <AppModal
            title={title}
            width={theme.sizes.modalWidth.medium}
            icon={<UserCheckBottomIcon />}
            open={open}
            onCancel={onCancel}
            destroyOnClose
        >
            <AppModalForm<ParentFormFields>
                name={formName}
                onFinish={onSubmitForm}
                initialValues={initialValues}
                {...props}
            >
                <WithAccountCreationOrNoPicker
                    selectedValue={withAccountCreationOrNoMode}
                    onClickRadioButton={setWithAccountCreationOrNoMode}
                />
                <FirstNameInputElement />
                <LastNameInputElement />
                {withAccountCreationOrNoMode === 'WITH_ACCOUNT_CREATION' && (
                    <EmailInputElement
                        disabled={!isInnerChildForm && isEditingParentWithoutAccount}
                        extraOnDisabled={childrenTexts.childDetails.parents.parentDisabledEmail}
                    />
                )}
                <BankAccountNumberInputElement optional />
                <PhoneNumberInputElement optional />
                <AddressInputsSection />
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    checkEqualityHandler={checkIfParentModalEditFormDataAreEqualAfterChanges}
                    buttonText={buttonText}
                    leftIcon={buttonIcon}
                    withPlus={isAddMode}
                    marginBottom={0}
                />
            </AppModalForm>
        </AppModal>
    );
};
