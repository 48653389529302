import { WorkingTimeDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';

export const parseWorkingTimeDTOIntoJsonObject: (workingTimeDTO: WorkingTimeDTO) => object = (
    workingTimeDTO,
) => {
    const { firstName, lastName, timeFrom, timeTo, date } = workingTimeDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const formattedTimeFrom = parseStringIntoFormattedStringTime(timeFrom);
    const formattedTimeTo = parseStringIntoFormattedStringTime(timeTo);

    return {
        [jsonObjectsTexts.workingTimeDTO.fields.firstName]: firstName,
        [jsonObjectsTexts.workingTimeDTO.fields.lastName]: lastName,
        [jsonObjectsTexts.workingTimeDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.workingTimeDTO.fields.timeFrom]: formattedTimeFrom,
        [jsonObjectsTexts.workingTimeDTO.fields.timeTo]: formattedTimeTo,
    };
};
