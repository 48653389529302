import { AppFormItem, AppFormItemProps, AppSelect, AppSelectProps } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts, employeeTexts } from 'consts/text';
import { useMemo } from 'react';
import { EmployeeId, SelectOptionType } from 'types';

export enum TeacherPickerVersion {
    GROUP = 'GROUP',
    INSPECTION = 'INSPECTION',
    MULTIPLE = 'MULTIPLE',
}

type ModeData = {
    label: string;
    formField: string;
    isOptional: boolean;
    selectMode?: AppSelectProps['mode'];
};

const groupModeData: ModeData = {
    label: employeeTexts.teacherPickerLabels.teachers,
    formField: formFields.teacherIds,
    isOptional: true,
    selectMode: 'multiple',
};

const inspectionModeData: ModeData = {
    label: employeeTexts.teacherPickerLabels.supervisedEmployee,
    formField: formFields.teacherId,
    isOptional: false,
};

const multipleModeData: ModeData = {
    label: employeeTexts.teacherPickerLabels.addTeachers,
    formField: formFields.teacherIds,
    isOptional: false,
    selectMode: 'multiple',
};

const defaultModeData: ModeData = {
    label: commonTexts.dataLabels.teacherList,
    formField: formFields.teacherId,
    isOptional: false,
};

export type TeachersPickerElementProps = AppFormItemProps &
    Pick<AppSelectProps, 'loading'> & {
        options?: SelectOptionType<EmployeeId>[];
        version?: TeacherPickerVersion;
    } & {
        onChange?: (teacherIds: EmployeeId | EmployeeId[]) => void;
    };

export const TeachersPickerElement = ({
    options,
    loading,
    version,
    disabled,
    optional,
    label,
    onChange,
    ...props
}: TeachersPickerElementProps) => {
    const {
        label: defaultLabel,
        formField,
        isOptional: isOptionalByDefault,
        selectMode,
    } = useMemo(() => {
        switch (version) {
            case TeacherPickerVersion.INSPECTION:
                return inspectionModeData;
            case TeacherPickerVersion.MULTIPLE:
                return multipleModeData;
            case TeacherPickerVersion.GROUP:
                return groupModeData;
            default:
                return defaultModeData;
        }
    }, [version]);

    return (
        <AppFormItem
            label={label ?? defaultLabel}
            name={formField}
            optional={optional ?? isOptionalByDefault}
            hasOptionalText={optional ?? isOptionalByDefault}
            disabled={disabled}
            {...props}
        >
            <AppSelect
                mode={selectMode}
                options={options}
                loading={loading}
                onChange={onChange}
                disabled={disabled}
            />
        </AppFormItem>
    );
};
