import styled, { css, useTheme } from 'styled-components';
import { AppText } from 'components/atoms/CommonAppComponents';
import { readStatusData, unreadStatusData } from './IndividualMailThreadMessageReadStatus.consts';

type IndividualMailThreadMessageReadStatusProps = {
    read: boolean;
};

const Container = styled.div`
    ${({ theme }) => css`
        margin-top: 24px;
        display: flex;
        align-items: center;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            margin-top: ${theme.sizes.marginNormal}px;
        }
    `}
`;

export const IndividualMailThreadMessageReadStatus = ({
    read,
}: IndividualMailThreadMessageReadStatusProps) => {
    const theme = useTheme();

    const { text, icon, textColor } = read ? readStatusData : unreadStatusData;

    return (
        <Container>
            {icon}
            <AppText margin={0} marginLeft={theme.sizes.marginSmall} color={textColor}>
                {text}
            </AppText>
        </Container>
    );
};
