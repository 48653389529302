import { elementColors } from 'theme/styledComponents/colors';
import { ColorMapper, DayAttendanceAbsentType, DayAttendanceRemote } from 'types';

export const tagColorsByDayAttendanceAbsentType: ColorMapper<
    DayAttendanceAbsentType | DayAttendanceRemote
> = {
    ABSENT: elementColors.red,
    ABSENT_PLANNED: elementColors.yellow,
    REMOTE: elementColors.green,
};
