import styled, { css } from 'styled-components';

export const OuterContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 12px;
        background-color: ${theme.colors.primary.primary2};
        border: 1px solid ${theme.colors.primary.primary5};
        border-radius: ${theme.borders.borderRadiusNormal}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    `}
`;

export const DesktopContainer = styled.div`
    display: table;
    width: 100%;
`;

export const MobileContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
