import { Rule } from 'antd/es/form';
import { AppFormItem, AppFormItemProps, AppFormRangeItem, AppTimePicker } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { TIME_LABEL } from 'consts/labels/common';
import { formElementRequiredText } from 'consts/errors/forms';
import { childrenTexts } from 'consts/text';
import { AppFormRangeItemMode } from 'types';
import { datesTimelineValidator } from 'utils/validators';

export type DeclaredStayHoursTimePickerProps = AppFormItemProps & {
    mode: AppFormRangeItemMode;
};

export const DeclaredStayHoursTimePicker = ({
    mode,
    ...props
}: DeclaredStayHoursTimePickerProps) => {
    const rules: Rule[] = [
        { required: true, message: formElementRequiredText },
        ({ getFieldValue }) =>
            datesTimelineValidator(getFieldValue, {
                mode,
                startValueFieldName: formFields.declaredStayHoursFromTime,
                finishValueFieldName: formFields.declaredStayHoursToTime,
                isFinishValueBeforeStartValueErrorText:
                    childrenTexts.childForm.settlementData.declaredStayHoursEndBeforeStart,
                isStartValueAfterFinishValueErrorText:
                    childrenTexts.childForm.settlementData.declaredStayHoursStartAfterEnd,
                unitToCompare: 'minute',
            }),
    ];

    const getFormItemLabel = (itemMode: AppFormRangeItemMode) => (
        <AppFormItem.Label
            mainText={
                itemMode === 'start'
                    ? childrenTexts.childForm.settlementData.declaredStayHoursStart
                    : childrenTexts.childForm.settlementData.declaredStayHoursEnd
            }
            additionalText={TIME_LABEL}
        />
    );

    return (
        <AppFormRangeItem
            mode={mode}
            startValues={{
                label: getFormItemLabel('start'),
                name: formFields.declaredStayHoursFromTime,
                rules,
            }}
            finishValues={{
                label: getFormItemLabel('finish'),
                name: formFields.declaredStayHoursToTime,
                rules,
            }}
            {...props}
        >
            <AppTimePicker autoComplete="off" />
        </AppFormRangeItem>
    );
};
