import { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { ScheduleType } from 'types';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { appPaths } from 'consts/paths/paths';
import { NoScheduleDataStateIcon } from 'assets';
import { journalTexts } from 'consts/text';
import { useJournalAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { PageBaseLayoutProps } from '../PageBaseLayout/PageBaseLayout';
import { StyledButton, StyledPageLayout, StyledSelect } from './GroupJournalSchedulesLayout.styled';
import {
    buttonAddScheduleMode,
    buttonEditScheduleMode,
    dailyScheduleTypeTexts,
    scheduleOptions,
    weeklyScheduleTypeTexts,
    getSchedulePagePathToRedirect,
} from './GroupJournalSchedulesLayout.utils';

type GroupJournalSchedulesLayoutProps = Required<
    Pick<PageBaseLayoutProps, 'isDataInitialLoading' | 'isDataRefetching' | 'isError' | 'isEmpty'>
> & {
    scheduleType: ScheduleType;
    children: ReactNode;
};

export const GroupJournalSchedulesLayout = ({
    scheduleType,
    children,
    isDataInitialLoading,
    isDataRefetching,
    isError,
    isEmpty,
}: GroupJournalSchedulesLayoutProps) => {
    const { journalId } = useJournalId();
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const {
        groupJournalsAbilities: { checkIfCanManageSchedule },
    } = useJournalAbilities();

    const canManageSchedule = checkIfCanManageSchedule();
    const isDailySchedule = scheduleType === 'DAILY';
    const { emptyInfo, emptyAdditionalInfo } = isDailySchedule
        ? dailyScheduleTypeTexts
        : weeklyScheduleTypeTexts;
    const { buttonText, buttonIcon } = isEmpty ? buttonAddScheduleMode : buttonEditScheduleMode;

    const handleScheduleTypeChange = (selectedScheduleType: ScheduleType) =>
        statefulNavigate(
            generatePath(getSchedulePagePathToRedirect(selectedScheduleType), { journalId }),
        );

    const navigateToScheduleAddPage = () =>
        statefulNavigate(
            generatePath(
                isDailySchedule
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.add
                    : appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.add,
                {
                    journalId,
                },
            ),
        );

    const navigateToScheduleEditPage = () =>
        statefulNavigate(
            generatePath(
                isDailySchedule
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.edit
                    : appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.edit,
                {
                    journalId,
                },
            ),
        );

    const headerContent = (
        <>
            <StyledSelect
                label={journalTexts.journalDetails.groupJournal.schedules.selectSchedule}
                value={scheduleType}
                options={scheduleOptions}
                allowClear={false}
                isFitContentWidth={!isMobile}
                onChange={handleScheduleTypeChange}
            />
            {canManageSchedule && (
                <StyledButton
                    type="primary"
                    icon={buttonIcon}
                    fullWidth={isMobile}
                    onClick={isEmpty ? navigateToScheduleAddPage : navigateToScheduleEditPage}
                >
                    {buttonText}
                </StyledButton>
            )}
        </>
    );

    return (
        <StyledPageLayout
            isEmpty={isEmpty}
            isError={isError}
            isDataInitialLoading={isDataInitialLoading}
            isDataRefetching={isDataRefetching}
            headerContent={headerContent}
            emptyStateData={{
                emptyInfo,
                emptyAdditionalInfo: canManageSchedule ? emptyAdditionalInfo : undefined,
                emptyIcon: <NoScheduleDataStateIcon />,
            }}
        >
            {children}
        </StyledPageLayout>
    );
};
