import { GroupConsultationChildDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';

export const parseGroupConsultationChildDTOIntoJsonObject: (
    groupConsultationChildDTO: GroupConsultationChildDTO,
) => object = (groupConsultationChildDTO) => {
    const { lastName, firstName, parents } = groupConsultationChildDTO;

    const parentsJsonObject = parents.map((parent) => ({
        [jsonObjectsTexts.groupConsultationDTO.innerObjects.groupConsultationChildDTO.innerObjects
            .groupConsultationParentDTO.fields.firstname]: parent.firstName,
        [jsonObjectsTexts.groupConsultationDTO.innerObjects.groupConsultationChildDTO.innerObjects
            .groupConsultationParentDTO.fields.lastname]: parent.lastName,
    }));

    return {
        [jsonObjectsTexts.groupConsultationDTO.innerObjects.groupConsultationChildDTO.fields
            .firstname]: firstName,
        [jsonObjectsTexts.groupConsultationDTO.innerObjects.groupConsultationChildDTO.fields
            .lastname]: lastName,
        [jsonObjectsTexts.groupConsultationDTO.innerObjects.groupConsultationChildDTO.fields
            .parents]: parentsJsonObject,
    };
};
