import { ChildPerson } from 'assets';
import { childrenTexts } from 'consts/text';
import { Child, ChildParent, ParentFormFields } from 'types';
import { ParentModalAddEditForm, ParentTable } from 'components/templates/parent';
import { parseParentFormFieldsIntoChildParent } from 'utils/parsers/parent/parseParentFormFieldsIntoChildParent';
import { useGuardianOrParent } from 'hooks/useGuardianOrParent/useGuardianOrParent';
import { ParentAndGuardianFormItemContentContainer } from 'components/molecules';
import { MAX_PARENTS_DATA_ITEMS_COUNT } from 'consts/child/generalValues';
import { useState } from 'react';
import { useParentMutate } from 'hooks/useParentMutate/useParentMutate';
import { ComponentBlockingAppLoader, DetailsFrameBase } from 'components/atoms';
import { parseChildParentIntoAddParentDTO } from 'utils/parsers/parent/parseChildParentIntoAddParentDTO';
import { parseChildParentIntoParentFormFields } from 'utils/parsers/parent/parseChildParentIntoParentFormFields';
import { useChildAbilities } from 'abilities';

export type ParentsProps = Pick<Child, 'parents' | 'id' | 'status'>;

export const Parents = ({ parents, id, status: childStatus }: ParentsProps) => {
    const [editingParent, setEditingParent] = useState<ChildParent | null>(null);
    const { addParent, deleteParent, updateParent, isParentMutating } = useParentMutate({
        childId: id,
    });
    const {
        isModalFormOpen,
        handleModalFormOpen,
        handleModalFormClose,
        handleDataItemAdd: handleParentAdd,
        handleDataItemEdit: handleParentEdit,
        handleDataItemRemove: handleParentRemove,
        handleTableDataItemEditClick: handleParentTableItemEditClick,
    } = useGuardianOrParent<ChildParent>({
        onItemAdd: (childParent) => {
            addParent({
                childId: id,
                childParent: parseChildParentIntoAddParentDTO(childParent),
            });
        },
        onItemEditFinish: (childParent) => {
            updateParent({
                childId: id,
                parentId: childParent.id,
                childParent: parseChildParentIntoAddParentDTO(childParent),
            });
        },
        onItemRemove: (childParent) => {
            deleteParent({ childId: id, parentId: childParent.id });
        },
        onItemToEditPick: (childParent) => {
            setEditingParent(childParent);
        },
        clearSelectedItem: () => setEditingParent(null),
    });
    const { childAbilities } = useChildAbilities();

    const canManageChildDetails = childAbilities.checkIfCanManageChildrenList();
    const isSelectedParentTableItemToEdit = !!editingParent;
    const modalFormMode = isSelectedParentTableItemToEdit ? 'edit' : 'add';
    const formName = isSelectedParentTableItemToEdit ? 'parentEdit' : 'parentAdd';

    const modalFormSubmitFormHandler = (data: ParentFormFields) => {
        const formData = parseParentFormFieldsIntoChildParent(data, editingParent);
        return isSelectedParentTableItemToEdit
            ? handleParentEdit(formData)
            : handleParentAdd(formData);
    };

    return (
        <DetailsFrameBase
            icon={<ChildPerson />}
            title={childrenTexts.childDetails.parents.parentsData}
            noFrame
            smallHeaderMargin
        >
            <ParentModalAddEditForm
                formName={formName}
                open={isModalFormOpen}
                mode={modalFormMode}
                onSubmitForm={modalFormSubmitFormHandler}
                onCancel={handleModalFormClose}
                initialValues={
                    isSelectedParentTableItemToEdit
                        ? parseChildParentIntoParentFormFields(editingParent)
                        : undefined
                }
            />
            <ParentAndGuardianFormItemContentContainer
                addButtonText={childrenTexts.childDetails.parents.addParent}
                onAddButtonClick={handleModalFormOpen}
                isInvisibleAddButton={
                    !canManageChildDetails || parents.length === MAX_PARENTS_DATA_ITEMS_COUNT
                }
                tableElement={
                    <ParentTable
                        parents={parents}
                        onEditParentClick={handleParentTableItemEditClick}
                        onRemoveParentClick={handleParentRemove}
                        testId="Parents_table"
                        canManageTable={canManageChildDetails}
                        childStatus={childStatus}
                    />
                }
            />
            {isParentMutating && <ComponentBlockingAppLoader />}
        </DetailsFrameBase>
    );
};
