import { useEffect } from 'react';
import { Form } from 'antd';
import { FormNavButtons } from 'components/molecules';
import { AppForm } from 'components/organisms';
import { FormPageProps } from 'types';
import { formFields } from 'consts/form/formFields';
import { childrenTexts } from 'consts/text';
import { GuardianFormSection } from './components/GuardianFormSection/GuardianFormSection';
import { ParentFormSection } from './components/ParentFormSection/ParentFormSection';

export const ChildAddStep2 = ({ enforcedInitialValues, ...props }: FormPageProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (enforcedInitialValues && !form.getFieldValue(formFields.parents)) {
            form.setFieldsValue(enforcedInitialValues);
        }
    }, [enforcedInitialValues, form]);

    return (
        <AppForm
            form={form}
            title={childrenTexts.childForm.addChildFormTitle}
            desktopTitleLevel={4}
            isFormHeaderAtBottom
            maxWidth={1024}
            {...props}
        >
            <ParentFormSection />
            <GuardianFormSection />
            <FormNavButtons buttonText="Dalej" withGoBackButton />
        </AppForm>
    );
};
