import styled, { css } from 'styled-components';
import { AppDatePicker, AppDatePickerProps, AppFormItem, AppFormItemProps } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { DATE_LABEL } from 'consts/labels/common';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

type AdditionalStyledDatePickerProps = { isHalfParentWidth?: boolean };
type DatePickerElementProps = AppFormItemProps &
    Pick<AppDatePickerProps, 'onChange'> &
    AdditionalStyledDatePickerProps;

export const StyledDatePicker = styled(AppDatePicker).withConfig({
    shouldForwardProp: getShouldForwardProp(['isHalfParentWidth']),
})<AppDatePickerProps & AdditionalStyledDatePickerProps>`
    ${({ isHalfParentWidth }) => css`
        width: ${isHalfParentWidth ? '50%' : ''};
    `}
`;

export const DatePickerElement = ({
    onChange,
    isHalfParentWidth,
    disabled,
    ...restProps
}: DatePickerElementProps) => (
    <AppFormItem
        name={formFields.date}
        label={<AppFormItem.Label mainText="Data" additionalText={DATE_LABEL} />}
        disabled={disabled}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...restProps}
    >
        <StyledDatePicker
            autoComplete="off"
            isHalfParentWidth={isHalfParentWidth}
            disabled={disabled}
            onChange={onChange}
        />
    </AppFormItem>
);
