import { ExtendedDataNode } from 'components/atoms';
import { parseMailThreadChildReceiverDTOIntoExtendedDataNode } from 'utils/parsers/mailThread/parseMailThreadChildReceiverDTOIntoExtendedDataNode';
import { MailThreadChildrenGroupDTO } from 'types';

export const getMailThreadChildrenGroupsTreePickerNodes = (
    mailThreadChildrenGroupDTOs: MailThreadChildrenGroupDTO[],
) =>
    mailThreadChildrenGroupDTOs.reduce<ExtendedDataNode[]>(
        (allGroupsTreeNodes, currentMailThreadChildrenGroupDTO) => {
            const {
                name: groupName,
                children: groupChildren,
                groupId,
            } = currentMailThreadChildrenGroupDTO;
            const childrenDataNodes: ExtendedDataNode[] = groupChildren.map((groupChild) =>
                parseMailThreadChildReceiverDTOIntoExtendedDataNode(groupChild, groupId),
            );
            return allGroupsTreeNodes.concat({
                title: groupName,
                key: groupId,
                children: childrenDataNodes,
            });
        },
        [],
    );
