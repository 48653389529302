import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { useAppAbility } from '../useAppAbility';

export type SettingsAbilitiesNames =
    | 'checkIfCanViewInstitutionData'
    | 'checkIfCanManageInstitutionData'
    | 'checkIfCanViewRolesAndPermissions'
    | 'checkIfCanManageRolesAndPermissions'
    | 'checkIfCanViewChangesHistory'
    | 'checkIfCanViewPayment'
    | 'checkIfCanViewSettlementSettings'
    | 'checkIfCanManageSettlementSettings'
    | 'checkIfCanViewDietSettings'
    | 'checkIfCanManageDietSettings'
    | 'checkIfCanViewPrincipal'
    | 'checkIfCanManagePrincipal';

export const useSettingsAbilities = () => {
    const ability = useAppAbility();

    const settingsAbilities: Record<SettingsAbilitiesNames, () => boolean> = {
        checkIfCanViewInstitutionData: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.INSTITUTION_DATA),
        checkIfCanManageInstitutionData: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.INSTITUTION_DATA),
        checkIfCanViewPrincipal: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.PRINCIPAL_SETTINGS),
        checkIfCanManagePrincipal: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.PRINCIPAL_SETTINGS),
        checkIfCanViewPayment: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.PAYMENTS_SETTINGS),
        checkIfCanViewSettlementSettings: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SETTLEMENT_SETTINGS),
        checkIfCanManageSettlementSettings: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.SETTLEMENT_SETTINGS),
        checkIfCanViewDietSettings: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.DIET_SETTINGS),
        checkIfCanManageDietSettings: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.DIET_SETTINGS),
        checkIfCanViewRolesAndPermissions: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.ROLES_AND_PERMISSIONS),
        checkIfCanManageRolesAndPermissions: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.ROLES_AND_PERMISSIONS),
        checkIfCanViewChangesHistory: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.CHANGES_HISTORY),
    };

    return { settingsAbilities };
};
