import { useMemo } from 'react';
import { Filter } from 'components/molecules';
import { ChildContractStatus, ChildStatus, GetChildrenFilterKey, GroupDTO, GroupId } from 'types';
import { groupDTOFields } from 'consts/group/groupDTOFields';
import { childFilterFields } from 'consts/filters/child/childFilterFields';
import { childrenTexts, commonTexts } from 'consts/text/index';

const contractValidOptions: { label: string; value: ChildContractStatus }[] = [
    { label: commonTexts.dataLabels.activeFemale, value: 'ACTIVE' },
    { label: commonTexts.dataLabels.inactiveFemale, value: 'INACTIVE' },
];

const statusOptions: { label: string; value: ChildStatus }[] = [
    { label: commonTexts.dataLabels.activeFemale, value: 'ACTIVE' },
    { label: commonTexts.dataLabels.inactiveFemale, value: 'INACTIVE' },
];

type ChildrenPageFiltersProps = {
    selectedGroupIds?: GroupId[] | null;
    status?: ChildStatus | null;
    contractValid?: ChildContractStatus | null;
    groups?: GroupDTO[];
    isGroupsLoading?: boolean;
    onChangeFilter?: (filterKey: GetChildrenFilterKey, value: string | string[]) => void;
};

export const ChildrenPageFilters = ({
    selectedGroupIds,
    status,
    contractValid,
    groups,
    isGroupsLoading,
    onChangeFilter,
}: ChildrenPageFiltersProps) => {
    const groupOptions = useMemo<{ label: string; value: GroupId }[] | undefined>(() => {
        if (!groups) {
            return [];
        }
        return groups.map((group) => ({
            label: group[groupDTOFields.name],
            value: group[groupDTOFields.id],
        }));
    }, [groups]);

    return (
        <>
            <Filter
                input={
                    <Filter.Select
                        value={contractValid}
                        options={contractValidOptions}
                        placeholder={commonTexts.actionLabels.select}
                        onChange={(value) =>
                            onChangeFilter?.(childFilterFields.contractValid, value)
                        }
                    />
                }
                label={childrenTexts.childrenListColumns.agreement}
                width="15%"
            />
            <Filter
                input={
                    <Filter.Select
                        value={status}
                        options={statusOptions}
                        placeholder={commonTexts.actionLabels.select}
                        onChange={(value) => onChangeFilter?.(childFilterFields.status, value)}
                        allowClear={false}
                    />
                }
                label={commonTexts.dataLabels.status}
                width="15%"
            />
            <Filter
                input={
                    <Filter.Select
                        mode="multiple"
                        value={selectedGroupIds}
                        options={groupOptions}
                        loading={isGroupsLoading}
                        placeholder={commonTexts.actionLabels.select}
                        onChange={(value) => onChangeFilter?.(childFilterFields.groupIds, value)}
                    />
                }
                label={commonTexts.dataLabels.group}
                width="15%"
            />
        </>
    );
};
