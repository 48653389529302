import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { getTextStyle, TextType } from 'utils/getTextStyle';

export type AppLinkProps = LinkProps & { highlighted?: boolean; textType?: TextType };

export const StyledAppLink = styled(Link).withConfig({
    shouldForwardProp: getShouldForwardProp(['highlighted', 'textType']),
})<AppLinkProps>`
    ${({ theme, highlighted, textType }) => css`
        text-decoration: none;
        color: ${highlighted
            ? theme.colors.primary.primary9
            : theme.colors.grayscale.gray10} !important;
        ${getTextStyle(textType || (highlighted ? 'BodyMSSemiBold' : 'BodyMSRegular'))}
    `}
`;

export const AppLink = (props: AppLinkProps) => <StyledAppLink {...props} />;
