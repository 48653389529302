import {
    AddDiscountDTO,
    ChildId,
    DiscountFormFields,
    EditDiscountDTO,
    EditDiscountFormFields,
} from 'types';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseAddDiscountsFormFieldsToAddDiscountDTO = (
    childId: ChildId,
    discountFormFields: DiscountFormFields,
): AddDiscountDTO => ({
    childId,
    amount: discountFormFields.amount,
    discountType: discountFormFields.discountType,
    discountTarget: discountFormFields.discountTarget,
    combinable: discountFormFields.combinable,
    startDate: parseDayjsDateIntoString(discountFormFields.startDate, 'YYYY-MM-DD'),
    name: discountFormFields.name,
    courseId: discountFormFields.courseId,
    endDate: parseDayjsDateIntoString(discountFormFields.endDate, 'YYYY-MM-DD'),
});

export const parseEditDiscountsFormFieldsToAddDiscountDTO = (
    discountFormFields: EditDiscountFormFields,
): EditDiscountDTO => ({
    amount: discountFormFields.amount,
    combinable: discountFormFields.combinable,
    startDate: parseDayjsDateIntoString(discountFormFields.startDate, 'YYYY-MM-DD'),
    name: discountFormFields.name,
    courseId: discountFormFields.courseId,
    endDate: parseDayjsDateIntoString(discountFormFields.endDate, 'YYYY-MM-DD'),
});
