import {
    StaffAppModules,
    PermissionType,
    EditablePermissionsOptionDetails,
    EditablePermissionsNames,
    ModuleWithPermissions,
} from 'types';
import { ModuleListItem } from '../ModuleListItem/ModuleListItem';
import { PermissionListItem } from '../PermissionListItem/PermissionListItem';
import {
    INVALID_PERMISSION_CLASS,
    PERMISSION_CLASS,
    SUBMODULE_CLASS,
    SUBPERMISSION_CLASS,
} from '../../classes';

export type ListEntryRendererProps = {
    entry: [string, PermissionType | EditablePermissionsOptionDetails];
    moduleName: StaffAppModules;
    parentModule?: StaffAppModules;
    missingPermissionsPerModule: ModuleWithPermissions[] | null;
};

export const ListEntryRenderer = ({
    entry,
    moduleName,
    parentModule,
    missingPermissionsPerModule,
}: ListEntryRendererProps) => {
    const [permissionOrSubmoduleName, permissionOptionOrSubmodule] = entry;
    const isSubmodule = !!parentModule;
    const currentEntryHasSubmodule = typeof permissionOptionOrSubmodule === 'object';
    const isValidClass = () => {
        if (missingPermissionsPerModule === null) return true;

        return !missingPermissionsPerModule.some((missingPermissionPerModule) =>
            missingPermissionPerModule.permissions.some(
                (missingPermission) => missingPermission === permissionOrSubmoduleName,
            ),
        );
    };
    const permissionListItemClassName = `${isSubmodule ? SUBPERMISSION_CLASS : PERMISSION_CLASS} ${
        isValidClass() ? '' : INVALID_PERMISSION_CLASS
    }`;

    if (currentEntryHasSubmodule) {
        const subPermissions = permissionOptionOrSubmodule
            ? Object.entries(permissionOptionOrSubmodule).map((permission) => (
                  <ListEntryRenderer
                      moduleName={permissionOrSubmoduleName as StaffAppModules}
                      key={`${permissionOrSubmoduleName}-${permission}`}
                      entry={permission}
                      missingPermissionsPerModule={missingPermissionsPerModule}
                  />
              ))
            : null;

        return (
            <>
                <ModuleListItem
                    className={SUBMODULE_CLASS}
                    moduleName={permissionOrSubmoduleName as StaffAppModules}
                    parentModule={moduleName}
                />
                {subPermissions}
            </>
        );
    }
    return (
        <PermissionListItem
            moduleName={moduleName}
            className={permissionListItemClassName}
            permissionName={permissionOrSubmoduleName as EditablePermissionsNames}
            option={permissionOptionOrSubmodule as PermissionType}
            dataTestId={permissionOrSubmoduleName}
        />
    );
};
