import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type GroupJournalTripsPageNetworkManageProps = {
    journalId: JournalId;
    onTripRemoveRequestFinish: () => void;
};

export const useGroupJournalTripsPageNetworkManage = ({
    journalId,
    onTripRemoveRequestFinish,
}: GroupJournalTripsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: tripsData,
        isInitialLoading: isTripsDataInitialLoading,
        isRefetching: isTripsDataRefetching,
        isError: isTripsDataError,
    } = useAppQuery('TRIPS', [journalId], () => StaffHTTPService.trips.getTrips(journalId), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { mutate: removeTrip, isLoading: isRemoveTripLoading } = useAppMutation(
        StaffHTTPService.trips.removeTrip,
        {
            key: ['REMOVE_TRIP'],
            onSuccess: async (_, variables) => {
                onTripRemoveRequestFinish();
                await queryClient.invalidateQueries([
                    institutionId,
                    'TRIP',
                    variables.journalId,
                    variables.tripId,
                ]);
                displaySuccess('Wycieczka została usunięta');
            },
            onError: () => {
                onTripRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['TRIPS', journalId]],
        },
    );

    return {
        tripsData,
        isTripsDataInitialLoading,
        isTripsDataRefetching,
        isTripsDataError,
        isRemoveTripLoading,
        removeTrip,
    };
};
