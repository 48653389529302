import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ImportantEventAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ImportantEventFormFields, ImportantEventId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { formFields } from 'consts/form/formFields';
import { ErrorDisplay } from 'components/molecules';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { parseImportantEventDTOIntoImportantEventFormFields } from 'utils/parsers/importantEvent/parseImportantEventDTOIntoImportantEventFormFields';
import { parseImportantEventFormFieldsIntoAddUpdateImportantEventDTO } from 'utils/parsers/importantEvent/parseImportantEventFormFieldsIntoAddUpdateImportantEventDTO';
import { journalTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalImportantEventEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<ImportantEventFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { importantEventId: importantEventIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const importantEventId = importantEventIdParam as ImportantEventId;

    const {
        data: importantEventData,
        isFetching: isImportantEventDataFetching,
        isError: isFetchImportantEventDataError,
    } = useAppQuery(
        'IMPORTANT_EVENT',
        [journalId, importantEventId],
        () => StaffHTTPService.importantEvents.getImportantEvent(journalId, importantEventId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (importantEventData && !form.getFieldValue(formFields.name)) {
            setFormData(parseImportantEventDTOIntoImportantEventFormFields(importantEventData));
        }
    }, [importantEventData, form, setFormData]);

    const onEditImportantEventSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.events.importantEvents
                .successfullyUpdatedImportantEvent,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: updateImportantEvent,
        error: editImportantEventError,
        isLoading: isEditImportantEventLoading,
    } = useAppMutation(StaffHTTPService.importantEvents.updateImportantEvent, {
        key: ['UPDATE_IMPORTANT_EVENT'],
        onSuccess: onEditImportantEventSuccess,
        invalidateQueryKeys: [['IMPORTANT_EVENTS', journalId, importantEventId]],
    });

    const onEditImportantEventFinish = (importantEventFormData: ImportantEventFormFields) => {
        const parsedUpdateImportantEventDTO =
            parseImportantEventFormFieldsIntoAddUpdateImportantEventDTO(importantEventFormData);
        updateImportantEvent({
            journalId,
            importantEventId,
            updateImportantEventDTO: parsedUpdateImportantEventDTO,
        });
    };

    if (isFetchImportantEventDataError) {
        return <ErrorDisplay />;
    }

    return (
        <ImportantEventAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={editImportantEventError}
            isLoading={isImportantEventDataFetching || isEditImportantEventLoading}
            onFinish={onEditImportantEventFinish}
        />
    );
};
