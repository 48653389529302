import { AddMailThreadDTO, MailThreadFormFields, MailThreadId } from 'types';

export const parseReplyMailThreadFormFieldsIntoAddMailThreadDTO: (
    mailThreadFormFields: MailThreadFormFields,
    sourceThreadId: MailThreadId,
) => AddMailThreadDTO = (mailThreadFormFields, sourceThreadId) => {
    const { topic, content, attachments } = mailThreadFormFields;
    return {
        topic,
        content,
        attachments,
        sourceThreadId,
    };
};
