import { TimePickerProps } from 'antd/lib/time-picker';
import { StyledXIcon } from 'components/atoms/StyledIcons';
import { SHORT_TIME_FORMAT } from 'consts/timeFormats';
import {
    AppTimePickerGlobalStyle,
    StyledClockIcon,
    StyledTimePicker,
} from './AppTimePicker.styled';

export type AppTimePickerProps = TimePickerProps & {
    isFitContentWidth?: boolean;
};

export const AppTimePicker = (props: AppTimePickerProps) => (
    <>
        <AppTimePickerGlobalStyle />
        <StyledTimePicker
            suffixIcon={<StyledClockIcon />}
            allowClear={{ clearIcon: <StyledXIcon /> }}
            format={SHORT_TIME_FORMAT}
            placeholder=""
            needConfirm={false}
            changeOnScroll
            {...props}
        />
    </>
);
