import { GroupChildDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseGroupChildDTOIntoJsonObject = (groupChildDTO: GroupChildDTO) => {
    const { firstName, lastName, joinDate, leaveDate } = groupChildDTO;

    const formattedJoinDate = parseStringIntoFormattedStringDate(joinDate);
    const formattedLeaveDate = leaveDate
        ? parseStringIntoFormattedStringDate(leaveDate)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.groupDTO.innerObjects.groupChildDTO.fields.firstname]: firstName,
        [jsonObjectsTexts.groupDTO.innerObjects.groupChildDTO.fields.lastname]: lastName,
        [jsonObjectsTexts.groupDTO.innerObjects.groupChildDTO.fields.joinDate]: formattedJoinDate,
        [jsonObjectsTexts.groupDTO.innerObjects.groupChildDTO.fields.leaveDate]: formattedLeaveDate,
    };
};
