import { useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { JournalType, SpecialConsultationDTO, SpecialConsultationId } from 'types';
import { ConsultationChildrenDisplay, ConsultationSubjectDisplay } from 'components/molecules';
import { Option } from 'components/atoms';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { commonTexts, journalTexts } from 'consts/text/index';
import { appPaths } from 'consts/paths/paths';
import { specialConsultationDTOFields } from 'consts/consultation/specialConsultation/specialConsultationDTOFields';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useSpecialJournalConsultationsPageNetworkManage } from './hooks/useSpecialJournalConsultationsPageNetworkManage';

const getColumns: (journalType: JournalType) => AppTableProps<SpecialConsultationDTO>['columns'] = (
    journalType,
) => [
    {
        title: journalTexts.journalDetails.common.consultations.consultationSubject,
        dataIndex: specialConsultationDTOFields.subject,
        key: specialConsultationDTOFields.subject,
        render: (_, consultation) => (
            <ConsultationSubjectDisplay
                subject={consultation.subject}
                date={consultation.date}
                journalId={consultation.journalId}
                consultationId={consultation.id}
                journalType={journalType}
            />
        ),
    },
    {
        title: commonTexts.dataLabels.child,
        dataIndex: specialConsultationDTOFields.childrenArray,
        key: specialConsultationDTOFields.childrenArray,
        render: (_, consultation) => (
            <ConsultationChildrenDisplay
                consultationChildren={consultation[specialConsultationDTOFields.childrenArray]}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.common.consultations.contactWith,
        dataIndex: specialConsultationDTOFields.contactWith,
        key: specialConsultationDTOFields.contactWith,
        render: (_, consultation) => consultation.contactWith,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.date,
        dataIndex: specialConsultationDTOFields.date,
        key: specialConsultationDTOFields.date,
        render: (date: SpecialConsultationDTO['date']) => parseStringIntoFormattedStringDate(date),
        align: 'center',
        isInvisibleInMobile: true,
    },
];

export const SpecialJournalConsultationsPage = () => {
    const [selectedToRemoveConsultationId, setSelectedToRemoveConsultationId] =
        useState<SpecialConsultationId | null>(null);
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const {
        specialJournalsAbilities: { checkIfCanManageConsultations },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const canManageConsultations = checkIfCanManageConsultations();

    const onConsultationRemoveRequestFinishOrCancel = () => setSelectedToRemoveConsultationId(null);

    const {
        consultationsData,
        isConsultationsDataRefetching,
        isConsultationsDataError,
        isRemoveConsultationLoading,
        isConsultationsDataInitialLoading,
        removeConsultation,
    } = useSpecialJournalConsultationsPageNetworkManage({
        journalId,
        onConsultationRemoveRequestFinish: onConsultationRemoveRequestFinishOrCancel,
    });

    const navigateToConsultationAddPage = () => {
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.add,
                { journalId },
            ),
        );
    };

    const navigateToConsultationEditPage = ({ id }: SpecialConsultationDTO) => {
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.edit
                    .pickedConsultation,
                {
                    journalId,
                    consultationId: id,
                },
            ),
        );
    };

    const navigateToConsultationDetailsPage = ({ id }: SpecialConsultationDTO) => {
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.details
                    .pickedConsultation,
                {
                    journalId,
                    consultationId: id,
                },
            ),
        );
    };

    const handleRemoveConsultationClick = (consultation: SpecialConsultationDTO) =>
        setSelectedToRemoveConsultationId(consultation.id);

    const handleRemoveConsultationConfirm = () => {
        if (!selectedToRemoveConsultationId) {
            return;
        }
        removeConsultation({
            journalId,
            consultationId: selectedToRemoveConsultationId,
        });
    };

    const columns = useMemo(() => getColumns('SPECIAL'), []);

    const options: Option<SpecialConsultationDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToConsultationDetailsPage,
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToConsultationEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageConsultations,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveConsultationClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManageConsultations,
        },
    ];

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveConsultationId}
                title={journalTexts.journalDetails.common.consultations.confirmDeleteConsultation}
                confirmActionLoading={isRemoveConsultationLoading}
                onOk={handleRemoveConsultationConfirm}
                onCancel={onConsultationRemoveRequestFinishOrCancel}
            />
            <AppTable
                title={journalTexts.journalDetails.common.consultations.consultationList}
                addButtonLabel={journalTexts.journalDetails.common.consultations.addConsultation}
                emptyInfo={journalTexts.journalDetails.common.consultations.emptyConsultationList}
                options={options}
                columns={columns}
                dataSource={consultationsData}
                rowKey={({ id }) => id}
                isTableManageUtilsHidden
                isError={isConsultationsDataError}
                isOptionsLoading={isRemoveConsultationLoading}
                isDataInitialLoading={isConsultationsDataInitialLoading}
                isDataRefetching={isConsultationsDataRefetching}
                isHiddenAddButton={!canManageConsultations}
                onAddButtonClick={navigateToConsultationAddPage}
            />
        </>
    );
};
