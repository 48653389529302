import { ChildId } from '../child';
import { CourseId } from '../lesson';

export type DiscountId = string & { readonly type: unique symbol };

export enum DiscountTarget {
    MEAL = 'MEALS',
    STAY = 'STAY',
    EXTRA_LESSONS = 'EXTRA_LESSONS',
}

export enum DiscountType {
    PERCENTAGE_DISCOUNT = 'PERCENTAGE_DISCOUNT',
    ALLOWANCE = 'ALLOWANCE',
    CO_FINANCING = 'CO_FINANCING',
}

export type DiscountDTO = {
    id: DiscountId;
    childId: ChildId;
    discountTarget: DiscountTarget;
    discountType: DiscountType;
    name: string;
    startDate: string;
    endDate: string;
    amount: number;
    combinable?: boolean;
    extraLessons?: {
        id: CourseId;
        name: string;
    };
};

export type AddDiscountDTO = {
    childId: ChildId;
    discountTarget: string;
    discountType: string;
    name: string;
    amount: number;
    startDate: string;
    endDate: string;
    combinable?: boolean
    courseId?: CourseId
};

export type EditDiscountDTO = Partial<Omit<AddDiscountDTO, 'discountTarget' | 'discountType'>>;
