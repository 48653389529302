import { useCallback, useEffect } from 'react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { MAIL_THREAD_LIST_ITEM_PREFIX } from 'components/atoms';
import { ChildId, InstitutionId, MailThreadId } from 'types';

type UpdateSelectedMailThreadReadStatusProps = {
    mailThreadId: MailThreadId;
    isLastMessageRead?: boolean;
    institutionId: InstitutionId;
    childId?: ChildId;
};

export const useUpdateSelectedMailThreadReadStatus = ({
    mailThreadId,
    isLastMessageRead,
    institutionId,
    childId,
}: UpdateSelectedMailThreadReadStatusProps) => {
    const queryClient = useQueryClient();

    const scrollToUpdateMailThreadListItem = useCallback(() => {
        setTimeout(() => {
            const updatedMailThreadListItemElement = document.getElementById(
                MAIL_THREAD_LIST_ITEM_PREFIX + mailThreadId,
            );
            if (!updatedMailThreadListItemElement) {
                return;
            }
            updatedMailThreadListItemElement.scrollIntoView({ behavior: 'smooth' });
        }, 0);
    }, [mailThreadId]);

    const updateSelectedMailThreadReadStatus = useCallback(() => {
        const mailThreadQueryKey: QueryKey = childId
            ? [institutionId, childId, 'MAIL_THREAD', mailThreadId]
            : [institutionId, 'MAIL_THREAD', mailThreadId];
        const mailThreadsQueryKey: QueryKey = childId
            ? [institutionId, childId, 'MAIL_THREADS']
            : [institutionId, 'MAIL_THREADS'];

        queryClient.invalidateQueries(mailThreadQueryKey);
        queryClient.invalidateQueries(mailThreadsQueryKey).then(scrollToUpdateMailThreadListItem);
    }, [institutionId, childId, mailThreadId, queryClient, scrollToUpdateMailThreadListItem]);

    useEffect(() => {
        if (isLastMessageRead === undefined || isLastMessageRead) {
            return;
        }
        updateSelectedMailThreadReadStatus();
    }, [isLastMessageRead, updateSelectedMailThreadReadStatus]);
};
