import { InstitutionType } from 'types';
import { AppParagraph } from 'components/atoms';
import { getInstitutionTypeLabel } from 'utils/getInstitutionTypeLabel';

type InstitutionTypeDisplayProps = {
    type: InstitutionType;
};

export const InstitutionTypeDisplay = ({ type }: InstitutionTypeDisplayProps) => {
    const institutionTypeLabel = getInstitutionTypeLabel(type);

    return <AppParagraph margin={0}>{institutionTypeLabel}</AppParagraph>;
};
