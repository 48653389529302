import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { JournalId } from 'types';
import { commonTexts, journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type JournalConsultationsPageNetworkManageProps = {
    journalId: JournalId;
    onConsultationRemoveRequestFinish: () => void;
};

export const useSpecialJournalConsultationsPageNetworkManage = ({
    journalId,
    onConsultationRemoveRequestFinish,
}: JournalConsultationsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: consultationsData,
        isInitialLoading: isConsultationsDataInitialLoading,
        isRefetching: isConsultationsDataRefetching,
        isError: isConsultationsDataError,
    } = useAppQuery(
        'SPECIAL_CONSULTATIONS',
        [journalId],
        () => StaffHTTPService.specialConsultations.getSpecialConsultations(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: removeSpecialConsultation, isLoading: isRemoveConsultationLoading } =
        useAppMutation(StaffHTTPService.specialConsultations.removeSpecialConsultation, {
            key: ['REMOVE_SPECIAL_CONSULTATION', journalId],
            onSuccess: async (_, variables) => {
                onConsultationRemoveRequestFinish();
                await queryClient.invalidateQueries([
                    institutionId,
                    'SPECIAL_CONSULTATION',
                    variables.journalId,
                    variables.consultationId,
                ]);
                displaySuccess(
                    journalTexts.journalDetails.common.consultations
                        .successfullyRemovedConsultation,
                );
            },
            onError: () => {
                onConsultationRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['SPECIAL_CONSULTATIONS', journalId]],
        });

    return {
        consultationsData,
        isConsultationsDataRefetching,
        isConsultationsDataInitialLoading,
        isConsultationsDataError,
        isRemoveConsultationLoading,
        removeConsultation: removeSpecialConsultation,
    };
};
