import { MailThreadReceiverDTO, SelectOptionType, UserId } from 'types';
import { getFullAuthorName } from 'utils/getFullAuthorName';

export const parseMailThreadReceiverDTOIntoSelectOptionType: (
    mailThreadReceiverDTO: MailThreadReceiverDTO,
) => SelectOptionType<UserId, string> = (mailThreadReceiverDTO) => {
    const { userId, name, subName } = mailThreadReceiverDTO;
    return {
        value: userId,
        label: getFullAuthorName(name, subName),
    };
};
