import styled, { css } from 'styled-components';
import { WeeklyScheduleDTO, NonNullableObject } from 'types';
import { commonTexts } from 'consts/text/index';
import { WeeklyScheduleCard } from './components';

type WeeklyScheduleCardsProps = {
    weeklySchedule: NonNullableObject<WeeklyScheduleDTO>;
};

const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        border-radius: ${theme.borders.borderRadiusLarge}px;
        border: 1px solid ${theme.colors.primary.primary5};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            flex-direction: column;
        }
    `}
`;

export const WeeklyScheduleCards = ({ weeklySchedule }: WeeklyScheduleCardsProps) => (
    <Container>
        {weeklySchedule.days.map((day) => (
            <WeeklyScheduleCard
                key={day.dayName}
                dayLabel={
                    commonTexts.dayLabels[
                        day.dayName.toLowerCase() as keyof typeof commonTexts.dayLabels
                    ]
                }
                description={day.description}
            />
        ))}
    </Container>
);
