import { SelectOptionType } from 'types';
import { GroupLabelListItem, GroupOptionListItem } from './components';

type OptionGroupSelectListItemProps<GroupId, ValueId> = {
    groupId: GroupId;
    groupLabel: string;
    selectedValues?: ValueId[];
    options: SelectOptionType<ValueId, string>[];
    onChangeOption: (isChecked: boolean, valueId: ValueId, groupId: GroupId) => void;
};

export const OptionGroupSelectListItem = <GroupId, ValueId extends string>({
    groupId,
    groupLabel,
    selectedValues,
    options,
    onChangeOption,
}: OptionGroupSelectListItemProps<GroupId, ValueId>) => (
    <>
        <GroupLabelListItem label={groupLabel} />
        {options.map((option) => (
            <GroupOptionListItem
                key={option.value}
                label={option.label}
                isChecked={
                    !!selectedValues?.some((selectedValue) => selectedValue === option.value)
                }
                onChange={(isChecked) => onChangeOption(isChecked, option.value, groupId)}
            />
        ))}
    </>
);
