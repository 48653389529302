import { AppFormItem, AppFormItemProps, AppText } from 'components/atoms';
import { SimpleTextAreaElement } from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { colors } from 'theme/styledComponents/colors';
import { ChildId, SpecialTopicChildWork } from 'types';
import { getFullName } from 'utils/getFullName';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { journalTexts } from 'consts/text';
import React from 'react';
import { clone } from 'lodash';

type ChildrenWorkProps = Pick<AppFormItemProps, 'name' | 'disabled'> & {
    childrenWork?: SpecialTopicChildWork[];
};

type CurrentChildrenWorkType = {
    id: ChildId;
    description: string;
};

export const ChildrenWork = ({
    childrenWork,
    disabled,
    name = formFields.childrenWork,
}: ChildrenWorkProps) => {
    const { setFieldValue, getFieldValue } = useFormInstance();

    const handleChildWorkChange = (childId: ChildId, childWorkInput: string) => {
        const currentChildrenWork: CurrentChildrenWorkType[] = getFieldValue(name) || [];

        const cloneCurrentChildrenWork = clone(currentChildrenWork);
        const existingChildIndex = cloneCurrentChildrenWork.findIndex(
            (child) => child.id === childId,
        );

        if (existingChildIndex !== -1) {
            cloneCurrentChildrenWork[existingChildIndex] = {
                id: childId,
                description: childWorkInput,
            };
        } else {
            cloneCurrentChildrenWork.push({ id: childId, description: childWorkInput });
        }
        setFieldValue(name, cloneCurrentChildrenWork);
    };

    return (
        childrenWork &&
        childrenWork.map((child) => (
            <React.Fragment key={child.id}>
                <AppFormItem
                    name={name}
                    key={child.id}
                    optional
                    hasOptionalText
                    disabled={disabled}
                />
                <AppText textType="BodyMediumSemiBold" margin={0} marginBottom={16}>
                    {getFullName(child.firstName, child.lastName)}
                    {child.absentType === 'ABSENT' && (
                        <AppText
                            textType="BodyMedium"
                            margin={0}
                            color={colors.red.red6}
                            marginLeft={30}
                        >
                            {journalTexts.journalDetails.common.attendance.absent}
                        </AppText>
                    )}
                    {child.absentType === null && (
                        <AppText
                            textType="BodyMedium"
                            margin={0}
                            color={colors.red.red6}
                            marginLeft={30}
                        >
                            {journalTexts.journalDetails.specialJournal.topic.noAttendanceRecord}
                        </AppText>
                    )}
                </AppText>

                {child.absentType === 'PRESENT' && (
                    <SimpleTextAreaElement
                        label={
                            journalTexts.journalDetails.specialJournal.topic
                                .informationAboutChildrenWork
                        }
                        name={`${name}_${child.id}`}
                        style={{ width: '100%' }}
                        onChange={({ target }) => handleChildWorkChange(child.id, target.value)}
                        initialValue={child.description}
                        optional
                        hasOptionalText
                        disabled={disabled}
                    />
                )}
                <AppFormItem />
            </React.Fragment>
        ))
    );
};
