import dayjs from 'dayjs';
import { AppDatePicker, AppFormItem, AppFormItemProps } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { childrenTexts } from 'consts/text';

const birthDateSameOrAfterCurrentDateError =
    'Data urodzenia musi być mniejsza od dnia dzisiejszego';

export const BirthDateDatePickerElement = (props: AppFormItemProps) => (
    <AppFormItem
        label={childrenTexts.childForm.personalData.birthDate}
        name={formFields.birthDate}
        rules={[
            { required: true, message: formElementRequiredText },
            {
                validator: (_, value) =>
                    value && !dayjs().isSameOrBefore(value, 'day')
                        ? Promise.resolve()
                        : Promise.reject(new Error(birthDateSameOrAfterCurrentDateError)),
            },
        ]}
        {...props}
    >
        <AppDatePicker autoComplete="bday" />
    </AppFormItem>
);
