import { RefObject } from 'react';
import { FormInstance } from 'antd';
import {
    MessageListItem,
    MessageListItemProps,
    MessagePanelTopBar,
    MessagePanelTopBarProps,
} from 'components/molecules';
import {
    MailThreadDTO,
    MailThreadId,
    MessageDTO,
    MessageFormFields,
    MessageWithoutReceiversDTO,
} from 'types';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { MessageListContainer, StyledAppInfiniteScroll } from './MailThreadOverview.styled';
import { SendMessageFormContent, SendMessageFormContentProps } from './components';

type MailThreadOverviewProps = Pick<MessagePanelTopBarProps, 'onOpenRemoveModal'> & {
    mailThread: MailThreadDTO;
    replyMailThreadId?: MailThreadId;
    messages: MessageDTO[] | MessageWithoutReceiversDTO[];
    addMessageFormInstance: FormInstance<MessageFormFields>;
    messageListRef: RefObject<HTMLDivElement>;
    isAddMessageLoading?: boolean;
    hasNextMessagesPage?: boolean;
    onSendMessageFormFinish: (messageData: MessageFormFields) => void;
    fetchNextMessagesPageFunction: () => void;
    uploadMessageAttachmentFunction?: SendMessageFormContentProps['uploadAttachmentFunction'];
    fetchMessageAttachmentFunction?: MessageListItemProps['fetchAttachmentFunction'];
    fetchAllMessageAttachmentsInZipFunction?: MessageListItemProps['fetchAllAttachmentsInZipFunction'];
};

const MESSAGES_SCROLLABLE_TARGET = 'MESSAGES_SCROLLABLE_DIV';

export const MailThreadOverview = ({
    messages,
    mailThread,
    replyMailThreadId,
    addMessageFormInstance,
    messageListRef,
    isAddMessageLoading,
    onSendMessageFormFinish,
    hasNextMessagesPage,
    fetchNextMessagesPageFunction,
    uploadMessageAttachmentFunction,
    fetchMessageAttachmentFunction,
    fetchAllMessageAttachmentsInZipFunction,
    onOpenRemoveModal,
}: MailThreadOverviewProps) => {
    const isMobile = useIsMobile();

    const isGroupMailThread = mailThread.type === 'GROUP';
    const isVisibleBlockingSendMessageFormInfo =
        isGroupMailThread && !mailThread.lastMessage.sentByMe;

    if (isMobile) {
        return (
            <>
                <MessagePanelTopBar mailThread={mailThread} onOpenRemoveModal={onOpenRemoveModal} />
                <MessageListContainer id={MESSAGES_SCROLLABLE_TARGET}>
                    <SendMessageFormContent
                        mailThreadId={mailThread.id}
                        replyMailThreadId={replyMailThreadId}
                        form={addMessageFormInstance}
                        isLoading={isAddMessageLoading}
                        isVisibleBlockingInfo={isVisibleBlockingSendMessageFormInfo}
                        onFinish={onSendMessageFormFinish}
                        uploadAttachmentFunction={uploadMessageAttachmentFunction}
                    />
                    <StyledAppInfiniteScroll
                        dataLength={messages?.length}
                        hasMore={hasNextMessagesPage || false}
                        inverse
                        scrollableTarget={MESSAGES_SCROLLABLE_TARGET}
                        next={fetchNextMessagesPageFunction}
                    >
                        {messages.map(({ id: messageId, ...restProps }) => (
                            <MessageListItem
                                key={messageId}
                                fetchAttachmentFunction={fetchMessageAttachmentFunction}
                                fetchAllAttachmentsInZipFunction={
                                    fetchAllMessageAttachmentsInZipFunction
                                }
                                {...restProps}
                            />
                        ))}
                    </StyledAppInfiniteScroll>
                </MessageListContainer>
            </>
        );
    }

    return (
        <>
            <MessagePanelTopBar mailThread={mailThread} onOpenRemoveModal={onOpenRemoveModal} />
            <MessageListContainer id={MESSAGES_SCROLLABLE_TARGET} ref={messageListRef}>
                <StyledAppInfiniteScroll
                    dataLength={messages?.length}
                    hasMore={hasNextMessagesPage || false}
                    inverse
                    scrollableTarget={MESSAGES_SCROLLABLE_TARGET}
                    next={fetchNextMessagesPageFunction}
                >
                    {messages.map(({ id: messageId, ...restProps }) => (
                        <MessageListItem
                            key={messageId}
                            fetchAttachmentFunction={fetchMessageAttachmentFunction}
                            fetchAllAttachmentsInZipFunction={
                                fetchAllMessageAttachmentsInZipFunction
                            }
                            {...restProps}
                        />
                    ))}
                </StyledAppInfiniteScroll>
            </MessageListContainer>
            <SendMessageFormContent
                mailThreadId={mailThread.id}
                replyMailThreadId={replyMailThreadId}
                form={addMessageFormInstance}
                isLoading={isAddMessageLoading}
                isVisibleBlockingInfo={isVisibleBlockingSendMessageFormInfo}
                onFinish={onSendMessageFormFinish}
                uploadAttachmentFunction={uploadMessageAttachmentFunction}
            />
        </>
    );
};
