import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { parseInstitutionBaseDataIntoSelectOptionType } from 'utils/parsers/institution/parseInstitutionBaseDataIntoSelectOptionType';
import { SchoolIcon } from 'assets';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { currentInstitutionAtom } from 'jotaiAtoms/currentInstitution';
import { writeLocalStorageInstitutionId } from 'services/localStorage/institutionId';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { AppSelect } from 'components/atoms/CommonAppComponents';
import { appPaths } from 'consts/paths/paths';
import { DropDownRender } from './components/dropdownRender';

export const InstitutionDropdown = () => {
    const userDetails = useAtomValue(userDetailsAtom);
    const setIsLoggedUserDrawerMenuOpen = useSetAtom(isLoggedUserDrawerMenuOpenAtom);
    const userInstitutions = userDetails?.institutions ? userDetails.institutions : [];
    const institutionsToPick = userInstitutions.map(parseInstitutionBaseDataIntoSelectOptionType);
    const [currentInstitution, setCurrentInstitution] = useAtom(currentInstitutionAtom);
    const navigate = useNavigate();

    const onChange = (pickedInstitutionId: string) => {
        const pickedInstitution = userInstitutions.find(({ id }) => id === pickedInstitutionId);
        setCurrentInstitution(pickedInstitution || userInstitutions[0]);
        writeLocalStorageInstitutionId(pickedInstitution?.id || userInstitutions[0].id);
        setIsLoggedUserDrawerMenuOpen(false);
        navigate(appPaths.app.base);
    };

    const dropdownRender = (menu: React.ReactElement) => <DropDownRender menu={menu} />;

    return (
        <AppSelect
            Icon={SchoolIcon}
            value={currentInstitution.id}
            onChange={onChange}
            options={institutionsToPick}
            dropdownRender={dropdownRender}
            allowClear={false}
            variant="borderless"
        />
    );
};
