import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { useContext, useMemo, useState } from 'react';
import { useWatch } from 'antd/es/form/Form';

import {
    ModalTreePickerElement,
    SpecialJournalChildrenPickerElement,
    SpecialJournalChildrenPickerElementProps,
} from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { formFields } from 'consts/form/formFields';
import { UserCheckBottomIcon } from 'assets';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { groupsTexts, journalTexts } from 'consts/text';
import {
    mapGroupsListToChildrenIdsList,
    mapGroupsListToSelectOptions,
    parseGroupDataToJournalChildrenTreeNodes,
    parseGroupDataToTreeNodes,
} from 'utils/treePickerUtils';
import { GroupChildDTO } from 'types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type SpecialJournalChildrenPickerProps = Pick<
    SpecialJournalChildrenPickerElementProps,
    'optional'
> & {
    addedChildren?: GroupChildDTO[];
};

export const SpecialJournalChildrenPicker = ({
    optional,
    addedChildren,
}: SpecialJournalChildrenPickerProps) => {
    const { setFieldValue } = useFormInstance();
    const { formError, setFormError } = useContext(AppFormContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const childrenSelectValue = useWatch<string[] | undefined>(formFields.childIdsToAdd) || [];

    const {
        data: groupsData,
        isFetching: isGroupsDataFetching,
        isError: isGroupsDataError,
    } = useAppQuery('JOURNALS_GROUPS', [], StaffHTTPService.journals.getJournalsGroups, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useDisplayFetchDataError({
        isFetching: isGroupsDataFetching,
        isError: isGroupsDataError,
        actualError: formError,
        errorMessage: groupsTexts.fetchingError,
        setError: setFormError,
    });

    const { mappedGroupsDataForPicker, mappedGroupsDataForTree } = useMemo(() => {
        if (!groupsData) {
            return {
                mappedGroupsDataForPicker: [],
                mappedGroupsDataForTree: [],
            };
        }
        const mappedDataForPicker = mapGroupsListToSelectOptions(groupsData);
        const mappedDataForTree = addedChildren
            ? parseGroupDataToJournalChildrenTreeNodes(groupsData, addedChildren)
            : parseGroupDataToTreeNodes(groupsData);
        return {
            mappedGroupsDataForPicker: mappedDataForPicker,
            mappedGroupsDataForTree: mappedDataForTree,
        };
    }, [groupsData, addedChildren]);

    const onClear = () => setFieldValue(formFields.childIdsToAdd, null);
    const onModalOpen = () => setIsModalOpen(true);
    const onModalClose = () => setIsModalOpen(false);

    const onSave = (values: React.Key[]) => {
        if (!groupsData) {
            return;
        }
        const childrenIdsFromAllGroups: React.Key[] = mapGroupsListToChildrenIdsList(groupsData);
        const filteredSelectedValues = values.filter((value) =>
            childrenIdsFromAllGroups.includes(value),
        );
        setFieldValue(formFields.childIdsToAdd, filteredSelectedValues);
        onModalClose();
    };

    return (
        <>
            <ModalTreePickerElement
                title={journalTexts.journals.addChildren}
                open={isModalOpen}
                onCancel={onModalClose}
                onSave={onSave}
                treeData={mappedGroupsDataForTree}
                initialValue={childrenSelectValue}
                icon={<UserCheckBottomIcon />}
                isLoading={isGroupsDataFetching}
            />
            <SpecialJournalChildrenPickerElement
                onClear={onClear}
                onClick={onModalOpen}
                isLoading={isGroupsDataFetching}
                optional={optional}
                hasOptionalText={optional}
                options={mappedGroupsDataForPicker}
            />
        </>
    );
};
