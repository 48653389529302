import { StaffAppModules } from 'types';
import { STAFF_MODULES_DATA } from 'consts/modules/staffModules';
import { useAtomValue } from 'jotai';
import { ListItem } from '../ListItem/ListItem';
import { ModuleOnOff } from '../ModuleOnOff/ModuleOnOff';
import { modulesEditAtom } from '../../roleAndPermissionState';

export type ModuleListItemProps = {
    moduleName: StaffAppModules;
    className?: string;
    parentModule?: StaffAppModules;
};

export const ModuleListItem = ({ className, moduleName, parentModule }: ModuleListItemProps) => {
    const moduleNameLabel = STAFF_MODULES_DATA[moduleName].name;
    const isSubmodule = !!parentModule;
    const modules = useAtomValue(modulesEditAtom);
    const disabled =
        isSubmodule && !modules.find((moduleToCheck) => moduleToCheck === parentModule);

    return (
        <ListItem
            className={className}
            permissionLabel={moduleNameLabel}
            actionComponent={
                <ModuleOnOff
                    disabled={disabled}
                    labelOption={isSubmodule ? 'access' : 'module'}
                    module={moduleName}
                />
            }
            dataTestId={moduleName}
        />
    );
};
