import { RangePickerProps } from 'antd/es/date-picker';
import { StyledCalendarEventIcon, StyledXIcon } from 'components/atoms/StyledIcons';
import { GlobalStyle, StyledRangePicker } from './AppRangePicker.styled';

export type AppRangePickerProps = RangePickerProps;

export const AppRangePicker = (props: AppRangePickerProps) => (
    <>
        <GlobalStyle />
        <StyledRangePicker
            suffixIcon={<StyledCalendarEventIcon />}
            allowClear={{ clearIcon: <StyledXIcon /> }}
            {...props}
        />
    </>
);
