import { generatePath, useParams } from 'react-router-dom';
import { InspectionId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { Details } from 'components/organisms';
import { getFullName } from 'utils/getFullName';
import { inspectionDTOFields } from 'consts/inspection/inspectionDTOFields';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalAbilities } from 'abilities';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const JournalInspectionDetailsPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { journalId } = useJournalId();
    const { inspectionId: inspectionIdParam } = useParams();
    const {
        groupJournalsAbilities: {
            checkIfCanManageInspections: checkIfCanManageGroupJournalInspections,
        },
        specialJournalsAbilities: {
            checkIfCanManageInspections: checkIfCanManageSpecialJournalInspections,
        },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const inspectionId = (inspectionIdParam || '') as InspectionId;
    const isGroupJournal = journalType === 'GROUP';
    const canManageGroupJournalInspections = checkIfCanManageGroupJournalInspections();
    const canManageSpecialJournalInspections = checkIfCanManageSpecialJournalInspections();
    const canManageInspections = isGroupJournal
        ? canManageGroupJournalInspections
        : canManageSpecialJournalInspections;
    const editPagePath = generatePath(
        isGroupJournal
            ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.edit
                  .pickedInspection
            : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.edit
                  .pickedInspection,
        { inspectionId, journalId },
    );

    const {
        data: inspectionData,
        isFetching: isInspectionDataFetching,
        isError: isFetchInspectionDataError,
    } = useAppQuery(
        'INSPECTION',
        [journalId, inspectionId],
        () => StaffHTTPService.inspections.getInspection(journalId, inspectionId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return (
        <Details
            title="Podgląd hospitacji"
            isLoading={isInspectionDataFetching}
            isError={isFetchInspectionDataError}
            editPagePath={canManageInspections ? editPagePath : undefined}
        >
            <Details.Item title="Temat hospitacji">
                {inspectionData?.[inspectionDTOFields.subject]}
            </Details.Item>
            <Details.Item title="Numer dokumentu">
                {inspectionData?.[inspectionDTOFields.documentNo]}
            </Details.Item>
            <Details.Item title="Data">
                {inspectionData &&
                    parseStringIntoFormattedStringDate(inspectionData[inspectionDTOFields.date])}
            </Details.Item>
            <Details.Item title="Forma nadzoru">
                {inspectionData?.[inspectionDTOFields.supervisionForm]}
            </Details.Item>
            <Details.Item title="Osoba nadzorująca">
                {inspectionData?.[inspectionDTOFields.supervisor]}
            </Details.Item>
            <Details.Item title="Osoba nadzorowana">
                {inspectionData &&
                    getFullName(
                        inspectionData[inspectionDTOFields.teacher].firstName,
                        inspectionData[inspectionDTOFields.teacher].lastName,
                    )}
            </Details.Item>
        </Details>
    );
};
