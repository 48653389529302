import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { GroupTopicAddEditForm } from 'components/templates';
import { GroupTopicFormFields } from 'types';
import { parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO } from 'utils/parsers/groupTopic/parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO';
import { journalTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalDetailsBreadcrumb } from '../../../hooks';

export const GroupJournalTopicAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const onAddTopicSuccess = () => {
        displaySuccess(journalTexts.journalDetails.groupJournal.topics.topicAddSuccessNotification);
        statefulNavigate(
            generatePath(appPaths.app.journals.groupJournalDetails.pickedJournal.topics.base, {
                journalId,
            }),
            {
                replace: true,
            },
        );
    };

    const {
        mutate: addGroupTopic,
        error: addGroupTopicError,
        isLoading: isAddGroupTopicLoading,
    } = useAppMutation(StaffHTTPService.groupTopics.addGroupTopic, {
        key: ['ADD_GROUP_TOPIC'],
        onSuccess: onAddTopicSuccess,
        invalidateQueryKeys: [['GROUP_TOPICS', journalId]],
    });

    const onAddTopicFinish = (topicData: GroupTopicFormFields) => {
        const parsedAddGroupTopicDTO =
            parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO(topicData);
        addGroupTopic({
            journalId,
            addGroupTopicDTO: parsedAddGroupTopicDTO,
        });
    };

    return (
        <GroupTopicAddEditForm
            mode="add"
            axiosError={addGroupTopicError}
            isLoading={isAddGroupTopicLoading}
            onFinish={onAddTopicFinish}
        />
    );
};
