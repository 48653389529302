import { MenuDTO, MenuFormFields } from 'types';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseMenuDTOIntoMenusFormFields: (menuDTO: MenuDTO) => MenuFormFields = ({
    dateFrom,
    dateTo,
    ...restFormFields
}) => ({
    ...restFormFields,
    dateFrom: parseStringIntoDayjsDate(dateFrom),
    dateTo: parseStringIntoDayjsDate(dateTo),
});
