import { ChildPayment } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { getChildPaymentFormTypeText } from 'utils/getChildPaymentFormTypeText';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { getChildPaymentTransactionTypeText } from 'utils/getChildPaymentTransactionTypeText';

export const parseChildPaymentIntoJsonObject: (childPayment: ChildPayment) => object = (
    childPayment,
) => {
    const { date, amount, paymentFormType, transactionType } = childPayment;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const paymentFormTypeLabel = getChildPaymentFormTypeText(paymentFormType);
    const transactionTypeLabel = getChildPaymentTransactionTypeText(transactionType);
    const formattedAmount = getFormattedPrice(amount);

    return {
        [jsonObjectsTexts.childPayment.fields.date]: formattedDate,
        [jsonObjectsTexts.childPayment.fields.amount]: formattedAmount,
        [jsonObjectsTexts.childPayment.fields.paymentFormType]: paymentFormTypeLabel,
        [jsonObjectsTexts.childPayment.fields.transactionType]: transactionTypeLabel,
    };
};
