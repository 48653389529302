import styled, { css } from 'styled-components';
import { IconAlertOctagon, IconAlertTriangle } from '@tabler/icons-react';
import { AppParagraph } from '../../CommonAppComponents';

export const Container = styled.div<{ backgroundColor: string; borderColor?: string }>`
    ${({ theme, backgroundColor, borderColor }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${theme.sizes.marginLarge}px;
        background-color: ${backgroundColor};
        border-radius: 8px;
        padding: 16px;
        ${borderColor &&
        css`
            border: 1px solid ${borderColor};
        `}
    `};
`;

export const StyledAlertOctagon = styled(IconAlertOctagon)`
    height: 16px;
    width: 16px;
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.red.red7};
`;

export const StyledAlertTriangle = styled(IconAlertTriangle)`
    height: 16px;
    width: 16px;
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.yellow.yellow7};
`;

export const BannerText = styled(AppParagraph)<{ color: string }>`
    ${({ color }) => css`
        display: flex;
        flex: 1;
        line-height: 1;
        color: ${color};

        &.ant-typography {
            margin: 0 16px;
        }
    `}
`;
