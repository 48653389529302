import { AdditionalLessonDTO, PaymentConfiguration } from 'types';
import { AppText } from 'components/atoms/CommonAppComponents';
import { settlementTexts } from 'consts/text';
import { noAdditionalLessonConfigurationColor } from 'consts/additionalLessons/noAdditionalLessonConfigurationColor';
import { additionalLessonPaymentMapper } from 'consts/additionalLessons/additionalLessonPaymentMapper';

export const AdditionalLessonsPaymentTypeColumn = ({
    additionalLesson,
}: {
    additionalLesson: AdditionalLessonDTO;
}) =>
    additionalLesson.configured ? (
        <AppText margin={0}>
            {additionalLessonPaymentMapper[additionalLesson.paymentType as PaymentConfiguration]}
        </AppText>
    ) : (
        <AppText margin={0} color={noAdditionalLessonConfigurationColor}>
            {settlementTexts.extraLessons.noConfiguration}
        </AppText>
    );
