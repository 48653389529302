import { useMemo, useState } from 'react';
import { generatePath, Outlet } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { IconCreditCard, IconEdit, IconEye, IconPlus } from '@tabler/icons-react';
import { subscriptionsTexts, commonTexts } from 'consts/text';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { AppTable, AppTableProps } from 'components/organisms';
import { FullInstitutionSubscriptionDTO } from 'types';
import { useSearchFilter } from 'hooks/useSearchFilter/useSearchFilter';
import { Option } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { fullInstitutionSubscriptionDTOFields } from 'consts/subscription/fullInstitutionSubscriptionDTOFields';
import {
    AppInstitutionSubscriptionContext,
    AppInstitutionSubscriptionContextProps,
} from 'contexts/AppInstitutionSubscriptionContext';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useAdminInstitutionsPageNetworkManage } from './hooks/useAdminInstitutionsPageNetworkManage';
import { columns } from './AdminInstitutionsPage.consts';

export const AdminInstitutionsPage = () => {
    const [selectedInstitutionSubscriptionDataItem, setSelectedInstitutionSubscriptionDataItem] =
        useState<FullInstitutionSubscriptionDTO | null>(null);
    const { searchFilterValue, handleSearchInput } = useSearchFilter({});
    const { statefulNavigate } = useNavigateWithState();

    const {
        institutionSubscriptionDataItems,
        isInstitutionSubscriptionDataItemsInitialLoading,
        isInstitutionSubscriptionDataItemsRefetching,
        isBlockSubscriptionLoading,
        isInstitutionSubscriptionDataItemsError,
        blockSubscription,
    } = useAdminInstitutionsPageNetworkManage({ search: searchFilterValue });

    const handleDetailsClick = (fullInstitutionSubscriptionDTO: FullInstitutionSubscriptionDTO) =>
        statefulNavigate(
            generatePath(appPaths.app.admin.institutions.details.pickedInstitution.base, {
                subscriptionId:
                    fullInstitutionSubscriptionDTO[
                        fullInstitutionSubscriptionDTOFields.subscriptionBasicData
                    ].id,
            }),
        );

    const handleAddSubscriptionClick = (
        fullInstitutionSubscriptionDTO: FullInstitutionSubscriptionDTO,
    ) => {
        statefulNavigate(appPaths.app.admin.institutions['add-subscription']);
        setSelectedInstitutionSubscriptionDataItem(fullInstitutionSubscriptionDTO);
    };

    const handleEditSubscriptionClick = (
        fullInstitutionSubscriptionDTO: FullInstitutionSubscriptionDTO,
    ) => {
        statefulNavigate(appPaths.app.admin.institutions['edit-subscription']);
        setSelectedInstitutionSubscriptionDataItem(fullInstitutionSubscriptionDTO);
    };

    const handleBlockSubscriptionClick = (
        fullInstitutionSubscriptionDTO: FullInstitutionSubscriptionDTO,
    ) => {
        blockSubscription(
            fullInstitutionSubscriptionDTO[fullInstitutionSubscriptionDTOFields.institution].id,
        );
    };

    const institutionSubscriptionDataContext: AppInstitutionSubscriptionContextProps =
        useMemo(() => {
            if (!selectedInstitutionSubscriptionDataItem) {
                return { institutionSubscriptionDataItem: null };
            }
            return { institutionSubscriptionDataItem: selectedInstitutionSubscriptionDataItem };
        }, [selectedInstitutionSubscriptionDataItem]);

    const options: Option<FullInstitutionSubscriptionDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: handleDetailsClick,
            Icon: <IconEye />,
        },
        {
            label: subscriptionsTexts.list.options.addSubscription,
            onClick: handleAddSubscriptionClick,
            checkIfVisible: ({ subscriptionBasicData }) => subscriptionBasicData.type !== 'ACTIVE',
            Icon: <IconPlus />,
        },
        {
            label: subscriptionsTexts.list.options.editSubscription,
            onClick: handleEditSubscriptionClick,
            checkIfVisible: ({ subscriptionBasicData }) => subscriptionBasicData.type === 'ACTIVE',
            Icon: <IconEdit />,
        },
        {
            label: subscriptionsTexts.list.options.block,
            checkIfVisible: ({ subscriptionBasicData }) => subscriptionBasicData.type === 'ACTIVE',
            onClick: handleBlockSubscriptionClick,
            Icon: <IconCreditCard />,
        },
    ];

    const filters: AppTableProps<FullInstitutionSubscriptionDTO>['tableManageUtilsProps'] = {
        searchInputProps: {
            defaultValue: searchFilterValue,
            onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
        },
    };

    return (
        <AppInstitutionSubscriptionContext.Provider value={institutionSubscriptionDataContext}>
            <Outlet />
            <AppTable
                title={subscriptionsTexts.list.title}
                emptyInfo={subscriptionsTexts.list.emptyInfo}
                options={options}
                columns={columns}
                rowKey={({ subscriptionBasicData }) => subscriptionBasicData.id}
                dataSource={institutionSubscriptionDataItems}
                isDataInitialLoading={isInstitutionSubscriptionDataItemsInitialLoading}
                isDataRefetching={isInstitutionSubscriptionDataItemsRefetching}
                isOptionsLoading={isBlockSubscriptionLoading}
                isError={isInstitutionSubscriptionDataItemsError}
                tableManageUtilsProps={filters}
            />
        </AppInstitutionSubscriptionContext.Provider>
    );
};
