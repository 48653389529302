import { useEffect, useState } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/es/form/Form';
import { useGuardianOrParent } from 'hooks/useGuardianOrParent/useGuardianOrParent';
import { addId } from 'utils/addId';
import { WithId } from 'types/common/WithId';

type GuardianAndParentChildFormSectionProps = {
    formValuePath: string;
};

export const useGuardianAndParentChildFormSection = <FormFields>({
    formValuePath,
}: GuardianAndParentChildFormSectionProps) => {
    const { setFieldValue } = useFormInstance();
    const [tableDataItems, setTableDataItems] = useState<WithId<FormFields>[]>([]);
    const [selectedTableDataItemToEdit, setSelectedTableDataItemToEdit] =
        useState<WithId<FormFields> | null>(null);
    const formData = useWatch<FormFields[] | undefined>(formValuePath);

    useEffect(() => {
        if (formData?.length && !tableDataItems.length) {
            const formDataWithIds = formData.map(addId);
            setTableDataItems(formDataWithIds);
        }
    }, [formData, tableDataItems]);

    const setFormValues = (guardiansData: WithId<FormFields>[]) => {
        setFieldValue(formValuePath, guardiansData);
    };

    const onItemAdd = (guardianOrParent: WithId<FormFields>) =>
        setTableDataItems((prevTableDataItems) => {
            const newTableDataItems = [...prevTableDataItems, guardianOrParent];
            setFormValues(newTableDataItems);
            return newTableDataItems;
        });

    const onItemEditFinish = (guardianOrParent: WithId<FormFields>) =>
        setTableDataItems((prevTableDataItems) => {
            const newTableDataItems = prevTableDataItems.map((prevTableDataItem) => {
                if (prevTableDataItem.id === selectedTableDataItemToEdit?.id) {
                    return guardianOrParent;
                }
                return prevTableDataItem;
            });
            setFormValues(newTableDataItems);
            return newTableDataItems;
        });

    const onItemRemove = (selectedTableDataItem: WithId<FormFields>) =>
        setTableDataItems((prevTableDataItems) => {
            const newTableDataItems = prevTableDataItems.filter(
                (prevTableDataItem) => prevTableDataItem.id !== selectedTableDataItem.id,
            );
            setFormValues(newTableDataItems);
            return newTableDataItems;
        });

    const onItemToEditPick = (selectedTableDataItem: WithId<FormFields>) => {
        const tableDataItemToEdit = tableDataItems.find(
            (tableDataItem) => tableDataItem.id === selectedTableDataItem.id,
        );
        setSelectedTableDataItemToEdit(tableDataItemToEdit || null);
    };

    const guardianOrParentOptions = useGuardianOrParent({
        onItemAdd,
        onItemEditFinish,
        onItemRemove,
        onItemToEditPick,
        clearSelectedItem: () => setSelectedTableDataItemToEdit(null),
    });

    return {
        ...guardianOrParentOptions,
        tableDataItems,
        selectedTableDataItemToEdit,
    };
};
