import { GroupConsultationFormFields, GroupConsultationDTO } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { groupConsultationDTOFields } from 'consts/consultation/groupConsultation/groupConsultationDTOFields';
import { parseChildWithParentsIntoChildWithParentsIds } from 'utils/parsers/common/parseChildWithParentsIntoChildWithParentsIds';

export const parseGroupConsultationDTOIntoGroupConsultationFormFields: (
    groupConsultationDTO: GroupConsultationDTO,
) => GroupConsultationFormFields = (groupConsultationDTO) => {
    const childrenWithParentsIds = groupConsultationDTO[
        groupConsultationDTOFields.childrenWithParents
    ].map(parseChildWithParentsIntoChildWithParentsIds);
    return {
        subject: groupConsultationDTO[groupConsultationDTOFields.subject],
        description: groupConsultationDTO[groupConsultationDTOFields.description],
        date: parseStringIntoDayjsDate(groupConsultationDTO[groupConsultationDTOFields.date]),
        childrenWithParentsIds,
    };
};
