import { FormTypes } from 'types';

type FormModeDependedData = {
    name: Extract<FormTypes, 'ownerAccountEdit' | 'employeeAccountEdit'>;
    isPhoneNumberOptional: boolean;
};

export const ownerAccountModeData: FormModeDependedData = {
    name: 'ownerAccountEdit',
    isPhoneNumberOptional: false,
};

export const employeeAccountModeData: FormModeDependedData = {
    name: 'employeeAccountEdit',
    isPhoneNumberOptional: true,
};
