import { DATE_LABEL, TIME_LABEL } from 'consts/labels/common';

type DateTimePickerData = {
    unitToCompare: 'day' | 'minute';
    dataFormatLabel: string;
};

export type FormItemData = {
    name: string;
    label: string;
};

export const timePickerData: DateTimePickerData = {
    unitToCompare: 'minute',
    dataFormatLabel: TIME_LABEL,
};

export const datePickerData: DateTimePickerData = {
    unitToCompare: 'day',
    dataFormatLabel: DATE_LABEL,
};
