import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { TopAppMenu } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { newsMenuItems } from './menuItems';

export const NewsLayout = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const outlet = useOutlet();

    useEffect(() => {
        if (outlet) {
            return;
        }
        navigate(appPaths.app.parent.news.attendance.base);
    }, [outlet, navigate]);

    return (
        <>
            <TopAppMenu items={newsMenuItems} selectedKeys={[pathname]} />
            <Outlet />
        </>
    );
};
