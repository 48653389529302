import { ChildGuardian } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { formatIdDocumentNumber } from 'utils/parsers/guardian/parseGuardianIdNumber';

export const parseChildGuardianIntoJsonObject: (childGuardian: ChildGuardian) => object = (
    childGuardian,
) => {
    const { firstName, lastName, phoneNumber, idNumber } = childGuardian;

    const parsedPhoneNumber = phoneNumber
        ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber)
        : EMPTY_VALUE_LABEL;
    const parsedIdDocumentNumber = formatIdDocumentNumber(idNumber);

    return {
        [jsonObjectsTexts.childGuardian.fields.firstName]: firstName,
        [jsonObjectsTexts.childGuardian.fields.lastName]: lastName,
        [jsonObjectsTexts.childGuardian.fields.phoneNumber]: parsedPhoneNumber,
        [jsonObjectsTexts.childGuardian.fields.idNumber]: parsedIdDocumentNumber,
    };
};
