import { EmployeeId, LessonDTO, LessonFormFields } from 'types';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';

export const parseLessonDTOIntoLessonFormFields = (lessonDTO: LessonDTO): LessonFormFields => ({
    journalId: lessonDTO.journal.id,
    teacherId: lessonDTO.teacher?.id || ('' as EmployeeId),
    name: lessonDTO.name,
    timeFrom: parseStringIntoDayjsTime(lessonDTO.timeFrom),
    timeTo: parseStringIntoDayjsTime(lessonDTO.timeTo),
    childIds: lessonDTO.children?.map((child) => child.id),
    startDate: parseStringIntoDayjsDate(lessonDTO.startDate),
    finishDate: parseStringIntoDayjsDate(lessonDTO.endDate),
    repetition: lessonDTO.repetition,
    journalName: lessonDTO.journal.name,
});
