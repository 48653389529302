import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { useTheme } from 'styled-components';

type AppReactJsonProps = Omit<ReactJsonViewProps, 'displayDataTypes'>;

export const AppReactJson = (props: AppReactJsonProps) => {
    const { colors } = useTheme();

    const theme = {
        base00: 'transparent',
        base01: 'transparent',
        base02: 'transparent',
        base03: 'transparent',
        base04: 'transparent',
        base05: 'transparent',
        base06: 'transparent',
        base07: colors.grayscale.gray10,
        base08: 'transparent',
        base09: colors.grayscale.gray10,
        base0A: 'transparent',
        base0B: 'transparent',
        base0C: colors.grayscale.gray10,
        base0D: colors.grayscale.gray10,
        base0E: colors.grayscale.gray10,
        base0F: 'transparent',
    };

    return <ReactJson displayDataTypes={false} theme={theme} {...props} />;
};
