import { GetJournalsFilterKey, JournalDTO, JournalsFilters } from 'types';
import { AppTableProps } from 'components/organisms';
import { commonTexts, journalTexts } from 'consts/text';
import { journalFields } from 'consts/journal/journalFields';
import {
    JournalNameDisplay,
    JournalSchoolYearsDisplay,
    TableTagDisplay,
} from 'components/molecules';
import { journalTypesLabels } from 'consts/journal/journalTypes';
import { tagColorsByJournalType } from 'consts/journal/tagColorsByJournalType';
import { journalStatusesLabels } from 'consts/journal/journalStatuses';
import { tagColorsByJournalStatus } from 'consts/journal/tagColorsByJournalStatus';
import { PageFilter } from 'hooks/usePageFilters/usePageFilters';

export const getColumns: (
    filtersValues: JournalsFilters,
) => AppTableProps<JournalDTO>['columns'] = (filtersValues) => [
    {
        title: commonTexts.dataLabels.name,
        dataIndex: journalFields.name,
        key: journalFields.name,
        render: (_, journal: JournalDTO) => (
            <JournalNameDisplay id={journal.id} name={journal.name} type={journal.type} />
        ),
        width: '20%',
        mobileWidth: '30%',
    },
    {
        title: journalTexts.journals.journalType,
        dataIndex: journalFields.type,
        key: journalFields.type,
        render: (type: JournalDTO['type']) => (
            <TableTagDisplay
                value={type}
                labelMapper={journalTypesLabels}
                colorMapper={tagColorsByJournalType}
            />
        ),
        align: 'center',
        width: '15%',
        mobileWidth: '50%',
    },
    {
        title: commonTexts.dataLabels.status,
        dataIndex: journalFields.status,
        key: journalFields.status,
        render: (status: JournalDTO['status']) => (
            <TableTagDisplay
                value={status}
                labelMapper={journalStatusesLabels}
                colorMapper={tagColorsByJournalStatus}
            />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
        width: '10%',
    },
    {
        title: commonTexts.dataLabels.schoolYear,
        dataIndex: journalFields.schoolYears,
        key: journalFields.schoolYears,
        render: (schoolYears: JournalDTO['schoolYears']) => (
            <JournalSchoolYearsDisplay
                schoolYears={schoolYears}
                fromYearFilter={filtersValues.fromYear}
                toYearFilter={filtersValues.toYear}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
];

export const filters: PageFilter<GetJournalsFilterKey>[] = [
    { name: 'type', type: 'SINGLE_VALUE' },
    { name: 'status', type: 'SINGLE_VALUE' },
    { name: 'fromYear', type: 'SINGLE_VALUE' },
    { name: 'toYear', type: 'SINGLE_VALUE' },
];
