import { useTheme } from 'styled-components';
import { ChildInstitutionDTO } from 'types';
import { AppFlex, AppText } from 'components/atoms/CommonAppComponents';
import { parentTexts } from 'consts/text';
import { Container, InfoText, StyledSchoolIcon } from './NavigationChildInstitutionInfo.styled';

type NavigationChildInstitutionInfoProps = {
    institutionData: Omit<ChildInstitutionDTO, 'institutionId'>;
};

export const NavigationChildInstitutionInfo = ({
    institutionData,
}: NavigationChildInstitutionInfoProps) => {
    const theme = useTheme();

    const { name, street, zipCode, city } = institutionData;

    return (
        <Container>
            <AppFlex gap={theme.sizes.marginSmall}>
                <StyledSchoolIcon />
                <AppText margin={0} textType="BodySmallBold">
                    {parentTexts.institutionAddress}
                </AppText>
            </AppFlex>
            <InfoText marginTop={theme.sizes.marginSmall}>{name}</InfoText>
            <InfoText>{street}</InfoText>
            <InfoText>{`${zipCode} ${city}`}</InfoText>
        </Container>
    );
};
