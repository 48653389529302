import { PaymentConfiguration } from 'types';
import { settlementTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const additionalLessonPaymentMapper = {
    [PaymentConfiguration.EACH_LESSON]:
        settlementTexts.extraLessons.paymentOptionsLabels.everyLesson,
    [PaymentConfiguration.MONTHLY]: settlementTexts.extraLessons.paymentOptionsLabels.monthly,
    null: EMPTY_VALUE_LABEL,
};

export const additionalLessonPaymentSelectMapper = {
    [PaymentConfiguration.EACH_LESSON]:
        settlementTexts.extraLessons.paymentOptionsLabels.everyLesson,
    [PaymentConfiguration.MONTHLY]: settlementTexts.extraLessons.paymentOptionsLabels.monthlyShort,
};
