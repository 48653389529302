import React, { useState } from 'react';
import { AppForm, AppFormProps } from 'components/organisms';
import { useAuth } from 'services/firebase/useAuth';
import { ChangePasswordData } from 'types/forms/auth';
import {
    ErrorDisplay,
    FormNavButtons,
    LoadingDisplay,
    PasswordInputElement,
} from 'components/molecules';
import { useLinkClickHandler, useSearchParams } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { getFirebaseErrorLabel } from 'utils/handleFirebaseError/getFirebaseErrorLabel';
import { useStepsForm } from 'hooks/useStepsForm/useStepsForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { authTexts } from 'consts/text/index';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import AuthHTTPService from 'services/HTTPService/auth/AuthHTTPService';
import { activateUserErrorParser } from 'utils/errorHandlers/auth/activateUserErrorParser';

type PasswordChangeFormTexts = Pick<
    AppFormProps<ChangePasswordData>,
    'title' | 'overLinkText' | 'linkButton' | 'subTitle'
> & {
    confirmButtonText: string;
};

const defaultTexts: PasswordChangeFormTexts = {
    title: 'Wpisz nowe hasło',
    overLinkText: 'Pamiętasz swoje hasło?',
    linkButton: authTexts.loginButtonLabel,
    confirmButtonText: authTexts.changePasswordLabel,
};

const initialChangePasswordTexts: PasswordChangeFormTexts = {
    title: 'Witaj w Elf-Edu!',
    subTitle: 'Wpisz hasło, którym będziesz się logować do aplikacji',
    confirmButtonText: authTexts.setPasswordLabel,
};

export const PasswordChangeForm = () => {
    const { finishForm } = useStepsForm();
    const [searchParams] = useSearchParams({});

    const oobCode = searchParams.get('oobCode');
    const link = searchParams.get('link');
    const email = searchParams.get('email') || '';

    const [firebaseError, setFirebaseError] = useState('');
    const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
    const { passwordChange } = useAuth();
    const navigateToSignIn = useLinkClickHandler(appPaths.auth['sign-in']);

    const { data, isFetching, isError } = useAppQuery(
        'USER_ACTIVE',
        [email],
        () => AuthHTTPService.authentication.getUserAccountActiveInfo(email),
        { refetchOnMount: 'always', enabled: !!email },
    );

    const isFirstTimeLoginMode = data?.active || !!link;

    const handleOnSuccessAction = () =>
        finishForm({ isFirstTimeLoginMode: String(isFirstTimeLoginMode) });

    const { mutate: setUserActive, isLoading: isSetUserActiveLoading } = useAppMutation(
        AuthHTTPService.authentication.activateUser,
        {
            key: ['ACTIVATE_USER'],
            onSuccess: handleOnSuccessAction,
            onError: (error) => setFirebaseError(activateUserErrorParser(error)),
        },
    );

    const onFinish = (values: ChangePasswordData) => {
        setFirebaseError('');
        if (!link && !oobCode) {
            setFirebaseError(
                'Brak tokenu uwierzytelniającego akcję, spróbuj ponownie otworzyć link, który znajduje się w mailu',
            );
        } else if (link) {
            setUserActive({ password: values.password, token: link });
        } else {
            setIsChangePasswordLoading(true);
            passwordChange(oobCode as string, values)
                .then(() => handleOnSuccessAction())
                .catch((error) => setFirebaseError(getFirebaseErrorLabel(error)))
                .finally(() => setIsChangePasswordLoading(false));
        }
    };

    if (isFetching) {
        return <LoadingDisplay />;
    }

    if (isError) {
        return <ErrorDisplay />;
    }

    const { title, overLinkText, linkButton, subTitle, confirmButtonText } = isFirstTimeLoginMode
        ? initialChangePasswordTexts
        : defaultTexts;

    return (
        <AppForm<ChangePasswordData>
            name="passwordChange"
            maxWidth={440}
            title={title}
            subTitle={subTitle}
            onFinish={onFinish}
            overLinkText={overLinkText}
            linkButton={linkButton}
            onLinkButtonClick={(e) =>
                navigateToSignIn(e as React.MouseEvent<HTMLAnchorElement, MouseEvent>)
            }
            error={firebaseError}
            isLoading={isSetUserActiveLoading || isChangePasswordLoading}
        >
            <PasswordInputElement withRulesVisible newPassword />
            <PasswordInputElement passwordRepeat newPassword />
            <FormNavButtons buttonText={confirmButtonText} />
        </AppForm>
    );
};
