import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { TopAppMenu, TopAppMenuProps } from 'components/atoms';
import { timetableMenuItems } from './timetableMenuItems';

export const TimetableLayout = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const outlet = useOutlet();

    useEffect(() => {
        if (outlet) {
            return;
        }
        navigate(appPaths.app.timetable.employeeSchedule.base);
    }, [outlet, navigate]);

    const onMenuItemClick: TopAppMenuProps['onClick'] = ({ key }) => navigate(key);

    return (
        <div>
            <TopAppMenu
                items={timetableMenuItems}
                selectedKeys={[pathname]}
                onClick={onMenuItemClick}
            />
            <Outlet />
        </div>
    );
};
