import { AddUpdateSpecialTopicDTO, SpecialTopicFormFields } from 'types';

export const parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO: (
    specialTopicFormFields: SpecialTopicFormFields,
) => AddUpdateSpecialTopicDTO = (specialTopicFormFields) => {
    const mappedChildrenWork = specialTopicFormFields.childrenWork?.map((childWork) => ({
        childId: childWork.id,
        description: childWork.description,
    }));
    const mappedNotInLessonChildrenWork = specialTopicFormFields.notInLessonChildrenWork?.length
        ? specialTopicFormFields.notInLessonChildrenWork.map((notInLessonChildWork) => ({
              childId: notInLessonChildWork.id,
              description: notInLessonChildWork.description,
          }))
        : specialTopicFormFields.notInLessonChildren?.map((notInLessonChildId) => ({
              childId: notInLessonChildId,
          }));

    return {
        name: specialTopicFormFields.name,
        lessonId: specialTopicFormFields.lessonId,
        description: specialTopicFormFields.description,
        childrenWork: mappedChildrenWork,
        notInLessonChildrenWork: mappedNotInLessonChildrenWork,
    };
};
