import { ReactNode } from 'react';
import { AppText } from 'components/atoms/CommonAppComponents';
import { Container, Header } from './WeeklyScheduleCardBase.styled';

export type WeeklyScheduleCardBaseProps = {
    dayLabel: string;
    date?: string;
    children: ReactNode;
};

export const WeeklyScheduleCardBase = ({
    dayLabel,
    date,
    children,
}: WeeklyScheduleCardBaseProps) => {
    const dateInfo = date ? (
        <AppText margin={0} textType="Caption">
            {date}
        </AppText>
    ) : null;

    return (
        <Container>
            <Header>
                <AppText margin={0} textType="BodyMediumSemiBold">
                    {dayLabel}
                </AppText>
                {dateInfo}
            </Header>
            {children}
        </Container>
    );
};
