import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { useAppAbility } from '../useAppAbility';

export const useEmployeeAbilities = () => {
    const ability = useAppAbility();

    const employeeAbilities = {
        checkIfCanViewEmployeeList: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.EMPLOYEES_LIST),
        checkIfCanManageEmployeeList: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.EMPLOYEES_LIST),
    };

    return { employeeAbilities };
};
