import styled, { css } from 'styled-components';
import { FolderPlusIcon } from 'assets';

export const StyledBlueFolderIcon = styled(FolderPlusIcon)`
    ${({ theme }) => css`
        & {
            --color-folder: ${theme.colors.primary.primary8};
        }
    `}
`;

export const StyledRedFolderIcon = styled(FolderPlusIcon)`
    ${({ theme }) => css`
        & {
            --color-folder: ${theme.colors.red.red6};
        }
    `}
`;
