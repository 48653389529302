import { subscriptionsTexts } from 'consts/text';
import { AppParagraph } from 'components/atoms';

type InstitutionAffiliationDisplayProps = {
    isPublicInstitution: boolean;
};

export const InstitutionAffiliationDisplay = ({
    isPublicInstitution,
}: InstitutionAffiliationDisplayProps) => {
    const institutionAffiliationText = isPublicInstitution
        ? subscriptionsTexts.details.institutionData.publicInstitution
        : subscriptionsTexts.details.institutionData.privateInstitution;

    return <AppParagraph margin={0}>{institutionAffiliationText}</AppParagraph>;
};
