import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type SpecialJournalQualificationsPageNetworkManageProps = {
    journalId: JournalId;
};

export const useSpecialJournalQualificationsPageNetworkManage = ({
    journalId,
}: SpecialJournalQualificationsPageNetworkManageProps) => {
    const {
        data: qualificationsData,
        isInitialLoading: isQualificationsDataInitialLoading,
        isRefetching: isQualificationsDataRefetching,
        isError: isQualificationsDataError,
    } = useAppQuery(
        'QUALIFICATIONS_BASES',
        [journalId],
        () => StaffHTTPService.qualifications.getQualifications(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    return {
        qualificationsData,
        isQualificationsDataInitialLoading,
        isQualificationsDataError,
        isQualificationsDataRefetching,
    };
};
