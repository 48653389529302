import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { CalendarAddPage, CalendarDetailsPage, CalendarEditPage, CalendarPage } from 'pages';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';

export const calendarRoutes: RouteObject[] = [
    {
        path: appPaths.app.calendar.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.EVENTS,
                }}
            >
                <CalendarPage />
            </AbilityRoute>
        ),
        children: [
            {
                path: appPaths.app.calendar.add,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.EDIT_AND_ADD,
                            subject: PERMISSIONS_NAME.EVENTS,
                        }}
                    >
                        <CalendarAddPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.calendar.edit.base,
                element: <Navigate to={appPaths.app.calendar.base} />,
            },
            {
                path: appPaths.app.calendar.edit.pickedEvent,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.EDIT_AND_ADD,
                            subject: PERMISSIONS_NAME.EVENTS,
                        }}
                    >
                        <CalendarEditPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.calendar.details.base,
                element: <Navigate to={appPaths.app.calendar.base} />,
            },
            {
                path: appPaths.app.calendar.details.pickedEvent,
                element: (
                    <AbilityRoute
                        can={{ action: AppActions.VIEW, subject: PERMISSIONS_NAME.EVENTS }}
                    >
                        <CalendarDetailsPage />
                    </AbilityRoute>
                ),
            },
        ],
    },
];
