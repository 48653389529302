import { useContext, useEffect } from 'react';
import { AppButton, AppLoader } from 'components/atoms';
import { IconDownload } from '@tabler/icons-react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { formFields } from 'consts/form/formFields';
import { parseInstitutionGusDataIntoFormData } from 'utils/parsers/auth/parseInstitutionGusDataIntoFormData';
import { AppFormContext } from 'contexts/AppFormContext';
import { authTexts } from 'consts/text/index';
import { ButtonContainer, IconContainer } from './FetchInstitutionDataByRegonButton.styled';

const fetchingInstitutionDataError =
    'Nie udało się pobrać danych GUS, spróbuj ponownie albo wpisz dane ręcznie';

export const FetchInstitutionDataByRegonButton = () => {
    const { formError, setFormError } = useContext(AppFormContext);
    const { getFieldValue, validateFields, setFieldsValue } = useFormInstance();
    const getRegonInputValue = () => getFieldValue(formFields.regonNumber);
    const { data, isFetching, isError, refetch } = useAppQuery(
        'GUS_INSTITUTION',
        [],
        () => StaffHTTPService.gus.getInstitutionDetails(getRegonInputValue()),
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (isError && !isFetching) {
            setFormError(fetchingInstitutionDataError);
        } else if (formError === fetchingInstitutionDataError) {
            setFormError('');
        }
        // it should change on isError, isFetching only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isFetching]);

    useEffect(() => {
        if (data) {
            setFieldsValue(parseInstitutionGusDataIntoFormData(data));
        }
    }, [data, setFieldsValue]);

    const fetchInstitutionDetails = () =>
        validateFields([formFields.regonNumber])
            .then(() => {
                setFormError('');
                refetch();
            })
            // Intentional, I just don't want to do anything if there is an error
            .catch(() => {});

    const icon = isFetching ? <AppLoader size={20} /> : <IconDownload size={20} />;

    return (
        <ButtonContainer>
            <AppButton
                disabled={isFetching}
                onClick={fetchInstitutionDetails}
                type="link"
                margin={0}
            >
                <IconContainer>{icon}</IconContainer>
                {authTexts.getDataButtonLabel}
            </AppButton>
        </ButtonContainer>
    );
};
