import { AppButton, AppLink, AppParagraph } from 'components/atoms';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { authTexts } from 'consts/text/index';
import { RightComponentProps } from '../../AuthUpperNavBar.types';

export const RightComponentDesktop = ({ redirectLinks }: RightComponentProps) => {
    const navigate = useNavigate();

    const mappedLinks = redirectLinks.map(({ path, label }) => (
        <AppLink key={`${path}${label}`} to={path}>
            <AppParagraph textType="BodyMedium">{label}</AppParagraph>
        </AppLink>
    ));

    const navigateToLogin = () => navigate({ pathname: appPaths.auth['sign-in'] });
    const navigateToSignup = () => navigate({ pathname: appPaths.auth['sign-up'] });

    return (
        <>
            {mappedLinks}
            <AppButton onClick={navigateToLogin}>{authTexts.loginButtonLabel}</AppButton>
            <AppButton onClick={navigateToSignup} type="primary" marginRight={3}>
                Przetestuj teraz
            </AppButton>
        </>
    );
};
