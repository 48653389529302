import { ThemeConfig } from 'antd';
import { DefaultTheme } from 'styled-components';
import { mobileThreshold } from 'theme/styledComponents/sizes';

const screenMDWidth = mobileThreshold + 1;

export const getAntThemeBasedOnStyledComponentsTheme: (
    styledComponentsTheme: DefaultTheme,
) => ThemeConfig = (styledComponentsTheme) => ({
    components: {
        Input: {
            fontWeightStrong: 700,
        },
        Checkbox: {
            colorBorder: styledComponentsTheme.colors.primary.primary7,
        },
        Tree: {
            colorBorder: styledComponentsTheme.colors.primary.primary7,
        },
        Button: {
            colorBgContainerDisabled: styledComponentsTheme.colors.grayscale.gray6,
            colorTextDisabled: styledComponentsTheme.colors.grayscale.gray3,
        },
        Radio: {
            radioSize: 20,
            dotSize: 8,
        },
        TreeSelect: {
            colorBorder: styledComponentsTheme.colors.primary.primary7,
        },
    },
    token: {
        fontFamily: 'DM Sans',
        color: styledComponentsTheme.colors.primary.primary9,
        colorText: styledComponentsTheme.colors.grayscale.gray10,
        colorHover: styledComponentsTheme.colors.primary.primary8,
        colorTextHover: styledComponentsTheme.colors.grayscale.gray10,
        colorActive: styledComponentsTheme.colors.primary.primary10,
        colorTextActive: styledComponentsTheme.colors.grayscale.gray10,

        colorPrimary: styledComponentsTheme.colors.primary.primary9,
        colorPrimaryText: styledComponentsTheme.colors.grayscale.gray1,
        colorPrimaryHover: styledComponentsTheme.colors.primary.primary8,
        colorPrimaryTextHover: styledComponentsTheme.colors.grayscale.gray1,
        colorPrimaryActive: styledComponentsTheme.colors.primary.primary10,
        colorPrimaryTextActive: styledComponentsTheme.colors.grayscale.gray1,

        colorError: styledComponentsTheme.colors.red.red6,
        colorErrorText: styledComponentsTheme.colors.grayscale.gray1,
        colorErrorHover: styledComponentsTheme.colors.red.red5,
        colorErrorTextHover: styledComponentsTheme.colors.grayscale.gray1,
        colorErrorActive: styledComponentsTheme.colors.red.red8,
        colorErrorTextActive: styledComponentsTheme.colors.grayscale.gray1,

        screenMD: screenMDWidth,
        screenMDMin: screenMDWidth,
    },
});
