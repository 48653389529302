import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { LogoFullNameRow } from 'assets';
import { upperBarBaseStyle } from 'consts/styles/upperBarBaseStyle';

export const ContainerRow = styled(Layout.Header)`
    ${({ theme }) => css`
        ${upperBarBaseStyle};

        height: ${theme.sizes.auth.authUpperNavBarHeightMobile}px;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            padding-right: 0;
        }

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            height: ${theme.sizes.auth.authUpperNavBarHeight}px;
        }
    `}
`;

export const LeftIcon = styled(LogoFullNameRow)`
    max-height: 16px;
    margin-left: 12px;
`;

export const RightContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
