import { useMemo, ComponentType } from 'react';
import { Table, TableProps } from 'antd';
import { CustomizeComponent } from 'rc-table/es/interface';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { AppColumnsType } from 'types';
import {
    AppTableAndCardListHeader,
    AppTableAndCardListHeaderProps,
    ErrorDisplay,
} from 'components/molecules';
import {
    AddButton,
    AppBodyCell,
    AppBodyRow,
    AppBodyRowProps,
    ComponentBlockingAppLoader,
    OptionsButtonProps,
} from 'components/atoms';
import { GlobalStyle, GlobalStyleWithTableHeader } from './AppTable.styled';
import {
    TableManageUtils,
    TableManageUtilsProps,
    AppExpandIcon,
    TableEmptyDisplayProps,
    TableLoadingDisplay,
    TableNothingFoundDisplay,
    TableEmptyDisplay,
    SearchInputProps,
} from './components';
import { useColumnsParsers } from './hooks/useColumnsParsers';

export type EditableCellsProps = {
    editableRow: ComponentType<AppBodyRowProps>;
    editableCell: CustomizeComponent;
};

export type AppTableProps<TableType> = Omit<
    TableProps<TableType>,
    'title' | 'columns' | 'loading' | 'showHeader'
> &
    Omit<AppTableAndCardListHeaderProps, 'mode' | 'loading'> &
    Partial<OptionsButtonProps<TableType>> &
    Omit<TableEmptyDisplayProps, 'colSpan'> & {
        columns: AppColumnsType<TableType>;
        isTableManageUtilsHidden?: boolean;
        isTableHeaderHidden?: boolean;
        isTableColumnsTitlesVisibleOnMobile?: boolean;
        getRecordId?: (record: TableType) => string;
        isError?: boolean;
        isDataInitialLoading?: boolean;
        isDataRefetching?: boolean;
        isOptionsLoading?: boolean;
        isAlwaysExtendedOnMobile?: boolean;
        tableManageUtilsProps?: Omit<TableManageUtilsProps, 'searchInputProps'> & {
            searchInputProps?: SearchInputProps;
        };
        hideExpandColumn?: boolean;
        editableCellsProps?: EditableCellsProps;
        additionalRightTableHeaderContent?: React.ReactNode;
        additionalLeftTableHeaderContent?: React.ReactNode;
        defaultExpandStyle?: boolean;
    };

const MOBILE_ROW_ADDITIONAL_COLUMNS_COUNT = 2;

export const AppTable = <TableType extends object>({
    title,
    addButtonLabel,
    onAddButtonClick,
    options,
    columns,
    dataSource,
    isError,
    emptyInfo,
    isDataInitialLoading,
    isDataRefetching,
    isOptionsLoading,
    isTableManageUtilsHidden,
    isTableHeaderHidden,
    isTableColumnsTitlesVisibleOnMobile = false,
    isAlwaysExtendedOnMobile = false,
    isHiddenAddButton,
    expandable,
    tableManageUtilsProps,
    rowSelection,
    pagination,
    hideExpandColumn = false,
    editableCellsProps,
    additionalRightTableHeaderContent,
    additionalLeftTableHeaderContent,
    defaultExpandStyle = true,
    ...props
}: AppTableProps<TableType>) => {
    const isMobile = useIsMobile();

    const isEmpty = !dataSource?.length;
    const isVisibleAddButton = !isHiddenAddButton && !!addButtonLabel;
    const isVisibleTableColumnsTitles = !isMobile || isTableColumnsTitlesVisibleOnMobile;
    const cellComponent =
        editableCellsProps && !isMobile ? editableCellsProps.editableCell : AppBodyCell;
    const searchValue = tableManageUtilsProps?.searchInputProps?.defaultValue || '';

    const { columnsToRender, expandedRowRender } = useColumnsParsers({
        options,
        columns,
        isOptionsLoading,
        withRowSelection: !!rowSelection,
        editableMobileCellContent: editableCellsProps?.editableCell,
    });

    const headerComponentWrapper = useMemo(() => (isEmpty ? () => null : undefined), [isEmpty]);

    const bodyComponentWrapper = useMemo(() => {
        const colSpan = isMobile
            ? columnsToRender.length + MOBILE_ROW_ADDITIONAL_COLUMNS_COUNT
            : columnsToRender.length;

        if (isDataInitialLoading) {
            return () => <TableLoadingDisplay colSpan={colSpan} />;
        }
        if (searchValue && isEmpty) {
            return () => <TableNothingFoundDisplay colSpan={colSpan} />;
        }
        if (isEmpty) {
            return () => <TableEmptyDisplay colSpan={colSpan} emptyInfo={emptyInfo} />;
        }

        // in this function we want to render the specific component in just a few cases otherwise, we want to leave the default handling
        return undefined;
    }, [emptyInfo, isEmpty, isDataInitialLoading, columnsToRender.length, searchValue, isMobile]);

    if (isError) {
        return <ErrorDisplay />;
    }

    return (
        <>
            {(isVisibleAddButton || !!additionalRightTableHeaderContent) && isMobile && (
                <>
                    {isVisibleAddButton && (
                        <AddButton
                            fullWidth
                            addButtonLabel={addButtonLabel}
                            onAddButtonClick={onAddButtonClick}
                            marginBottom={12}
                        />
                    )}
                    {additionalRightTableHeaderContent}
                </>
            )}
            {!isTableManageUtilsHidden && (
                <TableManageUtils
                    {...tableManageUtilsProps}
                    searchInputProps={{
                        ...tableManageUtilsProps?.searchInputProps,
                    }}
                    isMobile={isMobile}
                />
            )}
            <GlobalStyle />
            {!(isMobile || isTableHeaderHidden) && (
                <>
                    <GlobalStyleWithTableHeader />
                    <AppTableAndCardListHeader
                        mode="TABLE"
                        title={title}
                        addButtonLabel={addButtonLabel}
                        additionalLeftElement={additionalLeftTableHeaderContent}
                        additionalRightElement={additionalRightTableHeaderContent}
                        isHiddenAddButton={!isVisibleAddButton}
                        onAddButtonClick={onAddButtonClick}
                    />
                </>
            )}
            <Table<TableType>
                columns={columnsToRender}
                showHeader={isVisibleTableColumnsTitles}
                tableLayout="auto"
                dataSource={dataSource}
                expandable={
                    defaultExpandStyle || isMobile
                        ? {
                              expandIcon: AppExpandIcon,
                              showExpandColumn: isMobile && !hideExpandColumn,
                              expandedRowRender,
                              columnWidth: 20,
                              defaultExpandAllRows: isMobile && isAlwaysExtendedOnMobile,
                              expandIconColumnIndex: isAlwaysExtendedOnMobile ? -1 : 0,
                              ...expandable,
                          }
                        : undefined
                }
                components={{
                    body: {
                        row: editableCellsProps ? editableCellsProps.editableRow : AppBodyRow,
                        cell: cellComponent,
                        wrapper: bodyComponentWrapper,
                    },
                    header: {
                        wrapper: headerComponentWrapper,
                    },
                }}
                pagination={
                    pagination
                        ? { showSizeChanger: true, showQuickJumper: true, ...pagination }
                        : false
                }
                rowSelection={rowSelection}
                {...props}
            />
            {isDataRefetching && <ComponentBlockingAppLoader />}
        </>
    );
};
