import { Dispatch, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { IconChevronLeft } from '@tabler/icons-react';
import { useMediaQuery } from 'react-responsive';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { UpperBarDrawerWithSideMenu } from 'components/atoms';
import {
    ColumnContainer,
    StyledCollapseButton,
    StyledLogoEmblemOnly,
    StyledLogoFullNameRow,
} from './SideMenu.styled';
import { SideMenuNavigation } from './components';

export type SideMenuProps = {
    collapsed: boolean;
    toggleCollapsed: () => void;
    setSideBarCollapsed: Dispatch<boolean>;
};

export const SideMenu = ({ collapsed, toggleCollapsed, setSideBarCollapsed }: SideMenuProps) => {
    const theme = useTheme();
    const isMobile = useIsMobile();
    const isAutoSizeToggle = useMediaQuery({
        query: `(max-width: ${theme?.sizes.sideMenu.autoCollapseWidth}px)`,
    });

    const parsedCollapsed = isMobile ? false : collapsed;

    useEffect(() => {
        setSideBarCollapsed(isAutoSizeToggle);
    }, [isAutoSizeToggle, setSideBarCollapsed]);

    const sideMenuContent = (
        <ColumnContainer
            collapsible={!isMobile}
            collapsed={parsedCollapsed}
            collapsedWidth={theme.sizes.sideMenu.sideBarCollapsedWidth}
            width={isMobile ? '100%' : theme.sizes.sideMenu.sideBarWidth}
            reverseArrow={false}
            trigger={null}
        >
            {!isMobile && (
                <StyledCollapseButton
                    margin={0}
                    ghost
                    onClick={toggleCollapsed}
                    className="collapsedIndicator"
                >
                    <IconChevronLeft size={18} />
                </StyledCollapseButton>
            )}
            {parsedCollapsed ? <StyledLogoEmblemOnly /> : <StyledLogoFullNameRow />}
            <SideMenuNavigation collapsed={collapsed} />
        </ColumnContainer>
    );

    return isMobile ? (
        <UpperBarDrawerWithSideMenu>{sideMenuContent}</UpperBarDrawerWithSideMenu>
    ) : (
        sideMenuContent
    );
};
