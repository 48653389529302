import { generatePath } from 'react-router-dom';
import { PsychologicalHelpAddEditForm } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { PsychologicalHelpFormFields } from 'types';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO } from 'utils/parsers/psychologicalHelp/parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { journalTexts } from 'consts/text/index';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalPsychologicalHelpAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const onAddPsychologicalHelpSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.common.psychologicalHelp.successfullyAddedPsychologicalHelp,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.psychologicalHelps.base,
                { journalId },
            ),
            {
                replace: true,
            },
        );
    };

    const {
        mutate: addPsychologicalHelp,
        error: addPsychologicalHelpError,
        isLoading: isAddPsychologicalHelpLoading,
    } = useAppMutation(StaffHTTPService.psychologicalHelps.addPsychologicalHelp, {
        key: ['PSYCHOLOGICAL_HELPS', journalId],
        onSuccess: onAddPsychologicalHelpSuccess,
        invalidateQueryKeys: [['PSYCHOLOGICAL_HELPS', journalId]],
    });

    const onAddPsychologicalHelpFinish = (psychologicalHelpData: PsychologicalHelpFormFields) => {
        const parsedAddPsychologicalHelpDTO =
            parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO(
                psychologicalHelpData,
            );
        addPsychologicalHelp({
            journalId,
            addPsychologicalHelpDTO: parsedAddPsychologicalHelpDTO,
        });
    };

    return (
        <PsychologicalHelpAddEditForm
            mode="add"
            journalId={journalId}
            axiosError={addPsychologicalHelpError}
            isLoading={isAddPsychologicalHelpLoading}
            onFinish={onAddPsychologicalHelpFinish}
        />
    );
};
