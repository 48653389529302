import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    GroupJournalDailyScheduleEditPage,
    GroupJournalDailyScheduleAddPage,
    GroupJournalWeeklyScheduleAddPage,
    GroupJournalWeeklyScheduleEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';

export const journalScheduleRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE,
                }}
            >
                <GroupJournalDailyScheduleAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.daily.edit,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE,
                }}
            >
                <GroupJournalDailyScheduleEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE,
                }}
            >
                <GroupJournalWeeklyScheduleAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.schedules.weekly.edit,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE,
                }}
            >
                <GroupJournalWeeklyScheduleEditPage />
            </AbilityRoute>
        ),
    },
];
