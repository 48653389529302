import { appPaths } from 'consts/paths/paths';
import {
    SpecialJournalQualificationAdd,
    SpecialJournalQualificationDetails,
    SpecialJournalQualificationEdit,
} from 'pages';
import { RouteObject } from 'react-router-dom';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalQualificationsRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_QUALIFICATION_BASIS,
                }}
            >
                <SpecialJournalQualificationAdd />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.details.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.details
            .pickedQualification,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_QUALIFICATION_BASIS,
                }}
            >
                <SpecialJournalQualificationDetails />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.edit
            .pickedQualification,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_QUALIFICATION_BASIS,
                }}
            >
                <SpecialJournalQualificationEdit />
            </AbilityRoute>
        ),
    },
];
