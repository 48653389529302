import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { authTexts } from 'consts/text/index';
import { regonValidator } from 'utils/validators';
import { FetchInstitutionDataByRegonButton } from './components/FetchInstitutionDataByRegonButton/FetchInstitutionDataByRegonButton';

export type RegonInputElementProps = AppFormItemProps & { withFetchDataButton?: boolean };

export const RegonInputElement = ({
    withFetchDataButton = false,
    ...props
}: RegonInputElementProps) => (
    <>
        <AppFormItem
            label="Numer REGON"
            name={formFields.regonNumber}
            marginBottom={6}
            rules={[
                { required: true, message: formElementRequiredText },
                {
                    validator: (_, value) =>
                        regonValidator(value)
                            ? Promise.resolve()
                            : Promise.reject(new Error(authTexts.wrongRegonFormatMessage)),
                    message: authTexts.wrongRegonFormatMessage,
                },
            ]}
            {...props}
        >
            <AppInput autoComplete="off" />
        </AppFormItem>
        {withFetchDataButton && <FetchInstitutionDataByRegonButton />}
    </>
);
