import styled from 'styled-components';
import { AppButton, AppText, AppTitle, StyledPlusIcon } from 'components/atoms';
import { MessagesIcon } from 'assets';
import { messagesTexts } from 'consts/text';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

type NoSelectedMailThreadInfoProps = {
    onNewMailThreadButtonClick: () => void;
};

export const NoSelectedMailThreadInfo = ({
    onNewMailThreadButtonClick,
}: NoSelectedMailThreadInfoProps) => (
    <Container>
        <MessagesIcon />
        <AppTitle level={4} margin={0} marginTop={48} marginBottom={12}>
            {messagesTexts.selectMessageFromListFirstPart}
        </AppTitle>
        <AppText textType="BodyMedium" margin={0} marginBottom={24}>
            {messagesTexts.selectMessageFromListSecondPart}
        </AppText>
        <AppButton icon={<StyledPlusIcon />} margin={0} onClick={onNewMailThreadButtonClick}>
            {messagesTexts.createMessage}
        </AppButton>
    </Container>
);
