import { useEffect } from 'react';

type DisplayFetchDataErrorProps = {
    isError: boolean;
    isFetching: boolean;
    actualError: string;
    errorMessage: string;
    setError: (errorMessage: string) => void;
};

export const useDisplayFetchDataError = ({
    isError,
    isFetching,
    actualError,
    errorMessage,
    setError,
}: DisplayFetchDataErrorProps) => {
    useEffect(() => {
        if (isError && !isFetching) {
            setError(errorMessage);
        } else if (actualError === errorMessage) {
            setError('');
        }
        // it should change on isError, isFetching only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isFetching]);
};
