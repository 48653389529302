import styled from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

export const AppOrderedList = styled.ol`
    margin: 0;
    padding-inline-start: 30px;

    // TODo ask Ujma about scrollbar
    max-height: 20vh;
    overflow: auto;

    ${getTextStyle('BodyMedium')}
`;
