import { Form } from 'antd';
import { FormProps } from 'antd/es/form/Form';
import { FormName, PossibleFormData } from 'types/forms/forms';
import styled, { css } from 'styled-components';
import { ReactNode } from 'react';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

type StyledFormProps = { withoutFlexContainer?: boolean };
export type AppFormBaseProps<AppFormFields> = FormProps<AppFormFields> & FormName & StyledFormProps;

const StyledForm: <AppFormFields extends PossibleFormData>(
    props: AppFormBaseProps<AppFormFields>,
) => ReactNode = styled(Form).withConfig({
    shouldForwardProp: getShouldForwardProp(['withoutFlexContainer']),
})<StyledFormProps>`
    ${({ theme, withoutFlexContainer = false }) => css`
        ${!withoutFlexContainer &&
        css`
            display: flex;
        `}
        flex-direction: column;
        position: relative;
        justify-content: center;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            flex: 1;
        }
    `}
`;

const scrollToFirstErrorConfig: AppFormBaseProps<unknown>['scrollToFirstError'] = {
    scrollMode: 'if-needed',
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
};

export const AppFormBase = <AppFormFields extends PossibleFormData>({
    scrollToFirstError = scrollToFirstErrorConfig,
    ...restProps
}: AppFormBaseProps<AppFormFields>) => (
    <StyledForm scrollToFirstError={scrollToFirstError} {...restProps} />
);
