import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { IconPaperclip } from '@tabler/icons-react';
import { colors } from 'theme/styledComponents/colors';
import { AppText } from 'components/atoms/CommonAppComponents/AppText/AppText';
import { FileData } from 'types';
import { AppListItem } from 'components/atoms/CommonAppComponents/AppList/components';

type FileListItemProps = {
    mode: 'FORM' | 'DETAILS';
    fileData: FileData;
    actionButton?: ReactNode;
};

const StyledListItem = styled(AppListItem)`
    ${({ theme }) => css`
        &.ant-list-item {
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: ${theme.sizes.marginSmall}px;
            border-radius: ${theme.borders.borderRadiusExtraSmall}px;

            &:hover {
                background-color: ${theme.colors.primary.primary3};
            }
        }
    `}
`;

const FileNameContainer = styled.span`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.sizes.marginSmall}px;

        svg {
            flex-shrink: 0;
        }
    `}
`;

export const FileListItem = ({ mode, fileData, actionButton }: FileListItemProps) => {
    const color = mode === 'FORM' ? colors.grayscale.gray10 : colors.primary.primary8;

    return (
        <StyledListItem>
            <FileNameContainer>
                <IconPaperclip size={20} color={color} />
                <AppText textType="BodyMedium" margin={0} color={color}>
                    {fileData.fileName}
                </AppText>
            </FileNameContainer>
            {actionButton}
        </StyledListItem>
    );
};
