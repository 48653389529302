import { axiosErrors } from 'consts/errors/axiosErrors';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { childrenTexts, commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const guardianAddEditErrorParser: (
    error: unknown,
    defaultErrorMessage?: string,
) => string = (error, defaultErrorMessage = commonTexts.errorMessages.unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (code === apiErrorCodes.CHILD_GUARDIAN_ID_DUPLICATE) {
        return childrenTexts.childGuardianWithSelectedIdNumberAlreadyExistsErrorMessage;
    }

    return defaultErrorMessage;
};
