import { AppForm, AppFormProps, ChildGroupPicker } from 'components/organisms';
import {
    FirstNameInputElement,
    LastNameInputElement,
    EmailInputElement,
    FormNavButtons,
    PhoneNumberInputElement,
} from 'components/molecules';
import { UserRolePicker } from 'components/organisms/FormItems/UserRolePicker/UserRolePicker';
import { employeeAddErrorParser } from 'utils/errorHandlers/employee/employeeAddErrorParser';
import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { EmployeeFormFields } from 'types';
import { employeeTexts, commonTexts } from 'consts/text/index';

export type EmployeeAddEditFormProps = Omit<
    AppFormProps<EmployeeFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    onFinish: (employeeData: EmployeeFormFields) => void;
    axiosError: AxiosError | null;
    isLoading: boolean;
    type: 'add' | 'edit';
};
export const EmployeeAddEditForm = ({
    type,
    initialValues,
    onFinish,
    axiosError,
    ...props
}: EmployeeAddEditFormProps) => {
    const employeeAddError = axiosError ? employeeAddErrorParser(axiosError) : '';
    const isAddFlow = type === 'add';
    const title = isAddFlow ? employeeTexts.addEmployeeButtonLabel : 'Edytuj pracownika';
    const name = isAddFlow ? 'addEmployee' : 'editEmployee';
    const buttonText = isAddFlow ? commonTexts.actionLabels.add : commonTexts.actionLabels.save;
    const buttonIcon = isAddFlow ? undefined : <IconDeviceFloppy size={20} />;

    return (
        <AppForm<EmployeeFormFields>
            maxWidth={440}
            title={title}
            name={name}
            onFinish={onFinish}
            error={employeeAddError}
            withGoBack
            {...props}
        >
            <FirstNameInputElement />
            <LastNameInputElement />
            <EmailInputElement
                disabled={!isAddFlow}
                extraOnDisabled={employeeTexts.employeeDisabledEmail}
            />
            <PhoneNumberInputElement optional />
            <UserRolePicker />
            <ChildGroupPicker version="EMPLOYEE" />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddFlow}
            />
        </AppForm>
    );
};
