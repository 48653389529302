import { axiosErrors } from 'consts/errors/axiosErrors';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { childrenTexts, commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const childAddEditErrorParser = (error: unknown) => {
    const { code, message } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (message.includes('PESEL')) {
        return commonTexts.errorMessages.invalidPesel;
    }
    if (message.includes('phoneNumber')) {
        return commonTexts.errorMessages.invalidPhoneNumber;
    }
    if (code === apiErrorCodes.CHILD_GUARDIAN_ID_DUPLICATE) {
        return childrenTexts.childGuardianWithSelectedIdNumberAlreadyExistsErrorMessage;
    }
    if (code === apiErrorCodes.ARCHIVED_CHILD_UPDATE_FORBIDDEN) {
        return childrenTexts.archivedChildUpdateErrorMessage;
    }
    if (code === apiErrorCodes.USER_ALREADY_EXISTS) {
        return commonTexts.errorMessages.userEmailTakenEnterAnother;
    }
    if (code === apiErrorCodes.INSTITUTION_CHILD_LIMIT_EXCEEDED) {
        return commonTexts.errorMessages.childLimitError;
    }

    return commonTexts.errorMessages.unknown;
};
