import { ChildWorkDTO, SpecialTopicChildWork } from 'types';
import { ChildDailySpecialAttendanceDTO } from 'types/specialAttendance/specialAttendance';

type MergeChildrenWorkType = {
    childrenWork: ChildWorkDTO[];
    childrenAttendance: ChildDailySpecialAttendanceDTO[];
};

export const mergeChildrenWorkForSpecialTopic = ({
    childrenWork,
    childrenAttendance,
}: MergeChildrenWorkType): SpecialTopicChildWork[] =>
    childrenAttendance.map((item) => {
        const foundChild = childrenWork.find((child) => child.child.id === item.child.id);

        return foundChild
            ? {
                  id: foundChild.child.id,
                  firstName: foundChild.child.firstName,
                  lastName: foundChild.child.lastName,
                  description: foundChild.description,
                  absentType: item.type,
              }
            : {
                  id: item.child.id,
                  firstName: item.child.firstName,
                  lastName: item.child.lastName,
                  description: undefined,
                  absentType: item.type,
              };
    });
