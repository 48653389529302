import { GetHistoryOfChangesFilterKey } from 'types';

export const historyOfChangesFilterFields: Record<
    GetHistoryOfChangesFilterKey,
    GetHistoryOfChangesFilterKey
> = {
    referTo: 'referTo',
    startDate: 'startDate',
    endDate: 'endDate',
    historyTypes: 'historyTypes',
};
