import { AppParagraph, AppParagraphProps } from 'components/atoms';
import { ChildGroup } from 'types';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const ChildGroupDisplay = ({
    childGroups,
    textType,
    color,
    currentGroupOnly = true,
    showSpecialGroups = false,
}: {
    childGroups: ChildGroup[];
    textType?: AppParagraphProps['textType'];
    color?: AppParagraphProps['color'];
    currentGroupOnly?: boolean;
    showSpecialGroups?: boolean;
}) => {
    const groups = showSpecialGroups
        ? childGroups
        : childGroups.filter((group) => group.type === 'JOURNAL_GROUP');

    if (!groups.length) {
        return EMPTY_VALUE_LABEL;
    }

    if (currentGroupOnly) {
        const currentGroup = groups.find(({ leftGroup }) => !leftGroup);

        return currentGroup ? (
            <AppParagraph margin={0} textType={textType} color={color}>
                {currentGroup.name}
            </AppParagraph>
        ) : (
            EMPTY_VALUE_LABEL
        );
    }

    return groups.map(({ id, name }, index) => (
        <AppParagraph key={id} margin={0} textType={textType} color={color}>
            {index !== childGroups.length - 1 ? `${name},` : name}
        </AppParagraph>
    ));
};
