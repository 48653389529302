import { commonTexts, settlementTexts } from 'consts/text';
import { AppConfirmModal } from 'components/organisms';
import { StyledBlueFolderIcon } from 'components/atoms';

type ChildAddDiscountModalProps = {
    onConfirm: () => void;
    onReject: () => void;
    open: boolean;
};

export const ChildAddDiscountModal = ({
    onConfirm,
    onReject,
    open,
}: ChildAddDiscountModalProps) => (
    <AppConfirmModal
        open={open}
        icon={<StyledBlueFolderIcon />}
        title={settlementTexts.discounts.general.addDiscountModalTitle}
        okButtonProps={{ title: commonTexts.actionLabels.confirm }}
        cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
        onOk={onConfirm}
        onCancel={onReject}
    />
);
