import { AddUpdatePsychologicalHelpDTO, PsychologicalHelpFormFields } from 'types';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parsePsychologicalHelpFormFieldsIntoAddUpdatePsychologicalHelpDTO: (
    psychologicalHelpData: PsychologicalHelpFormFields,
) => AddUpdatePsychologicalHelpDTO = ({ dateFrom, dateTo, ...restPsychologicalHelpData }) => ({
    ...restPsychologicalHelpData,
    dateFrom: parseDayjsDateIntoString(dateFrom, 'YYYY-MM-DD'),
    dateTo: parseDayjsDateIntoString(dateTo, 'YYYY-MM-DD'),
});
