import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input/TextArea';
import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

const { TextArea } = Input;

export type AppTextAreaProps = TextAreaProps & { resizable?: boolean };

const StyledAppTextArea = styled(TextArea).withConfig({
    shouldForwardProp: getShouldForwardProp(['resizable']),
})<AppTextAreaProps>`
    ${({ resizable }) => css`
        &.ant-input {
            resize: ${resizable ? 'vertical' : 'none'};
        }
    `}
`;

export const AppTextArea = ({ resizable = true, ...props }: AppTextAreaProps) => (
    <StyledAppTextArea rows={6} resizable={resizable} {...props} />
);
