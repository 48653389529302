import styled, { css } from 'styled-components';
import { AppSelect } from 'components/atoms';

export const SelectFilter = styled(AppSelect)`
    ${({ theme }) => css`
        height: 44px;

        &.ant-select {
            div.ant-select-selector {
                height: 44px;
                align-items: center;

                & .ant-select-selection-search-input {
                    height: 100%;
                }
            }

            @media ${theme.sizes.mediaQueries.mobileMax} {
                &.ant-select-multiple {
                    .ant-select-clear {
                        display: none;
                    }
                }
            }
        }
    `}
`;
