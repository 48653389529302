import { GroupType, RemoteLessonGroupRecipientDTO } from 'types';
import { ExtendedDataNode } from 'components/atoms';
import { messagesTexts } from 'consts/text';
import { getMailThreadChildrenGroupsTreePickerNodes } from 'utils/getMailThreadChildrenGroupsTreePickerNodes';

type BaseNodeData = Pick<ExtendedDataNode, 'title' | 'key'>;

const JOURNAL_GROUP_MODE_DATA: BaseNodeData = {
    title: messagesTexts.recipientsPicker.groups,
    key: 'groups',
};

const SPECIAL_GROUP_NODE_DATA: BaseNodeData = {
    title: messagesTexts.recipientsPicker.specialGroups,
    key: 'specialGroups',
};

export const parseRemoteLessonChildrenGroupDTOsIntoExtendedDataNodes: (
    remoteLessonChildrenGroupDTOs: RemoteLessonGroupRecipientDTO[],
    groupType: GroupType,
) => ExtendedDataNode[] = (remoteLessonChildrenGroupDTOs, groupType) => {
    const groupsTreeNodes = getMailThreadChildrenGroupsTreePickerNodes(
        remoteLessonChildrenGroupDTOs,
    );
    const { title, key } =
        groupType === 'JOURNAL_GROUP' ? JOURNAL_GROUP_MODE_DATA : SPECIAL_GROUP_NODE_DATA;
    const groupsParentNode = {
        title,
        key,
        children: groupsTreeNodes,
    };
    return [groupsParentNode];
};
