import { SettlementOverallDTO, SettlementOverallFormFields } from 'types';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';

export const parseSettlementOverallDTOIntoSettlementOverallFormFields = (
    settlementOverallDTO: SettlementOverallDTO,
): SettlementOverallFormFields => ({
    workingDays: settlementOverallDTO.workingDays.workingDays,
    recipientName: settlementOverallDTO.recipientName,
    bankAccountNumber: settlementOverallDTO.bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(
              settlementOverallDTO.bankAccountNumber,
          )
        : '',
});
