import { HistoryChangeGroupDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseGroupChildDTOIntoJsonObject } from 'utils/parsers/jsonObject/group/parseGroupChildDTOIntoJsonObject';
import { parseGroupTeacherDTOIntoJsonObject } from 'utils/parsers/jsonObject/group/parseGroupTeacherDTOIntoJsonObject';
import { groupTypeLabels } from 'consts/group/groupTypeLabels';
import { groupStatusesLabels } from 'consts/group/groupStatusesLabels';

export const parseGroupDTOIntoJsonObject = (groupDTO: HistoryChangeGroupDTO) => {
    const { name, type, status, children, teachers } = groupDTO;
    const childrenJsonObjects = children.map(parseGroupChildDTOIntoJsonObject);
    const teachersJsonObjects = teachers.map(parseGroupTeacherDTOIntoJsonObject);

    return {
        [jsonObjectsTexts.groupDTO.fields.name]: name,
        [jsonObjectsTexts.groupDTO.fields.type]: groupTypeLabels[type],
        [jsonObjectsTexts.groupDTO.fields.status]: groupStatusesLabels[status],
        [jsonObjectsTexts.groupDTO.fields.children]: childrenJsonObjects,
        [jsonObjectsTexts.groupDTO.fields.teachers]: teachersJsonObjects,
    };
};
