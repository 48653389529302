import { AppText } from 'components/atoms';
import { StaffSubscriptionDTO, SubscriptionType } from 'types';
import { getBarColor } from 'consts/accountPackage/accountPackageBarColors';
import {
    SubscriptionChildrenQuantity,
    SubscriptionContainer,
    SubscriptionPaymentDetails,
    SubscriptionUpgradeCTA,
} from './components';

type SubscriptionProps = {
    titleBegin: string;
    titleEnd: string;
    packageData: StaffSubscriptionDTO;
};

export const Subscription = ({ titleBegin, titleEnd, packageData }: SubscriptionProps) => {
    const { type, childLimit, activeChildren, price } = packageData;

    return (
        <SubscriptionContainer>
            <AppText textType="BodyLarge" marginBottom={10}>
                {titleBegin}
            </AppText>
            <SubscriptionChildrenQuantity
                currentChildrenCount={activeChildren}
                maxChildrenLimit={childLimit}
                titleEnd={titleEnd}
                getBarColor={getBarColor}
            />
            {type !== SubscriptionType.TESTING && (
                <SubscriptionPaymentDetails childLimit={childLimit} price={price} />
            )}
            <SubscriptionUpgradeCTA />
        </SubscriptionContainer>
    );
};
