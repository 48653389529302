import { FirebaseOptions } from 'firebase/app';

export const FIREBASE_CONFIG: Record<ImportMeta['env']['VITE_APP_ENV'], FirebaseOptions> = {
    DEV: {
        apiKey: 'AIzaSyCVWcPhNxb2CQ0lk6mk4EcyYtGjrPxkRmc',
        authDomain: 'edziennik-dev.firebaseapp.com',
        projectId: 'edziennik-dev',
        storageBucket: 'edziennik-dev.appspot.com',
        messagingSenderId: '878454974577',
        appId: '1:878454974577:web:b11f12107c73dbea0a53d0',
        measurementId: 'G-C4PFHNS42E',
    },
    UAT: {
        apiKey: 'AIzaSyAd_x99fwyQtq6LdEexU8pV1TS6QpJ260U',
        authDomain: 'edziennik-uat.firebaseapp.com',
        projectId: 'edziennik-uat',
        storageBucket: 'edziennik-uat.appspot.com',
        messagingSenderId: '18911713473',
        appId: '1:18911713473:web:ab2832f40fd156517f1c40',
        measurementId: 'G-XBEM6WH8VD',
    },
    PROD: {
        apiKey: 'AIzaSyDts56YisEQNcKOS6WPU6tHlrDyF69MwJU',
        authDomain: 'edziennik-prod.firebaseapp.com',
        projectId: 'edziennik-prod',
        storageBucket: 'edziennik-prod.appspot.com',
        messagingSenderId: '122393822879',
        appId: '1:122393822879:web:5b0b0d59a0c748711644aa',
    },
};
