import { GroupTeacherDTO } from 'types';
import { AppParagraph } from 'components/atoms';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { getFullName } from 'utils/getFullName';

export const GroupTeacherDisplay = ({ groupTeachers }: { groupTeachers: GroupTeacherDTO[] }) => {
    const groupTeachersNames = groupTeachers.length
        ? groupTeachers
              .map(({ firstName, lastName }) => getFullName(firstName, lastName))
              .join(', ')
        : EMPTY_VALUE_LABEL;

    return <AppParagraph margin={0}>{groupTeachersNames}</AppParagraph>;
};
