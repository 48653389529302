import { DetailsColumn, DetailsFrameBase, DetailsRow, CopyContentText } from 'components/atoms';
import { parentTexts } from 'consts/text';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';

type PaymentTransferDetailsProps = {
    title: string;
    recipientName: string;
    bankAccountNumber: string;
    amount: number;
};

export const PaymentTransferDetails = ({
    title,
    recipientName,
    bankAccountNumber,
    amount,
}: PaymentTransferDetailsProps) => {
    const bankAccountNumberText =
        parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber);
    const institutionTransferAmountText = getFormattedPrice(amount);

    return (
        <DetailsFrameBase hideHeader>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.paymentTitle} span={12}>
                    <CopyContentText text={title} />
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.recipientName} span={12}>
                    <CopyContentText text={recipientName} />
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.bankAccountNumber} span={12}>
                    <CopyContentText text={bankAccountNumberText} />
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.amount} span={12}>
                    <CopyContentText text={institutionTransferAmountText} />
                </DetailsColumn>
            </DetailsRow>
        </DetailsFrameBase>
    );
};
