import dayjs, { Dayjs } from 'dayjs';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { FIRST_DAY_OF_MONTH, ONE_MONTH_DIFFERENCE, TENTH_DAY_OF_MONTH } from 'consts/dateConsts';
import { useAppAbility } from '../useAppAbility';

export const useJournalAbilities = () => {
    const ability = useAppAbility();

    const genericAbilities = {
        checkIfCanEditAttendanceForSelectedDate: (selectedDate: Dayjs) => {
            const canEditAllTime = ability.can(
                AppActions.EDIT_ALL_TIME,
                PERMISSIONS_NAME.LAST_MONTH_JOURNALS_ATTENDANCE_EDIT,
            );
            const canEdit10thDay = ability.can(
                AppActions.EDIT_10TH_DAY_OF_MONTH,
                PERMISSIONS_NAME.LAST_MONTH_JOURNALS_ATTENDANCE_EDIT,
            );
            const currentDate = dayjs();
            if (canEditAllTime) {
                return true;
            }
            if (canEdit10thDay) {
                const currentMonthFirstDay = currentDate.set('date', FIRST_DAY_OF_MONTH);
                const selectedMonthFirstDay = selectedDate.set('date', FIRST_DAY_OF_MONTH);
                const monthsDifference = currentMonthFirstDay.diff(selectedMonthFirstDay, 'month');
                if (selectedDate.isSameOrAfter(currentMonthFirstDay, 'date')) {
                    return true;
                }
                if (monthsDifference > ONE_MONTH_DIFFERENCE) {
                    return false;
                }
                return !(currentDate.date() > TENTH_DAY_OF_MONTH);
            }
            return false;
        },
        checkIfCanArchiveJournals: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.JOURNALS_ARCHIVING),
        // this permission is handled by backend
        checkIfCanAccessAllJournals: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.ALL_JOURNALS_ACCESS),
        checkIfCanChangeChildDates: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.JOURNALS_CHILDREN_DATES_EDIT),
    };

    const groupJournalsAbilities = {
        checkIfCanViewJournals: () =>
            ability.can(AppActions.VIEW_MODULE, STAFF_APP_MODULES.GROUP_JOURNALS),
        checkIfCanPrintJournals: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.GROUP_JOURNALS_PRINT),
        checkIfCanViewSchedule: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE),
        checkIfCanManageSchedule: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUP_JOURNALS_SCHEDULE),
        checkIfCanViewTopics: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_TOPICS),
        checkIfCanManageTopics: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUP_JOURNALS_TOPICS),
        checkIfCanViewAttendance: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_ATTENDANCE),
        checkIfCanManageAttendance: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUP_JOURNALS_ATTENDANCE),
        checkIfCanViewEvents: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS),
        checkIfCanManageEvents: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS),
        checkIfCanViewConsultations: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_CONSULTATIONS),
        checkIfCanManageConsultations: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUP_JOURNALS_CONSULTATIONS),
        checkIfCanViewPsychologicalHelp: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_PSYCHOLOGICAL_HELP),
        checkIfCanManagePsychologicalHelp: () =>
            ability.can(
                AppActions.EDIT_AND_ADD,
                PERMISSIONS_NAME.GROUP_JOURNALS_PSYCHOLOGICAL_HELP,
            ),
        checkIfCanViewChildren: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_CHILDREN_LIST_VIEW),
        checkIfCanViewInspections: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.GROUP_JOURNALS_INSPECTIONS),
        checkIfCanManageInspections: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.GROUP_JOURNALS_INSPECTIONS),
    };

    const specialJournalsAbilities = {
        checkIfCanViewJournals: () =>
            ability.can(AppActions.VIEW_MODULE, STAFF_APP_MODULES.SPECIAL_JOURNALS),
        checkIfCanPrintJournals: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.SPECIAL_JOURNALS_PRINT),
        checkIfCanManageJournals: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.SPECIAL_JOURNALS_MANAGE),
        checkIfCanViewAttendance: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_ATTENDANCE),
        checkIfCanManageAttendance: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.SPECIAL_JOURNALS_ATTENDANCE),
        checkIfCanViewConsultations: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_CONSULTATIONS),
        checkIfCanManageConsultations: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.SPECIAL_JOURNALS_CONSULTATIONS),
        checkIfCanViewInspections: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_INSPECTIONS),
        checkIfCanManageInspections: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.SPECIAL_JOURNALS_INSPECTIONS),
        checkIfCanViewQualificationBasis: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_QUALIFICATION_BASIS),
        checkIfCanManageQualificationBasis: () =>
            ability.can(
                AppActions.EDIT_AND_ADD,
                PERMISSIONS_NAME.SPECIAL_JOURNALS_QUALIFICATION_BASIS,
            ),
        checkIfCanViewChildren: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_CHILDREN_LIST_VIEW),
        checkIfCanViewTimetable: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_TIMETABLE_VIEW),
        checkIfCanViewTopics: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.SPECIAL_JOURNALS_TOPICS),
        checkIfCanManageTopics: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.SPECIAL_JOURNALS_TOPICS),
    };

    return {
        genericAbilities,
        groupJournalsAbilities,
        specialJournalsAbilities,
    };
};
