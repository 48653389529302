import { Address } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseAddressIntoJsonObject: (address: Address) => object = (address) => {
    const { street, zipCode, city } = address;

    return {
        [jsonObjectsTexts.address.fields.street]: street || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.address.fields.zipCode]: zipCode || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.address.fields.city]: city || EMPTY_VALUE_LABEL,
    };
};
