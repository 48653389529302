import {
    EducationalGuideTextbookUnits,
    EducationalGuideTextbooks,
    EducationalGuideTopicId,
    EducationalGuideTextbookId,
} from 'types/educationalGuide/educationalGuide';
import { SelectOptionType } from 'types';

export const mapUnitTopicsListToFlatList = (
    unitTopics: EducationalGuideTextbookUnits,
): SelectOptionType<EducationalGuideTopicId, string>[] =>
    unitTopics.units.flatMap((unit) =>
        unit.topics.map((topic) => ({
            label: unit.name,
            value: topic.id,
        })),
    );

export const mapTextbooksListToSelectOptionsList = (
    guideTextbooks: EducationalGuideTextbooks,
): SelectOptionType<EducationalGuideTextbookId, string>[] =>
    guideTextbooks.map((textbook) => ({ label: textbook.name, value: textbook.id }));

export const parseTextbookToTreeSelect = (
    educationalGuideTextbookUnits: EducationalGuideTextbookUnits,
) =>
    educationalGuideTextbookUnits.units.map((unit) => {
        const mappedTopics = unit.topics.map((topic) => ({
            title: topic.name,
            key: topic.id,
        }));
        return {
            checkable: false,
            children: mappedTopics,
            key: unit.name,
            title: unit.name,
        };
    });
