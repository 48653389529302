import { ChildSettlementDetails } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseChildSettlementDetailsIntoJsonObject: (
    childSettlementDetails: ChildSettlementDetails,
) => object = (childSettlementDetails) => {
    const { periodStart, periodEnd, stayStart, stayEnd } = childSettlementDetails;

    const parsedPeriodStart = periodStart
        ? parseStringIntoFormattedStringDate(periodStart)
        : EMPTY_VALUE_LABEL;
    const parsedPeriodEnd = periodEnd
        ? parseStringIntoFormattedStringDate(periodEnd)
        : EMPTY_VALUE_LABEL;
    const parsedStayStart = stayStart
        ? parseStringIntoFormattedStringDate(stayStart)
        : EMPTY_VALUE_LABEL;
    const parsedStayEnd = stayEnd ? parseStringIntoFormattedStringDate(stayEnd) : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.childDetailed.innerObjects.childSettlementDetails.fields.periodStart]:
            parsedPeriodStart,
        [jsonObjectsTexts.childDetailed.innerObjects.childSettlementDetails.fields.periodEnd]:
            parsedPeriodEnd,
        [jsonObjectsTexts.childDetailed.innerObjects.childSettlementDetails.fields.stayStart]:
            parsedStayStart,
        [jsonObjectsTexts.childDetailed.innerObjects.childSettlementDetails.fields.stayEnd]:
            parsedStayEnd,
    };
};
