import { appPaths } from 'consts/paths/paths';
import { commonTexts } from 'consts/text';
import { SimpleMenuItem } from 'components/atoms';

export const timetableMenuItems: SimpleMenuItem[] = [
    {
        key: appPaths.app.timetable.employeeSchedule.base,
        label: commonTexts.menuItems.timetableMenu.employeeTimetable,
    },
    {
        key: appPaths.app.timetable.workingTime.base,
        label: commonTexts.menuItems.timetableMenu.workingTime,
    },
    {
        key: appPaths.app.timetable.evidenceWorkingTime.base,
        label: commonTexts.menuItems.timetableMenu.evidenceWorkingTime,
    },
];
