import dayjs, { Dayjs } from 'dayjs';
import { SCHOOL_YEAR_FINISH_MONTH_INDEX, FIRST_DAY_OF_MONTH } from 'consts/dateConsts';

export const isAfterSchoolYear = (currentDate: Dayjs, schoolYearToCheck: number) => {
    const dateToCheckWithFirstMonthDay = dayjs()
        .date(FIRST_DAY_OF_MONTH)
        .month(SCHOOL_YEAR_FINISH_MONTH_INDEX)
        .year(schoolYearToCheck);
    const daysNumberInAugustInYearToCheck = dateToCheckWithFirstMonthDay.daysInMonth();
    const dateToCheckWithLastMonthDay = dateToCheckWithFirstMonthDay.set(
        'date',
        daysNumberInAugustInYearToCheck,
    );
    return currentDate.isAfter(dateToCheckWithLastMonthDay, 'date');
};
