import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { NewsLayout } from 'components/layouts';
import {
    DailySchedulePage,
    IndividualSchedulePage,
    ParentCalendarPage,
    WeeklySchedulePage,
    ParentGroupAttendancePage,
    ParentAbsencesAddPage,
} from 'pages/app/parent';

export const newsRoutes: RouteObject[] = [
    {
        path: appPaths.app.parent.news.base,
        element: <NewsLayout />,
        children: [
            {
                path: appPaths.app.parent.news.attendance.base,
                element: <ParentGroupAttendancePage />,
                children: [
                    {
                        path: appPaths.app.parent.news.attendance['add-absent'],
                        element: <ParentAbsencesAddPage />,
                    },
                ],
            },
            {
                path: appPaths.app.parent.news.calendar,
                element: <ParentCalendarPage />,
            },
            {
                path: appPaths.app.parent.news['daily-schedule'],
                element: <DailySchedulePage />,
            },
            {
                path: appPaths.app.parent.news['weekly-schedule'],
                element: <WeeklySchedulePage />,
            },
            {
                path: appPaths.app.parent.news.lessons,
                element: <IndividualSchedulePage />,
            },
        ],
    },
];
