import { useSearchParams } from 'react-router-dom';
import { ChildContractStatus, ChildStatus, GetChildrenFilterKey, GroupId, Pagination } from 'types';
import { DEFAULT_PAGE, PAGE_PARAM_KEY } from 'consts/pagination';
import { childFilterFields } from 'consts/filters/child/childFilterFields';
import { useSearchFilter } from 'hooks/useSearchFilter/useSearchFilter';
import { SEARCH_FILTER_KEY } from 'consts/filters/common/filtersKeys';

type ChildrenPageFiltersProps = {
    currentPage: Pagination['currentPage'];
};

export const useChildrenPageFilters = ({ currentPage }: ChildrenPageFiltersProps) => {
    const { searchFilterValue, handleSearchInput } = useSearchFilter({
        currentPage,
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const contractValidParam = searchParams.get(childFilterFields.contractValid);
    const statusParam = searchParams.get(childFilterFields.status);
    const groupsParams = searchParams.getAll(childFilterFields.groupIds);

    const handleClearFilters = (clearSearchFilter?: boolean) => {
        setSearchParams((prevSearchParams) => {
            if (clearSearchFilter) {
                prevSearchParams.delete(SEARCH_FILTER_KEY);
            }
            prevSearchParams.delete(childFilterFields.contractValid);
            prevSearchParams.delete(childFilterFields.status);
            prevSearchParams.delete(childFilterFields.groupIds);
            prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
            return prevSearchParams;
        });
    };

    const handleClearFilter = (filterKey: GetChildrenFilterKey) => {
        setSearchParams((prevSearchParams) => {
            prevSearchParams.delete(filterKey);
            prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
            return prevSearchParams;
        });
    };

    const updateFilter = (filterKey: GetChildrenFilterKey, value: string | string[]) => {
        setSearchParams(
            (prevSearchParams) => {
                if (Array.isArray(value)) {
                    prevSearchParams.delete(filterKey);
                    value.forEach((valueItem) => prevSearchParams.append(filterKey, valueItem));
                } else {
                    prevSearchParams.set(filterKey, value);
                }
                prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
                return prevSearchParams;
            },
            { replace: currentPage === DEFAULT_PAGE },
        );
    };

    const handleChangeFilter = (
        filterKey: GetChildrenFilterKey,
        value: string | string[] | undefined,
    ) => {
        if (!value || !value.length) {
            handleClearFilter(filterKey);
            return;
        }
        updateFilter(filterKey, value);
    };

    return {
        contractValidFilterValue: contractValidParam
            ? (contractValidParam as ChildContractStatus)
            : null,
        statusFilterValue: statusParam ? (statusParam as ChildStatus) : 'ACTIVE',
        groupsFilterValue: groupsParams ? (groupsParams as GroupId[]) : null,
        searchFilterValue,
        handleChangeFilter,
        handleClearFilter,
        handleClearFilters,
        handleSearchInput,
    };
};
