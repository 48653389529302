import { commonTexts, settlementTexts } from 'consts/text';
import { InfoText } from 'components/molecules';
import { useSearchParamsBasedPagination } from 'hooks/useSearchParamsBasedPagination/useSearchParamsBasedPagination';
import { AdditionalLessonDTO, CourseId } from 'types';
import { additionalLessonDTOFields } from 'consts/additionalLessons/additionalLessonDTOFields';
import { AppTable, AppTableProps } from 'components/organisms';
import { AppButton, Option } from 'components/atoms';
import { IconArrowBarRight, IconEdit } from '@tabler/icons-react';
import { useJournalAbilities } from 'abilities';
import styled, { css } from 'styled-components';
import { useMemo, useState } from 'react';
import { EditAdditionalLessonModal } from 'components/templates/settlement/EditAdditionalLessonModal/EditAdditionalLessonModal';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { parsePaginationDataToTablePaginationData } from 'utils/parsers/table/parsePaginationDataToTablePaginationData';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { AdditionalLessonJournalSelectModal } from 'components/templates/settlement/AdditionalLessonJournal/AdditionalLessonJournalSelectModal';
import {
    AdditionalLessonsPaymentTypeColumn,
    AdditionalLessonsPriceColumn,
    AdditionalLessonsSettlementTypeColumn,
} from 'components/atoms/AdditionalLessonsColumns';
import { noAdditionalLessonConfigurationColor } from 'consts/additionalLessons/noAdditionalLessonConfigurationColor';

const AdditionalLessonDisplay = styled(AppButton)<{ color?: string }>`
    ${({ theme, color }) => css`
        margin: 0;
        padding: 0;
        font-weight: normal;
        color: ${color ?? theme.colors.primary} !important;
    `}
`;

const columns = (
    setSelectedLesson: (record: AdditionalLessonDTO) => void,
): AppTableProps<AdditionalLessonDTO>['columns'] => [
    {
        title: settlementTexts.extraLessons.columnLabels.name,
        dataIndex: additionalLessonDTOFields.courseName,
        key: additionalLessonDTOFields.courseName,
        render: (_, record) => (
            <AdditionalLessonDisplay
                type="link"
                color={!record.configured ? noAdditionalLessonConfigurationColor : 'primary'}
                onClick={() => setSelectedLesson(record)}
            >
                {record.courseName}
            </AdditionalLessonDisplay>
        ),
    },
    {
        title: settlementTexts.extraLessons.columnLabels.price,
        dataIndex: additionalLessonDTOFields.price,
        key: additionalLessonDTOFields.price,
        render: (_, record) => <AdditionalLessonsPriceColumn additionalLesson={record} />,
        isVisibleAsExtendableInMobile: true,
        isInvisibleInMobile: false,
    },
    {
        title: settlementTexts.extraLessons.columnLabels.paymentConfig,
        dataIndex: additionalLessonDTOFields.paymentType,
        key: additionalLessonDTOFields.paymentType,
        render: (_, record) => <AdditionalLessonsPaymentTypeColumn additionalLesson={record} />,
        isVisibleAsExtendableInMobile: true,
        isInvisibleInMobile: false,
    },
    {
        title: settlementTexts.extraLessons.columnLabels.settlementConfig,
        dataIndex: additionalLessonDTOFields.settlementType,
        key: additionalLessonDTOFields.settlementType,
        render: (_, record) => <AdditionalLessonsSettlementTypeColumn additionalLesson={record} />,
        isVisibleAsExtendableInMobile: true,
        isInvisibleInMobile: false,
    },
];

export const AdditionalLessonsPage = () => {
    const [selectedLesson, setSelectedLesson] = useState<AdditionalLessonDTO | null>(null);
    const [selectedLessonIdForJournalSelect, setSelectedLessonIdForJournalSelect] =
        useState<CourseId | null>(null);

    const {
        groupJournalsAbilities: { checkIfCanViewJournals },
    } = useJournalAbilities();
    const canViewJournals = checkIfCanViewJournals();

    const { currentPage, currentPageSize, handlePaginationValuesChange } =
        useSearchParamsBasedPagination();

    const onDeselectAdditionalLesson = () => {
        setSelectedLesson(null);
    };

    const onDeselectAdditionalLessonForJournal = () => {
        setSelectedLessonIdForJournalSelect(null);
    };

    const options: Option<AdditionalLessonDTO>[] = [
        {
            label: commonTexts.actionLabels.goToJournal,
            onClick: (additionalLesson: AdditionalLessonDTO) =>
                setSelectedLessonIdForJournalSelect(additionalLesson.id),
            Icon: <IconArrowBarRight />,
            checkIfVisible: () => canViewJournals,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: (additionalLesson: AdditionalLessonDTO) => setSelectedLesson(additionalLesson),
            Icon: <IconEdit />,
        },
    ];

    const {
        data: extraLessonsData,
        isInitialLoading: isExtraLessonsDataInitialFetching,
        isRefetching: isExtraLessonsDataRefetching,
        isError: isExtraLessonsDataError,
    } = useAppQuery(
        'SETTLEMENT_EXTRA_LESSONS',
        [currentPage, currentPageSize],
        () =>
            StaffHTTPService.settlements.getExtraLessons({
                paginationParams: { size: currentPageSize, page: currentPage },
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const memorizedColumns = useMemo(() => columns(setSelectedLesson), [setSelectedLesson]);

    return (
        <>
            {selectedLessonIdForJournalSelect && (
                <AdditionalLessonJournalSelectModal
                    onClose={onDeselectAdditionalLessonForJournal}
                    courseId={selectedLessonIdForJournalSelect}
                />
            )}
            {selectedLesson && (
                <EditAdditionalLessonModal
                    onClose={onDeselectAdditionalLesson}
                    extraLesson={selectedLesson}
                />
            )}
            <InfoText marginBottom={0} text={settlementTexts.extraLessons.addInfo} />
            <AppTable<AdditionalLessonDTO>
                isError={isExtraLessonsDataError}
                title={settlementTexts.extraLessons.tableTitle}
                emptyInfo={settlementTexts.extraLessons.emptyInfo}
                columns={memorizedColumns}
                rowKey={({ id }) => id}
                isDataInitialLoading={isExtraLessonsDataInitialFetching}
                isDataRefetching={isExtraLessonsDataRefetching}
                options={options}
                dataSource={extraLessonsData?.content ?? []}
                tableManageUtilsProps={{ isVisibleSearchFilter: false }}
                pagination={{
                    ...parsePaginationDataToTablePaginationData({
                        currentPage,
                        currentPageSize,
                    }),
                    total: extraLessonsData?.totalElements || 0,
                    onChange: handlePaginationValuesChange,
                }}
            />
        </>
    );
};
