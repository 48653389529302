import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { AppLoader, AppModalBase, AppModalBaseProps } from 'components/atoms';
import { AppModalButtonProps } from 'types';
import { IconContainer, ModalButton, ModalText, ModalTitle } from './AppConfirmModal.styled';

export type AppConfirmModalProps = Omit<
    AppModalBaseProps,
    | 'title'
    | 'okButtonProps'
    | 'cancelButtonProps'
    | 'okText'
    | 'okType'
    | 'cancelText'
    | 'children'
> & {
    icon?: ReactNode;
    title?: string;
    text?: string;
    okButtonProps?: AppModalButtonProps;
    cancelButtonProps?: AppModalButtonProps;
    confirmActionLoading?: boolean;
};

export const AppConfirmModal = ({
    icon,
    title,
    text,
    okButtonProps,
    cancelButtonProps,
    onOk,
    onCancel,
    confirmActionLoading,
    ...props
}: AppConfirmModalProps) => {
    const theme = useTheme();

    const buttons =
        okButtonProps || cancelButtonProps ? (
            <>
                {cancelButtonProps && (
                    <ModalButton type={cancelButtonProps.type || 'default'} onClick={onCancel}>
                        {cancelButtonProps.title}
                    </ModalButton>
                )}
                {okButtonProps && (
                    <ModalButton
                        type={okButtonProps.type || 'primary'}
                        onClick={onOk}
                        danger={okButtonProps.danger}
                        disabled={confirmActionLoading}
                    >
                        {confirmActionLoading ? (
                            <AppLoader size={20} color={theme.colors.grayscale.gray1} />
                        ) : (
                            okButtonProps.title
                        )}
                    </ModalButton>
                )}
            </>
        ) : null;

    return (
        <AppModalBase
            closeIcon={null}
            width={theme.sizes.modalWidth.small}
            footer={buttons}
            onCancel={onCancel}
            isMobileFullScreenMode={false}
            {...props}
        >
            {icon && <IconContainer>{icon}</IconContainer>}
            {title && <ModalTitle level={5}>{title}</ModalTitle>}
            {text && <ModalText textType="BodyMedium">{text}</ModalText>}
        </AppModalBase>
    );
};
