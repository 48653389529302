import { AbsenceDTO, AbsenceType } from 'types';
import { timetableTexts } from 'consts/text';

type AbsenceTypeOption = {
    label: string;
    value: AbsenceType;
};
export const AbsenceTypePickerOptions: AbsenceTypeOption[] = [
    {
        label: timetableTexts.absence.absenceTypeLabels.HOLIDAY_LEAVE,
        value: AbsenceType.HOLIDAY_LEAVE,
    },
    {
        label: timetableTexts.absence.absenceTypeLabels.LEAVE_ON_REQUEST,
        value: AbsenceType.LEAVE_ON_REQUEST,
    },
    {
        label: timetableTexts.absence.absenceTypeLabels.OCCASIONAL_LEAVE,
        value: AbsenceType.OCCASIONAL_LEAVE,
    },
    {
        label: timetableTexts.absence.absenceTypeLabels.UNPAID_LEAVE,
        value: AbsenceType.UNPAID_LEAVE,
    },
    {
        label: timetableTexts.absence.absenceTypeLabels.SICK_LEAVE,
        value: AbsenceType.SICK_LEAVE,
    },
    {
        label: timetableTexts.absence.absenceTypeLabels.UNJUSTIFIED_ABSENCE,
        value: AbsenceType.UNJUSTIFIED_ABSENCE,
    },
    {
        label: timetableTexts.absence.absenceTypeLabels.OTHER,
        value: AbsenceType.OTHER,
    },
];

export const AbsenceTypeToPaymentStatusMap: Record<AbsenceType, { paid: boolean }> = {
    HOLIDAY_LEAVE: { paid: true },
    SICK_LEAVE: { paid: true },
    OTHER: { paid: false },
    UNJUSTIFIED_ABSENCE: { paid: false },
    UNPAID_LEAVE: { paid: false },
    OCCASIONAL_LEAVE: { paid: true },
    LEAVE_ON_REQUEST: { paid: true },
};

export const getAbsenceTypeLabel = (type: AbsenceDTO['type']) =>
    AbsenceTypePickerOptions.find((absenceTypeOption) => absenceTypeOption.value === type)?.label;
