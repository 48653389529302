import { AppButton } from 'components/atoms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { FormNavButtons } from 'components/molecules';
import { AppForm, AppFormProps } from '../AppForm/AppForm';

export type AppFormSummaryProps = Pick<
    AppFormProps<unknown>,
    'title' | 'subTitle' | 'overLinkText' | 'linkButton' | 'onLinkButtonClick' | 'isLoading'
> & {
    buttonText: string;
    onClick: () => void;
    hideGoBackButton?: boolean;
};
export const AppFormSummary = ({
    title,
    subTitle,
    overLinkText,
    linkButton,
    onLinkButtonClick,
    buttonText,
    onClick,
    hideGoBackButton,
    isLoading,
}: AppFormSummaryProps) => {
    const isMobile = useIsMobile();

    return (
        <AppForm
            maxWidth={410}
            name="summary"
            title={title}
            subTitle={subTitle}
            overLinkText={overLinkText}
            linkButton={linkButton}
            onLinkButtonClick={onLinkButtonClick}
            withGoBack={!hideGoBackButton}
            isLoading={isLoading}
        >
            <FormNavButtons
                marginTop={0}
                marginBottom={!linkButton && !overLinkText ? 0 : undefined}
            >
                <AppButton onClick={onClick} fullWidth={isMobile} type="primary">
                    {buttonText}
                </AppButton>
            </FormNavButtons>
        </AppForm>
    );
};
