import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { commonTexts, settingsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';

type DietsPageNetworkManageParams = {
    onDietRemoveRequestFinish: () => void;
};

export const useDietsPageNetworkManage = ({
    onDietRemoveRequestFinish,
}: DietsPageNetworkManageParams) => {
    const currentInstitution = useCurrentInstitution();
    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: dietsData,
        isInitialLoading: isDietsDataInitialLoading,
        isRefetching: isDietsDataRefetching,
        isError: isDietsDataError,
    } = useAppQuery('DIETS', [], StaffHTTPService.diets.getDiets, {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { mutate: removeDiet, isLoading: isRemoveDietLoading } = useAppMutation(
        StaffHTTPService.diets.archiveDiet,
        {
            key: ['DIETS', currentInstitution.id],
            onSuccess: async (_, variables) => {
                onDietRemoveRequestFinish();
                // variables is DietId
                await queryClient.invalidateQueries([currentInstitution.id, 'DIET', variables]);
                displaySuccess(settingsTexts.diets.successNotifications.dietDeleted);
            },
            onError: (error) => {
                const code = (error.response?.data as { code: string })?.code;
                onDietRemoveRequestFinish();
                if (code === apiErrorCodes.DIET_IN_USE_REMOVE_ATTEMPT) {
                    displayError(settingsTexts.diets.errorsNotifications.dietInUse);
                    return;
                }
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['DIETS']],
        },
    );

    return {
        dietsData,
        isDietsDataInitialLoading,
        isDietsDataRefetching,
        isDietsDataError,
        removeDiet,
        isRemoveDietLoading,
    };
};
