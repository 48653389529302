import { useEffect } from 'react';
import styled from 'styled-components';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { datesTimelineValidator } from 'utils/validators';
import { dayAttendanceDTOFields } from 'consts/attendance/dayAttendanceDTOFields';
import { formFields } from 'consts/form/formFields';
import { journalTexts } from 'consts/text';
import { AppFormItem, AppTimePicker, AppTimePickerProps } from 'components/atoms';
import { WorkingTimeFormFields, WorkingTimeTeacherDTO } from 'types';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { parseDayjsTimeIntoHoursMinutesFormat } from 'utils/parsers/dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export type WorkingTimePickerElementProps = Pick<AppTimePickerProps, 'disabled'> & {
    cellKey?: string;
    teacherWorkingTime?: WorkingTimeTeacherDTO;
    selectedDate?: string;
    handleSave?: (teacherWorkingTime: WorkingTimeTeacherDTO) => void;
};

const StyledAppFormItem = styled(AppFormItem)`
    width: 200px;

    .ant-form-item-explain-error {
        margin-bottom: 0;
    }
`;

export const WorkingTimePickerElement = ({
    cellKey,
    teacherWorkingTime,
    selectedDate,
    disabled,
    handleSave,
}: WorkingTimePickerElementProps) => {
    const { setFieldsValue, validateFields } = useFormInstance<WorkingTimeFormFields>();

    useEffect(() => {
        if (!selectedDate) {
            return;
        }

        setFieldsValue({
            timeFrom: teacherWorkingTime?.timeFrom
                ? parseStringIntoDayjsTime(teacherWorkingTime.timeFrom as string)
                : null,
            timeTo: teacherWorkingTime?.timeTo
                ? parseStringIntoDayjsTime(teacherWorkingTime.timeTo as string)
                : null,
        });
    }, [teacherWorkingTime, selectedDate, setFieldsValue]);

    const handleChangeTime = async () => {
        if (!teacherWorkingTime) {
            return;
        }
        try {
            const attendanceTimesValues = await validateFields();
            handleSave?.({
                timeFrom: attendanceTimesValues.timeFrom
                    ? parseDayjsTimeIntoHoursMinutesFormat(attendanceTimesValues.timeFrom)
                    : null,
                timeTo: attendanceTimesValues.timeTo
                    ? parseDayjsTimeIntoHoursMinutesFormat(attendanceTimesValues.timeTo)
                    : null,
                firstName: teacherWorkingTime.firstName,
                lastName: teacherWorkingTime.lastName,
                teacherId: teacherWorkingTime.teacherId,
            });
            // Intentional, I just don't want to do anything if there is an error
        } catch (error) {
            /* empty */
        }
    };

    return (
        <StyledAppFormItem
            key={cellKey}
            name={cellKey}
            initialValue={null}
            margin={0}
            optional
            rules={[
                ({ getFieldValue: getFieldFormValue }) =>
                    datesTimelineValidator(getFieldFormValue, {
                        mode: cellKey === dayAttendanceDTOFields.entryTime ? 'start' : 'finish',
                        startValueFieldName: formFields.entryTime,
                        finishValueFieldName: formFields.exitTime,
                        isFinishValueBeforeStartValueErrorText:
                            journalTexts.journalDetails.groupJournal.attendance.daily
                                .invalidExitTimeErrorMessage,
                        isStartValueAfterFinishValueErrorText:
                            journalTexts.journalDetails.groupJournal.attendance.daily
                                .invalidEntryTimeErrorMessage,
                        unitToCompare: 'minute',
                    }),
            ]}
        >
            <AppTimePicker
                onChange={handleChangeTime}
                allowClear={false}
                isFitContentWidth
                disabled={disabled}
            />
        </StyledAppFormItem>
    );
};
