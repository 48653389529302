import { BACKSLASH_SEPARATOR } from 'consts/separators';

export const getFirstAndLastJournalSchoolYears = (schoolYears: string[]) => {
    const firstSchoolYearsPair = schoolYears[0];
    const lastSchoolYearsPair = schoolYears[schoolYears.length - 1];
    const firstYear = firstSchoolYearsPair.split(BACKSLASH_SEPARATOR)[0];
    const lastYear = lastSchoolYearsPair.split(BACKSLASH_SEPARATOR)[1];

    return {
        firstYear,
        lastYear,
    };
};
