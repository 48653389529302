import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    GroupJournalTripAddPage,
    GroupJournalTripDetailsPage,
    GroupJournalTripEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalTripRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalTripAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.edit.pickedTrip,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalTripEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.details.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.events.trips.details
            .pickedTrip,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.GROUP_JOURNALS_EVENTS,
                }}
            >
                <GroupJournalTripDetailsPage />
            </AbilityRoute>
        ),
    },
];
