import { UserEdit } from 'assets';
import { EditAffectNextLessonsModal } from 'components/templates/lesson/EditAffectNextLessonsModal/EditAffectNextLessonsModal';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { colors } from 'theme/styledComponents/colors';
import {
    CommonFormProps,
    FormModeDependedTexts,
    JournalDTO,
    JournalId,
    JournalType,
    LessonDTO,
    LessonFormFields,
} from 'types';
import {
    AppConfirmModal,
    AppForm,
    AppFormProps,
    JournalChildPicker,
    JournalPicker,
    TeachersPicker,
} from 'components/organisms';
import {
    FormNavButtons,
    RepetitionPickerElement,
    SimpleDurationDatePicker,
    SimpleDurationTimePicker,
    SimpleInputElement,
} from 'components/molecules';
import { commonTexts, journalTexts, timetableTexts } from 'consts/text';
import { AppFormItem, AppFormItemGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { useForm, useWatch } from 'antd/es/form/Form';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { useDisabledDate } from 'hooks/useDisabledDate/useDisabledDate';
import { useTimetableAbilities } from 'abilities';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { findEmployeeIdByUserId } from 'utils/findEmployeeIdByUserId';
import { lessonAddEditErrorParser } from 'utils/errorHandlers/lesson/lessonAddEditErrorParser';
import { OPTIONAL_FIELD_LABEL } from 'consts/labels/common';
import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

const addModeTexts: FormModeDependedTexts = {
    name: 'lessonAdd',
    title: timetableTexts.lessons.addToTimetable,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'lessonEdit',
    title: timetableTexts.lessons.editLessons,
    buttonText: commonTexts.actionLabels.save,
};

type LessonAddEditFormProps = Omit<
    AppFormProps<LessonFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (lessonData: LessonFormFields, affectNext: boolean) => void;
        lessonData?: LessonDTO;
    };

export const LessonAddEditForm = ({
    mode,
    axiosError,
    initialValues,
    onFinish,
    lessonData,
    form,
    ...props
}: LessonAddEditFormProps) => {
    const [selectedJournal, setSelectedJournal] = useState<{
        type: JournalType;
        startDate?: string;
        endDate?: string;
    }>();
    const [affectNext, setAffectNext] = useState(false);
    const [validatedFormData, setValidatedFormData] = useState<LessonFormFields>();
    const [isAffectNextModalVisible, setIsAffectNextModalVisible] = useState<boolean>(false);
    const [isConfirmEditModalVisible, setIsConfirmEditModalVisible] = useState<boolean>(false);
    const [formInstance] = useForm(form);
    const lessonFormData = useWatch<LessonFormFields | undefined>([], formInstance);
    const renderDisabledDate = useDisabledDate(selectedJournal);
    const { timetableAbilities } = useTimetableAbilities();
    const userDetails = useUserDetails();

    const canManageAllEmployeeTimetable = timetableAbilities.checkIfCanManageAllEmployeeTimetable();
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;
    const lessonAddEditError = axiosError ? lessonAddEditErrorParser(axiosError) : undefined;

    const { data: employeesData } = useAppQuery(
        'LESSONS_EMPLOYEES',
        [],
        StaffHTTPService.employeeSchedule.getLessonsEmployees,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (canManageAllEmployeeTimetable || formInstance.getFieldValue(formFields.teacherId)) {
            return;
        }
        const userEmployeeId = employeesData
            ? findEmployeeIdByUserId(userDetails.id, employeesData)
            : undefined;
        formInstance.setFieldValue(formFields.teacherId, userEmployeeId);
    }, [
        canManageAllEmployeeTimetable,
        employeesData,
        formInstance,
        lessonFormData?.teacherId,
        userDetails.id,
    ]);

    useEffect(() => {
        if (lessonData) {
            setSelectedJournal({ type: lessonData?.journal.type });
        }
    }, [lessonData]);

    const handleChangeJournal = (journalData: JournalDTO | undefined) => {
        setSelectedJournal(journalData);
        formInstance.setFieldValue(formFields.journalName, journalData?.name);
        formInstance.resetFields([
            formFields.childIds,
            formFields.startDate,
            formFields.finishDate,
        ]);
    };

    const handleChangeTeacher = () => {
        formInstance.resetFields([
            formFields.journalId,
            formFields.childIds,
            formFields.startDate,
            formFields.finishDate,
        ]);
    };

    const onFormFinish = (dataLesson: LessonFormFields) => {
        if (isAddMode) {
            onFinish(dataLesson, false);
        } else {
            setValidatedFormData(dataLesson);
            setIsAffectNextModalVisible(true);
        }
    };

    const onAffectNextModalConfirm = () => {
        setIsAffectNextModalVisible(false);
        setIsConfirmEditModalVisible(true);
    };

    const onConfirmEdit = () => {
        setIsConfirmEditModalVisible(false);
        onFinish(validatedFormData!, affectNext);
    };

    const isJournalPickerDisabled = mode === 'edit' || lessonFormData?.teacherId === undefined;

    const isJournalChildPickerDisabled =
        lessonFormData?.teacherId === undefined ||
        lessonFormData?.journalId === undefined ||
        lessonFormData.startDate === undefined ||
        lessonFormData.finishDate === undefined;

    const getDate = (field: 'startDate' | 'finishDate') => {
        if (mode === 'add') {
            if (lessonFormData?.[field]) {
                return parseDayjsDateIntoString(lessonFormData[field], 'YYYY-MM-DD');
            }
            return undefined;
        }
        return lessonData?.date;
    };

    const optionalLessonNamedAdditionalText = `${OPTIONAL_FIELD_LABEL} ${timetableTexts.lessons.lessonNameAdditionalText}`;

    return (
        <>
            <AppConfirmModal
                icon={<UserEdit style={{ color: colors.red.red6 }} />}
                title={timetableTexts.lessons.confirmEditLessonModalTitle}
                text={timetableTexts.lessons.confirmEditLessonModalDescription}
                open={isConfirmEditModalVisible}
                onOk={onConfirmEdit}
                onCancel={() => setIsConfirmEditModalVisible(false)}
                width={300}
                isMobileFullScreenMode={false}
                okButtonProps={{ title: commonTexts.actionLabels.confirm, danger: true }}
                cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
            />
            <EditAffectNextLessonsModal
                onConfirm={onAffectNextModalConfirm}
                onCancel={() => setIsAffectNextModalVisible(false)}
                open={isAffectNextModalVisible}
                affectNext={affectNext}
                setAffectNext={setAffectNext}
            />
            <AppForm<LessonFormFields>
                name={name}
                title={title}
                error={lessonAddEditError}
                withGoBack
                onFinish={onFormFinish}
                form={formInstance}
                {...props}
            >
                <TeachersPicker
                    label={commonTexts.actionLabels.addTeacher}
                    onChange={handleChangeTeacher}
                    disabled={mode === 'edit' || !canManageAllEmployeeTimetable}
                    fetchConfig={{
                        fetchFunction: StaffHTTPService.employeeSchedule.getLessonsEmployees,
                        fetchQueryKey: 'EMPLOYEES_LESSONS' as QueryMainKeysType,
                    }}
                />
                <AppFormItemGroup title={timetableTexts.lessons.addLessons}>
                    <SimpleInputElement
                        name={formFields.name}
                        label={commonTexts.dataLabels.lessonName}
                        optional
                        additionalText={optionalLessonNamedAdditionalText}
                    />
                    {/* dummy AppFormItem for send journalName into backend as a name when name is not provided  */}
                    <AppFormItem name={formFields.journalName} margin={0} />
                    <JournalPicker
                        onChangeJournal={handleChangeJournal}
                        onClear={() => handleChangeJournal(undefined)}
                        disabled={isJournalPickerDisabled}
                        teacherId={lessonFormData?.teacherId}
                    />
                    <AppFormItem justifyContent="space-between" doubleItem>
                        <SimpleDurationDatePicker
                            disabled={!isAddMode}
                            mode="start"
                            disabledDate={renderDisabledDate}
                        />
                        <SimpleDurationDatePicker
                            disabled={!isAddMode}
                            mode="finish"
                            disabledDate={renderDisabledDate}
                        />
                    </AppFormItem>
                    <AppFormItem justifyContent="space-between" doubleItem>
                        <SimpleDurationTimePicker mode="start" />
                        <SimpleDurationTimePicker mode="finish" />
                    </AppFormItem>
                    <JournalChildPicker
                        journalId={lessonFormData?.journalId || ('' as JournalId)}
                        dateFrom={getDate('startDate')}
                        dateTo={getDate('finishDate')}
                        label={journalTexts.journals.addChildren}
                        disabled={isJournalChildPickerDisabled}
                        multiple
                    />
                    <RepetitionPickerElement disabled={!isAddMode} />
                </AppFormItemGroup>
                <FormNavButtons
                    buttonText={buttonText}
                    leftIcon={buttonIcon}
                    withPlus={isAddMode}
                    withDeviceFloppy={!isAddMode}
                    formValuesBeforeChanges={initialValues}
                    marginTop={0}
                    marginBottom={0}
                />
            </AppForm>
        </>
    );
};
