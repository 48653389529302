import AxiosBaseInstance from 'services/HTTPService/AxiosBaseInstance';
import { InstitutionId, TokenExpiredDTO, UserAccountActiveDTO, UserBaseAuthDTO } from 'types';
import { ACTIVATE_USER, USER, USER_ACCOUNT_ACTIVE, VERIFY_TOKEN_EXPIRATION } from './endpoints';

class AuthHTTPService {
    private axios = new AxiosBaseInstance();

    public authentication = {
        getUser: (institutionId: InstitutionId | null) =>
            this.axios.get<UserBaseAuthDTO>(USER, {
                params: { ...(institutionId ? { institutionId } : {}) },
            }),
        getUserAccountActiveInfo: (email: UserBaseAuthDTO['email']) =>
            this.axios.get<UserAccountActiveDTO>(USER_ACCOUNT_ACTIVE, { params: { email } }),
        activateUser: ({ password, token }: { password: string; token: string }) =>
            this.axios.put(ACTIVATE_USER, { password, token }),
        verifyTokenExpiration: (token: string) =>
            this.axios.get<TokenExpiredDTO>(VERIFY_TOKEN_EXPIRATION, { params: { token } }),
    };
}

export default new AuthHTTPService();
