import { InvoiceDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';

export const parseInvoiceDTOIntoJsonObject: (invoiceDTO: InvoiceDTO) => object = (invoiceDTO) => {
    const { name, nip, street, zipCode, city } = invoiceDTO;

    return {
        [jsonObjectsTexts.invoiceDTO.fields.name]: name,
        [jsonObjectsTexts.invoiceDTO.fields.nip]: nip,
        [jsonObjectsTexts.invoiceDTO.fields.street]: street,
        [jsonObjectsTexts.invoiceDTO.fields.zipCode]: zipCode,
        [jsonObjectsTexts.invoiceDTO.fields.city]: city,
    };
};
