import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { commonTexts, menusTexts, reportTexts } from 'consts/text';
import dayjs from 'dayjs';
import { GroupId } from 'types';
import { handleNetworkError } from 'utils/handleNetworkError';
import { useState } from 'react';
import { fileDownload } from 'utils/fileDownload';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type MenusPageNetworkManageParams = {
    date: string;
    onMenuRemoveRequestFinish: () => void;
};

type MenusReportHttpParamsType = {
    year: number;
    month: number;
    groupsIds?: GroupId[];
};

export const useMenusPageNetworkManage = ({
    date,
    onMenuRemoveRequestFinish: onGroupArchiveRequestFinish,
}: MenusPageNetworkManageParams) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();
    const [isGenerateReportModalVisible, setIsGenerateReportModalVisible] = useState(false);
    const [isGenerateReportModalLoading, setIsGenerateReportModalLoading] = useState(false);

    const parsedDateIntoObject = (dateToParse: string) => {
        const month = dayjs(dateToParse).month() + 1;
        const year = dayjs(dateToParse).year();

        return {
            month,
            year,
        };
    };

    const {
        data: menusData,
        isInitialLoading: isMenusDataInitialLoading,
        isRefetching: isMenusDataRefetching,
        isFetching: isMenusDataFetching,
        isError: isMenusDataError,
    } = useAppQuery(
        'MENUS',
        [date],
        () => StaffHTTPService.menus.getMenus(parsedDateIntoObject(date)),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: removeMenu, isLoading: isRemoveMenuLoading } = useAppMutation(
        StaffHTTPService.menus.removeMenu,
        {
            key: ['REMOVE_MENU'],
            onSuccess: async (_, variables) => {
                onGroupArchiveRequestFinish();
                // variables is MenuId
                await queryClient.invalidateQueries([institutionId, 'MENU', variables]);
                displaySuccess(menusTexts.successfullyRemovedMessage);
            },
            onError: () => {
                onGroupArchiveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['MENUS']],
        },
    );

    const handleGenerateReport = async (
        chosenOption: 'giveAway' | 'catering',
        data?: GroupId[],
    ) => {
        setIsGenerateReportModalLoading(true);

        const reportData: MenusReportHttpParamsType = {
            year: dayjs(date).year(),
            month: dayjs(date).month() + 1,
        };

        try {
            await (chosenOption === 'catering'
                ? StaffHTTPService.menus.getCateringReport(reportData)
                : StaffHTTPService.menus.getGiveAwayReport({
                      ...reportData,
                      groupsIds: data || [],
                  })
            ).then((response) => {
                displaySuccess(menusTexts.successfullyCreatedRapport);
                const binaryData = [];
                binaryData.push(response.data);
                const blob = new Blob(binaryData, { type: 'application/pdf' });
                const href = URL.createObjectURL(blob);
                fileDownload(href, `Raport ${chosenOption}.pdf`);
                URL.revokeObjectURL(href);
            });
        } catch (error) {
            displayError(reportTexts.unableToFetchJournalReportError);
            handleNetworkError(error);
        } finally {
            setIsGenerateReportModalLoading(false);
            setIsGenerateReportModalVisible(false);
        }
    };

    return {
        menusData,
        isMenusDataInitialLoading,
        isMenusDataRefetching,
        isMenusDataError,
        isRemoveMenuLoading,
        removeMenu,
        isMenusDataFetching,
        isGenerateReportModalVisible,
        setIsGenerateReportModalVisible,
        isGenerateReportModalLoading,
        handleGenerateReport,
    };
};
