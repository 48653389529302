import styled, { css } from 'styled-components';
import { IconHelp } from '@tabler/icons-react';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { MarginsProps } from 'types/components/margins';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { AppTooltip } from '../AppTooltip/AppTooltip';

export type AppTooltipQuestionMarkIconProps = MarginsProps & {
    tooltip: string;
    size?: number;
    color?: string;
};

const StyledIconHelp = styled(IconHelp).withConfig({
    shouldForwardProp: getShouldForwardProp(marginPropsToFilter),
})<Omit<AppTooltipQuestionMarkIconProps, 'tooltip'>>`
    ${(props) => css`
        ${getMarginsCss(props)}
        display: flex;
        align-items: center;
    `}
`;

export const AppTooltipQuestionMarkIcon = ({
    tooltip,
    size = 20,
    ...props
}: AppTooltipQuestionMarkIconProps) => (
    <AppTooltip tooltip={tooltip}>
        <StyledIconHelp size={size} {...props} />
    </AppTooltip>
);
