import { AppButton } from 'components/atoms';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    Container,
    InfoNormal,
    InfoSmall,
    MainTitle,
    StyledError404Image,
    Subtitle,
} from './NotFound404.styled';

export const NotFound404 = () => {
    const { sizes } = useTheme();
    const navigate = useNavigate();

    return (
        <Container>
            <MainTitle margin={0} marginBottom={sizes.marginLarge}>
                404
            </MainTitle>
            <Subtitle margin={0} marginBottom={sizes.marginLarge}>
                Nie znaleziono strony
            </Subtitle>
            <StyledError404Image />
            <InfoSmall textAlign="center" margin={0} marginBottom={sizes.marginLarge}>
                Strona do której próbujesz się dostać nie istnieje albo została przeniesiona.
            </InfoSmall>
            <InfoNormal textAlign="center" margin={0} marginBottom={sizes.marginLarge}>
                Spróbuj przejść do strony głównej
            </InfoNormal>
            <AppButton onClick={() => navigate(appPaths.app.base)} type="primary">
                Strona główna
            </AppButton>
        </Container>
    );
};
