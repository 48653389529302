import { GroupConsultationDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseGroupConsultationChildDTOIntoJsonObject } from './parseGroupConsultationChildDTOIntoJsonObject';

export const parseGroupConsultationDTOIntoJsonObject: (
    groupConsultationDTO: GroupConsultationDTO,
) => object = (groupConsultationDTO) => {
    const { subject, date, description, childrenWithParents } = groupConsultationDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const childrenWithParentsJsonObjects = childrenWithParents.map(
        parseGroupConsultationChildDTOIntoJsonObject,
    );

    return {
        [jsonObjectsTexts.groupConsultationDTO.fields.subject]: subject,
        [jsonObjectsTexts.groupConsultationDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.groupConsultationDTO.fields.description]: description,
        [jsonObjectsTexts.groupConsultationDTO.fields.childrenWithParents]:
            childrenWithParentsJsonObjects,
    };
};
