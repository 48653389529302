import { colors } from 'theme/styledComponents/colors';
import { CalendarEventType } from 'types';

export const EventTypeMapper = {
    [CalendarEventType.HOLIDAY_AND_SPECIAL_EVENT]: {
        color: '#1E4131',
        backgroundColor: colors.green.green4,
        hoverBackgroundColor: colors.green.green5,
    },
    [CalendarEventType.SCHOOL_HOLIDAY]: {
        color: '#6F3710',
        backgroundColor: colors.yellow.yellow3,
        hoverBackgroundColor: colors.yellow.yellow4,
    },
    [CalendarEventType.OTHER]: {
        color: '#3456F3',
        backgroundColor: colors.primary.primary5,
        hoverBackgroundColor: colors.primary.primary6,
    },
};
