import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { JournalId } from 'types';
import { commonTexts, journalTexts } from 'consts/text/index';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type GroupJournalPsychologicalHelpsPageNetworkManageProps = {
    journalId: JournalId;
    onPsychologicalHelpRemoveRequestFinish: () => void;
};

export const useGroupJournalPsychologicalHelpsPageNetworkManage = ({
    journalId,
    onPsychologicalHelpRemoveRequestFinish,
}: GroupJournalPsychologicalHelpsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: psychologicalHelpsData,
        isInitialLoading: isPsychologicalHelpsDataInitialLoading,
        isRefetching: isPsychologicalHelpsDataRefetching,
        isError: isPsychologicalHelpsDataError,
    } = useAppQuery(
        'PSYCHOLOGICAL_HELPS',
        [journalId],
        () => StaffHTTPService.psychologicalHelps.getPsychologicalHelps(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: removePsychologicalHelp, isLoading: isRemovePsychologicalHelpLoading } =
        useAppMutation(StaffHTTPService.psychologicalHelps.removePsychologicalHelp, {
            key: ['REMOVE_PSYCHOLOGICAL_HELP', journalId],
            onSuccess: async (_, variables) => {
                onPsychologicalHelpRemoveRequestFinish();
                await queryClient.invalidateQueries([
                    institutionId,
                    'PSYCHOLOGICAL_HELP',
                    variables.journalId,
                    variables.psychologicalHelpId,
                ]);
                displaySuccess(
                    journalTexts.journalDetails.common.psychologicalHelp
                        .successfullyRemovedPsychologicalHelp,
                );
            },
            onError: () => {
                onPsychologicalHelpRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['PSYCHOLOGICAL_HELPS', journalId]],
        });

    return {
        psychologicalHelpsData,
        isPsychologicalHelpsDataInitialLoading,
        isPsychologicalHelpsDataRefetching,
        isPsychologicalHelpsDataError,
        isRemovePsychologicalHelpLoading,
        removePsychologicalHelp,
    };
};
