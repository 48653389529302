import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { commonTexts, groupsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type GroupsPageNetworkManageParams = {
    search: string;
    onGroupArchiveRequestFinish: () => void;
};

export const useGroupsPageNetworkManage = ({
    search,
    onGroupArchiveRequestFinish,
}: GroupsPageNetworkManageParams) => {
    const { id: institutionId } = useCurrentInstitution();
    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: groupsData,
        isInitialLoading: isGroupsDataInitialLoading,
        isRefetching: isGroupsDataRefetching,
        isError: isGroupsDataError,
    } = useAppQuery('GROUPS', [search], () => StaffHTTPService.groups.getGroups({ search }), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { mutate: archiveGroup, isLoading: isArchiveGroupLoading } = useAppMutation(
        StaffHTTPService.groups.archiveGroup,
        {
            key: ['GROUPS'],
            onSuccess: async (_, variables) => {
                onGroupArchiveRequestFinish();
                // variables is GroupId
                await queryClient.invalidateQueries([institutionId, 'GROUP', variables]);
                displaySuccess(groupsTexts.successfullyArchiveGroupMessage);
            },
            onError: () => {
                onGroupArchiveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [
                ['GROUPS'],
                ['MENUS_GROUPS'],
                ['JOURNALS_GROUPS'],
                ['MAIL_THREAD_RECEIVERS'],
            ],
        },
    );

    return {
        groupsData,
        isGroupsDataInitialLoading,
        isGroupsDataRefetching,
        isGroupsDataError,
        isOptionsLoading: isArchiveGroupLoading,
        isArchiveGroupLoading,
        archiveGroup,
    };
};
