export const psychologicalHelpDTOFields = {
    id: 'id',
    journalId: 'journalId',
    helpForm: 'helpForm',
    additionalInformation: 'additionalInformation',
    child: 'child',
    basis: 'basis',
    description: 'description',
    dateFrom: 'dateFrom',
    dateTo: 'dateTo',
    attachments: 'attachments',
} as const;
