import { AppFormItem, AppFormItemProps, AppPhoneInput } from 'components/atoms';
import { formElementRequiredText } from 'consts/errors/forms';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { formFields } from 'consts/form/formFields';
import { Rule } from 'rc-field-form/lib/interface';
import { isValidPhoneNumberLength } from 'utils/isValidPhoneNumberLength';

export type PhoneNumberInputElementProps = AppFormItemProps & {
    optional?: boolean;
};

export const PhoneNumberInputElement = ({
    optional = false,
    ...props
}: PhoneNumberInputElementProps) => {
    const mainValidators: Rule[] = [
        {
            validator: (_, value) => {
                const isPossibleNumber = value && isPossiblePhoneNumber(value, 'PL');
                const isNumberCorrect = isPossibleNumber && isValidPhoneNumberLength(value);
                const isInputEmpty = !value;
                const isValidationCorrect = optional
                    ? isInputEmpty || isNumberCorrect
                    : isNumberCorrect;

                return isValidationCorrect
                    ? Promise.resolve()
                    : Promise.reject(new Error('Numer jest niepoprawny'));
            },
        },
    ];

    if (!optional) {
        mainValidators.push({ required: true, message: formElementRequiredText });
    }
    return (
        <AppFormItem
            label="Numer telefonu"
            optional={optional}
            hasOptionalText={optional}
            name={formFields.phoneNumber}
            rules={mainValidators}
            {...props}
        >
            <AppPhoneInput autoComplete="tel-national" />
        </AppFormItem>
    );
};
