import { IconDeviceFloppy } from '@tabler/icons-react';
import { useEffect } from 'react';
import { childAddEditErrorParser } from 'utils/errorHandlers/child/childAddEditErrorParser';
import { AppForm, AppFormProps, DietPicker } from 'components/organisms';
import { AppFormItem, AppFormItemGroup } from 'components/atoms';
import { childrenTexts, commonTexts } from 'consts/text';
import {
    AllergensInputElement,
    CityInputElement,
    DiseasesInputElement,
    FormNavButtons,
    SocialNetworksImageSharingAgreementPicker,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { EditChildForParentFormFields } from 'types';
import { AxiosError } from 'axios';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';

type ChildEditFormProps = Pick<
    AppFormProps<EditChildForParentFormFields>,
    'onFinish' | 'isLoading'
> & {
    axiosError?: AxiosError | null;
    initialValues: EditChildForParentFormFields | null;
};

export const ChildEditForParentForm = ({
    initialValues,
    axiosError,
    isLoading,
    onFinish,
}: ChildEditFormProps) => {
    const { form, setFormData, formInitialData } = useEditForm<EditChildForParentFormFields>();

    const childEditError = axiosError ? childAddEditErrorParser(axiosError) : '';

    useEffect(() => {
        if (!initialValues) {
            return;
        }

        setFormData(initialValues);
    }, [initialValues, setFormData]);

    return (
        <AppForm
            name="editChild"
            form={form}
            title={childrenTexts.editChildrenButtonLabel}
            desktopTitleLevel={4}
            isFormHeaderAtBottom
            error={childEditError}
            onFinish={onFinish}
            isLoading={isLoading}
            maxWidth={695}
        >
            <AppFormItemGroup title={childrenTexts.childForm.addressData.addressData}>
                <StreetAddressInputElement optional />
                <AppFormItem justifyContent="space-between" doubleItem>
                    <ZipCodeInputElement optional />
                    <CityInputElement optional />
                </AppFormItem>
            </AppFormItemGroup>
            <DietPicker httpMethod={ParentHTTPService.diets} />
            <DiseasesInputElement optional />
            <AllergensInputElement optional />
            <SocialNetworksImageSharingAgreementPicker />
            <FormNavButtons
                formValuesBeforeChanges={formInitialData}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
