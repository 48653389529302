import debounce from 'lodash/debounce';
import { AppConfirmModal, AppTable, AppTableProps } from 'components/organisms';
import { EmployeeId, EmployeeUser } from 'types/employee/employeeUser';
import { employeeUserFields } from 'consts/tables/employee/employeeUserFields';
import { RoleDisplay, EmployeeGroupsDisplay, AccountActiveDisplay } from 'components/molecules';
import { IconEdit, IconMailForward, IconTrash } from '@tabler/icons-react';
import { generatePath } from 'react-router-dom';
import { appPaths, appRoutes } from 'consts/paths/paths';
import { useState } from 'react';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { UserDeleteIcon } from 'assets';
import { getFullName } from 'utils/getFullName';
import { Option } from 'components/atoms';
import { employeeTexts, commonTexts } from 'consts/text';
import { useEmployeeAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useEmployeesPageNetworkManage } from './hooks/useEmployeesPageNetworkManage';

const columns: AppTableProps<EmployeeUser>['columns'] = [
    {
        title: 'Nazwisko i imię',
        dataIndex: employeeUserFields.lastName,
        key: employeeUserFields.lastName,
        isVisibleAsExtendableInMobile: false,
        render: (_, record) => getFullName(record.firstName, record.lastName),
    },
    {
        title: 'Rola',
        dataIndex: employeeUserFields.role,
        key: employeeUserFields.role,
        render: (role: EmployeeUser['role']) => <RoleDisplay role={role} />,
        align: 'center',
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: 'Grupy',
        dataIndex: employeeUserFields.groups,
        key: employeeUserFields.groups,
        render: (groups: EmployeeUser['groups']) => <EmployeeGroupsDisplay groups={groups} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: 'Adres e-mail',
        dataIndex: employeeUserFields.email,
        key: employeeUserFields.email,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: 'Numer telefonu',
        dataIndex: employeeUserFields.phoneNumber,
        key: employeeUserFields.phoneNumber,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: 'Konto',
        dataIndex: employeeUserFields.active,
        key: employeeUserFields.active,
        render: (isAccountActive: EmployeeUser['active']) => (
            <AccountActiveDisplay isAccountActive={isAccountActive} />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const EmployeesPage = () => {
    const [search, setSearch] = useState('');
    const [deleteEmployeeIdRequest, setDeleteEmployeeIdRequest] = useState<EmployeeId>();
    const { statefulNavigate } = useNavigateWithState();
    const { employeeAbilities } = useEmployeeAbilities();

    const canManageEmployeeList = employeeAbilities.checkIfCanManageEmployeeList();

    const onEmployeeRemoveRequestFinish = () => {
        setDeleteEmployeeIdRequest(undefined);
    };

    const {
        isInitialLoading,
        isRefetching,
        isError,
        sendActivationEmail,
        employeesData,
        removeEmployee,
        isOptionLoading,
        isRemoveEmployeeLoading,
    } = useEmployeesPageNetworkManage({ search, onEmployeeRemoveRequestFinish });

    const options: Option<EmployeeUser>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: ({ employeeId }) =>
                statefulNavigate(
                    generatePath(appPaths.app.employees.edit.pickedEmployee, { employeeId }),
                ),
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.retryResendEmail,
            onClick: ({ userId }) => sendActivationEmail(userId),
            checkIfVisible: ({ active }) => !active,
            Icon: <IconMailForward />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: ({ employeeId }) => setDeleteEmployeeIdRequest(employeeId),
            Icon: <IconTrash />,
        },
    ];

    const onAddEmployee = () => statefulNavigate(appRoutes.app.employees.add);

    const onEmployeeRemoveConfirmation = () => {
        if (!deleteEmployeeIdRequest) {
            return;
        }
        removeEmployee(deleteEmployeeIdRequest);
    };

    return (
        <>
            <AppConfirmModal
                open={!!deleteEmployeeIdRequest}
                title={employeeTexts.confirmDeleteEmployee}
                icon={<UserDeleteIcon />}
                okButtonProps={{ title: commonTexts.actionLabels.confirm, danger: true }}
                cancelButtonProps={{ title: 'Nie' }}
                onOk={onEmployeeRemoveConfirmation}
                onCancel={() => setDeleteEmployeeIdRequest(undefined)}
                confirmActionLoading={isRemoveEmployeeLoading}
            />
            <AppTable
                title="Lista pracowników"
                addButtonLabel={employeeTexts.addEmployeeButtonLabel}
                options={canManageEmployeeList ? options : undefined}
                isOptionsLoading={isOptionLoading}
                isHiddenAddButton={!canManageEmployeeList}
                onAddButtonClick={onAddEmployee}
                isError={isError}
                columns={columns}
                rowKey={({ userId }) => userId}
                emptyInfo={employeeTexts.emptyEmployeeList}
                dataSource={employeesData}
                isDataInitialLoading={isInitialLoading}
                isDataRefetching={isRefetching}
                tableManageUtilsProps={{
                    searchInputProps: { onInput: debounce(setSearch, DEBOUNCE_DELAY) },
                }}
            />
        </>
    );
};
