import { AxiosError } from 'axios';
import { AppModalForm, AppModalFormProps } from 'components/organisms';
import { ParentAbsenceFormFields, ParentJournalDatesDTO } from 'types';
import { commonTexts, parentTexts } from 'consts/text';
import { AppFormItem } from 'components/atoms';
import {
    AppModal,
    AppModalProps,
    FormNavButtons,
    SimpleDurationDatePicker,
} from 'components/molecules';

import { formFields } from 'consts/form/formFields';
import { absenceAddDeleteErrorParser } from 'utils/errorHandlers/absence/absenceAddDeleteErrorParser';
import { useDisabledDate } from 'hooks/useDisabledDate/useDisabledDate';

type ParentAbsenceAddModalProps = Required<Pick<AppModalProps, 'open' | 'onCancel'>> &
    Omit<
        AppModalFormProps<ParentAbsenceFormFields>,
        'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack' | 'form' | 'initialValues'
    > & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (parentAbsenceFormFields: ParentAbsenceFormFields) => void;
        journalDates?: ParentJournalDatesDTO;
    };

const startDateFormItemData = {
    name: formFields.startDate,
    label: parentTexts.groupAttendance.addAbsenceForm.dateFrom,
};

const endDateFormItemData = {
    name: formFields.endDate,
    label: parentTexts.groupAttendance.addAbsenceForm.dateFrom,
};

const MODAL_WIDTH = 645;

export const ParentAbsenceAddModal = ({
    open,
    axiosError,
    isLoading,
    onCancel,
    onFinish,
    journalDates,
    ...restProps
}: ParentAbsenceAddModalProps) => {
    const renderDisabledDate = useDisabledDate(journalDates);

    const absenceAddError = axiosError
        ? absenceAddDeleteErrorParser(axiosError, commonTexts.errorMessages.unknown)
        : '';

    return (
        <AppModal
            open={open}
            title={parentTexts.groupAttendance.addAbsenceForm.title}
            width={MODAL_WIDTH}
            isLoading={isLoading}
            onCancel={onCancel}
        >
            <AppModalForm<ParentAbsenceFormFields>
                name="absenceAdd"
                error={absenceAddError}
                onFinish={onFinish}
                {...restProps}
            >
                <AppFormItem justifyContent="space-between" doubleItem>
                    <SimpleDurationDatePicker
                        mode="start"
                        startFormItemData={startDateFormItemData}
                        finishFormItemData={endDateFormItemData}
                        disabledDate={renderDisabledDate}
                    />
                    <SimpleDurationDatePicker
                        mode="finish"
                        startFormItemData={startDateFormItemData}
                        finishFormItemData={endDateFormItemData}
                        disabledDate={renderDisabledDate}
                    />
                </AppFormItem>
                <FormNavButtons
                    buttonText={commonTexts.actionLabels.add}
                    withPlus
                    marginBottom={0}
                />
            </AppModalForm>
        </AppModal>
    );
};
