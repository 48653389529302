import { AppButton, AppButtonProps } from 'components/atoms/CommonAppComponents';
import { StyledPlusIcon } from 'components/atoms/StyledIcons';
import { messagesTexts } from 'consts/text';

type NewMailThreadButtonProps = Pick<AppButtonProps, 'onClick'> & {
    marginTop?: number;
    marginBottom?: number;
};

export const NewMailThreadButton = ({
    marginTop,
    marginBottom,
    onClick,
}: NewMailThreadButtonProps) => (
    <AppButton
        type="primary"
        margin={0}
        marginTop={marginTop}
        marginBottom={marginBottom}
        icon={<StyledPlusIcon />}
        fullWidth
        onClick={onClick}
    >
        {messagesTexts.newMessage}
    </AppButton>
);
