import { useContext } from 'react';
import { SubscriptionAddEditModal } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import AdminHTTPService from 'services/HTTPService/admin/AdminHTTPService';
import { subscriptionsTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { SubscriptionFormFields } from 'types';
import { AppInstitutionSubscriptionContext } from 'contexts/AppInstitutionSubscriptionContext';
import { parseSubscriptionFormFieldsIntoCreateSubscriptionDTO } from 'utils/parsers/subscription/parseSubscriptionFormFieldsIntoCreateSubscriptionDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const SubscriptionAddPage = () => {
    const { institutionSubscriptionDataItem } = useContext(AppInstitutionSubscriptionContext);
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    // TODO: use custom hook to redirect with search params
    const navigateToInstitutionsBase = () => statefulNavigate(appPaths.app.admin.institutions.base);

    const handleAddSubscriptionSuccess = () => {
        displaySuccess(subscriptionsTexts.list.actionMessages.successfullyAddSubscription);
        navigateToInstitutionsBase();
    };

    const {
        mutate: addSubscription,
        error: addSubscriptionError,
        isLoading: isAddSubscriptionLoading,
    } = useAppMutation(AdminHTTPService.clientSubscriptions.addSubscription, {
        key: ['ADD_SUBSCRIPTION'],
        onSuccess: handleAddSubscriptionSuccess,
        invalidateQueryKeys: [
            ['INSTITUTION_SUBSCRIPTION_DATA_ITEMS'],
            [
                'INSTITUTION_SUBSCRIPTION_DATA_ITEM',
                institutionSubscriptionDataItem?.subscriptionBasicData.id,
            ],
        ],
    });

    const handleAddSubscriptionFinish = (subscriptionFormFields: SubscriptionFormFields) => {
        const institutionId = institutionSubscriptionDataItem?.institution.id;
        if (!institutionId) {
            return;
        }
        const parsedCreateSubscriptionDTO =
            parseSubscriptionFormFieldsIntoCreateSubscriptionDTO(subscriptionFormFields);
        addSubscription({
            createSubscriptionDTO: parsedCreateSubscriptionDTO,
            institutionId,
        });
    };

    return (
        <SubscriptionAddEditModal
            open
            mode="add"
            institutionName={institutionSubscriptionDataItem?.institution.name}
            activeChildren={institutionSubscriptionDataItem?.subscriptionBasicData.activeChildren}
            axiosError={addSubscriptionError}
            isFetchDataError={!institutionSubscriptionDataItem}
            isLoading={isAddSubscriptionLoading}
            onCancel={navigateToInstitutionsBase}
            onFinish={handleAddSubscriptionFinish}
        />
    );
};
