import { useContext, useMemo } from 'react';
import { UserRolePickerElement } from 'components/molecules';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { AppFormContext } from 'contexts/AppFormContext';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { parseRoleIntoSelectOptionType } from 'utils/parsers/institution/parseRoleIntoSelectOptionType';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

const fetchingChildGroupsDataError =
    'Nie udało się pobrać danych o rolach pracowników, odśwież stronę i spróbuj ponownie';

export const UserRolePicker = () => {
    const { formError, setFormError } = useContext(AppFormContext);

    const { data, isFetching, isError } = useAppQuery(
        'ROLES',
        [],
        () => StaffHTTPService.roles.getRoles(),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useDisplayFetchDataError({
        isFetching,
        isError,
        actualError: formError,
        errorMessage: fetchingChildGroupsDataError,
        setError: setFormError,
    });

    const rolesOptions = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(parseRoleIntoSelectOptionType);
    }, [data]);

    return <UserRolePickerElement options={rolesOptions} isLoading={isFetching} />;
};
