import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { SelectOptionType, RoleId } from 'types';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';

export type UserRolePickerElementProps = AppFormItemProps & {
    options?: SelectOptionType<RoleId>[];
    isLoading?: boolean;
};

export const UserRolePickerElement = ({
    options,
    isLoading,
    ...props
}: UserRolePickerElementProps) => (
    <AppFormItem
        label="Rola pracownika"
        rules={[{ required: true, message: formElementRequiredText }]}
        name={formFields.employeeRole}
        {...props}
    >
        <AppSelect options={options} loading={isLoading} />
    </AppFormItem>
);
