export const settlementChildrenDTOFields = {
    child: 'child',
    lastName: 'lastName',
    firstName: 'firstName',
    mainGroup: 'mainGroup',
    id: 'id',
    settlementPeriodEnd: 'settlementPeriodEnd',
    stayCost: 'stayCost',
    mealCost: 'mealCost',
    lessonsCost: 'lessonsCost',
    balance: 'balance',
    totalCost: 'totalCost',
    institutionId: 'institutionId',
} as const;

export const settlementChildrenBillComponentDTOFields = {
    name: 'name',
    amountString: 'amountString',
    discountString: 'discountString',
    children: 'children',
};

export const settlementChildPaymentDTOFields = {
    date: 'date',
    transactionType: 'transactionType',
    paymentFormType: 'paymentFormType',
    amount: 'amount',
};
