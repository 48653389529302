import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SideMenu } from 'components/molecules';
import styled, { css, useTheme } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { useMediaQuery } from 'react-responsive';
import { SideMenuBreadcrumb } from 'components/atoms';
import { AppReminderModal } from 'components/organisms';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { CentralIslandLayout } from '../CentralIslandLayout/CentralIslandLayout';

type SideMenuLayoutProps = {
    withoutInnerLayout?: boolean;
};

const OutletContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['sideBarCollapsed']),
})<{ sideBarCollapsed: boolean }>`
    ${({ theme, sideBarCollapsed }) => css`
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            padding-top: ${theme.sizes.sideMenu.mobileUpperNavBarHeight}px;
            background-color: ${theme.colors.grayscale.gray1};
        }
        @media (${theme.sizes.mediaQueries.desktopMin}) {
            padding-left: ${sideBarCollapsed
                ? theme.sizes.sideMenu.sideBarCollapsedWidth
                : theme.sizes.sideMenu.sideBarWidth}px;
        }
    `}
`;

export const SideMenuLayout = ({ withoutInnerLayout }: SideMenuLayoutProps) => {
    const theme = useTheme();
    const userDetails = useUserDetails();
    const { id } = useCurrentInstitution();

    const [reminderOpened, setReminderOpen] = useState<boolean | undefined>(false);

    useEffect(() => {
        setReminderOpen(userDetails?.shouldShowPaymentReminder && !userDetails.institutionBlocked);
    }, [id, userDetails?.shouldShowPaymentReminder, userDetails.institutionBlocked]);

    const isInitialCollapsed = useMediaQuery({
        query: `(max-width: ${theme?.sizes.sideMenu.autoCollapseWidth}px)`,
    });
    const [sideBarCollapsed, setSideBarCollapsed] = useState(isInitialCollapsed);

    const toggleSideBarCollapsed = () => setSideBarCollapsed((prevState) => !prevState);

    return (
        <>
            <SideMenu
                collapsed={sideBarCollapsed}
                toggleCollapsed={toggleSideBarCollapsed}
                setSideBarCollapsed={setSideBarCollapsed}
            />
            <OutletContainer sideBarCollapsed={sideBarCollapsed}>
                {reminderOpened && <AppReminderModal onCancel={() => setReminderOpen(false)} />}
                {withoutInnerLayout ? (
                    <Outlet />
                ) : (
                    <>
                        <SideMenuBreadcrumb />
                        <CentralIslandLayout stickToTop fillSpaceWithIsland>
                            <Outlet />
                        </CentralIslandLayout>
                    </>
                )}
            </OutletContainer>
        </>
    );
};
