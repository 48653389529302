import { forwardRef, LegacyRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { CalendarOptions } from '@fullcalendar/core';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import calendarStyles from './StyledCalendar.css';

export const StyledCalendar = forwardRef(({ ...props }: CalendarOptions, ref) => {
    const isMobile = useIsMobile();

    return (
        <FullCalendar
            ref={ref as LegacyRef<FullCalendar>}
            plugins={[dayGridPlugin, interactionPlugin]}
            dayHeaderClassNames={calendarStyles}
            locale={['pl']}
            initialView="dayGridMonth"
            headerToolbar={false}
            viewClassNames="view"
            dayCellClassNames="dayCell"
            allDayClassNames="allDay"
            fixedWeekCount={false}
            height={isMobile ? 250 : 700}
            contentHeight="auto"
            views={{
                dayGrid: {
                    dayMaxEvents: isMobile ? 0 : 3,
                },
            }}
            {...props}
        />
    );
});
