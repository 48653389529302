import { CreateSubscriptionDTO, SubscriptionFormFields } from 'types';
import { parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO } from './parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseSubscriptionFormFieldsIntoCreateSubscriptionDTO: (
    subscriptionFormFields: SubscriptionFormFields,
) => CreateSubscriptionDTO = (subscriptionFormFields) => {
    const { periodStart } = subscriptionFormFields;

    const parsedUpdateSubscriptionDTO =
        parseSubscriptionFormFieldsIntoUpdateSubscriptionDTO(subscriptionFormFields);
    const parsedPeriodStart = parseDayjsDateIntoString(periodStart, 'YYYY-MM-DD');

    return {
        periodStart: parsedPeriodStart,
        ...parsedUpdateSubscriptionDTO,
    };
};
