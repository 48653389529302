import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useForm from 'antd/es/form/hooks/useForm';
import styled from 'styled-components';
import { isAxiosError } from 'axios';
import { MailThreadId, MessageFormFields } from 'types';
import { RemoveModal } from 'components/organisms';
import { messagesTexts } from 'consts/text';
import { PageBaseLayout } from 'components/layouts';
import { MailThreadOverview } from 'components/templates';
import { appPaths } from 'consts/paths/paths';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useMailThreadDetailsPageNetworkManage } from './hooks/useMailThreadDetailsPageNetworkManage';

const StyledPageBaseLayout = styled(PageBaseLayout)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const MailThreadDetailsPage = () => {
    const [messageAddFormInstance] = useForm<MessageFormFields>();
    const [removeModalOpened, setRemoveModalOpened] = useState(false);
    const mailThreadId = useParams<{ mailThreadId: MailThreadId }>().mailThreadId as MailThreadId;
    const { statefulNavigate } = useNavigateWithState();
    const messageListRef = useRef<HTMLDivElement>(null);

    const handleMailThreadRemoveRequestFinish = (isSuccess: boolean) => {
        setRemoveModalOpened(false);
        if (isSuccess) {
            statefulNavigate(appPaths.app.messages.base);
        }
    };

    const handleMessageAddRequestSuccess = () => {
        messageAddFormInstance.resetFields();
        messageListRef.current?.scrollTo({ top: 0, behavior: 'instant' });
    };

    const {
        mailThreadData,
        replyMailThreadId,
        messagesData,
        replyMailThreadDataError,
        removeMailThread,
        addMessage,
        isMailThreadDataInitialLoading,
        isReplyMailThreadDataInitialLoading,
        isMessagesDataInitialLoading,
        isMailThreadDataRefetching,
        isReplyMailThreadDataRefetching,
        isMessagesDataRefetching,
        isAddMessageLoading,
        isRemoveMailThreadLoading,
        isMailThreadDataError,
        isMessagesDataError,
        hasNextMessagesDataPage,
        fetchNextMessagesDataPage,
    } = useMailThreadDetailsPageNetworkManage({
        mailThreadId,
        onMailThreadRemoveRequestFinish: handleMailThreadRemoveRequestFinish,
        onMessageAddRequestSuccess: handleMessageAddRequestSuccess,
    });

    const handleOpenRemoveModal = () => setRemoveModalOpened(true);

    const handleCloseRemoveModal = () => setRemoveModalOpened(false);

    const handleRemoveMailThreadConfirm = () => removeMailThread(mailThreadId);

    const handleSendMessageFormFinish = (messageData: MessageFormFields) =>
        addMessage({ mailThreadId, addMessageDTO: messageData });

    const isReplyMailThreadNotFoundError =
        isAxiosError(replyMailThreadDataError) &&
        replyMailThreadDataError.response?.data.code === apiErrorCodes.REPLY_MAIL_THREAD_NOT_FOUND;
    const isReplyMailThreadDataError =
        !!replyMailThreadDataError && !isReplyMailThreadNotFoundError;
    const isDataInitialLoading =
        isMailThreadDataInitialLoading ||
        isReplyMailThreadDataInitialLoading ||
        isMessagesDataInitialLoading;
    const isDataRefetching =
        isMailThreadDataRefetching || isReplyMailThreadDataRefetching || isMessagesDataRefetching;
    const isError = isMailThreadDataError || isReplyMailThreadDataError || isMessagesDataError;

    return (
        <>
            <RemoveModal
                open={removeModalOpened}
                title={messagesTexts.removeModal.title}
                text={messagesTexts.removeModal.text}
                confirmActionLoading={isRemoveMailThreadLoading}
                onOk={handleRemoveMailThreadConfirm}
                onCancel={handleCloseRemoveModal}
            />
            <StyledPageBaseLayout
                isDataInitialLoading={isDataInitialLoading}
                isDataRefetching={isDataRefetching}
                isError={isError}
            >
                {mailThreadData && messagesData && (
                    <MailThreadOverview
                        mailThread={mailThreadData}
                        replyMailThreadId={replyMailThreadId}
                        messages={messagesData}
                        addMessageFormInstance={messageAddFormInstance}
                        messageListRef={messageListRef}
                        isAddMessageLoading={isAddMessageLoading}
                        hasNextMessagesPage={hasNextMessagesDataPage}
                        fetchNextMessagesPageFunction={fetchNextMessagesDataPage}
                        onSendMessageFormFinish={handleSendMessageFormFinish}
                        onOpenRemoveModal={handleOpenRemoveModal}
                    />
                )}
            </StyledPageBaseLayout>
        </>
    );
};
