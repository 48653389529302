import styled, { css } from 'styled-components';

export const BorderedContainerStyled = styled.div`
    ${({ theme }) => css`
        width: 100%;
        border: 1px solid ${theme.colors.primary.primary5};
        border-radius: ${theme.borders.borderRadiusNormal}px;
        padding: ${theme.sizes.marginNormal}px;
    `}
`;
