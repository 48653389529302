import { generatePath } from 'react-router-dom';
import { ParentTeacherConferenceAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { journalTexts } from 'consts/text';
import { ParentTeacherConferenceFormFields } from 'types';
import { parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO } from 'utils/parsers/parentTeacherConference/parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalParentTeacherConferenceAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const handleAddParentTeacherConferenceSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                .successfullyAddedParentTeacherConference,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events
                    .parentTeacherConferences.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addParentTeacherConference,
        error: addParentTeacherConferenceError,
        isLoading: isAddParentTeacherConferenceLoading,
    } = useAppMutation(StaffHTTPService.parentTeacherConferences.addParentTeacherConference, {
        key: ['ADD_PARENT_TEACHER_CONFERENCE'],
        onSuccess: handleAddParentTeacherConferenceSuccess,
        invalidateQueryKeys: [['PARENT_TEACHER_CONFERENCES', journalId]],
    });

    const handleAddParentTeacherConferenceFinish = (
        parentTeacherConferenceFormData: ParentTeacherConferenceFormFields,
    ) => {
        const parsedAddParentTeacherConferenceDTO =
            parseParentTeacherConferenceFormFieldsIntoAddUpdateParentTeacherConferenceDTO(
                parentTeacherConferenceFormData,
            );
        addParentTeacherConference({
            journalId,
            addParentTeacherConferenceDTO: parsedAddParentTeacherConferenceDTO,
        });
    };

    return (
        <ParentTeacherConferenceAddEditForm
            journalId={journalId}
            mode="add"
            axiosError={addParentTeacherConferenceError}
            isLoading={isAddParentTeacherConferenceLoading}
            onFinish={handleAddParentTeacherConferenceFinish}
        />
    );
};
