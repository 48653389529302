import { Breadcrumb, BreadcrumbProps } from 'antd';
import styled from 'styled-components';
import { BreadcrumbArrowIcon } from 'assets';

export type AppBreadcrumbProps = BreadcrumbProps;

const StyledBreadcrumb = styled(Breadcrumb)<AppBreadcrumbProps>`
    margin-top: 24px;
    margin-left: 16px;
`;

const StyledBreadcrumbArrowIcon = styled(BreadcrumbArrowIcon)`
    margin: 0 -6px;
`;

export const AppBreadcrumb = ({ ...props }: AppBreadcrumbProps) => (
    <StyledBreadcrumb separator={<StyledBreadcrumbArrowIcon />} {...props} />
);
