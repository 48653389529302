import { useEffect } from 'react';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { settingsTexts } from 'consts/text';
import { InvoiceEditFormFields } from 'types/forms/invoice';
import { InvoiceEditForm } from 'components/templates';
import { PageBaseLayout } from 'components/layouts';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parseInvoiceDTOIntoInvoiceEditFormFields } from 'utils/parsers/invoice/parseInvoiceDTOIntoInvoiceEditFormFields';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const InvoiceDetailsEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<InvoiceEditFormFields>();

    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const onSuccessNavigate = () => statefulNavigate(appPaths.app.settings['invoice-details'].base);

    const onSuccess = () => {
        onSuccessNavigate();
        displaySuccess(settingsTexts.invoiceData.successfullyEditedInvoiceDataMessage);
    };

    const {
        data: invoiceData,
        isFetching: isInvoiceDataFetching,
        isError: isInvoiceDataError,
    } = useAppQuery('SETTINGS_INVOICE_DATA', [], StaffHTTPService.invoiceData.getInvoiceData, {
        staleTime: FIVE_MINS_IN_MILLIS,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (invoiceData) {
            setFormData(parseInvoiceDTOIntoInvoiceEditFormFields(invoiceData));
        }
    }, [invoiceData, form, setFormData]);

    const {
        mutate: editInvoiceDetails,
        error: editErrorInvoiceDetailsError,
        isLoading: isLoadingEditInvoiceDetails,
    } = useAppMutation(StaffHTTPService.invoiceData.updateInvoiceData, {
        key: ['EDIT_INVOICE_DETAILS'],
        onSuccess,
        invalidateQueryKeys: [['SETTINGS_INVOICE_DATA']],
    });

    const onFinish = (fields: InvoiceEditFormFields) => editInvoiceDetails(fields);

    return (
        <PageBaseLayout isError={isInvoiceDataError}>
            <InvoiceEditForm
                isLoading={isLoadingEditInvoiceDetails || isInvoiceDataFetching}
                axiosError={editErrorInvoiceDetailsError}
                onFinish={onFinish}
                maxWidth={440}
                form={form}
                initialValues={formInitialData}
            />
        </PageBaseLayout>
    );
};
