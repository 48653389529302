import {
    AppCheckbox,
    AppFormItem,
    AppInput,
    AppTextArea,
    AppTooltipQuestionMarkIcon,
} from 'components/atoms';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { AppModalForm, AppModalFormProps } from 'components/organisms';
import {
    AppModal,
    DayPeriodPickerElement,
    EventTypePickerElement,
    FormHeader,
    FormNavButtons,
} from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { calendarTexts, commonTexts } from 'consts/text';
import { CommonFormProps, FormModeDependedTexts } from 'types';
import { HeaderContainer } from 'components/organisms/AppForm/AppForm.styled';
import { appPaths } from 'consts/paths/paths';
import { EventFormFields } from 'types/forms/event';
import styled, { css } from 'styled-components';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { AppCalendarContext } from 'contexts/AppCalendarContext';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { max3000LengthRule, max300LengthRule } from 'consts/rules/textRules';

export type EventAddEditFormProps = Omit<
    AppModalFormProps<EventFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (eventDate: EventFormFields) => void;
    };

const addModeTexts: FormModeDependedTexts = {
    name: 'eventAdd',
    title: calendarTexts.form.addTitle,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'eventEdit',
    title: calendarTexts.form.editTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

const AppTooltipStyled = styled(AppTooltipQuestionMarkIcon)`
    ${({ theme }) => css`
        color: ${theme.colors.primary.primary9};
        margin-block-end: -7px;
    `}
`;

const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const EventAddEditModal = ({
    mode,
    initialValues,
    axiosError,
    ...props
}: EventAddEditFormProps) => {
    const { statefulNavigate } = useNavigateWithState();
    const { selectedDate } = useContext(AppCalendarContext);

    const initialDate = dayjs(selectedDate);

    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const eventAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppModal open onCancel={() => statefulNavigate(appPaths.app.calendar.base)}>
            <AppModalForm<EventFormFields> name={name} {...props}>
                <HeaderContainer isFormHeaderAtBottom={false}>
                    <FormHeader title={title} desktopTitleLevel={4} error={eventAddEditError} />
                </HeaderContainer>
                <EventTypePickerElement />
                <MobileRow>
                    <AppFormItem
                        valuePropName="checked"
                        name={formFields.freeFromSchool}
                        marginBottom={12}
                        optional
                        hasOptionalText
                    >
                        <AppCheckbox value>{calendarTexts.form.freeFromSchool}</AppCheckbox>
                    </AppFormItem>
                    <AppTooltipStyled tooltip={calendarTexts.form.freeFromSchoolInfo} />
                </MobileRow>
                <AppFormItem
                    label={calendarTexts.form.name}
                    name={formFields.name}
                    rules={[max300LengthRule]}
                >
                    <AppInput autoComplete="off" />
                </AppFormItem>
                <AppFormItem justifyContent="space-between" doubleItem>
                    <DayPeriodPickerElement
                        mode="start"
                        endField={formFields.endDate}
                        startField={formFields.startDate}
                        label={commonTexts.dataLabels.dateFrom}
                        initialValue={initialDate}
                    />
                    <DayPeriodPickerElement
                        mode="finish"
                        endField={formFields.endDate}
                        startField={formFields.startDate}
                        label={commonTexts.dataLabels.dateTo}
                        initialValue={initialDate}
                    />
                </AppFormItem>
                <AppFormItem
                    label={calendarTexts.form.description}
                    name={formFields.description}
                    rules={[max3000LengthRule]}
                >
                    <AppTextArea />
                </AppFormItem>
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={buttonText}
                    leftIcon={buttonIcon}
                    withPlus={isAddMode}
                />
            </AppModalForm>
        </AppModal>
    );
};
