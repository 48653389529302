import { ChildId } from 'types';

const CHILD_ID_LOCAL_STORAGE_KEY = 'CHILD_ID';

export const writeLocalStorageChildId = (childId: ChildId) =>
    localStorage.setItem(CHILD_ID_LOCAL_STORAGE_KEY, childId);

export const readLocalStorageChildId = () => localStorage.getItem(CHILD_ID_LOCAL_STORAGE_KEY);

export const removeLocalStorageChildId = () => localStorage.removeItem(CHILD_ID_LOCAL_STORAGE_KEY);
