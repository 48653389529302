import { GroupConsultationFormFields, AddUpdateGroupConsultationDTO } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO: (
    groupConsultationFormFields: GroupConsultationFormFields,
) => AddUpdateGroupConsultationDTO = ({
    childrenWithParentsIds,
    date,
    ...otherGroupConsultationFormFields
}) => ({
    ...otherGroupConsultationFormFields,
    date: parseDayjsDateIntoString(date, 'YYYY-MM-DD'),
    childrenWithParents: childrenWithParentsIds,
});
