import { appPaths } from 'consts/paths/paths';
import { commonTexts } from 'consts/text';
import { SimpleMenuItem } from 'components/atoms';

export const newsMenuItems: SimpleMenuItem[] = [
    {
        key: appPaths.app.parent.news.attendance.base,
        label: commonTexts.menuItems.newsMenu.attendance,
    },
    {
        key: appPaths.app.parent.news.calendar,
        label: commonTexts.menuItems.newsMenu.calendar,
    },
    {
        key: appPaths.app.parent.news['daily-schedule'],
        label: commonTexts.menuItems.newsMenu.dailySchedule,
    },
    {
        key: appPaths.app.parent.news['weekly-schedule'],
        label: commonTexts.menuItems.newsMenu.weeklySchedule,
    },
    {
        key: appPaths.app.parent.news.lessons,
        label: commonTexts.menuItems.newsMenu.lessons,
    },
];
