import { DietDTO, DietsFormFields } from 'types';

export const parseDietDTOIntoDietsFormFields = (dietData: DietDTO): DietsFormFields => {
    const { name, breakfastPrice, dinnerPrice, soupPrice, snackPrice } = dietData;

    return {
        name,
        breakfastPrice: breakfastPrice ?? undefined,
        dinnerPrice: dinnerPrice ?? undefined,
        soupPrice: soupPrice ?? undefined,
        snackPrice: snackPrice ?? undefined,
    };
};
