import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    JournalInspectionAddPage,
    JournalInspectionDetailsPage,
    JournalInspectionEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalInspectionRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_INSPECTIONS,
                }}
            >
                <JournalInspectionAddPage journalType="SPECIAL" />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.edit
            .pickedInspection,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_INSPECTIONS,
                }}
            >
                <JournalInspectionEditPage journalType="SPECIAL" />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.details.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.details
            .pickedInspection,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_INSPECTIONS,
                }}
            >
                <JournalInspectionDetailsPage journalType="SPECIAL" />
            </AbilityRoute>
        ),
    },
];
