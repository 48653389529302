import { RemoteLessonAddEditForm } from 'components/templates';
import { appPaths } from 'consts/paths/paths';
import { remoteLessonsTexts } from 'consts/text';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { RemoteLessonsFormFields } from 'types';
import { parseRemoteLessonsFormFieldsIntoRemoteLessonCreate } from 'utils/parsers/remoteLessons/parseRemoteLessonsFormFieldsIntoRemoteLessonCreate';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const RemoteLessonAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    const onAddRemoteLessonSuccess = () => {
        displaySuccess(remoteLessonsTexts.notifications.addSuccess);
        statefulNavigate(appPaths.app['remote-lessons'].base, {
            replace: true,
        });
    };

    const {
        mutate: addRemoteLesson,
        error: addRemoteLessonError,
        isLoading: isAddRemoteLessonLoading,
    } = useAppMutation(StaffHTTPService.remoteLessons.addRemoteLesson, {
        key: ['ADD_REMOTE_LESSON'],
        onSuccess: onAddRemoteLessonSuccess,
        invalidateQueryKeys: [['REMOTE_LESSONS'], ['MAIL_THREADS']],
    });

    const onAddSpecialJournalFinish = (remoteLessonData: RemoteLessonsFormFields) => {
        const parsedRemoteLessonsCreateDTO =
            parseRemoteLessonsFormFieldsIntoRemoteLessonCreate(remoteLessonData);

        addRemoteLesson(parsedRemoteLessonsCreateDTO);
    };

    return (
        <RemoteLessonAddEditForm
            mode="add"
            onFinish={onAddSpecialJournalFinish}
            axiosError={addRemoteLessonError}
            isLoading={isAddRemoteLessonLoading}
        />
    );
};
