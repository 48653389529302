import { ChildSimpleDTO, SpecialTopicChildWork } from 'types';

export const parseNotInLessonChildIntoSpecialTopicChildWork: (
    notInLessonChild: ChildSimpleDTO,
) => SpecialTopicChildWork = (notInLessonChild) => {
    const { id, firstName, lastName } = notInLessonChild;

    return {
        id,
        firstName,
        lastName,
        description: undefined,
        absentType: 'PRESENT',
    };
};
