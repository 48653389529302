import { useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'consts/pagination';

export const usePagination = () => {
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);

    const handlePaginationValuesChange = (page: number, pageSize: number) => {
        const newPageSize = pageSize.toString();
        const newPage = (page - 1).toString();

        setCurrentPage(newPage);
        setCurrentPageSize(newPageSize);
    };

    return {
        currentPage,
        currentPageSize,
        handlePaginationValuesChange,
    };
};
