import styled, { css, useTheme } from 'styled-components';
import { AppAvatar, AppFlex, AppText } from 'components/atoms/CommonAppComponents';
import { getTextStyle } from 'utils/getTextStyle';

type ChildNameWithAvatarProps = {
    name: string;
};

const NAME_PARTS_SEPARATOR = ' ';
const FIRST_NAME_INDEX = 1;
const FIRST_NAME_FIRST_LETTER_INDEX = 0;

const StyledAppAvatar = styled(AppAvatar)`
    ${({ theme }) => css`
        & {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            color: ${theme.colors.primary.primary9};
            background-color: ${theme.colors.primary.primary4};

            .ant-avatar-string {
                transform: none;
                ${getTextStyle('BodySmallSemiBold')}
            }
        }
    `}
`;

export const ChildNameWithAvatar = ({ name }: ChildNameWithAvatarProps) => {
    const theme = useTheme();

    const firstNameLetter = name
        .split(NAME_PARTS_SEPARATOR)
        [FIRST_NAME_INDEX].charAt(FIRST_NAME_FIRST_LETTER_INDEX);

    return (
        <AppFlex align="center" gap={theme.sizes.marginSmall}>
            <StyledAppAvatar>{firstNameLetter}</StyledAppAvatar>
            <AppText margin={0} ellipsis>
                {name}
            </AppText>
        </AppFlex>
    );
};
