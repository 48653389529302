import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import {
    AppActions,
    AppMongoAbility,
    Permission,
    RoleBasedPermissionName,
    UserBaseAuthDTO,
    AppModule,
} from 'types';
import {
    ACTION_TYPE_FOR_PERMISSIONS,
    ROLE_BASED_PERMISSIONS,
} from 'consts/permissions/permissions';

const createAbilitiesForPermission = (
    permission: Permission,
    abilityBuilder: AbilityBuilder<AppMongoAbility>,
) => {
    if (ACTION_TYPE_FOR_PERMISSIONS[permission.name] === 'VIEW_AND_EDIT') {
        const canView = permission.actions?.includes('VIEW');
        const canEditAndAdd = permission.actions?.includes('EDIT_AND_ADD');
        if (canView) abilityBuilder.can(AppActions.VIEW, permission.name);
        if (canEditAndAdd) abilityBuilder.can(AppActions.EDIT_AND_ADD, permission.name);
    }
    if (ACTION_TYPE_FOR_PERMISSIONS[permission.name] === 'YES_OR_NO') {
        abilityBuilder.can(AppActions.DO_ACTION, permission.name);
    }
    if (
        ACTION_TYPE_FOR_PERMISSIONS[permission.name] ===
        'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME'
    ) {
        const canEditAllTime = permission.actions?.includes('EDIT_ALL_THE_TIME');
        const canEdit10thOfMonth = permission.actions?.includes('EDIT_TO_10TH_DAY_OF_MONTH');
        if (canEditAllTime) abilityBuilder.can(AppActions.EDIT_ALL_TIME, permission.name);
        if (canEdit10thOfMonth)
            abilityBuilder.can(AppActions.EDIT_10TH_DAY_OF_MONTH, permission.name);
    }
};

const createAbilitiesForRoleBasedPermission = (
    permission: RoleBasedPermissionName,
    abilityBuilder: AbilityBuilder<AppMongoAbility>,
) => abilityBuilder.can(AppActions.DO_ACTION, permission);

const createViewAbilitiesBasedOnModulePermissions = (
    module: AppModule,
    abilityBuilder: AbilityBuilder<AppMongoAbility>,
) => {
    abilityBuilder.can(AppActions.VIEW_MODULE, module);
};

export const defineAbilities = (user: UserBaseAuthDTO) => {
    const abilityBuilder = new AbilityBuilder<AppMongoAbility>(createMongoAbility);

    if (user) {
        const {
            type,
            role: { permissions, modules },
        } = user;
        const roleBasedPermissions = ROLE_BASED_PERMISSIONS[type];

        permissions.forEach((permission) =>
            createAbilitiesForPermission(permission, abilityBuilder),
        );
        modules.forEach((module) =>
            createViewAbilitiesBasedOnModulePermissions(module, abilityBuilder),
        );

        if (roleBasedPermissions.length) {
            roleBasedPermissions.forEach((permission) =>
                createAbilitiesForRoleBasedPermission(permission, abilityBuilder),
            );
        }
    }

    return abilityBuilder.build();
};
