import styled from 'styled-components';
import { PersonList } from 'components/molecules/List';

export const OuterDesktopListContainer = styled.div`
    margin-left: 50px;
    padding-block: 14px;
`;

export const InnerDesktopListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledPersonList = styled(PersonList)`
    color: ${({ theme }) => theme.colors.grayscale.gray9};
`;
