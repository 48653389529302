import { useParams } from 'react-router-dom';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { remoteLessonsTexts } from 'consts/text';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { RemoteLessonId, RemoteLessonsFormFields } from 'types';
import { RemoteLessonAddEditForm } from 'components/templates';
import { parseRemoteLessonDTOIntoRemoteLessonsFormFields } from 'utils/parsers/remoteLessons/parseRemoteLessonDTOIntoRemoteLessonsFormFields';
import { parseRemoteLessonsFormFieldsIntoRemoteLessonCreate } from 'utils/parsers/remoteLessons/parseRemoteLessonsFormFieldsIntoRemoteLessonCreate';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useEffect } from 'react';
import { formFields } from 'consts/form/formFields';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const RemoteLessonEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<RemoteLessonsFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { remoteLessonId } = useParams<{ remoteLessonId: RemoteLessonId }>();

    const {
        data: remoteLessonData,
        error: remoteLessonError,
        isFetching: isRemoteLessonDataFetching,
    } = useAppQuery(
        'REMOTE_LESSON',
        [remoteLessonId],
        () => StaffHTTPService.remoteLessons.getRemoteLesson(remoteLessonId as RemoteLessonId),
        {
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (remoteLessonData && !form.getFieldValue(formFields.name)) {
            setFormData(parseRemoteLessonDTOIntoRemoteLessonsFormFields(remoteLessonData));
        }
    }, [remoteLessonData, form, setFormData]);

    const navigateToRemoteLessonsPage = () => {
        statefulNavigate(appPaths.app['remote-lessons'].base, {
            replace: true,
        });
    };

    const onEditRemoteLessonSuccess = () => {
        displaySuccess(remoteLessonsTexts.notifications.editSuccess);
        navigateToRemoteLessonsPage();
    };

    const {
        mutate: updateRemoteLesson,
        error: updateRemoteLessonError,
        isLoading: isUpdateRemoteLessonLoading,
    } = useAppMutation(StaffHTTPService.remoteLessons.updateRemoteLesson, {
        key: ['EDIT_REMOTE_LESSON', remoteLessonId],
        onSuccess: onEditRemoteLessonSuccess,
        invalidateQueryKeys: [
            ['REMOTE_LESSONS'],
            ['REMOTE_LESSON', remoteLessonId],
            ['MAIL_THREADS'],
        ],
    });

    const onEditRemoteLessonFinish = (remoteLesson: RemoteLessonsFormFields) => {
        updateRemoteLesson({
            remoteLessonDTO: parseRemoteLessonsFormFieldsIntoRemoteLessonCreate(remoteLesson),
            remoteLessonId: remoteLessonId as RemoteLessonId,
        });
    };

    if (remoteLessonError) {
        return <ErrorDisplay />;
    }

    return (
        <RemoteLessonAddEditForm
            mode="edit"
            form={form}
            onFinish={onEditRemoteLessonFinish}
            axiosError={updateRemoteLessonError}
            isLoading={isUpdateRemoteLessonLoading || isRemoteLessonDataFetching}
            initialValues={formInitialData}
        />
    );
};
