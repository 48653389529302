import { FiltersParams, JournalsFilters, JournalStatus, JournalType } from 'types';
import { journalFilterFields } from 'consts/filters/journal/journalFilterFields';

export const parseFilterParamsIntoGetJournalsFiltersValues: (
    filterParams: FiltersParams,
    searchFilterParam: string,
) => JournalsFilters = (filterParams, searchFilterParam) => ({
    type: filterParams[journalFilterFields.type]
        ? (filterParams[journalFilterFields.type] as JournalType)
        : undefined,
    status: filterParams[journalFilterFields.status]
        ? (filterParams[journalFilterFields.status] as JournalStatus)
        : undefined,
    search: searchFilterParam,
    fromYear: filterParams[journalFilterFields.fromYear]
        ? Number(filterParams[journalFilterFields.fromYear])
        : undefined,
    toYear: filterParams[journalFilterFields.toYear]
        ? Number(filterParams[journalFilterFields.toYear])
        : undefined,
});
