import { ChildId, MailThreadChildrenGroupDTO, SelectOptionType } from 'types';
import { parseMailThreadChildReceiverDTOIntoSelectOptionType } from 'utils/parsers/mailThread/parseMailThreadChildReceiverDTOIntoSelectOptionType';

export const parseMailThreadChildrenGroupDTOsIntoSelectOptionTypeItems: (
    mailThreadChildrenGroupDTOs: MailThreadChildrenGroupDTO[],
) => SelectOptionType<ChildId, string>[] = (mailThreadChildrenGroupDTOs) =>
    mailThreadChildrenGroupDTOs.flatMap((mailThreadChildrenGroupDTO) =>
        mailThreadChildrenGroupDTO.children.map(
            parseMailThreadChildReceiverDTOIntoSelectOptionType,
        ),
    );
