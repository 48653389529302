import {
    PERMISSIONS_ACTION,
    PERMISSIONS_NAME,
    ROLE_BASED_PERMISSIONS_NAME,
} from 'consts/permissions/permissions';
import { MongoAbility } from '@casl/ability';
import { AppModule, StaffAppModules } from 'types/app';

export type PermissionType =
    | 'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME'
    | 'YES_OR_NO'
    | 'VIEW_AND_EDIT';

export type PermissionName = keyof typeof PERMISSIONS_NAME;
export type PermissionAction = keyof typeof PERMISSIONS_ACTION;
export type Permission = {
    name: PermissionName;
    actions: PermissionAction[] | null;
};

export type EditablePermissionsNames = Exclude<PermissionName, 'INSTITUTIONS_CREATE'>;
export type OtherPermissionsNames = Extract<PermissionName, 'INSTITUTIONS_CREATE'>;

export type ActionForOtherPermission = Partial<Record<OtherPermissionsNames, PermissionType>>;
export type ActionForEditablePermission = Partial<Record<EditablePermissionsNames, PermissionType>>;

export type EditablePermissionGroup = Partial<
    Record<
        Extract<StaffAppModules, 'GROUP_JOURNALS' | 'SPECIAL_JOURNALS'>,
        ActionForEditablePermission
    >
>;

export type EditablePermissionsOptionDetails = ActionForEditablePermission &
    EditablePermissionGroup;

export type RoleBasedPermissionName = keyof typeof ROLE_BASED_PERMISSIONS_NAME;

export enum AppActions {
    VIEW = 'view',
    EDIT_AND_ADD = 'edit-and-add',
    VIEW_MODULE = 'view-module',
    DO_ACTION = 'do-action',
    EDIT_ALL_TIME = 'edit-all-time',
    EDIT_10TH_DAY_OF_MONTH = 'edit-10th-day-of-month',
}

export type AppSubjects = PermissionName | RoleBasedPermissionName | AppModule;

export type AppMongoAbility = MongoAbility<[AppActions, AppSubjects]>;
