import {
    InstitutionDietId,
    AgreementValueStringified,
    ChildMealType,
    EditChildForParentFormFields,
    UpdateChildForParentDTO,
} from 'types';
import { childMealTypesArray } from 'consts/child/childMealTypes';

export const parseEditChildForParentFormFieldsIntoUpdateChildForParentDTO: (
    childFormFields: EditChildForParentFormFields,
) => UpdateChildForParentDTO = (childFormFields) => {
    const address =
        !childFormFields.street && !childFormFields.zipCode && !childFormFields.city
            ? null
            : {
                  street: childFormFields.street || null,
                  zipCode: childFormFields.zipCode || null,
                  city: childFormFields.city || null,
              };

    const parsedMealTypes = childMealTypesArray.reduce(
        (acc, meal) => {
            acc[meal] = !!childFormFields.selectMealsType?.includes(meal);
            return acc;
        },
        {} as { [key in ChildMealType]: boolean },
    );

    return {
        address,
        diseases: childFormFields.diseases,
        allergies: childFormFields.allergens,
        agreements: {
            facebook:
                childFormFields.socialNetworksImageSharingAgreementFacebook as AgreementValueStringified,
            instagram:
                childFormFields.socialNetworksImageSharingAgreementInstagram as AgreementValueStringified,
            tiktok: childFormFields.socialNetworksImageSharingAgreementTikTok as AgreementValueStringified,
            website:
                childFormFields.socialNetworksImageSharingAgreementWebsite as AgreementValueStringified,
        },

        diet: childFormFields.diet
            ? {
                  dietId: childFormFields.diet as InstitutionDietId,
                  ...parsedMealTypes,
              }
            : undefined,
    };
};
