import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE, PAGE_PARAM_KEY } from 'consts/pagination';
import { Pagination } from 'types';
import { SEARCH_FILTER_KEY } from 'consts/filters/common/filtersKeys';

const DEFAULT_SEARCH_VALUE = '';

type SearchFilterProps = {
    currentPage?: Pagination['currentPage'];
};

export const useSearchFilter = ({ currentPage }: SearchFilterProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParam = searchParams.get(SEARCH_FILTER_KEY) || DEFAULT_SEARCH_VALUE;

    const handleSearchInput = (value: string) => {
        setSearchParams(
            (prevSearchParams) => {
                if (!value) {
                    prevSearchParams.delete(SEARCH_FILTER_KEY);
                } else {
                    prevSearchParams.set(SEARCH_FILTER_KEY, value);
                }
                if (currentPage && currentPage !== DEFAULT_PAGE) {
                    prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
                }
                return prevSearchParams;
            },
            { replace: currentPage === undefined || currentPage === DEFAULT_PAGE },
        );
    };

    return {
        searchFilterValue: searchParam,
        handleSearchInput,
    };
};
