import styled from 'styled-components';
import { IconPlus } from '@tabler/icons-react';
import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { commonTexts } from 'consts/text';
import { ParentId, SelectOptionType } from 'types';

type SelectProps = {
    value?: ParentId[];
    options: SelectOptionType<ParentId>[];
    onClick?: () => void;
};

type ParentsPickerElementProps = AppFormItemProps & {
    selectProps: SelectProps;
};

const FormItemInnerContainer = styled.div`
    width: 100%;
`;

export const ParentsPickerElement = ({
    selectProps,
    disabled,
    ...restProps
}: ParentsPickerElementProps) => (
    <AppFormItem
        label={commonTexts.forms.selectParent}
        initialValue={[]}
        marginBottom={0}
        disabled={disabled}
        {...restProps}
    >
        {/* FormItemInnerContainer is used for disabling auto-changing field value by antd form  */}
        <FormItemInnerContainer>
            <AppSelect
                mode="multiple"
                value={selectProps.value}
                options={selectProps.options}
                suffixIcon={<IconPlus size={20} />}
                open={false}
                allowClear={false}
                showSearch={false}
                disabled={disabled}
                onClick={!disabled ? selectProps.onClick : undefined}
            />
        </FormItemInnerContainer>
    </AppFormItem>
);
