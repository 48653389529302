import { Input } from 'antd';
import styled, { css } from 'styled-components';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import { IconSearch } from '@tabler/icons-react';
import { SearchProps } from 'antd/es/input/Search';
import { AppLoader } from '../AppLoader/AppLoader';

export type AppSearchInputProps = Omit<SearchProps, 'onSearch'>;

const height = 44;

const StyledSearchInput = styled(Input.Search)<AppSearchInputProps>`
    ${({ theme }) => css`
        &.ant-input-search .ant-input-wrapper {
            ${inputAndPickerBorderStyle};
            border-radius: 8px;

            input {
                height: ${height}px;
                border: 0;
            }
            .ant-input-group-addon {
                border: 0;

                button {
                    display: flex;
                    background-color: ${theme.colors.grayscale.gray1};
                    align-items: center;
                    box-shadow: none;
                    height: ${height}px;
                    padding: 0 12px;
                    cursor: default;

                    & > div > div.ant-wave {
                        box-shadow: none;
                    }
                }
            }
        }
    `}
`;

const EnterButton = styled(IconSearch)`
    ${({ theme }) => css`
        color: ${theme.colors.grayscale.gray8};
    `}
`;

export const AppSearchInput = ({ loading, ...props }: AppSearchInputProps) => (
    <StyledSearchInput
        enterButton={loading ? <AppLoader size={20} /> : <EnterButton size={20} />}
        {...props}
    />
);
