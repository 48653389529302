import dayjs from 'dayjs';
import { generatePath } from 'react-router-dom';
import {
    EmptyDisplay,
    ErrorDisplay,
    JournalAttendanceFilters,
    LoadingDisplay,
    YesNoRadioItem,
} from 'components/molecules';
import { AppParagraph, ComponentBlockingAppLoader } from 'components/atoms';
import { AppTable, AppTableProps } from 'components/organisms';
import { WEEKDAY_AND_TIME_FORMAT } from 'consts/timeFormats';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import {
    Attendance,
    ChildDailySpecialAttendanceDTO,
    ChildId,
    DayAttendanceType,
    JournalId,
    LessonId,
} from 'types';
import { appPaths } from 'consts/paths/paths';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { getFullName } from 'utils/getFullName';
import { useMemo } from 'react';
import { commonTexts, journalTexts } from 'consts/text';
import { NoDataStateIcon } from 'assets';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { specialChildDailyAttendanceDTOFields } from 'consts/attendance/childDailyAttendanceDTOFields';
import { useJournalAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useSpecialJournalDailyAttendancePageNetworkManage } from './hooks/useSpecialJournalDailyAttendancePageNetworkManage';
import {
    ChildNameWrapper,
    FiltersContainer,
    Wrapper,
} from './SpecialJournalDailyAttendance.styled';

type OnAttendanceChangeProps = {
    journalId: JournalId;
    childId: ChildId;
    lessonId: LessonId;
    type: DayAttendanceType;
};

const specialJournalDailyAttendanceColumns = (
    lessonId: LessonId,
    journalId: JournalId,
    onAttendanceChange: ({ journalId, childId, lessonId, type }: OnAttendanceChangeProps) => void,
    canManageAttendance: boolean,
): AppTableProps<ChildDailySpecialAttendanceDTO>['columns'] => [
    {
        title: commonTexts.dataLabels.lastnameAndFirstname,
        dataIndex: specialChildDailyAttendanceDTOFields.child,
        key: 'child',
        render: (child) => (
            <ChildNameWrapper>{getFullName(child.firstName, child.lastName)}</ChildNameWrapper>
        ),
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: journalTexts.journalDetails.specialJournal.attendance.attendanceLabel,
        dataIndex: specialChildDailyAttendanceDTOFields.type,
        key: 'type',
        render: (type, { child }) => (
            <YesNoRadioItem
                onChange={(event) =>
                    onAttendanceChange({
                        journalId,
                        childId: child.id,
                        lessonId,
                        type: event.target.value,
                    })
                }
                value={type}
                yesValue="PRESENT"
                noValue="ABSENT"
                disabled={!canManageAttendance}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
];

export const SpecialJournalDailyAttendancePage = () => {
    const { journalId } = useJournalId();
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const { statefulNavigate } = useNavigateWithState();
    const {
        genericAbilities: { checkIfCanEditAttendanceForSelectedDate },
        specialJournalsAbilities: { checkIfCanManageAttendance },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const parsedDateFilterValue = dateFilterValue
        ? parseStringIntoDayjsDate(dateFilterValue)
        : dayjs();
    const selectedDate = dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD');
    const currentAttendanceType: Attendance = 'DAILY';
    const canManageAttendanceBase = checkIfCanManageAttendance();
    const canEditAttendanceForSelectedDate =
        checkIfCanEditAttendanceForSelectedDate(parsedDateFilterValue);
    const canManageAttendance = canManageAttendanceBase && canEditAttendanceForSelectedDate;

    const {
        dailyAttendanceData,
        firstAndLastJournalSchoolYears,
        isJournalDataFetching,
        isJournalDataError,
        isDailyAttendanceDataError,
        isDailyAttendanceDataRefetching,
        updateDailyAttendance,
        isUpdateDailyAttendanceLoading,
        isDailyAttendanceDataInitialLoading,
    } = useSpecialJournalDailyAttendancePageNetworkManage({
        journalId,
        dateFilterValue: selectedDate,
    });

    const handleAttendanceTypeChange = (selectedAttendanceType: Attendance) => {
        if (selectedAttendanceType === currentAttendanceType) {
            return;
        }
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.monthly.base,
                { journalId },
            ),
        );
    };

    const mappedLessons = useMemo(
        () =>
            dailyAttendanceData?.lessonsAttendance.map((lessonAttendance) => (
                <div key={lessonAttendance.courseId}>
                    <AppParagraph textType="BodyLargeSemiBold">
                        {dayjs(lessonAttendance.dateFrom).format(WEEKDAY_AND_TIME_FORMAT)}
                    </AppParagraph>
                    <AppTable
                        dataSource={lessonAttendance.childrenAttendance}
                        rowKey={(attendance) => attendance.child.id}
                        columns={specialJournalDailyAttendanceColumns(
                            lessonAttendance.lessonId,
                            journalId,
                            updateDailyAttendance,
                            canManageAttendance,
                        )}
                        isTableManageUtilsHidden
                        isTableHeaderHidden
                        isAlwaysExtendedOnMobile
                    />
                </div>
            )),
        [
            canManageAttendance,
            dailyAttendanceData?.lessonsAttendance,
            journalId,
            updateDailyAttendance,
        ],
    );

    const renderWrapperContent = () => {
        if (isDailyAttendanceDataError || isJournalDataError) {
            return <ErrorDisplay />;
        }
        if (isDailyAttendanceDataInitialLoading) {
            return <LoadingDisplay />;
        }
        if (!dailyAttendanceData?.lessonsAttendance.length) {
            return (
                <EmptyDisplay
                    emptyInfo={commonTexts.emptyInfo.noAddedChildren}
                    emptyIcon={<NoDataStateIcon />}
                />
            );
        }

        return (
            <>
                {(isUpdateDailyAttendanceLoading || isDailyAttendanceDataRefetching) && (
                    <ComponentBlockingAppLoader />
                )}
                {mappedLessons}
            </>
        );
    };

    return (
        <>
            <FiltersContainer>
                <JournalAttendanceFilters
                    attendanceType="DAILY"
                    date={parsedDateFilterValue}
                    firstSchoolYear={firstAndLastJournalSchoolYears?.firstYear}
                    lastSchoolYear={firstAndLastJournalSchoolYears?.lastYear}
                    isDatePickerDisabled={isJournalDataFetching}
                    onDateChange={handleChangeDateFilter}
                    onClickNextOrPrevDate={handleClickNextOrPrevDate}
                    onAttendanceTypeChange={handleAttendanceTypeChange}
                />
            </FiltersContainer>
            <Wrapper>{renderWrapperContent()}</Wrapper>
        </>
    );
};
