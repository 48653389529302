import { TripDTO } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseTripDTOIntoJsonObject: (tripDTO: TripDTO) => object = (tripDTO) => {
    const { name, date, description, childrenArray, sendConsentToParents } = tripDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const childrenJsonObjects = childrenArray.map((child) => ({
        [jsonObjectsTexts.tripDTO.innerObjects.tripChildDTO.fields.firstname]: child.firstName,
        [jsonObjectsTexts.tripDTO.innerObjects.tripChildDTO.fields.lastname]: child.lastName,
    }));

    return {
        [jsonObjectsTexts.tripDTO.fields.name]: name,
        [jsonObjectsTexts.tripDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.tripDTO.fields.description]: description,
        [jsonObjectsTexts.tripDTO.fields.childrenArray]: childrenJsonObjects,
        [jsonObjectsTexts.tripDTO.fields.sendConsentToParents]: sendConsentToParents
            ? commonTexts.dataLabels.yes
            : commonTexts.dataLabels.no,
    };
};
