import { OwnerDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parsePhoneNumberIntoFormattedPhoneNumber } from 'utils/parsers/phoneNumber/parsePhoneNumberIntoFormattedPhoneNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseOwnerDTOIntoJsonObject: (ownerDTO: OwnerDTO) => object = (ownerDTO) => {
    const { firstName, lastName, phoneNumber } = ownerDTO;

    const parsedPhoneNumber = phoneNumber
        ? parsePhoneNumberIntoFormattedPhoneNumber(phoneNumber)
        : EMPTY_VALUE_LABEL;

    return {
        [jsonObjectsTexts.ownerDTO.fields.firstname]: firstName,
        [jsonObjectsTexts.ownerDTO.fields.lastname]: lastName,
        [jsonObjectsTexts.ownerDTO.fields.phoneNumber]: parsedPhoneNumber,
    };
};
