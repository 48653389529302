import { ColumnsType } from 'antd/es/table/interface';
import { CustomizeComponent } from 'rc-table/es/interface';
import { MobileExpandableRenderCellContent } from 'components/atoms';
import { ReactNode } from 'react';
import { SubTableBody, SubTableCell, SubTableRow } from './AppExpandableRender.styled';

export type AppExpandableRenderProps<TableType> = {
    mobileExpandableFields: ColumnsType<TableType>;
    record: TableType;
    EditableMobileCellContent?: CustomizeComponent;
};

export const AppExpandableRender = <TableType extends object>({
    mobileExpandableFields,
    record,
    EditableMobileCellContent,
}: AppExpandableRenderProps<TableType>) => {
    const parsedColumnsIntoRecords = mobileExpandableFields.map((config) => {
        // I can do nothing with that :(
        // @ts-ignore
        const dataIndex = config?.dataIndex || '';
        // @ts-ignore
        const cellData = dataIndex ? record[dataIndex] : '';
        const { key, title, render, onCell } = config;

        const renderCellContent = () => {
            if (EditableMobileCellContent) {
                return (
                    <EditableMobileCellContent {...onCell?.(record)}>
                        {render ? render(cellData, record, dataIndex) : cellData}
                    </EditableMobileCellContent>
                );
            }
            return (
                <MobileExpandableRenderCellContent>
                    {render ? render(cellData, record, dataIndex) : cellData}
                </MobileExpandableRenderCellContent>
            );
        };

        return (
            <SubTableRow key={key}>
                <SubTableCell valign="middle">
                    <MobileExpandableRenderCellContent>
                        {title as ReactNode}
                    </MobileExpandableRenderCellContent>
                </SubTableCell>
                <SubTableCell valign="middle">{renderCellContent()}</SubTableCell>
            </SubTableRow>
        );
    });

    return <SubTableBody>{parsedColumnsIntoRecords}</SubTableBody>;
};
