import { useMemo } from 'react';
import { RcFile } from 'antd/es/upload/interface';
import {
    AppAvatarUpload,
    AppAvatarUploadProps,
    AppAvatarUploadFile,
    AppAvatarUploadRequestOptions,
} from 'components/atoms';
import { ChildId, ChildProfilePhotoDTO, FileDirectory, FileMetaDTO } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts } from 'consts/text/index';
import { MAX_FILE_SIZE } from 'consts/file/maxFileSize';
import { fileUploadErrorParser } from 'utils/errorHandlers/file/fileUploadErrorParser';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { AxiosResponse } from 'axios';

export type ChildAvatarMethodType = {
    addFile: ({
        directory,
        file,
    }: {
        directory: FileDirectory;
        file: string | Blob | RcFile;
    }) => Promise<AxiosResponse<FileMetaDTO>>;
    getFile: (fileUri: string) => Promise<AxiosResponse<string>>;
    updateProfilePhoto: ({
        childId,
        childProfilePhotoDTO,
    }: {
        childId: ChildId;
        childProfilePhotoDTO: ChildProfilePhotoDTO;
    }) => Promise<AxiosResponse<ChildProfilePhotoDTO>>;
    deleteProfilePhoto: ({ childId }: { childId: ChildId }) => Promise<AxiosResponse>;
};
type ChildAvatarProps = Pick<AppAvatarUploadProps, 'canManageAvatar'> & {
    childId: ChildId;
    childImageUri?: string | null;
    httpMethod: ChildAvatarMethodType;
};

const UploadChildImageBaseInfo: AppAvatarUploadFile = {
    uid: 'childProfilePhotoUid',
    name: 'childProfilePhoto',
};

export const ChildAvatar = ({
    childId,
    childImageUri,
    canManageAvatar,
    httpMethod,
}: ChildAvatarProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const { mutateAsync: uploadChildImage, isLoading: isUploadChildImageLoading } = useAppMutation(
        httpMethod.addFile,
        {
            key: ['ADD_FILE'],
        },
    );

    const { mutateAsync: updateChildImage, isLoading: isUpdateChildImageLoading } = useAppMutation(
        httpMethod.updateProfilePhoto,
        {
            key: ['UPDATE_CHILD_IMAGE'],
            invalidateQueryKeys: [
                ['CHILDREN', childId],
                ['CHILD_AVATAR', childImageUri],
                ['CHILD', childId],
            ],
        },
    );

    const { mutate: deleteChildImage, isLoading: isDeleteChildImageLoading } = useAppMutation(
        httpMethod.deleteProfilePhoto,
        {
            key: ['DELETE_CHILD_IMAGE'],
            onSuccess: () => displaySuccess(commonTexts.successMessages.deletePhoto),
            onError: () => displayError(commonTexts.errorMessages.actionExecution),
            invalidateQueryKeys: [
                ['CHILDREN', childId],
                ['CHILD', childId],
                ['CHILD_AVATAR', childImageUri],
            ],
        },
    );

    const {
        data: childImage,
        isFetching: isChildImageFetching,
        isError: isFetchChildImageError,
    } = useAppQuery(
        'CHILD_AVATAR',
        [childImageUri],
        () => httpMethod.getFile(childImageUri || ''),
        {
            enabled: !!childImageUri,
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const handleBeforeUpload = (file: RcFile) => {
        if (file.size > MAX_FILE_SIZE) {
            displayError(commonTexts.errorMessages.tooLargeFile);
            return false;
        }
        return true;
    };

    const handleUploadChildImage = async ({ file }: AppAvatarUploadRequestOptions) => {
        try {
            const { data: uploadFileResponseData } = await uploadChildImage({
                directory: 'CHILD',
                file,
            });
            await updateChildImage({
                childId,
                childProfilePhotoDTO: { fileUri: uploadFileResponseData.fileUri },
            });
            displaySuccess(commonTexts.successMessages.updatePhoto);
        } catch (error) {
            const fileUploadError = fileUploadErrorParser(error);
            displayError(fileUploadError);
        }
    };

    const uploadedChildImage: AppAvatarUploadFile | null = useMemo(() => {
        if (!childImage) {
            if (!isFetchChildImageError) {
                return null;
            }
            return {
                ...UploadChildImageBaseInfo,
                name: '',
                status: 'error',
                error: new Error(
                    'Podczas pobierania zdjęcia wystąpił błąd, odśwież stronę i spróbuj ponownie',
                ),
            };
        }
        const blob = new Blob([childImage]);
        const url = URL.createObjectURL(blob);
        return {
            ...UploadChildImageBaseInfo,
            status: 'done',
            url,
        };
    }, [childImage, isFetchChildImageError]);

    const isAppAvatarLoading =
        isChildImageFetching ||
        isDeleteChildImageLoading ||
        isUploadChildImageLoading ||
        isUpdateChildImageLoading;

    return (
        <AppAvatarUpload
            uploadedFile={uploadedChildImage}
            isLoading={isAppAvatarLoading}
            beforeUpload={handleBeforeUpload}
            onUpload={handleUploadChildImage}
            onRemove={() => deleteChildImage({ childId })}
            canManageAvatar={canManageAvatar}
        />
    );
};
