import { Dayjs } from 'dayjs';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';

export const useDisabledDate =
    <Type extends { startDate?: string; endDate?: string }>(selectedObject: Type | undefined) =>
    (dateValue: Dayjs) => {
        if (!selectedObject?.startDate || !selectedObject?.endDate) {
            return true;
        }

        const isBeforeStartDate = dateValue.isBefore(
            parseStringIntoDayjsDate(selectedObject.startDate),
            'date',
        );
        const isAfterEndDate = dateValue.isAfter(
            parseStringIntoDayjsDate(selectedObject.endDate),
            'date',
        );

        return isBeforeStartDate || isAfterEndDate;
    };
