import { useTheme } from 'styled-components';
import {
    HistoryChangeRefersTo,
    HistoryChangeRefersToSub,
    HistoryChangeSpecialAction,
    HistoryChangeType,
    NameChangeDTO,
} from 'types';
import { AppFlex, AppText } from 'components/atoms/CommonAppComponents';
import { historyChangeRefersToLabels } from 'consts/historyChange/historyChangeRefersToLabels';
import { tagsColorsByHistoryChangeType } from 'consts/historyChange/tagsColorsByHistoryChangeType';
import { historyChangeRefersToSubLabels } from 'consts/historyChange/historyChangeRefersToSubLabels';
import { historyChangeSpecialActionLabels } from 'consts/historyChange/historyChangeSpecialActionLabels';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

type HistoryChangeRefersToInfoProps = {
    refersTo: HistoryChangeRefersTo;
    refersToSub: HistoryChangeRefersToSub;
    specialAction: HistoryChangeSpecialAction;
    historyType: HistoryChangeType;
    objectName?: string | NameChangeDTO;
};

export const HistoryChangeRefersToInfo = ({
    refersTo,
    refersToSub,
    objectName,
    historyType,
    specialAction,
}: HistoryChangeRefersToInfoProps) => {
    const theme = useTheme();
    const isMobile = useIsMobile();

    const specialActionLabel = historyChangeSpecialActionLabels[specialAction];
    const firstRowText =
        refersToSub !== 'NONE'
            ? `${historyChangeRefersToLabels[refersTo]}/${historyChangeRefersToSubLabels[refersToSub]}`
            : historyChangeRefersToLabels[refersTo];
    const objectNameText =
        typeof objectName === 'object'
            ? `${objectName.oldName}->${objectName.newName}`
            : objectName;

    const renderSecondRow = () => {
        if (objectName && specialAction !== 'NONE') {
            return (
                <AppFlex gap={4} align={isMobile ? 'flex-start' : 'center'} vertical={isMobile}>
                    <AppText
                        margin={0}
                        textType="BodyMSSemiBold"
                        color={tagsColorsByHistoryChangeType[historyType].textColor}
                    >
                        {specialActionLabel}
                    </AppText>
                    <AppText margin={0}>{objectNameText}</AppText>
                </AppFlex>
            );
        } else if (specialAction !== 'NONE') {
            return (
                <AppText
                    margin={0}
                    textType="BodyMSSemiBold"
                    color={tagsColorsByHistoryChangeType[historyType].textColor}
                >
                    {specialActionLabel}
                </AppText>
            );
        } else if (objectName) {
            return <AppText margin={0}>{objectNameText}</AppText>;
        }
        return null;
    };

    return (
        <AppFlex vertical gap={4}>
            {!isMobile && (
                <AppText margin={0} textType="BodyMSSemiBold" color={theme.colors.grayscale.gray9}>
                    {firstRowText}
                </AppText>
            )}
            {renderSecondRow()}
        </AppFlex>
    );
};
