import React from 'react';
import { AppFormContext } from 'contexts/AppFormContext';
import {
    AppFormBase,
    AppFormBaseProps,
    AppFormContentContainer,
    AppFormBanner,
} from 'components/atoms';
import { CommonFormProps, PossibleFormData } from 'types';
import { useAppFormInnerErrorContext } from 'hooks/useAppFormInnerErrorContext/useAppFormInnerErrorContext';

export type AppModalFormProps<AppFormFields> = AppFormBaseProps<AppFormFields> &
    Partial<CommonFormProps> & {
        children?: React.ReactNode;
    };

export const AppModalForm = <AppFormFields extends PossibleFormData>({
    children,
    error,
    ...props
}: AppModalFormProps<AppFormFields>) => {
    const { contextValue, innerError } = useAppFormInnerErrorContext();

    const errorMessage = error || innerError;

    return (
        <AppFormContext.Provider value={contextValue}>
            <AppFormBase<AppFormFields>
                size="large"
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
                validateTrigger={['onSubmit']}
                {...props}
            >
                <AppFormContentContainer>
                    {errorMessage && <AppFormBanner mode="ERROR" text={errorMessage} />}
                    {children}
                </AppFormContentContainer>
            </AppFormBase>
        </AppFormContext.Provider>
    );
};
