import { map } from 'lodash';
import { WeeklyScheduleDTO, WeeklyScheduleDaysDTO, WeeklyScheduleFormFields } from 'types';
import DOMPurify from 'dompurify';

export const sanitizeWeeklyScheduleDTOData = (
    weeklyScheduleDTO: WeeklyScheduleDTO,
): WeeklyScheduleDTO => {
    const sanitizedDays: WeeklyScheduleDaysDTO[] = map(weeklyScheduleDTO.days, (day) => ({
        ...day,
        description: day.description ? DOMPurify.sanitize(day.description) : '',
    }));

    return { days: sanitizedDays };
};

export const sanitizeWeeklyScheduleDTODataIntoWeeklyScheduleFormFields = (
    weeklyScheduleDTO: WeeklyScheduleDTO,
): WeeklyScheduleFormFields => {
    const sanitizedDays: WeeklyScheduleDaysDTO[] = map(weeklyScheduleDTO.days, (day) => ({
        ...day,
        description: day.description ? DOMPurify.sanitize(day.description) : '',
    }));

    return sanitizedDays.reduce((acc, day) => {
        const dayNameLower = day.dayName.toLowerCase() as keyof WeeklyScheduleFormFields;
        acc[dayNameLower] = day.description;
        return acc;
    }, {} as WeeklyScheduleFormFields);
};
