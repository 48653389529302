import { ChildPayment } from 'types';
import { AddBillFormFields } from 'types/forms/addBill';

export const parseTransactionDtoIntoTransactionFormFields: (
    transactionDto: ChildPayment,
) => AddBillFormFields = (transactionDto) => {
    const { amount, transactionType, paymentFormType } = transactionDto;
    return {
        amount,
        transactionType,
        paymentFormType,
    };
};
