import DOMPurify from 'dompurify';
import { AddUpdateDailyScheduleDTO, DailyScheduleFormFields } from 'types';
import { textEditorNoContentValidator } from 'utils/validators';

export const parseDailyScheduleFormFieldsIntoAddUpdateDailyScheduleDTO: (
    dailyScheduleFormFields: DailyScheduleFormFields,
) => AddUpdateDailyScheduleDTO = (dailyScheduleFormFields) => {
    const isNoText = textEditorNoContentValidator(dailyScheduleFormFields.description);

    return {
        description: isNoText ? null : DOMPurify.sanitize(dailyScheduleFormFields.description),
    };
};
