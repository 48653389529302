import { generatePath, useOutlet } from 'react-router-dom';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import { PageBaseLayout } from 'components/layouts';
import { commonTexts, timetableTexts } from 'consts/text';
import { AddButton, AppSelect } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import { DatePickerArrowButtonType, DatePickerWithArrows } from 'components/molecules';
import { PageFilter, usePageFilters } from 'hooks/usePageFilters/usePageFilters';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { EmployeeId } from 'types';
import { customWeekStartEndFormat } from 'utils/customWeekStartEndFormat';
import { TimetableSchedule } from 'components/templates';
import { useTimetableAbilities } from 'abilities';
import { useTimetablePageNetworkManage } from 'pages/app/staff/timetable/TimetablePage/hooks/useTimetablePageNetworkManage';
import { useIsTablet } from 'hooks/useIsTablet/useIsTablet';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

const HeaderInnerContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.sizes.marginNormal}px;

        @media (${theme.sizes.mediaQueries.tabletMax}) {
            flex-direction: column;
            width: 100%;
        }
    `}
`;

const StyledAppSelect = styled(AppSelect)`
    min-width: 150px;
`;

const EMPLOYEE_ID_FILTER_FIELD = 'employeeId';
const filters: PageFilter<typeof EMPLOYEE_ID_FILTER_FIELD>[] = [
    { name: 'employeeId', type: 'SINGLE_VALUE' },
];

export const TimetablePage = () => {
    const { filtersParams, handleChangeFilter } = usePageFilters({ filters });
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const isTablet = useIsTablet();
    const { statefulNavigate } = useNavigateWithState();
    const { timetableAbilities } = useTimetableAbilities();
    const outlet = useOutlet();

    const canViewAllEmployeeTimetable = timetableAbilities.checkIfCanViewAllEmployeeTimetable();
    const canManageOwnTimetable = timetableAbilities.checkIfCanManageOwnTimetable();
    const employeeIdFilterValue = filtersParams[EMPLOYEE_ID_FILTER_FIELD]
        ? (filtersParams[EMPLOYEE_ID_FILTER_FIELD] as EmployeeId)
        : undefined;

    const {
        timetableScheduleData,
        teachersOptions,
        defaultEmployeeIdFilter,
        isTimetableDataInitialLoading,
        isEmployeesDataInitialLoading,
        isTimetableDataRefetching,
        isEmployeesDataFetching,
        isTimetableDataError,
        isEmployeesDataError,
    } = useTimetablePageNetworkManage({
        filtersValues: {
            date: dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'),
            teacherId: employeeIdFilterValue,
        },
        canViewAllEmployeeTimetable,
    });

    const navigateToLessonAddPage = () =>
        statefulNavigate(generatePath(appPaths.app.timetable.employeeSchedule.add), {
            state: { employeeId: employeeIdFilterValue },
        });

    const navigateToAbsenceAddPage = () =>
        statefulNavigate(generatePath(appPaths.app.timetable.employeeSchedule.absence.add), {
            state: { employeeId: employeeIdFilterValue },
        });

    const employeeFilterValue = employeeIdFilterValue || defaultEmployeeIdFilter;

    const headerContent = (
        <>
            <HeaderInnerContainer>
                {canViewAllEmployeeTimetable && (
                    <StyledAppSelect
                        label={commonTexts.actionLabels.selectEmployee}
                        value={employeeFilterValue}
                        options={teachersOptions}
                        allowClear={false}
                        loading={isEmployeesDataFetching}
                        disabled={!employeeFilterValue}
                        isFitContentWidth={!isTablet}
                        onChange={(employeeId) => handleChangeFilter('employeeId', employeeId)}
                    />
                )}
                <DatePickerWithArrows
                    picker="week"
                    format={customWeekStartEndFormat}
                    value={dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()}
                    onChange={handleChangeDateFilter}
                    onArrowButtonClick={(arrowButtonType: DatePickerArrowButtonType) =>
                        handleClickNextOrPrevDate(arrowButtonType, 'week')
                    }
                />
            </HeaderInnerContainer>
            {canManageOwnTimetable && (
                <HeaderInnerContainer>
                    <AddButton
                        type="default"
                        addButtonLabel={timetableTexts.lessons.addAbsence}
                        fullWidth={isTablet}
                        onAddButtonClick={navigateToAbsenceAddPage}
                    />
                    <AddButton
                        addButtonLabel={timetableTexts.lessons.addToTimetable}
                        fullWidth={isTablet}
                        onAddButtonClick={navigateToLessonAddPage}
                    />
                </HeaderInnerContainer>
            )}
        </>
    );

    return (
        <PageBaseLayout
            headerContent={headerContent}
            emptyStateData={{ emptyInfo: commonTexts.emptyInfo.noTeachers }}
            isDataInitialLoading={isTimetableDataInitialLoading || isEmployeesDataInitialLoading}
            isDataRefetching={isTimetableDataRefetching}
            isError={isTimetableDataError || isEmployeesDataError}
            isEmpty={!timetableScheduleData}
        >
            {outlet}
            {timetableScheduleData && (
                <TimetableSchedule
                    timetableSchedule={timetableScheduleData}
                    editable={canManageOwnTimetable}
                />
            )}
        </PageBaseLayout>
    );
};
