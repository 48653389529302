import { DiscountDTO, DiscountFormFields } from 'types';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseDiscountDTOToDiscountFormFields = (
    discountData: DiscountDTO,
): DiscountFormFields => ({
    amount: discountData.amount,
    discountTarget: discountData.discountTarget,
    discountType: discountData.discountType,
    endDate: parseStringIntoDayjsDate(discountData.endDate),
    startDate: parseStringIntoDayjsDate(discountData.startDate),
    name: discountData.name,
    combinable: discountData.combinable,
    ...(discountData.extraLessons?.id && { courseId: discountData.extraLessons.id }),
});
