import { Child, ChildId, SelectOptionType } from 'types';
import { childFields } from 'consts/child/childFields';
import { getFullName } from '../../getFullName';

export const parseChildIntoSelectOptionType: (
    child: Pick<Child, 'id' | 'firstName' | 'lastName'>,
) => SelectOptionType<ChildId> = (child) => ({
    value: child[childFields.id],
    label: getFullName(child[childFields.firstName], child[childFields.lastName]),
});
