import { formFields } from 'consts/form/formFields';
import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { HelpForm, SelectOptionType } from 'types';
import { psychologicalHelpFormsLabels } from 'consts/psychologicalHelp/psychologicalHelpFormsLabels';
import { formElementRequiredText } from 'consts/errors/forms';

const options: SelectOptionType<HelpForm>[] = [
    {
        label: psychologicalHelpFormsLabels.TALENTS_DEVELOPING_CLASSES,
        value: 'TALENTS_DEVELOPING_CLASSES',
    },
    {
        label: psychologicalHelpFormsLabels.CORRECTIVE_COMPENSATORY_CLASSES,
        value: 'CORRECTIVE_COMPENSATORY_CLASSES',
    },
    {
        label: psychologicalHelpFormsLabels.SPEECH_THERAPY_CLASSES,
        value: 'SPEECH_THERAPY_CLASSES',
    },
    {
        label: psychologicalHelpFormsLabels.SOCIOTHERAPEUTIC_CLASSES,
        value: 'SOCIOTHERAPEUTIC_CLASSES',
    },
    {
        label: psychologicalHelpFormsLabels.ADVICE_AND_CONSULTATION,
        value: 'ADVICE_AND_CONSULTATION',
    },
    {
        label: psychologicalHelpFormsLabels.CLASSES_WITH_PSYCHOLOGIST,
        value: 'CLASSES_WITH_PSYCHOLOGIST',
    },
    { label: psychologicalHelpFormsLabels.PEDAGOGICAL_THERAPY, value: 'PEDAGOGICAL_THERAPY' },
    { label: psychologicalHelpFormsLabels.OTHER, value: 'OTHER' },
];

export const HelpFormPickerElement = (props: AppFormItemProps) => (
    <AppFormItem
        label="Forma pomocy"
        name={formFields.helpForm}
        rules={[{ required: true, message: formElementRequiredText }]}
        {...props}
    >
        <AppSelect options={options} />
    </AppFormItem>
);
