import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { childrenTexts } from 'consts/text';
import { max300LengthRule } from 'consts/rules/textRules';

export const BirthPlaceInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label={childrenTexts.childForm.personalData.birthPlace}
        name={formFields.birthPlace}
        rules={[{ required: true, message: formElementRequiredText }, max300LengthRule]}
        {...props}
    >
        <AppInput autoComplete="off" />
    </AppFormItem>
);
