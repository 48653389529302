import { AccountActiveMarkIcon, AccountInactiveMarkIcon } from 'assets';

export const ContractValidDisplay = ({
    contractValid,
    dataTestId,
}: {
    contractValid: boolean;
    dataTestId?: string;
}) =>
    contractValid ? (
        <AccountActiveMarkIcon data-testid={`${dataTestId}-active`} />
    ) : (
        <AccountInactiveMarkIcon data-testid={`${dataTestId}-inactive`} />
    );
