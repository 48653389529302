export const subscriptionTableColumnKeys = {
    institutionName: 'institutionName',
    phoneNumber: 'phoneNumber',
    lastnameAndFirstname: 'lastnameAndFirstname',
    amount: 'amount',
    balance: 'balance',
    status: 'status',
    lastPayment: 'lastPayment',
    children: 'children',
} as const;
