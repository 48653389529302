import { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { ParentTeacherConferenceDTO, ParentTeacherConferenceId } from 'types';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { appPaths } from 'consts/paths/paths';
import {
    CountDisplay,
    DateDisplay,
    NameWithDateDisplay,
    PersonListDisplay,
} from 'components/molecules';
import { EventTypeSelect, Option } from 'components/atoms';
import { commonTexts, journalTexts } from 'consts/text';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { parentTeacherConferenceDTOFields } from 'consts/parentTeacherConference/parentTeacherConferenceDTOFields';
import { parentTeacherConferenceTableAdditionalColumnsKeys } from 'consts/parentTeacherConference/parentTeacherConferenceTableAdditionalColumnsKeys';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useGroupJournalParentTeacherConferencesPageNetworkManage } from './hooks/useGroupJournalParentTeacherConferencesPageNetworkManage';

const columns: AppTableProps<ParentTeacherConferenceDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
            .parentTeacherConferenceSubject,
        dataIndex: parentTeacherConferenceDTOFields.subject,
        key: parentTeacherConferenceDTOFields.subject,
        render: (_, parentTeacherConference) => (
            <NameWithDateDisplay
                name={parentTeacherConference.subject}
                date={parentTeacherConference.date}
            />
        ),
        width: '35%',
    },
    {
        title: commonTexts.dataLabels.parentsCount,
        dataIndex: parentTeacherConferenceTableAdditionalColumnsKeys.parentCount,
        key: parentTeacherConferenceTableAdditionalColumnsKeys.parentCount,
        render: (_, parentTeacherConference) => (
            <CountDisplay count={parentTeacherConference.parents.length} />
        ),
        align: 'center',
        width: '15%',
    },
    {
        title: commonTexts.dataLabels.date,
        dataIndex: parentTeacherConferenceDTOFields.date,
        key: parentTeacherConferenceDTOFields.date,
        render: (date: ParentTeacherConferenceDTO['date']) => <DateDisplay date={date} />,
        align: 'center',
        isInvisibleInMobile: true,
        width: '50%',
    },
    {
        title: journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
            .currentParents,
        dataIndex: parentTeacherConferenceDTOFields.parents,
        key: parentTeacherConferenceDTOFields.parents,
        render: (_, parentTeacherConference) => (
            <PersonListDisplay
                personsData={parentTeacherConference[parentTeacherConferenceDTOFields.parents]}
            />
        ),
        isInvisibleInDesktop: true,
        isVisibleAsExtendableInMobile: true,
    },
];

export const GroupJournalParentTeacherConferencesPage = () => {
    const [
        selectedToRemoveParentTeacherConferenceId,
        setSelectedToRemoveParentTeacherConferenceId,
    ] = useState<ParentTeacherConferenceId | null>(null);
    const { journalId } = useJournalId();
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const {
        groupJournalsAbilities: { checkIfCanManageEvents },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageEvents = checkIfCanManageEvents();

    const onParentTeacherConferenceRemoveRequestFinishOrCancel = () =>
        setSelectedToRemoveParentTeacherConferenceId(null);

    const {
        parentTeacherConferencesData,
        isParentTeacherConferencesDataInitialLoading,
        isParentTeacherConferencesDataRefetching,
        isParentTeacherConferencesDataError,
        isRemoveParentTeacherConferenceLoading,
        removeParentTeacherConference,
    } = useGroupJournalParentTeacherConferencesPageNetworkManage({
        journalId,
        onParentTeacherConferenceRemoveRequestFinish:
            onParentTeacherConferenceRemoveRequestFinishOrCancel,
    });

    const handleRemoveParentTeacherConferenceClick = (
        parentTeacherConference: ParentTeacherConferenceDTO,
    ) => setSelectedToRemoveParentTeacherConferenceId(parentTeacherConference.id);

    const handleRemoveParentTeacherConferenceConfirm = () => {
        if (!selectedToRemoveParentTeacherConferenceId) {
            return;
        }
        removeParentTeacherConference({
            journalId,
            parentTeacherConferenceId: selectedToRemoveParentTeacherConferenceId,
        });
    };

    const navigateToParentTeacherConferenceAddPage = () =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events
                    .parentTeacherConferences.add,
                {
                    journalId,
                },
            ),
        );

    const navigateToParentTeacherConferenceEditPage = ({ id }: ParentTeacherConferenceDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events
                    .parentTeacherConferences.edit.pickedParentTeacherConference,
                { parentTeacherConferenceId: id, journalId },
            ),
        );

    const navigateToParentTeacherConferenceDetailsPage = ({ id }: ParentTeacherConferenceDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events
                    .parentTeacherConferences.details.pickedParentTeacherConference,
                { parentTeacherConferenceId: id, journalId },
            ),
        );

    const expandedRowRender = useCallback(
        (parentTeacherConference: ParentTeacherConferenceDTO) => (
            <PersonListDisplay
                personsData={parentTeacherConference[parentTeacherConferenceDTOFields.parents]}
                desktopLabel={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .currentParents
                }
            />
        ),
        [],
    );

    const expandable = useMemo(
        () =>
            !isMobile
                ? {
                      expandedRowRender,
                      showExpandColumn: true,
                  }
                : undefined,
        [isMobile, expandedRowRender],
    );

    const options: Option<ParentTeacherConferenceDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToParentTeacherConferenceDetailsPage,
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToParentTeacherConferenceEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageEvents,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveParentTeacherConferenceClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManageEvents,
        },
    ];

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveParentTeacherConferenceId}
                title={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .confirmDeleteParentTeacherConference
                }
                confirmActionLoading={isRemoveParentTeacherConferenceLoading}
                onOk={handleRemoveParentTeacherConferenceConfirm}
                onCancel={onParentTeacherConferenceRemoveRequestFinishOrCancel}
            />
            <AppTable
                title={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .parentTeacherConferenceList
                }
                addButtonLabel={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .addParentTeacherConference
                }
                emptyInfo={
                    journalTexts.journalDetails.groupJournal.events.parentTeacherConferences
                        .emptyParentTeacherConferenceList
                }
                options={options}
                columns={columns}
                dataSource={parentTeacherConferencesData}
                expandable={expandable}
                rowKey={({ id }) => id}
                isError={isParentTeacherConferencesDataError}
                isOptionsLoading={isRemoveParentTeacherConferenceLoading}
                isDataInitialLoading={isParentTeacherConferencesDataInitialLoading}
                isDataRefetching={isParentTeacherConferencesDataRefetching}
                isHiddenAddButton={!canManageEvents}
                tableManageUtilsProps={{
                    isVisibleSearchFilter: false,
                    additionalFilters: (
                        <EventTypeSelect
                            eventType="PARENT_TEACHER_CONFERENCE"
                            journalId={journalId}
                        />
                    ),
                }}
                onAddButtonClick={navigateToParentTeacherConferenceAddPage}
            />
        </>
    );
};
