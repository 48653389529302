import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { SettlementLayout } from 'components/layouts/SettlementLayout/SettlementLayout';
import {
    AdditionalLessonsPage,
    ChildSettlementPage,
    ChildrenAddPaymentPage,
    ChildrenEditPaymentPage,
    ChildrenSettlementPage,
    EvidenceWorkingTimePage,
    ChildrenAddCostAllocationPage,
    ChildrenEditCostAllocationPage,
} from 'pages';

export const settlementRoutes: RouteObject[] = [
    {
        path: appPaths.app.settlement.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW_MODULE,
                    subject: STAFF_APP_MODULES.SETTLEMENT,
                }}
            >
                <SettlementLayout />
            </AbilityRoute>
        ),
        children: [
            {
                path: appPaths.app.settlement.children.base,
                element: <ChildrenSettlementPage />,
                children: [
                    {
                        path: appPaths.app.settlement.children.pickedChild.payment.edit
                            .pickedPayment,
                        element: <ChildrenEditPaymentPage />,
                    },
                    {
                        path: appPaths.app.settlement.children.pickedChild.payment.add,
                        element: <ChildrenAddPaymentPage />,
                    },
                ],
            },
            {
                path: appPaths.app.settlement.staff.base,
                element: <EvidenceWorkingTimePage />,
            },
            {
                path: appPaths.app.settlement.additionalLessons.base,
                element: <AdditionalLessonsPage />,
            },
        ],
    },
    {
        path: appPaths.app.settlement.children.pickedChild.details.base,
        element: <ChildSettlementPage />,
        children: [
            {
                path: appPaths.app.settlement.children.pickedChild.details.payment.edit
                    .pickedPayment,
                element: <ChildrenEditPaymentPage />,
            },
            {
                path: appPaths.app.settlement.children.pickedChild.details.payment.add,
                element: <ChildrenAddPaymentPage />,
            },
        ],
    },
    {
        path: appPaths.app.settlement.children.pickedChild['cost-allocation'].add.base,
        element: <ChildrenAddCostAllocationPage />,
    },
    {
        path: appPaths.app.settlement.children.pickedChild['cost-allocation'].edit
            .pickedCostAllocation,
        element: <ChildrenEditCostAllocationPage />,
    },
];
