import { EmployeeId } from '../employee';
import { InstitutionId } from '../institution';

export enum AbsenceType {
    HOLIDAY_LEAVE = 'HOLIDAY_LEAVE',
    LEAVE_ON_REQUEST = 'LEAVE_ON_REQUEST',
    OCCASIONAL_LEAVE = 'OCCASIONAL_LEAVE',
    UNPAID_LEAVE = 'UNPAID_LEAVE',
    SICK_LEAVE = 'SICK_LEAVE',
    UNJUSTIFIED_ABSENCE = 'UNJUSTIFIED_ABSENCE',
    OTHER = 'OTHER',
}

export type AbsenceId = string & { readonly type: unique symbol };

export type CreateAbsenceDTO = {
    teacherIds: EmployeeId[];
    startDate: string;
    endDate: string;
    type: AbsenceType;
    paid: boolean;
    description: string;
};

export type UpdateAbsenceDTO = Omit<CreateAbsenceDTO, 'teacherIds'> & {
    teacherId: EmployeeId;
};

export type AbsenceDTO = {
    id: AbsenceId;
    institutionId: InstitutionId;
    teacherId: EmployeeId;
    firstName: string;
    lastName: string;
    startDate: string;
    endDate: string;
    date: string;
    type: AbsenceType;
    paid: boolean;
    description: string;
};

export type AbsencesDTO = {
    absences: AbsenceDTO[];
};

export type AddChildAbsencesParams = {
    startDate: string;
    endDate: string;
};
