import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { AccountLayout } from 'components/layouts';
import {
    OwnerAndEmployeeAccountDetailsPage,
    OwnerAccountEditPage,
    PasswordUpdatePage,
} from 'pages';

export const accountRoutes: RouteObject[] = [
    {
        path: appPaths.app.account.base,
        element: <AccountLayout />,
        children: [
            // TODO: add page for parent role
            {
                path: appPaths.app.account.details.base,
                element: <OwnerAndEmployeeAccountDetailsPage />,
            },
            {
                path: appPaths.app.account['password-update'],
                element: <PasswordUpdatePage />,
            },
        ],
    },
    // TODO: add edit account data page for employee and parent roles
    {
        path: appPaths.app.account.details.edit,
        element: <OwnerAccountEditPage />,
    },
];
