import styled, { css, useTheme } from 'styled-components';
import { IconPlus } from '@tabler/icons-react';
import { AppText } from 'components/atoms/CommonAppComponents/AppText/AppText';
import { commonTexts } from 'consts/text';

const UploadButtonContainer = styled.span`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid ${theme.colors.primary.primary4};
        background: ${theme.colors.primary.primary2};
        overflow: hidden;
        align-items: center;
        cursor: pointer;
    `}
`;

export const UploadButton = () => {
    const theme = useTheme();

    return (
        <UploadButtonContainer>
            <IconPlus size={20} color={theme.colors.primary.primary7} />
            <AppText textType="BodyMSSemiBold" margin={0}>
                {commonTexts.actionLabels.add}
            </AppText>
            <AppText textType="BodyMSSemiBold" margin={0}>
                {commonTexts.otherLabels.image}
            </AppText>
        </UploadButtonContainer>
    );
};
