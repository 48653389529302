import { AppTitle } from 'components/atoms/index';
import { journalTexts } from 'consts/text/index';
import { Container, StyledTextContainer } from './DailyScheduleCard.styled';

type DailyScheduleCardProps = {
    description: string;
};

export const DailyScheduleCard = ({ description }: DailyScheduleCardProps) => (
    <Container>
        <AppTitle level={4} textAlign="center" margin={0} marginBottom={24}>
            {journalTexts.journalDetails.groupJournal.schedules.dailySchedule.dailyScheduleLabel}
        </AppTitle>
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        <StyledTextContainer dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
);
