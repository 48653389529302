import { AddGuardianDTO, GuardianFormFields } from 'types';
import { parseFormattedPhoneNumberIntoPhoneNumber } from '../phoneNumber/parseFormattedPhoneNumberIntoPhoneNumber';

export const parseGuardianFormFieldsIntoAddGuardianDTO: (
    guardianFormFields: GuardianFormFields,
) => AddGuardianDTO = ({ firstName, lastName, phoneNumber, idNumber }) => ({
    firstName,
    lastName,
    idNumber,
    phoneNumber: phoneNumber ? parseFormattedPhoneNumberIntoPhoneNumber(phoneNumber) : null,
});
