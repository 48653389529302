import { generatePath } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { DualValueCellContent } from 'components/atoms';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { InspectionId, JournalId, JournalType } from 'types';
import { appPaths } from 'consts/paths/paths';
import { LinkDisplay } from '../LinkDisplay/LinkDisplay';

type InspectionSubjectDisplayProps = {
    subject: string;
    date: string;
    journalId: JournalId;
    inspectionId: InspectionId;
    journalType: JournalType;
};

export const InspectionSubjectDisplay = ({
    subject,
    date,
    journalId,
    inspectionId,
    journalType,
}: InspectionSubjectDisplayProps) => {
    const isMobile = useIsMobile();

    const parsedDate = parseStringIntoFormattedStringDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY');
    const inspectionDetailsPagePath = generatePath(
        journalType === 'GROUP'
            ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.details
                  .pickedInspection
            : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.details
                  .pickedInspection,
        {
            journalId,
            inspectionId,
        },
    );

    return isMobile ? (
        <DualValueCellContent mainValue={subject} secondaryValue={parsedDate} />
    ) : (
        <LinkDisplay to={inspectionDetailsPagePath}>{subject}</LinkDisplay>
    );
};
