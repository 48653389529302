import { PsychologicalHelpDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { psychologicalHelpFormsLabels } from 'consts/psychologicalHelp/psychologicalHelpFormsLabels';
import { psychologicalHelpBasesLabels } from 'consts/psychologicalHelp/psychologicalHelpBasesLabels';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parsePsychologicalHelpDTOIntoJsonObject: (
    psychologicalHelpDTO: PsychologicalHelpDTO,
) => object = (psychologicalHelpDTO) => {
    const {
        helpForm,
        dateFrom,
        dateTo,
        child,
        basis,
        description,
        attachments,
        additionalInformation,
    } = psychologicalHelpDTO;

    const formattedDateFrom = parseStringIntoFormattedStringDate(dateFrom);
    const formattedDateTo = parseStringIntoFormattedStringDate(dateTo);
    const attachmentsNames = attachments.map((attachment) => attachment.fileName);
    const childJsonObject = {
        [jsonObjectsTexts.psychologicalHelpDTO.innerObjects.psychologicalHelpChildDTO.fields
            .firstname]: child.firstName,
        [jsonObjectsTexts.psychologicalHelpDTO.innerObjects.psychologicalHelpChildDTO.fields
            .lastname]: child.lastName,
    };

    return {
        [jsonObjectsTexts.psychologicalHelpDTO.fields.helpForm]:
            psychologicalHelpFormsLabels[helpForm],
        [jsonObjectsTexts.psychologicalHelpDTO.fields.dateFrom]: formattedDateFrom,
        [jsonObjectsTexts.psychologicalHelpDTO.fields.dateTo]: formattedDateTo,
        [jsonObjectsTexts.psychologicalHelpDTO.fields.child]: childJsonObject,
        [jsonObjectsTexts.psychologicalHelpDTO.fields.basis]: psychologicalHelpBasesLabels[basis],
        [jsonObjectsTexts.psychologicalHelpDTO.fields.description]: description,
        [jsonObjectsTexts.psychologicalHelpDTO.fields.additionalInformation]:
            additionalInformation || EMPTY_VALUE_LABEL,
        [jsonObjectsTexts.psychologicalHelpDTO.fields.attachments]: attachmentsNames,
    };
};
