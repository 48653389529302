import { SpecialJournalAddEditForm } from 'components/templates/journal';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseJournalDTOInfoSpecialJournalFormFields } from 'utils/parsers/journal/parseJournalDTOIntoSpecialJournalFormFields';
import { appPaths } from 'consts/paths/paths';
import { journalTexts } from 'consts/text';
import { SpecialJournalFormFields } from 'types/forms/journal';
import { parseSpecialJournalFormFieldsIntoUpdateSpecialJournalDTO } from 'utils/parsers/journal/parseSpecialJournalFormFieldsIntoAddUpdateSpecialJournalDTO';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ErrorDisplay } from 'components/molecules';
import { JournalId } from 'types';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { formFields } from 'consts/form/formFields';
import { journalFields } from 'consts/journal/journalFields';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const SpecialJournalEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SpecialJournalFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { journalId: journalIdParam } = useParams();
    const journalId = (journalIdParam || '') as JournalId;

    const {
        data: journalData,
        error: journalError,
        isFetching: isJournalDataFetching,
    } = useAppQuery('JOURNAL', [journalId], () => StaffHTTPService.journals.getJournal(journalId), {
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useEffect(() => {
        if (journalData && !form.getFieldValue(formFields.name)) {
            setFormData(parseJournalDTOInfoSpecialJournalFormFields(journalData));
        }
    }, [journalData, form, setFormData]);

    const navigateToJournalsPage = () => {
        statefulNavigate(appPaths.app.journals.base, {
            replace: true,
        });
    };

    const onEditJournalSuccess = () => {
        displaySuccess(journalTexts.journals.editSpecialJournalSuccess);
        navigateToJournalsPage();
    };

    const {
        mutate: updateGroupJournalLesson,
        error: editGroupJournalLessonError,
        isLoading: isEditGroupJournalLessonLoading,
    } = useAppMutation(StaffHTTPService.journals.editSpecialJournal, {
        key: ['EDIT_SPECIAL_JOURNAL', journalId],
        onSuccess: onEditJournalSuccess,
        invalidateQueryKeys: [
            ['JOURNALS'],
            ['JOURNAL', journalId],
            ['LESSONS_JOURNALS'],
            ['MAIL_THREAD_RECEIVERS'],
        ],
    });

    const onEditLessonFinish = (journalFormData: SpecialJournalFormFields) => {
        const parsedSpecialJournalDTO =
            parseSpecialJournalFormFieldsIntoUpdateSpecialJournalDTO(journalFormData);
        updateGroupJournalLesson({ journalId, specialJournalDTO: parsedSpecialJournalDTO });
    };

    if (!journalId) {
        navigateToJournalsPage();
    }

    if (journalError) {
        return <ErrorDisplay />;
    }

    return (
        <SpecialJournalAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            addedChildren={journalData?.[journalFields.group].childrenArray}
            onFinish={onEditLessonFinish}
            axiosError={editGroupJournalLessonError}
            isLoading={isEditGroupJournalLessonLoading || isJournalDataFetching}
        />
    );
};
