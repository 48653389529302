import { SimpleMenuItem } from 'components/atoms';
import { MailThreadsFilterKey, MailThreadSideBarMenuItemsType } from 'types';
import { messagesTexts } from 'consts/text';
import { PageFilter } from 'hooks/usePageFilters/usePageFilters';

export const mailThreadsTypes: SimpleMenuItem<MailThreadSideBarMenuItemsType>[] = [
    {
        key: 'ALL',
        label: messagesTexts.mailThreadsMenuItems.all,
    },
    {
        key: 'PRIVATE',
        label: messagesTexts.mailThreadsMenuItems.individual,
    },
    {
        key: 'GROUP',
        label: messagesTexts.mailThreadsMenuItems.group,
    },
];

export const ALL_MAIL_THREADS_MENU_KEY: Extract<MailThreadSideBarMenuItemsType, 'ALL'> = 'ALL';

export const filters: PageFilter<MailThreadsFilterKey>[] = [
    { name: 'type', type: 'SINGLE_VALUE' },
    { name: 'search', type: 'SINGLE_VALUE' },
];
