import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    ChildAddDiscountPage,
    ChildEditDiscountPage,
    JournalChildDetailsPage,
    JournalChildEditPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalChildRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.groupJournalDetails.pickedJournal.children.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.edit.pickedChild,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.CHILDREN_LIST,
                }}
            >
                <JournalChildEditPage journalType="GROUP" />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.details.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.groupJournalDetails.pickedJournal.children.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.details.pickedChild
            .base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.CHILDREN_LIST,
                }}
            >
                <JournalChildDetailsPage journalType="GROUP" />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.details.pickedChild[
            'add-discount'
        ].base,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildAddDiscountPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.details.pickedChild[
            'edit-discount'
        ].base,
        element: <Navigate to={appPaths.app.children.base} />,
    },
    {
        path: appPaths.app.journals.groupJournalDetails.pickedJournal.children.details.pickedChild[
            'edit-discount'
        ].pickedDiscount,
        element: (
            <AbilityRoute
                can={{ action: AppActions.EDIT_AND_ADD, subject: PERMISSIONS_NAME.CHILDREN_LIST }}
            >
                <ChildEditDiscountPage />
            </AbilityRoute>
        ),
    },
];
