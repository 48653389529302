import { generatePath, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Details } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { appPaths } from 'consts/paths/paths';
import { menusDTOFields } from 'consts/menus/menusDTOFields';
import { MenuId } from 'types';
import { Row } from 'antd';
import { commonTexts, menusTexts } from 'consts/text';
import { useDietAbilities } from 'abilities';
import { AppList } from 'components/atoms';
import { AttachmentListItem } from 'components/molecules';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const MenusDetailsPage = () => {
    const { statefulNavigate } = useNavigateWithState();
    const { menuId: menuIdParam } = useParams();
    const menuId = (menuIdParam || '') as MenuId;
    const theme = useTheme();
    const { dietAbilities } = useDietAbilities();
    const { checkIfCanManageDietConfig } = dietAbilities;
    const canManageDietConfig = checkIfCanManageDietConfig();

    const editPagePath = generatePath(appPaths.app.menu.edit.pickedMenu, { menuId });

    const {
        data: menuData,
        isFetching: isMenuDataFetching,
        isError: isFetchMenuDataError,
    } = useAppQuery('MENU', [menuId], () => StaffHTTPService.menus.getMenu(menuId), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    if (!menuId) {
        statefulNavigate(
            appPaths.app.menu.base,

            { replace: true },
        );
    }

    return (
        <Details
            title={menusTexts.menuPreview}
            isLoading={isMenuDataFetching}
            isError={isFetchMenuDataError}
            editPagePath={canManageDietConfig ? editPagePath : undefined}
        >
            <Details.Item title={menusTexts.menuName}>
                {menuData && menuData[menusDTOFields.name]}
            </Details.Item>

            <Row>
                <Details.Item title={menusTexts.appliesFrom}>
                    {menuData &&
                        parseStringIntoFormattedStringDate(menuData[menusDTOFields.dateFrom])}
                </Details.Item>
                <Details.Item title={menusTexts.appliesTo}>
                    {menuData &&
                        parseStringIntoFormattedStringDate(menuData[menusDTOFields.dateTo])}
                </Details.Item>
            </Row>
            {menuData && (
                <Details.Item title={commonTexts.dataLabels.attachment}>
                    <AppList
                        dataSource={menuData[menusDTOFields.attachments]}
                        marginTop={theme.sizes.marginNormal}
                        renderItem={(fileData) => (
                            <AttachmentListItem key={fileData.fileUri} fileData={fileData} />
                        )}
                    />
                </Details.Item>
            )}
        </Details>
    );
};
