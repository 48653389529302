import { NothingFoundDisplay } from 'components/molecules';
import { TableBodyWrapperProps, TableBodyWrapper } from '../TableBodyWrapper/TableBodyWrapper';

export type TableNothingFoundDisplayProps = Omit<TableBodyWrapperProps, 'children'>;

export const TableNothingFoundDisplay = (props: TableNothingFoundDisplayProps) => (
    <TableBodyWrapper {...props}>
        <NothingFoundDisplay />
    </TableBodyWrapper>
);
