export const evidenceWorkingTimeFields = {
    teacherId: 'teacherId',
    firstName: 'firstName',
    lastName: 'lastName',
    workingDays: 'workingDays',
    unjustifiedAbsences: 'unjustifiedAbsences',
    paidLeaves: 'paidLeaves',
    unpaidLeaves: 'unpaidLeaves',
    sickLeaves: 'sickLeaves',
    otherLeaves: 'otherLeaves',
    scheduledWorkingTime: 'scheduledWorkingTime',
    workingTime: 'workingTime',
    overtime: 'overtime',
};
