import { useContext, useMemo } from 'react';
import { useWatch } from 'antd/es/form/Form';
import { ChildGroupPickerElement, ChildGroupPickerElementProps } from 'components/molecules';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { AppFormContext } from 'contexts/AppFormContext';
import { parseGroupDTOIntoSelectOptionType } from 'utils/parsers/group/parseGroupDTOIntoSelectOptionType';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { formFields } from 'consts/form/formFields';
import { groupDTOFields } from 'consts/group/groupDTOFields';
import { GroupId } from 'types';
import { groupsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type ChildGroupPickerProps = Pick<ChildGroupPickerElementProps, 'version'>;

export const ChildGroupPicker = ({ version }: ChildGroupPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);
    const groupId = useWatch<GroupId | undefined>(
        version === 'CHILD' ? formFields.group : formFields.groups,
    );

    const {
        data: childGroupsData,
        isFetching,
        isError,
    } = useAppQuery('GROUPS', [], () => StaffHTTPService.groups.getGroups(), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useDisplayFetchDataError({
        isFetching,
        isError,
        actualError: formError,
        errorMessage: groupsTexts.fetchingError,
        setError: setFormError,
    });

    const childGroupOptions = useMemo(() => {
        if (!childGroupsData) {
            return [];
        }

        const filteredChildGroupsData = childGroupsData.filter(
            (childGroupsDataItem) =>
                childGroupsDataItem[groupDTOFields.id] === groupId ||
                childGroupsDataItem[groupDTOFields.status] !== 'ARCHIVED',
        );
        return filteredChildGroupsData.map(parseGroupDTOIntoSelectOptionType);
    }, [childGroupsData, groupId]);

    return (
        <ChildGroupPickerElement
            options={childGroupOptions}
            isLoading={isFetching}
            version={version}
        />
    );
};
