import styled, { css } from 'styled-components';
import { AppFormItem, AppText, AppTextEditor } from 'components/atoms';
import { getTextStyle } from 'utils/getTextStyle';

export const StyledAppFormItem = styled(AppFormItem)`
    ${({ theme }) => css`
        width: 16%;
        margin-bottom: 0;

        .ant-form-item-label {
            padding-bottom: 0;
        }

        &:first-child {
            border-radius: ${theme.borders.borderRadiusLarge}px 0 0
                ${theme.borders.borderRadiusLarge}px;
        }

        &:last-child {
            border-radius: ${theme.borders.borderRadiusLarge}px;
        }

        &:nth-child(odd),
        &:nth-child(odd) .ant-form-item-control-input-content > div {
            background-color: ${theme.colors.primary.primary2};
        }

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;

            &:first-child {
                border-radius: ${theme.borders.borderRadiusLarge}px
                    ${theme.borders.borderRadiusLarge}px 0 0;
            }
        }
    `}
`;

export const Label = styled(AppText)`
    padding: 12px;
    ${getTextStyle('BodyMediumSemiBold')};

    &.ant-typography {
        margin: 0;
    }
`;

export const StyledAppTextEditor = styled(AppTextEditor)`
    ${({ theme }) => css`
        padding: 12px;
        border: none;
        border-radius: ${theme.borders.borderRadiusLarge}px;

        .ql-container {
            height: 400px;
            background-color: ${theme.colors.primary.primary1};
        }
    `}
`;
