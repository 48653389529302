import { AppFormItem, AppFormItemProps, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { settingsTexts } from 'consts/text';
import { MethodOfCalcTheFee } from 'types';

const items: { label: string; value: MethodOfCalcTheFee }[] = [
    {
        label: settingsTexts.settlements.stays.formLabels.hourlyMethodOfCalcTheFee,
        value: MethodOfCalcTheFee.HOURLY,
    },
    {
        label: settingsTexts.settlements.stays.formLabels.monthlyMethodOfCalcTheFee,
        value: MethodOfCalcTheFee.MONTHLY,
    },
];

export const MethodOfCalcTheFeePickerElement = (props: AppFormItemProps) => {
    const radioElements = items.map(({ value, label }) => (
        <AppRadioButton key={value} value={value}>
            {label}
        </AppRadioButton>
    ));

    return (
        <AppFormItem
            label={settingsTexts.settlements.stays.formLabels.methodOfCalcTheFeeLabel}
            name={formFields.paymentType}
            {...props}
        >
            <AppRadioGroup>{radioElements}</AppRadioGroup>
        </AppFormItem>
    );
};
