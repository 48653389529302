import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { ChildId } from 'types';
import { guardianAddEditErrorParser } from 'utils/errorHandlers/guardian/guardianAddEditErrorParser';
import { childrenTexts } from 'consts/text/index';

type UseGuardianMutateProps = {
    childId: ChildId;
};

export const useGuardianMutate = ({ childId }: UseGuardianMutateProps) => {
    const { displayError, displaySuccess } = useSimpleNotification();
    const invalidateQueryKeys = [['CHILDREN'], ['CHILD', childId]];
    const queryKey = ['CHILD', childId];
    const { mutate: addGuardian, isLoading: isAddGuardianLoading } = useAppMutation(
        StaffHTTPService.children.guardian.addGuardian,
        {
            onSuccess: () => displaySuccess(childrenTexts.successfullyAddGuardianMessage),
            onError: (error) =>
                displayError(
                    guardianAddEditErrorParser(error, childrenTexts.addGuardianErrorMessage),
                ),
            key: queryKey,
            invalidateQueryKeys,
        },
    );
    const { mutate: deleteGuardian, isLoading: isDeleteGuardianLoading } = useAppMutation(
        StaffHTTPService.children.guardian.deleteGuardian,
        {
            onSuccess: () => displaySuccess(childrenTexts.successfullyDeleteGuardianMessage),
            onError: () => displayError(childrenTexts.deleteGuardianErrorMessage),
            key: queryKey,
            invalidateQueryKeys,
        },
    );
    const { mutate: updateGuardian, isLoading: isUpdateGuardianLoading } = useAppMutation(
        StaffHTTPService.children.guardian.updateGuardian,
        {
            onSuccess: () => displaySuccess(childrenTexts.successfullyUpdateGuardianMessage),
            onError: (error) =>
                displayError(
                    guardianAddEditErrorParser(error, childrenTexts.updateGuardianErrorMessage),
                ),
            key: queryKey,
            invalidateQueryKeys,
        },
    );

    return {
        addGuardian,
        isAddGuardianLoading,
        deleteGuardian,
        isDeleteGuardianLoading,
        updateGuardian,
        isUpdateGuardianLoading,
        isGuardianMutating:
            isAddGuardianLoading || isDeleteGuardianLoading || isUpdateGuardianLoading,
    };
};
