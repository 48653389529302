import { generatePath } from 'react-router-dom';
import { ImportantEventAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { ImportantEventFormFields } from 'types';
import { parseImportantEventFormFieldsIntoAddUpdateImportantEventDTO } from 'utils/parsers/importantEvent/parseImportantEventFormFieldsIntoAddUpdateImportantEventDTO';
import { journalTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalImportantEventAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const handleAddImportantEventSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.groupJournal.events.importantEvents
                .successfullyAddedImportantEvent,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.events.importantEvents.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addImportantEvent,
        error: addImportantEventError,
        isLoading: isAddImportantEventLoading,
    } = useAppMutation(StaffHTTPService.importantEvents.addImportantEvent, {
        key: ['ADD_IMPORTANT_EVENT'],
        onSuccess: handleAddImportantEventSuccess,
        invalidateQueryKeys: [['IMPORTANT_EVENTS', journalId]],
    });

    const handleAddImportantEventFinish = (importantEventFormData: ImportantEventFormFields) => {
        const parsedAddImportantEventDTO =
            parseImportantEventFormFieldsIntoAddUpdateImportantEventDTO(importantEventFormData);
        addImportantEvent({ journalId, addImportantEventDTO: parsedAddImportantEventDTO });
    };

    return (
        <ImportantEventAddEditForm
            mode="add"
            axiosError={addImportantEventError}
            isLoading={isAddImportantEventLoading}
            onFinish={handleAddImportantEventFinish}
        />
    );
};
