import { DetailsFrameBase, DetailsColumn, DetailsRow } from 'components/atoms';
import { subscriptionsTexts } from 'consts/text';
import { InstitutionCRMDataIcon } from 'assets';
import {
    CountWithLimitDisplay,
    FirstAndLastNameDisplay,
    PhoneNumberDisplay,
    PriceDisplay,
} from 'components/molecules/Table';

type CRMDataProps = {
    amount: number;
    childrenCount: number;
    childrenLimit: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
};

export const CRMData = ({
    amount,
    childrenCount,
    childrenLimit,
    firstName,
    lastName,
    phoneNumber,
}: CRMDataProps) => (
    <DetailsFrameBase
        icon={<InstitutionCRMDataIcon />}
        title={subscriptionsTexts.details.crmData.heading}
    >
        <DetailsRow>
            <DetailsColumn title={subscriptionsTexts.details.crmData.amount} span={12}>
                <PriceDisplay value={amount} />
            </DetailsColumn>
            <DetailsColumn title={subscriptionsTexts.details.crmData.children} span={12}>
                <CountWithLimitDisplay count={childrenCount} limit={childrenLimit} />
            </DetailsColumn>
        </DetailsRow>
        <DetailsRow>
            <DetailsColumn
                title={subscriptionsTexts.details.crmData.lastnameAndFirstname}
                span={12}
            >
                <FirstAndLastNameDisplay firstName={firstName} lastName={lastName} />
            </DetailsColumn>
            <DetailsColumn title={subscriptionsTexts.details.crmData.phoneNumber} span={12}>
                <PhoneNumberDisplay phoneNumber={phoneNumber} />
            </DetailsColumn>
        </DetailsRow>
    </DetailsFrameBase>
);
