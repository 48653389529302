import styled, { createGlobalStyle } from 'styled-components';

export const AppInfiniteScrollGlobalStyle = createGlobalStyle`
    .infinite-scroll-component {
        overflow: hidden !important;
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
`;
