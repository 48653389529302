import { SettlementMealsDTO, SettlementMealsFormFields } from 'types';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { parseStringIntoDayjsTime } from '../dateTime/parseStringIntoDayjsTime';

export const parseSettlementMealsDTOIntoSettlementMealsFormFields: (
    settlementMealsDTO: SettlementMealsDTO,
) => SettlementMealsFormFields = (settlementMealsDTO) => ({
    mealPaymentType: settlementMealsDTO.mealPaymentType,
    absenceReportType: settlementMealsDTO.absenceReportType,
    mealProviderType: settlementMealsDTO.mealProvider,
    absenceReportTime: settlementMealsDTO.absenceReportTime
        ? parseStringIntoDayjsTime(settlementMealsDTO.absenceReportTime)
        : null,
    ...(settlementMealsDTO.mealProvider === 'CATERING' && {
        recipientName: settlementMealsDTO.cateringPaymentDetails?.recipientName,
        cateringTransferName: settlementMealsDTO.cateringPaymentDetails?.transferTitle,
        cateringAccountNumber: settlementMealsDTO.cateringPaymentDetails?.bankAccountNumber
            ? parseBankAccountNumberIntoFormattedBankAccountNumber(
                  settlementMealsDTO.cateringPaymentDetails?.bankAccountNumber,
              )
            : '',
    }),
});
