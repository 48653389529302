import styled, { css } from 'styled-components';
import { AppFormItem, AppFormItemProps } from 'components/atoms';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

const filteredProps = ['withPasswordForget', 'withRulesVisible'];

export const PasswordItem = styled(AppFormItem).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppFormItemProps & { withPasswordForget: boolean; withRulesVisible: boolean }>`
  ${({ withPasswordForget, withRulesVisible }) => css`
    
    ${
        withRulesVisible &&
        css`
            &.ant-form-item-with-help .ant-form-item-explain {
                display: none;
            }
        `
    }
  
    &.ant-form-item:not(.ant-form-item-has-error) {
        ${
            withPasswordForget
                ? css`
                      margin: 0;
                  `
                : ''
        };
    `}}
`;

export const ForgotPasswordLinkContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
