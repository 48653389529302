import { useTheme } from 'styled-components';
import { IconMinus } from '@tabler/icons-react';
import { StyledPlusIcon } from 'components/atoms';

type ExpandIconProps = {
    isActive?: boolean;
};

export const ExpandIcon = ({ isActive }: ExpandIconProps) => {
    const theme = useTheme();

    return isActive ? (
        <IconMinus size={20} color={theme.colors.primary.primary9} />
    ) : (
        <StyledPlusIcon color={theme.colors.primary.primary9} />
    );
};
