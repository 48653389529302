import { ReactNode } from 'react';
import { Address } from 'types';
import { childrenTexts } from 'consts/text';
import { DetailsColumn, DetailsRow, DetailsFrameBase } from 'components/atoms';

export type AddressDataProps = {
    heading: string;
    icon: ReactNode;
    address: Address | null;
};

export const AddressData = ({ heading, icon, address }: AddressDataProps) => (
    <DetailsFrameBase icon={icon} title={heading}>
        <DetailsRow>
            <DetailsColumn title={childrenTexts.childDetails.addressData.street} span={12}>
                {address?.street}
            </DetailsColumn>
            <DetailsColumn title={childrenTexts.childDetails.addressData.zipCode} span={12}>
                {address?.zipCode}
            </DetailsColumn>
        </DetailsRow>
        <DetailsRow>
            <DetailsColumn title={childrenTexts.childDetails.addressData.city} span={24}>
                {address?.city}
            </DetailsColumn>
        </DetailsRow>
    </DetailsFrameBase>
);
