import React, { ReactNode } from 'react';
import { Menu, MenuProps } from 'antd';
import { AppMenuBookmark, AppMenuIconContainer } from './AppMenuSubComponents';
import { GlobalStyle } from './AppMenu.styled';

export type AppMenuMode = 'horizontal' | 'inline';

export type AppMenuProps = Omit<MenuProps, 'mode'> & {
    mode: AppMenuMode;
};

export type MenuItem = Required<MenuProps>['items'][number];

export type SimpleMenuItem<KeyType = string> = { key: KeyType; label: string };

export const getAppMenuItem = (
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem =>
    ({
        key,
        icon,
        children,
        label,
    }) as MenuItem;

export const getIconWithBookmark = (Icon: ReactNode) => (
    <AppMenuIconContainer>
        <AppMenuBookmark className="menu-bookmark" />
        {Icon}
    </AppMenuIconContainer>
);

export const AppMenu = (props: AppMenuProps) => (
    <>
        <GlobalStyle />
        <Menu {...props} />
    </>
);
