import React from 'react';
import { AppText } from 'components/atoms/CommonAppComponents';
import { useTheme } from 'styled-components';
import { OPTIONAL_FIELD_LABEL } from 'consts/labels/common';
import { ItemGroupContainer, ItemsContainer } from './AppFormItemGroup.styled';

export type AppFormItemGroupProps = {
    title?: string;
    additionalTitleText?: boolean;
    children?: React.ReactNode;
};

export const AppFormItemGroup = ({
    title,
    children,
    additionalTitleText = false,
}: AppFormItemGroupProps) => {
    const theme = useTheme();

    return (
        <ItemGroupContainer>
            {title && (
                <AppText
                    textType="BodyMediumSemiBold"
                    margin={0}
                    marginBottom={16}
                    color={theme.colors.primary.primary10}
                >
                    {title}
                    {additionalTitleText && (
                        <AppText textType="BodyMSRegular" margin={0}>
                            {` (${OPTIONAL_FIELD_LABEL})`}
                        </AppText>
                    )}
                </AppText>
            )}
            <ItemsContainer>{children}</ItemsContainer>
        </ItemGroupContainer>
    );
};
