import { CreateLessonDTO, LessonFormFields } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseDayjsTimeIntoHoursMinutesFormat } from 'utils/parsers/dateTime/parseDayjsTimeIntoHoursMinutesFormat';

export const parseLessonFormFieldsIntoAddLessonDTO: (
    lessonFormFields: LessonFormFields,
) => CreateLessonDTO = ({
    timeFrom,
    timeTo,
    startDate,
    finishDate,
    name,
    journalName,
    ...restFormFields
}) => ({
    ...restFormFields,
    timeFrom: parseDayjsTimeIntoHoursMinutesFormat(timeFrom),
    timeTo: parseDayjsTimeIntoHoursMinutesFormat(timeTo),
    startDate: parseDayjsDateIntoString(startDate, 'YYYY-MM-DD'),
    endDate: parseDayjsDateIntoString(finishDate, 'YYYY-MM-DD'),
    name: name ?? journalName,
});
