import { colors } from 'theme/styledComponents/colors';
import { settingsTexts } from 'consts/text';
import {
    ResponsiveAppParagraph,
    StyledAppButton,
    UpgradeSubscriptionContainer,
} from './SubscriptionUpgradeCTA.styled';
import { redirectToMailAgent } from '../../../../../utils/redirectToMailAgent';
import { CONTACT_EMAIL } from '../../../../../consts/contactEmail';

export const SubscriptionUpgradeCTA = () => (
    <UpgradeSubscriptionContainer>
        <ResponsiveAppParagraph margin={0} color={colors.grayscale.gray1} textAlign="left">
            <ResponsiveAppParagraph margin={0} color={colors.grayscale.gray1} textAlign="center">
                {settingsTexts.accountType.paymentQuestionBegin}
                <b>{settingsTexts.accountType.paymentQuestionBold}</b>
            </ResponsiveAppParagraph>
            {settingsTexts.accountType.paymentQuestionEnd}
        </ResponsiveAppParagraph>
        <StyledAppButton type="primary" onClick={() => redirectToMailAgent(CONTACT_EMAIL)}>
            {settingsTexts.accountType.contactWithUs}
        </StyledAppButton>
    </UpgradeSubscriptionContainer>
);
