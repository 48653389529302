export const EXTRA_LESSONS = '/api/v1/lessons/courses';

export const EXTRA_LESSONS_SETTLEMENT = '/api/v1/settlements/courses';
// TODO: to change type after backend implementation
export const EXTRA_LESSON = (courseId: string) => `/api/v1/settlements/courses/${courseId}`;

export const EXTRA_LESSON_JOURNALS = (courseId: string) =>
    `/api/v1/settlements/courses/${courseId}/journals`;

export const STAFF = '/api/v1/settlements/staff';

export const COST_ALLOCATIONS = '/api/v1/settlements/cost-split';
export const COST_ALLOCATION = (costAllocationId: string) =>
    `/api/v1/settlements/cost-split/${costAllocationId}`;

export const MONTHLY_SETTLEMENT = '/api/v1/monthly-settlement';
export const CHILD_SETTLEMENT = '/api/v1/monthly-settlement/get-one';
export const CHILD_SETTLEMENT_TRANSACTIONS = '/api/v1/transactions';
export const CHILD_SETTLEMENT_TRANSACTION = (transactionId: string) =>
    `/api/v1/transactions/${transactionId}`;
