import styled, { css } from 'styled-components';
import { Row } from 'antd';

export const DetailsRow = styled(Row)<{ noMarginTop?: boolean }>`
    ${({ theme, noMarginTop }) => css`
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        row-gap: ${theme.sizes.marginNormal}px;

        &:not(:first-child) {
            margin-top: ${noMarginTop ? `0` : `${theme.sizes.marginNormal}px`};
        }

        &:not(:last-child) {
            border-bottom: 1px solid ${theme.colors.primary.primary3};
        }
    `}
`;
