import { DatePicker } from 'antd';
import { createGlobalStyle, css, styled } from 'styled-components';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import { pickerFocusStyle } from 'consts/styles/pickerFocusStyle';
import type { AppRangePickerProps } from './AppRangePicker';

const { RangePicker } = DatePicker;

export const GlobalStyle = createGlobalStyle`
${({ theme }) => css`
    .ant-picker-dropdown-range {
        .ant-picker-cell {
            color: ${theme.colors.grayscale.gray6};

            &.ant-picker-cell-in-view {
                color: ${theme.colors.grayscale.gray10};
            }
        }
    }
`}
`;

export const StyledRangePicker = styled(RangePicker)<AppRangePickerProps>`
    ${({ theme }) => css`
        ${inputAndPickerBorderStyle}
        ${pickerFocusStyle}

        .anticon-swap-right {
            color: ${theme.colors.grayscale.gray7};
        }

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100%;
        }
    `}
`;
