import { useMemo, useState } from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { AppTable, AppTableProps, RemoveModal } from 'components/organisms';
import { getFullName } from 'utils/getFullName';
import { appPaths } from 'consts/paths/paths';
import { InspectionDTO, InspectionId, JournalType } from 'types';
import { inspectionDTOFields } from 'consts/inspection/inspectionDTOFields';
import { Option } from 'components/atoms';
import { InspectionSubjectDisplay, DateDisplay } from 'components/molecules';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { commonTexts, journalTexts } from 'consts/text';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalInspectionsPageNetworkManage } from './hooks/useJournalInspectionsPageNetworkManage';

const getColumns: (journalType: JournalType) => AppTableProps<InspectionDTO>['columns'] = (
    journalType,
) => [
    {
        title: journalTexts.journalDetails.common.inspections.inspectionSubject,
        dataIndex: inspectionDTOFields.subject,
        key: inspectionDTOFields.subject,
        render: (_, record) => (
            <InspectionSubjectDisplay
                subject={record.subject}
                date={record.date}
                journalId={record.journalId}
                inspectionId={record.id}
                journalType={journalType}
            />
        ),
    },
    {
        title: journalTexts.journalDetails.common.inspections.supervisor,
        dataIndex: inspectionDTOFields.supervisor,
        key: inspectionDTOFields.supervisor,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.common.inspections.supervised,
        dataIndex: inspectionDTOFields.teacher,
        key: inspectionDTOFields.teacher,
        render: (teacher: InspectionDTO['teacher']) =>
            getFullName(teacher.firstName, teacher.lastName),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.date,
        dataIndex: inspectionDTOFields.date,
        key: inspectionDTOFields.date,
        render: (date: InspectionDTO['date']) => <DateDisplay date={date} />,
        align: 'center',
        isInvisibleInMobile: true,
    },
];

export const JournalInspectionsPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { journalId } = useJournalId();
    const [selectedToRemoveInspectionId, setSelectedToRemoveInspectionId] =
        useState<InspectionId | null>(null);
    const { statefulNavigate } = useNavigateWithState();
    const {
        groupJournalsAbilities: {
            checkIfCanManageInspections: checkIfCanManageGroupJournalInspections,
        },
        specialJournalsAbilities: {
            checkIfCanManageInspections: checkIfCanManageSpecialJournalInspections,
        },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const isGroupJournal = journalType === 'GROUP';
    const canManageGroupJournalInspections = checkIfCanManageGroupJournalInspections();
    const canManageSpecialJournalInspections = checkIfCanManageSpecialJournalInspections();
    const canManageInspections = isGroupJournal
        ? canManageGroupJournalInspections
        : canManageSpecialJournalInspections;

    const onInspectionRemoveRequestFinishOrCancel = () => setSelectedToRemoveInspectionId(null);

    const {
        inspectionsData,
        isInspectionsDataInitialLoading,
        isInspectionsDataRefetching,
        isInspectionsDataError,
        isRemoveInspectionLoading,
        removeInspection,
    } = useJournalInspectionsPageNetworkManage({
        journalId,
        onInspectionRemoveRequestFinish: onInspectionRemoveRequestFinishOrCancel,
    });

    const navigateToInspectionAddPage = () => {
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.add
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.add,
                { journalId },
            ),
        );
    };

    const navigateToInspectionEditPage = ({ id }: InspectionDTO) => {
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.edit
                          .pickedInspection
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.edit
                          .pickedInspection,
                {
                    journalId,
                    inspectionId: id,
                },
            ),
        );
    };

    const navigateToInspectionDetailsPage = ({ id }: InspectionDTO) => {
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.inspections.details
                          .pickedInspection
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.inspections.details
                          .pickedInspection,
                {
                    journalId,
                    inspectionId: id,
                },
            ),
        );
    };

    const handleRemoveInspectionClick = (inspection: InspectionDTO) =>
        setSelectedToRemoveInspectionId(inspection.id);

    const handleRemoveInspectionConfirm = () => {
        if (!selectedToRemoveInspectionId) {
            return;
        }
        removeInspection({
            journalId,
            inspectionId: selectedToRemoveInspectionId,
        });
    };

    const columns = useMemo(() => getColumns(journalType), [journalType]);

    const options: Option<InspectionDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToInspectionDetailsPage,
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToInspectionEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageInspections,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveInspectionClick,
            Icon: <IconTrash />,
            checkIfVisible: () => canManageInspections,
        },
    ];

    if (!journalId) {
        return <Navigate to={appPaths.app.journals.base} />;
    }

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveInspectionId}
                title={journalTexts.journalDetails.common.inspections.confirmDeleteInspection}
                confirmActionLoading={isRemoveInspectionLoading}
                onOk={handleRemoveInspectionConfirm}
                onCancel={onInspectionRemoveRequestFinishOrCancel}
            />
            <AppTable
                title={journalTexts.journalDetails.common.inspections.inspectionList}
                addButtonLabel={journalTexts.journalDetails.common.inspections.addInspection}
                emptyInfo={journalTexts.journalDetails.common.inspections.emptyInspectionList}
                options={options}
                columns={columns}
                dataSource={inspectionsData}
                rowKey={({ id }) => id}
                isTableManageUtilsHidden
                isError={isInspectionsDataError}
                isOptionsLoading={isRemoveInspectionLoading}
                isDataInitialLoading={isInspectionsDataInitialLoading}
                isDataRefetching={isInspectionsDataRefetching}
                isHiddenAddButton={!canManageInspections}
                onAddButtonClick={navigateToInspectionAddPage}
            />
        </>
    );
};
