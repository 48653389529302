import { authTexts } from 'consts/text';
import { InstitutionType } from 'types';

export const institutionTypeItems: { label: string; value: InstitutionType }[] = [
    {
        label: authTexts.kinderGardenButtonLabel,
        value: 'KINDERGARTEN',
    },
    {
        label: authTexts.nurseryButtonLabel,
        value: 'NURSERY',
    },
    {
        label: authTexts.otherInstitutionLabel,
        value: 'OTHER',
    },
];
