import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { useAuth } from 'services/firebase/useAuth';
import { currentInstitutionAtom } from 'jotaiAtoms/currentInstitution';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { auth } from 'services/firebase/firebase';
import {
    readLocalStorageInstitutionId,
    writeLocalStorageInstitutionId,
} from 'services/localStorage/institutionId';
import AuthHTTPService from 'services/HTTPService/auth/AuthHTTPService';
import { currentChildAtom } from 'jotaiAtoms/currentChild';
import { readLocalStorageChildId, writeLocalStorageChildId } from 'services/localStorage/childId';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { ChildAuthDTO, InstitutionBaseData } from 'types';
import { useSimpleNotification } from '../useSimpleNotification/useSimpleNotification';

export const useUserDetailsFetch = () => {
    const isUserEmailVerified = auth.currentUser?.emailVerified || false;
    const { displayError } = useSimpleNotification();
    const { signUserOut } = useAuth();
    const [userDetails, setUserDetails] = useAtom(userDetailsAtom);
    const [currentInstitution, setCurrentInstitutionAtom] = useAtom(currentInstitutionAtom);
    const [currentChild, setCurrentChild] = useAtom(currentChildAtom);

    const { data, isFetching, isFetchedAfterMount, isError, refetch } = useAppQuery(
        'USER_DETAILS',
        [currentInstitution.id],
        () => AuthHTTPService.authentication.getUser(currentInstitution?.id || null),
        {
            enabled: isUserEmailVerified,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (data) {
            setUserDetails(data);
        }
    }, [currentInstitution.id, data, setUserDetails]);

    useEffect(() => {
        if (isError) {
            signUserOut();
            displayError('Wystąpił błąd sesji, nastąpiło automatyczne wylogowanie');
        }
    }, [displayError, isError, signUserOut]);

    useEffect(() => {
        if (!data || !isFetchedAfterMount) {
            return;
        }
        setUserDetails(data);
        if (!currentInstitution.id && data.institutions) {
            let selectedInstitution: InstitutionBaseData | undefined;
            const localStorageInstitutionId = readLocalStorageInstitutionId();
            if (localStorageInstitutionId) {
                selectedInstitution = data.institutions.find(
                    (institution) => institution.id === localStorageInstitutionId,
                );
            } else {
                // eslint-disable-next-line prefer-destructuring
                selectedInstitution = data.institutions[0];
                writeLocalStorageInstitutionId(selectedInstitution.id);
            }
            if (!selectedInstitution) {
                return;
            }
            setCurrentInstitutionAtom(selectedInstitution);
        }
        if (!currentChild.id && data.children) {
            let selectedChild: ChildAuthDTO | undefined;
            const localStorageChildId = readLocalStorageChildId();
            if (localStorageChildId) {
                selectedChild = data.children.find((child) => child.id === localStorageChildId);
            } else {
                // eslint-disable-next-line prefer-destructuring
                selectedChild = data.children[0];
                writeLocalStorageChildId(selectedChild.id);
                writeLocalStorageInstitutionId(selectedChild.institutionAddress.institutionId);
            }
            if (!selectedChild) {
                return;
            }
            setCurrentChild(selectedChild);
        }
    }, [
        currentChild.id,
        currentInstitution.id,
        data,
        isFetchedAfterMount,
        setCurrentChild,
        setCurrentInstitutionAtom,
        setUserDetails,
    ]);

    return {
        fetchUserDetails: refetch,
        isInitialFetching: isFetching && !userDetails,
    };
};
