import styled, { css } from 'styled-components';
import { IconTrash } from '@tabler/icons-react';
import { AppButton } from 'components/atoms/CommonAppComponents/AppButton/AppButton';
import { FileListItem } from 'components/atoms/FileListItem/FileListItem';
import { AppList } from 'components/atoms/CommonAppComponents/AppList/AppList';
import type { AppUploadFile, AppUploadProps } from '../../AppUpload';

type FileListProps = Pick<AppUploadProps, 'onRemove'> & {
    files: AppUploadProps['fileList'];
};

const StyledList = styled(AppList)`
    ${({ theme }) => css`
        margin-top: ${theme.sizes.marginNormal}px;
    `}
`;

const TrashButton = styled(AppButton)`
    ${({ theme }) => css`
        &.ant-btn {
            margin: 0;
            padding: 0;
            color: ${theme.colors.grayscale.gray10};
        }
    `}
`;

export const FileList = ({ files, onRemove }: FileListProps) => (
    <StyledList<AppUploadFile>
        dataSource={files}
        renderItem={(file) => (
            <FileListItem
                key={file.uid}
                mode="FORM"
                fileData={{ fileName: file.name, fileUri: '' }}
                actionButton={
                    <TrashButton
                        type="link"
                        icon={<IconTrash size={20} />}
                        onClick={() => onRemove?.(file)}
                    />
                }
            />
        )}
    />
);
