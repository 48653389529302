import { SettingsAbilitiesNames } from 'abilities';
import { appPaths } from 'consts/paths/paths';
import { commonTexts } from 'consts/text';

export type MenuItem = {
    key: string;
    label: string;
    canView: () => boolean;
};

export const settingNavBarOptions = (
    settingsAbilities: Record<SettingsAbilitiesNames, () => boolean>,
): MenuItem[] => {
    const allMenuItems: MenuItem[] = [
        {
            key: appPaths.app.settings['institution-details'].base,
            label: 'Dane placówki',
            canView: settingsAbilities.checkIfCanViewInstitutionData,
        },
        {
            key: appPaths.app.settings['principal-details'],
            label: commonTexts.menuItems.settingsMenu.principalData,
            canView: settingsAbilities.checkIfCanViewPrincipal,
        },
        {
            key: appPaths.app.settings['invoice-details'].base,
            label: 'Dane do faktury',
            canView: settingsAbilities.checkIfCanViewPayment,
        },
        {
            key: appPaths.app.settings.settlements.base,
            label: 'Rozliczenia',
            canView: settingsAbilities.checkIfCanViewSettlementSettings,
        },
        {
            key: appPaths.app.settings.diets.base,
            label: 'Posiłki',
            canView: settingsAbilities.checkIfCanViewDietSettings,
        },
        {
            key: appPaths.app.settings['roles-and-permissions'].base,
            label: 'Role i uprawnienia',
            canView: settingsAbilities.checkIfCanViewRolesAndPermissions,
        },
        {
            key: appPaths.app.settings['history-of-changes'],
            label: 'Historia zmian',
            canView: settingsAbilities.checkIfCanViewChangesHistory,
        },
        {
            key: appPaths.app.settings.payments,
            label: 'Płatności',
            canView: settingsAbilities.checkIfCanViewPayment,
        },
        {
            key: appPaths.app.settings['your-account-type'],
            label: 'Twój pakiet',
            canView: settingsAbilities.checkIfCanViewPayment,
        },
    ];

    return allMenuItems.filter((item) => item.canView());
};
