import styled from 'styled-components';

export const SubItemDotIcon = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.primary.primary9};
    margin-left: 16px;
`;

export const SubItemLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
`;

export const SubItemLabel = styled.div`
    padding-left: 8px;
`;
