import { ChildDiet, ChildForParentDto, ChildMealType, EditChildForParentFormFields } from 'types';
import { childMealTypesArray } from 'consts/child/childMealTypes';

export const parseChildForParentIntoEditChildForParentFormFields = (
    child: ChildForParentDto,
    childDiet: ChildDiet,
): EditChildForParentFormFields => {
    const { address, diseases, allergies, agreements } = child;

    const dietId = childDiet.institutionDiet?.id;

    const selectedMeals = childDiet
        ? Object.entries(childDiet)
              .filter(
                  ([key, value]) =>
                      value === true && childMealTypesArray.includes(key as ChildMealType),
              )
              .map(([key]) => key)
        : null;

    return {
        street: address?.street || '',
        city: address?.city || '',
        zipCode: address?.zipCode || '',
        ...(dietId && { diet: dietId }),
        ...(selectedMeals && { selectMealsType: selectedMeals as ChildMealType[] }),
        diseases: diseases || '',
        allergens: allergies || '',
        socialNetworksImageSharingAgreementInstagram: String(agreements.instagram),
        socialNetworksImageSharingAgreementFacebook: String(agreements.facebook),
        socialNetworksImageSharingAgreementTikTok: String(agreements.tiktok),
        socialNetworksImageSharingAgreementWebsite: String(agreements.website),
    };
};
