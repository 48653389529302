import { AxiosError } from 'axios';
import useForm from 'antd/es/form/hooks/useForm';
import { useWatch } from 'antd/es/form/Form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AppForm, AppFormProps, TeachersPicker } from 'components/organisms';
import { PrincipalFormFields } from 'types';
import { FormNavButtons, SimpleCheckboxElement, SimpleInputElement } from 'components/molecules';
import { commonTexts, settingsTexts } from 'consts/text/index';
import { formFields } from 'consts/form/formFields';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

export type PrincipalEditFormProps = Omit<
    AppFormProps<PrincipalFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    isViewMode: boolean;
    onFinish: (principalData: PrincipalFormFields) => void;
};

export const PrincipalEditForm = ({
    initialValues,
    axiosError,
    form,
    isViewMode,
    ...props
}: PrincipalEditFormProps) => {
    const [formInstance] = useForm(form);
    const selectFromTeacherListFormValue = useWatch(formFields.selectFromTeacherList, formInstance);
    const currentInstitution = useCurrentInstitution();

    const principalEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<PrincipalFormFields>
            form={form}
            title={settingsTexts.principalDetails.principalData}
            name="principalEdit"
            error={principalEditError}
            {...props}
        >
            <SimpleInputElement
                name={formFields.name}
                label={commonTexts.dataLabels.lastnameAndFirstname}
                disabled={isViewMode || selectFromTeacherListFormValue}
                optional={selectFromTeacherListFormValue}
                hasOptionalText={false}
            />
            <SimpleCheckboxElement
                name={formFields.selectFromTeacherList}
                label={commonTexts.actionLabels.selectFromTeacherList}
                disabled={isViewMode}
            />
            <TeachersPicker
                disabled={isViewMode || !selectFromTeacherListFormValue}
                optional={!selectFromTeacherListFormValue}
                hasOptionalText={false}
                fetchConfig={{
                    fetchFunction: () =>
                        StaffHTTPService.institutions.getInstitutionsEmployees(
                            currentInstitution.id,
                        ),
                    fetchQueryKey: 'INSTITUTIONS_EMPLOYEES' as QueryMainKeysType,
                }}
            />
            {!isViewMode && (
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={commonTexts.actionLabels.save}
                    leftIcon={<IconDeviceFloppy size={20} />}
                />
            )}
        </AppForm>
    );
};
