import { useMemo, useState } from 'react';

export const useAppFormInnerErrorContext = () => {
    const [innerError, setInnerError] = useState('');

    const contextValue = useMemo(
        () => ({ formError: innerError, setFormError: setInnerError }),
        [innerError],
    );

    return { contextValue, innerError };
};
