import { DailyScheduleDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseDailyScheduleDTOIntoJsonObject: (dailyScheduleDTO: DailyScheduleDTO) => object = (
    dailyScheduleDTO,
) => {
    const { description } = dailyScheduleDTO;

    return {
        [jsonObjectsTexts.dailyScheduleDTO.fields.description]: description || EMPTY_VALUE_LABEL,
    };
};
