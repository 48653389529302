import { useEffect, useMemo } from 'react';
import { generatePath, Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import styled from 'styled-components';
import { AppMenu, AppMenuProps } from 'components/atoms';
import { useSettingsAbilities } from 'abilities';
import { settingNavBarOptions } from './menuItems';
import { checkAndRedirect } from './utils';
import { settingsAbilitiesPaths } from './consts';

const StyledAppMenu = styled(AppMenu)`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const SettingsLayout = () => {
    const navigate = useNavigate();
    const outlet = useOutlet();
    const { pathname } = useLocation();
    const { settingsAbilities } = useSettingsAbilities();

    const onMenuItemClick: AppMenuProps['onClick'] = ({ key }) => navigate(key);

    const menuItems = useMemo(
        () =>
            settingNavBarOptions(settingsAbilities).map((menuItem) => ({
                key: generatePath(menuItem.key),
                label: menuItem.label,
            })),
        [settingsAbilities],
    );

    const selectedKeys = useMemo(
        () => menuItems.filter((item) => pathname.includes(item.key)).map((item) => item.key),
        [menuItems, pathname],
    );

    useEffect(() => {
        if (!outlet) {
            checkAndRedirect(settingsAbilities, settingsAbilitiesPaths, navigate);
        }
    }, [navigate, outlet, settingsAbilities]);

    return (
        <div>
            <StyledAppMenu
                mode="horizontal"
                items={menuItems}
                selectedKeys={selectedKeys}
                onClick={onMenuItemClick}
            />
            <Outlet />
        </div>
    );
};
