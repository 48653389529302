import { generatePath } from 'react-router-dom';
import { GroupJournalConsultationAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { GroupConsultationFormFields } from 'types';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO } from 'utils/parsers/groupConsultation/parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO';
import { journalTexts } from 'consts/text';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalConsultationAddPage = () => {
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const handleAddConsultationSuccess = () => {
        displaySuccess(
            journalTexts.journalDetails.common.consultations.successfullyAddedConsultation,
        );
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.consultations.base,
                { journalId },
            ),
            { replace: true },
        );
    };

    const {
        mutate: addConsultation,
        error: addConsultationError,
        isLoading: isAddConsultationLoading,
    } = useAppMutation(StaffHTTPService.groupConsultations.addGroupConsultation, {
        key: ['ADD_CONSULTATION'],
        onSuccess: handleAddConsultationSuccess,
        invalidateQueryKeys: [['CONSULTATIONS', journalId], ['MONTHLY_EVENTS']],
    });

    const handleAddInspectionFinish = (consultationFormData: GroupConsultationFormFields) => {
        const parsedAddConsultationDTO =
            parseGroupConsultationFormFieldsIntoAddUpdateGroupConsultationDTO(consultationFormData);
        addConsultation({ journalId, addGroupConsultationDTO: parsedAddConsultationDTO });
    };

    return (
        <GroupJournalConsultationAddEditForm
            mode="add"
            journalId={journalId}
            axiosError={addConsultationError}
            isLoading={isAddConsultationLoading}
            onFinish={handleAddInspectionFinish}
        />
    );
};
