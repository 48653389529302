export const journalFields = {
    id: 'id',
    institutionId: 'institutionId',
    name: 'name',
    group: 'group',
    type: 'type',
    startDate: 'startDate',
    endDate: 'endDate',
    status: 'status',
    schoolYears: 'schoolYears',
    lessonsType: 'lessonsType',
} as const;
