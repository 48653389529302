import { useRef, useState } from 'react';
import { StepsForm } from 'components/organisms/StepsForm/StepsForm';
import { StepsFormAnswers } from 'types/forms/forms';
import { parseStepsFormAnswersIntoOwnerCreateData } from 'utils/parsers/auth/parseStepsFormAnswersIntoOwnerCreateData';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { signUpErrorHandler } from 'utils/errorHandlers/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'services/firebase/useAuth';
import { appPaths } from 'consts/paths/paths';
import { SignUpStep1 } from './components/Step1/Step1';
import { SignUpStep2 } from './components/Step2/Step2';

export const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const credentialsRef = useRef({
        email: '',
        password: '',
    });
    const { signIn } = useAuth();
    const redirectToSignIn = () => navigate(appPaths.auth['sign-in'], { replace: true });
    const onSuccess = () => {
        const { email, password } = credentialsRef.current;
        if (email && password) {
            signIn({ email, password })
                .then(() => navigate(appPaths.app.base))
                .catch(redirectToSignIn);
        } else {
            redirectToSignIn();
        }
    };
    const { mutate: postOwner, error } = useAppMutation(StaffHTTPService.owner.postOwner, {
        key: ['USER'],
        onSuccess,
        onError: () => setIsLoading(false),
    });
    const parsedError = error ? signUpErrorHandler(error) : '';

    const onFinish = (stepsData: StepsFormAnswers) => {
        const ownerCreateData = parseStepsFormAnswersIntoOwnerCreateData(stepsData);
        credentialsRef.current.email = ownerCreateData.user.email;
        credentialsRef.current.password = ownerCreateData.user.password;
        setIsLoading(true);
        postOwner(ownerCreateData);
    };

    return (
        <StepsForm
            defaultPageMaxWidth={580}
            onFinish={onFinish}
            error={parsedError}
            formPagesNames={['registerStep1', 'registerStep2']}
            components={[SignUpStep1, SignUpStep2]}
            isLoading={isLoading}
        />
    );
};
