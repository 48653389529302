import styled from 'styled-components';
import { Row } from 'antd';

export const StyledRow = styled(Row)`
    align-items: center;
    margin-left: 32px;
`;

export const FiltersContainer = styled(Row)`
    margin-top: 40px;
`;
