import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { appRoutes } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import AdminHTTPService from 'services/HTTPService/admin/AdminHTTPService';
import { SubscriptionId } from 'types';
import { PageBaseLayout } from 'components/layouts';
import { fullInstitutionSubscriptionDTOFields } from 'consts/subscription/fullInstitutionSubscriptionDTOFields';
import { InstitutionDetailsPageContent } from './components';

export const AdminInstitutionDetailsPage = () => {
    const [_, setSearchParams] = useSearchParams();
    const { subscriptionId } = useParams() as { subscriptionId: SubscriptionId };

    const {
        data: institutionSubscriptionDataItem,
        isInitialLoading: isInstitutionSubscriptionDataItemInitialLoading,
        isRefetching: isInstitutionSubscriptionDataItemRefetching,
        isError: isInstitutionSubscriptionDataItemError,
    } = useAppQuery(
        'INSTITUTION_SUBSCRIPTION_DATA_ITEM',
        [subscriptionId],
        () =>
            AdminHTTPService.clientSubscriptions.getInstitutionSubscriptionDataItem(subscriptionId),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useEffect(() => {
        if (!institutionSubscriptionDataItem) {
            return;
        }
        setSearchParams(
            (prevSearchParams) => {
                const { name } =
                    institutionSubscriptionDataItem[
                        fullInstitutionSubscriptionDTOFields.institution
                    ];
                prevSearchParams.set(
                    appRoutes.app.admin.institutions.details.pickedInstitution.base,
                    name,
                );
                return prevSearchParams;
            },
            { replace: true },
        );
    }, [institutionSubscriptionDataItem, setSearchParams]);

    return (
        <PageBaseLayout
            isDataInitialLoading={isInstitutionSubscriptionDataItemInitialLoading}
            isDataRefetching={isInstitutionSubscriptionDataItemRefetching}
            isError={isInstitutionSubscriptionDataItemError}
        >
            <InstitutionDetailsPageContent
                institutionAndOwnerData={institutionSubscriptionDataItem}
            />
        </PageBaseLayout>
    );
};
