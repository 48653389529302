import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { TopAppMenu } from 'components/atoms';
import { settlementTexts } from 'consts/text';
import { SimpleMenuItem } from '../SettingsSettlementLayout/SettingsSettlementLayout';

export const settlementNavBarOptions: SimpleMenuItem[] = [
    {
        key: appPaths.app.settlement.children.base,
        label: settlementTexts.menuLabels.children,
    },
    {
        key: appPaths.app.settlement.staff.base,
        label: settlementTexts.menuLabels.employees,
    },
    {
        key: appPaths.app.settlement.additionalLessons.base,
        label: settlementTexts.menuLabels.extraLessons,
    },
];

export const SettlementLayout = () => {
    const navigate = useNavigate();
    const outlet = useOutlet();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!outlet) {
            navigate(appPaths.app.settlement.children.base);
        }
    }, [navigate, outlet]);

    return (
        <>
            <TopAppMenu items={settlementNavBarOptions} selectedKeys={[pathname]} />
            <Outlet />
        </>
    );
};
