import DOMPurify from 'dompurify';
import { AddUpdateWeeklyScheduleDTO, WeeklyScheduleFormFields, WeekDays } from 'types';
import { textEditorNoContentValidator } from 'utils/validators';

export const parseWeeklyScheduleFormFieldsIntoAddUpdateWeeklyScheduleDTO: (
    weeklyScheduleFormFields: WeeklyScheduleFormFields,
) => AddUpdateWeeklyScheduleDTO = (weeklyScheduleFormFields) => {
    const parsedWeeklyFormFields = Object.entries(weeklyScheduleFormFields).map(([key, value]) => ({
        dayName: key.toUpperCase() as WeekDays,
        description: textEditorNoContentValidator(value) ? '' : DOMPurify.sanitize(value),
    }));

    return { days: parsedWeeklyFormFields };
};
