import { AppButton, AppList } from 'components/atoms';
import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';
import {
    INVALID_PERMISSION_CLASS,
    MODULE_CLASS,
    PERMISSION_CLASS,
    SUBMODULE_CLASS,
    SUBPERMISSION_CLASS,
} from './classes';

export const StyledList = styled(AppList)`
    ${({ theme }) => css`
        &.ant-list-bordered {
            margin: ${theme.sizes.marginSmall}px 0;

            .ant-list-header .ant-list-item {
                padding-inline: 0;
                padding-block: 0;
            }

            .${MODULE_CLASS} {
                .ant-list-item-meta-title {
                    ${getTextStyle('H5')}
                }
            }

            .${SUBMODULE_CLASS} {
                .ant-list-item-meta-title {
                    ${getTextStyle('BodyMediumSemiBold')}
                }
            }

            .${PERMISSION_CLASS} {
                .ant-list-item-meta-title {
                    @media (${theme.sizes.mediaQueries.desktopMin}) {
                        margin-left: ${theme.sizes.marginSmall * 3}px;
                    }
                    ${getTextStyle('BodyMedium')}
                }
            }

            .${SUBPERMISSION_CLASS} {
                .ant-list-item-meta-title {
                    @media (${theme.sizes.mediaQueries.desktopMin}) {
                        margin-left: ${theme.sizes.marginSmall * 7}px;
                    }
                    ${getTextStyle('BodyMedium')}
                }
            }
        }

        .${INVALID_PERMISSION_CLASS} {
            border: 1px solid ${theme.colors.red.red7} !important;
            border-bottom: none !important;
        }

        .${INVALID_PERMISSION_CLASS} + .${INVALID_PERMISSION_CLASS} {
            border-top: none !important;
        }

        .${INVALID_PERMISSION_CLASS}:last-child {
            border-bottom: 1px solid ${theme.colors.red.red7} !important;
        }

        .${PERMISSION_CLASS}.${INVALID_PERMISSION_CLASS}+.${SUBMODULE_CLASS},
            .${PERMISSION_CLASS}.${INVALID_PERMISSION_CLASS}+.${PERMISSION_CLASS}:not(.${INVALID_PERMISSION_CLASS}) {
            border-top: 1px solid ${theme.colors.red.red7} !important;
        }

        :not(.ant-list-header) .ant-list-item:nth-child(odd) {
            &:not(:first-child) {
                border-top: 1px solid ${theme.colors.primary.primary3};
            }
            border-bottom: 1px solid ${theme.colors.primary.primary3};
            background-color: ${theme.colors.primary.primary2};
        }
        .ant-list-empty-text {
            display: none;
        }
    `}
` as typeof AppList;

export const StyledAppButton = styled(AppButton)`
    ${({ theme }) => css`
        &.ant-btn-default {
            height: 40px;
            align-self: flex-end;
            margin-bottom: ${theme.sizes.marginNormal}px;
        }
    `}
`;
