import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { ParentAccountEditPage, ParentAccountDetailsPage, PasswordUpdatePage } from 'pages';
import { AccountLayout } from 'components/layouts';

export const accountRoutes: RouteObject[] = [
    {
        path: appPaths.app.parent.account.base,
        element: <AccountLayout isParent />,
        children: [
            {
                path: appPaths.app.parent.account.details.base,
                element: <ParentAccountDetailsPage />,
            },
            {
                path: appPaths.app.parent.account['password-update'],
                element: <PasswordUpdatePage />,
            },
        ],
    },
    {
        path: appPaths.app.parent.account.details.edit,
        element: <ParentAccountEditPage />,
    },
];
