import { AxiosError } from 'axios';
import { FormNavButtons, WeeklyScheduleDayInputElement } from 'components/molecules';
import {
    CommonFormProps,
    FormModeDependedTexts,
    WeeklyScheduleDTO,
    WeeklyScheduleFormFields,
} from 'types';
import { commonTexts, journalTexts } from 'consts/text';
import { AppForm, AppFormProps } from 'components/organisms';
import { textEditorNoContentValidator } from 'utils/validators';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parseWeeklyScheduleFormFieldsIntoWeeklyScheduleFormFieldsWithoutTags } from 'utils/parsers/schedule/parseWeeklyScheduleFormFieldsIntoWeeklyScheduleFormFieldsWithoutTags';
import { WeeklyScheduleInputsContainer } from './WeeklyScheduleAddEditForm.styled';

const addModeTexts: FormModeDependedTexts = {
    name: 'weeklyScheduleAdd',
    title: journalTexts.journalDetails.groupJournal.schedules.weeklySchedule.addWeeklySchedule,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'weeklyScheduleEdit',
    title: journalTexts.journalDetails.groupJournal.schedules.weeklySchedule.editWeeklySchedule,
    buttonText: commonTexts.actionLabels.save,
};

type DailyScheduleEditFormProps = Omit<
    AppFormProps<WeeklyScheduleFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        onFinish: (weeklyScheduleData: WeeklyScheduleFormFields) => void;
        institutionWeeklySchedule?: WeeklyScheduleDTO;
    };

export const WeeklyScheduleAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    onFinish,
    institutionWeeklySchedule,
    ...props
}: DailyScheduleEditFormProps) => {
    const { displayError } = useSimpleNotification();

    const { days: institutionDays } = institutionWeeklySchedule || {};
    const isAddMode = mode === 'add';
    const { name, title, buttonText } = isAddMode ? addModeTexts : editModeTexts;
    const weeklyScheduleAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    const handleFinish = (weeklyScheduleData: WeeklyScheduleFormFields) => {
        if (mode === 'add') {
            const isFormDataEmpty = Object.values(weeklyScheduleData).every(
                textEditorNoContentValidator,
            );
            if (isFormDataEmpty) {
                displayError(commonTexts.errorMessages.minOneFilledInputRequired);
                return;
            }
        }
        onFinish(weeklyScheduleData);
    };

    return (
        <AppForm<WeeklyScheduleFormFields>
            name={name}
            title={title}
            error={weeklyScheduleAddEditError}
            withGoBack
            maxWidth="none"
            onFinish={handleFinish}
            {...props}
        >
            <WeeklyScheduleInputsContainer>
                {institutionDays?.map((day) => (
                    <WeeklyScheduleDayInputElement
                        key={day.dayName}
                        name={day.dayName.toLowerCase()}
                        label={
                            commonTexts.dayLabels[
                                day.dayName.toLowerCase() as keyof typeof commonTexts.dayLabels
                            ]
                        }
                        initialValue={day.description}
                    />
                ))}
            </WeeklyScheduleInputsContainer>
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                withPlus={isAddMode}
                withDeviceFloppy={!isAddMode}
                checkEqualityFormFieldsParser={
                    parseWeeklyScheduleFormFieldsIntoWeeklyScheduleFormFieldsWithoutTags
                }
            />
        </AppForm>
    );
};
