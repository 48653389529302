import styled, { css } from 'styled-components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { AppSelect, AppSelectProps } from 'components/atoms/CommonAppComponents';
import { ChildNameWithAvatar } from 'components/atoms/ChildNameWithAvatar/ChildNameWithAvatar';
import { ChildId } from 'types';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { currentChildAtom } from 'jotaiAtoms/currentChild';
import { writeLocalStorageChildId } from 'services/localStorage/childId';
import { parseChildIntoSelectOptionType } from 'utils/parsers/child/parseChildIntoSelectOptionType';
import { writeLocalStorageInstitutionId } from 'services/localStorage/institutionId';
import { appPaths } from 'consts/paths/paths';
import { currentInstitutionAtom } from 'jotaiAtoms/currentInstitution';
import { useNavigate } from 'react-router-dom';
import { DropdownRender } from './components';

const StyledAppSelect = styled(AppSelect)`
    ${css`
        .ant-layout-sider-collapsed && {
            div.ant-select-selector {
                padding: 0;

                .ant-select-selection-item {
                    display: block;
                    padding-inline-end: 0;

                    span.ant-avatar {
                        width: 24px;
                        height: 24px;
                    }

                    span.ant-typography {
                        display: none;
                    }
                }
            }
        }
    `}
`;

export const ChildDropdown = () => {
    const userDetails = useAtomValue(userDetailsAtom);
    const setIsLoggedUserDrawerMenuOpen = useSetAtom(isLoggedUserDrawerMenuOpenAtom);
    const setCurrentInstitutionAtom = useSetAtom(currentInstitutionAtom);
    const [currentChild, setCurrentChild] = useAtom(currentChildAtom);
    const navigate = useNavigate();

    const userChildren = userDetails?.children ? userDetails.children : [];
    const childrenToPick = userChildren.map(parseChildIntoSelectOptionType);

    const onChange = (pickedChildId: ChildId) => {
        const pickedChild = userChildren.find(({ id }) => id === pickedChildId);

        const childInstitution = {
            id:
                pickedChild?.institutionAddress.institutionId ||
                userChildren[0].institutionAddress.institutionId,
            name: pickedChild?.institutionAddress.name || userChildren[0].institutionAddress.name,
        };

        setCurrentChild(pickedChild || userChildren[0]);
        writeLocalStorageChildId(pickedChild?.id || userChildren[0].id);
        writeLocalStorageInstitutionId(childInstitution.id);
        setCurrentInstitutionAtom({
            id: childInstitution.id,
            name: childInstitution.name,
        });
        setIsLoggedUserDrawerMenuOpen(false);
        navigate(appPaths.app.base);
    };

    const dropdownRender: AppSelectProps['dropdownRender'] = (menu) => (
        <DropdownRender menu={menu} />
    );
    const optionRender: AppSelectProps['optionRender'] = (option) => (
        <ChildNameWithAvatar name={option.label as string} />
    );
    const labelRender: AppSelectProps['labelRender'] = ({ label }) => (
        <ChildNameWithAvatar name={label as string} />
    );

    return (
        <StyledAppSelect
            value={currentChild.id}
            onChange={onChange}
            options={childrenToPick}
            allowClear={false}
            variant="borderless"
            dropdownRender={dropdownRender}
            optionRender={optionRender}
            labelRender={labelRender}
        />
    );
};
