import { RemoveModal } from 'components/organisms/ModalVariations';
import { commonTexts, timetableTexts } from 'consts/text';
import { AbsenceId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type AbsenceRemoveModalProps = {
    open: boolean;
    onCancel: () => void;
    absenceId: AbsenceId;
    onFinish: () => void;
};
export const AbsenceRemoveModal = ({
    open,
    onCancel,
    absenceId,
    onFinish,
}: AbsenceRemoveModalProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();
    const { id: institutionId } = useCurrentInstitution();

    const onRemoveError = () => {
        displayError(commonTexts.errorMessages.actionExecution);
        onCancel();
    };

    const { mutate: removeAbsence, isLoading: isRemoveAbsenceLoading } = useAppMutation(
        StaffHTTPService.employeeSchedule.removeAbsence,
        {
            key: ['REMOVE_ABSENCE'],
            onSuccess: async (_, variables) => {
                displaySuccess(
                    timetableTexts.absence.notyficationMessages.successfullyRemovedAbsenceMessage,
                );
                //variables is AbsenceId
                await queryClient.invalidateQueries([institutionId, 'ABSENCE', variables]);
                onFinish();
            },
            onError: onRemoveError,
            invalidateQueryKeys: [['EMPLOYEE_SCHEDULE']],
        },
    );
    return (
        <RemoveModal
            open={open}
            title={timetableTexts.absence.removeModalTitle}
            confirmActionLoading={isRemoveAbsenceLoading}
            onCancel={onCancel}
            onOk={() => removeAbsence(absenceId)}
            text={timetableTexts.lessons.removeModalText}
        />
    );
};
