import { useTheme } from 'styled-components';
import { AppFormItemProps, AppLink, AppParagraph, AppPasswordInput } from 'components/atoms';
import { appPaths } from 'consts/paths/paths';
import {
    passwordRepeatValidatingRules,
    passwordValidatingRules,
} from 'consts/passwordValidatingRules';
import { formElementRequiredText } from 'consts/errors/forms';
import { formFields } from 'consts/form/formFields';
import { Rule } from 'rc-field-form/es/interface';
import { authTexts } from 'consts/text/index';
import { ForgotPasswordLinkContainer, PasswordItem } from './PasswordInputElement.styled';
import { PasswordRulesDisplay } from './components/PasswordRulesDisplay/PasswordRulesDisplay';

export type PasswordInputElementProps = Pick<AppFormItemProps, 'name'> & {
    labelPrefix?: string;
    withPasswordForget?: boolean;
    withRulesVisible?: boolean;
    passwordRepeat?: boolean;
    newPassword?: boolean;
    withPasswordValidatingRules?: boolean;
};

const getLabel = (labelPrefix?: string, passwordRepeat?: boolean) => {
    if (passwordRepeat) {
        return labelPrefix ? `Powtórz ${labelPrefix} hasło` : 'Powtórz hasło';
    }
    return labelPrefix ? `${labelPrefix} hasło` : 'Hasło';
};

export const PasswordInputElement = ({
    name,
    labelPrefix,
    withPasswordForget = false,
    withRulesVisible = false,
    passwordRepeat = false,
    newPassword = false,
    withPasswordValidatingRules = true,
}: PasswordInputElementProps) => {
    const theme = useTheme();
    const validationRules: Rule[] = [{ required: true, message: formElementRequiredText }];
    if (passwordRepeat) {
        validationRules.push(...passwordRepeatValidatingRules);
    }
    if (withPasswordValidatingRules) {
        validationRules.push(...passwordValidatingRules);
    }

    const formItemName = name || (passwordRepeat ? formFields.passwordRepeat : formFields.password);

    return (
        <>
            <PasswordItem
                marginBottom={withRulesVisible ? theme.sizes.marginSmall : undefined}
                label={getLabel(labelPrefix, passwordRepeat)}
                name={formItemName}
                rules={validationRules}
                withPasswordForget={withPasswordForget}
                withRulesVisible={withRulesVisible}
            >
                <AppPasswordInput
                    autoComplete={newPassword ? 'new-password' : 'current-password'}
                />
            </PasswordItem>
            {withPasswordForget && (
                <ForgotPasswordLinkContainer>
                    <AppLink to={appPaths.auth['password-reset-request']}>
                        <AppParagraph
                            color={theme.colors.primary.primary8}
                            textType="BodyMSSemiBold"
                        >
                            {authTexts.forgotPasswordLabel}
                        </AppParagraph>
                    </AppLink>
                </ForgotPasswordLinkContainer>
            )}
            {withRulesVisible && <PasswordRulesDisplay />}
        </>
    );
};
