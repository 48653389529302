import { settingsTexts } from 'consts/text';
import {
    HistoryChangeInfoMobileDisplay,
    HistoryChangeRefersToDisplay,
    HistoryChangeRefersToDisplayProps,
    TableTagDisplay,
} from 'components/molecules';
import { historyChangeFields } from 'consts/historyChange/historyChangeFields';
import { historyChangeTypeLabels } from 'consts/historyChange/historyChangeTypeLabels';
import { tagsColorsByHistoryChangeType } from 'consts/historyChange/tagsColorsByHistoryChangeType';
import { AppTableProps } from 'components/organisms';
import { HistoryChangeDTO } from 'types';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const getColumns: (
    onMoreButtonClick: HistoryChangeRefersToDisplayProps['onMoreButtonClick'],
) => AppTableProps<HistoryChangeDTO>['columns'] = (onMoreButtonClick) => [
    {
        title: settingsTexts.historyOfChanges.columnNames.dateWithTime,
        dataIndex: historyChangeFields.createdAt,
        key: historyChangeFields.createdAt,
        render: (createdAt: HistoryChangeDTO['createdAt']) =>
            parseStringIntoFormattedStringDate(
                createdAt,
                'YYYY-MM-DDTHH:mm:ss',
                'DD/MM/YYYY, HH:mm',
            ),
        renderMobile: (_, historyChangeDTO) => (
            <HistoryChangeInfoMobileDisplay record={historyChangeDTO} />
        ),
    },
    {
        title: settingsTexts.historyOfChanges.columnNames.user,
        dataIndex: historyChangeFields.userName,
        key: historyChangeFields.userName,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: settingsTexts.historyOfChanges.columnNames.historyType,
        dataIndex: historyChangeFields.historyType,
        key: historyChangeFields.historyType,
        render: (historyType) => (
            <TableTagDisplay
                value={historyType}
                labelMapper={historyChangeTypeLabels}
                colorMapper={tagsColorsByHistoryChangeType}
            />
        ),
        align: 'center',
        isInvisibleInMobile: true,
    },
    {
        title: settingsTexts.historyOfChanges.columnNames.historyChangeRefersTo,
        dataIndex: historyChangeFields.refersTo,
        key: historyChangeFields.refersTo,
        render: (_, historyChangeDTO) => (
            <HistoryChangeRefersToDisplay
                historyChangeDTO={historyChangeDTO}
                onMoreButtonClick={onMoreButtonClick}
            />
        ),
        isVisibleAsExtendableInMobile: true,
        width: '50%',
    },
];
