import { InspectionDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

export const parseInspectionDTOIntoJsonObject: (inspectionDTO: InspectionDTO) => object = (
    inspectionDTO,
) => {
    const { subject, documentNo, date, supervisionForm, supervisor, teacher } = inspectionDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const teacherJsonObject = {
        [jsonObjectsTexts.inspectionDTO.innerObjects.inspectionTeacherDTO.fields.firstname]:
            teacher.firstName,
        [jsonObjectsTexts.inspectionDTO.innerObjects.inspectionTeacherDTO.fields.lastname]:
            teacher.lastName,
    };

    return {
        [jsonObjectsTexts.inspectionDTO.fields.subject]: subject,
        [jsonObjectsTexts.inspectionDTO.fields.documentNo]: documentNo,
        [jsonObjectsTexts.inspectionDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.inspectionDTO.fields.supervisionForm]: supervisionForm,
        [jsonObjectsTexts.inspectionDTO.fields.supervisor]: supervisor,
        [jsonObjectsTexts.inspectionDTO.fields.teacher]: teacherJsonObject,
    };
};
