import styled, { css } from 'styled-components';
import { AppInfiniteScroll } from 'components/atoms';

export const MessageListContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow: auto;
        background-color: ${theme.colors.background.main};
    `}
`;

export const StyledAppInfiniteScroll = styled(AppInfiniteScroll)`
    ${({ theme }) => css`
        padding: 24px 48px;
        border-bottom: 1px solid ${theme.colors.primary.primary4};

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            padding-inline: ${theme.sizes.marginNormal}px;
        }
    `}
`;
