import { ChangeEvent, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import debounce from 'lodash/debounce';
import { AppSearchInput, NewMailThreadButton } from 'components/atoms';
import { Filter, MailThreadList } from 'components/molecules';
import { commonTexts } from 'consts/text';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { MailThreadId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'consts/pagination';
import { useAppInfiniteQuery } from 'services/reactQuery/useAppInfiniteQuery';
import { parsePageableResponseDataIntoArray } from 'utils/parsers/api/parsePageableResponseDataIntoArray';
import { parseFilterParamsIntoMailThreadsFiltersValues } from 'utils/parsers/mailThread/parseFilterParamsIntoGetJournalsFiltersValues';
import { usePageFilters } from 'hooks/usePageFilters/usePageFilters';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import {
    ALL_MAIL_THREADS_MENU_KEY,
    filters,
    mailThreadsTypes,
} from './MailThreadsPreviewSideBar.consts';
import {
    Container,
    FiltersContainer,
    MailThreadListContainer,
    StyledAppMenu,
} from './MailThreadsPreviewSideBar.styled';

export const MailThreadsPreviewSideBar = () => {
    const { filtersParams, handleChangeFilter } = usePageFilters({ filters });
    const { mailThreadId } = useParams<{ mailThreadId: MailThreadId }>();
    const { statefulNavigate } = useNavigateWithState();
    const isMobile = useIsMobile();
    const theme = useTheme();

    const { type: mailThreadsType, search: searchValue } = useMemo(
        () => parseFilterParamsIntoMailThreadsFiltersValues(filtersParams),
        [filtersParams],
    );

    const {
        data: pageableMailThreadsData,
        isInitialLoading: isMailThreadsDataInitialLoading,
        isRefetching: isMailThreadsDataRefetching,
        isError: isMailThreadsDataError,
        fetchNextPage: fetchNextMailThreadsDataPage,
        hasNextPage: hasNextMailThreadsDataPage,
    } = useAppInfiniteQuery(
        'MAIL_THREADS',
        [mailThreadsType, searchValue],
        ({ pageParam = DEFAULT_PAGE }) =>
            StaffHTTPService.mailThreads.getMailThreads({
                filters: { type: mailThreadsType, search: searchValue },
                paginationParams: { page: pageParam, size: DEFAULT_PAGE_SIZE },
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const handleInputSearchValue = (event: ChangeEvent<HTMLInputElement>) =>
        handleChangeFilter('search', event.target.value);

    const handleMailThreadSelect = (selectedMailThreadId: MailThreadId) =>
        statefulNavigate(
            generatePath(appPaths.app.messages.pickedMailThread, {
                mailThreadId: selectedMailThreadId,
            }),
        );

    const handleNewMailThreadButtonClick = () => statefulNavigate(appPaths.app.messages.add);

    const mailThreadsData = useMemo(() => {
        if (!pageableMailThreadsData) {
            return undefined;
        }
        return parsePageableResponseDataIntoArray(pageableMailThreadsData);
    }, [pageableMailThreadsData]);

    return (
        <Container>
            <FiltersContainer>
                {isMobile && (
                    <NewMailThreadButton
                        marginBottom={12}
                        onClick={handleNewMailThreadButtonClick}
                    />
                )}
                <Filter
                    input={
                        <AppSearchInput
                            onInput={debounce(handleInputSearchValue, DEBOUNCE_DELAY)}
                        />
                    }
                    label={commonTexts.actionLabels.searchTwo}
                />
                <StyledAppMenu
                    mode="horizontal"
                    items={mailThreadsTypes}
                    selectedKeys={[mailThreadsType ?? ALL_MAIL_THREADS_MENU_KEY]}
                    onClick={(menuInfo) => handleChangeFilter('type', menuInfo.key)}
                />
            </FiltersContainer>
            <MailThreadListContainer>
                <MailThreadList
                    mailThreadId={mailThreadId}
                    mailThreadsData={mailThreadsData}
                    searchValue={searchValue}
                    isInitialLoading={isMailThreadsDataInitialLoading}
                    isRefetching={isMailThreadsDataRefetching}
                    isError={isMailThreadsDataError}
                    hasNextDataPage={hasNextMailThreadsDataPage}
                    fetchNextDataPage={fetchNextMailThreadsDataPage}
                    handleSelect={handleMailThreadSelect}
                />
                {!isMobile && (
                    <NewMailThreadButton
                        marginTop={theme.sizes.marginNormal}
                        onClick={handleNewMailThreadButtonClick}
                    />
                )}
            </MailThreadListContainer>
        </Container>
    );
};
