import { AppForm, AppFormProps } from 'components/organisms';
import { FormNavButtons, SimpleInputElement, CurrencyInputElement } from 'components/molecules';
import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { DietsFormFields } from 'types';
import { commonTexts, settingsTexts } from 'consts/text/index';
import { formFields } from 'consts/form/formFields';

export type DietAddEditFormProps = Omit<
    AppFormProps<DietsFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    onFinish: (dietData: DietsFormFields) => void;
    axiosError: AxiosError | null;
    isLoading: boolean;
    type: 'add' | 'edit';
};
export const DietAddEditForm = ({
    type,
    initialValues,
    onFinish,
    axiosError,
    ...props
}: DietAddEditFormProps) => {
    const isAddFlow = type === 'add';
    const title = isAddFlow ? settingsTexts.diets.addDiet : settingsTexts.diets.editDiet;
    const name = isAddFlow ? 'dietAdd' : 'dietEdit';
    const buttonText = isAddFlow ? commonTexts.actionLabels.add : commonTexts.actionLabels.save;
    const buttonIcon = isAddFlow ? undefined : <IconDeviceFloppy size={20} />;
    const dietAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<DietsFormFields>
            title={title}
            name={name}
            onFinish={onFinish}
            error={dietAddEditError}
            withGoBack
            {...props}
        >
            <SimpleInputElement label={settingsTexts.diets.columnNames.name} name="name" />
            <CurrencyInputElement
                optional
                label={settingsTexts.diets.columnNames.breakfastPrice}
                name={formFields.breakfastPrice}
            />
            <CurrencyInputElement
                optional
                label={settingsTexts.diets.columnNames.soupPrice}
                name={formFields.soupPrice}
            />
            <CurrencyInputElement
                optional
                label={settingsTexts.diets.columnNames.dinnerPrice}
                name={formFields.dinnerPrice}
            />
            <CurrencyInputElement
                optional
                label={settingsTexts.diets.columnNames.snackPrice}
                name={formFields.snackPrice}
            />

            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddFlow}
            />
        </AppForm>
    );
};
