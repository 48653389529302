import { PARAM_FALSE_VALUE, PARAM_TRUE_VALUE, ParamBoolean } from 'consts/paramsConsts';

const STEP_KEY = 'step';
const FINISHED_KEY = 'finished';

export type Parameters = Record<string, string | string[]>;
export const getParamsToSet: (
    step: number,
    options: {
        finished?: ParamBoolean;
        customParameters?: Parameters;
    },
) => Parameters = (step, { finished = PARAM_FALSE_VALUE, customParameters = {} }) => ({
    [STEP_KEY]: step.toString(),
    [FINISHED_KEY]: finished,
    ...customParameters,
});
export const getStepParamStepAsNumber = (searchParams: URLSearchParams) =>
    parseInt(searchParams?.get(STEP_KEY) || '0', 10);
export const getStepParamStepAsBoolean = (searchParams: URLSearchParams) =>
    searchParams?.get(FINISHED_KEY) === PARAM_TRUE_VALUE;
