import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppText } from 'components/atoms';
import { PersonListProps } from 'components/molecules/List';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import {
    InnerDesktopListContainer,
    StyledPersonList,
    OuterDesktopListContainer,
} from './PersonListDisplay.styled';

type PersonListDisplayProps = {
    personsData: PersonListProps['personsData'];
    desktopLabel?: string;
};

export const PersonListDisplay = ({ personsData, desktopLabel }: PersonListDisplayProps) => {
    const isMobile = useIsMobile();

    const content = personsData.length ? (
        <StyledPersonList personsData={personsData} />
    ) : (
        EMPTY_VALUE_LABEL
    );

    return isMobile ? (
        content
    ) : (
        <OuterDesktopListContainer>
            <InnerDesktopListContainer>
                {desktopLabel && (
                    <AppText margin={0} marginBottom={8} textType="BodyMediumSemiBold">
                        {desktopLabel}
                    </AppText>
                )}
                {content}
            </InnerDesktopListContainer>
        </OuterDesktopListContainer>
    );
};
