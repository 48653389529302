import { RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    SpecialJournalConsultationDetailsPage,
    SpecialJournalConsultationEditPage,
    SpecialJournalConsultationAddPage,
} from 'pages';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { JournalNavigate } from 'navigation/routes/loggedApp/routes/staff/journal/components';

export const journalConsultationRoutes: RouteObject[] = [
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_CONSULTATIONS,
                }}
            >
                <SpecialJournalConsultationAddPage />
            </AbilityRoute>
        ),
    },

    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.edit.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.edit
            .pickedConsultation,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_CONSULTATIONS,
                }}
            >
                <SpecialJournalConsultationEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.details.base,
        element: (
            <JournalNavigate
                to={appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.base}
            />
        ),
    },
    {
        path: appPaths.app.journals.specialJournalDetails.pickedJournal.consultations.details
            .pickedConsultation,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW,
                    subject: PERMISSIONS_NAME.SPECIAL_JOURNALS_CONSULTATIONS,
                }}
            >
                <SpecialJournalConsultationDetailsPage />
            </AbilityRoute>
        ),
    },
];
