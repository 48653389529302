import {
    PARENT_DAILY_SCHEDULE,
    PARENT_INDIVIDUAL_SCHEDULE,
    PARENT_WEEKLY_SCHEDULE,
} from 'services/HTTPService/parent/endpoints/schedules';
import {
    AddGuardianDTO,
    AddMessageDTO,
    CalendarEventDetailedDtos,
    Child,
    ChildDiet,
    ChildForParentDto,
    ChildId,
    ChildProfilePhotoDTO,
    DailyScheduleDTO,
    DietDTO,
    DiscountDTO,
    FileDirectory,
    FileMetaDTO,
    GuardianId,
    MailPageMessageWithoutReceiversDTO,
    MailThreadDTO,
    MailThreadId,
    MailThreadReceiverDTO,
    MenuDTO,
    MessagesPaginationParams,
    MessageWithoutReceiversDTO,
    MonthlyChildGroupAttendanceDTO,
    PaginationParams,
    PaginationResponse,
    ParentAddMailThreadDTO,
    ParentProfileDTO,
    TimetableDTO,
    UpdateChildForParentDTO,
    UpdateParentProfileDTO,
    WeeklyScheduleDTO,
    AddChildAbsencesParams,
    ParentJournalDatesDTO,
    MonthlySettlementSimpleDTO,
    ChildSettlementId,
    ChildCostAllocationBasic,
    SettlementTransferInformationDTO,
    ChildSettlementDetails,
} from 'types';
import {
    CALENDAR_EVENTS_DAILY,
    CALENDAR_EVENTS_MONTHLY,
} from 'services/HTTPService/parent/endpoints/calendar';
import {
    CHILD_DETAILS,
    CHILD_GUARDIAN_BASE,
    CHILD_GUARDIAN,
    CHILD_PROFILE_PHOTO,
    CHILD_PARENT_PROFILE,
} from 'services/HTTPService/parent/endpoints/children';
import { DISCOUNTS } from 'services/HTTPService/parent/endpoints/discounts';
import { RcFile } from 'antd/es/upload';
import { REQUEST_FORM_FILE } from 'consts/api/requestFormFile';
import {
    ADD_TEMPORARY_FILE,
    FILE,
    GET_FILES_IN_ZIP,
} from 'services/HTTPService/parent/endpoints/files';
import {
    COST_ALLOCATIONS,
    MONTHLY_SETTLEMENT,
    SETTLEMENT_TRANSFER_INFORMATION,
    SETTLEMENTS_BASE,
} from 'services/HTTPService/parent/endpoints/settlements';
import { DIET_CHILD, DIETS } from 'services/HTTPService/parent/endpoints/diets';
import { MENU } from 'services/HTTPService/parent/endpoints/menus';
import {
    MAIL_THREAD,
    MAIL_THREAD_MESSAGES,
    MAIL_THREAD_RECEIVERS,
    MAIL_THREADS,
    REPLY_MAIL_THREAD,
    SUBSCRIBE_TO_NOTIFICATIONS,
    UNREAD_MAIL_THREADS,
} from 'services/HTTPService/parent/endpoints/mailThreads';
import {
    GROUP_ATTENDANCE,
    GROUP_MONTHLY_ATTENDANCE,
} from 'services/HTTPService/parent/endpoints/groupAttendance';
import { JOURNAL_DATES } from 'services/HTTPService/parent/endpoints/journal';
import ParentAxiosInstance from './ParentAxiosInstance';

class ParentHTTPService {
    private axios = new ParentAxiosInstance();

    public groupAttendance = {
        getGroupMonthlyAttendance: (month: number, year: number) =>
            this.axios.get<MonthlyChildGroupAttendanceDTO>(GROUP_MONTHLY_ATTENDANCE, {
                params: { month, year },
            }),
        addAbsences: ({ startDate, endDate }: AddChildAbsencesParams) =>
            this.axios.patch(GROUP_ATTENDANCE, undefined, {
                params: { startDate, endDate },
            }),
        deleteAbsence: (date: string) => this.axios.delete(GROUP_ATTENDANCE, { params: { date } }),
    };

    public journal = {
        getJournalDates: () => this.axios.get<ParentJournalDatesDTO>(JOURNAL_DATES),
    };

    public schedules = {
        getDailySchedule: () => this.axios.get<DailyScheduleDTO>(PARENT_DAILY_SCHEDULE),
        getWeeklySchedule: () => this.axios.get<WeeklyScheduleDTO>(PARENT_WEEKLY_SCHEDULE),
        getIndividualSchedule: (date: string) =>
            this.axios.get<TimetableDTO>(PARENT_INDIVIDUAL_SCHEDULE, { params: { date } }),
    };

    public calendar = {
        getMonthlyEvents: ({ month, year }: { month: number; year: number }) =>
            this.axios.get<CalendarEventDetailedDtos>(CALENDAR_EVENTS_MONTHLY, {
                params: { month, year },
            }),
        getDailyEvents: ({ date }: { date: string }) =>
            this.axios.get<string[]>(CALENDAR_EVENTS_DAILY, {
                params: { date },
            }),
    };

    public children = {
        getChild: (childId: ChildId) =>
            this.axios.get<ChildForParentDto>(CHILD_DETAILS(childId), {
                params: { childId },
            }),
        updateChild: ({
            childId,
            updateChildDTO,
        }: {
            childId: ChildId;
            updateChildDTO: UpdateChildForParentDTO;
        }) => this.axios.put<ChildForParentDto>(CHILD_DETAILS(childId), updateChildDTO),
        guardian: {
            addGuardian: ({
                childId,
                childGuardian,
            }: {
                childId: ChildId;
                childGuardian: AddGuardianDTO;
            }) => this.axios.post<Child>(CHILD_GUARDIAN_BASE(childId), childGuardian),
            updateGuardian: ({
                childId,
                guardianId,
                childGuardian,
            }: {
                childId: ChildId;
                guardianId: GuardianId;
                childGuardian: AddGuardianDTO;
            }) => this.axios.put<Child>(CHILD_GUARDIAN(childId, guardianId), childGuardian),
            deleteGuardian: ({
                childId,
                guardianId,
            }: {
                childId: ChildId;
                guardianId: GuardianId;
            }) => this.axios.delete<Child>(CHILD_GUARDIAN(childId, guardianId)),
        },
        profilePhoto: {
            updateProfilePhoto: ({
                childId,
                childProfilePhotoDTO,
            }: {
                childId: ChildId;
                childProfilePhotoDTO: ChildProfilePhotoDTO;
            }) =>
                this.axios.put<ChildProfilePhotoDTO>(
                    CHILD_PROFILE_PHOTO(childId),
                    childProfilePhotoDTO,
                ),
            deleteProfilePhoto: ({ childId }: { childId: ChildId }) =>
                this.axios.delete(CHILD_PROFILE_PHOTO(childId)),
        },
        parentProfile: {
            getParentProfile: (childId: ChildId) =>
                this.axios.get<ParentProfileDTO>(CHILD_PARENT_PROFILE(childId)),
            updateParentProfile: ({
                childId,
                updateParentProfileDTO,
            }: {
                childId: ChildId;
                updateParentProfileDTO: UpdateParentProfileDTO;
            }) =>
                this.axios.patch<ParentProfileDTO>(
                    CHILD_PARENT_PROFILE(childId),
                    updateParentProfileDTO,
                ),
        },
    };

    public settlements = {
        getMonthlySettlement: ({ year, month }: { month: number; year: number }) =>
            this.axios.get<MonthlySettlementSimpleDTO>(MONTHLY_SETTLEMENT, {
                params: { year, month },
            }),
        getCostAllocations: (settlementId: ChildSettlementId) =>
            this.axios.get<ChildCostAllocationBasic[]>(COST_ALLOCATIONS, {
                params: { monthlySettlementId: settlementId },
            }),
        getSettlementTransferInformation: ({ year, month }: { year: number; month: number }) =>
            this.axios.get<SettlementTransferInformationDTO>(SETTLEMENT_TRANSFER_INFORMATION, {
                params: { year, month },
            }),
        getSettlementForChild: () => this.axios.get<ChildSettlementDetails>(SETTLEMENTS_BASE),
    };

    public files = {
        addFile: ({
            directory,
            file,
        }: {
            directory: FileDirectory;
            file: string | Blob | RcFile;
        }) => {
            const formData = new FormData();
            formData.append(REQUEST_FORM_FILE, file);
            return this.axios.post<FileMetaDTO>(ADD_TEMPORARY_FILE, formData, {
                params: { directory },
            });
        },
        getFile: (fileUri: string) =>
            this.axios.get<string>(FILE, {
                responseType: 'arraybuffer',
                params: { fileUri },
            }),
        getFilesInZip: (files: string[]) =>
            this.axios.post<string>(
                GET_FILES_IN_ZIP,
                { fileUris: files },
                {
                    responseType: 'arraybuffer',
                },
            ),
    };

    public diets = {
        getDietForChild: () => this.axios.get<ChildDiet>(DIET_CHILD),
        getDiets: () => this.axios.get<DietDTO[]>(DIETS),
    };

    public menus = {
        getMenu: () => this.axios.get<MenuDTO>(MENU),
    };

    public discounts = {
        getDiscounts: () => this.axios.get<DiscountDTO[]>(DISCOUNTS),
    };

    public mailThreads = {
        getMailThreads: ({
            search,
            paginationParams,
        }: {
            search?: string;
            paginationParams?: PaginationParams;
        }) =>
            this.axios.get<PaginationResponse<MailThreadDTO>>(MAIL_THREADS, {
                params: { search, ...paginationParams },
            }),
        getMailThread: (mailThreadId: MailThreadId) =>
            this.axios.get<MailThreadDTO>(MAIL_THREAD(mailThreadId)),
        getReplyMailThread: (mailThreadId: MailThreadId) =>
            this.axios.get<MailThreadDTO | null>(REPLY_MAIL_THREAD(mailThreadId)),
        addMailThread: (parentAddMailThreadDTO: ParentAddMailThreadDTO) =>
            this.axios.post<MailThreadDTO>(MAIL_THREADS, parentAddMailThreadDTO),
        getMailThreadReceivers: () =>
            this.axios.get<MailThreadReceiverDTO[]>(MAIL_THREAD_RECEIVERS),
        notifications: {
            subscribeToUnreadMessageNotifications: (firebaseMessagingToken: string) =>
                this.axios.post(SUBSCRIBE_TO_NOTIFICATIONS, null, {
                    params: { firebaseMessagingToken },
                }),
            getUnreadThreads: () => this.axios.get<boolean>(UNREAD_MAIL_THREADS),
        },
        messages: {
            addMessage: ({
                mailThreadId,
                addMessageDTO,
            }: {
                mailThreadId: MailThreadId;
                addMessageDTO: AddMessageDTO;
            }) =>
                this.axios.post<MessageWithoutReceiversDTO>(
                    MAIL_THREAD_MESSAGES(mailThreadId),
                    addMessageDTO,
                ),
            getMailThreadMessages: ({
                mailThreadId,
                paginationParams,
            }: {
                mailThreadId: MailThreadId;
                paginationParams?: MessagesPaginationParams;
            }) =>
                this.axios.get<MailPageMessageWithoutReceiversDTO>(
                    MAIL_THREAD_MESSAGES(mailThreadId),
                    {
                        params: { threadId: mailThreadId, ...paginationParams },
                    },
                ),
        },
    };
}

export default new ParentHTTPService();
