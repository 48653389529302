import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';

export const Header = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['smallHeaderMargin', 'headerColor']),
})<{ smallHeaderMargin: boolean; headerColor: string }>`
    ${({ theme, smallHeaderMargin, headerColor }) => css`
        display: flex;
        flex-direction: row;
        margin-bottom: ${smallHeaderMargin ? theme.sizes.marginNormal : theme.sizes.marginLarge}px;
        align-items: center;
        color: ${headerColor};
    `}
`;
