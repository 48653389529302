import {
    AppForm,
    allowanceFields,
    coFinancingFields,
    percentageDiscountFields,
} from 'components/organisms';
import { AppFormItem, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { formFields } from 'consts/form/formFields';
import { FormNavButtons } from 'components/molecules';
import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { DiscountFormFields, ChildId, DiscountType, DiscountTarget } from 'types';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { commonTexts, settlementTexts } from 'consts/text';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { parseAddDiscountsFormFieldsToAddDiscountDTO } from 'utils/parsers/discounts/parseAddDiscountsFormFieldsToAddDiscountDTO';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useChildrenDetailsPath } from 'hooks/useChildrenDetailsPath/useChildrenDetailsPath';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ChildAddDiscountPage = () => {
    const [form] = Form.useForm();
    const { displaySuccess, displayError } = useSimpleNotification();
    const { childId } = useParams<{ childId: ChildId }>();
    const { statefulNavigate } = useNavigateWithState();
    const { getChildrenDetailsPath } = useChildrenDetailsPath();

    const discountTarget = useWatch(formFields.discountTarget, form);
    const discountType = useWatch(formFields.discountType, form);

    const navigateToChildPage = () => {
        if (!childId) {
            return;
        }
        const path = getChildrenDetailsPath('', childId);
        statefulNavigate(path);
    };

    const handleAddDiscountSuccess = () => {
        displaySuccess(settlementTexts.discounts.general.addSuccess);
        navigateToChildPage();
    };

    const handleAddDiscountError = () => {
        displayError(commonTexts.errorMessages.actionExecution);
    };

    const { data: extraLessonsData, isFetching: isFetchingExtraLessons } = useAppQuery(
        'EXTRA_LESSONS',
        [childId],
        () => StaffHTTPService.settlements.getExtraLessonsByChild(childId as ChildId),
        {
            enabled: discountTarget === DiscountTarget.EXTRA_LESSONS,
            refetchOnWindowFocus: false,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const { mutate: addDiscount, isLoading: isAddDiscountLoading } = useAppMutation(
        StaffHTTPService.discounts.addDiscount,
        {
            key: ['ADD_DISCOUNT'],
            onSuccess: handleAddDiscountSuccess,
            onError: handleAddDiscountError,
            invalidateQueryKeys: [['CHILD_DISCOUNTS', childId]],
        },
    );

    const onFinishForm = (data: DiscountFormFields) => {
        if (!childId) {
            return;
        }

        const parsedDiscount = parseAddDiscountsFormFieldsToAddDiscountDTO(childId, data);
        addDiscount(parsedDiscount);
    };

    const renderFieldsBasedOnType = () => {
        if (!discountTarget || !discountType) {
            return undefined;
        }

        switch (discountType) {
            case DiscountType.PERCENTAGE_DISCOUNT:
                return percentageDiscountFields(
                    discountTarget,
                    extraLessonsData,
                    isFetchingExtraLessons,
                );
            case DiscountType.CO_FINANCING:
                return coFinancingFields(discountTarget, extraLessonsData, isFetchingExtraLessons);
            case DiscountType.ALLOWANCE:
                return allowanceFields(discountTarget, extraLessonsData, isFetchingExtraLessons);
            default:
                return undefined;
        }
    };

    return (
        <AppForm<DiscountFormFields>
            form={form}
            name="addDiscount"
            title={settlementTexts.discounts.addEditForm.addTitle}
            withGoBack
            onFinish={onFinishForm}
            isLoading={isAddDiscountLoading}
        >
            <AppFormItem
                label={settlementTexts.discounts.addEditForm.discountTarget}
                name={formFields.discountTarget}
            >
                <AppRadioGroup sameSizeOptionButtons={false}>
                    <AppRadioButton value={DiscountTarget.MEAL}>
                        {settlementTexts.discounts.general.meals}
                    </AppRadioButton>
                    <AppRadioButton value={DiscountTarget.STAY}>
                        {settlementTexts.discounts.general.stay}
                    </AppRadioButton>
                    <AppRadioButton value={DiscountTarget.EXTRA_LESSONS}>
                        {settlementTexts.discounts.general.extraLessons}
                    </AppRadioButton>
                </AppRadioGroup>
            </AppFormItem>
            <AppFormItem
                label={settlementTexts.discounts.addEditForm.discountType}
                name={formFields.discountType}
            >
                <AppRadioGroup>
                    <AppRadioButton value={DiscountType.PERCENTAGE_DISCOUNT}>
                        {settlementTexts.discounts.general.percentageDiscount}
                    </AppRadioButton>
                    <AppRadioButton value={DiscountType.CO_FINANCING}>
                        {settlementTexts.discounts.general.coFinancing}
                    </AppRadioButton>
                    <AppRadioButton value={DiscountType.ALLOWANCE}>
                        {settlementTexts.discounts.general.allowance}
                    </AppRadioButton>
                </AppRadioGroup>
            </AppFormItem>
            {renderFieldsBasedOnType()}
            <FormNavButtons
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
