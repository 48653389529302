import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useTheme } from 'styled-components';
import { AppFormBanner, AppParagraph, AppTitle } from 'components/atoms';
import { FormHeaderContainer } from './FormHeader.styled';

export type FormHeaderProps = {
    title?: string;
    desktopTitleLevel?: 3 | 4;
    subTitle?: string;
    error?: string;
    titleAlign?: 'center' | 'right' | 'left';
};
export const FormHeader = ({
    title,
    desktopTitleLevel = 3,
    subTitle,
    error,
    titleAlign = 'center',
}: FormHeaderProps) => {
    const { sizes } = useTheme();
    const isMobile = useIsMobile();

    return (
        <FormHeaderContainer>
            <div>
                {title && (
                    <AppTitle
                        textAlign={titleAlign}
                        margin={0}
                        marginBottom={subTitle ? sizes.marginNormal : undefined}
                        level={isMobile ? 4 : desktopTitleLevel}
                    >
                        {title}
                    </AppTitle>
                )}
                {subTitle && (
                    <AppParagraph
                        textAlign="center"
                        marginBottom={sizes.marginNormal}
                        margin={0}
                        textType="BodyMedium"
                    >
                        {subTitle}
                    </AppParagraph>
                )}
            </div>
            {error && <AppFormBanner mode="ERROR" text={error} />}
        </FormHeaderContainer>
    );
};
