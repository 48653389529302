import { MailThreadReceiverDTO } from 'types';
import { getFullAuthorName } from 'utils/getFullAuthorName';
import { ExtendedDataNode } from 'components/atoms';

export const parseMailThreadReceiverDTOIntoExtendedDataNode: (
    mailThreadReceiverDTO: MailThreadReceiverDTO,
) => ExtendedDataNode = (mailThreadReceiverDTO) => {
    const { userId, name, subName } = mailThreadReceiverDTO;
    return {
        title: getFullAuthorName(name, subName),
        key: userId,
    };
};
