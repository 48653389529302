import { Dayjs } from 'dayjs';
import { NamePath, RuleObject } from 'rc-field-form/es/interface';
import { isNumberOnly } from 'consts/regexps';
import { AppFormRangeItemMode } from 'types';
import { HOUR_IN_MINUTES, THIRTY_MINUTES } from 'consts/timeConsts';

const BANK_ACCOUNT_NUMBER_DIGITS_AMOUNT = 26;

export const regonValidator = (value: string) => {
    const controlSum =
        ((Number(value[0]) * 8 +
            Number(value[1]) * 9 +
            Number(value[2]) * 2 +
            Number(value[3]) * 3 +
            Number(value[4]) * 4 +
            Number(value[5]) * 5 +
            Number(value[6]) * 6 +
            Number(value[7]) * 7) %
            11) %
        10;

    if (value.length === 9) {
        return controlSum === Number(value[8]) || false;
    } else if (value.length === 14) {
        const secondControlSum =
            ((Number(value[0]) * 2 +
                Number(value[1]) * 4 +
                Number(value[2]) * 8 +
                Number(value[3]) * 5 +
                // fourth value is multiplied by 0
                Number(value[5]) * 9 +
                Number(value[6]) * 7 +
                Number(value[7]) * 3 +
                Number(value[8]) * 6 +
                Number(value[9]) +
                Number(value[10]) * 2 +
                Number(value[11]) * 4 +
                Number(value[12]) * 8) %
                11) %
            10;

        return (controlSum === Number(value[8]) && secondControlSum === Number(value[13])) || false;
    }
    return false;
};

export const rspoValidator = (value: string) =>
    (value.length > 3 && isNumberOnly.test(value)) || false;

export const datesTimelineValidator: (
    getFieldValue: (name: NamePath) => Dayjs,
    config: {
        mode: AppFormRangeItemMode;
        startValueFieldName: string;
        finishValueFieldName: string;
        isStartValueAfterFinishValueErrorText: string;
        isFinishValueBeforeStartValueErrorText: string;
        unitToCompare: 'day' | 'minute';
    },
) => { validator: (_: RuleObject, value: Dayjs) => Promise<void> } = (
    getFieldValue,
    {
        mode,
        startValueFieldName,
        finishValueFieldName,
        isStartValueAfterFinishValueErrorText,
        isFinishValueBeforeStartValueErrorText,
        unitToCompare,
    },
) => ({
    validator(_, value) {
        const oppositeAccountingPeriodValue = getFieldValue(
            mode === 'start' ? finishValueFieldName : startValueFieldName,
        );
        if (!value || !oppositeAccountingPeriodValue) {
            return Promise.resolve();
        }
        if (mode === 'start' && value.isAfter(oppositeAccountingPeriodValue, unitToCompare)) {
            return Promise.reject(new Error(isStartValueAfterFinishValueErrorText));
        }
        if (mode === 'finish' && value.isBefore(oppositeAccountingPeriodValue, unitToCompare)) {
            return Promise.reject(new Error(isFinishValueBeforeStartValueErrorText));
        }
        return Promise.resolve();
    },
});

export const bankAccountNumberValidator = (value: string) => {
    const bankAccountNumberDigits = value.replaceAll(' ', '');
    return bankAccountNumberDigits.length === BANK_ACCOUNT_NUMBER_DIGITS_AMOUNT;
};

export const textEditorNoContentValidator = (text: string) =>
    text.replace(/<(.|\n)*?>/g, '').trim().length === 0;

export const timeRangeInMinutesEqualLimitOrLessThanLimitBy30MinutesValidator: (
    getFieldValue: (name: NamePath) => unknown,
    config: {
        timeStartValueFieldName: string;
        timeLimitValueInHoursFieldName: string;
        isTimeRangeNotWithinLimitErrorText: string;
    },
) => { validator: (_: RuleObject, timeEndValue: Dayjs) => Promise<void> } = (
    getFieldValue,
    { timeStartValueFieldName, timeLimitValueInHoursFieldName, isTimeRangeNotWithinLimitErrorText },
) => ({
    validator(_, timeEndValue) {
        const timeLimitInMinutesValue =
            (getFieldValue(timeLimitValueInHoursFieldName) as number) * HOUR_IN_MINUTES;
        const timeStartValue = getFieldValue(timeStartValueFieldName);
        if (timeLimitInMinutesValue && timeStartValue && timeEndValue) {
            const timeRangeInMinutes = (timeEndValue as Dayjs).diff(
                timeStartValue as Dayjs,
                'minute',
            );
            const isTimeRangeEqualTimeLimit = timeRangeInMinutes === timeLimitInMinutesValue;
            const isTimeRangeLessThanTimeLimitBy30Minutes =
                timeLimitInMinutesValue - timeRangeInMinutes === THIRTY_MINUTES;
            if (isTimeRangeEqualTimeLimit || isTimeRangeLessThanTimeLimitBy30Minutes) {
                return Promise.resolve();
            }
        }
        return Promise.reject(new Error(isTimeRangeNotWithinLimitErrorText));
    },
});
