import { AppModal, FormHeader, FormNavButtons, SimpleSelectElement } from 'components/molecules';
import { AppModalForm, AppModalFormProps } from 'components/organisms';
import { CurrencyInputElement } from 'components/molecules/FormElements/CurrencyInputElement/CurrencyInputElement';
import { formFields } from 'consts/form/formFields';
import { commonTexts, settlementTexts } from 'consts/text';
import { HeaderContainer } from 'components/organisms/AppForm/AppForm.styled';
import { DetailsColumn, DetailsFrameBase, DetailsRow } from 'components/atoms';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { paymentStatusOptions, paymentTypeOptions } from 'consts/settlementChildren/paymentOptions';
import { FormPageModeDependedTexts, PaymentFormFields } from 'types';
import { AxiosError } from 'axios';
import { ApiErrorType } from 'services/reactQuery/apiErrorType';

type ChildrenPaymentAddEditModalProps = Omit<
    AppModalFormProps<PaymentFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    axiosError?: AxiosError | null | ApiErrorType;
    isLoading?: boolean;
    onFinish: (eventDate: PaymentFormFields) => void;
    onCancel: () => void;
};

const addModeTexts: FormPageModeDependedTexts = {
    title: settlementTexts.children.addPayment.title,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormPageModeDependedTexts = {
    title: settlementTexts.children.editPayment.title,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const ChildrenPaymentModalAddEditForm = ({
    onCancel,
    initialValues,
    onFinish,
    axiosError,
    mode = 'add',
    form,
    ...props
}: ChildrenPaymentAddEditModalProps) => {
    const isAddMode = mode === 'add';
    const { title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const paymentAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppModal open onCancel={onCancel} destroyOnClose>
            <AppModalForm name="editPayment" form={form} {...props} onFinish={onFinish}>
                <HeaderContainer isFormHeaderAtBottom={false}>
                    <FormHeader title={title} desktopTitleLevel={4} error={paymentAddEditError} />
                </HeaderContainer>
                <DetailsFrameBase hideHeader noFrame={false} marginEnd={16}>
                    <DetailsRow>
                        <DetailsColumn title={settlementTexts.children.editPayment.amountToPay}>
                            {EMPTY_VALUE_LABEL}
                        </DetailsColumn>
                    </DetailsRow>
                </DetailsFrameBase>
                <SimpleSelectElement
                    name={formFields.paymentType}
                    label={settlementTexts.children.costAllocation.costAllocationTarget}
                    options={paymentTypeOptions}
                />
                <CurrencyInputElement
                    name={formFields.amount}
                    label={settlementTexts.children.editPayment.amount}
                />
                <SimpleSelectElement
                    name={formFields.status}
                    label={settlementTexts.children.editPayment.status}
                    options={paymentStatusOptions}
                />
                <FormNavButtons
                    formValuesBeforeChanges={initialValues}
                    buttonText={buttonText}
                    leftIcon={buttonIcon}
                    withPlus={isAddMode}
                    marginBottom={0}
                />
            </AppModalForm>
        </AppModal>
    );
};
