import { useContext, useMemo } from 'react';
import { TeachersPickerElement, TeachersPickerElementProps } from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { employeeTexts } from 'consts/text';
import { EmployeeDTO, EmployeeId, EmployeeUser, SelectOptionType } from 'types';
import { AxiosResponse } from 'axios';
import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';
import { parseEmployeeDTOIntoSelectOptionType } from 'utils/parsers/employee/parseEmployeeDTOIntoSelectOptionType';
import { parseEmployeeUserIntoSelectOptionType } from 'utils/parsers/employee/parseEmployeeUserIntoSelectOptionType';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type TeachersPickerProps = Pick<
    TeachersPickerElementProps,
    'version' | 'disabled' | 'optional' | 'hasOptionalText' | 'label'
> & {
    onChange?: (teacherIds: EmployeeId | EmployeeId[]) => void;
    fetchConfig: {
        fetchFunction: () => Promise<AxiosResponse<EmployeeDTO[] | EmployeeUser[], undefined>>;
        fetchQueryKey: QueryMainKeysType;
    };
};

export const TeachersPicker = ({ fetchConfig, disabled, ...props }: TeachersPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);
    const { fetchFunction, fetchQueryKey } = fetchConfig;

    //TODO: fetch only teachers (to change after changes on backend)
    const {
        data: employeesData,
        isFetching: isEmployeesDataFetching,
        isError: isEmployeesDataError,
    } = useAppQuery(fetchQueryKey, [], fetchFunction, { staleTime: FIVE_MINS_IN_MILLIS });

    useDisplayFetchDataError({
        isFetching: isEmployeesDataFetching,
        isError: isEmployeesDataError,
        actualError: formError,
        errorMessage: employeeTexts.fetchingEmployeesDataForInputError,
        setError: setFormError,
    });

    const processEmployeeUser = (data: EmployeeUser[]): SelectOptionType<EmployeeId>[] =>
        data.map(parseEmployeeUserIntoSelectOptionType);

    const processEmployeeDTO = (data: EmployeeDTO[]): SelectOptionType<EmployeeId>[] =>
        data.map(parseEmployeeDTOIntoSelectOptionType);

    const teachersOptions = useMemo(() => {
        if (!employeesData) {
            return [];
        }

        return employeesData.some((employee) => 'id' in employee)
            ? processEmployeeDTO(employeesData as EmployeeDTO[])
            : processEmployeeUser(employeesData as EmployeeUser[]);
    }, [employeesData]);

    return (
        <TeachersPickerElement
            {...props}
            options={teachersOptions}
            loading={isEmployeesDataFetching}
            disabled={disabled}
        />
    );
};
