import { GroupId, MailThreadChildReceiverDTO } from 'types';
import { ExtendedDataNode } from 'components/atoms';
import { getCombinedTreePickerKey } from 'utils/getCombinedTreePickerKey';

export const parseMailThreadChildReceiverDTOIntoExtendedDataNode: (
    mailThreadChildReceiverDTO: MailThreadChildReceiverDTO,
    groupId?: GroupId,
) => ExtendedDataNode = (mailThreadChildReceiverDTO, groupId) => {
    const { childId, name } = mailThreadChildReceiverDTO;
    const key = groupId ? getCombinedTreePickerKey(childId, groupId) : childId;
    return {
        title: name,
        key,
    };
};
