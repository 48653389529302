import { AxiosError } from 'axios';
import { FormNavButtons, DailyScheduleInputElement } from 'components/molecules';
import { CommonFormProps, DailyScheduleFormFields, FormModeDependedTexts } from 'types';
import { commonTexts, journalTexts } from 'consts/text';
import { AppForm, AppFormProps } from 'components/organisms';

const addModeTexts: FormModeDependedTexts = {
    name: 'dailyScheduleAdd',
    title: journalTexts.journalDetails.groupJournal.schedules.dailySchedule.addDailySchedule,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'dailyScheduleEdit',
    title: journalTexts.journalDetails.groupJournal.schedules.dailySchedule.editDailySchedule,
    buttonText: commonTexts.actionLabels.save,
};

type DailyScheduleEditFormProps = Omit<
    AppFormProps<DailyScheduleFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        onFinish: (dailyScheduleData: DailyScheduleFormFields) => void;
    };

export const DailyScheduleAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    ...props
}: DailyScheduleEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText } = isAddMode ? addModeTexts : editModeTexts;
    const dailyScheduleAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<DailyScheduleFormFields>
            name={name}
            title={title}
            error={dailyScheduleAddEditError}
            withGoBack
            maxWidth={600}
            {...props}
        >
            <DailyScheduleInputElement optional={!isAddMode} />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                withPlus={isAddMode}
                withDeviceFloppy={!isAddMode}
            />
        </AppForm>
    );
};
