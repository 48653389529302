import { generatePath, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { GroupTopicAddEditForm } from 'components/templates';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseGroupTopicDTOIntoGroupTopicFormFields } from 'utils/parsers/groupTopic/parseGroupTopicDTOIntoGroupTopicFormFields';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO } from 'utils/parsers/groupTopic/parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO';
import { GroupTopicFormFields, GroupTopicId } from 'types';
import { ErrorDisplay } from 'components/molecules';
import { journalTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const GroupJournalTopicEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<GroupTopicFormFields>();
    const { journalId } = useJournalId();
    const { id: institutionId } = useCurrentInstitution();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { topicId: groupTopicIdParam } = useParams();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const topicId = (groupTopicIdParam || '') as GroupTopicId;

    const {
        data: groupTopicData,
        error: groupTopicError,
        isFetching: isGroupTopicDataFetching,
    } = useAppQuery(
        'GROUP_TOPIC',
        [journalId, topicId],
        () => StaffHTTPService.groupTopics.getGroupTopic(journalId, topicId),
        { refetchOnWindowFocus: false, staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (groupTopicData) {
            setFormData(parseGroupTopicDTOIntoGroupTopicFormFields(groupTopicData));
        }
    }, [groupTopicData, setFormData]);

    const navigateToTopicsPage = () => {
        statefulNavigate(
            generatePath(appPaths.app.journals.groupJournalDetails.pickedJournal.topics.base, {
                journalId,
            }),
            {
                replace: true,
            },
        );
    };

    const {
        mutate: updateGroupTopic,
        error: editGroupTopicError,
        isLoading: isEditGroupTopicLoading,
    } = useAppMutation(StaffHTTPService.groupTopics.editGroupTopic, {
        key: ['UPDATE_GROUP_TOPIC'],
        onSuccess: async (_, variables) => {
            await queryClient.invalidateQueries([
                institutionId,
                'GROUP_TOPIC',
                variables.journalId,
                variables.groupTopicId,
            ]);

            displaySuccess(
                journalTexts.journalDetails.groupJournal.topics.topicEditSuccessNotification,
            );
            navigateToTopicsPage();
        },
        invalidateQueryKeys: [['GROUP_TOPICS', journalId]],
    });

    const onEditTopicFinish = (topicData: GroupTopicFormFields) => {
        const parsedUpdateTopicDTO = parseGroupTopicFormFieldsIntoAddUpdateGroupTopicDTO(topicData);
        updateGroupTopic({
            journalId,
            groupTopicId: topicId,
            updateGroupTopicDTO: parsedUpdateTopicDTO,
        });
    };

    if (!topicId) {
        navigateToTopicsPage();
    }

    if (groupTopicError) {
        return <ErrorDisplay />;
    }

    return (
        <GroupTopicAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={editGroupTopicError}
            isLoading={isEditGroupTopicLoading || isGroupTopicDataFetching}
            onFinish={onEditTopicFinish}
        />
    );
};
