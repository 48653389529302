import { WeeklyScheduleDaysDTO } from 'types';
import { commonTexts, jsonObjectsTexts } from 'consts/text';

export const parseWeeklyScheduleDaysDTOIntoJsonObject: (
    weeklyScheduleDaysDTO: WeeklyScheduleDaysDTO,
) => object = (weeklyScheduleDaysDTO) => {
    const { dayName, description } = weeklyScheduleDaysDTO;

    const dayNameInfo = dayName.toLowerCase() as keyof typeof commonTexts.dayLabels;

    return {
        [jsonObjectsTexts.weeklyScheduleDTO.innerObjects.weeklyScheduleDaysDTO.fields.dayName]:
            dayNameInfo,
        [jsonObjectsTexts.weeklyScheduleDTO.innerObjects.weeklyScheduleDaysDTO.fields.description]:
            description,
    };
};
