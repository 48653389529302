import { ChildId, ChildWithParentsIds, ParentId } from 'types';

export const countSelectedParentsAmount = (selectedChildrenWithParentsIds: ChildWithParentsIds[]) =>
    selectedChildrenWithParentsIds.reduce(
        (totalParentAmount, currentChild) => totalParentAmount + currentChild.parentIds.length,
        0,
    );

export const handleCheckParentOption = (
    parentId: ParentId,
    childId: ChildId,
    prevSelectedChildrenWithParentsIds: ChildWithParentsIds[],
    foundChildParentsGroup?: ChildWithParentsIds,
) => {
    if (!foundChildParentsGroup) {
        return [...prevSelectedChildrenWithParentsIds, { childId, parentIds: [parentId] }];
    }
    return prevSelectedChildrenWithParentsIds.map((selectedChildrenWithParentsIdsItem) => {
        if (selectedChildrenWithParentsIdsItem.childId !== foundChildParentsGroup.childId) {
            return selectedChildrenWithParentsIdsItem;
        }
        return {
            childId: foundChildParentsGroup.childId,
            parentIds: [...foundChildParentsGroup.parentIds, parentId],
        };
    });
};

export const handleUncheckParentOption = (
    parentId: ParentId,
    prevSelectedChildrenWithParentsIds: ChildWithParentsIds[],
    foundChildParentsGroup?: ChildWithParentsIds,
) => {
    if (!foundChildParentsGroup) {
        return prevSelectedChildrenWithParentsIds;
    }
    const filteredFoundGroupParents = foundChildParentsGroup.parentIds.filter(
        (parentIdItem) => parentIdItem !== parentId,
    );
    if (!filteredFoundGroupParents.length) {
        return prevSelectedChildrenWithParentsIds.filter(
            (selectedChildrenWithParentsIdsItem) =>
                selectedChildrenWithParentsIdsItem.childId !== foundChildParentsGroup.childId,
        );
    }
    return prevSelectedChildrenWithParentsIds.map((selectedChildrenWithParentsIdsItem) => {
        if (selectedChildrenWithParentsIdsItem.childId !== foundChildParentsGroup.childId) {
            return selectedChildrenWithParentsIdsItem;
        }
        return {
            childId: foundChildParentsGroup.childId,
            parentIds: filteredFoundGroupParents,
        };
    });
};
