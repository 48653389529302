import { colors } from 'theme/styledComponents/colors';
import { ColorMapper, HistoryChangeType } from 'types';

export const tagsColorsByHistoryChangeType: ColorMapper<HistoryChangeType> = {
    UPDATE: {
        textColor: colors.yellow.yellow7,
        backgroundColor: colors.yellow.yellow2,
    },
    CREATE: { textColor: colors.primary.primary9, backgroundColor: colors.primary.primary5 },
    DELETE: { textColor: colors.red.red8, backgroundColor: colors.red.red2 },
};
