import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { useAppAbility } from '../useAppAbility';

export const useOtherAbilities = () => {
    const ability = useAppAbility();

    const otherAbilities = {
        checkIfCanAddInstitution: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.INSTITUTIONS_CREATE),
    };

    return { otherAbilities };
};
