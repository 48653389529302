import { UpdateJournalChildDatesDTO, JournalChildDatesFormFields } from 'types';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO: (
    journalChildDatesFormFields: JournalChildDatesFormFields,
) => UpdateJournalChildDatesDTO = (journalChildDatesFormFields) => ({
    leaveDate: journalChildDatesFormFields.leaveDate
        ? parseDayjsDateIntoString(journalChildDatesFormFields.leaveDate, 'YYYY-MM-DD')
        : null,
    joinDate: parseDayjsDateIntoString(journalChildDatesFormFields.joinDate, 'YYYY-MM-DD'),
});
