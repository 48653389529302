import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { AxiosResponse } from 'axios';
import { PrincipalEditForm } from 'components/templates';
import { PrincipalDTO, PrincipalFormFields, UpdatePrincipalDTO } from 'types';
import { AppConfirmModal } from 'components/organisms';
import { commonTexts, settingsTexts } from 'consts/text';
import { UserEdit } from 'assets';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { parsePrincipalDTOIntoPrincipalFormFields } from 'utils/parsers/principal/parsePrincipalDTOIntoPrincipalFormFields';
import { parsePrincipalFormFieldsIntoUpdatePrincipalDTO } from 'utils/parsers/principal/parsePrincipalFormFieldsIntoUpdatePrincipalDTO';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useSettingsAbilities } from 'abilities';
import { PageBaseLayout } from 'components/layouts';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export const PrincipalEditPage = () => {
    const [parsedUpdatePrincipalDTO, setParsedUpdatePrincipalDTO] = useState<UpdatePrincipalDTO>();
    const currentInstitution = useCurrentInstitution();
    const { form, formInitialData, setFormData } = useEditForm<PrincipalFormFields>();
    const { settingsAbilities } = useSettingsAbilities();
    const { displaySuccess } = useSimpleNotification();
    const theme = useTheme();

    const canManagePrincipal = settingsAbilities.checkIfCanManagePrincipal();

    const {
        data: principalData,
        isError: isPrincipalDataError,
        isFetching: isPrincipalDataFetching,
    } = useAppQuery(
        'PRINCIPAL',
        [currentInstitution.id],
        () => StaffHTTPService.institutions.getPrincipal(currentInstitution.id),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (!principalData) {
            return;
        }
        setFormData(parsePrincipalDTOIntoPrincipalFormFields(principalData));
    }, [principalData, setFormData]);

    const onEditPrincipalSuccess = (editPrincipalResponse: AxiosResponse<PrincipalDTO>) => {
        displaySuccess(settingsTexts.principalDetails.successfullyEditedPrincipalMessage);
        setFormData(parsePrincipalDTOIntoPrincipalFormFields(editPrincipalResponse.data));
    };

    const {
        mutate: editPrincipal,
        error: editPrincipalError,
        isLoading: isEditPrincipalLoading,
    } = useAppMutation(StaffHTTPService.institutions.updatePrincipal, {
        key: ['UPDATE_PRINCIPAL'],
        onSuccess: onEditPrincipalSuccess,
        invalidateQueryKeys: [['PRINCIPAL', currentInstitution.id]],
    });
    const onEditPrincipalFinish = (principalFormFields: PrincipalFormFields) => {
        const parsedPrincipalFormFields =
            parsePrincipalFormFieldsIntoUpdatePrincipalDTO(principalFormFields);
        setParsedUpdatePrincipalDTO(parsedPrincipalFormFields);
    };

    const handleConfirmSaveData = () => {
        if (!parsedUpdatePrincipalDTO) {
            return;
        }
        setParsedUpdatePrincipalDTO(undefined);
        editPrincipal({
            institutionId: currentInstitution.id,
            updatePrincipalDTO: parsedUpdatePrincipalDTO,
        });
    };

    const handleCancelSaveData = () => setParsedUpdatePrincipalDTO(undefined);

    return (
        <>
            <AppConfirmModal
                open={!!parsedUpdatePrincipalDTO}
                title={commonTexts.warningMessages.saveDataWarning}
                icon={<UserEdit style={{ color: theme.colors.primary.primary10 }} />}
                okButtonProps={{ title: commonTexts.actionLabels.confirm }}
                cancelButtonProps={{ title: commonTexts.actionLabels.reject }}
                onOk={handleConfirmSaveData}
                onCancel={handleCancelSaveData}
            />
            <PageBaseLayout isError={isPrincipalDataError}>
                <PrincipalEditForm
                    form={form}
                    initialValues={formInitialData}
                    axiosError={editPrincipalError}
                    isLoading={isPrincipalDataFetching || isEditPrincipalLoading}
                    isViewMode={!canManagePrincipal}
                    onFinish={onEditPrincipalFinish}
                />
            </PageBaseLayout>
        </>
    );
};
