export const employeeUserFields = {
    userId: 'userId',
    employeeId: 'employeeId',
    email: 'email',
    role: 'role',
    firstName: 'firstName',
    lastName: 'lastName',
    firstAndLastName: 'firstAndLastName',
    phoneNumber: 'phoneNumber',
    active: 'active',
    groups: 'groups',
} as const;
