import { HistoryChangeGroupTopicDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseStringIntoFormattedStringTime } from 'utils/parsers/dateTime/parseStringIntoFormattedStringTime';

export const parseGroupTopicDTOIntoJsonObject: (
    groupTopicDTO: HistoryChangeGroupTopicDTO,
) => object = (groupTopicDTO) => {
    const { name, date, description, coreCurriculums, teachers, timeFrom, timeTo, textbookName } =
        groupTopicDTO;

    const formattedDate = parseStringIntoFormattedStringDate(date);
    const formattedTimeFrom = parseStringIntoFormattedStringTime(timeFrom);
    const formattedTimeTo = parseStringIntoFormattedStringTime(timeTo);
    const teachersJsonObjects = teachers.map((teacher) => ({
        [jsonObjectsTexts.groupTopicDTO.innerObjects.groupTeacherDTO.fields.firstname]:
            teacher.firstName,
        [jsonObjectsTexts.groupTopicDTO.innerObjects.groupTeacherDTO.fields.lastname]:
            teacher.lastName,
    }));

    return {
        [jsonObjectsTexts.groupTopicDTO.fields.textbookName]: textbookName,
        [jsonObjectsTexts.groupTopicDTO.fields.name]: name,
        [jsonObjectsTexts.groupTopicDTO.fields.date]: formattedDate,
        [jsonObjectsTexts.groupTopicDTO.fields.timeFrom]: formattedTimeFrom,
        [jsonObjectsTexts.groupTopicDTO.fields.timeTo]: formattedTimeTo,
        [jsonObjectsTexts.groupTopicDTO.fields.description]: description,
        [jsonObjectsTexts.groupTopicDTO.fields.coreCurriculums]: coreCurriculums,
        [jsonObjectsTexts.groupTopicDTO.fields.teachers]: teachersJsonObjects,
    };
};
