import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { settingsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { rolesAndPermissionsDeleteErrorParser } from 'utils/errorHandlers/rolesAndPermissions/rolesAndPermissionsDeleteErrorParser';

export const useRolesPageNetworkManage = () => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: rolesData,
        isLoading: isRolesDataLoading,
        isError: isRolesDataError,
    } = useAppQuery('ROLES', [], () => StaffHTTPService.roles.getRoles(), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { mutate: deleteRole, isLoading: isRoleDeleteLoading } = useAppMutation(
        StaffHTTPService.roles.deleteRole,
        {
            key: ['DELETE_ROLE'],
            onSuccess: async (_, variables) => {
                displaySuccess(settingsTexts.rolesAndPermissions.role.deleted);
                // variables is RoleId
                await queryClient.invalidateQueries([institutionId, 'ROLE', variables]);
            },
            onError: (error) => displayError(rolesAndPermissionsDeleteErrorParser(error)),
            invalidateQueryKeys: [['ROLES']],
        },
    );

    return {
        rolesData,
        isRoleDeleteLoading,
        isRolesDataLoading,
        isRolesDataError,
        deleteRole,
    };
};
