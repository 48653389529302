import styled, { css } from 'styled-components';
import { AppTable } from 'components/organisms';

export const FiltersContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 40px;
        gap: ${theme.sizes.marginNormal}px;

        @media ${theme.sizes.mediaQueries.mobileMax} {
            flex-direction: column-reverse;
        }
    `}
`;

export const StyledTable = styled(AppTable)`
    .ant-table-container {
        .ant-table-thead,
        .ant-table-tbody {
            .ant-table-cell {
                &:last-child,
                &:nth-last-child(2) {
                    background-color: ${({ theme }) => theme.colors.primary.primary2};
                }
            }
        }
    }
`;
