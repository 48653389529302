import { useLocation, useSearchParams } from 'react-router-dom';
import { routesPl } from 'consts/paths/routesPl';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { ReactNode } from 'react';
import { appRoutes } from 'consts/paths/paths';
import { AppBreadcrumb, AppLink } from 'components/atoms/CommonAppComponents';
import { getPathSnippetsWithoutBaseLocation, getPathSnippetDataByIndex } from './utils';

export const SideMenuBreadcrumb = () => {
    const isMobile = useIsMobile();
    const location = useLocation();
    const pathSnippets = getPathSnippetsWithoutBaseLocation(location.pathname);
    const [searchParams] = useSearchParams();

    const extraBreadcrumbItems = pathSnippets.reduce<{ key: string; title: ReactNode }[]>(
        (previousValue, _, index) => {
            const previousIndex = index - 1 < 0 ? 0 : index - 1;
            const previousSnippedData = getPathSnippetDataByIndex(
                previousIndex,
                pathSnippets,
                appRoutes,
                routesPl,
                searchParams,
            );

            const { url, recreatedPath, routeName, isLast, isId } = getPathSnippetDataByIndex(
                index,
                pathSnippets,
                appRoutes,
                routesPl,
                searchParams,
            );

            if (isId) {
                previousValue.pop();
                return previousValue.concat({
                    key: recreatedPath.join('-'),
                    title: (
                        <AppLink highlighted={isLast} to={url}>
                            {routeName || previousSnippedData.routeName}
                        </AppLink>
                    ),
                });
            }

            return previousValue.concat({
                key: recreatedPath.join('-'),
                title: (
                    <AppLink highlighted={isLast} to={url}>
                        {routeName || ''}
                    </AppLink>
                ),
            });
        },
        [],
    );

    return isMobile ? null : <AppBreadcrumb items={extraBreadcrumbItems} />;
};
