import { RouteObject } from 'react-router-dom';
import { SideMenuLayout } from 'components/layouts';
import { appPaths } from 'consts/paths/paths';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { SettingsLayout } from 'components/layouts/SettingsLayout/SettingsLayout';
import { AppBlockModal } from 'components/organisms';
import { PaymentsPage } from 'pages';

export const blockedStaffRoutes: RouteObject[] = [
    {
        element: <SideMenuLayout />,
        children: [
            {
                path: appPaths.app.settings.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW_MODULE,
                            subject: STAFF_APP_MODULES.SETTINGS,
                        }}
                    >
                        <SettingsLayout />
                    </AbilityRoute>
                ),
                children: [
                    {
                        path: appPaths.app.settings.payments,
                        element: (
                            <AbilityRoute
                                can={{
                                    action: AppActions.DO_ACTION,
                                    subject: PERMISSIONS_NAME.PAYMENTS_SETTINGS,
                                }}
                            >
                                <PaymentsPage />
                            </AbilityRoute>
                        ),
                    },
                ],
            },
            {
                path: `${appPaths.app.base}/*`,
                element: <AppBlockModal />,
            },
        ],
    },
];
