import { IconEdit } from '@tabler/icons-react';
import { Child } from 'types';
import { AppButton, AppTitle } from 'components/atoms';
import { ChildAvatar, ChildAvatarMethodType } from 'components/organisms';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { childrenTexts } from 'consts/text';
import { useChildAbilities } from 'abilities';
import { noop } from 'lodash';
import { Col } from 'antd';
import { ReactNode } from 'react';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { Container, LeftSide } from './Header.styled';

export type HeaderProps = Pick<Child, 'lastName' | 'firstName' | 'id' | 'photoUri'> & {
    childEditPagePath?: string;
    canManageChildAvatar?: boolean;
    httpMethod: ChildAvatarMethodType;
    additionalElement?: ReactNode;
};

export const Header = ({
    photoUri,
    lastName,
    firstName,
    id,
    childEditPagePath,
    canManageChildAvatar,
    httpMethod,
    additionalElement,
}: HeaderProps) => {
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();
    const { childAbilities } = useChildAbilities();

    const canManageChildDetails =
        (canManageChildAvatar || childAbilities.checkIfCanManageChildrenList()) &&
        !!childEditPagePath;

    const navigateToChildEdit = () =>
        childEditPagePath ? statefulNavigate(childEditPagePath) : noop;

    return (
        <Container>
            <LeftSide>
                <ChildAvatar
                    childId={id}
                    childImageUri={photoUri}
                    canManageAvatar={canManageChildDetails}
                    httpMethod={httpMethod}
                />
                <Col>
                    <AppTitle
                        level={isMobile ? 4 : 3}
                        margin={0}
                        marginTop={isMobile ? 'marginLarge' : undefined}
                        marginBottom={isMobile ? 'marginLarge' : undefined}
                        marginLeft={isMobile ? undefined : 'marginLarge'}
                    >
                        {lastName} {firstName}
                    </AppTitle>
                    {additionalElement || null}
                </Col>
            </LeftSide>
            {canManageChildDetails && (
                <AppButton
                    marginTop={8}
                    type="primary"
                    icon={<IconEdit />}
                    onClick={navigateToChildEdit}
                >
                    {childrenTexts.editChildrenButtonLabel}
                </AppButton>
            )}
        </Container>
    );
};
