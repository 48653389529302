import { generatePath } from 'react-router-dom';
import { EventType, JournalId } from 'types';
import { journalTexts } from 'consts/text';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { eventOptions, getEventsPagePathToRedirect } from './EventTypeSelect.utils';
import { StyledSelect } from './EventTypeSelect.styled';

type EventTypeSelectProps = {
    eventType: EventType | null;
    journalId: JournalId;
};

export const EventTypeSelect = ({ eventType, journalId }: EventTypeSelectProps) => {
    const isMobile = useIsMobile();
    const { statefulNavigate } = useNavigateWithState();

    const handleEventTypeChange = (selectedEventType: EventType) =>
        statefulNavigate(
            generatePath(getEventsPagePathToRedirect(selectedEventType), { journalId }),
        );

    return (
        <StyledSelect
            label={journalTexts.journalDetails.groupJournal.events.selectEventType}
            value={eventType}
            options={eventOptions}
            onChange={handleEventTypeChange}
            allowClear={false}
            isFitContentWidth={!isMobile}
        />
    );
};
