import { GroupDTO, GroupId, SelectOptionType } from 'types';
import { groupDTOFields } from 'consts/group/groupDTOFields';

export const parseGroupDTOIntoSelectOptionType: (
    groupDetails: GroupDTO,
) => SelectOptionType<GroupId> = (groupDetails) => ({
    label: groupDetails[groupDTOFields.name],
    value: groupDetails[groupDTOFields.id],
    disabled: groupDetails[groupDTOFields.status] === 'ARCHIVED',
});
