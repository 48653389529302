import {
    AbsencesDTO,
    AdditionalLessonDTO,
    CalendarEventDto,
    Child,
    ChildCostAllocation,
    ChildDetailed,
    ChildDetailedForParentDto,
    ChildGuardian,
    ChildParent,
    ChildPayment,
    ChildProfilePhotoDTO,
    DailyScheduleDTO,
    DietDTO,
    DiscountDTO,
    DtoData,
    EmployeeBasicInfo,
    EmployeeUser,
    GroupConsultationDTO,
    GroupDayAttendanceDTO,
    HistoryChangeGroupDTO,
    HistoryChangeGroupTopicDTO,
    HistoryChangeJournalDTO,
    HistoryChangeSpecialAttendanceDTO,
    ImportantEventDTO,
    InspectionDTO,
    InstitutionBasicDto,
    InstitutionDto,
    InvoiceDTO,
    LessonDTO,
    MenuDTO,
    OwnerDTO,
    ParentTeacherConferenceDTO,
    PrincipalDTO,
    PsychologicalHelpDTO,
    QualificationBasisDto,
    RemoteLessonDTO,
    Role,
    SettlementMealsDTO,
    SettlementOverallDTO,
    SettlementStaysDTO,
    SpecialConsultationDTO,
    SpecialTopicDTO,
    TripDTO,
    WeeklyScheduleDTO,
    WorkingTimeDTO,
} from 'types';
import { parseEmployeeUserIntoJsonObject } from 'utils/parsers/jsonObject/employee/parseEmployeeUserIntoJsonObject';
import { parseGroupDTOIntoJsonObject } from 'utils/parsers/jsonObject/group/parseGroupDTOIntoJsonObject';
import { parseMenuDTOIntoJsonObject } from 'utils/parsers/jsonObject/menu/parseMenuDTOIntoJsonObject';
import { parseWorkingTimeDTOIntoJsonObject } from 'utils/parsers/jsonObject/workingTime/parseWorkingTimeDTOIntoJsonObject';
import { parseRemoteLessonDTOIntoJsonObject } from 'utils/parsers/jsonObject/remoteLesson/parseRemoteLessonDTOIntoJsonObject';
import { parseLessonDTOIntoJsonObject } from 'utils/parsers/jsonObject/lesson/parseLessonDTOIntoJsonObject';
import { parseDietDTOIntoJsonObject } from 'utils/parsers/jsonObject/diet/parseDietDTOIntoJsonObject';
import { parseRoleIntoJsonObject } from 'utils/parsers/jsonObject/role/parseRoleDTOIntoJsonObject';
import { parseTripDTOIntoJsonObject } from 'utils/parsers/jsonObject/trip/parseTripDTOIntoJsonObject';
import { parseImportantEventDTOIntoJsonObject } from 'utils/parsers/jsonObject/importantEvent/parseImportantEventDTOIntoJsonObject';
import { parseParentTeacherConferenceDTOIntoJsonObject } from 'utils/parsers/jsonObject/parentTeacherConference/parseParentTeacherConferenceDTOIntoJsonObject';
import { parseInspectionDTOIntoJsonObject } from 'utils/parsers/jsonObject/inspection/parseInspectionDTOIntoJsonObject';
import { parseSpecialAttendanceDTOIntoJsonObject } from 'utils/parsers/jsonObject/specialAttendance/parseSpecialAttendanceDTOIntoJsonObject';
import { parseDiscountDTOIntoJsonObject } from 'utils/parsers/jsonObject/discount/parseDiscountDTOIntoJsonObject';
import { parsePrincipalDTOIntoJsonObject } from 'utils/parsers/jsonObject/principal/parsePrincipalDTOIntoJsonObject';
import { parseGroupDayAttendanceDTOIntoJsonObject } from 'utils/parsers/jsonObject/attendance/parseGroupDayAttendanceDTOIntoJsonObject';
import { parseCalendarEventDTOIntoJsonObject } from 'utils/parsers/jsonObject/calendarEvent/parseCalendarEventDTOIntoJsonObject';
import { parseSpecialTopicDTOIntoJsonObject } from 'utils/parsers/jsonObject/specialTopic/parseSpecialTopicDTOIntoJsonObject';
import { parseSpecialConsultationDTOIntoJsonObject } from 'utils/parsers/jsonObject/specialConsultation/parseSpecialConsultationDTOIntoJsonObject';
import { parseWeeklyScheduleDTOIntoJsonObject } from 'utils/parsers/jsonObject/weeklySchedule/parseWeeklyScheduleDTOIntoJsonObject';
import { parseDailyScheduleDTOIntoJsonObject } from 'utils/parsers/jsonObject/dailySchedule/parseDailyScheduleDTOIntoJsonObject';
import { parseQualificationBasisDTOIntoJsonObject } from 'utils/parsers/jsonObject/qualification/parseQualificationBasisDTOIntoJsonObject';
import { parsePsychologicalHelpDTOIntoJsonObject } from 'utils/parsers/jsonObject/psychologicalHelp/parsePsychologicalHelpDTOIntoJsonObject';
import { parseGroupTopicDTOIntoJsonObject } from 'utils/parsers/jsonObject/groupTopic/parseGroupTopicDTOIntoJsonObject';
import { parseGroupConsultationDTOIntoJsonObject } from 'utils/parsers/jsonObject/groupConsultation/parseGroupConsultationDTOIntoJsonObject';
import { parseChildProfilePhotoDTOIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildProfilePhotoDTOIntoJsonObject';
import { parseAbsencesDTOIntoJsonObject } from 'utils/parsers/jsonObject/absence/parseAbsencesDTOIntoJsonObject';
import { parseInstitutionDtoIntoJsonObject } from 'utils/parsers/jsonObject/institution/parseInstitutionDtoIntoJsonObject';
import { parseInstitutionBasicDtoIntoJsonObject } from 'utils/parsers/jsonObject/institution/parseInstitutionBasicDtoIntoJsonObject';
import { parseChildParentIntoJsonObject } from 'utils/parsers/jsonObject/parent/parseChildParentIntoJsonObject';
import { parseChildIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildIntoJsonObject';
import { parseChildDetailedIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildDetailedIntoJsonObject';
import { parseOwnerDTOIntoJsonObject } from 'utils/parsers/jsonObject/owner/parseOwnerDTOIntoJsonObject';
import { parseEmployeeBasicInfoIntoJsonObject } from 'utils/parsers/jsonObject/employee/parseEmployeeBasicInfoIntoJsonObject';
import { parseChildGuardianIntoJsonObject } from 'utils/parsers/jsonObject/guardian/parseChildGuardianIntoJsonObject';
import { parseChildDetailedForParentDtoIntoJsonObject } from 'utils/parsers/jsonObject/child/parseChildDetailedForParentDtoIntoJsonObject';
import { parseJournalDTOIntoJsonObject } from 'utils/parsers/jsonObject/lesson/parseJournalDTOIntoJsonObject';
import { parseInvoiceDTOIntoJsonObject } from 'utils/parsers/jsonObject/invoice/parseInvoiceDTOIntoJsonObject';
import { parseAdditionalLessonDTOIntoJsonObject } from 'utils/parsers/jsonObject/additionalLesson/parseAdditionalLessonDTOIntoJsonObject';
import { parseChildPaymentIntoJsonObject } from 'utils/parsers/jsonObject/childPayment/parseChildPaymentIntoJsonObject';
import { parseSettlementMealsDTOIntoJsonObject } from 'utils/parsers/jsonObject/settlementMeals/parseSettlementMealsDTOIntoJsonObject';
import { parseSettlementStaysDTOIntoJsonObject } from 'utils/parsers/jsonObject/settlementStays/parseSettlementStaysDTOIntoJsonObject';
import { parseSettlementOverallDTOIntoJsonObject } from 'utils/parsers/jsonObject/settlementOverall/parseSettlementOverallDTOIntoJsonObject';
import { parseChildCostAllocationIntoJsonObject } from 'utils/parsers/jsonObject/childConstAllocation/parseChildCostAllocationIntoJsonObject';

export const parseDtoDataIntoJsonObject: (dtoData: DtoData) => object | null = (dtoData) => {
    const { type, record } = dtoData;

    switch (type) {
        case 'EmployeeUserDto': {
            return parseEmployeeUserIntoJsonObject(record as EmployeeUser);
        }
        case 'GroupDto': {
            return parseGroupDTOIntoJsonObject(record as HistoryChangeGroupDTO);
        }
        case 'MenuDto': {
            return parseMenuDTOIntoJsonObject(record as MenuDTO);
        }
        case 'WorkingTimeDto': {
            return parseWorkingTimeDTOIntoJsonObject(record as WorkingTimeDTO);
        }
        case 'RemoteLessonDto': {
            return parseRemoteLessonDTOIntoJsonObject(record as RemoteLessonDTO);
        }
        case 'LessonDto': {
            return parseLessonDTOIntoJsonObject(record as LessonDTO);
        }
        case 'DietDto': {
            return parseDietDTOIntoJsonObject(record as DietDTO);
        }
        case 'RoleDto': {
            return parseRoleIntoJsonObject(record as Role);
        }
        case 'TripDto': {
            return parseTripDTOIntoJsonObject(record as TripDTO);
        }
        case 'ImportantEventDto': {
            return parseImportantEventDTOIntoJsonObject(record as ImportantEventDTO);
        }
        case 'ParentTeacherConferenceDto': {
            return parseParentTeacherConferenceDTOIntoJsonObject(
                record as ParentTeacherConferenceDTO,
            );
        }
        case 'Inspection': {
            return parseInspectionDTOIntoJsonObject(record as InspectionDTO);
        }
        case 'SpecialTopicDto': {
            return parseSpecialTopicDTOIntoJsonObject(record as SpecialTopicDTO);
        }
        case 'SpecialConsultationDto': {
            return parseSpecialConsultationDTOIntoJsonObject(record as SpecialConsultationDTO);
        }
        case 'SpecialAttendanceDto': {
            return parseSpecialAttendanceDTOIntoJsonObject(
                record as HistoryChangeSpecialAttendanceDTO,
            );
        }
        case 'WeeklyScheduleDto': {
            return parseWeeklyScheduleDTOIntoJsonObject(record as WeeklyScheduleDTO);
        }
        case 'DailyScheduleDto': {
            return parseDailyScheduleDTOIntoJsonObject(record as DailyScheduleDTO);
        }
        case 'QualificationBasisDto': {
            return parseQualificationBasisDTOIntoJsonObject(record as QualificationBasisDto);
        }
        case 'PsychologicalHelpDto': {
            return parsePsychologicalHelpDTOIntoJsonObject(record as PsychologicalHelpDTO);
        }
        case 'GroupTopicDto': {
            return parseGroupTopicDTOIntoJsonObject(record as HistoryChangeGroupTopicDTO);
        }
        case 'GroupConsultationDto': {
            return parseGroupConsultationDTOIntoJsonObject(record as GroupConsultationDTO);
        }
        case 'DayGroupAttendanceDto': {
            return parseGroupDayAttendanceDTOIntoJsonObject(record as GroupDayAttendanceDTO);
        }
        case 'InstitutionDto': {
            return parseInstitutionDtoIntoJsonObject(record as InstitutionDto);
        }
        case 'InstitutionBasicDto': {
            return parseInstitutionBasicDtoIntoJsonObject(record as InstitutionBasicDto);
        }
        case 'PrincipalDto': {
            return parsePrincipalDTOIntoJsonObject(record as PrincipalDTO);
        }
        case 'DiscountDto': {
            return parseDiscountDTOIntoJsonObject(record as DiscountDTO);
        }
        case 'ChildDetailedDto': {
            return parseChildDetailedIntoJsonObject(record as ChildDetailed);
        }
        case 'ChildDto': {
            return parseChildIntoJsonObject(record as Child);
        }
        case 'ChildParentDto': {
            return parseChildParentIntoJsonObject(record as ChildParent);
        }
        case 'ChildProfilePhotoDto': {
            return parseChildProfilePhotoDTOIntoJsonObject(record as ChildProfilePhotoDTO);
        }
        case 'CalendarEventDto': {
            return parseCalendarEventDTOIntoJsonObject(record as CalendarEventDto);
        }
        case 'AbsencesDto': {
            return parseAbsencesDTOIntoJsonObject(record as AbsencesDTO);
        }
        case 'OwnerDto': {
            return parseOwnerDTOIntoJsonObject(record as OwnerDTO);
        }
        case 'EmployeeBasicInfo': {
            return parseEmployeeBasicInfoIntoJsonObject(record as EmployeeBasicInfo);
        }
        case 'ChildGuardianDto': {
            return parseChildGuardianIntoJsonObject(record as ChildGuardian);
        }
        case 'ChildDetailedForParentDto': {
            return parseChildDetailedForParentDtoIntoJsonObject(
                record as ChildDetailedForParentDto,
            );
        }
        case 'JournalDto': {
            return parseJournalDTOIntoJsonObject(record as HistoryChangeJournalDTO);
        }
        case 'InvoiceDataDto': {
            return parseInvoiceDTOIntoJsonObject(record as InvoiceDTO);
        }
        case 'CoursesSettlementConfigDto': {
            return parseAdditionalLessonDTOIntoJsonObject(record as AdditionalLessonDTO);
        }
        case 'TransactionDto': {
            return parseChildPaymentIntoJsonObject(record as ChildPayment);
        }
        case 'SettlementMealSettingsDto': {
            return parseSettlementMealsDTOIntoJsonObject(record as SettlementMealsDTO);
        }
        case 'SettlementStaySettingsDto': {
            return parseSettlementStaysDTOIntoJsonObject(record as SettlementStaysDTO);
        }
        case 'SettlementGeneralSettingsDto': {
            return parseSettlementOverallDTOIntoJsonObject(record as SettlementOverallDTO);
        }
        case 'CostSplitDto': {
            return parseChildCostAllocationIntoJsonObject(record as ChildCostAllocation);
        }
        default: {
            return null;
        }
    }
};
